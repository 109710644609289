import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import ArrowButtonsForImages from 'components/upb_viewer/pdp_components/images_section/ArrowButtonsForImages';
import { chartreuse, darkGrey2, iconGrey2, squidInk, white } from 'lib/css/colors';
import ProductImagePlaceholder from 'components/upb_viewer/pdp_components/images_section/ProductImagePlaceholder';

function ProductImagesSection({ images = [] }) {
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageSliderSettings, setImageSliderSettings] = useState({
    startIndex: 0,
    endIndex: 0,
  });

  const firstImage = images?.[0];
  const showLeftFade = imageSliderSettings.startIndex > 0;
  const showRightFade = imageSliderSettings.endIndex < images?.length;
  const thumbImageLimit = 4;
  const showPlaceholderImage =
    firstImage?.url?.includes('placeholder-img') || !images || images?.length === 0;

  useEffect(() => {
    if (images?.length && !showPlaceholderImage) {
      const endIndex = Math.min(thumbImageLimit, images?.length);
      setSelectedImage(firstImage);
      setImageSliderSettings(preSettings => ({ ...preSettings, endIndex: endIndex }));
    }
  }, [images?.length, firstImage, thumbImageLimit, showPlaceholderImage]);

  const selectImageHandler = (image, index) => {
    setSelectedImage(image);
    setSelectedImageIndex(imageSliderSettings.startIndex + index);
  };

  return (
    <Container className={'p-0 m-0 container-fluid'}>
      {showPlaceholderImage && (
        <Row>
          <Col className={classNames('d-flex justify-content-center align-items-start')}>
            <ProductImagePlaceholder imageWidth={400} iconWidth={100} textFontSize={20} />
          </Col>
        </Row>
      )}
      {!showPlaceholderImage && (
        <Row>
          <Col className={classNames('d-flex justify-content-center', 'align-items-start')}>
            {images?.length > 1 && (
              <div className={css(sliderStyles.backArrow)}>
                <ArrowButtonsForImages
                  left
                  selectedImageIndex={selectedImageIndex}
                  setSelectedImageIndex={setSelectedImageIndex}
                  setSelectedImage={setSelectedImage}
                  setImageSliderSettings={setImageSliderSettings}
                  displayImages={images}
                  thumbImageLimit={thumbImageLimit}
                />
              </div>
            )}

            <Image className={css(imageStyles.productImage)} src={selectedImage?.url} fluid />

            {images?.length > 1 && (
              <div className={css(sliderStyles.nextArrow)}>
                <ArrowButtonsForImages
                  right
                  selectedImageIndex={selectedImageIndex}
                  setSelectedImageIndex={setSelectedImageIndex}
                  setSelectedImage={setSelectedImage}
                  setImageSliderSettings={setImageSliderSettings}
                  displayImages={images}
                  thumbImageLimit={thumbImageLimit}
                />
              </div>
            )}
          </Col>
        </Row>
      )}

      {images?.length > 1 && (
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <div className={css(sliderStyles.backArrow)}>
              <ArrowButtonsForImages
                left
                selectedImageIndex={selectedImageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                setSelectedImage={setSelectedImage}
                setImageSliderSettings={setImageSliderSettings}
                displayImages={images}
                thumbImageLimit={thumbImageLimit}
              />
            </div>
            <Row
              className={classNames(
                showLeftFade
                  ? showRightFade
                    ? css(styles.fade)
                    : css(styles.fadeLeft)
                  : showRightFade
                  ? css(styles.fadeRight)
                  : undefined,
                'flex-nowrap',
              )}
            >
              {images
                .slice(imageSliderSettings.startIndex, imageSliderSettings.endIndex)
                .map((image, idx) => (
                  <div key={image.id} className={`position-relative px-0 mr-1`}>
                    <Image
                      className={`m-0 ${css(styles.thumbImage, imageStyles.imageSelector)} ${
                        selectedImage?.url === image.url && styles.thumbImageCurrent
                      }`}
                      src={image.url}
                      fluid
                      onClick={() => selectImageHandler(image, idx)}
                    />
                  </div>
                ))}
            </Row>
            <div className={css(sliderStyles.nextArrow)}>
              <ArrowButtonsForImages
                right
                selectedImageIndex={selectedImageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                setSelectedImage={setSelectedImage}
                setImageSliderSettings={setImageSliderSettings}
                displayImages={images}
                thumbImageLimit={thumbImageLimit}
              />
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

const imageStyles = StyleSheet.create({
  productImage: {
    objectFit: 'contain',
    height: '65vh',
    width: '95%',
    borderRadius: '5px',
  },
  imageSelector: {
    objectFit: 'contain',
    height: '100%',
    width: 100,
  },
});

const styles = StyleSheet.create({
  column: {
    position: 'absolute',
    height: '500px',
  },
  greyscaleImage: {
    filter: 'grayscale(100%)',
  },
  iframe3x2: {
    width: '100%',
    height: '100%',
    paddingBottom: '67%',
  },
  label360: {
    top: 0,
    right: 0,
    cursor: 'pointer',
  },
  row: {
    paddingBottom: '60vh',
    position: 'relative',
  },
  thumbImage: {
    cursor: 'pointer',
    height: 100,
    width: 100,
    margin: '0px 5px',
    borderRadius: '5px',
    border: '2px solid',
    borderColor: darkGrey2,
  },
  thumbImageHeight: {
    maxHeight: 100,
  },
  thumbImageCurrent: {
    cursor: 'pointer',
    height: 100,
    width: 100,
    borderRadius: '5px',
    border: '2px solid',
    borderColor: iconGrey2,
  },
  topLeftLabel: {
    top: 12,
    left: 15,
    position: 'absolute',
  },
  addToggle: {
    top: 10,
    position: 'absolute',
    right: 10,
  },
  bannerStyle: {
    borderRadius: '5px 0px 10px',
    height: 40,
    fontSize: 18,
    fontWeight: 400,
    padding: 10,
  },
  bannerIcon: {
    paddingRight: 5,
    color: chartreuse,
  },
  shipDirectBannerStyle: {
    backgroundColor: squidInk,
    color: white,
  },
  fade: {
    '-webkit-mask': 'linear-gradient(90deg, transparent, black 15%, black 85%, transparent)',
  },
  fadeLeft: {
    '-webkit-mask': 'linear-gradient(90deg, transparent, black 15%)',
  },
  fadeRight: {
    '-webkit-mask': 'linear-gradient(90deg, black 85%, transparent)',
  },
});

const sliderStyles = StyleSheet.create({
  backArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 25,
    zIndex: 999,
  },
  nextArrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 25,
    zIndex: 999,
  },
});

ProductImagesSection.propTypes = {
  images: PropTypes.array,
};

export default ProductImagesSection;
