import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { colorStyles, darkGrey2, greyBackground, greyStroke } from 'lib/css/colors';
import { displayJsonSafeText } from 'lib/utils/displayJsonSafeText';

function AttributesTagView({ tags }) {
  return (
    <>
      {tags.length > 0 && (
        <div className={classNames('my-3 p-3 d-flex flex-wrap', css(styles.container))}>
          {tags.map((tag, idx) => (
            <div
              className={classNames(css(colorStyles.squidInk, styles.tag), 'bg-white d-flex')}
              key={idx}
            >
              {displayJsonSafeText(tag)}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    background: greyBackground,
    borderRadius: 8,
    border: `1px solid ${darkGrey2}`,
    gap: 10,
  },
  tag: {
    border: `1px solid ${greyStroke}`,
    borderRadius: 8,
    padding: '8px 12px',
  },
});

AttributesTagView.propTypes = {
  tags: PropTypes.array,
};

export default AttributesTagView;
