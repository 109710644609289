// update along with app/lib/enums/product_type.py
const ProductType = {
  MANUFACTURER: 'manufacturer',
  DISTRIBUTOR: 'distributor',
  GLOBAL: 'global',
};

const ProductTypeDisplay = {
  MANUFACTURER: 'Manufacturer',
  DISTRIBUTOR: 'Distributor',
};

const ProductTypeDisplayOptions = [
  { value: ProductTypeDisplay.MANUFACTURER, label: 'Manufacturer' },
  { value: ProductTypeDisplay.DISTRIBUTOR, label: 'Distributor' },
];

const GLOBAL_PRODUCT_TYPE = 'global';

export { ProductType, ProductTypeDisplay, ProductTypeDisplayOptions, GLOBAL_PRODUCT_TYPE };
