import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';

import { grape } from 'lib/css/colors';
import { PRODUCT_LIST_IMAGE_SOURCE_EXPORT } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function ImageSourceModal({ productListId, onHide }) {
  const [thumbnailOnly, setThumbnailOnly] = useState(false);

  const { postData: exportImageSourceData } = usePost(
    PRODUCT_LIST_IMAGE_SOURCE_EXPORT,
    () => {
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while.
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }).then(() => {
        onHide();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while trying to export the file`,
      }),
  );

  return (
    <Modal animation={false} centered show={true} onHide={onHide}>
      <div>
        <Modal.Header className="py-3 mb-0" closeButton>
          <h5>Export Product List Image Sources</h5>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-center my-3">
            <Col className="d-flex align-items-center">
              Thumbnail Image Sources Only
              <ToggleSwitch
                className="ml-1 mr-4"
                onColor={grape}
                checked={thumbnailOnly}
                onChange={e => setThumbnailOnly(e)}
              />
            </Col>
          </Row>
          <hr />
          <div>
            <Button
              onClick={() =>
                exportImageSourceData({
                  product_list_id: productListId,
                  thumbnail_only: thumbnailOnly,
                })
              }
              size="lg"
              className="mr-2"
            >
              Export Image Source File
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

ImageSourceModal.propTypes = {
  productListId: PropTypes.number,
  onHide: PropTypes.func,
};

export default ImageSourceModal;
