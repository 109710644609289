import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import SwalDialog from 'lib/utils/toast';
import ToggleSwitch from 'react-switch';

import { areYouSure } from 'lib/utils/toast';
import {
  GLOBAL_MANUFACTURERS,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  BRANDS_UPDATE_FROM_QA,
  GLOBAL_MANUFACTURERS_UPDATE,
} from 'lib/networking/endpoints';
import { grape } from 'lib/css/colors';
import { ManufacturerVerificationStatus } from 'lib/enums/ManufacturerVerificationStatus';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function BrandQAEditModal({ brand, onHide, refetch }) {
  const [updatedBrand, setUpdatedBrand] = useState({
    name: brand?.name,
    id: brand?.id,
    similar_brand_names: brand?.similar_brands,
    removed_similar_brands: [],
  });

  const [updatedManufacturer, setUpdatedManufacturer] = useState({
    global_manufacturer_name: brand?.global_manufacturer?.name,
    global_manufacturer_id: brand?.global_manufacturer?.id,
    manufacturer_verification_status: brand?.global_manufacturer?.verification_status,
    is_new: false,
  });

  const [selectedGlobalManufacturer, setSelectedGlobalManufacturer] = useState({
    global_manufacturer_name: brand?.global_manufacturer?.name,
    global_manufacturer_id: brand?.global_manufacturer?.id,
  });

  const [checkedBrands, setCheckedBrands] = useState([]);

  const { data: { manufacturer = {} } = {} } = useGet(
    `${GLOBAL_MANUFACTURERS}/${selectedGlobalManufacturer.global_manufacturer_id}`,
    {},
    !selectedGlobalManufacturer.global_manufacturer_id,
  );

  useEffect(() => {
    if (selectedGlobalManufacturer.global_manufacturer_id !== null) {
      if (
        manufacturer.id !== updatedManufacturer.global_manufacturer_id ||
        manufacturer.name !== updatedManufacturer.global_manufacturer_name
      ) {
        setUpdatedManufacturer({
          global_manufacturer_id: manufacturer.id,
          global_manufacturer_name: manufacturer.name,
          manufacturer_verification_status: manufacturer.verification_status,
          is_new: false,
        });
      }
    } else {
      if (
        selectedGlobalManufacturer.global_manufacturer_id !==
          updatedManufacturer.global_manufacturer_id ||
        selectedGlobalManufacturer.global_manufacturer_name !==
          updatedManufacturer.global_manufacturer_name
      ) {
        setUpdatedManufacturer({
          global_manufacturer_id: selectedGlobalManufacturer.global_manufacturer_id,
          global_manufacturer_name: selectedGlobalManufacturer.global_manufacturer_name,
          manufacturer_verification_status: ManufacturerVerificationStatus.UNVERIFIED,
          is_new: true,
        });
      }
    }
  }, [selectedGlobalManufacturer, manufacturer, updatedManufacturer]);

  const { postData: editBrand } = usePost(
    BRANDS_UPDATE_FROM_QA,
    () => {
      SwalDialog('success', 'Successfully Updated the Brand', 'Success', 'center');
      onHide();
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while Updating the Brand: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const { postData: updateGlobalManufacturerVerificationStatus } = usePost(
    GLOBAL_MANUFACTURERS_UPDATE,
    () => {
      SwalDialog('success', 'Successfully Updated the Manufacturer', 'Success', 'center');
      onHide();
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while Updating the Manufacturer: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const handleBrandNameChange = event => {
    setUpdatedBrand(prevState => ({
      ...prevState,
      name: event.target.value,
    }));
  };

  const handleManufacturerChange = option => {
    if (option?.__isNew__) {
      setSelectedGlobalManufacturer({
        global_manufacturer_id: null,
        global_manufacturer_name: option?.label || '',
      });
    } else {
      setSelectedGlobalManufacturer({
        global_manufacturer_id: option?.value || null,
        global_manufacturer_name: option?.label || '',
      });
    }
  };

  const handleCheck = id => {
    setCheckedBrands(prevCheckedBrands =>
      prevCheckedBrands.includes(id)
        ? prevCheckedBrands.filter(brandId => brandId !== id)
        : [...prevCheckedBrands, id],
    );
  };

  const handleRemove = () => {
    setUpdatedBrand(prevState => ({
      ...prevState,
      similar_brand_names: prevState.similar_brand_names.filter(
        similarBrand => !checkedBrands.includes(similarBrand.id),
      ),
      removed_similar_brands: [...prevState.removed_similar_brands, ...checkedBrands],
    }));
    setCheckedBrands([]);
  };

  const handleVerificationStatusChange = () => {
    setUpdatedManufacturer(prevState => ({
      ...prevState,
      manufacturer_verification_status:
        prevState.manufacturer_verification_status === ManufacturerVerificationStatus.VERIFIED
          ? ManufacturerVerificationStatus.UNVERIFIED
          : ManufacturerVerificationStatus.VERIFIED,
    }));
  };

  const handleUpdate = () => {
    areYouSure(() => {
      editBrand({
        ...updatedBrand,
        global_manufacturer_id: updatedManufacturer.global_manufacturer_id,
        global_manufacturer_name: updatedManufacturer.global_manufacturer_name,
        global_manufacturer_verification_status:
          updatedManufacturer.manufacturer_verification_status,
      });
      if (!updatedManufacturer.is_new && updatedManufacturer.global_manufacturer_id) {
        updateGlobalManufacturerVerificationStatus({
          id: updatedManufacturer.global_manufacturer_id,
          verified: updatedManufacturer.manufacturer_verification_status,
        });
      }
    }, 'Are you sure you want to update the Brand and Manufacturer?');
  };

  const handleUpdateAndResolve = () => {
    areYouSure(() => {
      editBrand({
        ...updatedBrand,
        global_manufacturer_id: updatedManufacturer.global_manufacturer_id,
        global_manufacturer_name: updatedManufacturer.global_manufacturer_name,
        global_manufacturer_verification_status:
          updatedManufacturer.manufacturer_verification_status,
        resolve: true,
      });
      if (!updatedManufacturer.is_new && updatedManufacturer.global_manufacturer_id) {
        updateGlobalManufacturerVerificationStatus({
          id: updatedManufacturer.global_manufacturer_id,
          verified: updatedManufacturer.manufacturer_verification_status,
        });
      }
    }, 'Are you sure you want to Update and Resolve the Brand and Manufacturer?');
  };

  return (
    <Modal centered show={true} onHide={onHide} size="m">
      <Modal.Header className="py-2 my-2" closeButton>
        <h5 className="font-weight-bold">Edit Brand</h5>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Brand Name</Form.Label>
          <Form.Control
            value={updatedBrand?.name}
            type="text"
            onChange={e => handleBrandNameChange(e)}
            className={css(styles.formControl)}
          />
        </Form.Group>
        <Row>
          <Col className={css(styles.columnWidth_2)}>
            <Form.Group>
              <Form.Label>Manufacturer Name</Form.Label>
              <SearchableScrollableSelect
                fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
                optionMapper={manufacturer => ({
                  label: manufacturer.name,
                  value: manufacturer.id,
                })}
                otherRequestParams={{ verified_only: false }}
                initOption={{
                  label: updatedManufacturer.global_manufacturer_name,
                  value: updatedManufacturer.global_manufacturer_id,
                }}
                onChange={handleManufacturerChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Row>
              <Form.Label>Verified</Form.Label>
            </Row>
            <Row>
              <ToggleSwitch
                onColor={grape}
                checked={
                  updatedManufacturer.manufacturer_verification_status ===
                  ManufacturerVerificationStatus.VERIFIED
                }
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={handleVerificationStatusChange}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={css(styles.columnWidth)}>
            <Form.Label>Similar Brand Names</Form.Label>
          </Col>
          <Col>
            <Button onClick={handleRemove}>Remove</Button>
          </Col>
        </Row>
        <Row>
          <ul>
            {updatedBrand?.similar_brand_names?.length > 0 ? (
              updatedBrand.similar_brand_names.map(similarBrand => (
                <Row key={similarBrand.id}>
                  <Form.Check type="checkbox" onChange={() => handleCheck(similarBrand.id)} />
                  <li>{similarBrand.brand_name}</li>
                </Row>
              ))
            ) : (
              <p>No similar brand names</p>
            )}
          </ul>
        </Row>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <Button variant="primary" onClick={handleUpdateAndResolve}>
          Update and Resolve
        </Button>
        <Button variant="outline-primary" onClick={handleUpdate}>
          Update
        </Button>
        <Button variant="outline-primary" onClick={() => onHide()}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

BrandQAEditModal.propTypes = {
  brand: PropTypes.object,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

const styles = StyleSheet.create({
  formControl: {
    maxWidth: '520px',
  },
  columnWidth: {
    width: '500px',
  },
  columnWidth_2: {
    minWidth: '400px',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
  },
});

export default BrandQAEditModal;
