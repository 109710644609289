import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

import ManufacturerFilter from 'components/manage_brands/brand_details/ManufacturerFilter';
import SimilarNameEditor from 'components/manage_brands/brand_details/SimilarNameEditor';
import VideoUrlEditor from 'components/manage_brands/brand_details/VideoUrlEditor';

function EditableProperty({
  label,
  brand,
  setIsEditing,
  updatedBrand,
  setUpdatedBrand,
  updateBrand,
  refetch,
}) {
  const handleUpdateBrand = (property, value) => {
    setUpdatedBrand(prevBrand => ({
      ...prevBrand,
      [property]: value !== undefined ? value : null,
    }));
  };
  return label === 'manufacturer_id' ? (
    <ManufacturerFilter
      manufacturerID={brand.manufacturer_id}
      label={label}
      handleUpdateBrand={handleUpdateBrand}
    />
  ) : label === 'similar_brands' ? (
    <SimilarNameEditor
      similarBrands={brand.similar_brands}
      setIsEditing={setIsEditing}
      refetch={refetch}
    />
  ) : label === 'video_urls' ? (
    <VideoUrlEditor
      label={label}
      brand={brand}
      setIsEditing={setIsEditing}
      setUpdatedBrand={setUpdatedBrand}
      updatedBrand={updatedBrand}
      updateBrand={updateBrand}
    />
  ) : (
    <Form.Control
      as={label === 'description' ? 'textarea' : 'input'}
      className="mr-2"
      rows={10}
      style={{ height: 'auto' }}
      autoFocus
      defaultValue={brand[label]}
      onChange={event => handleUpdateBrand(label, event.target.value)}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          setIsEditing(false);
        }
      }}
    />
  );
}

EditableProperty.propTypes = {
  brand: PropTypes.object,
  label: PropTypes.string,
  setIsEditing: PropTypes.func,
  setUpdatedBrand: PropTypes.func,
  updatedBrand: PropTypes.object,
  refetch: PropTypes.func,
  updateBrand: PropTypes.func,
};

export default EditableProperty;
