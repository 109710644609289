import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import Select from 'react-select/creatable';

import { BRANDS_MERGE } from 'lib/networking/endpoints';
import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import usePost from 'lib/hooks/usePost';

function MergeBrandsModal({ mergeList, setMergeList, onHide }) {
  const [filteredBrands, setFilteredBrands] = useState(mergeList);
  const [parentBrand, setParentBrand] = useState(null);

  useEffect(() => {
    //If there is only one verified brand in mergeList it will be set as the parent brand.
    const verifiedBrand = mergeList.find(
      brand => brand.verification_status === BrandVerificationStatus.VERIFIED,
    );
    const unverifiedBrands = mergeList.filter(
      brand => brand.verification_status !== BrandVerificationStatus.VERIFIED,
    );

    if (
      verifiedBrand &&
      mergeList.filter(brand => brand.verification_status === BrandVerificationStatus.VERIFIED)
        .length === 1
    ) {
      setFilteredBrands(unverifiedBrands);
      setParentBrand(verifiedBrand);
    }
  }, [mergeList]);

  const { postData: mergeBrands, loading } = usePost(
    BRANDS_MERGE,
    () => {
      SwalDialog('success', 'Successfully merged brands', 'Success', 'center');
      onHide();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while merging brands: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const options = mergeList.map((brand, index) => ({
    label: brand.name,
    value: index,
  }));

  const handleMergeBrands = () => {
    const hasVerifiedBrand = filteredBrands.some(
      brand => brand.verification_status === BrandVerificationStatus.VERIFIED,
    );

    if (!parentBrand?.verification_status === BrandVerificationStatus.VERIFIED) {
      Swal.fire({
        icon: 'error',
        title: 'Parent Brand Not Verified',
        text: 'Parent brand is not verified.',
        confirmButtonText: 'OK',
      });
    } else if (hasVerifiedBrand) {
      Swal.fire({
        icon: 'warning',
        title: 'Cannot Merge',
        text: 'At least one verified brand in the secondary brands.',
        confirmButtonText: 'OK',
      });
    } else {
      mergeBrands({
        parentId: parentBrand.id,
        mergeIds: filteredBrands.map(brand => brand.id),
      });
    }
  };

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Merge Brands</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h3 className="font-weight-bold">Parent Brand</h3>
        <Select
          className="mb-3"
          options={options}
          value={parentBrand ? options.find(option => option.label === parentBrand.name) : null}
          onChange={option => {
            if (option) {
              setParentBrand(mergeList[option.value]);
              setFilteredBrands(
                mergeList.filter(mergeBrand => mergeBrand.id !== mergeList[option.value].id),
              );
            } else {
              setParentBrand(null);
            }
          }}
          placeholder="Select the parent brand"
        />
        <h3 className="font-weight-bold">Secondary Brands</h3>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>FSA ID</th>
              <th>Verified</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {filteredBrands.map((brand, index) => (
              <tr key={index}>
                <td>{brand.name}</td>
                <td>{brand.id}</td>
                <td>
                  {brand.verification_status === BrandVerificationStatus.VERIFIED ? 'Yes' : 'No'}
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilteredBrands(
                        filteredBrands.filter(mergeBrand => mergeBrand.id !== brand.id),
                      );
                      setMergeList(mergeList.filter(mergeBrand => mergeBrand.id !== brand.id));
                    }}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row className="pt-3 mt-3 d-flex justify-content-between">
          <Col sm={3}>
            <Button
              disabled={filteredBrands?.length < 1 || loading || !parentBrand}
              block
              variant="primary"
              onClick={handleMergeBrands}
            >
              {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Merge'}
            </Button>
          </Col>
          <Col sm={2}>
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

MergeBrandsModal.propTypes = {
  mergeList: PropTypes.array,
  setMergeList: PropTypes.func,
  onHide: PropTypes.func,
};

export default MergeBrandsModal;
