import React from 'react';
import PropTypes from 'prop-types';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

function DataSourcePendingAttributesTableRow({
  sourceAttribute,
  sortedUnsavedAttributeMap,
  setUnsavedAttributeMap,
  attributeOptions,
}) {
  return (
    <tr>
      <td>{sourceAttribute}</td>
      <td>
        <FontAwesomeIcon icon={faArrowRight} />
      </td>
      <td>
        <Select
          isClearable
          options={attributeOptions}
          value={attributeOptions.find(
            option => option.value === sortedUnsavedAttributeMap[sourceAttribute],
          )}
          onChange={option => {
            setUnsavedAttributeMap({
              ...sortedUnsavedAttributeMap,
              [sourceAttribute]: option?.value || null,
            });
          }}
        />
      </td>
    </tr>
  );
}

DataSourcePendingAttributesTableRow.propTypes = {
  sourceAttribute: PropTypes.string.isRequired,
  sortedUnsavedAttributeMap: PropTypes.object.isRequired,
  setUnsavedAttributeMap: PropTypes.func.isRequired,
  attributeOptions: PropTypes.array.isRequired,
};

export default DataSourcePendingAttributesTableRow;
