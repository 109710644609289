import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';

import { API_INTEGRATION, API_INTEGRATION_DELETE } from 'lib/networking/endpoints';
import { API_INTEGRATION_OPTIONS } from 'lib/enums/APIIntegrationEnum';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { salmon } from 'lib/css/colors';
import SwalDialog from 'lib/utils/toast';
import SyndigoAPIIntegrationModal from 'components/manage_vendors/vendor_details/APIIntegrationModals/SyndigoAPIIntegrationModal';
import useDelete from 'lib/hooks/useDelete';
import useGet from 'lib/hooks/useGet';

const MODAL = { SYNDIGO: SyndigoAPIIntegrationModal };

function APIIntegrationDropDown({ globalVendorId = null, globalManufacturerId = null }) {
  const [selectedAPIIntegration, setSelectedAPIIntegration] = useState(null);
  const [showAPIIntegrationModal, setShowAPIIntegrationModal] = useState(false);

  const {
    data: { data: integration = {} } = {},
    loading,
    refetch,
  } = useGet(API_INTEGRATION, {
    global_vendor_id: globalVendorId,
    global_manufacturer_id: globalManufacturerId,
  });

  useEffect(() => {
    if (integration) {
      setSelectedAPIIntegration(
        API_INTEGRATION_OPTIONS.find(op => op.value === integration.integration_type),
      );
    }
  }, [integration]);

  const { deleteData: deleteAPIIntegration, loading: deleting } = useDelete(
    API_INTEGRATION_DELETE,
    false,
    () => {
      SwalDialog('success', 'Deleted successfully', 'Success', 'center');
      refetch();
    },
    error => SwalDialog('error', 'An error occurred', error.response?.data?.message, 'center'),
  );

  const SelectedModal = selectedAPIIntegration ? MODAL[selectedAPIIntegration.value] : null;

  if (loading || deleting) {
    return <LoadingSpinner short />;
  }

  return (
    <>
      <Row>
        <Col sm="4">
          <Form.Group>
            <Form.Label>Select API Integration</Form.Label>
            <Select
              options={API_INTEGRATION_OPTIONS}
              value={selectedAPIIntegration}
              onChange={option => {
                setSelectedAPIIntegration(option);
                setShowAPIIntegrationModal(false);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm="6">
          {selectedAPIIntegration?.value &&
          integration.integration_type !== selectedAPIIntegration.value ? (
            <Button className={css(styles.button)} onClick={() => setShowAPIIntegrationModal(true)}>
              {`Connect ${selectedAPIIntegration?.value}`}
            </Button>
          ) : null}
          {selectedAPIIntegration?.value &&
          integration.integration_type === selectedAPIIntegration.value ? (
            <>
              <Button
                className={css(styles.ActiveButton)}
                onClick={() => setShowAPIIntegrationModal(true)}
              >
                {`Edit ${selectedAPIIntegration?.value} Connection`}
              </Button>
              <Button
                className={css(styles.DisconnectButton)}
                variant="link"
                onClick={() => deleteAPIIntegration({ api_integration_id: integration.id })}
              >
                Delete
              </Button>
            </>
          ) : null}
        </Col>
      </Row>
      {showAPIIntegrationModal && SelectedModal ? (
        <SelectedModal
          onHide={() => setShowAPIIntegrationModal(false)}
          onSave={() => {}}
          apiIntegrationData={integration}
          globalVendorId={globalVendorId}
        />
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: '35px',
  },
  ActiveButton: {
    marginTop: '35px',
  },
  DisconnectButton: {
    marginTop: '35px',
    marginLeft: '2px',
    color: salmon,
  },
});

APIIntegrationDropDown.propTypes = {
  globalVendorId: PropTypes.number,
  globalManufacturerId: PropTypes.number,
};

export default APIIntegrationDropDown;
export { API_INTEGRATION_OPTIONS };
