import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { filterContainerBoarder, salmon } from 'lib/css/colors';

import { Col, Form, Button, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';

import CatalogAttributeFilterValue from 'components/manage_vendors/vendor_details/catalog_settings/CatalogAttributeFilterValue';
import AddNewAttributeModal from 'components/manage_vendors/vendor_details/catalog_settings/AddNewAttributeModal';
import { areYouSure } from 'lib/utils/toast';

import {
  CATALOG_FILTER_ATTRIBUTES_VENDOR_UPDATE,
  CATALOG_FILTER_ATTRIBUTES_VENDOR_DELETE,
  CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE_PRESET,
} from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';
import pointerOnHover from 'lib/css/pointerOnHover';

function CatalogAttributeFilterView({ catalogFilterAttribute, globalVendorId, refetch }) {
  const [showAddNewAttributeModal, setShowAddNewAttributeModal] = useState(false);
  const [updatedFilterValues, setUpdatedFilterValues] = useState({
    global_vendor_id: globalVendorId,
    catalog_filter_attribute_id: catalogFilterAttribute.id,
    catalog_filter_attribute_name: catalogFilterAttribute.name,
    attribute_values: catalogFilterAttribute.catalog_filter_attribute_values.map(
      attribute_value => ({
        attribute_id: attribute_value.attribute_value?.attributes?.id,
        global_attribute_id: attribute_value.attribute_value?.global_attribute?.id,
        attribute_name: attribute_value.attribute_value?.attributes?.id
          ? attribute_value.attribute_value?.attributes?.attribute_name
          : attribute_value.attribute_value?.global_attribute?.global_attribute_name,
        catalog_filter_attribute_display_name: attribute_value.display_name,
        catalog_filter_attribute_value_id: attribute_value.id,
        catelog_filter_attribute_value_name: attribute_value.attribute_value.attribute_value,
        attribute_value_id: attribute_value.attribute_value_id,
        is_new: false,
      }),
    ),
    deleted_attribue_values: [],
  });

  const { postData: updateFilter } = usePost(
    CATALOG_FILTER_ATTRIBUTES_VENDOR_UPDATE,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully Saved',
        title: 'Success',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: deleteFilter } = usePost(
    CATALOG_FILTER_ATTRIBUTES_VENDOR_DELETE,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully Deleted the Filter',
        title: 'Success',
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: makePreset } = usePost(
    CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE_PRESET,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully Created the Preset',
        title: 'Success',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const handleDeleteAttribute = (index, id) => {
    const updatedValues = updatedFilterValues.attribute_values.filter((_, i) => i !== index);
    setUpdatedFilterValues(prevState => ({
      ...prevState,
      attribute_values: updatedValues,
      deleted_attribue_values: [...prevState.deleted_attribue_values, id],
    }));
  };
  const updateFilterName = event => {
    setUpdatedFilterValues(prevState => ({
      ...prevState,
      catalog_filter_attribute_name: event.target.value,
    }));
  };

  const saveChanges = () => {
    updateFilter(updatedFilterValues);
  };

  const handleDeleteFilter = () => {
    deleteFilter({ catalog_filter_attribute_id: catalogFilterAttribute.id });
  };
  const handleMakingPreset = () => {
    makePreset({ catalog_filter_attribute_id: catalogFilterAttribute.id });
  };
  return (
    <Row>
      <Col className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
        <Form>
          <Form.Group as={Row}>
            <Col sm={4} className="d-flex">
              <Form.Control
                className="mr-2"
                type="text"
                placeholder="Filter Name"
                value={updatedFilterValues.catalog_filter_attribute_name}
                onChange={event => {
                  updateFilterName(event);
                }}
              />
            </Col>
            <Col className="d-flex justify-content-end">
              <Button
                className="mr-2"
                variant="outline-primary"
                onClick={() => {
                  areYouSure(
                    handleMakingPreset,
                    'Are you sure you want to Create a preset from the filter?',
                  );
                }}
              >
                Save as a Preset
              </Button>
              <Button
                className="mr-2"
                variant="outline-danger"
                onClick={() => {
                  areYouSure(handleDeleteFilter, 'Are you sure you want to Delete the Filter?');
                }}
              >
                Delete Filter
              </Button>
              <Button
                className={css(styles.button)}
                onClick={() => {
                  areYouSure(saveChanges, 'Are you sure you want to save the changes?');
                }}
              >
                Save
              </Button>
            </Col>
          </Form.Group>

          <div className="mt-4">
            {updatedFilterValues.attribute_values.map((attribute_value, index) => (
              <Row key={index}>
                <Col>
                  <CatalogAttributeFilterValue
                    key={index}
                    catalogFilterAttributeValue={attribute_value}
                    updatedFilterValues={updatedFilterValues}
                    setUpdatedFilterValues={setUpdatedFilterValues}
                  />
                </Col>
                <div className="mr-3">
                  <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                    <FontAwesomeIcon
                      className={classNames(pointerOnHover, 'mt-4')}
                      style={{ color: salmon }}
                      icon={faTrashAlt}
                      onClick={() => {
                        areYouSure(
                          () =>
                            handleDeleteAttribute(
                              index,
                              attribute_value.catalog_filter_attribute_value_id,
                            ),
                          'Are you sure you want to Delete the Attribute?',
                        );
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </Row>
            ))}
            <Row className="d-flex justify-content-end">
              <Button
                className={css(styles.button_2)}
                onClick={() => setShowAddNewAttributeModal(true)}
              >
                + Add New Attribute
              </Button>
            </Row>
          </div>
        </Form>
      </Col>
      {showAddNewAttributeModal && (
        <AddNewAttributeModal
          onHide={() => setShowAddNewAttributeModal(false)}
          catalogFilterAttributeID={catalogFilterAttribute.id}
          setUpdatedFilterValues={setUpdatedFilterValues}
        />
      )}
    </Row>
  );
}

CatalogAttributeFilterView.propTypes = {
  catalogFilterAttribute: PropTypes.object,
  globalVendorId: PropTypes.number,
  refetch: PropTypes.func,
};

const styles = StyleSheet.create({
  filterContainer: {
    border: `3px solid ${filterContainerBoarder}`,
  },
  button: {
    minWidth: '100px',
    minHeight: '10px',
  },
  button_2: {
    minWidth: '130px',
    minHeight: '10px',
    marginRight: '10px',
  },
});

export default CatalogAttributeFilterView;
