import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';

import { darkerSalmon } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import { SORT_DIRECTIONS } from 'lib/constants';

function TableHeader({ title, onSearch, value, sortable, sortConfig, setSortConfig }) {
  const [searchText, setSearchText] = useState('');

  const handleTextChange = e => {
    const value = e.target.value;
    setSearchText(value);
  };

  const updateSortConfig = () => {
    let direction = SORT_DIRECTIONS.ASC;
    if (sortConfig.field === title) {
      if (sortConfig.direction === SORT_DIRECTIONS.ASC) {
        direction = SORT_DIRECTIONS.DESC;
      }
    }
    setSortConfig({
      field: title,
      direction,
    });
  };
  return (
    <th
      className={classNames(css(title === 'name' ? styles.nameColumn : null), 'd-flex-column p-1')}
    >
      <div
        className={classNames(
          sortConfig?.field === title ? css(styles.sorted) : null,
          'd-flex align-items-center',
        )}
      >
        <div className={css(styles.label)}>{title}</div>
        {sortable ? (
          sortConfig.field === title && sortConfig.direction === 'desc' ? (
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'ml-2')}
              icon={faArrowDown}
              onClick={updateSortConfig}
            />
          ) : (
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'ml-2')}
              icon={faArrowUp}
              onClick={updateSortConfig}
            />
          )
        ) : null}
      </div>
      <Form.Control
        type="text"
        className={css(styles.formControl)}
        onChange={handleTextChange}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            onSearch(searchText);
          }
        }}
        defaultValue={value}
      />
    </th>
  );
}

const styles = StyleSheet.create({
  formControl: {
    height: '1.5rem',
    fontSize: '0.75rem',
    padding: '0.1rem 0.2rem',
  },
  nameColumn: {
    minWidth: 130,
  },
  label: {
    wordBreak: 'break-word',
    maxHeight: '20px',
    overflow: 'hidden',
  },
  sorted: {
    color: darkerSalmon,
  },
});

TableHeader.propTypes = {
  onSearch: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string,
  sortable: PropTypes.bool,
  sortConfig: PropTypes.object,
  setSortConfig: PropTypes.func,
};

export default TableHeader;
