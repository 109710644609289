import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';

import { grape } from 'lib/css/colors';
import { PRODUCT_LIST_IMAGE_CLEANUP } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function ImageCleanModal({ productListId, onHide }) {
  const [optimizeImages, setOptimizeImages] = useState(false);
  const [deleteDuplicateImages, setDeleteDuplicateImages] = useState(false);
  const [deleteBrokenImages, setDeleteBrokenImages] = useState(false);

  const { postData: cleanImages } = usePost(
    PRODUCT_LIST_IMAGE_CLEANUP,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started started image cleanup process.',
      }).then(() => {
        onHide();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while cleaningup images: ${error?.response?.data?.message}`,
      }),
  );

  return (
    <Modal animation={false} centered show={true} onHide={onHide}>
      <div>
        <Modal.Header className="py-3 mb-0" closeButton>
          <h5>Clean Product List Images</h5>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex align-items-center my-3">
            <Col className="d-flex align-items-center">
              Optimize Images
              <ToggleSwitch
                className="ml-1 mr-4"
                onColor={grape}
                checked={optimizeImages}
                onChange={e => setOptimizeImages(e)}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="d-flex align-items-center">
              Delete Duplicate Images
              <ToggleSwitch
                className="ml-1 mr-4"
                onColor={grape}
                checked={deleteDuplicateImages}
                onChange={e => setDeleteDuplicateImages(e)}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="d-flex align-items-center">
              Delete Broken Images
              <ToggleSwitch
                className="ml-1 mr-4"
                onColor={grape}
                checked={deleteBrokenImages}
                onChange={e => setDeleteBrokenImages(e)}
              />
            </Col>
          </Row>
          <hr />
          <div>
            <Button
              onClick={() =>
                cleanImages({
                  product_list_id: productListId,
                  optimize_images: optimizeImages,
                  delete_duplicate_images: deleteDuplicateImages,
                  delete_broken_images: deleteBrokenImages,
                  optimize_propagated_images: true,
                })
              }
              size="lg"
              className="mr-2"
            >
              Clean Images
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

ImageCleanModal.propTypes = {
  productListId: PropTypes.number,
  onHide: PropTypes.func,
};

export default ImageCleanModal;
