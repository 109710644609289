import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { Dropdown } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ATTRIBUTE_VALUE_GROUP_MAPS } from 'lib/networking/endpoints';
import { backgroundGrey2, darkGrey } from 'lib/css/colors';
import CreateOrUpdateValueGroupModal from 'components/manage_attribute_value/shared/CreateOrUpdateValueGroupModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function AttributeValueGroupSection({
  attributeValues = [],
  attributeValueGroupMaps = [],
  createNewAttributeValueMap,
  refetchAll,
}) {
  const [showNewAttributeValueGroupModal, setShowNewAttributeValueGroupModal] = useState(false);
  const [selectedValueGroup, setSelectedValueGroup] = useState({});

  const { postData: deleteAttributeValueMapGroups, loading: deleting } = usePost(
    ATTRIBUTE_VALUE_GROUP_MAPS,
    () => refetchAll(),
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  const getTaxonomyLevel = taxoLevel => {
    return Object.keys(TaxonomyLevel).find(key => TaxonomyLevel[key] === taxoLevel.toString());
  };

  const getCategoryName = valueRow => {
    const taxonomyLevel = getTaxonomyLevel(valueRow.taxonomy_level);
    if (taxonomyLevel.includes(TaxonomyLevel.L0)) {
      return valueRow.l0_category_name;
    } else if (taxonomyLevel.includes(TaxonomyLevel.L1)) {
      return valueRow.l1_category_name;
    } else if (taxonomyLevel.includes(TaxonomyLevel.L2)) {
      return valueRow.l2_category_name;
    } else if (taxonomyLevel.includes(TaxonomyLevel.L3)) {
      return valueRow.l3_category_name;
    } else if (taxonomyLevel.includes(TaxonomyLevel.L4)) {
      return valueRow.l4_category_name;
    }
  };

  const getSelectedValuesAsAJson = valueRow => {
    const splitValueIds = valueRow.attribute_value_ids.split(',');
    const splitValueNames = valueRow.attribute_values.split(',');
    return splitValueIds.map((t, index) => ({
      valueName: splitValueNames[index],
      id: parseInt(t),
    }));
  };

  const hideModal = () => {
    setShowNewAttributeValueGroupModal(false);
    setSelectedValueGroup({});
  };

  if (deleting) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Row className={classNames(css(styles.header), 'mt-4 py-1 font-weight-bold')}>
        <Col sm={3}>
          <FontAwesomeIcon
            className={classNames(css(styles.plusIcon), pointerOnHover, 'float-left mt-1')}
            icon={faPlus}
            color={darkGrey}
            onClick={() => {
              if (attributeValues.length === 0) {
                SwalDialog(
                  'error',
                  'Please define Values for the attribute',
                  'No Attribute Values Defined',
                  'center',
                );
              } else {
                setShowNewAttributeValueGroupModal(true);
              }
            }}
          />
          <span className="float-left pl-2">Value Group</span>
        </Col>
        <Col sm={3}>Category Level</Col>
        <Col sm={6}>Values</Col>
      </Row>
      <div className={css(styles.scrollableDiv)}>
        {attributeValueGroupMaps.map((value, index) => (
          <div key={index}>
            <Row>
              <Col sm={3}>
                <Dropdown alignRight>
                  <Dropdown.Toggle variant="outline-none"></Dropdown.Toggle>
                  {getCategoryName(value)}
                  <Dropdown.Menu className="mr-n2 mt-2 dropdown-menu-right">
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedValueGroup({
                          ...value,
                          category_name: getCategoryName(value),
                          selected_values: getSelectedValuesAsAJson(value),
                        });
                        setShowNewAttributeValueGroupModal(true);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        deleteAttributeValueMapGroups({ attribute_value_group_map_ids: value.ids })
                      }
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col sm={3}>{getTaxonomyLevel(value.taxonomy_level)}</Col>
              <Col sm={6}>{value.attribute_values}</Col>
            </Row>
            <hr className="my-0 mr-1" />
          </div>
        ))}
      </div>
      {showNewAttributeValueGroupModal === true ? (
        <CreateOrUpdateValueGroupModal
          onHide={() => hideModal()}
          valueGroupMapDetails={selectedValueGroup}
          attributeValues={attributeValues}
          createNewAttributeValueMap={createNewAttributeValueMap}
        />
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: backgroundGrey2,
  },
  scrollableDiv: {
    overflow: 'scroll',
    height: '250px',
  },
  plusIcon: {
    height: '15px',
    width: '15px',
    border: `1px solid ${darkGrey}`,
  },
});

AttributeValueGroupSection.propTypes = {
  attributeValues: PropTypes.array,
  attributeValueGroupMaps: PropTypes.array,
  createNewAttributeValueMap: PropTypes.func,
  refetchAll: PropTypes.func,
};

export default AttributeValueGroupSection;
