import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css } from 'aphrodite';

import { fontStyles, subNutritionRowStyles } from 'components/shared/nutrition_facts/sharedStyles';
import Separator from 'components/shared/nutrition_facts/Separator';

function AddedSugarsRow({ quantity, uom, percentage }) {
  if (!quantity) {
    return null;
  }
  const valueString = [quantity, uom].filter(x => x).join('');
  return (
    <>
      <div className="w-100 d-flex justify-content-between">
        <div
          className={classNames(css(subNutritionRowStyles(2).titleContainer), fontStyles('16px'))}
        >
          Includes {valueString} Added Sugars
        </div>
        {percentage && <div className={fontStyles('16px', true)}>{`${percentage}%`}</div>}
      </div>
      <Separator />
    </>
  );
}

AddedSugarsRow.propTypes = {
  title: PropTypes.string,
  quantity: PropTypes.string,
  uom: PropTypes.string,
  percentage: PropTypes.string,
};

export default AddedSugarsRow;
