import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { darkGrey2, iconGrey2, white } from 'lib/css/colors';

function ArrowButtonsForImages({
  left,
  selectedImageIndex,
  setSelectedImageIndex,
  setSelectedImage,
  setImageSliderSettings,
  displayImages,
  thumbImageLimit,
}) {
  const backArrowHandler = () => {
    const index = selectedImageIndex === 0 ? selectedImageIndex : selectedImageIndex - 1;
    setSelectedImageIndex(index);
    setSelectedImage(displayImages[index]);
    setImageSliderSettings(preSettings => {
      if (index < preSettings.startIndex) {
        return {
          startIndex: preSettings.startIndex - 1 >= 0 ? preSettings.startIndex - 1 : 0,
          endIndex: preSettings.startIndex - 1 >= 0 ? preSettings.endIndex - 1 : thumbImageLimit,
        };
      }
      return preSettings;
    });
  };

  const nextArrowHandler = () => {
    const index =
      selectedImageIndex + 1 === displayImages.length ? selectedImageIndex : selectedImageIndex + 1;
    setSelectedImageIndex(index);
    setSelectedImage(displayImages[index]);
    setImageSliderSettings(preSettings => {
      if (index >= preSettings.endIndex) {
        return {
          startIndex:
            preSettings.endIndex + 1 <= displayImages.length
              ? preSettings.startIndex + 1
              : preSettings.startIndex,
          endIndex:
            preSettings.endIndex + 1 <= displayImages.length
              ? preSettings.endIndex + 1
              : displayImages.length,
        };
      }
      return preSettings;
    });
  };

  return (
    <FontAwesomeIcon
      className={classNames(left ? 'mr-2' : 'ml-2', css(styles.navigationArrow))}
      icon={left ? faChevronLeft : faChevronRight}
      size="1x"
      onClick={left ? backArrowHandler : nextArrowHandler}
    />
  );
}

const styles = StyleSheet.create({
  navigationArrow: {
    background: white,
    color: iconGrey2,
    width: 40,
    height: 40,
    borderRadius: '50%',
    textAlign: 'center',
    padding: 8,
    border: '1px solid ',
    borderColor: darkGrey2,
    ':hover': {
      cursor: 'pointer',
    },
    boxShadow: '0px 5px 10px 2px rgba(185, 185, 185, 0.25)',
  },
});

ArrowButtonsForImages.propTypes = {
  left: PropTypes.bool,
  selectedImageIndex: PropTypes.number,
  setSelectedImageIndex: PropTypes.func,
  setSelectedImage: PropTypes.func,
  setImageSliderSettings: PropTypes.func,
  displayImages: PropTypes.array,
  thumbImageLimit: PropTypes.number,
};

export default ArrowButtonsForImages;
