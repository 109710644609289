import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { VendorRequirementOptions } from 'lib/enums/VendorRequirement';

function BroadlinerComplianceModalRow({ header, value, update }) {
  return (
    <Form.Group>
      <Form.Label className="font-weight-bold">{header}</Form.Label>
      <Select
        options={VendorRequirementOptions}
        onChange={e => update(header, e)}
        value={VendorRequirementOptions.find(option => option.value === value)}
      />
    </Form.Group>
  );
}

BroadlinerComplianceModalRow.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
  update: PropTypes.func,
};

export default BroadlinerComplianceModalRow;
