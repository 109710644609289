import sw from 'stopword';

function getKeywords(text) {
  if (!text) {
    return [];
  }
  const rawText = text.replace(/[^a-zA-Z]+/gi, ' ').toLowerCase();
  return sw.removeStopwords(rawText.split(' ').filter(word => word.length >= 3));
}

function convertToTitleCase(text) {
  // SPICE, SALMON MAGIC => Spice, Salmon Magic
  if (!text) {
    return text;
  }
  const words = text.toLowerCase().split(' ');
  const convertedWords = words.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return convertedWords.join(' ');
}

export { getKeywords, convertToTitleCase };
