import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

import Container from 'components/shared/Container';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getURL, PRODUCT_LIST_IMAGE_FOR_TAGGING, PRODUCT_IMAGES } from 'lib/networking/endpoints';
import ImageFilterPanel from 'components/image_tagger/ImageFilterPanel';
import ImagesSection from 'components/image_tagger/ImagesSection';
import ImageTaggingPanel from 'components/image_tagger/ImageTaggingPanel';
import { IMAGE_TAGGER_HELP_GUIDE_URL } from 'lib/constants';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const PRODUCTS_PER_PAGE = 100;

function ImageTaggerView() {
  const { productListId } = useParams();
  const [filteredImageCategory, setFilteredImageCategory] = useState(null);
  const [selectedImageCategory, setSelectedImageCategory] = useState(null);
  const [selectedImageFacing, setSelectedImageFacing] = useState(null);
  const [filteredImageFacing, setFilteredImageFacing] = useState(null);
  const [selectedProductListId, setSelectedProductListId] = useState(parseInt(productListId));
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [showOnlyUntagged, setShowOnlyUntagged] = useState(true);
  const [showAIMLSuggestions, setShowAIMLSuggestions] = useState(false);

  useEffect(() => {
    setSelectedProductListId(parseInt(productListId));
    setSelectedImageCategory(null);
    setSelectedImageFacing(null);
    setFilteredImageCategory(null);
    setFilteredImageFacing(null);
  }, [productListId]);

  const {
    data: { total_image_count } = {},
    loading: loadingCount,
    refetch: refechCount,
  } = useGet(
    getURL(PRODUCT_LIST_IMAGE_FOR_TAGGING, { product_list_id: selectedProductListId }),
    {
      category_id: filteredImageCategory?.value,
      facing: filteredImageFacing || null,
      show_only_untagged: showOnlyUntagged,
      show_ai_suggestions: showAIMLSuggestions,
      count_only: true,
    },
    !selectedProductListId,
  );

  const {
    data: { images } = {},
    loading,
    refetch,
  } = useGet(
    getURL(PRODUCT_LIST_IMAGE_FOR_TAGGING, { product_list_id: selectedProductListId }),
    {
      limit: PRODUCTS_PER_PAGE,
      offset: currentPage * PRODUCTS_PER_PAGE,
      category_id: filteredImageCategory?.value,
      facing: filteredImageFacing || null,
      show_only_untagged: showOnlyUntagged,
      show_ai_suggestions: showAIMLSuggestions,
    },
    !selectedProductListId,
  );

  const { postData: tagImages, loading: updating } = usePost(
    PRODUCT_IMAGES,
    () => {
      refetch();
      refechCount();
      Swal.fire({
        title: 'Images Tagged Successfully',
        icon: 'success',
        position: 'center',
      }).then(() => {
        setSelectedImageIds([]);
        setSelectedImageFacing(null);
        if (!showAIMLSuggestions) {
          setSelectedImageCategory(null);
        }
      });
    },
    () => {
      Swal.fire({
        title: 'Error Tagging Images',
        icon: 'error',
        position: 'center',
      });
    },
  );

  useEffect(() => {
    if (!showOnlyUntagged) {
      setShowAIMLSuggestions(false);
    }
  }, [showOnlyUntagged]);

  useEffect(() => {
    if (showAIMLSuggestions) {
      setSelectedImageCategory(filteredImageCategory);
    }
  }, [filteredImageCategory, showAIMLSuggestions]);

  useEffect(() => {
    setSelectedImageIds([]);
  }, [images]);

  return (
    <Container>
      <div className="d-flex justify-content-between">
        <h3 className="font-weight-bold">Image Tagger</h3>
        <div className="mr-5">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
          <a href={IMAGE_TAGGER_HELP_GUIDE_URL} target="_blank" rel="noreferrer">
            Help Guide
          </a>
        </div>
      </div>
      <Row className={classNames(css(styles.fixedPane), 'mt-3 px-4')}>
        <Col>
          <ImageFilterPanel
            selectedImageCategory={filteredImageCategory}
            setSelectedImageCategory={option => {
              setFilteredImageCategory(option);
              setCurrentPage(0);
            }}
            selectedImageFacing={filteredImageFacing}
            setSelectedImageFacing={option => {
              setFilteredImageFacing(option);
              setCurrentPage(0);
            }}
            selectedProductListId={selectedProductListId}
            setSelectedProductListId={setSelectedProductListId}
            showOnlyUntagged={showOnlyUntagged}
            setShowOnlyUntagged={setShowOnlyUntagged}
            showAIMLSuggestions={showAIMLSuggestions}
            setShowAIMLSuggestions={setShowAIMLSuggestions}
          />
        </Col>
        <Col className={css(styles.imageTaggingPane)}>
          <ImageTaggingPanel
            selectedImageCategory={selectedImageCategory}
            setSelectedImageCategory={setSelectedImageCategory}
            selectedImageFacing={selectedImageFacing}
            setSelectedImageFacing={setSelectedImageFacing}
            onsubmit={() => {
              if (selectedImageIds.length === 0) {
                Swal.fire({
                  title: 'Select Images to Tag',
                  icon: 'warning',
                  position: 'center',
                });
                return;
              }
              tagImages({
                image_ids: selectedImageIds,
                category_attribute_id: selectedImageCategory?.value,
                facing: selectedImageFacing,
              });
            }}
            disabled={selectedImageIds.length === 0 || loading || updating}
          />
        </Col>
      </Row>
      <Row className={classNames(css(styles.scrollPane), 'px-4')}>
        <Col>
          <ImagesSection
            totalPages={Math.ceil(total_image_count / PRODUCTS_PER_PAGE)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            loadingCount={loadingCount}
            images={images}
            selectedImageIds={selectedImageIds}
            onSelectAll={checked => {
              if (checked) {
                setSelectedImageIds(images.map(image => image.id));
                return;
              }
              setSelectedImageIds([]);
            }}
            onImageClick={image => {
              if (selectedImageIds.includes(image.id)) {
                setSelectedImageIds(selectedImageIds.filter(id => id !== image.id));
                return;
              }
              setSelectedImageIds([...selectedImageIds, image.id]);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  fixedPane: {
    position: 'sticky',
    top: 0,
    zIndex: 4,
    backgroundColor: 'white',
  },
  scrollPane: {
    height: 'calc(100vh - 250px)',
    overflowY: 'auto',
  },
});

export default ImageTaggerView;
