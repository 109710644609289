import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import ActivityLogTypes from 'components/settings/activity_logs/ActivityLogTypesEnum';
import { ALL_SELECT_OPTION } from 'lib/constants';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import { USERS_GET_FOR_DROPDOWN } from 'lib/networking/endpoints';

function ActivityLogFilters({
  selectedUser,
  setSelectedUser,
  selectedActivityType,
  setSelectedActivityType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const activityTypeOptions = [
    ALL_SELECT_OPTION,
    ...Object.keys(ActivityLogTypes).map(type => ({
      label: type,
      value: type,
    })),
  ];

  return (
    <div className="mr-2">
      <Row className="mb-3 d-flex">
        <Col>
          <Form.Label>Select User</Form.Label>
          <SearchableScrollableSelect
            initOption={selectedUser}
            onChange={option => setSelectedUser(option)}
            fetchUrl={USERS_GET_FOR_DROPDOWN}
            optionMapper={user => ({ label: user.name, value: user.id })}
            placeholder="Select user"
            autoFocus={false}
          />
        </Col>
        <Col>
          <Form.Label>Activity Type</Form.Label>
          <Select
            isClearable
            options={activityTypeOptions}
            value={activityTypeOptions.find(
              activityType => activityType?.value === selectedActivityType?.value,
            )}
            placeholder="Select a activity type"
            onChange={setSelectedActivityType}
          />
        </Col>
        <Col>
          <Form.Label>Select Date Range</Form.Label>
          <div className="d-flex">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              customInput={<Form.Control />}
              placeholderText="Start Date"
              dateFormat="yyyy-MM-dd HH:mm"
            />
            <DatePicker
              className="ml-2"
              selected={endDate}
              onChange={date => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={<Form.Control />}
              placeholderText="End Date"
              dateFormat="yyyy-MM-dd HH:mm"
            />
          </div>
        </Col>
      </Row>
      <Row></Row>
    </div>
  );
}

ActivityLogFilters.propTypes = {
  selectedUser: PropTypes.object,
  setSelectedUser: PropTypes.func,
  selectedActivityType: PropTypes.object,
  setSelectedActivityType: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
  endDate: PropTypes.instanceOf(Date),
  setEndDate: PropTypes.func,
};

export default ActivityLogFilters;
