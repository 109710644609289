const API_INTEGRATION_TYPES = {
  SYNDIGO: 'SYNDIGO',
  GS1: 'GS1',
};

const API_INTEGRATION_OPTIONS = [
  { label: 'SYNDIGO', value: API_INTEGRATION_TYPES.SYNDIGO },
  { label: 'GS1', value: API_INTEGRATION_TYPES.GS1 },
];

export { API_INTEGRATION_TYPES, API_INTEGRATION_OPTIONS };
