import React, { useContext, useState } from 'react';

import { Button, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AttributeRows from 'components/manage_attribute/AttributeView/AttributeRows';
import { ATTRIBUTES_BY_CATEGORY_LEVEL, ATTRIBUTES_MAPS_CREATE } from 'lib/networking/endpoints';
import { backgroundGrey2, darkGrey } from 'lib/css/colors';
import GlobalAttributeRows from 'components/manage_attribute/AttributeView/GlobalAttributeRows';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ManageAttributeContext } from 'components/manage_attribute/ManageAttributeContainer';
import AttributeAssignmentToTaxonomiesModal from 'components/manage_attribute/AttributeAssignmentToTaxonomiesModal';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function AssignAttributeTableSplitViewForAttributes() {
  const [showAttributesAssignModal, setShowAttributesAssignModal] = useState(false);

  const { categoryLevel, selectedCategories } = useContext(ManageAttributeContext);
  const {
    data: { attribute_maps = [], global_attributes = [] } = [],
    loading,
    refetch,
  } = useGet(ATTRIBUTES_BY_CATEGORY_LEVEL, {
    taxonomy_level: categoryLevel.value,
    category_id:
      selectedCategories?.length > 1
        ? [selectedCategories.map(cat => cat.id)]
        : selectedCategories[0]?.id || null,
    multi_category_ids: selectedCategories?.length > 1,
  });

  const { postData: createOrUpdateAttributeMaps, loading: creatingNewMulti } = usePost(
    ATTRIBUTES_MAPS_CREATE,
    () => {
      refetch();
      SwalDialog('success', 'Attributes created successfully', 'Success', 'center');
      setShowAttributesAssignModal(false);
    },
    error => {
      SwalDialog('error', error.response?.data?.message, 'An error occurred', 'center');
    },
  );

  const createAttribute = data => {
    createOrUpdateAttributeMaps({
      attribute_ids: data.map(attr => attr.value),
      category_details: selectedCategories,
      category_level: categoryLevel.value,
    });
  };

  if (loading || creatingNewMulti) {
    return <LoadingSpinner />;
  }

  return (
    <div className={classNames(css(styles.scorllableContainer), 'mx-4')}>
      <Table hover className="py-1">
        <thead className={classNames(css(styles.tableHeader), 'font-weight-bold')}>
          <tr>
            <td className="py-1 pl-4">
              {selectedCategories.length ? (
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="mr-2"
                  onClick={() => setShowAttributesAssignModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="px-0" color={darkGrey} />
                </Button>
              ) : null}
              Attribute Name
            </td>
            <td className="py-1">Input Type</td>
            <td className="py-1">Category</td>
            <td className="py-1">Sub Category</td>
            <td className="py-1">Applied Level</td>
          </tr>
        </thead>
        <tbody>
          <AttributeRows attributes={attribute_maps} refetch={refetch} />
          <GlobalAttributeRows globalAttributes={global_attributes} refetch={refetch} />
        </tbody>
      </Table>
      {showAttributesAssignModal ? (
        <AttributeAssignmentToTaxonomiesModal
          show={showAttributesAssignModal}
          onHide={() => setShowAttributesAssignModal(false)}
          modalHeader={
            selectedCategories.length > 1
              ? 'Assign Attributes For Multi Categories'
              : 'Assign Attribute For Single Category'
          }
          createAttribute={createAttribute}
          isCreatingAttributeMap={true}
          isGlobal={false}
        />
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  scorllableContainer: {
    height: '80vh',
    overflowY: 'scroll',
  },
  tableHeader: {
    backgroundColor: backgroundGrey2,
    borderRadius: 4,
    top: 0,
    zIndex: 1,
    position: 'sticky',
  },
});

export default AssignAttributeTableSplitViewForAttributes;
