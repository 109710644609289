import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

import { GLOBAL_VENDORS_FOR_DROPDOWN } from 'lib/networking/endpoints';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function DistributorFilter({
  updateSelectedVendorId,
  selectedOption,
  testVendorsOnly = false,
  label = 'Select Distributor',
}) {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <SearchableScrollableSelect
        initOption={selectedOption}
        onChange={option => updateSelectedVendorId(option)}
        fetchUrl={GLOBAL_VENDORS_FOR_DROPDOWN}
        optionMapper={vendor => ({ label: vendor.name, value: vendor.id })}
        otherRequestParams={{ test_vendors_only: testVendorsOnly }}
        autoFocus={false}
      />
    </Form.Group>
  );
}

DistributorFilter.propTypes = {
  updateSelectedVendorId: PropTypes.func,
  selectedOption: PropTypes.object,
  testVendorsOnly: PropTypes.bool,
  label: PropTypes.string,
};

export default DistributorFilter;
