import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ProductRow({ product, setRecommendationModalSettings }) {
  return (
    <tr>
      <td />
      <td>{product.sku}</td>
      <td>
        {
          <Link to={`/global-product-detail/${product.global_product_id}`}>
            {product.product_name}
          </Link>
        }
      </td>
      <td>{product.l4_category_name}</td>
      <td>
        <Button
          variant="link"
          onClick={() => setRecommendationModalSettings({ show: true, product: product })}
        >
          View Details
        </Button>
      </td>
    </tr>
  );
}

ProductRow.propTypes = {
  product: PropTypes.object,
  setRecommendationModalSettings: PropTypes.func,
};

export default ProductRow;
