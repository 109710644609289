import React from 'react';
import PropTypes from 'prop-types';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import dayjs from 'dayjs';

import { DATE_FORMAT_WITH_TIME } from 'lib/constants';
import { DELETE_TASK, EXECUTE_TASK, KILL_TASK } from 'lib/networking/endpoints';
import { SwalToast } from 'lib/utils/toast';
import { TaskStatus } from 'lib/enums/TaskEnums';
import useDelete from 'lib/hooks/useDelete';
import usePost from 'lib/hooks/usePost';

function TaskTableRow({ task, refetch }) {
  const { deleteData: deleteTask } = useDelete(
    DELETE_TASK,
    false,
    () => {
      SwalToast('success', 'Successfully deleted the task');
      refetch();
    },
    () => SwalToast('error', `Could not delete the task`),
  );

  const { deleteData: killProcessingTask } = useDelete(
    KILL_TASK,
    false,
    () => {
      SwalToast('success', 'Successfully stopped the task');
      refetch();
    },
    () => SwalToast('error', `Could not stop the task`),
  );

  const { postData: executeTask } = usePost(
    EXECUTE_TASK,
    false,
    () => {
      SwalToast('success', 'Successfully executed the task');
      refetch();
    },
    () => SwalToast('error', `Could not execute the task`),
  );

  const toolTip = task => (
    <Tooltip>
      <div>
        {Object.entries(task).map(([key, value]) => (
          <div key={key}>
            <strong>{key}: </strong>
            <span>{JSON.stringify(value)}</span>
          </div>
        ))}
        {task.id}
      </div>
    </Tooltip>
  );

  return (
    <tr key={task.id}>
      <td>
        <OverlayTrigger overlay={toolTip(task)} placement="right">
          <span>{task.id}</span>
        </OverlayTrigger>
      </td>
      <td>{dayjs(task.date_created).format(DATE_FORMAT_WITH_TIME)}</td>
      <td>{`${task.task_type}(${task.class_name})`}</td>
      <td>{task.method_args?.data_source}</td>
      <td>{task.pid}</td>
      <td>{task.instance_id}</td>
      <td>{task.status}</td>
      <td className="d-flex justify-content-center">
        <Button
          disabled={TaskStatus.QUEUED !== task.status}
          className="mr-1"
          onClick={() => executeTask({ task_id: task.id, by_pass_health_status: true })}
        >
          Execute
        </Button>
        <Button
          disabled={TaskStatus.PROCESSING === task.status}
          variant="danger"
          className="mr-1"
          onClick={() => deleteTask({ task_id: task.id })}
        >
          Delete
        </Button>
        <Button
          disabled={task.status !== TaskStatus.PROCESSING && task.pid}
          variant="outline-primary"
          onClick={() => killProcessingTask({ task_id: task.id })}
        >
          Kill
        </Button>
      </td>
    </tr>
  );
}

TaskTableRow.propTypes = {
  task: PropTypes.object,
  refetch: PropTypes.func,
};

export default TaskTableRow;
