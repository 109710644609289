import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Col, Row, Spinner } from 'react-bootstrap';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ceil } from 'lodash';
import Select from 'react-select';
import ToggleSwitch from 'react-switch';
import { useDebounce } from 'use-debounce';
import { useHistory, useParams } from 'react-router-dom';

import ClearableInput from 'components/shared/ClearableInput';
import Container from 'components/shared/Container';
import { getURL, PRODUCT_LIST } from 'lib/networking/endpoints';
import { grape } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationBar from 'components/shared/PaginationBar';
import pointerOnHover from 'lib/css/pointerOnHover';
import QAProductListCatalogView from 'components/product_lists/product_list/qa_product_list/QAProductListCatalogView';
import QAProductListSidePanel from 'components/product_lists/product_list/qa_product_list/QAProductListSidePanel';
import { THUMBNAIL_SOURCE_OPTIONS } from 'lib/constants';
import useGet from 'lib/hooks/useGet';

const DEFAULT_PAGE_SIZE = 100;

function QAProductListView() {
  const { productListId } = useParams();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [showOnlyIncompleteProducts, setShowOnlyIncompleteProducts] = useState(true);
  const [selectedVendorCategory, setSelectedVendorCategory] = useState(null);
  const [selectedRosettaCategory, setSelectedRosettaCategory] = useState(null);
  const [skuQuery, setSkuQuery] = useState('');
  const [skuDebouncedQuery] = useDebounce(skuQuery, 500);
  const [useRosettaCategory, setUseRosettaCategory] = useState(false);
  const [thumbnailSource, setThumbnailSource] = useState(null);

  useEffect(() => {
    if (!useRosettaCategory) {
      setSelectedRosettaCategory(null);
    } else {
      setSelectedVendorCategory('');
    }
  }, [useRosettaCategory]);

  let productListFiltersForCount = {
    show_only_incomplete_products: showOnlyIncompleteProducts,
    vendor_category: selectedVendorCategory || '', // send "" instead of null
    with_propagated_details: true,
    sku_query_string: skuDebouncedQuery,
    l1_category_display_name: selectedRosettaCategory || '',
    thumbnail_source: thumbnailSource,
    with_details_for_qa_view: true,
  };

  let productListFilters = {
    offset: DEFAULT_PAGE_SIZE * (currentPage - 1),
    limit: DEFAULT_PAGE_SIZE,
    ...productListFiltersForCount,
  };
  if (!skuDebouncedQuery) {
    // When there is a sku query, we don't want to stage the loading data
    productListFilters = { ...productListFilters };
  }
  const {
    data: { data: productListCount } = {},
    loading: loadingCount,
    refetch: refetchCount,
  } = useGet(
    getURL(PRODUCT_LIST, {
      product_list_id: productListId,
    }),
    {
      count_only: true,
      ...productListFiltersForCount,
    },
    !productListId,
  );

  const {
    data: { data: productList } = {},
    loading,
    refetch,
  } = useGet(
    getURL(PRODUCT_LIST, {
      product_list_id: productListId,
    }),
    productListFilters,
    !productListId,
  );

  const products = productList?.products || [];
  const productCount = productListCount?.product_count || 0;

  if (!productListId) {
    return null;
  }

  return (
    <Container>
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mx-4')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/product-lists')}
        />
        QA - {productList?.name} Catalog
      </h3>

      <Row>
        <Col sm={3} className="mt-3">
          <QAProductListSidePanel
            selectedVendorCategory={selectedVendorCategory}
            setSelectedVendorCategory={setSelectedVendorCategory}
            selectedRosettaCategory={selectedRosettaCategory}
            setSelectedRosettaCategory={setSelectedRosettaCategory}
            productListId={productListId}
            useRosettaCategory={useRosettaCategory}
            setCurrentPage={setCurrentPage}
          />
        </Col>
        <Col>
          <div className="mr-5">
            <div className="d-flex justify-content-end flex-row align-items-center mb-3">
              <div className="text-right mr-2 text-muted d-flex align-items-center">
                {loadingCount ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-4" />
                ) : (
                  `${(currentPage - 1) * DEFAULT_PAGE_SIZE + 1}-${Math.min(
                    currentPage * DEFAULT_PAGE_SIZE,
                    productCount,
                  )} of ${productCount} Items`
                )}
              </div>
              <div className="w-25">
                <ClearableInput
                  type="text"
                  placeholder="Search by SKU"
                  value={skuQuery}
                  onChange={e => {
                    setSkuQuery(e.target.value);
                    setCurrentPage(1);
                  }}
                  onClear={() => {
                    setSkuQuery('');
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end flex-row align-items-center mb-3">
              <div className="mx-3 d-flex align-items-center">
                <span className="text-secondary">Show Only Incomplete Data</span>
                <ToggleSwitch
                  className="ml-2"
                  onColor={grape}
                  checked={showOnlyIncompleteProducts}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={e => {
                    setShowOnlyIncompleteProducts(e);
                    setCurrentPage(1);
                  }}
                  disabled={loading}
                />
              </div>
              <div className="mx-3 d-flex align-items-center">
                <span className="text-secondary">Use Rosetta Categories</span>
                <ToggleSwitch
                  className="ml-2"
                  onColor={grape}
                  checked={useRosettaCategory}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={e => setUseRosettaCategory(e)}
                  disabled={loading}
                />
              </div>
              <div className="ml-3 d-flex align-items-center">
                <span className="text-secondary">Thumbnail Source</span>
                <Select
                  className={classNames(css(styles.dropDownSelector), 'ml-2')}
                  isDisabled={loading}
                  options={THUMBNAIL_SOURCE_OPTIONS}
                  value={THUMBNAIL_SOURCE_OPTIONS.find(option => option.value === thumbnailSource)}
                  onChange={option => setThumbnailSource(option?.value)}
                  placeholder={loading ? 'Thumbnail source...' : ''}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center flex-column align-items-center">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <QAProductListCatalogView
                products={products}
                refetch={() => {
                  refetch();
                  refetchCount();
                }}
              />
            )}
            {!loading && (
              <div className="my-4">
                <PaginationBar
                  totalPages={ceil(productCount / DEFAULT_PAGE_SIZE)}
                  currentPageNumber={currentPage - 1}
                  onPageChange={newPage => setCurrentPage(newPage + 1)}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  dropDownSelector: {
    minWidth: '230px',
  },
});

export default QAProductListView;
