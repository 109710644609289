import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { DATE_FORMAT } from 'lib/constants';
import { getURLWithSearchParams } from 'lib/networking/endpoints';
import pointerOnHover from 'lib/css/pointerOnHover';
import { ProductType } from 'lib/enums/ProductType';
import ProductUpdateStatusBadge from 'components/product_updates/ProductUpdateStatusBadge';

function ProductUpdatesTableRow({ changeLogGroup }) {
  const history = useHistory();
  return (
    <tr
      onClick={() => {
        const url = getURLWithSearchParams('/product-updates/details', {
          requestId: changeLogGroup.request_id,
          productId: changeLogGroup.product_id,
          productType: changeLogGroup.manufacturer_name
            ? ProductType.MANUFACTURER
            : changeLogGroup.vendor_name
            ? ProductType.DISTRIBUTOR
            : null,
        });
        history.push(url);
      }}
      className={pointerOnHover}
    >
      <td>{dayjs(changeLogGroup.date_created).format(DATE_FORMAT)}</td>
      <td>{changeLogGroup.sku}</td>
      <td>{changeLogGroup.product_name}</td>
      <td>{changeLogGroup.manufacturer_name || changeLogGroup.vendor_name}</td>
      <td>{changeLogGroup.change_log_ids.split(',').length}</td>
      <td>
        <ProductUpdateStatusBadge status={changeLogGroup.status} />
      </td>
    </tr>
  );
}

ProductUpdatesTableRow.propTypes = {
  changeLogGroup: PropTypes.object,
};

export default ProductUpdatesTableRow;
