import React from 'react';
import PropTypes from 'prop-types';

import { Button, Spinner } from 'react-bootstrap';

import { DATA_SOURCE_RUN_URL, getURL } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
import pointerOnHover from 'lib/css/pointerOnHover';

function DataSourceRunURLField({
  dataSourceRunId,
  deltaFile = false,
  label = 'Download Source File',
}) {
  const { data: { data: presignedUrl } = {}, loading } = useGet(
    getURL(DATA_SOURCE_RUN_URL, { data_source_run_id: dataSourceRunId }),
    { deltaFile },
  );

  if (loading) {
    return (
      <Spinner animation="border" role="status" size="sm">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (!presignedUrl) {
    return null;
  }

  return (
    <Button
      variant="link"
      className={pointerOnHover}
      onClick={() => (window.location.href = presignedUrl)}
      disabled={!presignedUrl}
    >
      {label}
    </Button>
  );
}

DataSourceRunURLField.propTypes = {
  dataSourceRunId: PropTypes.number,
  deltaFile: PropTypes.bool,
  label: PropTypes.string,
};

export default DataSourceRunURLField;
