import React, { useContext, useEffect, useState } from 'react';

import { Col, Row, Table } from 'react-bootstrap';

import {
  ALL_ATTRIBUTES,
  ATTRIBUTE,
  ATTRIBUTE_MERGE,
  GLOBAL_ATTRIBUTE,
  UPDATE_ATTRIBUTES_BY_ATTRIBUTE_ID,
} from 'lib/networking/endpoints';
import { ATTRIBUTE_UPDATE_SAMPLE_FILE } from 'lib/constants';
import Container from 'components/shared/Container';
import FileUploadModal from 'components/shared/FileUploadModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageAttributesFilterPanel from 'components/manage_attribute_value/ManageAttributesFilterPanel';
import { ManageAttributeValueContext } from 'components/manage_attribute_value/ManageAttributeValueContainer';
import ManageAttributesViewTableRow from 'components/manage_attribute_value/ManageAttributesViewTableRow';
import NewAttributeModal from 'components/manage_attribute/NewAttributeModal';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import useGet from 'lib/hooks/useGet';

function ManageAttributesView() {
  const [sortBy, setSortBy] = useState({ column: 'attribute_name', direction: 'asc' });
  const [showNewGlobalAttributesModal, setShowNewGlobalAttributeModal] = useState(false);
  const [showNewAttributesModal, setShowNewAttributesModal] = useState(false);
  const [showAttributeUpdateModal, setShowAttributeUpdateModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]); // ['3_global_attribute', '4_attribute']
  const { setAttributes, filteredAttributes, setFilteredAttributes } = useContext(
    ManageAttributeValueContext,
  );
  const { data: allAttributes, loading } = useGet(ALL_ATTRIBUTES);

  useEffect(() => {
    if (allAttributes) {
      setAttributes(
        allAttributes.map(attr => ({
          ...attr,
          attribute_name: attr.attribute_name || attr.global_attribute_name,
        })),
      );
      setFilteredAttributes(allAttributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAttributes]);

  const { setSelectedAttribute } = useContext(ManageAttributeValueContext);

  const { postData: creactAttribute, loading: creatingAttribute } = usePost(
    ATTRIBUTE,
    res => {
      SwalDialog('success', 'Attribute created successfully', 'Success', 'center');
      setShowNewAttributesModal(false);
      setSelectedAttribute({ attribute_name: res.data.attribute_name, id: res.data.id });
    },
    error => {
      SwalDialog('error', error?.response?.data?.message, 'Error', 'center');
    },
  );

  const { postData: createGlobalAttribute, loading: creatingNewGlobal } = usePost(
    GLOBAL_ATTRIBUTE,
    res => {
      SwalDialog('success', 'Attribute created successfully', 'Success', 'center');
      setShowNewGlobalAttributeModal(false);
      setSelectedAttribute({
        global_attribute_name: res.data.global_attribute_name,
        id: res.data.id,
      });
    },
    error => {
      SwalDialog('error', error?.response?.data?.message, 'Error', 'center');
    },
  );

  const { postData: mergeAttribute, loading: mergingAttribute } = usePost(
    ATTRIBUTE_MERGE,
    () => {
      SwalDialog('success', 'Attribute merging process started', 'Success', 'center');
      setSelectedIds([]);
    },
    error => {
      SwalDialog('error', error.response?.data?.message, 'Error', 'center');
    },
  );

  const { postData: updateAttributes } = usePost(
    UPDATE_ATTRIBUTES_BY_ATTRIBUTE_ID,
    () => {
      SwalDialog('success', 'update started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during the update.', 'Error', 'center');
    },
  );

  // create a new attribute
  const createNewAttribute = data => {
    creactAttribute({
      attribute_name: data.newAttribute,
      attribute_display_name: data.attributeDisplayName,
      attribute_sub_section_id: data.attributeSubSection.value,
      attribute_sub_section_name: data.attributeSubSection.label,
      attribute_section_id: data.attributeSection.value,
      attribute_section_name: data.attributeSection.label,
      input_type: data.inputType,
      universal_specific_field: data.universalSpecificField,
      fsa_attribute_id: data.fsaAttributeId,
      usda_code: data.USDACode,
      visibility: data.isVisible,
      mandatory: data.isMandatory,
      schema_ids: data?.schemas?.map(schema => schema.value),
    });
  };

  // create a new global attribute
  const createNewGlobalAttribute = data => {
    createGlobalAttribute({
      global_attribute_name: data.newAttribute,
      attribute_display_name: data.attributeDisplayName,
      mandatory: data.isMandatory,
      visibility: data.isVisible,
      key_attribute: data.keyAttribute,
      priority_level: data.priorityLevel.value,
      attribute_section_id: data.attributeSection.value,
      attribute_section_name: data.attributeSection.label,
      attribute_sub_section_id: data.attributeSubSection.value,
      attribute_sub_section_name: data.attributeSubSection.label,
      input_type: data.inputType,
      universal_specific_field: data.universalSpecificField,
      fsa_attribute_id: data.fsaAttributeId,
      usda_code: data.USDACode,
      schema_ids: data?.schemas?.map(schema => schema.value),
    });
  };

  const mergeSelectedAttributes = () => {
    if (selectedIds.length > 2 || selectedIds.length < 2) {
      SwalDialog('warning', 'Please select two attributes to merge at a time', 'Warning', 'center');
    } else {
      const isGlobalAttribute = selectedIds.every(attr => attr.includes('global_attribute'));
      const isAttribute = selectedIds.every(attr => !attr.includes('global_attribute'));
      if (!isGlobalAttribute && !isAttribute) {
        SwalDialog(
          'warning',
          'Please select both from attributes or from global attributes',
          'Warning',
          'center',
        );
      } else {
        mergeAttribute({
          parent_attribute_id: selectedIds[0].split('_')[0],
          attribute_id: selectedIds[1].split('_')[0],
          is_global_attribute: isGlobalAttribute,
        });
      }
    }
  };

  const getUniqueKey = attribute => {
    return `${attribute.id}_${
      'global_attribute_name' in attribute ? 'global_attribute' : 'attribute'
    }`;
  };

  // Function to handle sorting when a table header is clicked
  const handleSort = column => {
    if (sortBy.column === column) {
      // If already sorted by this column, toggle the direction
      setSortBy({
        column,
        direction: sortBy.direction === 'asc' ? 'desc' : 'asc',
      });
    } else {
      // If sorting by a new column, set the new column and default direction to 'asc'
      setSortBy({ column, direction: 'asc' });
    }
  };

  // Sort the attributes based on the current sorting criteria
  const sortedAttributes = filteredAttributes.sort((a, b) => {
    if (sortBy.column) {
      const aValue = a[sortBy.column] || '';
      const bValue = b[sortBy.column] || '';
      if (sortBy.direction === 'asc') {
        return aValue.localeCompare(bValue);
      } else {
        return bValue.localeCompare(aValue);
      }
    } else {
      return 0; // Default: retain the original order
    }
  });

  const viewLoading = loading || creatingAttribute || creatingNewGlobal || mergingAttribute;

  return (
    <Container>
      <Row>
        <Col className="mt-2 d-flex">
          <h3 className="font-weight-bold">Manage Attributes</h3>
        </Col>
      </Row>
      <ManageAttributesFilterPanel
        setShowNewGlobalAttributeModal={setShowNewGlobalAttributeModal}
        setShowNewAttributesModal={setShowNewAttributesModal}
        setShowAttributeUpdateModal={setShowAttributeUpdateModal}
        mergeSelectedAttributes={mergeSelectedAttributes}
      />
      <Row>
        <Col className="font-weight-bold ml-3 mb-3">{`Count : ${sortedAttributes.length}`}</Col>
      </Row>
      {viewLoading ? <LoadingSpinner /> : null}
      <Table hover responsive>
        <thead className={pointerOnHover}>
          <tr className="d-flex">
            <th className="col-2" onClick={() => handleSort('attribute_name')}>
              Name {sortBy.column === 'attribute_name' && (sortBy.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th className="col-1">ID</th>
            <th className="col-1">Category</th>
            <th className="col-2">Sub Category</th>
            <th className="col-2">Display Name</th>
            <th className="col-1">Type</th>
            <th className="col-1">Status</th>
            <th className="col-1">Visibility</th>
            <th className="col-1">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedAttributes.map(attribute => (
            <ManageAttributesViewTableRow
              key={getUniqueKey(attribute)}
              attribute={attribute}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              getUniqueKey={getUniqueKey}
            />
          ))}
        </tbody>
      </Table>
      {showNewGlobalAttributesModal ? (
        <NewAttributeModal
          onHide={() => setShowNewGlobalAttributeModal(false)}
          modalHeader="Add New Global Attribute"
          createAttribute={createNewGlobalAttribute}
        />
      ) : null}
      {showNewAttributesModal ? (
        <NewAttributeModal
          onHide={() => setShowNewAttributesModal(false)}
          modalHeader="Add New Attribute"
          createAttribute={createNewAttribute}
          isCreatingAttributeMap={false}
          isGlobal={false}
        />
      ) : null}
      {showAttributeUpdateModal ? (
        <FileUploadModal
          onHide={() => setShowAttributeUpdateModal(false)}
          onSubmit={updateAttributes}
          header="Update Attributes"
          sample_file_url={ATTRIBUTE_UPDATE_SAMPLE_FILE}
        />
      ) : null}
    </Container>
  );
}

export default ManageAttributesView;
