import React from 'react';
import PropTypes from 'prop-types';

import { Badge, FormCheck, Image, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { danger, greyscale, lighterGrey } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationBar from 'components/shared/PaginationBar';

function ImagesSection({
  loading,
  loadingCount,
  images,
  onImageClick,
  selectedImageIds = [],
  onSelectAll,
  totalPages,
  currentPage,
  setCurrentPage,
}) {
  if (loading) {
    return <LoadingSpinner />;
  }

  if (!images || images.length === 0) {
    return (
      <div className="d-flex align-items-center justify-content-center mt-5">No images found</div>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <FormCheck
          id="select-all-checkbox"
          type="checkbox"
          label="Select All"
          checked={selectedImageIds.length === images.length}
          onChange={e => onSelectAll(e.target.checked)}
        />
        <div className="ml-3">Total selected : {selectedImageIds.length}</div>
        <div className="ml-auto">
          {loadingCount ? (
            <Spinner animation="border" role="status" size="sm" />
          ) : (
            <PaginationBar
              totalPages={totalPages}
              currentPageNumber={currentPage}
              onPageChange={page => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
      <div className={css(styles.imagesSection)}>
        {images.map((image, index) => (
          <div
            key={index}
            className={css(
              styles.imageContainer,
              selectedImageIds.includes(image.id) ? styles.selectedImage : '',
            )}
          >
            <div className={css(styles.imageTagContainer)}>
              <Badge className={css(styles.imageTag)} variant="secondary">
                {image.category}
              </Badge>
              <Badge className={css(styles.imageTag)} variant="info">
                {image.facing}
              </Badge>
            </div>
            <div className="h-100 d-flex justify-content-center align-items-center">
              <Image
                className={css(styles.image)}
                fluid
                src={image.url}
                alt={image.url}
                onClick={() => onImageClick(image)}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  imagesSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageContainer: {
    width: 200,
    height: 200,
    margin: 4,
    cursor: 'pointer',
    ':hover': {
      opacity: 0.7,
    },
    border: '1px solid ' + lighterGrey,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    padding: 5,
  },
  selectedImage: {
    border: '4px solid ' + danger,
  },
  imageTagContainer: {
    padding: 5,
    position: 'absolute',
  },
  imageTag: {
    margin: 2,
  },
  paginationText: {
    fontSize: 12,
    color: greyscale,
    marginLeft: 10,
  },
});

ImagesSection.propTypes = {
  loading: PropTypes.bool,
  loadingCount: PropTypes.bool,
  images: PropTypes.array,
  onImageClick: PropTypes.func,
  selectedImageIds: PropTypes.array,
  onSelectAll: PropTypes.func,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};

export default ImagesSection;
