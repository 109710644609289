import React, { useEffect, useState } from 'react';

import { Col, Form, Row, Table } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';

import Container from 'components/shared/Container';
import { DATA_SOURCE_ALL } from 'lib/networking/endpoints';
import DataSourcePendingAttributeViewRow from 'components/pending_attribute_mapping/DataSourcePendingAttributeViewRow';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function DataSourcePendingAttributeView() {
  const [searchString, setSearchString] = useState('');
  const [searchQuery] = useDebounce(searchString, 400);
  const [filteredDataSources, setFilteredDataSources] = useState([]);

  const {
    data: { data: dataSources = [] } = {},
    loading,
    error,
  } = useGet(DATA_SOURCE_ALL, {
    search_term: searchQuery,
    limit: 500, // Until pagination is implemented
    exclude_recurring_data_sources: false,
    include_pending_attributes_mapping: true,
  });

  useEffect(() => {
    if (dataSources && dataSources.length > 0) {
      setFilteredDataSources(
        dataSources.filter(dataSource => dataSource.unmapped_attributes.length > 0),
      );
    }
  }, [dataSources]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  const handleSearch = event => {
    setSearchString(event.target.value);
    if (event.target.value) {
      const oneTimeSources = filteredDataSources?.filter(dataSource =>
        dataSource.source_name.toLowerCase().includes(searchString.toLowerCase()),
      );
      setFilteredDataSources(oneTimeSources);
    } else {
      setFilteredDataSources([...filteredDataSources]);
    }
  };

  const tableHeaders = (
    <thead>
      <tr>
        <th>Source Name</th>
        <th>Source Type</th>
        <th>Entity Type</th>
        <th>Pending Attributes</th>
        <th>Mapped Attributes</th>
        <th>Actions</th>
      </tr>
    </thead>
  );

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="font-weight-bold">Pending Attribute Mappings</h3>
        </Col>
      </Row>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Control
              onChange={handleSearch}
              type="text"
              placeholder="Search Data Source"
              value={searchString}
            />
          </Form.Group>
        </Col>
      </Row>

      <Table hover className="mt-0">
        {tableHeaders}
        <tbody>
          {filteredDataSources?.map(record => (
            <DataSourcePendingAttributeViewRow dataSourceRecord={record} key={record.id} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default DataSourcePendingAttributeView;
