import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';

import { AuthContext } from 'components/providers/AuthContextProvider';
import { NAVIGATION_SIDE_BAR_WIDTH } from 'lib/constants';
import { backgroundGrey3, deeplavender, greyscale } from 'lib/css/colors';
function SideNavItem({ url, title, icon }) {
  const { logout } = useContext(AuthContext);

  const handleClick = () => {
    if (title === 'Sign Out') {
      logout();
    }
  };

  return (
    <LinkContainer to={url} className={css(styles.link)} activeClassName={css(styles.activeLink)}>
      <Nav.Link onClick={handleClick}>
        <div className={css(styles.iconContainer)}>
          <FontAwesomeIcon size="lg" icon={icon} />
          <div className={css(styles.textWrapper)}>{title}</div>
        </div>
      </Nav.Link>
    </LinkContainer>
  );
}

const styles = StyleSheet.create({
  activeLink: {
    backgroundColor: backgroundGrey3,
    color: deeplavender,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.4rem',
    color: greyscale,
    maxWidth: NAVIGATION_SIDE_BAR_WIDTH - 4,
  },
  textWrapper: {
    fontSize: '10px',
  },
});

SideNavItem.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
};

export default SideNavItem;
