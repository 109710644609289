import React, { createContext, useState } from 'react';

import { Route } from 'react-router-dom';

import ProductListExportView from 'components/product_lists/product_export/ProductListExportView';

const ProductListExportContext = createContext({});

function ProductListExportContainer() {
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState(addUniqueKey([]));
  const [isAGlobalAttribute, setIsAGlobalAttribute] = useState();

  function addUniqueKey(objects) {
    return objects.map(obj => ({
      ...obj,
      uniqueKey: `${obj.attributeName}_${obj.isGlobalAttribute}`,
    }));
  }

  return (
    <ProductListExportContext.Provider
      value={{
        attributes,
        setAttributes,
        selectedAttributes,
        setSelectedAttributes,
        isAGlobalAttribute,
        setIsAGlobalAttribute,
      }}
    >
      <Route path="/product-lists/:productListId/product-list-export" exact>
        <ProductListExportView />
      </Route>
    </ProductListExportContext.Provider>
  );
}

export default ProductListExportContainer;
export { ProductListExportContext };
