import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, FormControl, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getURL,
  TAXONOMY_DELETE_CATEGORY,
  TAXONOMY_EDIT_CATEGORY_NAME,
} from 'lib/networking/endpoints';
import SwalDialog, { areYouSure } from 'lib/utils/toast';
import pointerOnHover from 'lib/css/pointerOnHover';
import usePut from 'lib/hooks/usePut';
import useDelete from 'lib/hooks/useDelete';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';

function TaxonomyTableCell({
  category,
  canAddNew,
  canEdit,
  canDelete,
  openCreateModal,
  textCSS,
  taxonomyLevel,
  setCurrentPage,
  setCategoryModified,
}) {
  const categoryName = category?.category_name;

  const [editable, setEditable] = useState(false);
  const [textValue, setTextValue] = useState(categoryName);

  const { putData: editCategoryName } = usePut(
    TAXONOMY_EDIT_CATEGORY_NAME,
    () => {
      setCurrentPage(1);
      setCategoryModified(true);
      SwalDialog('success', 'Successfully updated category name', 'Success');
    },
    error => SwalDialog('error', error?.response?.data?.message, 'Error'),
  );

  const { deleteData: deleteCategory } = useDelete(
    getURL(TAXONOMY_DELETE_CATEGORY, { category_level: taxonomyLevel, category_id: category?.id }),
    false,
    () => {
      setCurrentPage(1);
      setCategoryModified(true);
      SwalDialog('success', 'Successfully deleted the category', 'Success');
    },
    error => SwalDialog('error', error?.response?.data?.message, 'Error'),
  );

  const handleCreateNew = e => {
    e.stopPropagation();
    openCreateModal();
  };

  const handleDelete = e => {
    e.stopPropagation();
    areYouSure(
      () => deleteCategory({}),
      `Are you sure you want to delete this L${taxonomyLevel} category?${
        taxonomyLevel === TaxonomyLevel.L4 ? '' : ' All child categories will be deleted.'
      }`,
    );
  };

  const renderTooltip = props => (
    <Tooltip {...props}>{`${categoryName} | ${category.rosetta_id}`}</Tooltip>
  );

  return (
    <td className={classNames(css(styles.cell), 'p-1 position-relative', pointerOnHover)}>
      {categoryName ? (
        editable ? (
          <InputGroup>
            <FormControl
              autoFocus
              className={classNames(textCSS, css(styles.textInput))}
              value={textValue}
              onChange={e => setTextValue(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  if (textValue) {
                    if (textValue !== categoryName) {
                      editCategoryName({
                        category_id: category.id,
                        category_name: textValue,
                        category_level: taxonomyLevel,
                      });
                    }
                  } else {
                    e.target.blur();
                  }
                }
                if (e.key === 'Escape') {
                  e.target.blur();
                }
              }}
              onBlur={() => {
                setTextValue(categoryName);
                setEditable(false);
              }}
            />
          </InputGroup>
        ) : (
          <OverlayTrigger placement="top" overlay={renderTooltip} transition={false}>
            <div
              className={classNames(textCSS, css(styles.textBox))}
              onDoubleClick={() => {
                if (canEdit) {
                  setEditable(true);
                }
              }}
            >
              {categoryName}
            </div>
          </OverlayTrigger>
        )
      ) : null}
      {canDelete ? (
        <Button
          variant="outline-secondary"
          className={classNames(css(styles.deleteButton), 'position-absolute')}
          onClick={handleDelete}
        >
          <FontAwesomeIcon icon={faTrashAlt} className="mx-1" />
        </Button>
      ) : null}
      {canAddNew ? (
        <Button
          variant="outline-secondary"
          className={classNames(css(styles.createNewButton), 'position-absolute')}
          onClick={handleCreateNew}
        >
          <FontAwesomeIcon icon={faPlus} className="mx-1" />
        </Button>
      ) : null}
    </td>
  );
}

const styles = StyleSheet.create({
  createNewButton: {
    padding: '0.05rem',
    fontSize: '0.6rem',
    bottom: 0,
    right: 0,
    borderColor: 'transparent',
  },
  deleteButton: {
    padding: '0.05rem',
    fontSize: '0.6rem',
    top: 0,
    right: 0,
    borderColor: 'transparent',
  },
  textBox: {
    maxHeight: 28,
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
  textInput: {
    height: '1.5rem',
    fontSize: '0.65rem',
    padding: '0.1rem',
    marginRight: '1.4rem',
  },
  editButton: {
    padding: '0.05rem',
    fontSize: '0.65rem',
    height: '1.5rem',
    width: '0.8rem',
  },
  cell: {
    paddingTop: '10px',
  },
});

TaxonomyTableCell.propTypes = {
  category: PropTypes.object,
  canAddNew: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  openCreateModal: PropTypes.func,
  textCSS: PropTypes.string,
  taxonomyLevel: PropTypes.string,
  setCurrentPage: PropTypes.func,
  setCategoryModified: PropTypes.func,
};

export default TaxonomyTableCell;
