import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';

import { DATE_FORMAT_WITH_TIME } from 'lib/constants';
import ProductUpdateStatusBadge from 'components/product_updates/ProductUpdateStatusBadge';

function ChangeLogRow({ changeLog }) {
  return (
    <tr>
      <td>{dayjs(changeLog.date_created).format(DATE_FORMAT_WITH_TIME)}</td>
      <td>{changeLog.user_type}</td>
      <td>
        <ProductUpdateStatusBadge status={changeLog.change_status} />
      </td>
      <td>{changeLog.user_email}</td>
    </tr>
  );
}

ChangeLogRow.propTypes = {
  changeLog: PropTypes.object,
};

export default ChangeLogRow;
