// update along with app/lib/enums/data_source_category.py
const DATA_SOURCE_CATEGORY = {
  CSV: 'CSV',
  S3_CSV: 'S3_CSV',
  GDSN: 'GDSN',
  GS1_S3_XML: 'GS1_S3_XML',
  GS1_CSV: 'GS1_CSV',
  JSON_FEED: 'JSON_FEED',
  S3_FOLDER: 'S3_FOLDER',
  SYNDIGO_API: 'SYNDIGO_API',
};

const DATA_SOURCE_CATEGORY_OPTIONS = [
  { value: DATA_SOURCE_CATEGORY.CSV, label: 'CSV' },
  { value: DATA_SOURCE_CATEGORY.S3_CSV, label: 'S3 CSV' },
  { value: DATA_SOURCE_CATEGORY.GDSN, label: 'GS1 Feed' },
  { value: DATA_SOURCE_CATEGORY.GS1_S3_XML, label: 'GS1 S3 XML' },
  { value: DATA_SOURCE_CATEGORY.GS1_CSV, label: 'GS1 CSV' },
  { value: DATA_SOURCE_CATEGORY.JSON_FEED, label: 'JSON FEED' },
  { value: DATA_SOURCE_CATEGORY.S3_FOLDER, label: 'S3 FOLDER' },
  { value: DATA_SOURCE_CATEGORY.SYNDIGO_API, label: 'Syndigo API' },
];

// update along with `get_lookback_days_required_category_types`
// in app/lib/enums/data_source_category.py
const LOOK_BACK_DAYS_REQUIRED_TYPES = [
  DATA_SOURCE_CATEGORY.GDSN,
  DATA_SOURCE_CATEGORY.GS1_S3_XML,
  DATA_SOURCE_CATEGORY.JSON_FEED,
  DATA_SOURCE_CATEGORY.S3_FOLDER,
];

export default DATA_SOURCE_CATEGORY;
export { DATA_SOURCE_CATEGORY_OPTIONS, LOOK_BACK_DAYS_REQUIRED_TYPES };
