import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { blackberry, white } from 'lib/css/colors';
import { Button, Form, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';

import SwalDialog from 'lib/utils/toast';
import { UPDATE_USER_ROLES } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function EditUserModal({ show, onHide, user, rolesOptions }) {
  const [name, setName] = useState('');
  const [role, setRole] = useState();

  const handleHide = () => {
    onHide();
  };

  useEffect(() => {
    if (user) {
      setName(user.name || '');
      setRole(user.role.name || '');
    }
  }, [user]);

  const { postData: updateUserRole } = usePost(
    UPDATE_USER_ROLES,
    () => {
      SwalDialog('success', 'User Roles updated successfully', 'Success', 'center', () =>
        window.location.reload(),
      );
    },
    () => {
      SwalDialog('error', 'An error occurred while updating user roles', 'Error', 'center');
    },
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? blackberry : white,
      '&:hover': {
        backgroundColor: white,
        color: white,
      },
    }),
  };

  return (
    <Modal show={show} onHide={handleHide} size="md" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>Edit Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control value={name} onChange={e => setName(e.target.value)} type="text" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control disabled={true} value={user?.email} type="text" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Select
            styles={customStyles}
            classNamePrefix="themed_select"
            value={rolesOptions.find(option => option.value === role)}
            onChange={role => setRole(role.value)}
            options={rolesOptions}
          />
        </Form.Group>
        <div className="d-flex mt-3 justify-content-left">
          <Button
            className={classNames(css(styles.updateButton), 'px-4')}
            onClick={() => {
              if (role) {
                updateUserRole({
                  user_roles_mapping: [{ user_id: user.id, role: role }],
                });
              }
            }}
          >
            Update
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
  updateButton: {
    backgroundColor: blackberry,
    borderColor: blackberry,
    color: white,
    fontWeight: 'bold',
  },
});

EditUserModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  user: PropTypes.object,
  rolesOptions: PropTypes.array,
};

export default EditUserModal;
