//Update along with app/lib/enums/similar_brand_names_status.py
const SimilarBrandNamesStatus = {
  NOT_REVIEWED: 'NOT_REVIEWED',
  FIRST_YES: 'FIRST_YES',
  FIRST_NO: 'FIRST_NO',
  SECOND_YES: 'SECOND_YES',
  SECOND_NO: 'SECOND_NO',
  TIED: 'TIED',
};

export { SimilarBrandNamesStatus };
