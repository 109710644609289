import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import SwalDialog from 'lib/utils/toast';
import { useHistory } from 'react-router-dom/';

import ConfirmationDeleteModal from 'components/shared/ConfirmationDeleteModal';
import { DELETE_SCHEMA, SCHEMA } from 'lib/networking/endpoints';
import pointerOnHover from 'lib/css/pointerOnHover';
import { purple, salmon } from 'lib/css/colors';
import useDelete from 'lib/hooks/useDelete';
import usePost from 'lib/hooks/usePost';
import usePut from 'lib/hooks/usePut';

function ManageSchemaRow({ schema, refetch, setEditableRow, newRecord = false }) {
  const [canEdit, setCanEdit] = useState(newRecord ? true : false);
  const [schemaName, setSchemaName] = useState(newRecord ? '' : schema?.schema_name);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const history = useHistory();

  const { postData: createNewSchema } = usePost(
    SCHEMA,
    () => {
      SwalDialog('success', 'Created successfully', 'Success', 'center');
      setEditableRow(false);
      refetch();
    },
    error => {
      SwalDialog('error', 'An error occurred', error.response?.data?.message, 'center');
    },
  );

  const { putData: updateSchema } = usePut(
    SCHEMA,
    () => {
      SwalDialog('success', 'Updated successfully', 'Success', 'center');
      refetch();
    },
    error => {
      SwalDialog('error', 'An error occurred', error.response?.data?.message, 'center');
    },
  );

  const { deleteData: deleteSchema } = useDelete(
    DELETE_SCHEMA,
    false,
    () => {
      SwalDialog('success', 'Deleted successfully', 'Success', 'center');
      refetch();
    },
    error => SwalDialog('error', 'An error occurred', error.response?.data?.message, 'center'),
  );

  return (
    <tr
      key={schema.id}
      onClick={e => (canEdit ? e.stopPropagation : history.push(`/manage-schema/${schema.id}`))}
      className={pointerOnHover}
    >
      <td>
        {canEdit ? (
          <Form.Control
            autoFocus
            type="text"
            defaultValue={schemaName}
            onChange={e => setSchemaName(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setCanEdit(false);
              }
            }}
          />
        ) : (
          schema.schema_name
        )}
      </td>
      <td>{schema.attribute_count}</td>
      <td onClick={e => e.stopPropagation()}>
        <Button
          variant="link"
          className={classNames(css(styles.deleteButton), 'mr-1 float-right')}
          onClick={() => setShowConfirmationModal(true)}
        >
          Delete
        </Button>
        <Button
          variant="link"
          className={classNames(css(styles.editButton), 'mr-1 float-right')}
          onClick={() => {
            if (canEdit) {
              if (newRecord) {
                createNewSchema({ schema_name: schemaName });
              } else {
                updateSchema({ schema_name: schemaName, schema_id: schema.id });
              }
            }
            setCanEdit(true);
          }}
        >
          {canEdit ? 'Done' : 'Edit'}
        </Button>
        {showConfirmationModal ? (
          <ConfirmationDeleteModal
            onHide={() => setShowConfirmationModal(false)}
            onSave={() => deleteSchema({ schema_id: schema.id })}
          />
        ) : null}
      </td>
    </tr>
  );
}

const styles = StyleSheet.create({
  deleteButton: {
    color: salmon,
  },
  editButton: {
    color: purple,
  },
});

ManageSchemaRow.propTypes = {
  schema: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  setEditableRow: PropTypes.func,
  newRecord: PropTypes.bool,
};

export default ManageSchemaRow;
