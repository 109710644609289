import React from 'react';

import { useParams } from 'react-router-dom';

import AttributeViewSection from 'components/manage_attribute_value/value_section/AttributeViewSection';
import Container from 'components/shared/Container';
import GlobalAttributeViewSection from 'components/manage_attribute_value/value_section/GlobalAttributeViewSection';

function ManageAttributeValueDetailsView() {
  const { attribute_type } = useParams();

  return (
    <Container className="mx-3">
      {attribute_type === 'attribute' ? <AttributeViewSection /> : <GlobalAttributeViewSection />}
    </Container>
  );
}

export default ManageAttributeValueDetailsView;
