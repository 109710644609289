import React from 'react';
import PropTypes from 'prop-types';

import { convertToTitleCase } from 'lib/utils/stringUtils';

function VendorDisplayField({ label, vendor }) {
  if (label === 'excluded_p0_sync_attributes') {
    return (
      <div>
        {vendor[label] ? (
          vendor[label].split(',').map((phrase, index) => <p key={index}>&bull; {phrase}</p>)
        ) : (
          <p>-</p>
        )}
      </div>
    );
  }

  if (label === 'rejected_brand_names' || label === 'chains') {
    return (
      <div>
        {vendor[label] ? (
          vendor[label].map((item, index) => <p key={index}>&bull; {item.name || item}</p>)
        ) : (
          <p>-</p>
        )}
      </div>
    );
  }

  if (label === 'vendor_category') {
    return <p>{convertToTitleCase(vendor[label]) || '-'}</p>;
  }

  return <p>{vendor[label] || '-'}</p>;
}

VendorDisplayField.propTypes = {
  label: PropTypes.string.isRequired,
  vendor: PropTypes.object.isRequired,
};

export default VendorDisplayField;
