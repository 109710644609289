import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function CategorizedDatePicker({ title, startDate, endDate, setDateRange }) {
  return (
    <th className="d-flex-column p-1">
      <div className={css(styles.label)}>{title}</div>
      <DatePicker
        className={classNames(css(styles.datePicker), 'form-control')}
        selectsRange
        isClearable={false}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        onChange={update => setDateRange(update)}
      />
    </th>
  );
}

const styles = StyleSheet.create({
  datePicker: {
    height: '1.5rem',
    width: '100%',
    fontSize: '0.75rem',
    padding: '0.1rem 0.2rem',
  },
  label: {
    wordBreak: 'break-word',
    maxHeight: '20px',
    overflow: 'hidden',
  },
});

CategorizedDatePicker.propTypes = {
  title: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setDateRange: PropTypes.func,
};

export default CategorizedDatePicker;
