import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import dayjs from 'dayjs';
import { faParking } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Image } from 'react-bootstrap';
import { uniq, without } from 'lodash';

import { anchovy } from 'lib/css/colors';
import { DATE_FORMAT_WITH_TIME } from 'lib/constants';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import RadioButton from 'components/shared/RadioButton';
import useImageDimensions from 'lib/hooks/useImageDimensions';

function AssetRow({ asset, setSelectedAssets, selectedAssets, isPropagated, isDeleted = false }) {
  const { size } = useImageDimensions(asset.url);
  const isChecked = !isDeleted && selectedAssets.includes(asset.id);
  const toggleCheck = () => {
    if (isChecked) {
      setSelectedAssets([...without(selectedAssets, asset.id)]);
    } else {
      setSelectedAssets([...uniq([...selectedAssets, asset.id])]);
    }
  };
  return (
    <tr key={asset.id} onClick={isPropagated || isDeleted ? null : toggleCheck}>
      {!isDeleted && (
        <td width="50">
          <RadioButton
            checked={isChecked}
            className="pr-0 mt-2 float-right"
            box
            small={true}
            onClick={toggleCheck}
            disabled={isPropagated}
          />
        </td>
      )}
      <td width="130">
        <div className="mx-4">
          <Image
            src={asset.url ?? placeholderProductImage}
            className="d-block"
            height="40" // Adjust the height to your desired value
            alt="Product"
            fluid
          />
        </div>
        <div className="text-center">
          <p className={classNames(css(styles.smallLabel), 'text-muted mb-0')}>
            {size && `${size.width} × ${size.height}`}
            {isPropagated && (
              <FontAwesomeIcon
                style={{ textAlign: 'center' }}
                className={classNames(css(styles.icon), 'ml-2')}
                icon={faParking}
              />
            )}
          </p>
          {asset.is_thumbnail ? (
            <span className="badge badge-pill badge-primary" style={{ textAlign: 'center' }}>
              Thumbnail
            </span>
          ) : null}
        </div>
      </td>
      <td>{asset.asset_type || '-'}</td>
      <td>{asset.category || '-'}</td>
      <td>{asset.facing || '-'}</td>
      <td>{asset.source || '-'}</td>
      <td>{asset.uploaded_by || '-'}</td>
      {isDeleted && <td>{asset.user_email || '-'}</td>}
      <td>{dayjs(asset.date_uploaded).format(DATE_FORMAT_WITH_TIME) || '-'}</td>
      <td>{dayjs(asset.date_modified).format(DATE_FORMAT_WITH_TIME) || '-'}</td>
    </tr>
  );
}

const styles = StyleSheet.create({
  smallLabel: {
    fontSize: '0.75rem',
  },
  icon: {
    color: anchovy,
  },
});

AssetRow.propTypes = {
  asset: PropTypes.object,
  setSelectedAssets: PropTypes.func,
  selectedAssets: PropTypes.array,
  isPropagated: PropTypes.bool,
  isDeleted: PropTypes.bool,
};

export default AssetRow;
