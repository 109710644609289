import React from 'react';
import PropTypes from 'prop-types';

import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import AttributesTableView from 'components/upb_viewer/pdp_components/AttributesTableView';

function TaxanomySection({ taxanomies }) {
  if (!taxanomies?.length) {
    return null;
  }
  return (
    <AttributeSection title="Taxonomies">
      <AttributesTableView attributes={taxanomies} />
    </AttributeSection>
  );
}

TaxanomySection.propTypes = {
  taxanomies: PropTypes.array,
};

export default TaxanomySection;
