import React from 'react';
import PropTypes from 'prop-types';

import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import AttributesTableView from 'components/upb_viewer/pdp_components/AttributesTableView';

function SpecificationsSection({ specifications, topOffAttributes }) {
  if (!specifications.length && !topOffAttributes.length) {
    return null;
  }
  return (
    <AttributeSection title="Specifications">
      <AttributesTableView attributes={[...specifications, ...topOffAttributes]} />
    </AttributeSection>
  );
}

SpecificationsSection.propTypes = {
  specifications: PropTypes.array,
  topOffAttributes: PropTypes.array,
};

export default SpecificationsSection;
