import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import pointerOnHover from 'lib/css/pointerOnHover';

function CardResyncButton({ reSync }) {
  return (
    <FontAwesomeIcon
      onClick={() => reSync()}
      spin={false}
      className={classNames('mt-1 text-primary float-right', pointerOnHover)}
      icon={faSync}
    />
  );
}

CardResyncButton.propTypes = {
  reSync: PropTypes.func,
};

export default CardResyncButton;
