import React from 'react';
import PropTypes from 'prop-types';

import ProductCatalogCard from 'components/product_lists/product_list/ProductCatalogCard';

function ProductListCatalogView({ products }) {
  return (
    <div className="d-flex flex-wrap justify-content-center">
      {products.length ? (
        products.map(product => (
          <ProductCatalogCard
            key={`${product.product_type}-${product.product_id}`}
            product={product}
          />
        ))
      ) : (
        <div>No products found for the given filters</div>
      )}
    </div>
  );
}

ProductListCatalogView.propTypes = {
  products: PropTypes.array,
};

export default ProductListCatalogView;
