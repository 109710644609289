import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { css, StyleSheet } from 'aphrodite';
import { Table } from 'react-bootstrap';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { DATE_FORMAT_WITH_TIME } from 'lib/constants';
import pointerOnHover from 'lib/css/pointerOnHover';

function ManageVendorsTable({ vendors }) {
  return (
    <Container>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>Vendor Name</th>
            <th>FSA ID</th>
            <th>Is a DP partner?</th>
            <th>Last Modified</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {vendors?.map((vendor, index) => (
            <tr
              key={index}
              className={pointerOnHover}
              onClick={() => {
                window.open(`/enrich-products/vendor-details/${vendor.id}`, '_blank');
              }}
            >
              <td>{vendor.name}</td>
              <td>{vendor.id}</td>
              <td>{vendor.is_dp_partner ? 'Yes' : 'No'}</td>
              <td>{dayjs(vendor.date_modified).format(DATE_FORMAT_WITH_TIME)}</td>
              <td>{dayjs(vendor.date_created).format(DATE_FORMAT_WITH_TIME)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

ManageVendorsTable.propTypes = {
  vendors: PropTypes.array,
};

export default ManageVendorsTable;
