import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { chartreuse, darkGrey2, white } from 'lib/css/colors';
import useProductScoreStyle from 'lib/hooks/product/useProductScoreStyle';

function ProductListScoreCard({ score, name }) {
  const { letter, backgroundStyle } = useProductScoreStyle(score);

  return (
    <div className={classNames('d-flex mx-2 p-2', css(styles.cardContainer, backgroundStyle))}>
      <div className={css(styles.letter)}>{letter}</div>
      <div className="d-flex flex-column">
        <span className={css(styles.percentage)}>{`${score}%`}</span>
        <span className={css(styles.name)}>{name}</span>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: chartreuse,
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'space-around',
    maxWidth: '230px',
    minWidth: '230px',
  },
  imageIcon: {
    color: darkGrey2,
  },
  percentage: {
    color: white,
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
  letter: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: white,
  },
  name: {
    color: white,
    fontSize: '0.8rem',
  },
});

ProductListScoreCard.propTypes = {
  score: PropTypes.number,
  name: PropTypes.string,
};

export default ProductListScoreCard;
