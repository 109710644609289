import React from 'react';

import Swal from 'sweetalert2';

import { ATTRIBUTION_CSV_IMPORT } from 'lib/networking/endpoints';
import FileUploadButton from 'components/shared/FileUploadButton';
import usePost from 'lib/hooks/usePost';

function ImportAttributesSection() {
  const { postData: uploadAttributes, loading: uploading } = usePost(
    ATTRIBUTION_CSV_IMPORT,
    () => {
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          Attribute import process started successfully.
           If there are any errors, the errors file can be downloaded through
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>.
        </div>`,
        title: 'Success',
      });
    },
    () => {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while uploading categories',
        title: 'Error',
      });
    },
  );

  const onFileUpload = formData => uploadAttributes(formData);

  return (
    <FileUploadButton
      text="Import Attributes"
      uploadElementId="attribute-import"
      fileTypes=".csv"
      onFileUpload={onFileUpload}
      disabled={uploading}
    />
  );
}

export default ImportAttributesSection;
