import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import DropZone from 'components/shared/Dropzone';
import usePost from 'lib/hooks/usePost';
import { BRANDS_IMPORT } from 'lib/networking/endpoints';

function BrandImportModal({ onHide, refetch }) {
  const [file, setFile] = useState();

  const { postData: importBrands, loading } = usePost(
    BRANDS_IMPORT,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Brand Import started successfully',
      }).then(() => onHide());
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while importing the: ${error?.response?.data?.message}`,
      }),
  );

  const onSubmit = () => {
    importBrands({ source_location_url: file?.s3_path });
  };

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">Import New Brands</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <DropZone
          text="Drag and drop some files here, or click to select files"
          onUploadSuccess={newUrls => setFile(newUrls?.[0])}
          fileUrls={file ? [file.presigned_url] : []}
          isImage={false}
        />
        <div className="my-3">
          <Button className="px-5 mr-2" disabled={loading} onClick={onSubmit}>
            Process Import
          </Button>
          <Button variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

BrandImportModal.propTypes = {
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default BrandImportModal;
