import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Row, Col } from 'react-bootstrap';

import Container from 'components/shared/Container';
import { DATE_FORMAT_WITH_TIME } from 'lib/constants';
import URLField from 'components/data_sources/single_data_source_view/URLField';

function DataSourceSummaryTab({ dataSource, dataSourceRun }) {
  return (
    <Container className="mt-4">
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Name
        </Col>
        <Col>{dataSource.source_name}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Created
        </Col>
        <Col>{dayjs(dataSource.date_created).format(DATE_FORMAT_WITH_TIME)}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Verification Status
        </Col>
        <Col>{dataSource.verification_status}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Entity Type
        </Col>
        <Col>{dataSource.entity_type}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Source Type
        </Col>
        <Col>{dataSource.source_type}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Last Run
        </Col>
        <Col>
          {dataSourceRun.date_created
            ? dayjs(dataSourceRun.date_created).format(DATE_FORMAT_WITH_TIME)
            : 'Never'}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Rows Processed
        </Col>
        <Col>{dataSourceRun.run_metadata?.processed_count || 'N/A'}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Rows Rejected
        </Col>
        <Col>{dataSourceRun.run_metadata?.rejected_count || 'N/A'}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs={2} className="font-weight-bold">
          Uploaded CSV
        </Col>
        <Col>
          <URLField dataSourceId={dataSource.id} />
        </Col>
      </Row>
    </Container>
  );
}

DataSourceSummaryTab.propTypes = {
  dataSource: PropTypes.shape({
    source_name: PropTypes.string.isRequired,
    date_created: PropTypes.string.isRequired,
    verification_status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    entity_type: PropTypes.string.isRequired,
    source_type: PropTypes.string.isRequired,
  }).isRequired,
  dataSourceRun: PropTypes.shape({
    date_created: PropTypes.string,
    run_metadata: PropTypes.shape({
      processed_count: PropTypes.number,
      rejected_count: PropTypes.number,
    }),
  }),
};

export default DataSourceSummaryTab;
