import React, { useContext, useEffect, useState } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import { some } from 'lodash';

import { backgroundGrey2, darkGrey } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ManageAttributeContext } from 'components/manage_attribute/ManageAttributeContainer';
import RadioButton from 'components/shared/RadioButton';
import { TAXONOMY_CATEGORY_NAMES } from 'lib/networking/endpoints';
import TaxonomyLevel, { CATEGORY_OPTIONS, TaxonomyGlobal } from 'lib/enums/TaxonomyLevel';
import usePost from 'lib/hooks/usePost';

function AssignAttributeSplitViewForCategory() {
  const [categories, setCategories] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const {
    categoryLevel,
    setCategoryLevel,
    searchText,
    setSearchText,
    selectedCategories,
    setSelectedCategories,
  } = useContext(ManageAttributeContext);

  useEffect(() => {
    if (categoryLevel.value !== TaxonomyGlobal.GL) {
      updateProducts({ taxonomy_level: categoryLevel.value });
    }
  }, [categoryLevel]); // eslint-disable-line react-hooks/exhaustive-deps

  const { postData: updateProducts, loading } = usePost(TAXONOMY_CATEGORY_NAMES, res => {
    setCategories(res.data.data || []);
    setFilteredItems(res.data.data || []);
  });

  const handleSearch = event => {
    setSearchText(event.target.value);
    if (event.target.value) {
      setFilteredItems(
        categories.filter(item =>
          item.category_name.toLowerCase().includes(searchText.toLowerCase()),
        ),
      );
    } else {
      setFilteredItems(categories);
    }
  };

  return (
    <div className="px-4 mt-1">
      <h6 className="font-weight-bold">Rosetta Category</h6>
      <Row>
        <Col sm={11} className="pl-3">
          <Select
            classNamePrefix="themed_select"
            options={CATEGORY_OPTIONS.filter(cat => ![TaxonomyLevel.L4].includes(cat.value))}
            isSearchable
            onChange={e => {
              setCategoryLevel(e);
              setSelectedCategories([]);
            }}
            value={categoryLevel}
          />
        </Col>
      </Row>
      <hr className="my-7 mr-5" />
      <Row className="mt-2">
        <Col sm={11} className="mb-4 pl-3">
          <Form.Control
            onChange={handleSearch}
            type="text"
            placeholder="Search Category"
            value={searchText}
          />
        </Col>
      </Row>
      {categoryLevel.value !== TaxonomyGlobal.GL ? (
        <>
          <Row>
            <Col sm={12}>
              <span
                className={classNames(css(styles.resultsText), 'float-right pr-5')}
              >{`${filteredItems.length} Results`}</span>
            </Col>
          </Row>
          <Row className="ml-1 mr-2 my-2">
            <Col sm={11} className={classNames(css(styles.selectAll), 'pl-3')}>
              <RadioButton
                box
                className="align-middle mt-1"
                checked={
                  filteredItems.length > 0 && selectedCategories?.length === filteredItems.length
                }
                disabled={false}
                onClick={() =>
                  selectedCategories.length === filteredItems?.length
                    ? setSelectedCategories([])
                    : setSelectedCategories(filteredItems)
                }
                label="Select All Categories"
                small
              />
            </Col>
          </Row>
          <div className={css(styles.scrollableDiv)}>
            {loading ? (
              <Row>
                <Col colSpan={4}>
                  <LoadingSpinner short />
                </Col>
              </Row>
            ) : (
              filteredItems?.map((category, index) => (
                <>
                  <Row key={index} className="pl-3 pb-1 ml-1">
                    <Col className="pl-0">
                      <RadioButton
                        box
                        className="align-middle"
                        checked={some(selectedCategories, cat => cat.id === category.id)}
                        disabled={false}
                        onClick={() => {
                          if (some(selectedCategories, cat => cat.id === category.id)) {
                            let temp = selectedCategories.filter(item => item.id !== category.id);
                            setSelectedCategories(temp);
                          } else {
                            setSelectedCategories([
                              ...selectedCategories,
                              { category_name: category.category_name, id: category.id },
                            ]);
                          }
                        }}
                        label={category.category_name}
                        small
                      />
                    </Col>
                  </Row>
                  <hr className="my-1 mr-5" />
                </>
              ))
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  resultsText: {
    color: darkGrey,
  },
  selectAll: {
    backgroundColor: backgroundGrey2,
    borderRadius: 4,
  },
  scrollableDiv: {
    overflow: 'scroll',
    maxHeight: '500px',
  },
});

export default AssignAttributeSplitViewForCategory;
