import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import { faEdit, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';

import EditProductsViewModal from 'components/global_products/edit_view/EditProductsViewModal';
import ImportAttributesSection from 'components/global_products/attribute/ImportAttributesSection';
import ProductStatus from 'lib/enums/ProductStatus';

const STATUS_OPTIONS = [
  {
    label: 'Uncategorized',
    value: [
      ProductStatus.UNCATEGORIZED,
      ProductStatus.MIGRATION_FAILED,
      ProductStatus.LEGACY_UNCLASSIFIED,
      ProductStatus.MIGRATION_FAILED_UNCLASSIFIED,
      ProductStatus.CATEGORY_PREDICTED,
    ],
    key: 'uncategorized',
  },
  { label: 'Categorized', value: [ProductStatus.CATEGORIZED], key: 'categorized' },
  { label: 'All', value: [], key: 'all' },
];

function GlobalProductsHeader({
  selectedStatus,
  setSelectedStatus,
  setCurrentPage,
  clearSelectedIDs,
  disabled = false,
  handleExportGlobalProducts,
  productsExporting = false,
  forAttribution = false,
}) {
  const [showEditViewModal, setShowEditViewModal] = useState(false);

  useEffect(() => {
    if (forAttribution) {
      setSelectedStatus([ProductStatus.CATEGORIZED]);
    }
    // ignore setSelectedStatus to avoid render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forAttribution]);

  return (
    <Row>
      <Col>
        {forAttribution ? null : (
          <ButtonGroup>
            {STATUS_OPTIONS.map(option => (
              <Button
                key={option.key}
                variant={isEqual(selectedStatus, option.value) ? 'primary' : 'outline-primary'}
                onClick={() => {
                  clearSelectedIDs();
                  setCurrentPage(1);
                  setSelectedStatus(option.value);
                }}
                disabled={disabled}
              >
                {option.label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Col>
      <Col className="d-flex justify-content-end">
        {handleExportGlobalProducts ? (
          <Button
            className="mr-2"
            variant="outline-primary"
            onClick={handleExportGlobalProducts}
            disabled={productsExporting}
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
            Export
          </Button>
        ) : null}
        <Button onClick={() => setShowEditViewModal(true)}>
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Edit View
        </Button>
        {forAttribution ? (
          <div className="ml-2">
            <ImportAttributesSection />
          </div>
        ) : null}
      </Col>
      <EditProductsViewModal show={showEditViewModal} onHide={setShowEditViewModal} />
    </Row>
  );
}

GlobalProductsHeader.propTypes = {
  disabled: PropTypes.bool,
  selectedStatus: PropTypes.array,
  setCurrentPage: PropTypes.func,
  clearSelectedIDs: PropTypes.func,
  setSelectedStatus: PropTypes.func,
  handleExportGlobalProducts: PropTypes.func,
  forAttribution: PropTypes.bool,
  productsExporting: PropTypes.bool,
};

export default GlobalProductsHeader;
