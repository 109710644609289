const RoleNames = {
  ADMIN: 'admin',
  CLASSIFIER: 'classifier',
  USER: 'user',
  CLASSIFIER_WITH_CATEGORY_PERMISSIONS: 'Classifier with Category Permissions',
  OPERATIONS: 'Operations',
  UPB_VIEWER: 'upb viewer',
  SYNCS: 'syncs',
  BRAND_HARVESTING: 'Brand Harvesting',
};

export const HomeUrls = [
  { label: '/product-lists', value: RoleNames.ADMIN },
  { label: '/global-products/classify', value: RoleNames.CLASSIFIER },
  { label: '/upb-viewer', value: RoleNames.USER },
  { label: '/global-products/classify', value: RoleNames.CLASSIFIER_WITH_CATEGORY_PERMISSIONS },
  { label: '/product-lists', value: RoleNames.OPERATIONS },
  { label: '/upb-viewer', value: RoleNames.UPB_VIEWER },
  { label: '/upb-viewer', value: RoleNames.SYNCS },
  { label: '/enrich-products/brand-harvest', value: RoleNames.BRAND_HARVESTING },
];

export default RoleNames;
