import React, { useState } from 'react';

import { Button, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BRANDS_UPDATE_FROM_FSA } from 'lib/networking/endpoints';
import DropZone from 'components/shared/Dropzone';
import { SwalToast } from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

const BRAND_LOGO_FOLDER = 'brand_logo';

function UploadBrandLogoFromQAModal({ brand, onHide, setBrandData }) {
  const [uploadedBrandLogoUrl, setUploadedBrandLogoUrl] = useState([]);

  const { postData: updateBrand, loading } = usePost(
    BRANDS_UPDATE_FROM_FSA,
    () => {
      SwalToast('success', `Uploaded the logo`);
    },
    () => SwalToast('error', `Could not upload the logo`),
  );

  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Upload Brand Logo</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <DropZone
            text="Drag and drop the logo image here, or click to select the image"
            onUploadSuccess={newUrls => setUploadedBrandLogoUrl(newUrls)}
            fileUrls={uploadedBrandLogoUrl}
            isImage={true}
            isPublic={true}
            filePath={BRAND_LOGO_FOLDER}
          />
          <div className="mt-3 d-flex justify-content-center align-items-center">
            <Button
              className="mr-2"
              disabled={uploadedBrandLogoUrl?.length !== 1}
              variant="primary"
              onClick={() =>
                updateBrand({
                  id: brand.id,
                  logo_url: uploadedBrandLogoUrl[0],
                  from_qa: true,
                }).then(() => {
                  setUploadedBrandLogoUrl([]);
                  setBrandData(prev => ({
                    ...prev,
                    logoUrl: uploadedBrandLogoUrl[0],
                  }));
                  onHide();
                })
              }
            >
              {loading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                'Upload Brand Logo'
              )}
            </Button>
            <Button variant="outline-primary" onClick={() => setUploadedBrandLogoUrl([])}>
              Clear Logo
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

UploadBrandLogoFromQAModal.propTypes = {
  brand: PropTypes.object,
  onHide: PropTypes.func,
  setBrandData: PropTypes.func,
};

export default UploadBrandLogoFromQAModal;
