import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

import ClassificationStatsView from 'components/stats/ClassificationStatsView';
import ClassificationGraphView from 'components/stats/graphs/ClassificationGraphView';
import ManufacturerProductClassificationStatsView from 'components/stats/ManufacturerProductClassificationStatsView';
import ManufacturerProductVerifiedStatsView from 'components/stats/ManufacturerProductVerifiedStatsView';
import VendorProductClassificationStatView from 'components/stats/VendorProductClassificationStatView';
import VendorProductVerifiedStatsView from 'components/stats/VendorProductVerifiedStatsView';

const DATE_RANGE_OPTIONS = [
  { label: '7 days', value: 7 },
  { label: '2 weeks', value: 14 },
];

function StatsView() {
  const [selectedOption, setSelectedOption] = useState(DATE_RANGE_OPTIONS[0]);

  return (
    <div>
      <Row className="my-3">
        <Col className="d-flex justify-content-center">
          <ClassificationStatsView />
        </Col>
        <Col className="d-flex justify-content-center">
          <ManufacturerProductClassificationStatsView />
        </Col>
        <Col className="d-flex justify-content-center">
          <VendorProductClassificationStatView />
        </Col>
        <Col className="d-flex justify-content-center">
          <VendorProductVerifiedStatsView />
        </Col>
        <Col className="d-flex justify-content-center">
          <ManufacturerProductVerifiedStatsView />
        </Col>
      </Row>
      <Row>
        <Col sm="2" className="pl-5 pr-0 ml-2 my-4">
          <Select
            value={selectedOption}
            options={DATE_RANGE_OPTIONS}
            onChange={option => setSelectedOption(option)}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="pl-0 ml-0">
          <ClassificationGraphView lookBackDays={selectedOption.value} />
        </Col>
      </Row>
    </div>
  );
}

export default StatsView;
