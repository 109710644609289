// update along with app/lib/enums/cd_export_type.py
const CDExportType = {
  CATEGORIES: 'categories',
  TAXONOMY: 'taxonomy',
  BRANDS: 'brands',
  BRANDED_PRODUCTS: 'branded_products',
  IMAGES: 'images',
};

export default CDExportType;
