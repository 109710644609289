import React from 'react';
import PropTypes from 'prop-types';

import AttributesParagraphView from 'components/upb_viewer/pdp_components/AttributesParagraphView';
import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import AttributeSubsection from 'components/upb_viewer/pdp_components/AttributeSubsection';
import AttributesTableView from 'components/upb_viewer/pdp_components/AttributesTableView';

function TipsAndHandlingSection({
  tipsAndHandling,
  storageInstructions,
  preparationInstructions,
  servingSuggestions,
}) {
  if (
    !tipsAndHandling &&
    (!storageInstructions || !storageInstructions.length) &&
    !preparationInstructions &&
    !servingSuggestions
  ) {
    return null;
  }

  return (
    <AttributeSection title="Tips + Handling">
      {tipsAndHandling && (
        <AttributeSubsection>
          <AttributesParagraphView paragraph={tipsAndHandling} />
        </AttributeSubsection>
      )}
      {storageInstructions && storageInstructions.length > 0 && (
        <AttributeSubsection title="Storage Instructions">
          <AttributesTableView attributes={storageInstructions} />
        </AttributeSubsection>
      )}
      {preparationInstructions && (
        <AttributeSubsection title="Preparation Instructions">
          <AttributesParagraphView paragraph={preparationInstructions} />
        </AttributeSubsection>
      )}
      {servingSuggestions && (
        <AttributeSubsection title="Serving Suggestions">
          <AttributesParagraphView paragraph={servingSuggestions} />
        </AttributeSubsection>
      )}
    </AttributeSection>
  );
}

TipsAndHandlingSection.propTypes = {
  tipsAndHandling: PropTypes.string,
  storageInstructions: PropTypes.array,
  preparationInstructions: PropTypes.string,
  servingSuggestions: PropTypes.string,
};

export default TipsAndHandlingSection;
