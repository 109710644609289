import React from 'react';
import PropTypes from 'prop-types';

import { uniq, without } from 'lodash';

import RadioButton from 'components/shared/RadioButton';

function ManageAttributeCategoriesAssignedTableRow({
  tableRow,
  selectedIds,
  setSelectedIds,
  showToggle = true,
}) {
  const keyWithIsAppliedTrue = Object.keys(tableRow).find(key => tableRow[key].is_applied === true);
  const uniqueKey = Object.values(tableRow).find(val => val.is_applied === true)?.attribute_map_id;

  const toggleCheck = uniqueKey => {
    const isChecked = selectedIds.includes(uniqueKey);
    if (isChecked) {
      setSelectedIds([...without(selectedIds, uniqueKey)]);
    } else {
      setSelectedIds([...uniq([...selectedIds, uniqueKey])]);
    }
  };

  return (
    <tr key={uniqueKey}>
      {showToggle && (
        <td>
          <RadioButton
            box
            className="align-middle"
            small={true}
            checked={selectedIds.includes(uniqueKey)}
            onClick={() => toggleCheck(uniqueKey)}
          />
        </td>
      )}
      <td>{tableRow[0].category_name || '-'}</td>
      <td>{tableRow[1]?.category_name || '-'}</td>
      <td>{tableRow[2]?.category_name || '-'}</td>
      <td>{tableRow[3]?.category_name || '-'}</td>
      <td>L{keyWithIsAppliedTrue}</td>
    </tr>
  );
}

ManageAttributeCategoriesAssignedTableRow.propTypes = {
  tableRow: PropTypes.object,
  selectedIds: PropTypes.array.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  showToggle: PropTypes.func,
};

export default ManageAttributeCategoriesAssignedTableRow;
