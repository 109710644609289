import React from 'react';
import PropTypes from 'prop-types';

import AttributesTableView from 'components/upb_viewer/pdp_components/AttributesTableView';

function ChildNutritionSection({ childNutritions }) {
  if (!childNutritions?.length) {
    return null;
  }
  return <AttributesTableView attributes={childNutritions} />;
}

ChildNutritionSection.propTypes = {
  childNutritions: PropTypes.array,
};

export default ChildNutritionSection;
