import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';

import { areYouSure } from 'lib/utils/toast';
import BrandQAEditModal from 'components/manage_brands/BrandQAEditModal';
import { BRANDS_UPDATE_FROM_QA } from 'lib/networking/endpoints';
import SimilarBrandsModal from 'components/manage_brands/SimilarBrandsModal';
import SwalDialog from 'lib/utils/toast';
import VerificationStatusIcon from 'components/global_products/detail_view/VerificationStatusIcon';

import usePost from 'lib/hooks/usePost';
function BrandQAViewTableRow({ brand, refetch }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSimililarBrandsModal, setShowSimililarBrandsModal] = useState(false);
  const [selectedSimilarBrand, setSelectedSimilarBrand] = useState(null);
  const { postData: editBrand } = usePost(
    BRANDS_UPDATE_FROM_QA,
    () => {
      SwalDialog('success', 'Successfully Updated the Brand', 'Success', 'center');
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while Updating the Brand: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const handleSimilarBrandClick = similarBrand => {
    setSelectedSimilarBrand(similarBrand);
    setShowSimililarBrandsModal(true);
  };

  const handleResolve = () => {
    areYouSure(() => {
      editBrand({
        name: brand?.name,
        id: brand?.id,
        resolve: true,
        global_manufacturer_name: brand?.global_manufacturer?.name,
        global_manufacturer_id: brand?.global_manufacturer?.id,
        manufacturer_verification_status: brand?.global_manufacturer?.verification_status,
      });
    }, 'Are you sure you want to Resolve the Brand?');
  };
  return (
    <>
      <tr>
        <td>{brand?.name}</td>
        <td>{brand?.global_manufacturer?.name || '-'}</td>
        <td>
          <ul>
            {brand?.similar_brands?.map(similarBrand => (
              <Row key={similarBrand.id}>
                <Col>
                  <Button variant="link" onClick={() => handleSimilarBrandClick(similarBrand)}>
                    {similarBrand.brand_name}
                  </Button>
                </Col>
                <Col>
                  <VerificationStatusIcon
                    verificationStatus={similarBrand.qa_verification_status}
                  />
                </Col>
              </Row>
            ))}
          </ul>
        </td>
        <td>{brand?.verified_by?.name || '-'}</td>
        <td>
          <Button variant="link" onClick={() => setShowEditModal(true)}>
            Edit
          </Button>
          <Button
            variant="link"
            key={brand?.id}
            onClick={() => window.open(`/enrich-products/brand-details/${brand.id}`, '_blank')}
          >
            View
          </Button>
          <Button variant="link" onClick={handleResolve}>
            Resolve
          </Button>
        </td>
        {showEditModal ? (
          <BrandQAEditModal
            onHide={() => setShowEditModal(false)}
            brand={brand}
            refetch={refetch}
          />
        ) : null}
      </tr>
      {showSimililarBrandsModal && selectedSimilarBrand && (
        <SimilarBrandsModal
          show={showSimililarBrandsModal}
          onHide={() => setShowSimililarBrandsModal(false)}
          similarBrand={selectedSimilarBrand}
          refetch={refetch}
        />
      )}
    </>
  );
}

BrandQAViewTableRow.propTypes = {
  brand: PropTypes.object,
  refetch: PropTypes.func,
};

export default BrandQAViewTableRow;
