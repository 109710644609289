import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select/creatable';

import { darkerSalmon } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import { SORT_DIRECTIONS } from 'lib/constants';

function CascadeCategoryDropdown({
  title,
  options,
  searchTerm,
  setSearchTerm,
  setCurrentPage,
  sortConfig,
  setSortConfig,
}) {
  const sortable = true;
  const customStyles = {
    control: (provided, _state) => ({
      ...provided,
      height: '25px',
      minHeight: '20px',
      fontWeight: 'normal',
    }),
    valueContainer: (provided, _state) => ({
      ...provided,
      padding: '0 5px',
      minWidth: '5px',
    }),
    input: (provided, _state) => ({
      ...provided,
      minWidth: '5px',
    }),
    indicatorsContainer: (provided, _state) => ({
      ...provided,
      height: '25px',
    }),
    dropdownIndicator: (provided, _state) => ({
      ...provided,
      padding: '0',
      width: '15px',
    }),
    clearIndicator: (provided, _state) => ({
      ...provided,
      padding: '0',
      width: '15px',
    }),
    menu: (provided, _state) => ({
      ...provided,
      minWidth: '200px',
    }),
    option: (provided, _state) => ({
      ...provided,
      fontWeight: 'normal',
      padding: '5px',
    }),
  };

  const [inputValue, setInputValue] = useState(searchTerm || '');

  const updateSortConfig = () => {
    let direction = SORT_DIRECTIONS.ASC;
    if (sortConfig.field === title) {
      if (sortConfig.direction === SORT_DIRECTIONS.ASC) {
        direction = SORT_DIRECTIONS.DESC;
      }
    }
    setSortConfig({
      field: title,
      direction,
    });
  };

  // this is to add the editing capability for the select component
  const onInputChange = (option, { action }) => {
    if (action === 'input-change') {
      setInputValue(option);
    }
  };

  return (
    <th className="d-flex-column p-1">
      <div
        className={classNames(
          sortConfig?.field === title ? css(styles.sorted) : null,
          'd-flex align-items-center',
        )}
      >
        <div className={css(styles.title)}>{title}</div>
        {sortable ? (
          sortConfig?.field === title && sortConfig?.direction === 'desc' ? (
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'ml-2')}
              icon={faArrowDown}
              onClick={updateSortConfig}
            />
          ) : (
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'ml-2')}
              icon={faArrowUp}
              onClick={updateSortConfig}
            />
          )
        ) : null}
      </div>
      <Select
        options={options}
        value={
          options?.find(option => option.value === searchTerm) || {
            value: searchTerm,
            label: searchTerm,
          }
        }
        onChange={option => {
          setSearchTerm(option?.value || '', option?.id || '');
          typeof setCurrentPage === 'function' ? setCurrentPage(1) : null;
        }}
        styles={customStyles}
        formatCreateLabel={searchText => 'Search "' + searchText + '"'}
        isSearchable
        isClearable
        onInputChange={onInputChange}
        inputValue={inputValue}
        createOptionPosition="first"
      />
    </th>
  );
}

const styles = StyleSheet.create({
  sorted: {
    color: darkerSalmon,
  },
  title: {
    wordBreak: 'break-word',
    maxHeight: '20px',
    overflow: 'hidden',
  },
});

CascadeCategoryDropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  setCurrentPage: PropTypes.func,
  sortConfig: PropTypes.object,
  setSortConfig: PropTypes.func,
};

export default CascadeCategoryDropdown;
