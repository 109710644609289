import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Col, Row, Table } from 'react-bootstrap';
import Select from 'react-select';

import ChangelogRow from 'components/global_products/detail_view/ChangelogRow';
import { CHANGE_LOG_TYPE_MAP, CHANGE_LOG_TYPE } from 'lib/enums/ChangeLogEnums';

function GlobalProductsChangelogView({ changeLogs }) {
  return (
    <div className="my-4">
      <Row>
        <Col sm="3">
          <Select
            className="ml-2"
            options={CHANGE_LOG_TYPE_MAP}
            value={CHANGE_LOG_TYPE_MAP.find(
              option => option.value === CHANGE_LOG_TYPE.PRODUCT_UPDATE,
            )}
            isDisabled
          />
        </Col>
      </Row>
      {changeLogs?.length ? (
        <Table hover responsive className={classNames(css(styles.table), 'mt-3')}>
          <thead>
            <tr>
              <th width="40">Date</th>
              <th width="30">Type</th>
              <th width="200">User email</th>
              <th>Old Value</th>
              <th>New Value</th>
            </tr>
          </thead>
          <tbody>
            {changeLogs.map(changeLog => (
              <ChangelogRow key={changeLog.id} changeLog={changeLog} />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="m-3">This product has had no changes yet</div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  table: {
    fontSize: '12px',
  },
});

GlobalProductsChangelogView.propTypes = {
  changeLogs: PropTypes.array,
  refetch: PropTypes.func,
};

export default GlobalProductsChangelogView;
