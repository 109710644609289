import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';
import { faEdit, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EditProductsViewModal from 'components/all_products/edit_view/EditProductsViewModal';

function AllProductsHeader({ handleExportAllProducts, productsExporting = false }) {
  const [showEditViewModal, setShowEditViewModal] = useState(false);

  return (
    <Row>
      <Col className="d-flex justify-content-end">
        {handleExportAllProducts ? (
          <Button
            className="mr-2"
            variant="outline-primary"
            onClick={handleExportAllProducts}
            disabled={productsExporting}
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
            Export
          </Button>
        ) : null}
        <Button onClick={() => setShowEditViewModal(true)}>
          <FontAwesomeIcon icon={faEdit} className="mr-2" />
          Edit View
        </Button>
      </Col>
      <EditProductsViewModal show={showEditViewModal} onHide={setShowEditViewModal} />
    </Row>
  );
}

AllProductsHeader.propTypes = {
  disabled: PropTypes.bool,
  setCurrentPage: PropTypes.func,
  handleExportAllProducts: PropTypes.func,
  productsExporting: PropTypes.bool,
};

export default AllProductsHeader;
