import React from 'react';
import PropTypes from 'prop-types';

import {
  l0_category,
  l1_category,
  l2_category,
  l3_category,
  l4_category,
} from 'lib/css/categoryTexts';
import pointerOnHover from 'lib/css/pointerOnHover';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import TaxonomyTableCell from 'components/manage_taxonomy/TaxonomyTableCell';

function TaxonomyTableRow({
  l0Category,
  l1Category,
  l2Category,
  l3Category,
  l4Category,
  onClassify,
  hasTaxonomyPermission,
  setCreateModalOptions,
  manageTaxonomy = false,
  setCurrentPage,
  setCategoryModified,
}) {
  const clickable = !!onClassify;

  const handleRowClick = () => {
    if (clickable && l4Category) {
      onClassify(l4Category.id);
    }
  };

  const openCreateModal = () => {
    const options = { show: true };
    if (l0Category) {
      options.l0Category = {
        label: `${l0Category.category_name} || ${l0Category.rosetta_id}`,
        value: l0Category.id,
      };
    }
    if (l1Category) {
      options.l1Category = {
        label: `${l1Category.category_name} || ${l1Category.rosetta_id}`,
        value: l1Category.id,
      };
    }
    if (l2Category) {
      options.l2Category = {
        label: `${l2Category.category_name} || ${l2Category.rosetta_id}`,
        value: l2Category.id,
      };
    }
    if (l3Category) {
      options.l3Category = {
        label: `${l3Category.category_name} || ${l3Category.rosetta_id}`,
        value: l3Category.id,
      };
    }
    if (l4Category) {
      options.l4Category = {
        label: `${l4Category.category_name} || ${l4Category.rosetta_id}`,
        value: l4Category.id,
      };
    }
    setCreateModalOptions(options);
  };
  return (
    <tr className={clickable ? pointerOnHover : undefined} onClick={handleRowClick}>
      {manageTaxonomy ? (
        <TaxonomyTableCell
          category={l0Category}
          canAddNew={hasTaxonomyPermission && !!l0Category && !l1Category}
          canEdit={hasTaxonomyPermission && manageTaxonomy}
          canDelete={hasTaxonomyPermission && !!l0Category && !l1Category}
          openCreateModal={openCreateModal}
          textCSS={l0_category}
          taxonomyLevel={TaxonomyLevel.L0}
          setCurrentPage={setCurrentPage}
          setCategoryModified={setCategoryModified}
        />
      ) : null}
      <TaxonomyTableCell
        category={l1Category}
        canAddNew={hasTaxonomyPermission && !!l1Category && !l2Category}
        canEdit={hasTaxonomyPermission && manageTaxonomy}
        canDelete={hasTaxonomyPermission && !!l1Category && !l2Category}
        openCreateModal={openCreateModal}
        textCSS={l1_category}
        taxonomyLevel={TaxonomyLevel.L1}
        setCurrentPage={setCurrentPage}
        setCategoryModified={setCategoryModified}
      />
      <TaxonomyTableCell
        category={l2Category}
        canAddNew={hasTaxonomyPermission && !!l2Category && !l3Category}
        canEdit={hasTaxonomyPermission && manageTaxonomy}
        canDelete={hasTaxonomyPermission && !!l2Category && !l3Category}
        openCreateModal={openCreateModal}
        textCSS={l2_category}
        taxonomyLevel={TaxonomyLevel.L2}
        setCurrentPage={setCurrentPage}
        setCategoryModified={setCategoryModified}
      />
      <TaxonomyTableCell
        category={l3Category}
        canAddNew={hasTaxonomyPermission && !!l3Category && !l4Category}
        canEdit={hasTaxonomyPermission && manageTaxonomy}
        canDelete={hasTaxonomyPermission && !!l3Category && !l4Category}
        openCreateModal={openCreateModal}
        textCSS={l3_category}
        taxonomyLevel={TaxonomyLevel.L3}
        setCurrentPage={setCurrentPage}
        setCategoryModified={setCategoryModified}
      />
      <TaxonomyTableCell
        category={l4Category}
        canAddNew={hasTaxonomyPermission && !!l4Category}
        canEdit={hasTaxonomyPermission && manageTaxonomy}
        canDelete={hasTaxonomyPermission && !!l4Category && manageTaxonomy}
        openCreateModal={openCreateModal}
        textCSS={l4_category}
        taxonomyLevel={TaxonomyLevel.L4}
        setCurrentPage={setCurrentPage}
        setCategoryModified={setCategoryModified}
      />
    </tr>
  );
}

TaxonomyTableRow.propTypes = {
  l0Category: PropTypes.object,
  l1Category: PropTypes.object,
  l2Category: PropTypes.object,
  l3Category: PropTypes.object,
  l4Category: PropTypes.object,
  onClassify: PropTypes.func,
  hasTaxonomyPermission: PropTypes.bool,
  setCreateModalOptions: PropTypes.func,
  manageTaxonomy: PropTypes.bool,
  setCurrentPage: PropTypes.func,
  setCategoryModified: PropTypes.func,
};

export default TaxonomyTableRow;
