import React, { useState } from 'react';

import { Tab, Tabs } from 'react-bootstrap';

import Container from 'components/shared/Container';
import ImageRecommendationsView from 'components/assets/image_recommendations/ImageRecommendationsView';

const ASSETS_TAB = {
  TAG_IMAGES: 'Tag Images',
  RECOMMENDATIONS: 'Recommendations',
};

function AssetsView() {
  const [activeTabKey, setActiveTabKey] = useState(ASSETS_TAB.RECOMMENDATIONS);

  return (
    <Container>
      <h3 className="font-weight-bold">Assets</h3>
      <Tabs defaultActiveKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
        <Tab eventKey={ASSETS_TAB.TAG_IMAGES} title={ASSETS_TAB.TAG_IMAGES}>
          <div className="p-3">Image Tagger</div>
        </Tab>
        <Tab eventKey={ASSETS_TAB.RECOMMENDATIONS} title={ASSETS_TAB.RECOMMENDATIONS}>
          <ImageRecommendationsView />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default AssetsView;
