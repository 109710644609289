import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'react-bootstrap';
import dayjs from 'dayjs';

import { DATE_FORMAT_WITH_TIME } from 'lib/constants';
import CardResyncButton from 'components/stats/CardResyncButton';
import LoadingSpinner from 'components/shared/LoadingSpinner';

function StatsCard({
  cardTitle,
  value,
  lastSyncedTimestamp,
  cardText,
  reSync,
  loading,
  showResyncButton = false,
  showFooter = false,
}) {
  return (
    <Card className="text-center h-100">
      <Card.Header>
        {cardTitle}
        {showResyncButton ? <CardResyncButton reSync={reSync} /> : null}
      </Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between">
        {loading ? (
          <LoadingSpinner short={true} />
        ) : (
          <>
            <Card.Title>{value}</Card.Title>
            <Card.Text>{cardText}</Card.Text>
          </>
        )}
      </Card.Body>
      {showFooter ? (
        <Card.Footer className="text-muted small">
          <div>{'Count - cached for 6 hours'}</div>
          <div>{`Last synced was at ${dayjs(lastSyncedTimestamp).format(
            DATE_FORMAT_WITH_TIME,
          )}`}</div>
        </Card.Footer>
      ) : null}
    </Card>
  );
}

StatsCard.propTypes = {
  cardTitle: PropTypes.string,
  value: PropTypes.number,
  lastSyncedTimestamp: PropTypes.string,
  cardText: PropTypes.string,
  loading: PropTypes.bool,
  reSync: PropTypes.func,
  showResyncButton: PropTypes.bool,
  showFooter: PropTypes.bool,
};

export default StatsCard;
