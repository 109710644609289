import React, { useState } from 'react';

import { Col, Form, Row, Button } from 'react-bootstrap';

import Container from 'components/shared/Container';
import { GS1_XML_DECODE } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function GS1XMLDecoder() {
  const [results, setResults] = useState(null);
  const { postData: decodeXML, loading } = usePost(GS1_XML_DECODE);

  const [file, setFile] = useState(null);

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!file) {
      SwalDialog('error', 'No file selected', 'Please select a file to upload', 'center');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await decodeXML(formData);
      if (response) {
        setResults(response.data?.data?.[0] || []);
      }
    } catch (error) {
      SwalDialog('error', error, 'Error', 'center');
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="font-weight-bold">GS1 XML file decoder</h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Upload GS1 XML file</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>
            <Button type="submit" disabled={loading}>
              {loading ? 'Uploading...' : 'Upload'}
            </Button>
          </Form>
        </Col>
      </Row>
      {!loading && results && (
        <Row>
          <Col>
            <h5 className="font-weight-bold pt-4">Results</h5>
            <pre className="p-3 border">{JSON.stringify(results, null, 2)}</pre>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default GS1XMLDecoder;
