import React, { useContext, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { isEqual, reject, uniq, without } from 'lodash';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

import ActionDropDown from 'components/shared/ActionDropDown';
import Container from 'components/shared/Container';
import GlobalAttributes from 'lib/enums/GlobalAttributes';
import { PRODUCT_LIST_ATTRIBUTION_CSV_EXPORT } from 'lib/networking/endpoints';
import { ProductListExportContext } from 'components/product_lists/product_export/ProductListExportContainer';
import ProductListSelectedAttributeSplitRow from 'components/product_lists/product_export/ProductListSelectedAttributeSplitRow';
import { P0SyncAttributes } from 'lib/enums/P0Attributes';
import { taxonomyColumns } from 'lib/enums/TaxonomyLevel';
import usePost from 'lib/hooks/usePost';

const DEFAULT_SELECTED_ATTRIBUTES = [
  GlobalAttributes.gtin,
  P0SyncAttributes.sku,
  P0SyncAttributes.name,
  P0SyncAttributes.brand_name,
  P0SyncAttributes.manufacturer_name,
  P0SyncAttributes.pack_size,
  P0SyncAttributes.vendor_category,
  P0SyncAttributes.vendor_subcategory,
  P0SyncAttributes.catch_or_variable_weight,
  P0SyncAttributes.storage_temperature,
  P0SyncAttributes.trade_item_key_words_comma_separated,
  GlobalAttributes.status,
  P0SyncAttributes.thumbnail,
];

const DEFAULT_SELECTED_TAXONOMIES = [
  taxonomyColumns.l4_category_name,
  taxonomyColumns.l3_category_name,
  taxonomyColumns.l2_category_name,
  taxonomyColumns.l1_category_name,
  taxonomyColumns.l0_category_name,
];

const DEFAULT_SCORES = ['cd_product_score'];

const DEFAULT_SELECTED_OTHER_COLUMNS = ['vendor_name', 'cnd_id', 'core_11', 'tag_family_data'];

function ProductListSelectedAttributeSplitView() {
  const { productListId } = useParams();
  const { selectedAttributes, setSelectedAttributes } = useContext(ProductListExportContext);
  const [selectedCategories, setSelectedCategories] = useState(DEFAULT_SELECTED_TAXONOMIES);
  const [defaultAttributes, setDefaultAttributes] = useState(DEFAULT_SELECTED_ATTRIBUTES);
  const [selectedScores, setSelectedScores] = useState(DEFAULT_SCORES);
  const [defaultOtherColumns, setDefaultOtherColumns] = useState(DEFAULT_SELECTED_OTHER_COLUMNS);

  const { postData: exportAttributeReportCSV } = usePost(
    PRODUCT_LIST_ATTRIBUTION_CSV_EXPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  const handleClick = attr => {
    const uniqKey = attr.id + '_' + attr.attributeName + '_' + attr.isGlobalAttribute;
    setSelectedAttributes(reject(selectedAttributes, obj => isEqual(obj.uniqKey, uniqKey)));
  };

  const handleDefaultAttributesClick = attr => {
    if (defaultAttributes.find(attri => attri === attr)) {
      setDefaultAttributes([...without(defaultAttributes, attr)]);
    } else {
      setDefaultAttributes([...uniq([...defaultAttributes, attr])]);
    }
  };

  const handleDefaultTaxonomyClick = taxoColumn => {
    if (selectedCategories.find(taxoCol => taxoCol === taxoColumn)) {
      setSelectedCategories([...without(selectedCategories, taxoColumn)]);
    } else {
      setSelectedCategories([...uniq([...selectedCategories, taxoColumn])]);
    }
  };

  const handleScoreClick = scoreColumn => {
    if (selectedScores.find(scoreCol => scoreCol === scoreColumn)) {
      setSelectedScores([...without(selectedScores, scoreColumn)]);
    } else {
      setSelectedScores([...uniq([...selectedScores, scoreColumn])]);
    }
  };

  const handleDefaultOtherColumnsClick = column => {
    if (defaultOtherColumns.find(col => col === column)) {
      setDefaultOtherColumns([...without(defaultOtherColumns, column)]);
    } else {
      setDefaultOtherColumns([...uniq([...defaultOtherColumns, column])]);
    }
  };

  const ExportFile = (propagateData, allAttributes) => {
    let propagatedAttributes = false;
    if (propagateData) {
      propagatedAttributes = true;
    }
    exportAttributeReportCSV({
      product_list_id: productListId,
      attributes: uniq(
        selectedAttributes.map(attr => attr.attributeName).concat(defaultAttributes),
      ),
      taxonomies: allAttributes ? DEFAULT_SELECTED_TAXONOMIES : selectedCategories,
      propagated_data: propagatedAttributes,
      scores: allAttributes ? DEFAULT_SCORES : selectedScores,
      other_columns: allAttributes ? DEFAULT_SELECTED_OTHER_COLUMNS : defaultOtherColumns,
      all_attributes: allAttributes,
    });
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col className="d-flex justify-content-end">
          <ActionDropDown
            submenuItems={[
              {
                title: 'Export product specific data',
                action: () => ExportFile(false, false),
              },
              {
                title: 'Export with propagated data',
                action: () => ExportFile(true, false),
              },
              {
                title: 'Export all attributes with specific data',
                action: () => ExportFile(true, true),
              },
              {
                title: 'Export all attributes with propagated data',
                action: () => ExportFile(true, true),
              },
            ]}
          />
        </Col>
      </Row>
      {selectedCategories.map(attr => (
        <ProductListSelectedAttributeSplitRow
          key={attr}
          eventHandler={handleDefaultTaxonomyClick}
          colValue={attr}
          objectValue={attr}
        />
      ))}
      {defaultOtherColumns.map(attr => (
        <ProductListSelectedAttributeSplitRow
          key={attr}
          eventHandler={handleDefaultOtherColumnsClick}
          colValue={attr}
          objectValue={attr}
        />
      ))}
      {defaultAttributes.map(attr => (
        <ProductListSelectedAttributeSplitRow
          key={attr}
          eventHandler={handleDefaultAttributesClick}
          colValue={attr}
          objectValue={attr}
        />
      ))}
      {selectedAttributes.map(attr => (
        <ProductListSelectedAttributeSplitRow
          key={attr}
          eventHandler={handleClick}
          colValue={attr.attributeName}
          objectValue={attr}
        />
      ))}
      {selectedScores.map(attr => (
        <ProductListSelectedAttributeSplitRow
          key={attr}
          eventHandler={handleScoreClick}
          colValue={attr}
          objectValue={attr}
        />
      ))}
    </Container>
  );
}

export default ProductListSelectedAttributeSplitView;
