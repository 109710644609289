import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { useLocation } from 'react-router-dom';

import { auroMetalSaurus, backgroundDarkGrey } from 'lib/css/colors';
import { sectionTextBlackClass, sectionTextClass } from 'components/upb_viewer/css/sharedStyles';

function AttributesTableView({ attributes = [], linkToPDPPage = false }) {
  const location = useLocation();
  if (!attributes.length) {
    return null;
  }
  return (
    <div className={css(styles.table)}>
      {attributes.map((attribute, index) => (
        <Row
          key={attribute.display_name + index}
          className={css(styles.rowStyle, index % 2 === 0 && styles.greyBackground)}
        >
          <Col className={sectionTextClass}>
            <div className="d-flex align-self-center">{attribute.display_name}</div>
          </Col>
          {linkToPDPPage ? (
            <Col>
              <div className="d-flex align-self-center flex-column">
                <Button
                  variant="link"
                  className={classNames(sectionTextBlackClass, 'text-left pl-0')}
                  onClick={() => {
                    window.open(
                      location.pathname.includes('upb-pdp')
                        ? `/upb-pdp/` + `${attribute.id}/${attribute.product_type}`
                        : `/global-product-detail/${attribute.global_product_id}/` +
                            `${attribute.id}/${attribute.product_type}`,
                      '_blank',
                    );
                  }}
                >
                  {attribute.attribute_value}
                </Button>
              </div>
            </Col>
          ) : (
            <Col className={sectionTextBlackClass}>
              <div className="d-flex align-self-center flex-column">
                {attribute.attribute_value}
              </div>
            </Col>
          )}
        </Row>
      ))}
    </div>
  );
}

const styles = StyleSheet.create({
  rowStyle: {
    marginLeft: -6,
    marginRight: 0,
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '6px',
  },
  auroMetalSaurus: {
    color: auroMetalSaurus,
  },
  greyBackground: {
    backgroundColor: backgroundDarkGrey,
  },
  table: {
    borderRadius: 8,
    overflow: 'hidden',
  },
});

AttributesTableView.propTypes = {
  attributes: PropTypes.array,
  linkToPDPPage: PropTypes.bool,
};

export default AttributesTableView;
