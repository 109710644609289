import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import PaginationComponent from 'components/shared/PaginationComponent';
import ProductRow from 'components/assets/image_recommendations/ProductRow';
import RecommendedImagesModal from 'components/assets/image_recommendations/recommendations/RecommendedImagesModal';

function RecommendationProductsTable({
  products,
  productCount,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  refetch,
}) {
  const [recommendationModalSettings, setRecommendationModalSettings] = useState({ show: false });
  return (
    <div>
      <div className="w-100 d-flex justify-content-between mb-2">
        <div className="text-secondary">
          {productCount} Product{productCount === 1 ? '' : 's'}
        </div>
        <PaginationComponent
          totalItems={productCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          defaultSize
        />
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Thumbnail</th>
            <th>SKU</th>
            <th>Name</th>
            <th>L4 Category</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {products?.length ? (
            products.map(product => (
              <ProductRow
                key={`${product.product_type}-${product.product_id}`}
                product={product}
                setRecommendationModalSettings={setRecommendationModalSettings}
              />
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No products found for the selected filters
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {recommendationModalSettings.product ? (
        <RecommendedImagesModal
          show={recommendationModalSettings.show}
          product={recommendationModalSettings.product}
          onHide={() => setRecommendationModalSettings({ show: false })}
          refetch={refetch}
        />
      ) : null}
    </div>
  );
}

RecommendationProductsTable.propTypes = {
  products: PropTypes.array,
  productCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  refetch: PropTypes.func,
};

export default RecommendationProductsTable;
