import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import Select from 'react-select';

import { ATTRIBUTES } from 'lib/networking/endpoints';
import { AttributeType } from 'lib/enums/AttributeType';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function AttributeAssignmentToTaxonomiesModal({
  onHide,
  modalHeader,
  createAttribute,
  isGlobal = true,
}) {
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const { data, attributesLoading } = useGet(
    ATTRIBUTES,
    { attribute_type: AttributeType.NON_GLOBAL_ATTRIBUTES },
    isGlobal === true,
  );

  const attributeOptions = useMemo(() => {
    if (!isEmpty(data)) {
      return data.attributes?.map(attr => ({ label: attr.attribute_name, value: attr.id }));
    }
  }, [data]);

  if (attributesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6 font-weight-bold" centered>
      <Modal.Header>
        <h5 className="px-2 font-weight-bold">{modalHeader}</h5>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form.Group>
          <Form.Label>Select Attributes</Form.Label>
          <Select
            value={selectedAttributes}
            options={attributeOptions}
            onChange={options => setSelectedAttributes(options || [])}
            isSearchable
            isClearable
            isMulti
          />
        </Form.Group>
        <Row className="pt-3 mt-3">
          <Col sm={3}>
            <Button
              block
              onClick={() => {
                createAttribute([...selectedAttributes]);
              }}
            >
              Save
            </Button>
          </Col>
          <Col sm={2} className="pl-0">
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

AttributeAssignmentToTaxonomiesModal.propTypes = {
  onHide: PropTypes.func,
  modalHeader: PropTypes.string,
  createAttribute: PropTypes.func,
  isGlobal: PropTypes.bool,
};

export default AttributeAssignmentToTaxonomiesModal;
