import React, { useState } from 'react';

import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

import Container from 'components/shared/Container';
import ManageAttributeValueDetailsView from 'components/manage_attribute_value/ManageAttributeValueDetailsView';
import ManageAttributeCategoriesAssignedView from 'components/manage_attribute_value/ManageAttributeCategoriesAssignedView';
import pointerOnHover from 'lib/css/pointerOnHover';

const MANAGE_ATTRIBUTES_TABS = {
  ATTRIBUTE_DETAILS: 'Attribute Details',
  APPLIED_CATEGORIES: 'Categories Applied To',
};

function ManageAttributeTabView() {
  const [activeTabKey, setActiveTabKey] = useState(MANAGE_ATTRIBUTES_TABS.ATTRIBUTE_DETAILS);
  const { attribute_name, attribute_type } = useParams();
  const history = useHistory();

  let decodedAttributeName;
  try {
    decodedAttributeName = decodeURIComponent(attribute_name);
  } catch (error) {
    // having a `%` on the name will throw an error when decoding
    decodedAttributeName = attribute_name;
  }

  return (
    <Container>
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mr-2')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/manage-attributes')}
        />
        {decodedAttributeName}
      </h3>
      <Tabs
        defaultActiveKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
        className="mt-4"
      >
        <Tab
          eventKey={MANAGE_ATTRIBUTES_TABS.ATTRIBUTE_DETAILS}
          title={MANAGE_ATTRIBUTES_TABS.ATTRIBUTE_DETAILS}
        >
          <ManageAttributeValueDetailsView />
        </Tab>
        {attribute_type === 'attribute' && (
          <Tab
            eventKey={MANAGE_ATTRIBUTES_TABS.APPLIED_CATEGORIES}
            title={MANAGE_ATTRIBUTES_TABS.APPLIED_CATEGORIES}
          >
            <ManageAttributeCategoriesAssignedView />
          </Tab>
        )}
      </Tabs>
    </Container>
  );
}

export default ManageAttributeTabView;
