import React, { createContext, useState } from 'react';

import { Route } from 'react-router-dom';

import ManageAttributesView from 'components/manage_attribute_value/ManageAttributesView';
import ManageAttributeTabView from 'components/manage_attribute_value/ManageAttributeTabView';

const ManageAttributeValueContext = createContext({});

function ManageAttributeValueContainer() {
  const [attributes, setAttributes] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState();
  // used in categories assigned for attributes tab
  const [categories, setCategories] = useState([]);

  return (
    <ManageAttributeValueContext.Provider
      value={{
        attributes,
        setAttributes,
        filteredAttributes,
        setFilteredAttributes,
        selectedAttribute,
        setSelectedAttribute,
        categories,
        setCategories,
      }}
    >
      <Route path="/manage-attributes" exact>
        <ManageAttributesView />
      </Route>
      <Route path="/manage-attributes/:attribute_name/:attribute_type/:attribute_id" exact>
        <ManageAttributeTabView />
      </Route>
    </ManageAttributeValueContext.Provider>
  );
}

export default ManageAttributeValueContainer;
export { ManageAttributeValueContext };
