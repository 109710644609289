import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { anchovy, grape } from 'lib/css/colors';
import EditableProperty from 'components/manage_manufacturers/manufacturer_details/EditableProperty';
import { faSquareCheck } from 'images/icons/fsa-monotone-svg-icons';
import pointerOnHover from 'lib/css/pointerOnHover';

function ManufacturerRow({ label, title, manufacturer, updateManufacturer, refetch }) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedManufacturer, setUpdatedManufacturer] = useState({ id: manufacturer.id });

  return (
    <div className={css(styles.container)}>
      <p className={css(styles.label)}>{title}</p>
      {['is_partner', 'upb_data_provider', 'requires_manufacturer_portal'].includes(label) ? (
        <div>
          <ToggleSwitch
            onColor={grape}
            checked={manufacturer[label]}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() =>
              updateManufacturer({
                id: manufacturer.id,
                [label]: !manufacturer[label],
              })
            }
          />
        </div>
      ) : isEditing ? (
        <Form
          onBlur={() => {
            if (label !== 'similar_manufacturers') {
              setIsEditing(false);
            }
          }}
          className={label === 'similar_brands' ? css(styles.rowNotEditable) : css(styles.row)}
        >
          {label !== 'similar_manufacturers' ? (
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faSquareCheck}
              onMouseDown={() => {
                updateManufacturer(updatedManufacturer);
                setIsEditing(false);
              }}
              size="lg"
            />
          ) : null}
          <EditableProperty
            label={label}
            manufacturer={manufacturer}
            setIsEditing={setIsEditing}
            setUpdatedManufacturer={setUpdatedManufacturer}
            refetch={refetch}
          />
        </Form>
      ) : (
        <div className={css(styles.row)}>
          <FontAwesomeIcon
            className={classNames(pointerOnHover, css(styles.editIcon))}
            icon={faPen}
            onClick={() => setIsEditing(true)}
          />
          {label === 'description' ? (
            <div>
              {manufacturer[label] ? (
                manufacturer[label]
                  .split('\n')
                  .map((paragraph, index) => <p key={index}>{paragraph}</p>)
              ) : (
                <p>-</p>
              )}
            </div>
          ) : label === 'similar_manufacturers' ? (
            <div>
              {manufacturer[label].length ? (
                manufacturer[label].map(similar_mfr => (
                  <p key={similar_mfr.id}>&bull; {similar_mfr.manufacturer_name}</p>
                ))
              ) : (
                <p>-</p>
              )}
            </div>
          ) : (
            <p> {manufacturer[label] || '-'}</p>
          )}
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  rowNotEditable: {
    display: 'flex',
  },
  editIcon: {
    marginRight: '0.75rem',
  },
});

ManufacturerRow.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  manufacturer: PropTypes.object,
  updateManufacturer: PropTypes.func,
  refetch: PropTypes.func,
};

export default ManufacturerRow;
