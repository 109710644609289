import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';

import DropZone from 'components/shared/Dropzone';
import pointerOnHover from 'lib/css/pointerOnHover';

function FileUploadModal({ onHide, onSubmit, header, sample_file_url = null, postBody = null }) {
  const [file, setFile] = useState();

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">{header}</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {sample_file_url ? (
          <Button
            variant="link"
            className={classNames(pointerOnHover, 'pl-0')}
            onClick={() => (window.location.href = sample_file_url)}
            disabled={!sample_file_url}
          >
            Sample File
          </Button>
        ) : null}
        <DropZone
          text="Drag and drop some files here, or click to select files"
          onUploadSuccess={newUrls => setFile(newUrls?.[0])}
          fileUrls={file ? [file.presigned_url] : []}
          isImage={false}
        />
        <div className="my-3">
          <Button
            disabled={!file}
            className="px-5 mr-2"
            onClick={() => {
              if (postBody) {
                onSubmit({ ...postBody, source_location_url: file.s3_path });
              } else {
                onSubmit({ source_location_url: file.s3_path });
              }
              onHide();
            }}
          >
            Upload
          </Button>
          <Button variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

FileUploadModal.propTypes = {
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  header: PropTypes.string,
  sample_file_url: PropTypes.string,
  postBody: PropTypes.object,
};

export default FileUploadModal;
