import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import { ATTRIBUTE_VALUES } from 'lib/networking/endpoints';

import useGet from 'lib/hooks/useGet';
function CatalogAttributeFilterValue({
  catalogFilterAttributeValue,
  updatedFilterValues,
  setUpdatedFilterValues,
}) {
  const { data: { attribute_values } = {} } = useGet(
    ATTRIBUTE_VALUES,
    {
      ...(catalogFilterAttributeValue.attribute_id && {
        attribute_id: catalogFilterAttributeValue.attribute_id,
      }),
      ...(catalogFilterAttributeValue.global_attribute_id && {
        global_attribute_id: catalogFilterAttributeValue.global_attribute_id,
      }),
    },
    !catalogFilterAttributeValue.attribute_id && !catalogFilterAttributeValue.global_attribute_id,
  );

  const attributeValuesForDropdown = attribute_values?.map(attribute_value => ({
    label: attribute_value.attribute_value,
    value: attribute_value.id,
  }));

  const handleDisplayNameChange = (event, id) => {
    setUpdatedFilterValues(prevState => ({
      ...prevState,
      attribute_values: prevState.attribute_values.map(attribute_value =>
        attribute_value.catalog_filter_attribute_value_id === id
          ? { ...attribute_value, catalog_filter_attribute_display_name: event.target.value }
          : attribute_value,
      ),
    }));
  };
  const handleAttributeValueChange = (option, id) => {
    setUpdatedFilterValues(prevState => ({
      ...prevState,
      attribute_values: prevState.attribute_values.map(attribute_value =>
        attribute_value.catalog_filter_attribute_value_id === id
          ? { ...attribute_value, attribute_value_id: option.value }
          : attribute_value,
      ),
    }));
  };
  return (
    <div>
      <div className="mt-3">
        <Form.Group as={Row} className="w-100">
          <Form.Label
            as={Col}
            type="text"
            placeholder="Filter Name"
            className="d-flex justify-content-end align-items-center"
          >
            {catalogFilterAttributeValue.attribute_name}
          </Form.Label>
          <Col>
            <Select
              options={attributeValuesForDropdown}
              placeholder="Select value"
              value={
                attributeValuesForDropdown?.find(
                  option =>
                    option.value ===
                    updatedFilterValues.attribute_values.find(
                      attr =>
                        attr.catalog_filter_attribute_value_id ===
                        catalogFilterAttributeValue.catalog_filter_attribute_value_id,
                    )?.attribute_value_id,
                ) || null
              }
              onChange={option =>
                handleAttributeValueChange(
                  option,
                  catalogFilterAttributeValue.catalog_filter_attribute_value_id,
                )
              }
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Display Name"
              value={catalogFilterAttributeValue.catalog_filter_attribute_display_name}
              onChange={event =>
                handleDisplayNameChange(
                  event,
                  catalogFilterAttributeValue.catalog_filter_attribute_value_id,
                )
              }
            />
          </Col>
        </Form.Group>
      </div>
    </div>
  );
}

CatalogAttributeFilterValue.propTypes = {
  catalogFilterAttributeValue: PropTypes.object,
  updatedFilterValues: PropTypes.object,
  setUpdatedFilterValues: PropTypes.func,
};

export default CatalogAttributeFilterValue;
