import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Row } from 'react-bootstrap';

function AddEditAttributeValueRow({
  onHide,
  attributeValueId,
  value,
  fsaAttributeValueId,
  usdaCode,
  editRecord,
  createNewAttributeValue,
}) {
  // we let the user to create multiple values at once seperated by comma
  const [newValues, setnewValues] = useState(value || '');
  const [newValueIds, setNewValueIds] = useState(fsaAttributeValueId || '');
  const [newUSDACodes, setNewUSDACodes] = useState(usdaCode || '');

  return (
    <Row className="py-3">
      <Col sm={5}>
        <Form.Control
          type="text"
          value={newValues}
          onChange={e => {
            // if its an update do not split from the ','
            if (attributeValueId) {
              setnewValues(e.target.value);
            } else {
              setnewValues(e.target.value.split(','));
            }
          }}
          placeholder="Enter values comma seperated"
        />
      </Col>
      <Col sm={3}>
        <Form.Control
          type="text"
          value={newValueIds}
          onChange={e => {
            // if its an update do not split from the ','
            if (attributeValueId) {
              setNewValueIds(e.target.value);
            } else {
              setNewValueIds(e.target.value.split(','));
            }
          }}
          placeholder={attributeValueId ? 'Enter attribute value id' : 'Enter ids comma seperated'}
        />
      </Col>
      <Col sm={2}>
        <Form.Control
          type="text"
          value={newUSDACodes}
          onChange={e => {
            // if its an update do not split from the ','
            if (attributeValueId) {
              setNewUSDACodes(e.target.value);
            } else {
              setNewUSDACodes(e.target.value.split(','));
            }
          }}
          placeholder={attributeValueId ? 'Enter usda code id' : 'Enter codes comma seperated'}
        />
      </Col>
      <Col sm={2} className="mt-1">
        <Button
          disabled={!newValues}
          onClick={() => {
            if (value) {
              editRecord({
                attributeValueId: attributeValueId,
                attributeValue: newValues,
                fsaAttributeValueId: newValueIds,
                usdaCode: newUSDACodes,
              });
            } else {
              createNewAttributeValue({
                attributeValues: newValues,
                attributeValueIds: newValueIds,
                usdaCodes: newUSDACodes,
              });
            }
          }}
        >
          Save
        </Button>
        <Button variant="outline-primary" className="ml-1" onClick={() => onHide()}>
          Cancel
        </Button>
      </Col>
    </Row>
  );
}

AddEditAttributeValueRow.propTypes = {
  onHide: PropTypes.func,
  attributeValueId: PropTypes.number,
  value: PropTypes.string,
  fsaAttributeValueId: PropTypes.number,
  usdaCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editRecord: PropTypes.func,
  createNewAttributeValue: PropTypes.func,
};

export default AddEditAttributeValueRow;
