import React, { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

import StatsCard from 'components/stats/StatsCard';
import usePost from 'lib/hooks/usePost';
import { VENDOR_PRODUCT_CLASSIFICATION_STAT } from 'lib/networking/endpoints';

function VendorProductClassificationStatView() {
  const [reCalculate, setRecalculate] = useState(false);
  const [record, setRecord] = useState({});

  const { postData: getCount, loading } = usePost(VENDOR_PRODUCT_CLASSIFICATION_STAT, res => {
    setRecalculate(false);
    setRecord(res.data.data);
  });

  useEffect(() => {
    if (isEmpty(record) || reCalculate) {
      getCount({
        re_calculate: reCalculate,
        overall_stat: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reCalculate]);

  const handleReSync = () => {
    setRecalculate(true);
  };

  return (
    <>
      <StatsCard
        cardTitle={'Classified Vendor Products Count'}
        lastSyncedTimestamp={record?.date_modified}
        value={record?.count}
        reSync={handleReSync}
        loading={loading}
      />
    </>
  );
}

export default VendorProductClassificationStatView;
