import React from 'react';
import PropTypes from 'prop-types';

import ToggleSwitch from 'react-switch';

import { grape } from 'lib/css/colors';

function VendorToggleField({ label, vendor, updateVendor }) {
  return (
    <ToggleSwitch
      onColor={grape}
      checked={vendor[label]}
      checkedIcon={false}
      uncheckedIcon={false}
      onChange={() =>
        updateVendor({
          id: vendor.id,
          [label]: !vendor[label],
        })
      }
    />
  );
}

VendorToggleField.propTypes = {
  label: PropTypes.string.isRequired,
  vendor: PropTypes.object.isRequired,
  updateVendor: PropTypes.func.isRequired,
};

export default VendorToggleField;
