import React, { createContext, useState } from 'react';

import { Route } from 'react-router-dom';

import ManageSchemaView from 'components/manage_attribute/manage_schema/ManageSchemaView';

const ManageSchemaContext = createContext({});

function ManageSchemaContainer() {
  const [selectedSchema, setSelectedSchema] = useState(null);

  return (
    <ManageSchemaContext.Provider
      value={{
        selectedSchema,
        setSelectedSchema,
      }}
    >
      <Route path="/manage-schema" exact>
        <ManageSchemaView />
      </Route>
    </ManageSchemaContext.Provider>
  );
}

export default ManageSchemaContainer;
export { ManageSchemaContext };
