import React from 'react';
import PropTypes from 'prop-types';

import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import AttributesTableView from 'components/upb_viewer/pdp_components/AttributesTableView';

function WeightAndDimensionsSection({ dimensions }) {
  if (!dimensions.length) {
    return null;
  }
  return (
    <AttributeSection title="Weights + Dimensions">
      <AttributesTableView attributes={dimensions} />
    </AttributeSection>
  );
}

WeightAndDimensionsSection.propTypes = {
  dimensions: PropTypes.array,
};

export default WeightAndDimensionsSection;
