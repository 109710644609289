import React from 'react';

import { css, StyleSheet } from 'aphrodite';
import SplitPane from 'react-split-pane';

import ProductListAttributeSplitView from 'components/product_lists/product_export/ProductListAttributeSplitView';
import ProductListSelectedAttributeSplitView from 'components/product_lists/product_export/ProductListSelectedAttributeSplitView';

function ProductListExportView() {
  return (
    <SplitPane primary="first" split="vertical" defaultSize="30%" className={css(styles.splitPane)}>
      <ProductListAttributeSplitView />
      <ProductListSelectedAttributeSplitView />
    </SplitPane>
  );
}

const styles = StyleSheet.create({
  icon: {
    height: 27,
    width: 27,
  },
  splitPane: {
    position: 'relative',
  },
});

export default ProductListExportView;
