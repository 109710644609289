import { ADVANCED_MAPPING_FUNCTIONS } from 'lib/constants';

const isAdvancedMapping = sourceAttribute => {
  // get only the function name `CONCAT` from CONCAT(ITEM PACK,' ',STOCK UOM)
  const functionName = sourceAttribute.split('(')?.[0];
  if (!functionName) {
    return false;
  }
  return Object.keys(ADVANCED_MAPPING_FUNCTIONS).includes(functionName);
};

export default isAdvancedMapping;
