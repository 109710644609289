import React from 'react';
import PropTypes from 'prop-types';

function AttributeChangeRow({ attributeChange }) {
  const productAttribute =
    attributeChange.global_product_manufacturer_attribute ||
    attributeChange.global_product_vendor_attribute;
  const attributeName =
    productAttribute?.attribute?.attribute_name ||
    productAttribute?.global_attribute?.global_attribute_name;

  return (
    <tr>
      <td>{attributeName}</td>
      <td>{attributeChange.old_value}</td>
      <td>{attributeChange.new_value}</td>
    </tr>
  );
}

AttributeChangeRow.propTypes = {
  attributeChange: PropTypes.object,
};

export default AttributeChangeRow;
