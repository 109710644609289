import React from 'react';
import PropTypes from 'prop-types';

import { Spinner } from 'react-bootstrap';

import { DATA_SOURCE_URL, getURL } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function URLField({ dataSourceId, label = 'Download' }) {
  const { data: { data: presignedUrl } = {}, loading } = useGet(
    getURL(DATA_SOURCE_URL, { data_source_id: dataSourceId }),
  );

  if (loading) {
    return (
      <Spinner animation="border" role="status" size="sm">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (!presignedUrl) {
    return null;
  }

  return (
    <a href={presignedUrl} rel="noopener noreferrer" target="_blank">
      {label}
    </a>
  );
}

URLField.propTypes = {
  dataSourceId: PropTypes.number,
  label: PropTypes.string,
};

export default URLField;
