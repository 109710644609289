import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';

import { coolGrey, squidInk, greyStroke } from 'lib/css/colors';
import { fontSize } from 'components/upb_viewer/css/fontStyles';

const colorStyles = StyleSheet.create({
  coolGrey: { color: coolGrey },
  squidInk: { color: squidInk },
});

const sectionHeaderClass = classNames(fontSize('18px', '700'), css(colorStyles.coolGrey));
const sectionsSubheaderClass = classNames(fontSize('16px', '600'), css(colorStyles.coolGrey));
const sectionTextClass = classNames(fontSize('16px', '400'), css(colorStyles.coolGrey));
const pdfSectionTextClass = classNames(fontSize('14px', '400'), css(colorStyles.squidInk));
const sectionTextBlackClass = classNames(fontSize('16px', '400'), css(colorStyles.squidInk));

const pdfTagContainerClass = css(
  StyleSheet.create({
    tag: {
      border: `1px solid ${greyStroke}`,
      borderRadius: 8,
      maxHeight: 35,
      padding: '4px 8px',
    },
  }).tag,
);

const tagContainerClass = css(
  StyleSheet.create({
    tag: {
      border: `1px solid ${greyStroke}`,
      borderRadius: 8,
      padding: '8px 12px',
    },
  }).tag,
);

const preserveFormatClass = css(
  StyleSheet.create({
    preserveFormat: {
      whiteSpace: 'pre-wrap',
    },
  }).preserveFormat,
);

export {
  pdfSectionTextClass,
  pdfTagContainerClass,
  preserveFormatClass,
  sectionHeaderClass,
  sectionsSubheaderClass,
  sectionTextBlackClass,
  sectionTextClass,
  tagContainerClass,
};
