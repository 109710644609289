import React from 'react';
import PropTypes from 'prop-types';

import MainNutritionRow from 'components/shared/nutrition_facts/MainNutritionRow';
import { NUTRITION_ATTRIBUTES } from 'components/upb_viewer/pdp_components/ProductNutritionFactsView';
import SubNutritionRow from 'components/shared/nutrition_facts/SubNutritionRow';
import useFindAttributeValueByName from 'lib/hooks/product/useFindAttributeValueByName';

function FatSection({ nutritionAttributes }) {
  const totalFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_FAT_QUANTITYCONTAINED,
  );
  const totalFatUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_FAT_UOM,
  );
  const totalFatDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_FAT_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const saturatedFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SATURATED_FAT_QUANTITYCONTAINED,
  );
  const saturatedFatUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SATURATED_FAT_UOM,
  );
  const saturatedFatDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SATURATED_FAT_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const transFattyAcidsQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TRANSFATTY_ACIDS_QUANTITYCONTAINED,
  );
  const transFattyAcidsUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TRANSFATTY_ACIDS_UOM,
  );
  const polyUnsaturatedFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.POLYUNSATURATED_FAT_QUANTITYCONTAINED,
  );
  const polyUnsaturatedFatUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.POLYUNSATURATED_FAT_UOM,
  );
  const monoUnsaturatedFatQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.MONOUNSATURATED_FAT_QUANTITYCONTAINED,
  );
  const monoUnsaturatedFatUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.MONOUNSATURATED_FAT_UOM,
  );

  if (!totalFatQuantity) {
    return null;
  }

  return (
    <>
      <MainNutritionRow
        title="Total Fat"
        quantity={totalFatQuantity}
        uom={totalFatUom}
        percentage={totalFatDailyValue}
      />
      <SubNutritionRow
        title="Saturated Fat"
        quantity={saturatedFatQuantity}
        uom={saturatedFatUom}
        percentage={saturatedFatDailyValue}
        offset={1}
        boldPercentage
      />
      <SubNutritionRow
        title={
          <span>
            <span className="font-italic">Trans </span>
            Fat
          </span>
        }
        quantity={transFattyAcidsQuantity}
        uom={transFattyAcidsUom}
        offset={1}
      />
      <SubNutritionRow
        title="Polyunsaturated Fat"
        quantity={polyUnsaturatedFatQuantity}
        uom={polyUnsaturatedFatUom}
        offset={1}
      />
      <SubNutritionRow
        title="Monounsaturated Fat"
        quantity={monoUnsaturatedFatQuantity}
        uom={monoUnsaturatedFatUom}
        offset={1}
      />
    </>
  );
}

FatSection.propTypes = {
  nutritionAttributes: PropTypes.array,
};

export default FatSection;
