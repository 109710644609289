import React, { useState } from 'react';

import { Button, Col, Form, Row, Tab, Tabs, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from 'use-debounce';

import { backgroundDarkGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { DATA_STREAMS } from 'lib/networking/endpoints';
import DataStreamViewTableRow from 'components/data_streams/DataStreamViewTableRow';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import NewDataStreamModal from 'components/data_streams/NewDataStreamModal';
import useGet from 'lib/hooks/useGet';

const DATA_STREAM_TABS = {
  DISTRIBUTOR_DATA_STREAM: 'Distributor Data Stream',
  MANUFACTURER_DATA_STREAM: 'Manufacturer Data Stream',
};

function DataStreamsView() {
  const [activeTabKey, setActiveTabKey] = useState(DATA_STREAM_TABS.DISTRIBUTOR_DATA_STREAM);
  const [showModal, setShowModal] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchQuery] = useDebounce(searchString, 400);

  const {
    data: { data: distributorDataStreams = [] } = {},
    loading,
    error,
    refetch: refetchDistributorStreams,
  } = useGet(DATA_STREAMS, {
    search_term: searchQuery,
    limit: 50,
    distributor_only: true,
  });

  const { data: { data: manufacturerrDataStreams = [] } = {}, refetch: refetcManufacturerStreams } =
    useGet(DATA_STREAMS, {
      search_term: searchQuery,
      limit: 50,
      manufacturer_only: true,
    });

  const refetchDataStreams = () => {
    refetchDistributorStreams();
    refetcManufacturerStreams();
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  const tableHeaders = (
    <thead>
      <tr>
        <th>Stream Name</th>
        <th>Type</th>
        <th>Export Type</th>
        <th>Total Sync Jobs</th>
        <th>Actions</th>
      </tr>
    </thead>
  );

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="font-weight-bold">Data Streams</h3>
        </Col>
        <Col className="ml-auto col-auto">
          <Button onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faPlus} className="mx-1" />
            New Data Stream
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Control
              onChange={e => setSearchString(e.target.value)}
              type="text"
              placeholder="Search Data Stream"
              value={searchString}
            />
          </Form.Group>
        </Col>
      </Row>
      <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
        <Tab
          eventKey={DATA_STREAM_TABS.DISTRIBUTOR_DATA_STREAM}
          title={DATA_STREAM_TABS.DISTRIBUTOR_DATA_STREAM}
        >
          <Table hover className={css(styles.mainTable)}>
            {tableHeaders}
            <tbody>
              {distributorDataStreams?.map(record => (
                <DataStreamViewTableRow
                  dataStreamRecord={record}
                  key={record.id}
                  refetch={refetchDistributorStreams}
                />
              ))}
            </tbody>
          </Table>
        </Tab>
        <Tab
          eventKey={DATA_STREAM_TABS.MANUFACTURER_DATA_STREAM}
          title={DATA_STREAM_TABS.MANUFACTURER_DATA_STREAM}
        >
          <Table hover className={css(styles.mainTable)}>
            {tableHeaders}
            <tbody>
              {manufacturerrDataStreams.map(record => (
                <DataStreamViewTableRow
                  dataStreamRecord={record}
                  key={record.id}
                  refetch={refetcManufacturerStreams}
                />
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
      {showModal ? (
        <NewDataStreamModal
          onHide={() => setShowModal(false)}
          refetch={() => refetchDataStreams()}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  mainTable: {
    backgroundColor: backgroundDarkGrey,
  },
});

export default DataStreamsView;
