import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { PRODUCT_LIST_UPDATE_PRODUCTS } from 'lib/networking/endpoints';
import BrandFilter from 'components/shared/BrandFilter';
import usePost from 'lib/hooks/usePost';

function ProductEditModal({ globalProductId, product, show, onHide, refetch }) {
  const [name, setName] = useState(product.name);
  const [packSize, setPackSize] = useState(product.pack_size);
  const [brand, setBrand] = useState({ label: product.brand_name });

  const { postData: updateProductDetails, loading: updating } = usePost(
    PRODUCT_LIST_UPDATE_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Product details updated successfully',
        title: 'Success',
        timer: 500,
      }).then(() => {
        onHide();
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while updating product details: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  return (
    <Modal animation={false} centered show={show} onHide={onHide}>
      <div>
        <Modal.Header className={css(styles.modalHeader)} closeButton />
        <Modal.Body>
          <Row>
            <Col>
              <Row className="mb-4">
                <Col>
                  <h4>
                    <strong>SKU #{product.sku}</strong>
                  </h4>
                </Col>
              </Row>
              <Row className="my-3">
                <Col>
                  <h6>
                    <strong>Name</strong>
                  </h6>
                  <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} />
                </Col>
              </Row>
              <Row className="my-3">
                <Col>
                  <h6>
                    <strong>Pack Size</strong>
                  </h6>
                  <Form.Control
                    type="text"
                    value={packSize}
                    onChange={e => setPackSize(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="my-3">
                <Col>
                  <h6>
                    <strong>Brand</strong>
                  </h6>
                  <BrandFilter brand={brand} setBrand={setBrand} />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <div className="my-3">
            <Button
              onClick={() =>
                updateProductDetails({
                  global_product_id: globalProductId,
                  product_id: product.id,
                  product_type: product.entity_type.toLowerCase(),
                  name: name,
                  pack_size: packSize,
                  brand_id: brand.value,
                  from_product_outliers_page: true,
                })
              }
              size="lg"
              className="mr-2"
              disabled={updating}
            >
              {updating ? <Spinner animation="border" role="status" size="sm" /> : 'Update'}
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    borderBottom: 'none',
  },
});

ProductEditModal.propTypes = {
  globalProductId: PropTypes.number,
  product: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default ProductEditModal;
