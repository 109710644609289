import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Button, Table } from 'react-bootstrap';

import { backgroundGrey } from 'lib/css/colors';
import { BRANDS_PRODUCTS, GLOBAL_MANUFACTURERS_PRODUCTS } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function ProductsView({ brandId, manufacturerId }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const {
    data: { brand_products = [], brand_products_count = 0 } = {},
    loading: brandProductsLoading,
  } = useGet(
    BRANDS_PRODUCTS,
    {
      brand_id: brandId,
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    },
    !brandId,
  );

  const {
    data: { manufacturer_products = [], manufacturer_products_count = 0 } = {},
    loading: manufacturerActivityLoading,
  } = useGet(
    GLOBAL_MANUFACTURERS_PRODUCTS,
    {
      manufacturer_id: manufacturerId,
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
    },
    !manufacturerId,
  );

  const products = useMemo(() => {
    if (brandId && brand_products.length) {
      return brand_products;
    } else if (manufacturerId && manufacturer_products.length) {
      return manufacturer_products;
    }
    return [];
  }, [brandId, manufacturerId, brand_products, manufacturer_products]);

  const count = useMemo(() => {
    if (brandId && brand_products_count) {
      return brand_products_count;
    } else if (manufacturerId && manufacturer_products_count) {
      return manufacturer_products_count;
    }
    return 0;
  }, [brandId, manufacturerId, brand_products_count, manufacturer_products_count]);

  if (brandProductsLoading || manufacturerActivityLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <div className="my-2 d-flex justify-content-between">
        <p>{count} products found</p>
        <PaginationComponent
          totalItems={count}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          defaultSize
        />
      </div>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            {brandId && <th>Entity Type</th>}
            {brandId && <th>Entity Name</th>}
            <th>SKU</th>
            <th>Product Name</th>
            {!brandId && <th>Brand Name</th>}
            <th>Pack Size</th>
            <th />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {products?.length ? (
            products.map((product, index) => (
              <tr key={index}>
                {brandId && <td>{product.entity_type}</td>}
                {brandId && <td>{product.entity_name}</td>}
                <td>{product.sku}</td>
                <td>{product.name}</td>
                {!brandId && <td>{product.brand_name}</td>}
                <td>{product.pack_size || '-'}</td>
                <td>
                  <Button
                    variant="link"
                    key={index}
                    className={pointerOnHover}
                    onClick={() => {
                      window.open(
                        `/global-product-detail/${product.global_product_id}/` +
                          `${product.id}/${product.entity_type.toLowerCase()}`,
                        '_blank',
                      );
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No products found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky-top',
    zIndex: 1,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

ProductsView.propTypes = {
  brandId: PropTypes.number,
  manufacturerId: PropTypes.number,
};

export default ProductsView;
