import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

import {
  getURLWithSearchParams,
  GLOBAL_VENDORS_FOR_DROPDOWN,
  GLOBAL_VENDORS_BY_IDS,
} from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';
import ProductListFilter from 'lib/enums/ProductListFilter';
import { removeEmpty } from 'lib/utils/objectUtils';
import SearchableScrollableMultiSelect from 'components/shared/SearchableScrollableMultiSelect';

function VendorFilter({ selectedValues, updateFilters }) {
  const [initOptions, setInitOptions] = useState([]);

  // initial load
  useEffect(() => {
    const url = getURLWithSearchParams(
      GLOBAL_VENDORS_BY_IDS,
      removeEmpty({
        ids: selectedValues?.length ? selectedValues.join(',') : null,
      }),
    );
    httpGet(url).then(res => {
      if (res.status === 200) {
        const vendors = res.data?.data;
        if (vendors?.length) {
          setInitOptions(vendors.map(vendor => ({ label: vendor.name, value: vendor.id })));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Group>
      <Form.Label>Select Distributors</Form.Label>
      <SearchableScrollableMultiSelect
        onChange={options =>
          updateFilters(
            ProductListFilter.DISTRIBUTOR_IDS,
            options?.map(option => option.value),
          )
        }
        fetchUrl={GLOBAL_VENDORS_FOR_DROPDOWN}
        optionMapper={vendor => ({ label: vendor.name, value: vendor.id })}
        initOptions={initOptions}
      />
    </Form.Group>
  );
}

VendorFilter.propTypes = {
  selectedValues: PropTypes.array,
  updateFilters: PropTypes.func,
};

export default VendorFilter;
