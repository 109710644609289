import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { black } from 'lib/css/colors';
import { OFFSET_STEP } from 'components/shared/nutrition_facts/sharedStyles';

const DEFAULT_SEPARATOR_THICKNESS = 1;
const MEDIUM_SEPARATOR_THICKNESS = 6;
const LARGE_SEPARATOR_THICKNESS = 14;

function Separator({ thickness = DEFAULT_SEPARATOR_THICKNESS, offset = 0 }) {
  return <hr className={classNames(css(separatorStyles(thickness, offset).separator), 'my-0')} />;
}

const separatorStyles = (thickness, offset) =>
  StyleSheet.create({
    separator: {
      borderWidth: thickness,
      borderColor: black,
      marginLeft: OFFSET_STEP * offset,
    },
  });

Separator.propTypes = {
  thickness: PropTypes.number,
  offset: PropTypes.number,
};

export default Separator;
export { DEFAULT_SEPARATOR_THICKNESS, MEDIUM_SEPARATOR_THICKNESS, LARGE_SEPARATOR_THICKNESS };
