const L4ImageStatus = {
  NOT_UPLOADED: 'NOT_UPLOADED',
  PENDING_REVIEW: 'PENDING_REVIEW',
  REVIEWED: 'REVIEWED',
  REJECTED: 'REJECTED',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const L4ImageStatusOptions = [
  { label: 'Pending Review', value: L4ImageStatus.PENDING_REVIEW },
  { label: 'Reviewed', value: L4ImageStatus.REVIEWED },
  { label: 'Rejected', value: L4ImageStatus.REJECTED },
  { label: 'Not Applicable', value: L4ImageStatus.NOT_APPLICABLE },
];

export default L4ImageStatus;
