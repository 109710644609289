import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Swal from 'sweetalert2';

import {
  ATTRIBUTES_FOR_DATA_SOURCE_MAPPING,
  ATTRIBUTE_VALUES,
  CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE,
} from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function AddNewFilterModal({ onHide, globalVendorId, refetch }) {
  const [attributesList, setAttributesList] = useState([
    {
      global_vendor_id: globalVendorId,
      name: '',
      attributes: [
        {
          attribute_id: null,
          global_attribute_id: null,
          attribute_value_id: null,
          catalog_filter_attribute_display_name: '',
        },
      ],
    },
  ]);
  const [selectedAttributeID, setSelectedAttributeID] = useState();
  const [selectedGlobalAttributeID, setSelectedGlobalAttributeID] = useState();
  const { data: { data: attributes } = {} } = useGet(ATTRIBUTES_FOR_DATA_SOURCE_MAPPING);
  const { data: { attribute_values } = {} } = useGet(
    ATTRIBUTE_VALUES,
    {
      ...(selectedAttributeID && {
        attribute_id: selectedAttributeID,
      }),
      ...(selectedGlobalAttributeID && {
        global_attribute_id: selectedGlobalAttributeID,
      }),
    },
    !selectedAttributeID && !selectedGlobalAttributeID,
  );

  const { postData: createFilter } = usePost(
    CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created',
        title: 'Success',
      });
      onHide();
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const attributeOptions = attributes?.map(attr => ({
    label: attr.name,
    value: {
      attribute_id: attr.attribute_id,
      global_attribute_id: attr.global_attribute_id,
    },
  }));

  const attributeValuesForDropdown = attribute_values?.map(attribute_value => ({
    label: attribute_value.attribute_value,
    value: attribute_value.id,
  }));

  const handleAttributeChange = (index, option) => {
    setAttributesList(prevList => [
      {
        ...prevList[0],
        attributes: prevList[0].attributes.map((attr, i) =>
          i === index
            ? {
                ...attr,
                attribute_id: option.value.attribute_id,
                global_attribute_id: option.value.global_attribute_id,
              }
            : attr,
        ),
      },
    ]);
  };

  const handleAttributeValueChange = (index, option) => {
    setAttributesList(prevList => [
      {
        ...prevList[0],
        attributes: prevList[0].attributes.map((attr, i) =>
          i === index ? { ...attr, attribute_value_id: option.value } : attr,
        ),
      },
    ]);
  };

  const handleDisplayNameChange = (index, event) => {
    setAttributesList(prevList => [
      {
        ...prevList[0],
        attributes: prevList[0].attributes.map((attr, i) =>
          i === index
            ? { ...attr, catalog_filter_attribute_display_name: event.target.value }
            : attr,
        ),
      },
    ]);
  };

  const handleNameChange = event => {
    setAttributesList(prevList => [
      {
        ...prevList[0],
        name: event.target.value,
      },
    ]);
  };

  const addNewAttributeSection = () => {
    setAttributesList(prevList => [
      {
        ...prevList[0],
        attributes: [
          ...prevList[0].attributes,
          {
            attribute_id: null,
            global_attribute_id: null,
            attribute_value_id: null,
            catalog_filter_attribute_display_name: '',
          },
        ],
      },
    ]);
  };
  const handleDeleteAttribute = index => {
    setAttributesList(prevList => [
      {
        ...prevList[0],
        attributes: prevList[0].attributes.filter((_, i) => i !== index),
      },
    ]);
  };
  const handleMenuOpen = index => {
    setSelectedAttributeID(attributesList[0].attributes[index].attribute_id);
    setSelectedGlobalAttributeID(attributesList[0].attributes[index].global_attribute_id);
  };
  const handleCreateFilter = () => {
    createFilter(attributesList[0]);
  };
  return (
    <Modal centered show={true} onHide={onHide} size="xl">
      <Modal.Header className="py-2 my-2" closeButton>
        <h5 className="font-weight-bold">Create New Filter</h5>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col md={1}>
            <h5 className="font-weight-bold">Name:</h5>
          </Col>
          <Col md={6}>
            <Form.Control
              type="text"
              placeholder="Name"
              value={attributesList[0].name}
              onChange={event => handleNameChange(event)}
            />
          </Col>
        </Row>
        {attributesList[0].attributes.map((_, index) => (
          <Col key={index} className={classNames(css(styles.filterContainer), 'my-2 p-3')}>
            <Row>
              <Col className="d-flex justify-content-center">
                <Form.Label>Attribute</Form.Label>
              </Col>
              <Col className="d-flex justify-content-center">
                <Form.Label>Attribute Value</Form.Label>
              </Col>
              <Col className="d-flex justify-content-center">
                <Form.Label>Display Name</Form.Label>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Select
                  options={attributeOptions}
                  onChange={option => handleAttributeChange(index, option)}
                />
              </Col>
              <Col>
                <Select
                  options={attributeValuesForDropdown}
                  onChange={option => handleAttributeValueChange(index, option)}
                  onMenuOpen={() => handleMenuOpen(index)}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter display name"
                  value={attributesList[0].attributes[index].catalog_filter_attribute_display_name}
                  onChange={event => handleDisplayNameChange(index, event)}
                />
              </Col>
              <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                <FontAwesomeIcon
                  className="mr-2 mt-2"
                  icon={faTrashAlt}
                  onClick={() => handleDeleteAttribute(index)}
                />
              </OverlayTrigger>
            </Row>
          </Col>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={addNewAttributeSection}>
          Add New Attribute
        </Button>
        <Button variant="primary" onClick={handleCreateFilter}>
          Create Filter
        </Button>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddNewFilterModal.propTypes = {
  onHide: PropTypes.func,
  globalVendorId: PropTypes.number,
  refetch: PropTypes.func,
};

const styles = StyleSheet.create({
  filterContainer: {
    border: '1px solid #e5e5e5',
  },
});

export default AddNewFilterModal;
