import Swal from 'sweetalert2';

const SwalDialog = (type, message, title = 'Success', position = 'top-middle', callback) => {
  Swal.fire({
    position: position,
    icon: type,
    text: message,
    title: title,
    showConfirmButton: true,
  }).then(() => callback && callback());
};

const areYouSure = (action, text, title) => {
  Swal.fire({
    title: title || 'Are you sure?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    text,
    customClass: {
      cancelButton: 'order-1',
      confirmButton: 'order-2',
    },
  }).then(result => {
    if (result.value) {
      action();
    }
  });
};

const SwalToast = (type, message, position) => {
  Swal.fire({
    toast: true,
    title:
      '<div style="display: flex; align-items: center;"><i class="icon"></i>' + message + '</div>',
    icon: type,
    position: position ? position : 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
};

export default SwalDialog;
export { areYouSure, SwalToast };
