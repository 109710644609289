import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

import { CONTENT_VERIFICATION_STATUS_OPTIONS } from 'lib/enums/ContentVerificationStatus';

function DataSourceEditTabFlagUpdateImportTypeOptions({ metaData, setMetaData }) {
  return (
    <Form.Group className="d-flex align-items-center" as={Row}>
      <Form.Label column>
        Update content verification status
        <OverlayTrigger
          overlay={<Tooltip>Whether to update the content verification status on products</Tooltip>}
          placement="top"
          delayShow={300}
          delayHide={150}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
        </OverlayTrigger>
      </Form.Label>
      <Col md={7}>
        <Select
          isClearable
          value={
            CONTENT_VERIFICATION_STATUS_OPTIONS.filter(
              op => op.value === metaData.verify_content_status,
            ) || null
          }
          options={CONTENT_VERIFICATION_STATUS_OPTIONS}
          onChange={option => {
            setMetaData({ ...metaData, verify_content_status: option?.value || null });
          }}
        />
      </Col>
    </Form.Group>
  );
}

DataSourceEditTabFlagUpdateImportTypeOptions.propTypes = {
  metaData: PropTypes.object.isRequired,
  setMetaData: PropTypes.func.isRequired,
};

export default DataSourceEditTabFlagUpdateImportTypeOptions;
