import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ceil, floor, range } from 'lodash';
import { css, StyleSheet } from 'aphrodite';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination } from 'react-bootstrap';

// Make sure these are >= 5 so that it can accommodate:
// first, current, last page + 2x ellipsis blocks in the worst case
const MAX_PAGES_FOR_PAGING = 7;
const ELLIPSIS = '...';

function PaginationBar({ totalPages, currentPageNumber, onPageChange }) {
  const pageTilesToDisplay = useMemo(() => {
    if (totalPages <= MAX_PAGES_FOR_PAGING) {
      return range(totalPages);
    }

    const lastPageIndex = totalPages - 1;
    const numberOfMidTiles = MAX_PAGES_FOR_PAGING - 2;
    const lowerDisplayIndex = numberOfMidTiles - 1;
    const upperDisplayIndex = lastPageIndex - (numberOfMidTiles - 1);

    let pageTiles;
    if (currentPageNumber < lowerDisplayIndex) {
      // pattern => 1 | 2 | 3 | 4 | ... | 100
      pageTiles = [...range(numberOfMidTiles), ELLIPSIS, lastPageIndex];
    } else if (currentPageNumber > upperDisplayIndex) {
      // pattern => 1 | ... | 97 | 98 | 99 | 100
      pageTiles = [0, ELLIPSIS, ...range(totalPages - numberOfMidTiles, totalPages)];
    } else {
      // pattern => 1 | ... | 56 | 57 | 58 | ... | 100
      const offset = (numberOfMidTiles - 3) / 2;
      const leftOffset = floor(offset);
      const rightOffset = ceil(offset);
      pageTiles = [
        0,
        ELLIPSIS,
        ...range(currentPageNumber - leftOffset, currentPageNumber + rightOffset + 1),
        ELLIPSIS,
        lastPageIndex,
      ];
    }

    return pageTiles;
  }, [currentPageNumber, totalPages]);

  if (totalPages <= 1) {
    // hide when no pages to switch to
    return null;
  }

  return (
    <Pagination bsPrefix="pagination global_pagination">
      <Pagination.First onClick={() => onPageChange(0)}>
        <FontAwesomeIcon className={css(styles.doubleChevron)} icon={faAngleDoubleLeft} />
      </Pagination.First>
      <Pagination.Prev
        onClick={() => onPageChange(currentPageNumber === 0 ? 0 : currentPageNumber - 1)}
      >
        <FontAwesomeIcon className={css(styles.singleChevron)} icon={faAngleLeft} />
      </Pagination.Prev>

      {pageTilesToDisplay.map((page, index) =>
        page === ELLIPSIS ? (
          <Pagination.Ellipsis key={index} />
        ) : (
          <Pagination.Item
            active={currentPageNumber === page}
            onClick={() => onPageChange(page)}
            key={index}
            className={css(styles.pageNumber)}
          >
            {page + 1}
          </Pagination.Item>
        ),
      )}

      <Pagination.Next
        onClick={() =>
          onPageChange(
            currentPageNumber + 1 === totalPages ? currentPageNumber : currentPageNumber + 1,
          )
        }
      >
        <FontAwesomeIcon className={css(styles.singleChevron)} icon={faAngleRight} />
      </Pagination.Next>
      <Pagination.Last onClick={() => onPageChange(totalPages - 1)}>
        <FontAwesomeIcon className={css(styles.doubleChevron)} icon={faAngleDoubleRight} />
      </Pagination.Last>
    </Pagination>
  );
}

const styles = StyleSheet.create({
  singleChevron: {
    marginLeft: 3.625,
    marginRight: 3.625,
    color: '#475467',
  },
  doubleChevron: {
    marginLeft: 1,
    marginRight: 1,
    color: '#475467',
  },
  pageNumber: {
    borderRadius: 3,
    color: '#475467',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: '#fff',
      color: '#475467',
      borderColor: '#475467',
    },
    ':focus': {
      backgroundColor: '#fff',
      color: '#475467',
      borderColor: '#475467',
    },
    minWidth: 40,
    textAlign: 'center',
  },
});

PaginationBar.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPageNumber: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PaginationBar;
