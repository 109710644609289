import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { useResizeDetector } from 'react-resize-detector';

import { backgroundColorStyles, coolGrey } from 'lib/css/colors';

function ShowMore({
  max = 100,
  className,
  children,
  onClick,
  onSeeMoreClick,
  defaultState = true,
  showText,
}) {
  const [contentOverflow, setContentOverflow] = useState(false);
  const [showMore, setShowMore] = useState(defaultState);

  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 300,
    refreshOptions: { trailing: true },
  });

  useEffect(() => {
    if (height && height >= max) {
      setContentOverflow(true);
    }
  }, [height, max]);

  const styles = stylesCss(max);

  return (
    <div
      className={classNames(className, css(showMore && styles.compressed), 'position-relative')}
      ref={ref}
    >
      <div className={css(contentOverflow && styles.contentOverflow)} onClick={onClick}>
        {children}
      </div>
      {contentOverflow && (
        <div
          className={css(showMore ? styles.showMoreBtn : styles.showMoreExpanded)}
          style={showMore ? rawStyles.gradient : {}}
        >
          <div className={css(backgroundColorStyles.white)}>
            <Button
              variant="link"
              onClick={() => {
                onSeeMoreClick && onSeeMoreClick();
                setShowMore(!showMore);
              }}
              className={classNames(css(styles.linkButton), 'border-0 pl-2')}
            >
              {showMore ? showText || 'Show More' : 'Show Less'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const stylesCss = max =>
  StyleSheet.create({
    linkButton: {
      fontSize: '16px',
      fontWeight: 500,
      color: coolGrey,
    },
    compressed: {
      maxHeight: max,
      overflow: 'hidden',
    },
    contentOverflow: {
      position: 'relative',
      marginBottom: 40,
    },
    showMoreBtn: {
      paddingTop: '3rem',
      width: '100%',
      position: 'absolute',
      bottom: 0,
    },
    showMoreExpanded: {
      position: 'absolute',
      bottom: -40,
    },
  });

const rawStyles = {
  gradient: {
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
  },
};

ShowMore.propTypes = {
  max: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onSeeMoreClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  defaultState: PropTypes.bool,
  showText: PropTypes.string,
};

export default ShowMore;
