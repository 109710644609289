import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { grape } from 'lib/css/colors';

function SelectedAttributesSplitViewRow({ eventHandler, objectValue, handleUpdate, provided }) {
  return (
    <div ref={provided?.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}>
      <Row>
        <Col sm="5">
          <Form.Check
            className="pl-4"
            type="checkbox"
            checked={true}
            onChange={() => eventHandler(objectValue)}
            label={objectValue.attributeName}
          />
        </Col>
        <Col sm="5">
          <Form.Control
            placeholder="canonical_products"
            type="text"
            value={objectValue.columnName}
            onChange={e => handleUpdate(objectValue, e.target.value, objectValue.isRequired)}
          />
        </Col>
        <Col sm="2">
          <ToggleSwitch
            checked={objectValue.isRequired}
            onColor={grape}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() =>
              handleUpdate(objectValue, objectValue.columnName, !objectValue.isRequired)
            }
            className="ml-3"
          />
        </Col>
      </Row>
    </div>
  );
}

SelectedAttributesSplitViewRow.propTypes = {
  eventHandler: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  objectValue: PropTypes.object.isRequired,
  provided: PropTypes.object,
};

export default SelectedAttributesSplitViewRow;
