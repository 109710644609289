import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { backgroundLightBlue, white } from 'lib/css/colors';
import CascadeCategoryDropdown from 'components/shared/CascadeCategoryDropdown';
import CategorizedDatePicker from 'components/shared/CategorizedDatePicker';
import { GlobalProductsContext } from 'components/global_products/GlobalProductsContainer';
import {
  CATEGORY_LEVELS,
  GLOBAL_PRODUCTS_TABLE_ID,
  SORTABLE_GLOBAL_PRODUCT_COLUMNS,
} from 'lib/constants';
import GlobalProductsTableCell from 'components/global_products/global_products_view/GlobalProductsTableCell';
import ResizableTable from 'components/shared/ResizableTable';
import { SELECT_ALL_ON_PAGE } from 'lib/enums/ProductStatus';
import TableHeader from 'components/shared/TableHeader';
import useTaxonomyOptions from 'lib/hooks/useTaxonomyOptions';

function GlobalProductsTable({ setSelectedIDsWithShift }) {
  const {
    view,
    products,
    onColumnSearch,
    searchFilters,
    sortConfig,
    setSortConfig,
    startDate,
    endDate,
    setDateRange,
    selectedIDs,
  } = useContext(GlobalProductsContext);
  const columns = view?.columns?.filter(c => c.visible).map(c => c.name);

  const { options: { l0Options, l1Options, l2Options, l3Options, l4Options } = {} } =
    useTaxonomyOptions(
      searchFilters[CATEGORY_LEVELS.L0_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L1_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L2_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L3_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L4_CATEGORY],
      true,
    );

  const optionMap = {
    [CATEGORY_LEVELS.L0_CATEGORY]: l0Options,
    [CATEGORY_LEVELS.L1_CATEGORY]: l1Options,
    [CATEGORY_LEVELS.L2_CATEGORY]: l2Options,
    [CATEGORY_LEVELS.L3_CATEGORY]: l3Options,
    [CATEGORY_LEVELS.L4_CATEGORY]: l4Options,
  };
  return (
    <ResizableTable
      resizable={true}
      resizeOptions={{
        resizeMode: 'overflow',
        liveDrag: true,
        draggingClass: 'drag',
        minWidth: 20,
        serialize: true,
        partialRefresh: true,
      }}
      className={css(styles.globalProductsTable)}
      tableID={GLOBAL_PRODUCTS_TABLE_ID}
      columns={columns}
    >
      <thead className={css(styles.thead)}>
        <tr>
          <th className="px-1 py-1">
            <Form.Check
              checked={selectedIDs.length === products?.length}
              type="checkbox"
              onChange={event => {
                const ids = products.map(
                  product => `${product.product_type}-${product.id}-${product.global_product_id}`,
                );
                setSelectedIDsWithShift(event, ids, SELECT_ALL_ON_PAGE);
              }}
            />
          </th>
          {columns?.map(column =>
            column.includes('category') && !column.includes('vendor') ? (
              <CascadeCategoryDropdown
                key={column}
                title={column}
                options={optionMap[column]}
                searchTerm={searchFilters[column]}
                setSearchTerm={(searchText, searchId) =>
                  onColumnSearch(column, searchText, searchId)
                }
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            ) : column === 'categorized_at' ? (
              <CategorizedDatePicker
                key={column}
                title={column}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
              />
            ) : (
              <TableHeader
                title={column}
                key={column}
                onSearch={searchText => onColumnSearch(column, searchText)}
                value={searchFilters[column] || ''}
                sortable={SORTABLE_GLOBAL_PRODUCT_COLUMNS.includes(column)}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            ),
          )}
        </tr>
      </thead>
      <tbody className={css(styles.tbody)}>
        {products.length ? (
          products.map(product => (
            <tr
              key={`${product.product_type}-${product.id}`}
              className={
                selectedIDs.includes(
                  `${product.product_type}-${product.id}-${product.global_product_id}`,
                )
                  ? css(styles.highlighted)
                  : null
              }
            >
              <td className="px-1 py-0">
                <Form.Check
                  type="checkbox"
                  checked={selectedIDs.includes(
                    `${product.product_type}-${product.id}-${product.global_product_id}`,
                  )}
                  onChange={event =>
                    setSelectedIDsWithShift(
                      event,
                      `${product.product_type}-${product.id}-${product.global_product_id}`,
                    )
                  }
                  className="py-2"
                />
              </td>
              {columns?.map(col => {
                if (col === 'name') {
                  return (
                    <td key={col} className="p-1">
                      <Link
                        to={
                          `/global-product-detail/${product.global_product_id}/` +
                          `${product.id}/${product.product_type}`
                        }
                        className={css(styles.productName)}
                      >
                        {product.name}
                      </Link>
                    </td>
                  );
                } else {
                  return (
                    <td className="p-1" key={col}>
                      <GlobalProductsTableCell product={product} column={col} />
                    </td>
                  );
                }
              })}
            </tr>
          ))
        ) : (
          <>
            <tr>
              {columns?.map(col => (
                <td key={col} />
              ))}
              <td />
            </tr>
          </>
        )}
      </tbody>
    </ResizableTable>
  );
}

const styles = StyleSheet.create({
  globalProductsTable: {
    fontSize: '0.65rem',
  },
  productName: {
    textDecoration: 'none',
    color: 'inherit',
    maxWidth: '200px',
  },
  thead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: white,
    minWidth: '15px',
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
  highlighted: {
    backgroundColor: backgroundLightBlue,
  },
});

GlobalProductsTable.propTypes = {
  setSelectedIDsWithShift: PropTypes.func,
};

export default GlobalProductsTable;
