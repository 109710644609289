import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faArrowRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { salmon } from 'lib/css/colors';
import Select from 'react-select';

import { areYouSure } from 'lib/utils/toast';
import {
  ATTRIBUTE_VALUES,
  DATA_SOURCE_AUTO_MAP,
  DATA_SOURCE_UPDATE,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePut from 'lib/hooks/usePut';

function AttributeValueMappingModal({
  selectedOption,
  sourceAttribute,
  onHide,
  dataSource,
  refetch,
}) {
  const [columnValueMap, setColumnValueMap] = useState(
    dataSource?.column_value_map?.[sourceAttribute] || {},
  );

  const [valueMappingToDisplay, setValueMappingToDisplay] = useState(
    Object.keys(dataSource?.column_value_map?.[sourceAttribute]).map(columnValue => ({
      [columnValue]: { fsa_attribute_value: columnValueMap[columnValue], auto_mapped: 0 },
    })) || {},
  );

  const { data: { attribute_values } = {} } = useGet(
    ATTRIBUTE_VALUES,
    {
      ...(selectedOption.attributeID && { attribute_id: selectedOption.attributeID }),
      ...(selectedOption.globalAttributeID && {
        global_attribute_id: selectedOption.globalAttributeID,
      }),
    },
    !selectedOption.attributeID && !selectedOption.globalAttributeID,
  );
  const loading = false;

  const { data: { updated_column_value_mapping } = {}, refetch: refetchColumnValues } = useGet(
    DATA_SOURCE_AUTO_MAP,
    {
      mapping: JSON.stringify(valueMappingToDisplay), //to encode the array
      fsa_attribute: selectedOption.label,
    },
  );

  const { putData: updateDataSource } = usePut(DATA_SOURCE_UPDATE, () => {
    refetch();
    onHide();
    SwalDialog('success', `Successfully Updated the Column Value Map`, 'Success', 'center');
  });

  const attributeValueOptions = attribute_values?.map(value => ({
    value: value.attribute_value,
    label: value.attribute_value,
  }));

  const handleColumnValueMapChange = (source, option, index, mapping) => {
    setValueMappingToDisplay(prevState => {
      const updatedMap = [...prevState];
      updatedMap[index] = {
        [source]: {
          ...mapping,
          fsa_attribute_value: option?.value || null,
          auto_mapped: 0,
        },
      };
      return updatedMap;
    });
    setColumnValueMap(prevState => ({
      ...prevState,
      [source]: option?.value || null,
    }));
  };
  return (
    <Modal size="xl" show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Map Source Attribute Values</Modal.Title>
        <Button
          className="ml-2"
          variant="outline-primary"
          onClick={() => {
            refetchColumnValues();
            setValueMappingToDisplay([...updated_column_value_mapping]);
            const transformed = updated_column_value_mapping.reduce((acc, item) => {
              const key = Object.keys(item)[0];
              acc[key] = item[key].fsa_attribute_value;
              return acc;
            }, {});
            setColumnValueMap(transformed);
          }}
        >
          Auto Map
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Table striped>
          <thead>
            <tr>
              <th>Source Attribute Values</th>
              <th />
              <th />
              <th>FSA Attribute Values</th>
            </tr>
          </thead>
          {loading ? (
            <div className={css(styles.spinnerOverlay)}>
              <LoadingSpinner />
            </div>
          ) : (
            <tbody>
              {valueMappingToDisplay.map((item, index) => {
                const [source, mapping] = Object.entries(item)[0];
                return (
                  <tr key={index}>
                    <td>{source}</td>
                    <td>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </td>
                    <td>
                      {mapping.auto_mapped ? (
                        <OverlayTrigger overlay={<Tooltip>Auto mapped</Tooltip>}>
                          <FontAwesomeIcon icon={faExclamationCircle} color={salmon} />
                        </OverlayTrigger>
                      ) : null}
                    </td>
                    <td>
                      <Select
                        value={
                          attributeValueOptions?.find(
                            option => option.value === mapping.fsa_attribute_value,
                          ) || null
                        }
                        options={attributeValueOptions}
                        onChange={option =>
                          handleColumnValueMapChange(source, option, index, mapping)
                        }
                        isClearable
                        classNamePrefix="react-select"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            areYouSure(() => {
              updateDataSource({
                data_source_id: dataSource.id,
                column_value_map: {
                  ...dataSource?.column_value_map,
                  [sourceAttribute]: columnValueMap,
                },
              });
            }, 'Are you sure You want to Save The Column Value Mapping?');
          }}
        >
          Save Source Attrubute Value Mappings
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  spinnerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});

AttributeValueMappingModal.propTypes = {
  onHide: PropTypes.func,
  selectedOption: PropTypes.object,
  sourceAttribute: PropTypes.string,
  dataSource: PropTypes.object,
  refetch: PropTypes.func,
};

export default AttributeValueMappingModal;
