import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useParams } from 'react-router-dom';

import { GLOBAL_VENDOR_UPDATE_IMAGE_FLAG } from 'lib/networking/endpoints';
import { IMAGE_FLAG_TYPES_OPTIONS } from 'lib/enums/ImageFlagTypeEnum';
import { SwalToast } from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function ImageFlagDropdown({ imageFlag }) {
  const { vendorID } = useParams();
  const [imageFlagType, setImageFlagType] = useState(imageFlag || null);
  const { postData: update } = usePost(
    GLOBAL_VENDOR_UPDATE_IMAGE_FLAG,
    () => SwalToast('success', `Successfully Updated`),
    () => SwalToast('error', `Error Occurred`),
  );
  return (
    <>
      <Row>
        <Col sm="4">
          <Form.Group>
            <Form.Label>Select Image Flag</Form.Label>
            <Select
              options={IMAGE_FLAG_TYPES_OPTIONS}
              value={IMAGE_FLAG_TYPES_OPTIONS.find(op => op.value === imageFlagType)}
              onChange={option => {
                setImageFlagType(option.value || null);
                update({ fsa_global_vendor_id: vendorID, image_flag: option.value });
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

ImageFlagDropdown.propTypes = {
  imageFlag: PropTypes.string,
};

export default ImageFlagDropdown;
