import React, { useState } from 'react';

import { Col, Form, Row, Table } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

import Container from 'components/shared/Container';
import { DATA_SOURCE_ALL } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function DataSourcesToValidateTable() {
  const history = useHistory();
  const [searchString, setSearchString] = useState('');
  const [searchQuery] = useDebounce(searchString, 400);

  const { data: { data: dataSources = [] } = {}, loading } = useGet(DATA_SOURCE_ALL, {
    search_term: searchQuery,
    limit: 500,
    exclude_recurring_data_sources: false,
    include_should_verify_content_only: true,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Control
              onChange={e => setSearchString(e.target.value)}
              type="text"
              placeholder="Search Data Source"
              value={searchString}
            />
          </Form.Group>
        </Col>
      </Row>
      <Table striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Entity Type</th>
            <th># of Products</th>
          </tr>
        </thead>
        <tbody>
          {dataSources.map(dataSource => (
            <tr
              key={dataSource.id}
              className={pointerOnHover}
              onClick={() =>
                history.push(`/product-content-validator/data-source/${dataSource.id}`)
              }
            >
              <td>{dataSource.source_name}</td>
              <td>{dataSource.source_type}</td>
              <td>{dataSource.entity_type}</td>
              <td>{dataSource.product_count}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default DataSourcesToValidateTable;
