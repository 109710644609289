import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Col, Row } from 'react-bootstrap';

import { anchovy } from 'lib/css/colors';
import { GLOBAL_VENDORS_UPDATE } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import VendorEditableField from './VendorEditableField';
import VendorToggleField from './VendorToggleField';

function VendorRow({ label, title, vendor, refetch }) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedVendor, setUpdatedVendor] = useState({ id: vendor.id });

  const { postData: updateVendor, loading } = usePost(
    GLOBAL_VENDORS_UPDATE,
    () => {
      SwalDialog('success', 'Successfully updated vendor', 'Success', 'center', refetch());
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while updating the vendor: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <div className={css(styles.container)}>
      {label === 'is_dp_partner' ? (
        <Row>
          <Col>
            <p className={css(styles.label)}>{title}</p>
            <div>
              <VendorToggleField label={label} vendor={vendor} updateVendor={updateVendor} />
            </div>
          </Col>
          {vendor.is_dp_partner && (
            <Col>
              <p className={css(styles.label)}>Is the dp partner currently being onboarded</p>
              <div>
                <VendorToggleField
                  label="is_currently_onboarding"
                  vendor={vendor}
                  updateVendor={updateVendor}
                />
              </div>
            </Col>
          )}
        </Row>
      ) : (
        <>
          <p className={css(styles.label)}>{title}</p>
          {[
            'cnd_id',
            'name',
            'vendor_url',
            'excluded_p0_sync_attributes',
            'excluded_cnd_sync_fields',
            'rejected_brand_names',
            'chains',
            'vendor_category',
          ].includes(label) ? (
            <VendorEditableField
              label={label}
              vendor={vendor}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setUpdatedVendor={setUpdatedVendor}
              updatedVendor={updatedVendor}
              updateVendor={updateVendor}
            />
          ) : (
            <div>
              <VendorToggleField label={label} vendor={vendor} updateVendor={updateVendor} />
            </div>
          )}
        </>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  editIcon: {
    marginRight: '0.75rem',
  },
});

VendorRow.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  vendor: PropTypes.object,
  refetch: PropTypes.func,
};

export default VendorRow;
