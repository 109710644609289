import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'react-bootstrap';

import RadioButton from 'components/shared/RadioButton';

function ProductListSelectedAttributeSplitRow({ eventHandler, colValue, objectValue }) {
  return (
    <Row>
      <RadioButton
        box
        className="ml-4 mt-1"
        checked={true}
        small
        onClick={() => eventHandler(objectValue)}
      />
      <span className="ml-4 mt-1">{colValue}</span>
    </Row>
  );
}

ProductListSelectedAttributeSplitRow.propTypes = {
  eventHandler: PropTypes.func.isRequired,
  colValue: PropTypes.string.isRequired,
  objectValue: PropTypes.string.isRequired,
};

export default ProductListSelectedAttributeSplitRow;
