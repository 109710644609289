const DATA_STREAM_TYPE = {
  SFTP: 'SFTP',
  S3: 'S3',
};

const DATA_STREAM_TYPE_OPTIONS = Object.entries(DATA_STREAM_TYPE).map(([value, label]) => ({
  label,
  value,
}));

const DATA_STREAM_EXPORT_TYPE = {
  CSV: 'CSV',
  JSON: 'JSON',
};

const DATA_STREAM_EXPORT_TYPE_OPTIONS = Object.entries(DATA_STREAM_EXPORT_TYPE).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

const DATA_STREAM_ENTITY = {
  MANUFACTURER: 'MANUFACTURER',
  VENDOR: 'VENDOR',
};

const DATA_STREAM_ENTITY_OPTIONS = [
  { value: DATA_STREAM_ENTITY.MANUFACTURER, label: 'Manufacturer' },
  { value: DATA_STREAM_ENTITY.VENDOR, label: 'Distributor' },
];

export {
  DATA_STREAM_TYPE,
  DATA_STREAM_TYPE_OPTIONS,
  DATA_STREAM_EXPORT_TYPE,
  DATA_STREAM_EXPORT_TYPE_OPTIONS,
  DATA_STREAM_ENTITY,
  DATA_STREAM_ENTITY_OPTIONS,
};
