import React, { useState } from 'react';

import { Button, Col, Row, Table } from 'react-bootstrap';

import AddUserModal from 'components/settings/team/AddUserModal';
import Container from 'components/shared/Container';
import EditRolesModal from 'components/settings/team/EditRolesModal';
import EditUserModal from 'components/settings/team/EditUserModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import { ROLES, USERS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function TeamView() {
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [showEditRolesModal, setShowEditRolesModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const hasUserPermissions = useUserPermissions();

  const {
    data: { data: users = [] } = {},
    loading: usersLoading,
    refetch: refetchUsers,
  } = useGet(USERS);

  const { data: { data: roles = [] } = {}, refetch: refetchRoles } = useGet(ROLES);
  const rolesOptions = roles.map(role => {
    return { label: role.name, value: role.name };
  });

  if (usersLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <div className="pb-3">
        <Row>
          <Col>
            <h3 className="font-weight-bold">Team</h3>
          </Col>
          <Col className="text-right">
            {hasUserPermissions([UserPermission.MANAGE_USERS]) ? (
              <Button
                variant="outline-primary"
                className="px-4 mr-2"
                onClick={() => setShowAddUserModal(true)}
              >
                Add User
              </Button>
            ) : null}
            {hasUserPermissions([UserPermission.CREATE_EDIT_ROLE]) ? (
              <Button
                variant="outline-primary"
                className="px-4 ml-2"
                onClick={() => setShowEditRolesModal(true)}
              >
                Edit Roles
              </Button>
            ) : null}
          </Col>
        </Row>
        <Table bordered striped hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => {
              return (
                <tr
                  key={user.id}
                  className={pointerOnHover}
                  onClick={() => {
                    if (hasUserPermissions([UserPermission.ASSIGN_USER_ROLE])) {
                      setShowUserEditModal(true);
                      setSelectedUser(user);
                    }
                  }}
                >
                  <td className="py-2">{user.name}</td>
                  <td className="py-2">{user.email}</td>
                  <td className="py-2">{user.role?.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <EditUserModal
        show={showUserEditModal}
        onHide={() => setShowUserEditModal(false)}
        user={selectedUser}
        rolesOptions={rolesOptions}
      />
      <EditRolesModal
        show={showEditRolesModal}
        onHide={() => setShowEditRolesModal(false)}
        roles={roles}
        rolesOptions={rolesOptions}
        refetch={() => {
          refetchUsers();
          refetchRoles();
        }}
      />
      <AddUserModal
        rolesOptions={rolesOptions}
        show={showAddUserModal}
        onHide={() => setShowAddUserModal(false)}
      />
    </Container>
  );
}

export default TeamView;
