import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';

function LoadingSpinner({ short = false }) {
  return (
    <div
      className={classNames(
        css(short ? styles.shortContainer : styles.container),
        'w-100 d-flex align-items-center justify-content-center',
      )}
    >
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100vh',
  },
  shortContainer: {
    height: '20hvh',
  },
});

LoadingSpinner.propTypes = {
  short: PropTypes.bool,
};

export default LoadingSpinner;
