import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';

import {
  CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE,
  CATALOG_FILTER_PRESETS_FOR_DROPDOWN,
  CATALOG_FILTER_PRESET,
} from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function AddNewFilterFromPresetsModal({ onHide, globalVendorId, refetch }) {
  const [selectedPresetID, setSelectedPresetID] = useState();
  const { data: { data: filter_presets } = {} } = useGet(CATALOG_FILTER_PRESETS_FOR_DROPDOWN);

  const { data: { data: preset } = {} } = useGet(CATALOG_FILTER_PRESET, {
    catalog_preset_id: selectedPresetID,
  });

  const { postData: createFilter } = usePost(
    CATALOG_FILTER_ATTRIBUTES_VENDOR_CREATE,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created',
        title: 'Success',
      });
      onHide();
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const preset_options_for_dropdown = filter_presets?.map(preset => ({
    label: preset.preset_name,
    value: preset.preset_id,
  }));

  const handleCreate = () => {
    createFilter({
      global_vendor_id: globalVendorId,
      name: preset?.name,
      attributes: preset?.catalog_filter_attribute_values?.map(attribute_value => ({
        attribute_value_id: attribute_value?.attribute_value_id,
        catalog_filter_attribute_display_name: attribute_value?.display_name,
      })),
    });
  };
  const handleChange = option => {
    setSelectedPresetID(option.value);
  };
  return (
    <Modal centered show={true} onHide={onHide} size="xl">
      <Modal.Header className="py-2 my-2" closeButton>
        <h5 className="font-weight-bold">Create New Filter From a Preset</h5>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={2} className="d-flex align-items-center">
            <h5 className="font-weight-bold">Preset Name :</h5>
          </Col>
          <Col className="d-felx justify-content-start">
            <Select
              className="ml-2"
              placeholder="Select the Preset"
              options={preset_options_for_dropdown}
              onChange={option => handleChange(option)}
            />
          </Col>
        </Row>
        <div className=" mt-4">
          {preset?.catalog_filter_attribute_values?.map((attribute_value, index) => (
            <Col key={index}>
              <Row className="d-flex justify-content-center ml-2">
                <Form.Group as={Row} className="w-100">
                  <Form.Label
                    as={Col}
                    type="text"
                    placeholder="Filter Name"
                    className="d-flex justify-content-end align-items-center"
                  >
                    {attribute_value?.attribute_value?.attributes
                      ? attribute_value?.attribute_value?.attributes?.attribute_name
                      : attribute_value?.attribute_value?.global_attribute?.global_attribute_name}
                  </Form.Label>
                  <Col>
                    <Select
                      value={attribute_value?.attribute_value?.attribute_value}
                      placeholder={attribute_value?.attribute_value?.attribute_value}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Display Name"
                      value={attribute_value?.display_name}
                    />
                  </Col>
                </Form.Group>
              </Row>
            </Col>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleCreate()}>
          Create Filter
        </Button>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddNewFilterFromPresetsModal.propTypes = {
  onHide: PropTypes.func,
  globalVendorId: PropTypes.number,
  refetch: PropTypes.func,
};

export default AddNewFilterFromPresetsModal;
