import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { FormControl, InputGroup } from 'react-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { anchovy, danger } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';

function ClearableInput({ onClear = () => {}, ...props }) {
  const inputRef = useRef(null);
  useEffect(() => {
    if (props.autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [props.autoFocus]);
  return (
    <InputGroup>
      <FormControl ref={inputRef} className={css(styles.inputBorder)} type="text" {...props} />
      <InputGroup.Text className={css(styles.clearIcon)} onClick={onClear}>
        <FontAwesomeIcon className={pointerOnHover} icon={faTimes} />
      </InputGroup.Text>
    </InputGroup>
  );
}

const styles = StyleSheet.create({
  clearIcon: {
    ':hover': {
      color: danger,
    },
    color: anchovy,
    position: 'absolute',
    right: 0,
    top: 4,
    backgroundColor: 'transparent',
    border: 'none',
    zIndex: 1000,
  },
  inputBorder: {
    borderRadius: 5,
  },
});

ClearableInput.propTypes = {
  onClear: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default ClearableInput;
