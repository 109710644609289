const BooleanValues = {
  TRUE: true,
  FALSE: false,
};

const BooleanValueOptions = [
  { value: BooleanValues.TRUE, label: 'true' },
  { value: BooleanValues.FALSE, label: 'false' },
];

export { BooleanValues, BooleanValueOptions };
