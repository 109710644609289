import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { isEmpty, some } from 'lodash';
import Select from 'react-select';
import SplitPane from 'react-split-pane';
import { useParams } from 'react-router-dom';

import {
  ATTRIBUTES_MAPS_MULTI_CREATE,
  TAXONOMY_CATEGORY_NAMES,
  TAXONOMY_UPPER_LEVELS,
} from 'lib/networking/endpoints';
import { backgroundGrey2 } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageAttributeCategoriesAssignedTableRow from 'components/manage_attribute_value/ManageAttributeCategoriesAssignedTableRow';
import RadioButton from 'components/shared/RadioButton';
import TaxonomyLevel, { CATEGORY_OPTIONS, TaxonomyGlobal } from 'lib/enums/TaxonomyLevel';
import usePost from 'lib/hooks/usePost';
import useGet from 'lib/hooks/useGet';

function AddCategoryToAttributeModal({ onHide, refetch }) {
  const { attribute_id } = useParams();
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState(null);

  const [categoryLevel, setCategoryLevel] = useState(null);
  // selected categories of the particular category level
  const [selectedCategories, setSelectedCategories] = useState([]);
  // When a category is selected this will hold it until teh next selection is done.
  // This variable is considered when sending the request to get the upper heirachy of the
  // selected category
  const [selectedCategory, setSelectedCategory] = useState(null);
  // all the selected categories with the categorylevel
  const [categories, setCategories] = useState([]);
  // this will hold all the txonomy mappings for the selected ones
  const [selectedMappings, setSelectedMappings] = useState([]);

  const { postData: updateCategoryOptions, loading } = usePost(TAXONOMY_CATEGORY_NAMES, res => {
    setCategories(res.data.data || []);
    setFilteredItems(res.data.data || []);
  });

  const { postData: create } = usePost(ATTRIBUTES_MAPS_MULTI_CREATE, res => {
    setCategories(res.data.data || []);
    setFilteredItems(res.data.data || []);
    refetch();
  });

  function getRequestBody() {
    return {
      category_level: categoryLevel?.value,
      category_id: selectedCategory?.id,
    };
  }

  const { data: { data: mappings = null } = [] } = useGet(
    TAXONOMY_UPPER_LEVELS,
    getRequestBody(),
    !selectedCategory,
  );

  useEffect(() => {
    if (!isEmpty(mappings)) {
      setSelectedMappings(prevMappings => [...prevMappings, mappings]);
      setSelectedCategory(null);
    }
  }, [mappings]);

  const handleSearch = event => {
    setSearchText(event.target.value);
    if (event.target.value) {
      setFilteredItems(
        categories.filter(item =>
          item.category_name.toLowerCase().includes(searchText?.toLowerCase()),
        ),
      );
    } else {
      setFilteredItems(categories);
    }
  };

  return (
    <Modal show={true} onHide={onHide} size="xl" className="px-6 font-weight-bold" centered>
      <Modal.Header>
        <h5 className="px-2 font-weight-bold">Assign Categories</h5>
      </Modal.Header>
      <Modal.Body className="px-4">
        <SplitPane
          primary="first"
          split="vertical"
          defaultSize="40%"
          className={css(styles.splitPane)}
        >
          <div className="pr-4">
            <Form.Group>
              <Form.Label>Rosetta Category</Form.Label>
              <Select
                classNamePrefix="themed_select"
                options={CATEGORY_OPTIONS.filter(
                  cat => ![TaxonomyGlobal.GL, TaxonomyLevel.L4].includes(cat.value),
                )}
                isSearchable
                onChange={e => {
                  setCategoryLevel(e);
                  updateCategoryOptions({ taxonomy_level: e.value });
                  setCategories([]);
                }}
                value={categoryLevel}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <Form.Control
                onChange={handleSearch}
                type="text"
                placeholder="Search Category"
                value={searchText}
              />
            </Form.Group>
            <div className={css(styles.scrollableDiv)}>
              {loading ? (
                <Row>
                  <Col colSpan={4}>
                    <LoadingSpinner short />
                  </Col>
                </Row>
              ) : (
                filteredItems?.map((category, index) => (
                  <>
                    <Row key={index} className="pl-3 pb-1 ml-1">
                      <Col className="pl-0">
                        <RadioButton
                          box
                          className="align-middle"
                          checked={some(
                            selectedCategories,
                            cat =>
                              cat.id === category.id && cat.categoryLevel === categoryLevel.value,
                          )}
                          disabled={false}
                          onClick={() => {
                            if (
                              some(
                                selectedCategories,
                                cat =>
                                  cat.id === category.id &&
                                  cat.categoryLevel === categoryLevel.value,
                              )
                            ) {
                              let selectedMappings = selectedCategories.filter(
                                item => item.id !== category.id,
                              );
                              setSelectedCategories(selectedMappings);
                              setSelectedCategory(null);
                            } else {
                              setSelectedCategories([
                                ...selectedCategories,
                                {
                                  category_name: category.category_name,
                                  id: category.id,
                                  categoryLevel: categoryLevel.value,
                                },
                              ]);
                              setSelectedCategory({ ...category, categoryLevel: categoryLevel });
                            }
                          }}
                          label={category.category_name}
                          small
                        />
                      </Col>
                    </Row>
                    <hr className="my-1 mr-5" />
                  </>
                ))
              )}
            </div>
          </div>
          <div className="ml-2">
            <Table>
              <thead>
                <tr>
                  <td className="py-1">L0 Category</td>
                  <td className="py-1">L1 Category</td>
                  <td className="py-1">L2 Category</td>
                  <td className="py-1">L3 Category</td>
                  <td className="py-1">Apllied Level</td>
                </tr>
              </thead>
              <tbody>
                {selectedMappings.map((ele, index) => (
                  <ManageAttributeCategoriesAssignedTableRow
                    key={index}
                    tableRow={ele}
                    selectedIds={[]}
                    showToggle={false}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </SplitPane>
        <Row className="pt-3 mt-3">
          <Col sm={3}>
            <Button
              block
              disabled={isEmpty(categoryLevel) || isEmpty(selectedCategories)}
              onClick={() => {
                create({
                  attribute_id: attribute_id,
                  category_details: selectedCategories.map(ele => ({
                    category_name: ele.category_name,
                    category_level: ele.categoryLevel,
                    category_id: ele.id,
                  })),
                });
                onHide();
              }}
            >
              Save
            </Button>
          </Col>
          <Col sm={2} className="pl-0">
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  scrollableDiv: {
    overflow: 'scroll',
    height: '50vh',
  },
  splitPane: {
    position: 'relative',
    minHeight: '70vh',
  },
});

AddCategoryToAttributeModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AddCategoryToAttributeModal;
