import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import L4CategoryFilter from 'components/l4_default_images/filters/L4CategoryFilter';
import { USERS_OF_REJECTED_DEFAULT_L4_IMAGES } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function RejectedImagesFilter({
  user,
  setUser,
  selectedL4CategoryId,
  setSelectedL4CategoryId,
  setSelectedL4CategoryName,
  l4OptionsForUser,
  loadingl4OptionsForUser,
}) {
  const { data: { data: users } = {}, loading: usersLoading } = useGet(
    USERS_OF_REJECTED_DEFAULT_L4_IMAGES,
  );

  const userOptions = useMemo(() => {
    if (!users?.length) {
      return [];
    }

    return users.map((user, index) => ({ label: user.name, value: index }));
  }, [users]);

  return (
    <div className={css(styles.container)}>
      <div className="my-3">
        <Form.Group>
          <Form.Label>Select User</Form.Label>
          <Select
            isClearable
            options={userOptions}
            value={userOptions.find(option => option?.label === user?.label)}
            onChange={option => {
              setUser(users[option?.value]);
            }}
            isDisabled={usersLoading}
            placeholder={usersLoading ? 'Loading' : 'Select a user'}
          />
        </Form.Group>
      </div>
      <div className="my-3">
        <L4CategoryFilter
          selectedL4CategoryId={selectedL4CategoryId}
          setSelectedL4CategoryId={setSelectedL4CategoryId}
          setSelectedL4CategoryName={setSelectedL4CategoryName}
          l4Options={l4OptionsForUser}
          parentLoading={loadingl4OptionsForUser}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '85vh',
  },
});

RejectedImagesFilter.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  selectedL4CategoryId: PropTypes.number,
  setSelectedL4CategoryId: PropTypes.func,
  setSelectedL4CategoryName: PropTypes.func,
  l4OptionsForUser: PropTypes.array,
  loadingl4OptionsForUser: PropTypes.bool,
};

export default RejectedImagesFilter;
