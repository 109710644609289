import React, { use, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import DropZone from 'components/shared/Dropzone';
import EntityType from 'lib/enums/EntityType';
import { NUTRITIONAL_PANEL_FOLDER } from 'components/nutritional_panels/NutritionalPanelUploadModal';
import { NUTRITIONAL_PANELS_UPLOAD_FOR_PRODUCT } from 'lib/networking/endpoints';
import { ProductType } from 'lib/enums/ProductType';
import usePost from 'lib/hooks/usePost';

function NutritionalPanelUploadModal({ onHide, refetch, selectedProductOption }) {
  const [imageDetails, setImageDetails] = useState([]);

  const entityType =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? EntityType.DISTRIBUTOR
      : EntityType.MANUFACTURER;

  const entityId =
    selectedProductOption?.product?.global_vendor?.id ||
    selectedProductOption?.product?.global_manufacturer?.id;

  const sku =
    selectedProductOption?.product?.vendor_sku || selectedProductOption?.product?.manufacturer_sku;

  const { postData: uploadNutritionalPanelImages, loading } = usePost(
    NUTRITIONAL_PANELS_UPLOAD_FOR_PRODUCT,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Nutritional panel images uploaded successfully',
      }).then(() => onHide());
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while creating the nutritional panel ` +
          `upload: ${error?.response?.data?.message}`,
      }),
  );

  const displayText = 'Drag and drop images here, or click to select images';
  const imageUploadPath = `${NUTRITIONAL_PANEL_FOLDER}/${entityType.toLowerCase()}/${entityId}`;
  const filePath = imageUploadPath;
  const onUploadSuccess = newUrls => setImageDetails(newUrls);
  const fileUrls = imageDetails;

  const onSubmit = () => {
    const data = {
      product_type: entityType,
      product_id: selectedProductOption?.productId,
      image_details: imageDetails,
    };
    uploadNutritionalPanelImages(data);
  };

  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">Upload Nutritional Panel Images</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <DropZone
          text={displayText}
          onUploadSuccess={onUploadSuccess}
          fileUrls={fileUrls}
          filePath={filePath}
          isImage={true}
          isMulti={true}
          isPublic={true}
          returnFileName={true}
          fileName={sku}
          addHashPrefix={false}
        />
        <div className="my-3">
          <Button className="px-5 mr-2" disabled={loading} onClick={onSubmit}>
            Upload
          </Button>
          <Button variant="outline-primary mx-2" onClick={() => setImageDetails([])}>
            Clear Images
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              onHide();
              setImageDetails([]);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

NutritionalPanelUploadModal.propTypes = {
  onHide: PropTypes.func,
  refetch: PropTypes.func,
  selectedProductOption: PropTypes.object.isRequired,
};

export default NutritionalPanelUploadModal;
export { NUTRITIONAL_PANEL_FOLDER };
