import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import { PRODUCT_OUTLIERS_CHANGED_PRODUCTS_EXPORT } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function ProductOutlierChangesExportModal({ onHide }) {
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(dayjs().endOf('day').toDate());

  const { postData: exportChangedProducts, loading } = usePost(
    PRODUCT_OUTLIERS_CHANGED_PRODUCTS_EXPORT,
    () => {
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }).then(() => {
        onHide();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred on exporting updated products: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  return (
    <Modal animation={false} centered show={true} onHide={onHide}>
      <div>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label>Select Date Range</Form.Label>
              <div className="d-flex">
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  customInput={<Form.Control />}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd HH:mm"
                />
                <DatePicker
                  className="ml-2"
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  customInput={<Form.Control />}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd HH:mm"
                />
              </div>
            </Col>
          </Row>
          <hr />
          <div>
            <Button
              onClick={() =>
                exportChangedProducts({
                  start_date: startDate,
                  end_date: endDate,
                })
              }
              size="lg"
              className="mr-2"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Export'}
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

ProductOutlierChangesExportModal.propTypes = {
  brand: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default ProductOutlierChangesExportModal;
