const ManufacturerVerificationStatus = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
};

const ManufacturerVerificationStatusOptions = [
  { value: ManufacturerVerificationStatus.VERIFIED, label: 'Verified' },
  { value: ManufacturerVerificationStatus.UNVERIFIED, label: 'Unverified' },
  { value: ManufacturerVerificationStatus.PENDING_VERIFICATION, label: 'Pending Verification' },
];

export { ManufacturerVerificationStatus, ManufacturerVerificationStatusOptions };
