import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import ProductListFilter from 'lib/enums/ProductListFilter';

const CLASSIFICATION_STATE = {
  CLASSIFIED: 'Classified',
  UNCLASSIFIED: 'Unclassified',
};

function ClassificationStatusFilter({ selectedValue, updateFilters }) {
  const options = [
    {
      label: CLASSIFICATION_STATE.CLASSIFIED,
      value: CLASSIFICATION_STATE.CLASSIFIED,
    },
    {
      label: CLASSIFICATION_STATE.UNCLASSIFIED,
      value: CLASSIFICATION_STATE.UNCLASSIFIED,
    },
  ];

  return (
    <Form.Group>
      <Form.Label>Classification Status</Form.Label>
      <Select
        isClearable
        options={options}
        value={options.find(val => val.value === selectedValue)}
        onChange={opt => updateFilters(ProductListFilter.CLASSIFICATION_STATUS, opt?.value)}
      />
    </Form.Group>
  );
}

ClassificationStatusFilter.propTypes = {
  selectedValue: PropTypes.string,
  updateFilters: PropTypes.func,
};

export default ClassificationStatusFilter;
