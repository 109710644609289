import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { uniq, without } from 'lodash';

import ImagesSection from 'components/assets/image_recommendations/recommendations/ImagesSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';
import { WEB_IMAGE_RECOMMENDATIONS } from 'lib/networking/endpoints';

function WebImagesSection({
  product,
  selectedUrls,
  setSelectedUrls,
  hideTab,
  selectedThumbnailUrl,
  setSelectedThumbnailUrl,
}) {
  const {
    data: { data: imageUrls } = {},
    loading,
    error,
  } = useGet(WEB_IMAGE_RECOMMENDATIONS, {
    product_id: product.product_id,
    product_type: product.product_type,
  });

  useEffect(() => {
    if (imageUrls && !imageUrls.length) {
      hideTab();
    }
    // ignore hideTab to avoid render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrls]);

  if (loading) {
    return <LoadingSpinner short />;
  }

  if (error) {
    return null;
  }

  if (!imageUrls?.length) {
    return <div className="w-100 text-center">No web images found for the given product.</div>;
  }

  const onImageClick = url => {
    // If no thumbnail is selected, select the first one
    if (!selectedThumbnailUrl && selectedUrls?.length === 0) {
      setSelectedThumbnailUrl(url);
    }
    if (selectedUrls.includes(url)) {
      setSelectedUrls(without(selectedUrls, url));
    } else {
      setSelectedUrls(uniq([...selectedUrls, url]));
    }
  };

  return (
    <div>
      <ImagesSection
        images={imageUrls.map(url => ({ image_id: url, url }))}
        onImageClick={onImageClick}
        isImageSelected={url => selectedUrls.includes(url)}
        selectedThumbnailUrl={selectedThumbnailUrl}
        setSelectedThumbnailUrl={setSelectedThumbnailUrl}
      />
    </div>
  );
}

WebImagesSection.propTypes = {
  product: PropTypes.object,
  selectedUrls: PropTypes.array,
  setSelectedUrls: PropTypes.func,
  hideTab: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
};

export default WebImagesSection;
