import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';

import { DEFAULT_VIEW, ALL_PRODUCTS_TABLE_ID } from 'lib/constants';
import EditColumnsSection from 'components/all_products/edit_view/EditColumnsSection';
import { findAndReplace } from 'lib/utils/arrayUtils';
import { AllProductsContext } from 'components/all_products/AllProductsContainer';
import useGet from 'lib/hooks/useGet';
import { VIEWS } from 'lib/networking/endpoints';

const viewOptionMapper = view => ({ label: view?.name, value: view?.name });

function EditProductsViewModal({ show, onHide }) {
  const { allViews, setAllViews, setView, createView } = useContext(AllProductsContext);
  const [selectedView, setSelectedView] = useState(DEFAULT_VIEW);

  const { data: { data: views } = {} } = useGet(VIEWS);

  useEffect(() => {
    if (!views?.length) {
      return;
    }
    const vs = views.map(v => ({
      ...v,
      columns: v.columns.map(c => ({ name: c, visible: true })),
    }));
    setSelectedView(vs.find(v => v.active));
  }, [views]);

  const updateViewColumns = useCallback(
    newColumns => {
      const newView = { ...selectedView, columns: newColumns };
      const newViews = findAndReplace(allViews, v => v.name === selectedView.name, newView);
      setSelectedView(newView);
      setAllViews(newViews);
    },
    [allViews, selectedView, setAllViews],
  );

  const customStyles = () => {
    return {
      control: (provided, _state) => ({
        ...provided,
        height: '30px',
        minHeight: '20px',
        fontWeight: 'normal',
      }),

      valueContainer: (provided, _state) => ({
        ...provided,
        padding: '0 5px',
      }),

      indicatorsContainer: (provided, _state) => ({
        ...provided,
        height: '25px',
      }),

      option: (provided, _state) => ({
        ...provided,
        fontWeight: 'normal',
        padding: '5px',
      }),
    };
  };

  return (
    <Modal animation={false} show={show} onHide={onHide} centered>
      <Modal.Header className="py-2 mb-0" closeButton>
        <h5>Edit View Columns</h5>
      </Modal.Header>
      <Modal.Body className={css(styles.modalText)}>
        <Form.Group>
          <Form.Label>Select an existing view, or create a new view</Form.Label>
          <Select
            value={viewOptionMapper(selectedView)}
            options={allViews.map(viewOptionMapper)}
            onChange={option => {
              let v;
              if (option.__isNew__) {
                v = { name: option.value, columns: [] };
                setAllViews([...allViews, v]);
              } else {
                v = allViews.find(v => v.name === option.value);
              }
              setSelectedView(v);
            }}
            styles={customStyles}
          />
        </Form.Group>
        <hr className="m-2" />
        <EditColumnsSection selectedView={selectedView} updateViewColumns={updateViewColumns} />
        <div className="w-100 text-right">
          <Button
            variant="success"
            onClick={() => {
              createView({
                view_data: allViews.map(v => ({
                  ...v,
                  columns: v.columns.map(c => c.name),
                  active: v === selectedView,
                })),
              });
              setView(selectedView);
              window.localStorage.removeItem(ALL_PRODUCTS_TABLE_ID);
              window.sessionStorage.removeItem(ALL_PRODUCTS_TABLE_ID);
              onHide();
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalText: {
    fontSize: '0.8rem',
  },
});

EditProductsViewModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
};

export default EditProductsViewModal;
