import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Image, Media } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { anchovy, grape } from 'lib/css/colors';
import EditableProperty from 'components/manage_manufacturers/manufacturer_details/EditableProperty';
import { faSquareCheck } from 'images/icons/fsa-monotone-svg-icons';
import { ManufacturerVerificationStatus } from 'lib/enums/ManufacturerVerificationStatus';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';

function ManufacturerLogoSplitView({
  manufacturer,
  updateManufacturer,
  setShowManufacturerLogoModal,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedManufacturer, setUpdatedManufacturer] = useState({ id: manufacturer.id });

  return (
    <div className={classNames(css(styles.container), 'mt-5')}>
      <Media
        className={pointerOnHover}
        onClick={() => {
          setShowManufacturerLogoModal(true);
        }}
      >
        <Image
          className={css(styles.square)}
          src={manufacturer?.logo_url || placeholderProductImage}
          alt="Manufacturer Logo"
        />
      </Media>
      <p className="small text-muted">* Click on image to change logo</p>
      <h3 className="font-weight-bold py-4 ">{manufacturer.name}</h3>
      <div>
        <p className={css(styles.label)}>ID</p>
        <p className={css(styles.value)}>{manufacturer.id}</p>
      </div>
      <div>
        <p className={css(styles.label)}>Cut and Dry ID</p>
        {isEditing ? (
          <Form onBlur={() => setIsEditing(false)} className={css(styles.row)}>
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faSquareCheck}
              onMouseDown={() => {
                updateManufacturer(updatedManufacturer);
                setIsEditing(false);
              }}
              size="lg"
            />
            <EditableProperty
              label={'cnd_id'}
              manufacturer={manufacturer}
              setIsEditing={setIsEditing}
              setUpdatedManufacturer={setUpdatedManufacturer}
            />
          </Form>
        ) : (
          <div className={css(styles.row)}>
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faPen}
              onClick={() => {
                setIsEditing(true);
              }}
            />
            <p className={css(styles.value)}>{manufacturer.cnd_id}</p>
          </div>
        )}
      </div>
      <div className={css(styles.toggleSwitchContainer)}>
        <p className={css(styles.label)}>Verification Status</p>
        <ToggleSwitch
          onColor={grape}
          className={css(styles.value)}
          checked={manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={() =>
            updateManufacturer({
              id: manufacturer.id,
              verification_status:
                manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED
                  ? ManufacturerVerificationStatus.UNVERIFIED
                  : ManufacturerVerificationStatus.VERIFIED,
            })
          }
        />
      </div>
      {manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED && (
        <div className={css(styles.toggleSwitchContainer)}>
          <p className={css(styles.label)}>Verified by</p>
          <p className={css(styles.value)}>{manufacturer.verified_by?.name || '-'}</p>
        </div>
      )}
      <div>
        <p className={css(styles.label)}>Linked Brands</p>
        <p className={css(styles.value)}>{manufacturer.brand_count}</p>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: '1.9rem',
  },
  editIcon: {
    marginRight: '0.75rem',
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  square: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    border: '2px solid ' + anchovy,
    marginBottom: '1rem',
    padding: '0.5rem',
  },
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  value: {
    display: 'block',
  },
  toggleSwitchContainer: {
    marginBottom: '1rem',
  },
});

ManufacturerLogoSplitView.propTypes = {
  manufacturer: PropTypes.object,
  updateManufacturer: PropTypes.func,
  setShowManufacturerLogoModal: PropTypes.func,
  refetch: PropTypes.func,
};

export default ManufacturerLogoSplitView;
