import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import {
  BrandHarvestingStatusOptions,
  BrandHarvestingQualityOptions,
} from 'lib/enums/BrandHarvestingEnums';
import DistributorFilter from 'components/shared/DistributorFilter';

const BOOLEAN_OPTIONS = [
  { label: 'Available', value: true },
  { label: 'Not Available', value: false },
];

function BrandHarvestingFilters({
  setShowBrandHarvestingEditModal,
  selectedIds,
  setCachedBrandHarvestFilters,
  selectedOptions,
  setSelectedOptions,
}) {
  const [inputBrandOrManufacturerText, setinputBrandOrManufacturerText] = useState('');

  const hangleKeyPress = e => {
    if (e.key === 'Enter') {
      setSelectedOptions({
        ...selectedOptions,
        searchQuery: inputBrandOrManufacturerText,
      });
      setCachedBrandHarvestFilters({
        ...selectedOptions,
        searchQuery: inputBrandOrManufacturerText,
      });
      e.preventDefault();
    }
  };

  const handleButtonClick = () => {
    setSelectedOptions({ ...selectedOptions, searchQuery: inputBrandOrManufacturerText });
    setCachedBrandHarvestFilters({ ...selectedOptions, searchQuery: inputBrandOrManufacturerText });
  };

  return (
    <>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Select
              isClearable
              value={selectedOptions?.selectedStatus}
              options={BrandHarvestingStatusOptions}
              onChange={option => {
                setSelectedOptions({ ...selectedOptions, selectedStatus: option });
                setCachedBrandHarvestFilters({ ...selectedOptions, selectedStatus: option });
              }}
            />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label>MPN</Form.Label>
            <Select
              isClearable
              value={selectedOptions.mpn}
              options={BOOLEAN_OPTIONS}
              onChange={option => {
                setSelectedOptions({ ...selectedOptions, mpn: option });
                setCachedBrandHarvestFilters({ ...selectedOptions, mpn: option });
              }}
            />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label>GTIN</Form.Label>
            <Select
              isClearable
              value={selectedOptions.gtin}
              options={BOOLEAN_OPTIONS}
              onChange={option => {
                setSelectedOptions({ ...selectedOptions, gtin: option });
                setCachedBrandHarvestFilters({ ...selectedOptions, gtin: option });
              }}
            />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label>Data Quality</Form.Label>
            <Select
              isClearable
              value={selectedOptions.dataQuality}
              options={BrandHarvestingQualityOptions}
              onChange={option => {
                setSelectedOptions({ ...selectedOptions, dataQuality: option });
                setCachedBrandHarvestFilters({ ...selectedOptions, dataQuality: option });
              }}
            />
          </Form.Group>
        </Col>
        <Col sm="4">
          <Form.Group>
            <DistributorFilter
              updateSelectedVendorId={option => {
                setSelectedOptions({ ...selectedOptions, selectedDistributor: option });
                setCachedBrandHarvestFilters({ ...selectedOptions, selectedDistributor: option });
              }}
              selectedOption={selectedOptions.selectedDistributor}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <Form.Control
            type="text"
            placeholder="Search by Brand Name, Manufacturer"
            onChange={e => {
              setinputBrandOrManufacturerText(e.target.value);
            }}
            onKeyPress={hangleKeyPress}
          />
        </Col>
        <Col>
          <Button className="px-20 mt-1" onClick={handleButtonClick}>
            Search
          </Button>
        </Col>
        <Col>
          <Button
            className="px-20 mt-1 float-right"
            disabled={!selectedIds.length}
            onClick={setShowBrandHarvestingEditModal}
          >
            Edit Selected Brands
          </Button>
        </Col>
      </Row>
    </>
  );
}

BrandHarvestingFilters.propTypes = {
  setShowBrandHarvestingEditModal: PropTypes.func,
  setCachedBrandHarvestFilters: PropTypes.func,
  selectedIds: PropTypes.array,
  selectedOptions: PropTypes.object,
  setSelectedOptions: PropTypes.func,
};

export default BrandHarvestingFilters;
