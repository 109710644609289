import { useCallback } from 'react';

import difference from 'lodash/difference';
import uniq from 'lodash/uniq';

export const useSelected = (selected, setSelected) => {
  const add = useCallback(
    items => {
      setSelected(oldList => uniq([...oldList, ...items]));
    },
    [setSelected],
  );

  const remove = useCallback(
    items => {
      setSelected(oldList => difference(oldList, items));
    },
    [setSelected],
  );

  const change = useCallback(
    (addOrRemove, items) => {
      if (addOrRemove) {
        add(items);
      } else {
        remove(items);
      }
    },
    [add, remove],
  );

  const clear = useCallback(() => setSelected([]), [setSelected]);

  return { add, remove, clear, change };
};
