import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import AssetRow from 'components/global_products/detail_view/AssetRow';

function ProductAssetsTable({
  otherImages,
  thumbnailImage,
  selectedAssets,
  setSelectedAssets,
  deletedImages,
}) {
  return (
    <div className="my-4">
      {deletedImages?.length || otherImages?.length || thumbnailImage?.url ? (
        <Table hover className="mt-3">
          <thead>
            <tr>
              {!deletedImages?.length && <th></th>}
              <th>Image</th>
              <th>Type</th>
              <th>State</th>
              <th>Facing</th>
              <th>Image Source</th>
              <th>Uploaded By</th>
              {deletedImages?.length && <th>Deleted By</th>}
              <th>Date Uploaded</th>
              <th>{deletedImages?.length ? 'Date Deleted' : 'Last Modified'}</th>
            </tr>
          </thead>
          <tbody>
            {thumbnailImage?.id || thumbnailImage?.url ? (
              <AssetRow
                key={thumbnailImage.id + '_' + thumbnailImage.url}
                asset={thumbnailImage}
                selectedAssets={selectedAssets}
                setSelectedAssets={setSelectedAssets}
                isPropagated={thumbnailImage.is_propagated}
              />
            ) : null}
            {otherImages?.map(asset => (
              <AssetRow
                key={asset.id}
                asset={asset}
                selectedAssets={selectedAssets}
                setSelectedAssets={setSelectedAssets}
                isPropagated={asset.is_propagated}
              />
            ))}
            {deletedImages?.map(asset => (
              <AssetRow key={asset.id} asset={asset} isDeleted={true} />
            ))}
          </tbody>
        </Table>
      ) : null}
    </div>
  );
}

ProductAssetsTable.propTypes = {
  otherImages: PropTypes.array,
  thumbnailImage: PropTypes.object,
  setSelectedAssets: PropTypes.func,
  selectedAssets: PropTypes.array,
  deletedImages: PropTypes.array,
};

export default ProductAssetsTable;
