import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { uniq, without } from 'lodash';

import BrandHarvestingEditModal from 'components/manage_brands/harvest_brands/BrandHarvestingEditModal';
import {
  BrandHarvestingStatus,
  BrandHarvestingQuality,
  BrandHarvestingPriority,
} from 'lib/enums/BrandHarvestingEnums';
import RadioButton from 'components/shared/RadioButton';

function BrandsHarvestTableRow({ brand, selectedIds, setSelectedIds, viewOnly = false }) {
  const [showBrandHarvestingEditModal, setShowBrandHarvestingEditModal] = useState(false);
  const [brandDetails, setBrandDetails] = useState(brand);

  const toggleCheck = key => {
    const isChecked = selectedIds.includes(key);
    if (isChecked) {
      setSelectedIds([...without(selectedIds, key)]);
    } else {
      setSelectedIds([...uniq([...selectedIds, key])]);
    }
  };

  return (
    <>
      <tr>
        <td>
          <div>
            {!viewOnly ? (
              <RadioButton
                box
                className="float-left"
                small={true}
                checked={selectedIds.includes(brandDetails.id)}
                onClick={() => toggleCheck(brandDetails.id)}
              />
            ) : null}
            {brandDetails.name}
          </div>
        </td>
        <td>{brandDetails.global_manufacturer?.name || '-'}</td>
        <td>{brandDetails.product_count}</td>
        <td>{BrandHarvestingPriority[brandDetails.priority] || '-'}</td>
        <td>{brandDetails.manufacturer_product_number_exists ? 'True' : 'False'}</td>
        <td>{brandDetails.gtin_exists ? 'True' : 'False'}</td>
        <td>{BrandHarvestingQuality[brandDetails.data_quality] || ''}</td>
        <td>
          {brandDetails.harvest_url?.length > 50
            ? brandDetails.harvest_url.substring(0, 50) + '...'
            : brandDetails.harvest_url}
        </td>
        <td>{BrandHarvestingStatus[brandDetails.harvest_status] || ''}</td>
        <td
          className="d-flex justify-content-center"
          onClick={() => {
            if (!viewOnly) {
              setShowBrandHarvestingEditModal(true);
            }
          }}
        >
          <Button variant="link" disabled={viewOnly}>
            Edit
          </Button>
        </td>
      </tr>
      {showBrandHarvestingEditModal && (
        <BrandHarvestingEditModal
          onHide={() => setShowBrandHarvestingEditModal(false)}
          brandToHarvest={brandDetails}
          setBrandDetails={setBrandDetails}
        />
      )}
    </>
  );
}

BrandsHarvestTableRow.propTypes = {
  brand: PropTypes.object,
  refetch: PropTypes.func,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
  viewOnly: PropTypes.bool,
};

export default BrandsHarvestTableRow;
