import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import { BRANDS_FOR_HARVEST_GROUPED } from 'lib/networking/endpoints';
import BrandHarvestGroupedViewTableRow from 'components/manage_brands/harvest_brands/BrandHarvestGroupedViewTableRow';
import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function BrandHarvestGroupedView({ itemsPerPage, currentPage, selectedOptions, setBrandCount }) {
  const { data: { brands = [], count } = {}, loading } = useGet(BRANDS_FOR_HARVEST_GROUPED, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    is_dp_only: true,
    brand_harvest_status: selectedOptions.selectedStatus?.value || null,
    manufacturer_product_number_exists: selectedOptions.mpn?.value,
    gtin_exists: selectedOptions.gtin?.value,
    data_quality: selectedOptions.dataQuality?.value || null,
    distributor_id: selectedOptions.selectedDistributor?.value || null,
    searchQuery: selectedOptions.searchQuery || null,
    status: BrandVerificationStatus.VERIFIED,
  });

  useEffect(() => {
    if (count) {
      setBrandCount(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>URL</th>
              <th>Manufacturer</th>
              <th># Of Brands</th>
              <th>Total DP Products</th>
              <th>Priority</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand, index) => (
              <BrandHarvestGroupedViewTableRow key={index} groupedRow={brand} brands={brands} />
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

BrandHarvestGroupedView.propTypes = {
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  selectedOptions: PropTypes.array,
  setBrandCount: PropTypes.func,
};

export default BrandHarvestGroupedView;
