import React, { useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';

import Container from 'components/shared/Container';
import { grape } from 'lib/css/colors';
import { GTIN_GS1 } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import usePost from 'lib/hooks/usePost';

const VERSIONS = [
  { label: 'Version 5', value: 5 },
  { label: 'Version 7', value: 7 },
];

function GS1LookUpView() {
  const [data, setData] = useState({
    gtin: '',
    version: VERSIONS[0],
  });
  const [jsonData, setJsonData] = useState({ data: null, message: null });
  const [rawJson, setRawJson] = useState(false);

  const { postData: gs1GtinLookUp, loading: searching } = usePost(
    GTIN_GS1,
    res => {
      if (res.data.product_details) {
        setJsonData({ data: res.data.product_details, message: null });
      } else {
        setJsonData({ data: null, message: res.data.error });
      }
    },
    () => {
      Swal.fire({
        title: 'Error in gtin look up',
        icon: 'error',
        position: 'center',
      });
    },
  );

  // eslint-disable-next-line react/prop-types
  const RenderJson = ({ data }) => {
    if (!data) {
      return null;
    }
    if (typeof data === 'object' && data !== null) {
      return (
        <ul>
          {Object.entries(data).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> <RenderJson data={value} />
            </li>
          ))}
        </ul>
      );
    } else {
      return <span>{data.toString()}</span>;
    }
  };

  return (
    <Container className={css(styles.container)}>
      <div className="d-flex justify-content-between">
        <h3 className="font-weight-bold">GS1 Look Up</h3>
      </div>
      <Row className="my-3">
        <Col>
          <Form.Control
            type="text"
            placeholder="Enter gtin to look up"
            value={data.gtin}
            onChange={e => setData({ ...data, gtin: e.target.value })}
          />
        </Col>
        <Col>
          <Select
            value={data.version}
            options={VERSIONS}
            onChange={option => setData({ ...data, version: option })}
          />
        </Col>
        <Col>
          <Button
            className="mt-1"
            onClick={() => gs1GtinLookUp({ gtin: data.gtin, version: data.version.value })}
          >
            Search
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          {jsonData.data && (
            <>
              <Form.Label className="mr-3 pt-1">Raw</Form.Label>
              <ToggleSwitch
                checked={rawJson}
                onColor={grape}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => setRawJson(!rawJson)}
                className="ml-2"
              />
            </>
          )}
        </Col>
      </Row>
      {searching && <LoadingSpinner />}
      {jsonData.message ? (
        <p>{jsonData.message}</p>
      ) : (
        <>
          {rawJson ? (
            <pre>{JSON.stringify(jsonData.data, null, 2)}</pre>
          ) : (
            <RenderJson data={jsonData.data} />
          )}
        </>
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
    overflowX: 'hidden',
  },
});

export default GS1LookUpView;
