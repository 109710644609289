/*eslint-disable*/
function cleanSentence(str) {
  const splitedSentence = str.replace(/([a-z]{2,})([A-Z])([a-z]{1,})/g, '$1. $2$3');

  if (!splitedSentence.match(/[!.]$/g)) {
    return splitedSentence + '.';
  }
  return splitedSentence;
}
function tipsAndHandling(str) {
  const seperatedString = str.replace(/(\. ?)(?=(\w* ?\w*:))/g, '.\n');
  return seperatedString.split('\n');
}

function camelCaseToSentence(str) {
  return str.replace(/^[a-z]|[A-Z]/g, function(c, i) {
    return i === 0 ? c.toUpperCase() : ' ' + c.toUpperCase();
  });
}

function snakeCaseToSentence(str) {
  return titleCase(str.replace(/_/g, ' '));
}

function titleCase(str) {
  return str.replace(/\w\S*/g, function(word) {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  });
}

export { camelCaseToSentence, cleanSentence, snakeCaseToSentence, tipsAndHandling, titleCase };
