import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { uniqBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import { getURLWithSearchParams } from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';

const RESULTS_PER_PAGE = 100;

function SearchableScrollableMultiSelect({
  onChange,
  fetchUrl,
  optionMapper,
  initOptions = [],
  className = null,
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(initOptions);
  const [loading, setLoading] = useState(false);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [searchQuery] = useDebounce(searchValue, 500);

  const loadMoreOptions = page => {
    if (!canFetchMore) {
      return;
    }
    setLoading(true);
    const url = getURLWithSearchParams(fetchUrl, {
      limit: RESULTS_PER_PAGE,
      offset: (page - 1) * RESULTS_PER_PAGE,
      search: searchQuery,
    });
    httpGet(url)
      .then(res => {
        if (res.status === 200) {
          const items = res.data?.data || [];
          if (items?.length) {
            setCurrentPage(page + 1);
            setOptions(uniqBy([...options, ...items.map(optionMapper)], 'value'));
          } else {
            setCanFetchMore(false);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedOptions(initOptions);
  }, [initOptions]);

  // reset params when a search query is inserted
  useEffect(() => {
    setCurrentPage(1);
    setOptions([]);
    loadMoreOptions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  // initial load
  useEffect(() => {
    loadMoreOptions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      className={className}
      isMulti
      isClearable
      isSearchable
      isLoading={loading}
      options={options}
      value={selectedOptions}
      onChange={options => {
        setSelectedOptions(options || []);
        onChange(options);
      }}
      onMenuScrollToBottom={() => loadMoreOptions(currentPage)}
      inputValue={searchValue}
      onInputChange={input => setSearchValue(input)}
    />
  );
}

SearchableScrollableMultiSelect.propTypes = {
  onChange: PropTypes.func,
  fetchUrl: PropTypes.string,
  optionMapper: PropTypes.func,
  initOptions: PropTypes.array,
  className: PropTypes.string,
};

export default SearchableScrollableMultiSelect;
