import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { darkGrey2 } from 'lib/css/colors';

function CollapsibleSection({ title, expandedByDefault = false, children }) {
  const [expanded, setExpanded] = useState(expandedByDefault);

  return (
    <div className={css(styles.container)}>
      <div
        className={classNames('d-flex align-items-center font-weight-bold', css(styles.header))}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="w-100">{title}</div>
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} size="lg" />
      </div>
      <div className={classNames(css(styles.children), !expanded && 'd-none')}>{children}</div>
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    padding: 10,
    paddingRight: 5,
    cursor: 'pointer',
    fontSize: 16,
    borderBottom: '1px solid ' + darkGrey2,
  },
  container: {
    marginBottom: 15,
  },
  children: {
    paddingTop: 10,
  },
});

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  expandedByDefault: PropTypes.bool,
  children: PropTypes.node,
};

export default CollapsibleSection;
