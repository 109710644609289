import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import { IMAGE_RECOMMENDATIONS } from 'lib/networking/endpoints';
import ImagesSection from 'components/product_lists/product_list/qa_product_list/ImagesSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function RecommendationsSection({ l4CategoryId, selectedThumbnailUrl, setSelectedThumbnailUrl }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const {
    data: { data: images, count: imageCount } = {},
    loading,
    error,
  } = useGet(IMAGE_RECOMMENDATIONS, {
    l4_category_id: l4CategoryId,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });

  if (loading) {
    return <LoadingSpinner short />;
  }

  if (error) {
    return null;
  }

  if (!images?.length) {
    return (
      <div className="w-100 text-center">
        No thumbnail recommendations found for the given L4 Category.
      </div>
    );
  }

  const handleImage = image => {
    setSelectedThumbnailUrl(image?.url);
  };

  return (
    <div>
      <div className="w-100 d-flex justify-content-between mb-2">
        <div className="text-secondary">
          <span>
            {imageCount} Image{imageCount === 1 ? '' : 's'}
          </span>
        </div>
        <PaginationComponent
          totalItems={imageCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={css(styles.recommendationsContainer)}>
        <ImagesSection
          images={images}
          onImageClick={image => handleImage(image)}
          isImageSelected={imageUrl => selectedThumbnailUrl === imageUrl}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  recommendationsContainer: {
    height: '400px',
    overflowY: 'auto',
  },
});

RecommendationsSection.propTypes = {
  l4CategoryId: PropTypes.number,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
};

export default RecommendationsSection;
