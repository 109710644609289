import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { uniq, without } from 'lodash';

import { IMAGE_RECOMMENDATIONS } from 'lib/networking/endpoints';
import ImagesSection from 'components/assets/image_recommendations/recommendations/ImagesSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function OtherImagesSection({
  l4CategoryId,
  selectedImageIds,
  setSelectedImageIds,
  selectedThumbnailUrl,
  setSelectedThumbnailUrl,
  hideTab,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const {
    data: { data: images, count: imageCount } = {},
    loading,
    error,
  } = useGet(IMAGE_RECOMMENDATIONS, {
    l4_category_id: l4CategoryId,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    thumbnails: false,
  });

  useEffect(() => {
    if (images && !images.length) {
      hideTab();
    }
    // ignore hideTab to avoid render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  if (loading) {
    return <LoadingSpinner short />;
  }

  if (error) {
    return null;
  }

  if (!images?.length) {
    return (
      <div className="w-100 text-center">
        No other image recommendations found for the given L4 Category.
      </div>
    );
  }

  const onImageClick = imageId => {
    // If no thumbnail is selected, select the first one
    if (!selectedThumbnailUrl && selectedImageIds?.length === 0) {
      const firstImage = images.find(image => image.image_id === imageId);
      setSelectedThumbnailUrl(firstImage?.url);
    }
    if (selectedImageIds.includes(imageId)) {
      setSelectedImageIds(without(selectedImageIds, imageId));
    } else {
      setSelectedImageIds(uniq([...selectedImageIds, imageId]));
    }
  };

  return (
    <div>
      <div className="w-100 d-flex justify-content-between mb-2">
        <div className="text-secondary">
          <span>
            {imageCount} Image{imageCount === 1 ? '' : 's'}
          </span>
          {selectedImageIds.length ? (
            <span className="ml-1">({selectedImageIds.length} selected)</span>
          ) : null}
        </div>
        <PaginationComponent
          totalItems={imageCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <ImagesSection
        images={images}
        onImageClick={onImageClick}
        isImageSelected={imageId => selectedImageIds.includes(imageId)}
        selectedThumbnailUrl={selectedThumbnailUrl}
        setSelectedThumbnailUrl={setSelectedThumbnailUrl}
      />
    </div>
  );
}

OtherImagesSection.propTypes = {
  l4CategoryId: PropTypes.number,
  selectedImageIds: PropTypes.array,
  setSelectedImageIds: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
  hideTab: PropTypes.func,
};

export default OtherImagesSection;
