import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FileUploadButton({
  text,
  uploadElementId,
  onFileUpload,
  fileTypes,
  icon = faUpload,
  disabled = false,
}) {
  const submitFormData = event => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    onFileUpload(formData);
    document.getElementById(uploadElementId).value = '';
  };

  const onButtonClick = () => {
    document.getElementById(uploadElementId).click();
  };

  return (
    <div>
      <input
        id={uploadElementId}
        type="file"
        accept={fileTypes}
        className="d-none"
        onChange={submitFormData}
      />
      <Button onClick={onButtonClick} disabled={disabled}>
        <FontAwesomeIcon icon={icon} className="mr-2" />
        {text}
      </Button>
    </div>
  );
}

FileUploadButton.propTypes = {
  text: PropTypes.string,
  uploadElementId: PropTypes.string,
  onFileUpload: PropTypes.func,
  fileTypes: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
};

export default FileUploadButton;
