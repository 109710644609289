import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { uniq, without } from 'lodash';

import { DATE_FORMAT } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ProductUnlinkButton from 'components/global_products/detail_view/ProductUnlinkButton';
import RadioButton from 'components/shared/RadioButton';
import VerificationStatusIcon from 'components/global_products/detail_view/VerificationStatusIcon';

function DistributorRow({
  distributor,
  refetch,
  selectedIds,
  setSelectedIds,
  linkTypeChangeLogLoading,
  linkTypeChangeLog,
}) {
  const isChecked = selectedIds.vendorProducts.includes(distributor.id);

  const toggleCheck = () => {
    const isChecked = selectedIds.vendorProducts.includes(distributor.id);
    if (isChecked) {
      setSelectedIds({
        ...selectedIds,
        vendorProducts: without(selectedIds.vendorProducts, distributor.id),
        vendorProductObjects: without(selectedIds.vendorProductObjects, distributor),
      });
    } else {
      setSelectedIds({
        ...selectedIds,
        vendorProducts: uniq([...selectedIds.vendorProducts, distributor.id]),
        vendorProductObjects: uniq([...selectedIds.vendorProductObjects, distributor]),
      });
    }
  };

  return (
    <tr key={distributor.id}>
      <td>
        <RadioButton
          box
          className="align-middle"
          small={true}
          checked={isChecked}
          onClick={() => toggleCheck()}
        />
      </td>
      <td>{distributor.global_vendor.name}</td>
      <td>{distributor.vendor_sku}</td>
      <td>{distributor.name}</td>
      <td>{dayjs(distributor.date_created).format(DATE_FORMAT)}</td>
      <td className="text-center">
        {distributor.cd_product_score?.percentage
          ? distributor.cd_product_score?.percentage + ' %'
          : '-'}
      </td>
      <td>
        {linkTypeChangeLogLoading ? (
          <LoadingSpinner short={true} />
        ) : linkTypeChangeLog?.length > 0 ? (
          linkTypeChangeLog[0].meta_data.link_type
        ) : (
          '-'
        )}
      </td>
      <td className="text-center">
        <VerificationStatusIcon
          verificationStatus={distributor.verification_status}
          aiSuggestedVerification={distributor.ai_suggested_verification}
        />
      </td>
      <td>
        <ProductUnlinkButton
          globalProductId={distributor.global_product.id}
          vendorProductIds={[distributor.id]}
          verificationStatus={distributor.verification_status}
          refetch={refetch}
          disabled={!isChecked}
        />
      </td>
    </tr>
  );
}

DistributorRow.propTypes = {
  distributor: PropTypes.object,
  refetch: PropTypes.func,
  selectedIds: PropTypes.object,
  setSelectedIds: PropTypes.func,
  linkTypeChangeLogLoading: PropTypes.bool,
  linkTypeChangeLog: PropTypes.array,
};

export default DistributorRow;
