import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from 'react-bootstrap';

import ActivityView from 'components/shared/ActivityView';
import BrandDetailView from 'components/manage_brands/brand_details/BrandDetailView';
import Container from 'components/shared/Container';
import ProductsView from 'components/manage_manufacturers/shared/ProductsView';

const BRAND_DETAILS_VIEW_TABS = {
  BRAND_DETAILS: 'Brand Details',
  BRAND_ACTIVITY: 'Brand Activity',
  BRAND_PRODUCTS: 'Brand Products',
};

function BrandMenuSplitView({ brand, updateBrand, refetch }) {
  const [activeTabKey, setActiveTabKey] = useState(BRAND_DETAILS_VIEW_TABS.BRAND_DETAILS);

  return (
    <Container>
      <Tabs
        className="mb-2 border-top-0"
        defaultActiveKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
      >
        <Tab
          eventKey={BRAND_DETAILS_VIEW_TABS.BRAND_DETAILS}
          title={BRAND_DETAILS_VIEW_TABS.BRAND_DETAILS}
        >
          <BrandDetailView brand={brand} updateBrand={updateBrand} refetch={refetch} />
        </Tab>
        <Tab
          eventKey={BRAND_DETAILS_VIEW_TABS.BRAND_PRODUCTS}
          title={BRAND_DETAILS_VIEW_TABS.BRAND_PRODUCTS}
        >
          <ProductsView brandId={brand.id} />
        </Tab>
        <Tab
          eventKey={BRAND_DETAILS_VIEW_TABS.BRAND_ACTIVITY}
          title={BRAND_DETAILS_VIEW_TABS.BRAND_ACTIVITY}
        >
          <ActivityView brandId={brand.id} />
        </Tab>
      </Tabs>
    </Container>
  );
}

BrandMenuSplitView.propTypes = {
  brand: PropTypes.object,
  updateBrand: PropTypes.func,
  refetch: PropTypes.func,
};

export default BrandMenuSplitView;
