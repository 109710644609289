import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import Select from 'react-select/creatable';

import { SKU_MAPPINGS_MERGE_PRODUCTS } from 'lib/networking/endpoints';
import { ProductVerificationStatus } from 'lib/enums/ProductVerificationStatus';
import usePost from 'lib/hooks/usePost';

function MergeProductsModal({ globalProductId, productsList, setProductsList, onHide }) {
  const [secondaryProducts, setSecondaryProducts] = useState(productsList);
  const [parentProduct, setParentProduct] = useState(null);

  useEffect(() => {
    //Set the verified product in productList as the parent product.
    const verifiedProduct = productsList.find(
      product => product.verification_status === ProductVerificationStatus.VERIFIED,
    );
    const unverifiedProducts = productsList.filter(
      product => product.verification_status !== ProductVerificationStatus.VERIFIED,
    );
    const verifiedProductsList = productsList.filter(
      product => product.verification_status === ProductVerificationStatus.VERIFIED,
    );

    if (verifiedProduct && verifiedProductsList.length === 1) {
      setSecondaryProducts(unverifiedProducts);
      setParentProduct(verifiedProduct);
    }
  }, [productsList]);

  const { postData: mergeProducts, loading } = usePost(
    SKU_MAPPINGS_MERGE_PRODUCTS,
    () => {
      SwalDialog('success', 'Successfully merged products', 'Success', 'center');
      onHide();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while merging products: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const options = productsList.map((product, index) => ({
    label: product.name,
    value: index,
  }));

  const handleMergeProducts = () => {
    const hasVerifiedProduct = secondaryProducts.some(
      product => product.verification_status === ProductVerificationStatus.VERIFIED,
    );

    if (!parentProduct?.verification_status === ProductVerificationStatus.VERIFIED) {
      Swal.fire({
        icon: 'error',
        title: 'Parent Product Not Verified',
        text: 'Parent product is not verified.',
        confirmButtonText: 'OK',
      });
    } else if (hasVerifiedProduct) {
      Swal.fire({
        icon: 'warning',
        title: 'Cannot Merge',
        text: 'At least one verified product in the secondary products.',
        confirmButtonText: 'OK',
      });
    } else {
      mergeProducts({
        global_product_id: globalProductId,
        parent_id: parentProduct.id,
        merge_ids: secondaryProducts.map(product => product.id),
      });
    }
  };

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Merge Products</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h3 className="font-weight-bold">Parent Product</h3>
        <Select
          className="mb-3"
          options={options}
          value={parentProduct ? options.find(option => option.label === parentProduct.name) : null}
          onChange={option => {
            if (option) {
              setParentProduct(productsList[option.value]);
              setSecondaryProducts(
                productsList.filter(mergeBrand => mergeBrand.id !== productsList[option.value].id),
              );
            } else {
              setParentProduct(null);
            }
          }}
          placeholder="Select the parent product"
        />
        <h3 className="font-weight-bold">Secondary products</h3>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>FSA ID</th>
              <th>Verified</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {secondaryProducts.map((product, index) => (
              <tr key={index}>
                <td>{product.name}</td>
                <td>{product.id}</td>
                <td>
                  {product.verification_status === ProductVerificationStatus.VERIFIED
                    ? 'Yes'
                    : 'No'}
                </td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => {
                      setSecondaryProducts(
                        secondaryProducts.filter(mergeProduct => mergeProduct.id !== product.id),
                      );
                      setProductsList(
                        productsList.filter(
                          mergeProduct =>
                            mergeProduct.id !== product.id &&
                            mergeProduct.product_type === product.product_type,
                        ),
                      );
                    }}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row className="pt-3 mt-3 d-flex justify-content-between">
          <Col sm={3}>
            <Button
              disabled={secondaryProducts?.length < 1 || loading || !parentProduct}
              block
              variant="primary"
              onClick={handleMergeProducts}
            >
              {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Merge'}
            </Button>
          </Col>
          <Col sm={2}>
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

MergeProductsModal.propTypes = {
  globalProductId: PropTypes.number,
  productsList: PropTypes.array,
  setProductsList: PropTypes.func,
  onHide: PropTypes.func,
};

export default MergeProductsModal;
