import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl } from 'react-bootstrap';

import { iconGrey, lighterGrey } from 'lib/css/colors';

function SearchBar({ value, setValue, placeholder, disabled = false }) {
  return (
    <div className={classNames('w-100', css(styles.container))}>
      <span className={css(styles.iconContainer)}>
        <FontAwesomeIcon icon={faSearch} />
      </span>
      {value ? (
        <span
          className={classNames('text-center', css(styles.closeIconContainer))}
          onClick={() => setValue('')}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </span>
      ) : null}
      <FormControl
        placeholder={placeholder}
        className={classNames(css(styles.input), 'd-inline-block')}
        value={value}
        onChange={e => setValue(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  iconContainer: {
    color: iconGrey,
    display: 'block',
    height: '2.75rem',
    lineHeight: '2.75rem',
    pointerEvents: 'none',
    position: 'absolute',
    textAlign: 'center',
    width: '2.75rem',
  },
  input: {
    borderWidth: '2px',
    borderColor: lighterGrey,
    height: 44,
    paddingLeft: '2.375rem',
    paddingRight: '2rem',
  },
  closeIconContainer: {
    cursor: 'pointer',
    color: iconGrey,
    display: 'block',
    float: 'right',
    height: '2.75rem',
    lineHeight: '2.75rem',
    position: 'absolute',
    right: 0,
    width: '2.75rem',
  },
  iconContainerZindex: {
    zIndex: 2,
  },
});

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SearchBar;
