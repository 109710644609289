import React, { useState } from 'react';

import { Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';

import { CHANGE_LOG_STATUS_MAP } from 'lib/enums/ChangeLogStatus';
import { CHANGE_LOGS_BY_REQUEST_ID } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ProductUpdatesTableRow from 'components/product_updates/ProductUpdatesTableRow';
import { removeEmpty } from 'lib/utils/objectUtils';
import useGet from 'lib/hooks/useGet';

const FILTER_OPTIONS = [{ label: 'All', value: null }, ...CHANGE_LOG_STATUS_MAP];

function ProductUpdatesView() {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { data: { data: changeLogGroups } = {}, loading } = useGet(
    CHANGE_LOGS_BY_REQUEST_ID,
    removeEmpty({ status: selectedStatus }),
  );

  if (!changeLogGroups) {
    return null;
  }

  return (
    <Container>
      <h3 className="font-weight-bold">Product Updates</h3>
      <Row className="my-3">
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Select
              options={FILTER_OPTIONS}
              defaultValue={FILTER_OPTIONS[0]}
              value={FILTER_OPTIONS.find(option => option.value === selectedStatus)}
              onChange={option => setSelectedStatus(option.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <div className="my-3">
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <Table hover striped>
            <thead>
              <tr>
                <th width="100">Date</th>
                <th width="100">SKU</th>
                <th width="400">Product Name</th>
                <th>Manufacturer/Distributor</th>
                <th># of Changes</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {changeLogGroups.map((changeLogGroup, index) => (
                <ProductUpdatesTableRow key={index} changeLogGroup={changeLogGroup} />
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </Container>
  );
}

export default ProductUpdatesView;
