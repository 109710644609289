import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Table } from 'react-bootstrap';
import { isEqual } from 'lodash';

import {
  DATA_SOURCE_UPDATE,
  GLOBAL_MANUFACTURERS,
  GLOBAL_VENDORS_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import useGet from 'lib/hooks/useGet';
import usePut from 'lib/hooks/usePut';

import DataSourceSkuMappingsTabRow from 'components/data_sources/single_data_source_view/DataSourceSkuMappingsTabRow';
import LoadingSpinner from 'components/shared/LoadingSpinner';

function DataSourceSkuMappingsTab({ dataSource, refetch }) {
  const { data: { data: globalVendors = [] } = {}, loading: vendorsLoading } = useGet(
    GLOBAL_VENDORS_FOR_DROPDOWN,
  );
  const { data: { manufacturers = [] } = {}, loading: mfrsLoading } = useGet(GLOBAL_MANUFACTURERS);
  const { putData: updateDataSource, loading: updating } = usePut(DATA_SOURCE_UPDATE, () => {
    refetch();
  });

  const [unsavedColumnMap, setUnsavedColumnMap] = useState(dataSource.column_map || {});

  const getManufacturerOptions = useMemo(() => {
    return manufacturers.map(manufacturer => ({
      value: manufacturer.id,
      label: manufacturer.name,
    }));
  }, [manufacturers]);

  const getVendorOptions = useMemo(() => {
    return globalVendors.map(vendor => ({
      value: vendor.id,
      label: vendor.name,
    }));
  }, [globalVendors]);

  const columnMap = dataSource.column_map || {};
  if (vendorsLoading || mfrsLoading) {
    return <LoadingSpinner short />;
  }

  return (
    <Container>
      <div className="mb-3 w-100 d-flex justify-content-end">
        <Button
          className="px-5"
          variant="outline-primary"
          disabled={isEqual(columnMap, unsavedColumnMap) || updating}
          onClick={() => {
            updateDataSource({
              data_source_id: dataSource.id,
              column_map: unsavedColumnMap,
            });
          }}
        >
          Save
        </Button>
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>Column</th>
            <th />
            <th>Manufacturer / Distributor</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(columnMap).map((column, idx) => (
            <DataSourceSkuMappingsTabRow
              key={idx}
              column={column}
              getManufacturerOptions={getManufacturerOptions}
              getVendorOptions={getVendorOptions}
              setUnsavedColumnMap={setUnsavedColumnMap}
              unsavedColumnMap={unsavedColumnMap}
            />
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

DataSourceSkuMappingsTab.propTypes = {
  dataSource: PropTypes.object,
  refetch: PropTypes.func,
};

export default DataSourceSkuMappingsTab;
