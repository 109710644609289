import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Tab, Tabs } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { GLOBAL_PRODUCT_TYPE } from 'lib/enums/ProductType';
import GlobalProductDeletedImagesView from 'components/global_products/detail_view/assets_view/GlobalProductDeletedImagesView';
import GlobalProductImagesView from 'components/global_products/detail_view/assets_view/GlobalProductImagesView';
import GlobalProductNutritionalPanelView from 'components/global_products/detail_view/assets_view/GlobalProductNutritionalPanelView';

const PRODUCT_ASSETS_VIEW_TABS = {
  PRODUCR_IMAGES: 'Product Images',
  DELETED_IMAGES: 'Deleted Images',
  NUTRITIONAL_PANELS: 'Nutritional Panels',
};

function GlobalProductAssetView({ refetch, selectedProductOption }) {
  const [activeTabKey, setActiveTabKey] = useState(PRODUCT_ASSETS_VIEW_TABS.PRODUCR_IMAGES);

  useEffect(() => {
    if (selectedProductOption?.productType === GLOBAL_PRODUCT_TYPE) {
      setActiveTabKey(PRODUCT_ASSETS_VIEW_TABS.PRODUCR_IMAGES);
    }
  }, [selectedProductOption]);

  return (
    <Container fluid className={classNames(css(styles.container), 'py-3')}>
      <Tabs
        className="border-top-0"
        activeKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
        transition={false}
      >
        <Tab
          eventKey={PRODUCT_ASSETS_VIEW_TABS.PRODUCR_IMAGES}
          title={PRODUCT_ASSETS_VIEW_TABS.PRODUCR_IMAGES}
        >
          <GlobalProductImagesView
            refetch={refetch}
            selectedProductOption={selectedProductOption}
          />
        </Tab>
        {selectedProductOption?.productType !== GLOBAL_PRODUCT_TYPE ? (
          <Tab
            eventKey={PRODUCT_ASSETS_VIEW_TABS.DELETED_IMAGES}
            title={PRODUCT_ASSETS_VIEW_TABS.DELETED_IMAGES}
          >
            <GlobalProductDeletedImagesView
              refetch={refetch}
              selectedProductOption={selectedProductOption}
            />
          </Tab>
        ) : null}
        <Tab
          eventKey={PRODUCT_ASSETS_VIEW_TABS.NUTRITIONAL_PANELS}
          title={PRODUCT_ASSETS_VIEW_TABS.NUTRITIONAL_PANELS}
        >
          <GlobalProductNutritionalPanelView
            refetch={refetch}
            selectedProductOption={selectedProductOption}
          />
        </Tab>
      </Tabs>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
  },
});

GlobalProductAssetView.propTypes = {
  selectedProductOption: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

export default GlobalProductAssetView;
