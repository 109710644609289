import { css, StyleSheet } from 'aphrodite';

import {
  categoryBlack,
  categoryBlue,
  categoryGreen,
  categoryOrange,
  categoryPink,
} from 'lib/css/colors';

const categoryStyles = StyleSheet.create({
  l0_category: {
    color: categoryBlack,
  },
  l1_category: {
    color: categoryBlue,
  },
  l2_category: {
    color: categoryGreen,
  },
  l3_category: {
    color: categoryOrange,
  },
  l4_category: {
    color: categoryPink,
  },
});

const l0_category = css(categoryStyles.l0_category);
const l1_category = css(categoryStyles.l1_category);
const l2_category = css(categoryStyles.l2_category);
const l3_category = css(categoryStyles.l3_category);
const l4_category = css(categoryStyles.l4_category);

export { categoryStyles, l0_category, l1_category, l2_category, l3_category, l4_category };
