const IMAGE_FLAG_TYPES = {
  BEST: 'BEST',
  CUSTOM: 'CUSTOM',
  HYBRID: 'HYBRID',
};

const IMAGE_FLAG_TYPES_OPTIONS = Object.entries(IMAGE_FLAG_TYPES).map(([value, label]) => ({
  label,
  value,
}));

export default IMAGE_FLAG_TYPES;
export { IMAGE_FLAG_TYPES_OPTIONS };
