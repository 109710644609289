import React from 'react';
import PropTypes from 'prop-types';

import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import PurveyorBrandSection from 'components/upb_viewer/pdp_components/PurveyorBrandSection';
import PurveyorManufacturerSection from 'components/upb_viewer/pdp_components/PurveyorManufacturerSection';

const PurveyorDetailsSection = ({ brand = {}, manufacturer = {} }) => {
  if (!brand?.description && !manufacturer?.description) {
    return null;
  }

  const purveyorHeader = brand?.description
    ? `About ${brand.name}`
    : manufacturer?.description
    ? `About ${manufacturer.name}`
    : 'About the Purveyor';

  return (
    <AttributeSection title={purveyorHeader}>
      <div className="ml-3 pt-2 pb-3">
        {brand.description ? (
          <PurveyorBrandSection productBrand={brand} />
        ) : (
          manufacturer.description && <PurveyorManufacturerSection manufacturer={manufacturer} />
        )}
      </div>
    </AttributeSection>
  );
};

PurveyorDetailsSection.propTypes = {
  brand: PropTypes.object,
  manufacturer: PropTypes.object,
};

export default PurveyorDetailsSection;
