import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Tab, Tabs } from 'react-bootstrap';

import OtherImagesSection from 'components/product_lists/product_list/qa_product_list/OtherImagesSection';
import RecommendationsSection from 'components/product_lists/product_list/qa_product_list/RecommendationsSection';
import UploadImagesSection from 'components/product_lists/product_list/qa_product_list/UploadImagesSection';

const IMAGE_RECOMMENDATIONS_TAB = {
  RECOMMENDED_IMAGES: 'Recommended Images',
  UPLOAD_IMAGES: 'Upload Images',
  OTHER_IMAGES: 'Other Images',
};

function RecommendedImagesTabView({
  l4CategoryId,
  setSelectedThumbnailId,
  selectedThumbnailUrl,
  setSelectedThumbnailUrl,
  uploadedImages,
  setUploadedImages,
  brandId,
  hasOtherImages,
  productId,
  productType,
}) {
  const [activeTabKey, setActiveTabKey] = useState(IMAGE_RECOMMENDATIONS_TAB.RECOMMENDED_IMAGES);
  return (
    <div>
      <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
        <Tab
          eventKey={IMAGE_RECOMMENDATIONS_TAB.RECOMMENDED_IMAGES}
          title={IMAGE_RECOMMENDATIONS_TAB.RECOMMENDED_IMAGES}
        >
          <div className={classNames('my-3')}>
            <RecommendationsSection
              l4CategoryId={l4CategoryId}
              selectedThumbnailUrl={selectedThumbnailUrl}
              setSelectedThumbnailId={setSelectedThumbnailId}
              setSelectedThumbnailUrl={setSelectedThumbnailUrl}
              brandId={brandId}
            />
          </div>
        </Tab>
        <Tab
          eventKey={IMAGE_RECOMMENDATIONS_TAB.UPLOAD_IMAGES}
          title={IMAGE_RECOMMENDATIONS_TAB.UPLOAD_IMAGES}
        >
          <div className="my-3">
            <UploadImagesSection
              imageUrls={uploadedImages}
              setImageUrls={setUploadedImages}
              selectedThumbnailUrl={selectedThumbnailUrl}
              setSelectedThumbnailUrl={setSelectedThumbnailUrl}
            />
          </div>
        </Tab>
        {hasOtherImages && (
          <Tab
            eventKey={IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES}
            title={IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES}
          >
            <div className={classNames('my-3')}>
              <OtherImagesSection
                productId={productId}
                productType={productType}
                selectedThumbnailUrl={selectedThumbnailUrl}
                setSelectedThumbnailId={setSelectedThumbnailId}
                setSelectedThumbnailUrl={setSelectedThumbnailUrl}
              />
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

RecommendedImagesTabView.propTypes = {
  l4CategoryId: PropTypes.number,
  selectedThumbnailId: PropTypes.number,
  setSelectedThumbnailId: PropTypes.func,
  uploadedImages: PropTypes.array,
  setUploadedImages: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
  brandId: PropTypes.number,
  hasOtherImages: PropTypes.bool,
  productId: PropTypes.number,
  productType: PropTypes.string,
};

export default RecommendedImagesTabView;
