import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { areYouSure } from 'lib/utils/toast';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { anchovy } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import RecommendedImagesTabView from 'components/l4_default_images/recommendations/RecommendedImagesTabView';
import {
  TAXONOMY_SET_L4_DEFAULT_IMAGE,
  TAXONOMY_SET_L4_DEFAULT_IMAGE_NOT_APPLICABLE,
} from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function L4DefaultImageSection({
  selectedL4CategoryId,
  setSelectedL4CategoryId,
  selectedThumbnailUrl,
  setSelectedThumbnailUrl,
  selectedL4CategoryName,
  setSelectedL4CategoryName,
  L4Category,
  l4CategoryOptions,
  loading,
  refetchCategories,
}) {
  const history = useHistory();
  const [uploadedImages, setUploadedImages] = useState([]);

  useEffect(() => {
    setUploadedImages([]);
  }, [selectedL4CategoryId]);

  const { postData: markTooBroad } = usePost(TAXONOMY_SET_L4_DEFAULT_IMAGE_NOT_APPLICABLE, () => {
    Swal.fire({
      icon: 'success',
      text: 'Default image state updated as too broad',
      title: 'Success',
    }).then(() => goToNextCategory());
  });

  const { postData: setL4DefaultImage, loading: updating } = usePost(
    TAXONOMY_SET_L4_DEFAULT_IMAGE,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Default Image updated successfully',
        title: 'Success',
      }).then(() => {
        const currentIndex = l4CategoryOptions.findIndex(
          option => option.id === selectedL4CategoryId,
        );
        const nextIndex = (currentIndex + 1) % l4CategoryOptions.length;
        setSelectedL4CategoryName(l4CategoryOptions[nextIndex]?.label);
        setSelectedL4CategoryId(l4CategoryOptions[nextIndex]?.id);
        refetchCategories();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while updating default image: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  const categories = [
    { label: 'L1 Category', name: L4Category?.l1_category_name },
    { label: 'L2 Category', name: L4Category?.l2_category_name },
    { label: 'L3 Category', name: L4Category?.l3_category_name },
  ];

  const goToNextCategory = () => {
    const currentIndex = l4CategoryOptions.findIndex(option => option.id === selectedL4CategoryId);
    const nextIndex = (currentIndex + 1) % l4CategoryOptions.length;
    setSelectedL4CategoryName(l4CategoryOptions[nextIndex]?.label);
    setSelectedL4CategoryId(l4CategoryOptions[nextIndex]?.id);
  };

  if (loading) {
    return <LoadingSpinner short />;
  }
  return (
    <div>
      <Row className="mb-3">
        <Col>
          <Row>
            <Col>
              <h2>
                <strong>L4: {selectedL4CategoryName}</strong>
              </h2>
            </Col>
          </Row>
          {selectedL4CategoryName ? (
            <Row>
              {categories.map((category, index) => (
                <Col key={index}>
                  <strong>{`${category.label} : ${category.name}`}</strong>
                </Col>
              ))}
            </Row>
          ) : null}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Image
            className={css(styles.square)}
            fluid
            src={selectedThumbnailUrl ?? placeholderProductImage}
          />
        </Col>
        <Col>
          <Row className="my-4">
            <Col>
              {L4Category?.default_image_uploaded_by ? (
                <strong>{`Image uploaded by : ${L4Category?.default_image_uploaded_by}`}</strong>
              ) : null}
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              {L4Category?.feedback ? (
                <strong>{`Feedback : ${L4Category?.feedback}`}</strong>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Button
            className="mr-3"
            variant="outline-primary"
            onClick={() => {
              setL4DefaultImage({
                l4_category_id: selectedL4CategoryId,
                default_image_url: selectedThumbnailUrl,
              });
              setUploadedImages([]);
            }}
            size="lg"
            disabled={updating || !selectedL4CategoryId || !selectedThumbnailUrl}
          >
            Save Changes
          </Button>
          <Button
            className="mr-3"
            variant="outline-primary"
            onClick={() => {
              const currentIndex = l4CategoryOptions.findIndex(
                option => option.id === selectedL4CategoryId,
              );
              const previousIndex =
                (currentIndex - 1 + l4CategoryOptions.length) % l4CategoryOptions.length;
              setSelectedL4CategoryName(l4CategoryOptions[previousIndex]?.label);
              setSelectedL4CategoryId(l4CategoryOptions[previousIndex]?.id);
            }}
            size="lg"
            disabled={updating || !selectedL4CategoryId}
          >
            Previous
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => goToNextCategory()}
            size="lg"
            disabled={updating || !selectedL4CategoryId}
          >
            Next
          </Button>
          <Button
            disabled={updating || !selectedL4CategoryId}
            className="ml-3"
            onClick={() =>
              areYouSure(() => {
                markTooBroad({
                  l4_category_id: selectedL4CategoryId,
                });
              }, 'Mark this L4 category as too broad to have an image?')
            }
          >
            Too Broad
          </Button>
        </Col>
        <Col>
          <Button
            className="float-right"
            onClick={() => history.push('/enrich-products/l4-default-images/review')}
          >
            Review Images
          </Button>
        </Col>
      </Row>
      <hr />
      <RecommendedImagesTabView
        l4CategoryId={selectedL4CategoryId}
        uploadedImages={uploadedImages}
        setUploadedImages={setUploadedImages}
        selectedThumbnailUrl={selectedThumbnailUrl}
        setSelectedThumbnailUrl={setSelectedThumbnailUrl}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    borderBottom: 'none',
  },
  recommendationsContainer: {
    height: '420px',
    overflowY: 'auto',
  },
  square: {
    width: '250px',
    height: '250px',
    objectFit: 'contain',
    border: '1px solid ' + anchovy,
    marginBottom: '0.75rem',
    padding: '0.2rem',
  },
});

L4DefaultImageSection.propTypes = {
  selectedL4CategoryId: PropTypes.number,
  setSelectedL4CategoryId: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
  selectedL4CategoryName: PropTypes.string,
  setSelectedL4CategoryName: PropTypes.func,
  L4Category: PropTypes.object,
  l4CategoryOptions: PropTypes.array,
  loading: PropTypes.bool,
  refetchCategories: PropTypes.func,
};

export default L4DefaultImageSection;
