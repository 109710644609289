import React from 'react';

import { camelCaseToSentence, snakeCaseToSentence } from 'lib/utils/StringProcess';

function displayJsonSafeText(text) {
  if (!text || typeof text !== 'string') {
    return text;
  }
  try {
    if (text.includes('•')) {
      const items = text
        .split('•')
        .map(item => item.trim())
        .slice(1);
      return (
        <>
          {items.map((item, index) => (
            <p className="mb-1" key={index}>
              {item}
            </p>
          ))}
        </>
      );
    }

    const jsonText = JSON.parse(text.replaceAll("'", '"'));
    // If JSON Object type string
    if (text.match(/^{.*}$/)) {
      return (
        <ul className="list-unstyled my-0">
          {Object.keys(jsonText).map((key, idx) => (
            <li key={idx}>
              {camelCaseToSentence(key)}: <b>{snakeCaseToSentence(jsonText[key])}</b>
            </li>
          ))}
        </ul>
      );
      // If Object array
    } else if (text.match(/^\[.*\]$/) && Array.isArray(jsonText)) {
      return (
        <>
          {jsonText.map((json, idx) => (
            <div className={idx && idx > 0 ? 'border-top mt-2 pt-2' : ''} key={idx}>
              {displayJsonSafeText(JSON.stringify(json))}
            </div>
          ))}
        </>
      );
    }
    return text;
  } catch (e) {
    return text;
  }
}

export { displayJsonSafeText };
