import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';

import ImageCategorySelector from 'components/image_tagger/ImageCategorySelector';
import ImageFacingSelector from 'components/image_tagger/ImageFacingSelector';

function ImageTaggingPanel({
  selectedImageCategory,
  setSelectedImageCategory,
  selectedImageFacing,
  setSelectedImageFacing,
  onsubmit,
  disabled,
}) {
  return (
    <div className="border-left pl-5">
      <h5 className="font-weight-bold">Tag</h5>
      <Row>
        <Col>
          <ImageCategorySelector
            selectedImageCategory={selectedImageCategory}
            setSelectedImageCategory={setSelectedImageCategory}
            disabled={disabled}
          />
        </Col>
        <Col>
          <ImageFacingSelector
            selectedImageFacing={selectedImageFacing}
            setSelectedImageFacing={setSelectedImageFacing}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button disabled={disabled} variant="primary" onClick={() => onsubmit()}>
            Tag Selected
          </Button>
        </Col>
      </Row>
    </div>
  );
}

ImageTaggingPanel.propTypes = {
  selectedImageCategory: PropTypes.object,
  setSelectedImageCategory: PropTypes.func,
  selectedImageFacing: PropTypes.string,
  setSelectedImageFacing: PropTypes.func,
  onsubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ImageTaggingPanel;
