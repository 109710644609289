import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { areYouSure } from 'lib/utils/toast';
import { blackberry, white } from 'lib/css/colors';
import {
  BRANDS_UPDATE_FROM_FSA,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import { SwalToast } from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function PrimaryBrandEditModal({ onHide, brandData, setBrandData }) {
  const [updatedBrandData, setUpdatedBrandData] = useState({
    name: brandData.brandName,
    manufacturer_name: brandData.manufacturerName,
    manufacturer_id: brandData.manufacturerID,
    keywords: brandData.keywords,
    brand_url: brandData.brandURL,
    description: brandData.description,
  });

  const { postData: updateBrand } = usePost(
    BRANDS_UPDATE_FROM_FSA,
    () => {
      SwalToast('success', `Brand updated successfully`);
    },
    () => SwalToast('error', `Failed to update the brand`),
  );

  const handleSaveBrandData = () => {
    let changedBrandData = {};

    const addChangeIfUpdated = (fieldKey, updatedValue, originalValue) => {
      //function to add changed data to be sent to the backend, only changed data are sent
      if (updatedValue !== originalValue) {
        changedBrandData[fieldKey] = updatedValue ? updatedValue : null;
      }
    };
    addChangeIfUpdated('name', updatedBrandData.name, brandData.brandName);
    addChangeIfUpdated(
      'manufacturer_id',
      updatedBrandData.manufacturer_id,
      brandData.manufacturerID,
    );
    addChangeIfUpdated('brand_url', updatedBrandData.brand_url, brandData.brandURL);
    addChangeIfUpdated('description', updatedBrandData.description, brandData.description);

    if (
      JSON.stringify(updatedBrandData.keywords.sort()) !== JSON.stringify(brandData.keywords.sort())
    ) {
      changedBrandData.keywords = updatedBrandData.keywords;
    }
    setBrandData(prev => ({
      ...prev,
      brandName: updatedBrandData.name,
      manufacturerID: updatedBrandData.manufacturer_id,
      manufacturerName: updatedBrandData.manufacturer_name,
      brandURL: updatedBrandData.brand_url,
      description: updatedBrandData.description,
      keywords: [...updatedBrandData.keywords],
    }));
    if (Object.keys(changedBrandData).length > 0) {
      changedBrandData.id = brandData.brandID;
      changedBrandData.from_qa = true;
      updateBrand(changedBrandData);
    } else {
      SwalToast('info', 'No changes were made to the brand data.');
    }
    onHide();
  };

  return (
    <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>Edit Brand Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">Brand Name</Form.Label>
            <Form.Control
              type="text"
              value={updatedBrandData.name}
              placeholder="Enter the new brand name"
              onChange={e =>
                setUpdatedBrandData(Prev => ({
                  ...Prev,
                  name: e.target.value,
                }))
              }
              className="mb-3"
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">Manufacturer</Form.Label>
            <SearchableScrollableSelect
              initOption={{
                label: updatedBrandData.manufacturer_name,
                value: updatedBrandData.manufacturer_id,
              }}
              onChange={option =>
                setUpdatedBrandData(Prev => ({
                  ...Prev,
                  manufacturer_id: option?.value,
                  manufacturer_name: option?.label,
                }))
              }
              fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
              optionMapper={manufacturer => ({
                label: manufacturer.name,
                value: manufacturer.id,
              })}
              className="mb-3"
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">
              Keywords: {brandData.keywords.join(', ')}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new keywords as comma separated values"
              onChange={e =>
                setUpdatedBrandData(Prev => ({
                  ...Prev,
                  keywords: [
                    ...brandData.keywords,
                    ...e.target.value
                      .split(',')
                      .map(word => word.trim())
                      .filter(Boolean),
                  ],
                }))
              }
              className="mb-3"
            />
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">Brand URL</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                value={updatedBrandData.brand_url}
                placeholder="Enter the new URL"
                onChange={e =>
                  setUpdatedBrandData(prev => ({ ...prev, brand_url: e.target.value }))
                }
                className="mr-2"
                style={{ flex: 1 }}
              />
            </div>
          </Form.Group>
        </div>
        <div className={css(styles.section)}>
          <Form.Group>
            <Form.Label className="font-weight-bold">Description:</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={updatedBrandData.description}
              placeholder="Enter the new description"
              onChange={e =>
                setUpdatedBrandData(prev => ({ ...prev, description: e.target.value }))
              }
              className="mb-3"
              style={{ overflowY: 'auto' }}
            />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <div>
          <Button
            variant="primary"
            onClick={() => {
              if (updatedBrandData.name) {
                areYouSure(() => {
                  handleSaveBrandData();
                }, 'Are you sure want to save the changes?');
              } else {
                SwalToast('error', 'Brand name cannot be empty.');
              }
            }}
            disabled={!updatedBrandData.name}
          >
            Save
          </Button>
          <Button variant="outline-primary" onClick={onHide} className="ml-2">
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
  updateButton: {
    backgroundColor: blackberry,
    borderColor: blackberry,
    color: white,
    fontWeight: 'bold',
  },
  section: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '8px',
    marginBottom: '10px',
    backgroundColor: '#f9f9f9',
  },
});

PrimaryBrandEditModal.propTypes = {
  onHide: PropTypes.func,
  brandData: PropTypes.object,
  setBrandData: PropTypes.func,
};

export default PrimaryBrandEditModal;
