import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { FormControl } from 'react-bootstrap';

import pointerOnHover from 'lib/css/pointerOnHover';

function DisplayValueTableRow({ valueRecord, updatedRecords, setUpdatedRecords }) {
  const updatedValue = updatedRecords[valueRecord.attribute_value];
  const currentValue =
    updatedValue !== undefined ? updatedValue : valueRecord.attribute_display_value || '';

  const handleChange = e => {
    const newValue = e.target.value;
    // Update the updatedRecords JSON object with the new value
    setUpdatedRecords(prevUpdatedRecords => ({
      ...prevUpdatedRecords,
      [valueRecord.attribute_value]: newValue,
    }));
  };

  return (
    <tr className={pointerOnHover}>
      <td width="10">{valueRecord.attribute_value}</td>
      <td width="10">{'=>'}</td>
      <td>
        <FormControl
          autoFocus
          value={currentValue}
          onChange={handleChange}
          className={css(styles.otherColumn)}
        />
      </td>
    </tr>
  );
}

DisplayValueTableRow.propTypes = {
  valueRecord: PropTypes.object,
  updatedRecords: PropTypes.object,
  setUpdatedRecords: PropTypes.func,
};

const styles = StyleSheet.create({
  otherColumn: {
    maxWidth: 300,
  },
});

export default DisplayValueTableRow;
