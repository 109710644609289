import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TOKEN_CONFIG } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

const ConfigContext = createContext({});

function ConfigContextProvider({ children }) {
  const [config, setConfig] = useState({});
  const { data = {} } = useGet(TOKEN_CONFIG);

  useEffect(() => {
    // Only update state if data has changed
    if (JSON.stringify(data) !== JSON.stringify(config)) {
      setConfig(data);
    }
  }, [data, config]);

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}

ConfigContextProvider.propTypes = {
  children: PropTypes.node,
};

export { ConfigContext };
export default ConfigContextProvider;
