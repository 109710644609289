import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { isEmpty, omit } from 'lodash';
import Swal from 'sweetalert2';

import BrandFilter from 'components/product_lists/product_list/filters/BrandFilter';
import ClassificationStatusFilter from 'components/product_lists/product_list/filters/ClassificationStatusFilter';
import CutDrySyncStatusFilter from 'components/product_lists/product_list/filters/CutDrySyncStatusFilter';
import { getURL, PRODUCT_LIST } from 'lib/networking/endpoints';
import ManufacturerFilter from 'components/product_lists/product_list/filters/ManufacturerFilter';
import ProductListFilter from 'lib/enums/ProductListFilter';
import StatusFilter from 'components/product_lists/product_list/filters/StatusFilter';
import usePut from 'lib/hooks/usePut';
import VendorFilter from 'components/product_lists/product_list/filters/VendorFilter';
import VerificationStatusFilter from 'components/product_lists/product_list/filters/VerificationStatusFilter';

function ProductListFiltersSection({ productListId, filters, refetch }) {
  const [selectedFilters, setSelectedFilters] = useState(filters || {});
  const updateFilters = (key, values) => {
    if (values?.length) {
      setSelectedFilters({
        ...selectedFilters,
        [key]: values,
      });
    } else {
      setSelectedFilters(omit(selectedFilters, key));
    }
  };

  const { putData: updateProductList, loading: updating } = usePut(
    getURL(PRODUCT_LIST, { product_list_id: productListId }),
    () => {
      refetch();
      Swal.fire({
        title: 'Success',
        text: 'Product list filters successfully updated.',
        icon: 'success',
      });
    },
    error =>
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        icon: 'error',
      }),
  );

  return (
    <div className={css(styles.container)}>
      <h4 className="font-weight-bold">Filters</h4>
      <hr className="my-0" />
      <div className="my-3">
        <ManufacturerFilter
          selectedValues={selectedFilters[ProductListFilter.MANUFACTURER_IDS] || []}
          updateFilters={updateFilters}
        />
      </div>
      <div className="my-3">
        <VendorFilter
          selectedValues={selectedFilters[ProductListFilter.DISTRIBUTOR_IDS] || []}
          updateFilters={updateFilters}
        />
      </div>
      <div className="my-3">
        <BrandFilter
          selectedValues={selectedFilters[ProductListFilter.BRAND_IDS] || []}
          updateFilters={updateFilters}
        />
      </div>
      <div className="my-3">
        <StatusFilter
          selectedValues={selectedFilters[ProductListFilter.STATUS] || []}
          updateFilters={updateFilters}
        />
      </div>
      <div className="my-3">
        <CutDrySyncStatusFilter
          selectedValue={selectedFilters[ProductListFilter.CND_SYNC_STATUS]}
          updateFilters={updateFilters}
        />
      </div>
      <div className="my-3">
        <ClassificationStatusFilter
          selectedValue={selectedFilters[ProductListFilter.CLASSIFICATION_STATUS]}
          updateFilters={updateFilters}
        />
      </div>
      <div className="my-3">
        <VerificationStatusFilter
          selectedValues={selectedFilters[ProductListFilter.VERIFICATION_STATUS] || []}
          updateFilters={updateFilters}
        />
      </div>
      <div className="my-3">
        <Button
          disabled={isEmpty(selectedFilters) || updating}
          onClick={() => updateProductList({ filters: selectedFilters })}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '85vh',
  },
});

ProductListFiltersSection.propTypes = {
  productListId: PropTypes.string,
  filters: PropTypes.object,
  refetch: PropTypes.func,
};

export default ProductListFiltersSection;
