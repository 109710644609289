import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Row } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { areYouSure } from 'lib/utils/toast';
import { grape } from 'lib/css/colors';
import { ProductTypeDisplay } from 'lib/enums/ProductType';

function ConvertManufacturerProductsModal({
  globalProductId,
  productListId,
  selectedProductsList,
  convertProducts,
  convertProductsOfProductList,
  onHide,
}) {
  const [convertForProductList, setConvertForProductList] = useState(false);

  return (
    <Modal animation={false} centered show={true} onHide={onHide}>
      <div>
        <Modal.Body>
          <Row className="my-3">
            <Col>
              <h6>
                <strong>
                  Should all related products in this product list from same manufacturer be
                  converted to distributor products?
                </strong>
              </h6>
              <ToggleSwitch
                className="ml-1 mr-4"
                onColor={grape}
                checked={convertForProductList}
                onChange={e => setConvertForProductList(e)}
              />
            </Col>
          </Row>
          <hr />
          <div>
            <Button
              className="mr-3 px-4"
              disabled={!selectedProductsList.length}
              variant="outline-danger"
              onClick={
                convertForProductList
                  ? () => {
                      areYouSure(
                        () =>
                          convertProductsOfProductList({
                            product_list_id: productListId,
                            manufacturer_product_ids: selectedProductsList
                              .filter(
                                product => product.entity_type === ProductTypeDisplay.MANUFACTURER,
                              )
                              .map(product => product.id),
                          }),
                        'Are you sure you want to convert the manufacturer products for the whole product list?',
                      );
                      onHide();
                    }
                  : () => {
                      areYouSure(
                        () =>
                          convertProducts({
                            global_product_id: globalProductId,
                            manufacturer_product_ids: selectedProductsList
                              .filter(
                                product => product.entity_type === ProductTypeDisplay.MANUFACTURER,
                              )
                              .map(product => product.id),
                          }),
                        'Are you sure you want to convert the selected manufacturer products?',
                      );
                      onHide();
                    }
              }
            >
              Convert Manufacturer Products
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

ConvertManufacturerProductsModal.propTypes = {
  globalProductId: PropTypes.number,
  productListId: PropTypes.number,
  selectedProductsList: PropTypes.array,
  convertProducts: PropTypes.func,
  convertProductsOfProductList: PropTypes.func,
  onHide: PropTypes.func,
};

export default ConvertManufacturerProductsModal;
