// updated along with app/lib/enums/taxonomy_level.py
const taxonomyColumns = {
  l4_category_name: 'l4_category_name',
  l3_category_name: 'l3_category_name',
  l2_category_name: 'l2_category_name',
  l1_category_name: 'l1_category_name',
  l0_category_name: 'l0_category_name',
  l4_rosetta_category_id: 'l4_rosetta_category_id',
  l3_rosetta_category_id: 'l3_rosetta_category_id',
  l2_rosetta_category_id: 'l2_rosetta_category_id',
  l1_rosetta_category_id: 'l1_rosetta_category_id',
  l0_rosetta_category_id: 'l0_rosetta_category_id',
};

const TaxonomyLevel = {
  L0: '0',
  L1: '1',
  L2: '2',
  L3: '3',
  L4: '4',
};

const TaxonomyGlobal = {
  GL: 'GL',
};

const CATEGORY_OPTIONS = [
  { value: TaxonomyGlobal.GL, label: 'Global' },
  { value: TaxonomyLevel.L0, label: 'L0 Category' },
  { value: TaxonomyLevel.L1, label: 'L1 Category' },
  { value: TaxonomyLevel.L2, label: 'L2 Category' },
  { value: TaxonomyLevel.L3, label: 'L3 Category' },
  { value: TaxonomyLevel.L4, label: 'L4 Category' },
];

export { CATEGORY_OPTIONS, taxonomyColumns, TaxonomyGlobal };
export default TaxonomyLevel;
