import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import CreateUpdateDataStreamSyncJob from 'components/data_streams/CreateUpdateDataStreamSyncJob';
import { DATA_STREAM_PROCESS } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import { white } from 'lib/css/colors';

function DataStreamViewTableRow({ dataStreamRecord }) {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [showDataStreamSyncJobModal, setShowDataStreamSyncJobModal] = useState(false);
  const [selectedDataStreamSyncJob, setSelectedDataStreamSyncJob] = useState(null);
  const dataStreamSyncJobs = dataStreamRecord.data_stream_sync_jobs || [];

  const { postData: processDataStreamSync } = usePost(
    DATA_STREAM_PROCESS,
    () => {
      SwalDialog('success', `successfully started`, 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'Error occurred while starting', 'Error', 'center');
    },
  );

  function updateDataStreamSyncJobs(newStreamSyncJob, isExisting = false) {
    if (isExisting) {
      // Update existing job
      const jobIndex = dataStreamSyncJobs.findIndex(job => job.id === newStreamSyncJob.id);
      if (jobIndex !== -1) {
        dataStreamSyncJobs[jobIndex] = newStreamSyncJob;
      }
    } else {
      // Add new job
      dataStreamSyncJobs.push(newStreamSyncJob);
    }
    setSelectedDataStreamSyncJob(null);
    setShowDataStreamSyncJobModal(false);
  }

  return (
    <>
      <tr key={dataStreamRecord.id}>
        <td>
          <FontAwesomeIcon
            icon={expanded ? faChevronUp : faChevronDown}
            size="lg"
            className="pr-2"
            onClick={() => setExpanded(!expanded)}
          />
          {dataStreamRecord.stream_name}
        </td>
        <td>{dataStreamRecord.stream_type}</td>
        <td>{dataStreamRecord.stream_export_type}</td>
        <td>{dataStreamSyncJobs.length}</td>
        <td>
          <Button
            variant="link"
            onClick={() => {
              setSelectedDataStreamSyncJob(null);
              setShowDataStreamSyncJobModal(true);
            }}
            className="pl-0"
          >
            Create Sync Job
          </Button>
          <Button
            variant="link"
            onClick={() => history.push(`/data-streams/${dataStreamRecord.id}`)}
          >
            View
          </Button>
        </td>
      </tr>
      {expanded && (
        <tr>
          <td colSpan="5" className="pl-4 pt-0 pb-0 pr-0">
            <div className="expanded-table-container">
              <Table className={css(styles.dropDownTable)}>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Cron Expression</th>
                    <th>Next Run</th>
                    <th>Last Updated Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {dataStreamSyncJobs.map(syncJob => (
                    <tr key={syncJob.id}>
                      <td>{syncJob.in_progress ? 'In Progress' : '-'}</td>
                      <td>{syncJob.cron_expression}</td>
                      <td>{syncJob.get_human_readable_next_run}</td>
                      <td>{syncJob.meta_data?.last_processed_time}</td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => {
                            setSelectedDataStreamSyncJob(syncJob);
                            setShowDataStreamSyncJobModal(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="link"
                          onClick={() =>
                            processDataStreamSync({ data_stream_sync_job_id: syncJob.id })
                          }
                        >
                          Process Now
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </td>
        </tr>
      )}
      {showDataStreamSyncJobModal ? (
        <CreateUpdateDataStreamSyncJob
          onHide={() => setShowDataStreamSyncJobModal(false)}
          dataStreamId={dataStreamRecord.id}
          updateDataStreamSyncJobs={updateDataStreamSyncJobs}
          dataStreamSyncJob={selectedDataStreamSyncJob}
        />
      ) : null}
    </>
  );
}

DataStreamViewTableRow.propTypes = {
  dataStreamRecord: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  dropDownTable: {
    backgroundColor: white,
  },
});

export default DataStreamViewTableRow;
