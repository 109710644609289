// update along with app/lib/enums/vendor_category.py
const VendorCategory = {
  MEAT: 'Meat',
  SEAFOOD: 'SeaFood',
  PRODUCE: 'Produce',
  BROADLINER: 'Broadliner',
};

const VendorCategoryOptions = Object.entries(VendorCategory).map(([value, label]) => ({
  label,
  value,
}));

export default VendorCategory;

export { VendorCategoryOptions };
