import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'react-bootstrap';

function DropDownMenu({
  attributeName,
  editAttribute,
  broadlinerCompliance,
  deleteAttribute,
  showBroadlinerCompliance = false,
}) {
  return (
    <Dropdown alignRight>
      <Dropdown.Toggle variant="outline-none" id="dropdown-basic"></Dropdown.Toggle>
      {attributeName}
      <Dropdown.Menu>
        <Dropdown.Item onClick={editAttribute}>Edit</Dropdown.Item>
        {showBroadlinerCompliance === true ? (
          <Dropdown.Item onClick={broadlinerCompliance}>Broadliner Compliance</Dropdown.Item>
        ) : null}
        <Dropdown.Item onClick={deleteAttribute}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

DropDownMenu.propTypes = {
  attributeName: PropTypes.string,
  editAttribute: PropTypes.func,
  broadlinerCompliance: PropTypes.func,
  deleteAttribute: PropTypes.func,
  showBroadlinerCompliance: PropTypes.bool,
};

export default DropDownMenu;
