import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import { backgroundGrey4 } from 'lib/css/colors';
import BrandRow from 'components/manage_brands/brand_details/BrandRow';

function BrandDetailView({ brand, updateBrand, refetch }) {
  const brandRows = {
    name: 'Brand Name',
    brand_url: 'Brand Website',
    manufacturer_id: 'Manufacturer',
    slogan: 'Slogan',
    description: 'Description',
    similar_brands: 'Similar Brands',
    video_urls: 'Video URLs',
  };
  return (
    <div className="px-4 mt-3">
      {Object.keys(brandRows).map(key => (
        <div key={key}>
          <BrandRow
            label={key}
            title={brandRows[key]}
            brand={brand}
            updateBrand={updateBrand}
            refetch={refetch}
          />
          <hr className={css(styles.separator)} />
        </div>
      ))}
    </div>
  );
}

const styles = StyleSheet.create({
  separator: {
    color: backgroundGrey4,
    backgroundColor: backgroundGrey4,
    margin: '6px 0',
  },
});

BrandDetailView.propTypes = {
  brand: PropTypes.object,
  updateBrand: PropTypes.func,
  refetch: PropTypes.func,
};

export default BrandDetailView;
