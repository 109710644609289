const EntityType = {
  DISTRIBUTOR: 'DISTRIBUTOR',
  MANUFACTURER: 'MANUFACTURER',
};

const ENTITY_TYPE_OPTIONS = [
  { value: EntityType.DISTRIBUTOR, label: 'Distributor' },
  { value: EntityType.MANUFACTURER, label: 'Manufacturer' },
];

export default EntityType;
export { ENTITY_TYPE_OPTIONS };
