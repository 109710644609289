import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AttributeIcons from '@awesome.me/kit-ff5c0e126f/icons/modules/kit/custom';
import Select from 'react-select/creatable';

function AttributeIconSelect({ attributeDetails, updateAttribute }) {
  const options = Object.keys(AttributeIcons).map(iconName => ({
    value: iconName,
    label: (
      <div>
        <Row>
          <Col xs={2}>
            <FontAwesomeIcon icon={AttributeIcons[iconName]} />
          </Col>
          <Col>{iconName.replace(/^fa/, '').replace(/Vect$/, '')}</Col>
        </Row>
      </div>
    ),
  }));
  return (
    <Col sm={3}>
      <Select
        placeholder="Select Icon"
        value={options.find(op => op.value === attributeDetails?.icon)}
        options={options}
        onChange={option => updateAttribute({ ...attributeDetails, icon: option.value })}
      />
    </Col>
  );
}

AttributeIconSelect.propTypes = {
  updateAttribute: PropTypes.func,
  attributeDetails: PropTypes.object,
};

export default AttributeIconSelect;
