import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Dropdown } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AddEditAttributeValueRow from 'components/manage_attribute_value/shared/AddEditAttributeValueRow';
import { backgroundGrey2, darkGrey } from 'lib/css/colors';
import MergeAttributeValueModal from 'components/manage_attribute_value/value_section/MergeAttributeValueModal';
import pointerOnHover from 'lib/css/pointerOnHover';

function AttributeValueSection({
  createNewAttributeValue,
  attribute_values = [],
  editRecord,
  deleteRecord,
  subSection,
  section,
  attributeId,
}) {
  const [showNewAttributeValueRow, setShowNewAttributeValueRow] = useState(false);
  const [selectedAttributeValueId, setSelectedAttributeValueId] = useState();
  const [showMergeAttributeValueModal, setShowMergeAttributeValueModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({ id: '', value: '' });

  return (
    <div>
      <Row className={classNames(css(styles.header), 'mt-4 py-1 font-weight-bold')}>
        <Col sm={2}>
          <FontAwesomeIcon
            className={classNames(css(styles.plusIcon), pointerOnHover, 'float-left mt-1')}
            icon={faPlus}
            color={darkGrey}
            onClick={() => setShowNewAttributeValueRow(true)}
          />
          <span className="float-left pl-2">Values</span>
        </Col>
      </Row>
      <div className={css(styles.scrollableDiv)}>
        {attribute_values.map(value => (
          <div key={value.id}>
            {rowToEdit.id === value.id ? (
              <AddEditAttributeValueRow
                onHide={() => setRowToEdit({ id: '', value: '' })}
                attributeValueId={value.id}
                value={value.attribute_value}
                fsaAttributeValueId={value.attribute_value_id}
                usdaCode={value.usda_code}
                editRecord={editRecord}
                createNewAttributeValue={createNewAttributeValue}
              />
            ) : (
              <Row>
                <Col sm={12}>
                  <Dropdown alignRight>
                    <Dropdown.Toggle variant="outline-none" id="dropdown-basic"></Dropdown.Toggle>
                    {value.attribute_value} - {section?.section_id}.{subSection?.sub_section_id}.
                    {attributeId}.{value.attribute_value_id} - {value.usda_code}
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setRowToEdit({ id: value.id })}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => deleteRecord({ attributeValueId: value.id })}>
                        Delete
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedAttributeValueId(value.id);
                          setShowMergeAttributeValueModal(true);
                        }}
                      >
                        Merge Attribute Value
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            )}
            <hr className="my-0 mr-1" />
          </div>
        ))}
        {showNewAttributeValueRow === true ? (
          <AddEditAttributeValueRow
            onHide={() => setShowNewAttributeValueRow(false)}
            createNewAttributeValue={createNewAttributeValue}
          />
        ) : null}
        {showMergeAttributeValueModal ? (
          <MergeAttributeValueModal
            onHide={() => setShowMergeAttributeValueModal(false)}
            attribute_values={attribute_values}
            selected_attribute_value_id={selectedAttributeValueId}
          />
        ) : null}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: backgroundGrey2,
  },
  scrollableDiv: {
    overflow: 'scroll',
    height: '250px',
  },
  plusIcon: {
    height: '15px',
    width: '15px',
    border: `1px solid ${darkGrey}`,
  },
});

AttributeValueSection.propTypes = {
  createNewAttributeValue: PropTypes.func,
  attribute_values: PropTypes.array,
  editRecord: PropTypes.func,
  deleteRecord: PropTypes.func,
  subSection: PropTypes.object,
  section: PropTypes.object,
  attributeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AttributeValueSection;
