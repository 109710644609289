import React, { useState } from 'react';

import classNames from 'classnames';
import { Col, Row, Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from 'use-debounce';

import { backgroundGrey3, white } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import { GLOBAL_VENDORS } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageVendorsTable from 'components/manage_vendors/ManageVendorsTable';
import PaginationComponent from 'components/shared/PaginationComponent';
import SearchBar from 'components/shared/SearchBar';
import useGet from 'lib/hooks/useGet';
import VendorCreateModal from 'components/manage_vendors/VendorCreateModal';
import VendorFilters from 'components/manage_vendors/filters/VendorFilters';

function ManageVendorsView() {
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery] = useDebounce(searchInput, 200);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [showFilters, setShowFilters] = useState(false);
  const [isDPPartner, setIsDPPartner] = useState('');
  const [showCreateVendorModal, setShowCreateVendorModal] = useState(false);

  const { data: { vendors, count } = {}, loading } = useGet(GLOBAL_VENDORS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    query: searchQuery,
    is_dp_partner: isDPPartner,
  });

  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Manage Vendors</h3>
      </div>
      <Row>
        <Col xs={4}>
          <SearchBar
            value={searchInput}
            setValue={setSearchInput}
            placeholder="Search by manufacturer name"
          />
        </Col>
        <Col className="d-flex mr-2 justify-content-start">
          <Button variant="link" onClick={() => setShowFilters(!showFilters)} className="p-2">
            <FontAwesomeIcon size="lg" icon={faFilter} className="mr-2" />
            {showFilters ? 'Hide' : 'Show'} Filters
          </Button>
        </Col>
        <Col className="d-flex mr-2 justify-content-end">
          <Button variant="primary" onClick={() => setShowCreateVendorModal(true)}>
            Create Vendor
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex mt-3">
          <span className={classNames(css(styles.selectedText), 'text-secondary ml-2')}>
            {count} Vendors
          </span>
        </Col>
        <Col className={classNames(css(styles.pagination), 'd-flex justify-content-end')}>
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            defaultSize
          />
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        {loading ? <LoadingSpinner short /> : <ManageVendorsTable vendors={vendors} />}
        <div className={css(styles.vendorFiltersOverlay, showFilters ? '' : styles.overlayHidden)}>
          <VendorFilters
            setIsDPPartner={setIsDPPartner}
            setShowFilters={setShowFilters}
            loading={loading}
          />
        </div>
      </div>
      {showCreateVendorModal ? (
        <VendorCreateModal onHide={() => setShowCreateVendorModal(false)} />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '90vh',
  },
  vendorFiltersOverlay: {
    position: 'absolute',
    padding: '1.5rem',
    top: 40,
    right: 0,
    bottom: 0,
    width: '30vw',
    minHeight: '100vh',
    backgroundColor: white,
    borderLeft: '5px solid' + backgroundGrey3,
    transitionProperty: 'transform, visibility, width',
    transitionDuration: '0.4s',
    transitionTimingFunction: 'ease-in-out',
    zIndex: 999,
    visibility: 'visible',
  },
  overlayHidden: {
    transform: 'translateX(100%)',
    visibility: 'hidden',
    width: 0,
  },
  pagination: {
    marginTop: '1rem',
    zIndex: 2,
  },
  selectedText: {
    fontSize: '1rem',
  },
});

export default ManageVendorsView;
