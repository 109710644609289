import React, { useContext } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isEqual, reject } from 'lodash';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

import Container from 'components/shared/Container';
import { DATA_STREAM } from 'lib/networking/endpoints';
import { SingleDataStreamViewContext } from 'components/data_streams/SingleDataStreamViewContainer';
import SelectedAttributesSplitViewRow from 'components/data_streams/attribute_map_tab/SelectedAttributesSplitViewRow';
import usePut from 'lib/hooks/usePut';

function SelectedAttributesSplitView() {
  const { record_id } = useParams();
  const { selectedAttributes, setSelectedAttributes } = useContext(SingleDataStreamViewContext);

  const { putData: updateDataStream } = usePut(
    DATA_STREAM,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully updated',
        title: 'Success',
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const handleClick = attr => {
    // remove the attribute from the selected list
    const uniqKey = attr.id + '_' + attr.attributeName + '_' + attr.isGlobalAttribute;
    setSelectedAttributes(reject(selectedAttributes, obj => isEqual(obj.uniqKey, uniqKey)));
  };

  const handleUpdate = (attr, columnName, isRequired) => {
    const uniqKey = attr.id + '_' + attr.attributeName + '_' + attr.isGlobalAttribute;
    const tempArray = selectedAttributes.map(attr => {
      if (isEqual(attr.uniqKey, uniqKey)) {
        return { ...attr, columnName: columnName, isRequired: isRequired };
      }
      return attr;
    });
    setSelectedAttributes(tempArray);
  };

  function handleUpdateStream() {
    updateDataStream({
      attribute_map: selectedAttributes.map(attr => ({
        column_name: attr.columnName,
        attribute_name: attr.attributeName,
        is_global_attribute: attr.isGlobalAttribute,
        attribute_id: attr.id,
        required: attr.isRequired,
      })),
      data_stream_id: record_id,
    });
  }

  const handleOnDragEnd = result => {
    if (!result?.destination) {
      return;
    }
    // Logic to reorder your selectedAttributes based on the drag result
    const reorderedAttributes = Array.from(selectedAttributes);
    const [reorderedItem] = reorderedAttributes.splice(result.source.index, 1);
    reorderedAttributes.splice(result.destination.index, 0, reorderedItem);
    setSelectedAttributes(reorderedAttributes);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col className="d-flex justify-content-end">
          <Button onClick={() => handleUpdateStream()}>Save</Button>
        </Col>
      </Row>
      <Row className="mb-3 pl-2">
        <Col sm="5">Attribute Name</Col>
        <Col sm="5">Column Name</Col>
        <Col sm="2">Is Required</Col>
      </Row>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="columnsDroppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {selectedAttributes.map((attr, index) => (
                <Draggable key={attr.uniqKey} draggableId={attr.uniqKey} index={index}>
                  {providedDraggable => (
                    <SelectedAttributesSplitViewRow
                      objectValue={attr}
                      eventHandler={handleClick}
                      handleUpdate={handleUpdate}
                      provided={providedDraggable}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
}

export default SelectedAttributesSplitView;
