import React, { useState } from 'react';

import classNames from 'classnames';
import { Button, Container, Col, Form, Row } from 'react-bootstrap';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { BRANDS_CREATE, GLOBAL_MANUFACTURERS_FOR_DROPDOWN } from 'lib/networking/endpoints';
import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import DropZone from 'components/shared/Dropzone';
import pointerOnHover from 'lib/css/pointerOnHover';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

const BRAND_LOGO_FOLDER = 'brand_logo';

function BrandEditorView() {
  const history = useHistory();

  const { postData: createBrand } = usePost(
    BRANDS_CREATE,
    res => {
      SwalDialog('success', 'Successfully created new brand', 'Success', 'center');
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while creating the brand: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const [name, setName] = useState();
  const [manufacturerID, setManufacturerID] = useState();
  const [description, setDescription] = useState();
  const [slogan, setSlogan] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [brandUrl, setBrandUrl] = useState();
  const [archived, setArchived] = useState(false);
  const [verified, setVerified] = useState(false);

  return (
    <Container>
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mr-2')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/enrich-products/manage-brands')}
        />
        Create New Brand
      </h3>

      <div className="border my-3 shadow-sm">
        <Form
          className="px-3 py-3"
          onSubmit={e => {
            e.preventDefault();
            if (name) {
              createBrand({
                name,
                manufacturerID,
                description,
                slogan,
                archived,
                logo_url: logoUrl,
                brand_url: brandUrl,
                verified: verified
                  ? BrandVerificationStatus.VERIFIED
                  : BrandVerificationStatus.UNVERIFIED,
              });
            }
          }}
        >
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Brand Name'}
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                value={name || ''}
                onChange={e => setName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Manufacturers Name'}
            </Form.Label>
            <Col sm="10">
              <SearchableScrollableSelect
                autoFocus={false}
                onChange={option => setManufacturerID(option?.value ?? '')}
                fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
                optionMapper={manufacturer => ({
                  label: manufacturer.name,
                  value: manufacturer.id,
                })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Description'}
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                rows="6"
                value={description || ''}
                onChange={e => setDescription(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Slogan'}
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                value={slogan || ''}
                onChange={e => setSlogan(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Brand URL'}
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                value={brandUrl || ''}
                onChange={e => setBrandUrl(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Logo'}
            </Form.Label>
            <Col sm="10">
              <DropZone
                text="Drag and drop the logo image here, or click to select the image"
                onUploadSuccess={newUrl => setLogoUrl(newUrl)}
                fileUrls={logoUrl}
                isPublic={true}
                isImage={true}
                filePath={BRAND_LOGO_FOLDER}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Archived'}
            </Form.Label>
            <Col sm="8">
              <Form.Check
                type="checkbox"
                checked={!!archived}
                onChange={e => setArchived(e.target.checked)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              {'Verified'}
            </Form.Label>
            <Col sm="8">
              <Form.Check
                type="checkbox"
                checked={!!verified}
                onChange={e => setVerified(e.target.checked)}
              />
            </Col>
          </Form.Group>

          <Button type="submit">{'Create New Brand'}</Button>
        </Form>
      </div>
    </Container>
  );
}

export default BrandEditorView;
