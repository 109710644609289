import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { ManufacturerVerificationStatusOptions } from 'lib/enums/ManufacturerVerificationStatus';

function ManufacturerFilters({
  setProductList,
  status,
  setStatus,
  loading,
  productList,
  setShowFilters,
  product_lists,
  productListOptions,
}) {
  const [selectedStatus, setSelectedStatus] = useState(status || '');
  const [selectedProductList, setSelectedProductList] = useState(
    productListOptions.find(option => option.label === productList?.name),
  );

  return (
    <div>
      <h4 className="font-weight-bold">Filters</h4>
      <hr className="my-0" />
      <div className="my-3">
        <Form.Group>
          <Form.Label>Product List</Form.Label>
          <Select
            isClearable
            options={productListOptions}
            value={selectedProductList}
            placeholder="Select a Product List"
            onChange={setSelectedProductList}
          />
          <Form.Label className="mt-3">Status</Form.Label>
          <Select
            isClearable
            options={ManufacturerVerificationStatusOptions}
            value={ManufacturerVerificationStatusOptions.find(
              statu => statu?.value === selectedStatus,
            )}
            placeholder="Select Manufacturer Verification Status"
            onChange={option => setSelectedStatus(option?.value)}
          />
        </Form.Group>
      </div>
      <div className="my-4">
        <Button
          disabled={loading}
          onClick={() => {
            setProductList(product_lists.find(list => list.id === selectedProductList?.value));
            setStatus(selectedStatus);
            setShowFilters(false);
          }}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
}

ManufacturerFilters.propTypes = {
  productList: PropTypes.object,
  setProductList: PropTypes.func,
  loading: PropTypes.bool,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  setShowFilters: PropTypes.func,
  product_lists: PropTypes.array,
  productListOptions: PropTypes.array,
};

export default ManufacturerFilters;
