import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { VendorConfigurationStatusOptions } from 'lib/enums/VendorConfigurationStatus';

function VendorFilters({ setIsDPPartner, loading, setShowFilters }) {
  const [selectedDPPartnerStatus, setSelectedDPPartnerStatus] = useState('');

  return (
    <div>
      <h4 className="font-weight-bold">Filters</h4>
      <hr className="my-0" />
      <div className="my-3">
        <Form.Group>
          <Form.Label className="mt-3">DP Partner</Form.Label>
          <Select
            isClearable
            options={VendorConfigurationStatusOptions}
            value={VendorConfigurationStatusOptions.find(
              statu => statu?.value === selectedDPPartnerStatus,
            )}
            placeholder="Select Vendor DP Partner Status"
            onChange={option => setSelectedDPPartnerStatus(option?.value)}
          />
        </Form.Group>
      </div>
      <div className="my-4">
        <Button
          disabled={loading}
          onClick={() => {
            setIsDPPartner(selectedDPPartnerStatus);
            setShowFilters(false);
          }}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
}

VendorFilters.propTypes = {
  loading: PropTypes.bool,
  setIsDPPartner: PropTypes.func,
  setShowFilters: PropTypes.func,
};

export default VendorFilters;
