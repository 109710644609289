import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { Image } from 'react-bootstrap';

import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';

const DATE_FORMAT = 'MM/DD/YYYY HH:mm:ss';

function ProductRow({ product }) {
  const handleProductClick = () => {
    const url = `/global-product-detail/${product.global_product_id}/${product.product_id}/${product.product_type}`; // eslint-disable-line
    window.open(url, '_blank');
  };

  return (
    <tr key={product.id} className={pointerOnHover} onClick={handleProductClick}>
      <td>{product.sku}</td>
      <td>
        <Image
          src={product.propagated_thumbnail_image_url ?? placeholderProductImage}
          className="mx-auto d-block"
          width="40"
          height="40"
          alt="Product"
        />
      </td>
      <td>{product.product_name}</td>
      <td>{product.l4_category_name}</td>
      <td>{dayjs(product.date_modified).format(DATE_FORMAT)}</td>
      <td>{dayjs(product.date_created).format(DATE_FORMAT)}</td>
    </tr>
  );
}

ProductRow.propTypes = {
  product: PropTypes.object,
};

export default ProductRow;
