import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { IMAGE_RECOMMENDATIONS } from 'lib/networking/endpoints';
import ImagesSection from 'components/assets/image_recommendations/recommendations/ImagesSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useGet from 'lib/hooks/useGet';

function ThumbnailsSection({ l4CategoryId, selectedThumbnailId, setSelectedThumbnailId, hideTab }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const {
    data: { data: images, count: imageCount } = {},
    loading,
    error,
  } = useGet(IMAGE_RECOMMENDATIONS, {
    l4_category_id: l4CategoryId,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    thumbnails: true,
  });

  useEffect(() => {
    if (images && !images.length) {
      hideTab();
    }
    // ignore hideTab to avoid render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  if (loading) {
    return <LoadingSpinner short />;
  }

  if (error) {
    return null;
  }

  if (!images?.length) {
    return (
      <div className="w-100 text-center">
        No thumbnail recommendations found for the given L4 Category.
      </div>
    );
  }

  return (
    <div>
      <div className="w-100 d-flex justify-content-between mb-2">
        <div className="text-secondary">
          <span>
            {imageCount} Image{imageCount === 1 ? '' : 's'}
          </span>
        </div>
        <PaginationComponent
          totalItems={imageCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <ImagesSection
        images={images}
        onImageClick={imageId => setSelectedThumbnailId(imageId)}
        isImageSelected={imageId => selectedThumbnailId === imageId}
      />
    </div>
  );
}

ThumbnailsSection.propTypes = {
  l4CategoryId: PropTypes.number,
  selectedThumbnailId: PropTypes.number,
  setSelectedThumbnailId: PropTypes.func,
  hideTab: PropTypes.func,
};

export default ThumbnailsSection;
