import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { getURL, TAXONOMY_CATEGORY_LEVEL_OPTIONS } from 'lib/networking/endpoints';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import useGet from 'lib/hooks/useGet';

function L1CategoryFilter({ selectedL1CategoryId, setSelectedL1CategoryId, parentLoading }) {
  const {
    data: { data: categories } = {},
    loading,
    error,
  } = useGet(getURL(TAXONOMY_CATEGORY_LEVEL_OPTIONS, { category_level: TaxonomyLevel.L1 }));
  const categoryOptions = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    return categories.map(category => ({
      label: category.category_name,
      value: category.id,
    }));
  }, [categories]);

  if (error) {
    return null;
  }

  return (
    <Form.Group>
      <Form.Label>Select L1 Category</Form.Label>
      <Select
        isClearable
        isDisabled={loading || parentLoading}
        options={categoryOptions}
        value={categoryOptions.find(option => option.value === selectedL1CategoryId)}
        onChange={option => setSelectedL1CategoryId(option?.value)}
        placeholder={loading ? 'Loading...' : ''}
      />
    </Form.Group>
  );
}

L1CategoryFilter.propTypes = {
  selectedL1CategoryId: PropTypes.number,
  setSelectedL1CategoryId: PropTypes.func,
  parentLoading: PropTypes.bool,
};

export default L1CategoryFilter;
