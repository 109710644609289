import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs } from 'react-bootstrap';

import ActivityView from 'components/shared/ActivityView';
import { BRANDS_GET_BY_MANUFACTURER } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import ManufacturerDetailView from 'components/manage_manufacturers/manufacturer_details/ManufacturerDetailView';
import ManufacturerBrandsTable from 'components/manage_manufacturers/manufacturer_details/ManufacturerBrandsTable';
import ProductsView from 'components/manage_manufacturers/shared/ProductsView';
import useGet from 'lib/hooks/useGet';

const MANUFACTURER_DETAILS_VIEW_TABS = {
  MANUFACTURER_DETAILS: 'Manufacturer Details',
  LINKED_BRANDS: 'Linked Brands',
  MANUFACTURER_ACTIVITY: 'Manufacturer Activity',
  MANUFACTURER_PRODUCTS: 'Manufacturer Products',
};

function ManufacturerMenuSplitView({ manufacturer, updateManufacturer, refetch }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const { data: { brands = [] } = {} } = useGet(BRANDS_GET_BY_MANUFACTURER, {
    manufacturer_id: manufacturer.id,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });
  const [activeTabKey, setActiveTabKey] = useState(
    MANUFACTURER_DETAILS_VIEW_TABS.MANUFACTURER_DETAILS,
  );

  return (
    <Container>
      <Tabs
        className="mb-2 border-top-0"
        defaultActiveKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
      >
        <Tab
          eventKey={MANUFACTURER_DETAILS_VIEW_TABS.MANUFACTURER_DETAILS}
          title={MANUFACTURER_DETAILS_VIEW_TABS.MANUFACTURER_DETAILS}
        >
          <ManufacturerDetailView
            manufacturer={manufacturer}
            updateManufacturer={updateManufacturer}
            refetch={refetch}
          />
        </Tab>
        <Tab
          eventKey={MANUFACTURER_DETAILS_VIEW_TABS.LINKED_BRANDS}
          title={MANUFACTURER_DETAILS_VIEW_TABS.LINKED_BRANDS}
        >
          <ManufacturerBrandsTable
            brands={brands}
            brandCount={manufacturer.brand_count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Tab>
        <Tab
          eventKey={MANUFACTURER_DETAILS_VIEW_TABS.MANUFACTURER_PRODUCTS}
          title={MANUFACTURER_DETAILS_VIEW_TABS.MANUFACTURER_PRODUCTS}
        >
          <ProductsView manufacturerId={manufacturer.id} />
        </Tab>
        <Tab
          eventKey={MANUFACTURER_DETAILS_VIEW_TABS.MANUFACTURER_ACTIVITY}
          title={MANUFACTURER_DETAILS_VIEW_TABS.MANUFACTURER_ACTIVITY}
        >
          <ActivityView manufacturerId={manufacturer.id} />
        </Tab>
      </Tabs>
    </Container>
  );
}

ManufacturerMenuSplitView.propTypes = {
  manufacturer: PropTypes.object,
  updateManufacturer: PropTypes.func,
  refetch: PropTypes.func,
};

export default ManufacturerMenuSplitView;
