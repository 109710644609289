import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';

import { anchovy } from 'lib/css/colors';
import EditableProperty from 'components/manage_vendors/vendor_details/EditableProperty';
import { faSquareCheck } from 'images/icons/fsa-monotone-svg-icons';
import pointerOnHover from 'lib/css/pointerOnHover';
import VendorDisplayField from './VendorDisplayField';

function VendorEditableField({
  label,
  vendor,
  isEditing,
  setIsEditing,
  setUpdatedVendor,
  updatedVendor,
  updateVendor,
}) {
  return isEditing ? (
    <Form onBlur={() => setIsEditing(false)} className={css(styles.row)}>
      <FontAwesomeIcon
        className={classNames(pointerOnHover, css(styles.editIcon))}
        icon={faSquareCheck}
        onMouseDown={() => {
          updateVendor(updatedVendor);
          setIsEditing(false);
        }}
        size="lg"
      />
      <EditableProperty
        label={label}
        vendor={vendor}
        setIsEditing={setIsEditing}
        setUpdatedVendor={setUpdatedVendor}
      />
    </Form>
  ) : (
    <div className={css(styles.row)}>
      <FontAwesomeIcon
        className={classNames(pointerOnHover, css(styles.editIcon))}
        icon={faPen}
        onClick={() => {
          setIsEditing(true);
        }}
      />
      <VendorDisplayField label={label} vendor={vendor} />
    </div>
  );
}

const styles = StyleSheet.create({
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  editIcon: {
    marginRight: '0.75rem',
  },
});

VendorEditableField.propTypes = {
  label: PropTypes.string.isRequired,
  vendor: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  setUpdatedVendor: PropTypes.func.isRequired,
  updatedVendor: PropTypes.object,
  updateVendor: PropTypes.func.isRequired,
};

export default VendorEditableField;
