import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form } from 'react-bootstrap';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EditableFieldV2({
  value,
  setValue,
  placeholder,
  loading = false,
  view_as_plain_text = false,
  editButtonSide = 'left',
}) {
  const [edit, setEdit] = useState(false);
  const [val, setVal] = useState(value);

  const editButton = (
    <Button
      variant="outline-dark"
      className="mr-1 px-1 boarder-0"
      disabled={loading}
      onClick={() => setEdit(true)}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Button>
  );

  return (
    <div>
      <Form>
        {edit ? (
          <div>
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                autoFocus
                value={val || ''}
                onChange={e => setVal(e.target.value)}
                placeholder={placeholder}
              />
              <Button
                className="ml-1 float-right"
                onClick={() => {
                  setValue(val);
                  setEdit(false);
                }}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <Button
                className="ml-1"
                variant="outline-primary"
                onClick={() => {
                  setVal(value);
                  setEdit(false);
                }}
                disabled={loading}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            {editButtonSide === 'left' ? editButton : null}
            {view_as_plain_text ? (
              val
            ) : (
              <Form.Control type="text" value={val} readOnly className="pl-3" />
            )}
            {editButtonSide === 'right' ? (
              <>
                <span className="pl-1"></span>
                {editButton}
              </>
            ) : null}
          </div>
        )}
      </Form>
    </div>
  );
}

EditableFieldV2.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  view_as_plain_text: PropTypes.bool,
  editButtonSide: PropTypes.string,
};

export default EditableFieldV2;
