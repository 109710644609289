import React from 'react';
import PropTypes from 'prop-types';

import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function HelperTooltip({ text }) {
  return (
    <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
      <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} size="lg" />
    </OverlayTrigger>
  );
}

HelperTooltip.propTypes = {
  text: PropTypes.string,
};

export default HelperTooltip;
