import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'react-bootstrap';

function ImageChangeRow({ imageChange }) {
  const newImageUrl = imageChange.new_value;
  const oldImageUrl = imageChange.old_value;
  const operation =
    oldImageUrl && newImageUrl ? 'Update' : oldImageUrl ? 'Remove' : newImageUrl ? 'Add' : null;
  const metadata = imageChange.meta_data || {};
  const displayUrl = newImageUrl || oldImageUrl;

  return (
    <tr>
      <td>{operation}</td>
      <td>{displayUrl ? <Image src={displayUrl} height={50} /> : null}</td>
      <td>{metadata.asset_type?.new_value || 'N/A'}</td>
      <td>{metadata.category?.new_value || 'N/A'}</td>
      <td>{metadata.facing?.new_value || 'N/A'}</td>
      <td>
        {newImageUrl ? (
          <a href={newImageUrl} rel="noopener noreferrer" target="_blank">
            Download Image
          </a>
        ) : null}
      </td>
    </tr>
  );
}

ImageChangeRow.propTypes = {
  imageChange: PropTypes.object,
};

export default ImageChangeRow;
