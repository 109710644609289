import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft, faRedoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import { areYouSure } from 'lib/utils/toast';
import Container from 'components/shared/Container';
import {
  DATA_SOURCE_GET_BY_ID,
  DATA_SOURCE_LAST_RUN_DATA,
  DATA_SOURCE_PROCESS,
  getURL,
} from 'lib/networking/endpoints';
import DataSourceSummaryTab from 'components/data_sources/single_data_source_view/DataSourceSummaryTab';
import DataSourcePendingAttributeTable from 'components/pending_attribute_mapping/DataSourcePendingAttributeTable';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function DataSourcePendingAttributeMappingView() {
  const { data_source_id } = useParams();
  const history = useHistory();

  const {
    data: dataSource,
    loading,
    error,
    refetch,
  } = useGet(getURL(DATA_SOURCE_GET_BY_ID, { data_source_id: data_source_id }));

  const {
    data: dataSourceRun = {},
    loading: loadingRun,
    refetch: refetchRun,
  } = useGet(
    DATA_SOURCE_LAST_RUN_DATA,
    {
      data_source_id: data_source_id,
    },
    !data_source_id,
  );

  useEffect(() => {
    if (dataSourceRun && dataSourceRun.run_metadata) {
      dataSourceRun.run_metadata.retries = dataSourceRun.retries || 0;
    }
    if (dataSourceRun && dataSourceRun.in_progress) {
      const interval = setInterval(() => {
        refetchRun();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [dataSourceRun, refetchRun]);

  const { postData: processDataSource, loading: processing } = usePost(DATA_SOURCE_PROCESS);

  const runInProgress = useMemo(
    () => loadingRun || (dataSourceRun && dataSourceRun.in_progress),
    [loadingRun, dataSourceRun],
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  if (!dataSource) {
    return <h3 className="my-5 text-center">Data Source not found</h3>;
  }

  return (
    <Container>
      <Row className="mr-4">
        <Col>
          <h3 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
              onClick={() => history.push('/pending-attribute-mappings')}
            />
            {dataSource.source_name}
          </h3>
        </Col>
        <Col className="ml-auto col-auto">
          <Row className="d-flex flex-column">
            <Col className="text-right">
              <Button
                variant="outline-primary"
                disabled={runInProgress || processing}
                onClick={() =>
                  processDataSource({
                    data_source_id: data_source_id,
                    only_later_mapped_attributes: true,
                  }).then(() => {
                    setTimeout(() => {
                      refetchRun();
                    }, 1000);
                  })
                }
              >
                {runInProgress ? 'Run in progress, please wait' : 'Process Mapped Attributes Now'}
              </Button>

              <OverlayTrigger overlay={<Tooltip>Forcefully resume/restart this process</Tooltip>}>
                <Button
                  disabled={processing || !runInProgress}
                  className="ml-2"
                  variant="danger"
                  onClick={() =>
                    areYouSure(
                      () =>
                        processDataSource({
                          data_source_id: data_source_id,
                          forcefully_resume: true,
                          only_later_mapped_attributes: true,
                        }).then(() => {
                          setTimeout(() => {
                            refetchRun();
                          }, 1000);
                        }),
                      'This will forcefully restart/resume the process. ' +
                        "This is not recommended unless you know what you're doing.",
                    )
                  }
                >
                  <FontAwesomeIcon icon={faRedoAlt} />
                </Button>
              </OverlayTrigger>
            </Col>
            {dataSourceRun?.run_metadata && (
              <Col className="text-right mt-2">
                <div className={classNames(css(styles.smallText), 'text-muted')}>
                  {JSON.stringify(dataSourceRun.run_metadata, null, 2)}
                  {loadingRun && (
                    <span className={classNames(css(styles.smallText), 'text-muted ml-2')}>
                      Loading...
                    </span>
                  )}
                </div>
              </Col>
            )}

            {dataSourceRun?.error_report_url && (
              <Col className="text-right mt-2">
                <div className={classNames(css(styles.smallText), 'text-muted')}>
                  <a
                    href={dataSourceRun.error_report_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Error Report
                  </a>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <DataSourcePendingAttributeTable dataSource={dataSource} refetch={refetch} />
    </Container>
  );
}

const styles = StyleSheet.create({
  smallText: {
    fontSize: '0.8rem',
  },
});

DataSourceSummaryTab.propTypes = {
  dataSource: PropTypes.object,
};

export default DataSourcePendingAttributeMappingView;
