import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { sectionsSubheaderClass } from 'components/upb_viewer/css/sharedStyles';

function AttributeSubsection({ title, children }) {
  return (
    <div className={css(styles.container)}>
      {title && (
        <div className={classNames(css(styles.header), sectionsSubheaderClass)}>{title}</div>
      )}
      <div>{children}</div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 25,
  },
  header: {
    marginBottom: 8,
  },
});

AttributeSubsection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default AttributeSubsection;
