import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import CollapsibleSection from 'components/upb_viewer/CollapsibleSection';
import { darkGrey2 } from 'lib/css/colors';
import { DEFAULT_ALL_CATEGORY_OPTION } from 'components/upb_viewer/UPBViewer';
import {
  getURL,
  TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS,
  PRODUCT_LIST_VENDOR_CATEGORIES,
} from 'lib/networking/endpoints';
import NestedFiltersGroup from 'components/upb_viewer/NestedFiltersGroup';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

const UncategoriedOption = {
  label: 'Uncategorized',
  value: 'Uncategorized',
};
function QAProductListSidePanel({
  productListId,
  selectedVendorCategory,
  setSelectedVendorCategory,
  selectedRosettaCategory,
  setSelectedRosettaCategory,
  useRosettaCategory,
  setCurrentPage,
}) {
  const { data: { data: rosettaCategories } = {}, loading: rosettaCategoryLoading } = useGet(
    TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS,
    { use_display_names: true },
  );

  const rosettaCategoryOptions = useMemo(() => {
    if (!rosettaCategories?.length) {
      return [];
    }
    const catOptions = rosettaCategories
      .map(category => ({
        label: category.display_name,
        value: category.display_name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    catOptions.unshift(UncategoriedOption);
    catOptions.unshift(DEFAULT_ALL_CATEGORY_OPTION);
    return catOptions;
  }, [rosettaCategories]);

  const { data: { data: categories } = {}, loading } = useGet(
    getURL(PRODUCT_LIST_VENDOR_CATEGORIES, { product_list_id: productListId }),
  );

  const categoryOptions = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    const categoryOptionsArray = categories
      .map(category => ({
        label: category.vendor_category,
        value: category.vendor_category,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .filter(category => category.label !== '');
    categoryOptionsArray.unshift(UncategoriedOption);
    categoryOptionsArray.unshift(DEFAULT_ALL_CATEGORY_OPTION);
    return categoryOptionsArray;
  }, [categories]);

  return (
    <div className={css(styles.container)}>
      {useRosettaCategory ? (
        <CollapsibleSection title="Rosetta Category" expandedByDefault>
          {rosettaCategoryLoading ? (
            <LoadingSpinner short />
          ) : (
            <div className={css(styles.filterGroupContainer)}>
              <NestedFiltersGroup
                options={rosettaCategoryOptions}
                selectedOptions={[
                  rosettaCategoryOptions.find(option => option.value === selectedRosettaCategory),
                ]}
                setSelectedOptions={options => {
                  setSelectedRosettaCategory(options?.[0]?.value);
                  setCurrentPage(1);
                }}
              />
            </div>
          )}
        </CollapsibleSection>
      ) : (
        <CollapsibleSection title="Category" expandedByDefault>
          {loading ? (
            <LoadingSpinner short />
          ) : (
            <div className={css(styles.filterGroupContainer)}>
              <NestedFiltersGroup
                options={categoryOptions}
                selectedOptions={[
                  categoryOptions.find(option => option.value === selectedVendorCategory),
                ]}
                setSelectedOptions={options => {
                  setSelectedVendorCategory(options?.[0]?.value || null);
                  setCurrentPage(1);
                }}
              />
            </div>
          )}
        </CollapsibleSection>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 35,
    overflowY: 'auto',
    borderRight: '1px solid ' + darkGrey2,
  },
  filterGroupContainer: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
});

QAProductListSidePanel.propTypes = {
  productListId: PropTypes.string.isRequired,
  selectedVendorCategory: PropTypes.string,
  setSelectedVendorCategory: PropTypes.func,
  selectedRosettaCategory: PropTypes.string,
  setSelectedRosettaCategory: PropTypes.func,
  useRosettaCategory: PropTypes.bool,
  setCurrentPage: PropTypes.func,
};

export default QAProductListSidePanel;
