import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Media, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from 'components/providers/AuthContextProvider';
import { NAVIGATION_SIDE_BAR_WIDTH } from 'lib/constants';
import { backgroundGrey3, blueviolet, white } from 'lib/css/colors';
import {
  fsaDashboard,
  fsaDataSources,
  fsaDataStreams,
  fsaEnrichProducts,
  fsaManageAttributes,
  fsaManageTaxonomy,
  fsaProducts,
  fsaSettings,
  fsaSignOut,
  fsaStats,
} from 'images/icons/navbar';
import userPic from 'lib/img/avatar.jpg';
import Logo from 'lib/img/fsaLogo.png';
import useUserPermissions from 'lib/hooks/useUserPermissions';
import SideNavItem from 'components/layout/sidenav/SideNavItem';
import SideNavItemWithChildren from 'components/layout/sidenav/SideNavItemWithChildren';
import UserPermission from 'lib/enums/UserPermission';

function AppSideNavBar({ expanded }) {
  const { authUser, homeUrl } = useContext(AuthContext);
  const hasUserPermissions = useUserPermissions();

  return (
    <div
      className={classNames(
        css(styles.container, expanded && styles.containerExpanded),
        'd-flex flex-column',
        !expanded && 'd-none',
      )}
    >
      <div className="mt-5">
        <Nav className={css(styles.nav)}>
          <Nav.Link
            className={classNames(css(styles.link, styles.name), 'text-white font-weight-bold')}
          >
            <Media>
              <img
                className={css(styles.circle)}
                src={authUser?.picture_url || userPic}
                alt="User Avatar"
              />
            </Media>
          </Nav.Link>
          {hasUserPermissions([UserPermission.VIEW_DASHBOARD]) ? (
            <SideNavItem title="Dashboard" url="/dashboard" icon={fsaDashboard} />
          ) : null}
          {hasUserPermissions([UserPermission.UPB_VIEW], false) ? (
            <SideNavItemWithChildren
              title="Products"
              icon={fsaProducts}
              submenuItems={[
                {
                  url: '/all-products',
                  title: 'All Products',
                  permissions: [UserPermission.CLASSIFY],
                },
                {
                  url: '/product-lists',
                  title: 'Product Lists',
                  permissions: [UserPermission.MANAGE_PRODUCT_LISTS],
                },
                {
                  url: '/upb-viewer/Bean%20(Legume)%20%26%20Mushroom__8',
                  title: 'UPB Viewer',
                  permissions: [UserPermission.UPB_VIEW],
                },
                {
                  url: '/file-exports',
                  title: 'File Exports',
                  permissions: [UserPermission.VIEW_FILE_EXPORTS],
                },
                {
                  url: '/manage-display-values',
                  title: 'Manage Display Values',
                  permissions: [UserPermission.EDIT_ATTRIBUTES],
                },
                {
                  url: '/duplicate-global-products',
                  title: 'Link Duplicate Products',
                  permissions: [UserPermission.VIEW_SKU_MAPPINGS],
                },
                {
                  url: '/global-product-linkages',
                  title: 'Manage Global Product Links',
                  permissions: [UserPermission.VIEW_SKU_MAPPINGS],
                },
              ]}
            />
          ) : null}
          {hasUserPermissions([UserPermission.EDIT_ATTRIBUTES]) ? (
            <SideNavItemWithChildren
              title="Attributes"
              icon={fsaManageAttributes}
              submenuItems={[
                {
                  url: '/assign-attributes',
                  title: 'Assign Attributes',
                  permissions: [UserPermission.EDIT_ATTRIBUTES],
                },
                {
                  url: '/manage-attributes',
                  title: 'Manage Attributes',
                  permissions: [UserPermission.EDIT_ATTRIBUTES],
                },
                {
                  url: '/manage-schema',
                  title: 'Manage Schema',
                  permissions: [UserPermission.EDIT_ATTRIBUTES],
                },
                {
                  url: '/manage-attribute-value-alternatives',
                  title: 'Manage Attribute Value Alternatives',
                  permissions: [UserPermission.EDIT_ATTRIBUTES],
                },
                {
                  url: '/pending-attribute-mappings',
                  title: 'Pending Attribute Mappings',
                  permissions: [UserPermission.EDIT_ATTRIBUTES],
                },
              ]}
            />
          ) : null}
          {hasUserPermissions([UserPermission.CLASSIFY, UserPermission.MANAGE_TAXONOMY], false) ? (
            <SideNavItemWithChildren
              title="Classify"
              icon={fsaManageTaxonomy}
              submenuItems={[
                {
                  url: '/global-products/classify',
                  title: 'Classify',
                  permissions: [UserPermission.CLASSIFY],
                },
                {
                  url: '/manage-taxonomy',
                  title: 'Manage Taxonomy',
                  permissions: [UserPermission.MANAGE_TAXONOMY],
                },
                {
                  url: '/ai-suggested-product-classifier',
                  title: 'AI Suggested Product Classifier',
                  permissions: [UserPermission.CLASSIFY],
                },
              ]}
            />
          ) : null}
          {hasUserPermissions([UserPermission.MANAGE_DATA_SOURCES]) ? (
            <SideNavItemWithChildren
              title="Sources"
              icon={fsaDataSources}
              submenuItems={[
                {
                  url: '/data-sources',
                  title: 'Data Sources',
                  permissions: [UserPermission.MANAGE_ASSETS],
                },
                {
                  url: '/product-content-validator',
                  title: 'Scraped Product Validator',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
              ]}
            />
          ) : null}
          {hasUserPermissions([UserPermission.MANAGE_DATA_STREAMS]) ? (
            <SideNavItemWithChildren
              title="Streams"
              icon={fsaDataStreams}
              submenuItems={[
                {
                  url: '/data-streams',
                  title: 'Data Streams',
                  permissions: [UserPermission.MANAGE_ASSETS],
                },
              ]}
            />
          ) : null}
          {hasUserPermissions(
            [UserPermission.ENRICH_PRODUCTS, UserPermission.BRAND_HARVESTING],
            false, // hasAll
          ) ? (
            <SideNavItemWithChildren
              title="Enrich"
              icon={fsaEnrichProducts}
              submenuItems={[
                {
                  url: '/assets',
                  title: 'Assets',
                  permissions: [UserPermission.MANAGE_ASSETS],
                },
                {
                  url: '/enrich-products/manage-brands',
                  title: 'Manage Brands',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
                {
                  url: '/enrich-products/brand-qa',
                  title: 'QA Brands',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
                {
                  url: '/enrich-products/brand-harvest',
                  title: 'Brand Harvesting Tool',
                  permissions: [UserPermission.BRAND_HARVESTING],
                },
                {
                  url: '/enrich-products/manage-manufacturers',
                  title: 'Manage Manufacturers',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
                {
                  url: '/enrich-products/manage-vendors',
                  title: 'Manage Vendors',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
                {
                  url: '/enrich-products/l4-default-images',
                  title: 'L4 Default Images',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
                {
                  url: '/image-tagger',
                  title: 'Image Tagger',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
                {
                  url: '/nutritional-panel-upload',
                  title: 'Upload Nutritional Panels',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
                {
                  url: '/enrich-products/gs1-lookup',
                  title: 'GS1 Look Up',
                  permissions: [UserPermission.ENRICH_PRODUCTS],
                },
              ]}
            />
          ) : null}
          {hasUserPermissions(
            [UserPermission.VIEW_USER_MANAGEMENT, UserPermission.DATA_SYNC],
            false,
          ) ? (
            <SideNavItemWithChildren
              title="Settings"
              icon={fsaSettings}
              submenuItems={[
                {
                  url: '/settings/team',
                  title: 'Team',
                  permissions: [UserPermission.VIEW_USER_MANAGEMENT],
                },
                {
                  url: '/sync-data',
                  title: 'Sync Data',
                  permissions: [UserPermission.DATA_SYNC],
                },
                {
                  url: '/task-view',
                  title: 'Task View',
                  permissions: [UserPermission.TASKS_VIEW],
                },
                {
                  url: '/settings/activity-logs',
                  title: 'Activity Logs',
                  permissions: [UserPermission.MANAGE_ACTIVITY_LOGS],
                },
              ]}
            />
          ) : null}
          {hasUserPermissions([[UserPermission.VIEW_SKU_MAPPINGS]]) ? (
            <SideNavItem title="Stats" url="/stats" icon={fsaStats} />
          ) : null}
          <Link to={homeUrl}>
            <div
              className={classNames(
                'd-flex align-items-center',
                css(styles.bottomLogo),
                'justify-content-center',
              )}
            >
              <img src={Logo} alt="fsaLogo" className={css(styles.logo)} />
            </div>
          </Link>
          <SideNavItem title="Sign Out" url="/login" icon={fsaSignOut} />
          <Link to={homeUrl}>
            <div
              className={classNames(
                'd-flex align-items-center',
                css(styles.bottomLogo),
                'justify-content-center',
              )}
            >
              <img src={Logo} alt="fsaLogo" className={css(styles.logo)} />
            </div>
          </Link>
        </Nav>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  bottomLogo: {
    position: 'fixed',
    bottom: '10px',
    width: NAVIGATION_SIDE_BAR_WIDTH - 4,
  },
  container: {
    backgroundColor: white,
    height: '100%',
    left: '-100vw',
    maxWidth: NAVIGATION_SIDE_BAR_WIDTH,
    position: 'fixed',
    top: 0,
    width: '80vw',
    zIndex: 1500, // high enough to be over the fade.
    borderRight: '4px solid ' + backgroundGrey3,
  },
  containerExpanded: {
    left: 0,
    color: white,
  },
  circle: {
    borderRadius: '60%',
    width: '100%',
    backgroundColor: blueviolet,
    objectFit: 'contain',
  },
  link: {
    padding: '0.5rem',
  },
  logo: {
    width: '90%',
  },
  name: {
    marginBottom: 25,
  },
  nav: {
    backgroundColor: white,
    flexDirection: 'column',
    maxWidth: NAVIGATION_SIDE_BAR_WIDTH,
    minHeight: '100%',
    minWidth: NAVIGATION_SIDE_BAR_WIDTH,
    animation: 'margin-left .75s forwards',
    position: 'fixed',
    borderRight: '4px solid ' + backgroundGrey3,
  },
});

AppSideNavBar.propTypes = {
  expanded: PropTypes.bool,
};

export default AppSideNavBar;
