import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { areYouSure } from 'lib/utils/toast';
import { DATA_SOURCE_ARCHIVE } from 'lib/networking/endpoints';
import { DATE_FORMAT_WITH_TIME } from 'lib/constants';
import pointerOnHover from 'lib/css/pointerOnHover';
import usePost from 'lib/hooks/usePost';

function DataSourceViewTableRow({ dataSourceRecord, refetch }) {
  const history = useHistory();
  const { postData: archiveDataSource } = usePost(
    DATA_SOURCE_ARCHIVE,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Data source archived successfully',
        title: 'Success',
        timer: 500,
      }).then(() => {
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while assigning images: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  return (
    <tr
      key={dataSourceRecord.id}
      className={pointerOnHover}
      onClick={() => history.push(`/data-sources/${dataSourceRecord.id}`)}
    >
      <td>{dataSourceRecord.source_name}</td>
      <td>{dataSourceRecord.entity_type}</td>
      <td>{dataSourceRecord.global_vendor?.name || dataSourceRecord.global_manufacturer?.name}</td>
      <td>{dataSourceRecord.source_type}</td>
      <td className={dataSourceRecord.latest_run?.in_progress ? 'text-danger' : ''}>
        {!dataSourceRecord.latest_run
          ? 'Never'
          : dataSourceRecord.latest_run?.in_progress
          ? 'In Progress'
          : 'Completed'}
      </td>
      <td>{dayjs(dataSourceRecord.latest_run?.date_modified).format(DATE_FORMAT_WITH_TIME)}</td>
      {dataSourceRecord.is_recurring ? (
        <td>{dataSourceRecord.latest_run?.run_metadata?.error_count || '-'}</td>
      ) : null}
      <td>
        <OverlayTrigger overlay={<Tooltip>Archive</Tooltip>}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="mr-3"
            onClick={e => {
              e.stopPropagation();
              areYouSure(
                () => archiveDataSource({ data_source_id: dataSourceRecord.id }),
                'Are you sure you want to archive this data source?',
              );
            }}
          />
        </OverlayTrigger>
      </td>
    </tr>
  );
}

DataSourceViewTableRow.propTypes = {
  dataSourceRecord: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default DataSourceViewTableRow;
