import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { BRANDS_GENERATE_ACTIVITY_REPORT } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function GenerateActivityReportModal({ onHide }) {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { postData: generateActivityReport } = usePost(
    BRANDS_GENERATE_ACTIVITY_REPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () => {
      SwalDialog('error', 'An error occurred while Generationg the File', 'Error', 'center');
    },
  );
  const handleGenerateActivityReport = () => {
    const fromDate = new Date(from);
    const toDate = new Date(to);

    if (fromDate > toDate) {
      SwalDialog(
        'error',
        '"From" date cannot be later than the "To" date',
        'Invalid Date Range',
        'center',
      );
      return;
    }
    generateActivityReport({
      from: from,
      to: to,
    });
  };
  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Generate Activity Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="mr-2 ml-2">
            <Row>
              <Form.Label>From</Form.Label>
            </Row>
            <Row>
              <Form.Control type="date" onChange={e => setFrom(e.target.value)}></Form.Control>
            </Row>
          </Col>
          <Col className="mr-2 ml-2">
            <Row>
              <Form.Label>To</Form.Label>
            </Row>
            <Row>
              <Form.Control type="date" onChange={e => setTo(e.target.value)}></Form.Control>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleGenerateActivityReport} disabled={!from || !to}>
          Generate Activity Report
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

GenerateActivityReportModal.propTypes = {
  onHide: PropTypes.func,
};

export default GenerateActivityReportModal;
