import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faCheck, faDownload, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import pointerOnHover from 'lib/css/pointerOnHover';
import { PRESIGNED_URL } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function DocumentsInputTypeView({ strJsonObject, setAttributeValue, isEditView = false }) {
  const [val, setVal] = useState(() => {
    try {
      return JSON.parse(strJsonObject) || [];
    } catch (e) {
      return []; // Default to an empty array if JSON parsing fails
    }
  });
  const [showAddForm, setShowAddForm] = useState(val.length === 0 && isEditView);
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  const { postData: getPresignedUrl } = usePost(
    PRESIGNED_URL,
    res => {
      window.location.href = res.data.presigned_url;
    },
    () => {
      SwalDialog('error', 'An error occurred while downloading the file', 'Error', 'center');
    },
  );

  const handleAdd = () => {
    if (newKey && newValue) {
      const updatedObject = { [newKey]: newValue };
      const newVal = [...val, updatedObject];
      setVal(newVal);
      setNewKey('');
      setNewValue('');
      setAttributeValue(JSON.stringify(newVal));
    }
  };

  return (
    <Table className="mt-1">
      <tbody>
        {val?.map((obj, index) => (
          <tr key={index}>
            {Object.entries(obj).map(([key, value]) => (
              <>
                <td>{key}</td>
                <td className={css(styles.urlCell)}>{value}</td>
                <td>
                  <FontAwesomeIcon
                    icon={faDownload}
                    className={classNames(pointerOnHover, 'pl-1 ml-1')}
                    onClick={() => getPresignedUrl({ file_url: value })}
                  />
                </td>
                <td>
                  {isEditView && (
                    <div className="d-flex align-items-center">
                      <Button
                        size="sm"
                        className="ml-1 py-0 px-1"
                        variant="outline-primary"
                        onClick={() => {
                          const temp = val.filter((_, i) => i !== index);
                          setVal(temp);
                          setAttributeValue(JSON.stringify(temp));
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                      {index === val.length - 1 && (
                        <Button
                          size="sm"
                          className="py-0 px-1 ml-1"
                          onClick={() => setShowAddForm(true)}
                          disabled={showAddForm}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      )}
                    </div>
                  )}
                </td>
              </>
            ))}
          </tr>
        ))}
        {showAddForm && (
          <tr>
            <td>
              <Form.Control
                size="sm"
                placeholder="Key"
                value={newKey}
                onChange={e => setNewKey(e.target.value)}
              />
            </td>
            <td>
              <Form.Control
                size="sm"
                placeholder="Value"
                value={newValue}
                onChange={e => setNewValue(e.target.value)}
              />
            </td>
            <td className="pt-3 px-0">
              <Button size="sm" className="py-0 px-0" onClick={handleAdd}>
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                className="py-0 px-1 ml-1"
                onClick={() => {
                  setShowAddForm(false);
                  setNewKey('');
                  setNewValue('');
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

const styles = StyleSheet.create({
  urlCell: {
    width: '60%',
    overflowX: 'auto',
  },
});

DocumentsInputTypeView.propTypes = {
  strJsonObject: PropTypes.object.isRequired,
  setAttributeValue: PropTypes.func.isRequired,
  isEditView: PropTypes.bool,
};

export default DocumentsInputTypeView;
