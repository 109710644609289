import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Button, Col, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { areYouSure } from 'lib/utils/toast';
import { CHANGE_LOG_TYPE } from 'lib/enums/ChangeLogEnums';
import {
  CHANGE_LOGS_LATEST_RECORD,
  SKU_MAPPINGS_DELETE_PRODUCTS,
  SKU_MAPPINGS_UNVERIFY_LINKS,
  SKU_MAPPINGS_VERIFY_LINKS,
} from 'lib/networking/endpoints';
import CreateManufacturerProductsModal from 'components/global_products/detail_view/CreateManufacturerProductsModal';
import DistributorRow from 'components/global_products/detail_view/DistributorRow';
import ManufacturerRow from 'components/global_products/detail_view/ManufacturerRow';
import ProductUnlinkButton from 'components/global_products/detail_view/ProductUnlinkButton';
import { ProductVerificationStatus } from 'lib/enums/ProductVerificationStatus';
import RadioButton from 'components/shared/RadioButton';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const DEFAULT_SELECTED_IDS = {
  manufacturerProducts: [],
  vendorProducts: [],
  vendorProductObjects: [],
};

function GlobalProductsRelationshipsView({ globalProduct, refetch }) {
  const [selectedIds, setSelectedIds] = useState(DEFAULT_SELECTED_IDS);
  const [showCreateManufacturerModal, setShowCreateManufacturerModal] = useState(false);
  const [selectAllDistributorProducts, setSelectAllDistributorProducts] = useState(false);
  const [selectAllManufacturerProducts, setSelectAllManufacturerProducts] = useState(false);

  const { data: { data: linkTypeChangeLog = [] } = [], loading: skuChangeLogLoading } = useGet(
    CHANGE_LOGS_LATEST_RECORD,
    {
      vendor_product_ids: globalProduct?.global_product_vendors?.map(vendor => vendor.id),
      manufacturer_product_ids: globalProduct?.global_product_manufacturers?.map(mfr => mfr.id),
      change_log_type: CHANGE_LOG_TYPE.PRODUCT_LINK_UPDATED,
    },
    !globalProduct,
  );

  const { postData: verifyLinks, loading: linksVerifying } = usePost(
    SKU_MAPPINGS_VERIFY_LINKS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully verified',
        title: 'Success',
      });
      setSelectedIds(DEFAULT_SELECTED_IDS);
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: deleteProducts, loading: deletingProducts } = usePost(
    SKU_MAPPINGS_DELETE_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully deleted products',
        title: 'Success',
      });
      setSelectedIds(DEFAULT_SELECTED_IDS);
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  const { postData: unverifyLinks, loading: linksUnverifying } = usePost(
    SKU_MAPPINGS_UNVERIFY_LINKS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully unverified',
        title: 'Success',
      });
      setSelectedIds(DEFAULT_SELECTED_IDS);
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );

  if (!globalProduct) {
    return null;
  }

  const manufacturerProducts =
    globalProduct.global_product_manufacturers?.filter(product => !product.archived) || [];

  const distributorProducts =
    globalProduct.global_product_vendors?.filter(
      product => !product.is_placeholder_product && !product.archived,
    ) || [];
  const placeHolderProducts =
    globalProduct.global_product_vendors?.filter(
      product => product.is_placeholder_product && !product.archived,
    ) || [];

  const isMultiSelected =
    selectedIds.manufacturerProducts.length > 0 || selectedIds.vendorProducts.length > 0;

  const selectDistributorProducts = () => {
    if (selectAllDistributorProducts) {
      setSelectAllDistributorProducts(false);
      setSelectedIds(prev => ({
        ...prev,
        vendorProducts: [],
        vendorProductObjects: [],
      }));
    } else {
      setSelectAllDistributorProducts(true);
      const distributorProductIDs = distributorProducts.map(product => product.id);
      setSelectedIds(prev => ({
        ...prev,
        vendorProducts: distributorProductIDs,
        vendorProductObjects: distributorProducts,
      }));
    }
  };

  const selectManufacturerProducts = () => {
    if (selectAllManufacturerProducts) {
      setSelectAllManufacturerProducts(false);
      setSelectedIds(prev => ({
        ...prev,
        manufacturerProducts: [],
      }));
    } else {
      setSelectAllManufacturerProducts(true);
      const manufacturerProductIDs = globalProduct.global_product_manufacturers.map(
        product => product.id,
      );
      setSelectedIds(prev => ({
        ...prev,
        manufacturerProducts: manufacturerProductIDs,
      }));
    }
  };

  return (
    <div className="my-4">
      <Row className="mb-1">
        <Col>
          <div className={css(styles.heading)}>Manufacturer</div>
        </Col>
        <Col xs="auto">
          <Button
            className="mr-2"
            disabled={!isMultiSelected || linksVerifying}
            onClick={() =>
              verifyLinks({
                global_product_id: globalProduct.id,
                manufacturer_product_ids: selectedIds.manufacturerProducts,
                vendor_product_ids: selectedIds.vendorProducts,
              })
            }
          >
            Verify Links
          </Button>
          <Button
            className="mr-2"
            disabled={!isMultiSelected || linksUnverifying}
            onClick={() =>
              unverifyLinks({
                global_product_id: globalProduct.id,
                manufacturer_product_ids: selectedIds.manufacturerProducts,
                vendor_product_ids: selectedIds.vendorProducts,
              })
            }
          >
            Unverify Links
          </Button>
          <ProductUnlinkButton
            globalProductId={globalProduct.id}
            manufacturerProductIds={
              isMultiSelected
                ? selectedIds.manufacturerProducts
                : globalProduct.global_product_manufacturers?.map(mfr => mfr.id)
            }
            vendorProductIds={
              isMultiSelected
                ? selectedIds.vendorProducts
                : globalProduct.global_product_vendors?.map(vendor => vendor.id)
            }
            verificationStatus={ProductVerificationStatus.UNVERIFIED}
            buttonText={isMultiSelected ? 'Unlink selected products' : 'Unlink All'}
            refetch={refetch}
            disabled={!isMultiSelected}
          />
          <Button
            className="mr-2"
            variant="danger"
            disabled={!isMultiSelected || deletingProducts}
            onClick={() =>
              areYouSure(
                () =>
                  deleteProducts({
                    global_product_id: globalProduct.id,
                    manufacturer_product_ids: isMultiSelected
                      ? selectedIds.manufacturerProducts
                      : globalProduct.global_product_manufacturers?.map(mfr => mfr.id),
                    vendor_product_ids: isMultiSelected
                      ? selectedIds.vendorProducts
                      : globalProduct.global_product_vendors?.map(vendor => vendor.id),
                  }),
                'Are you sure you want to delete the selected products?',
              )
            }
          >
            Delete Products
          </Button>
        </Col>
      </Row>
      {manufacturerProducts?.length ? (
        <Table hover className="mt-3">
          <thead>
            <tr>
              <th>
                <RadioButton
                  box
                  className="align-middle"
                  small={true}
                  checked={selectAllManufacturerProducts}
                  onClick={() => selectManufacturerProducts()}
                />
              </th>
              <th>Manufacturer</th>
              <th>SKU</th>
              <th>Name</th>
              <th>Created Date</th>
              <th>CD Product Score</th>
              <th>Linkage type</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {manufacturerProducts?.map(manufacturer => (
              <ManufacturerRow
                key={manufacturer.id}
                manufacturer={manufacturer}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                linkTypeChangeLogLoading={skuChangeLogLoading}
                linkTypeChangeLog={linkTypeChangeLog?.filter(
                  record => record.global_product_manufacturer_id === manufacturer.id,
                )}
                refetch={refetch}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="m-3">
          This product is not linked to a Manufacturer Product yet. Link here
        </div>
      )}
      <Row className="mb-1">
        <Col>
          <div className={css(styles.heading)}>Distributors</div>
        </Col>
        <Col xs="auto">
          <Button
            className="ml-1"
            disabled={!isMultiSelected || selectedIds.manufacturerProducts.length > 0}
            onClick={() => setShowCreateManufacturerModal(true)}
          >
            Create Manufacturer Products
          </Button>
        </Col>
      </Row>
      {distributorProducts ? (
        <Table hover className="mt-3">
          <thead>
            <tr>
              <th>
                <RadioButton
                  box
                  className="align-middle"
                  small={true}
                  checked={selectAllDistributorProducts}
                  onClick={() => selectDistributorProducts()}
                />
              </th>
              <th>Distributor</th>
              <th>SKU</th>
              <th>Name</th>
              <th>Created Date</th>
              <th>CD Product Score</th>
              <th>Linkage type</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {distributorProducts.map(distributor => (
              <DistributorRow
                key={distributor.id}
                distributor={distributor}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                linkTypeChangeLogLoading={skuChangeLogLoading}
                linkTypeChangeLog={linkTypeChangeLog?.filter(
                  record => record.global_product_vendor_id === distributor.id,
                )}
                refetch={refetch}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="m-3">
          This product is not linked to a Distributor Product yet. Link here
        </div>
      )}

      {placeHolderProducts.length > 0 && (
        <>
          <div className={css(styles.heading)}>Placeholder Products</div>
          <Table hover className="mt-3">
            <thead>
              <tr>
                <th />
                <th>Distributor</th>
                <th>SKU</th>
                <th>Name</th>
                <th>Created Date</th>
                <th>CD Product Score</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {placeHolderProducts.map(distributor => (
                <DistributorRow
                  key={distributor.id}
                  distributor={distributor}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  refetch={refetch}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}
      {showCreateManufacturerModal && (
        <CreateManufacturerProductsModal
          selectedProductsList={selectedIds.vendorProductObjects}
          onHide={() => {
            setShowCreateManufacturerModal(false);
            setSelectedIds(DEFAULT_SELECTED_IDS);
          }}
          refetch={refetch}
        />
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
});

GlobalProductsRelationshipsView.propTypes = {
  globalProduct: PropTypes.object,
  refetch: PropTypes.func,
};

export default GlobalProductsRelationshipsView;
