import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DropDownMenu from 'components/manage_attribute/AttributeView/DropDownMenu';
import EditAttributeModal from 'components/manage_attribute/EditAttributeModal';
import { GLOBAL_ATTRIBUTE, GLOBAL_ATTRIBUTE_DETAILS } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import useDelete from 'lib/hooks/useDelete';
import usePost from 'lib/hooks/usePost';

function GlobalAttributeRows({ globalAttributes = [], refetch }) {
  const [showEditAttributeModal, setShowEditAttributeModal] = useState(false);
  const [globalAttributeToEdit, setGlobalAttributeToEdit] = useState({});

  const { deleteData: deleteGlobalAttribute } = useDelete(
    GLOBAL_ATTRIBUTE_DETAILS,
    false,
    () => refetch(),
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  const { postData: createOrUpdateGlobalAttribute } = usePost(
    GLOBAL_ATTRIBUTE,
    () => {
      refetch();
      SwalDialog('success', 'Attribute updated successfully', 'Success', 'center');
      setShowEditAttributeModal(false);
    },
    () => SwalDialog('error', 'An error occurred', 'Error', 'center'),
  );

  const updateGlobalAttribute = data => {
    createOrUpdateGlobalAttribute({
      global_attribute_name_updated: true,
      global_attribute_id: globalAttributeToEdit.id,
      global_attribute_name: data.attributeName,
      attribute_display_name: data.attributeDisplayName,
      attribute_sub_section_id: data.attributeSubSectionId.value,
      attribute_sub_section_name: data.attributeSubSectionId.label,
      attribute_section_id: data.attributeSectionId.value,
      attribute_section_name: data.attributeSectionId.label,
      mandatory: data.isMandatory,
      visibility: data.isVisible,
      key_attribute: data.keyAttribute,
      input_type: data.inputType,
      priority_level: data.priorityLevel,
      is_indexed: data.isIndexed,
    });
  };

  return (
    <>
      {globalAttributes.map(attr => (
        <tr key={attr.id}>
          <td>
            <DropDownMenu
              attributeName={attr.global_attribute_name}
              deleteAttribute={() => deleteGlobalAttribute({ global_attribute_id: attr.id })}
              editAttribute={() => {
                setGlobalAttributeToEdit(attr);
                setShowEditAttributeModal(true);
              }}
            />
          </td>
          <td>{attr.input_type}</td>
          <td>{attr.attribute_sub_section.attribute_section.attribute_section_name}</td>
          <td>{attr.attribute_sub_section.attribute_sub_section_name}</td>
          <td>Global</td>
        </tr>
      ))}
      {showEditAttributeModal ? (
        <EditAttributeModal
          onHide={() => setShowEditAttributeModal(false)}
          modalHeader="Edit Global Attribute"
          updateAttribute={updateGlobalAttribute}
          details={{
            attributeName: globalAttributeToEdit.global_attribute_name,
            attributeDisplayName: globalAttributeToEdit.attribute_display_name,
            isMandatory: globalAttributeToEdit.mandatory,
            isVisible: globalAttributeToEdit.visibility,
            isAKeyAttribute: globalAttributeToEdit.key_attribute,
            inputType: globalAttributeToEdit.input_type,
            priorityLevel: globalAttributeToEdit.priority_level,
            sectionId: globalAttributeToEdit.attribute_sub_section.attribute_section.id,
            sectionName:
              globalAttributeToEdit.attribute_sub_section.attribute_section.attribute_section_name,
            subSectionId: globalAttributeToEdit.attribute_sub_section.id,
            subSectionName: globalAttributeToEdit.attribute_sub_section.attribute_sub_section_name,
          }}
        />
      ) : null}
    </>
  );
}

GlobalAttributeRows.propTypes = {
  globalAttributes: PropTypes.array,
  refetch: PropTypes.func,
};

export default GlobalAttributeRows;
