import React, { useState } from 'react';

import { Button, Col, Row, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwalDialog from 'lib/utils/toast';
import { uniqBy } from 'lodash';

import { ADD_DISPLAY_VALUE, GET_DISPLAY_VALUE_ENABLED_RECORDS } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import DisplayValueTableRow from 'components/manage_display_values/DisplayValueTableRow';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';
import { useMemo } from 'react';
import usePost from 'lib/hooks/usePost';

function ManageDisplayValueView() {
  const [updatedRecords, setUpdatedRecords] = useState([]);

  const { data: { attribute_values = [] } = {}, loading } = useGet(
    GET_DISPLAY_VALUE_ENABLED_RECORDS,
  );

  const uniqueAttributeValues = useMemo(() => {
    return uniqBy(attribute_values, 'attribute_value');
  }, [attribute_values]);

  const { postData: updateRecords, loading: viewCreating } = usePost(
    ADD_DISPLAY_VALUE,
    () => {
      SwalDialog('success', 'Updated successfully', 'Success', 'center');
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred while updating: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  return (
    <Container>
      <Row>
        <Col sm={11}>
          <h3 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
            />
            Manage Display Values
          </h3>
        </Col>
        <Col sm={1}>
          <Button
            className="float-right"
            onClick={() => updateRecords({ attribute_values: updatedRecords })}
            block
          >
            Save
          </Button>
        </Col>
      </Row>
      <div className="my-3">
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <Table hover striped>
            <thead>
              <tr>
                <th className={css(styles.otherColumn)}>Attribute Value</th>
                <th className={css(styles.middleColumn)}></th>
                <th className={css(styles.otherColumn)}>Display Value</th>
              </tr>
            </thead>
            <tbody>
              {uniqueAttributeValues.map((valueRecord, index) => (
                <DisplayValueTableRow
                  key={index}
                  valueRecord={valueRecord}
                  updatedRecords={updatedRecords}
                  setUpdatedRecords={setUpdatedRecords}
                />
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </Container>
  );
}

const styles = StyleSheet.create({
  middleColumn: {
    maxWidth: 100,
    minWidth: 100,
  },
  otherColumn: {
    minWidth: 300,
    maxWidth: 300,
  },
});

export default ManageDisplayValueView;
