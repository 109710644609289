import React from 'react';

import { Redirect, useLocation } from 'react-router-dom';

function RedirectToLogin() {
  const location = useLocation();
  const urlParams = new global.URLSearchParams({
    redirect_on_login: location.pathname + location.search,
  });
  return <Redirect to={'/login?' + urlParams.toString()} />;
}

export default RedirectToLogin;
