import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import AISuggestedClassificationProductTableCell from 'components/ai_suggested_classification_product/AISuggestedClassificationProductTableCell';
import { backgroundLightBlue, white } from 'lib/css/colors';
import CascadeCategoryDropdown from 'components/shared/CascadeCategoryDropdown';
import {
  CATEGORY_LEVELS,
  AUTO_PRODUCT_CLASSIFICATION_TABLE_ID,
  SORTABLE_AUTOMATIC_CLASSIFIER_COLUMNS,
} from 'lib/constants';
import {
  DefaultAISuggestedClassifierView,
  AISuggestedClassifierProductContext,
} from 'components/ai_suggested_classification_product/AISuggestedClassificationProductView';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { SELECT_ALL_ON_PAGE } from 'lib/enums/ProductStatus';
import TableHeader from 'components/shared/TableHeader';
import ResizableTable from 'components/shared/ResizableTable';
import useTaxonomyOptions from 'lib/hooks/useTaxonomyOptions';

function AISuggestedClassificationProductTable({ setSelectedIDsWithShift }) {
  const {
    products,
    productsLoading,
    classifyingProducts,
    rejectingPredictions,
    onColumnSearch,
    searchFilters,
    selectedIDs,
    sortConfig,
    setSortConfig,
  } = useContext(AISuggestedClassifierProductContext);

  const columns = DefaultAISuggestedClassifierView.columns.map(c => c.name);

  const { options: { l1Options, l2Options, l3Options, l4Options } = {} } = useTaxonomyOptions(
    undefined,
    searchFilters[CATEGORY_LEVELS.L1_CATEGORY],
    searchFilters[CATEGORY_LEVELS.L2_CATEGORY],
    searchFilters[CATEGORY_LEVELS.L3_CATEGORY],
    searchFilters[CATEGORY_LEVELS.L4_CATEGORY],
    true,
  );

  const optionMap = {
    [CATEGORY_LEVELS.L1_CATEGORY]: l1Options,
    [CATEGORY_LEVELS.L2_CATEGORY]: l2Options,
    [CATEGORY_LEVELS.L3_CATEGORY]: l3Options,
    [CATEGORY_LEVELS.L4_CATEGORY]: l4Options,
  };

  if (productsLoading || classifyingProducts || rejectingPredictions) {
    return <LoadingSpinner />;
  }

  return (
    <ResizableTable
      resizable={true}
      resizeOptions={{
        resizeMode: 'overflow',
        liveDrag: true,
        draggingClass: 'drag',
        minWidth: 20,
        serialize: true,
        partialRefresh: true,
      }}
      className={css(styles.productsTable)}
      tableID={AUTO_PRODUCT_CLASSIFICATION_TABLE_ID}
      columns={columns}
    >
      <thead className={css(styles.thead)}>
        <tr>
          <th className="px-1 py-1">
            <Form.Check
              checked={selectedIDs.length === products?.length}
              type="checkbox"
              onChange={event => {
                const ids = products?.map(
                  product =>
                    `${product.acp_id}-${product.product_type}-${product.id}-` +
                    `${product.global_product_id}-${product.l4_category_id}`,
                );
                setSelectedIDsWithShift(event, ids, SELECT_ALL_ON_PAGE);
              }}
            />
          </th>
          {columns?.map(column =>
            column.includes('category') && !column.includes('vendor') ? (
              <CascadeCategoryDropdown
                key={column}
                title={column}
                options={optionMap[column]}
                searchTerm={searchFilters[column]}
                setSearchTerm={(searchText, searchId) =>
                  onColumnSearch(column, searchText, searchId)
                }
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            ) : (
              <TableHeader
                title={column}
                key={column}
                onSearch={searchText => onColumnSearch(column, searchText)}
                value={searchFilters[column] || ''}
                sortable={SORTABLE_AUTOMATIC_CLASSIFIER_COLUMNS.includes(column)}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            ),
          )}
        </tr>
      </thead>
      <tbody className={css(styles.tbody)}>
        {products?.length ? (
          products?.map(product => (
            <tr
              key={`${product.product_type}-${product.id}`}
              className={
                selectedIDs.includes(
                  `${product.acp_id}-${product.product_type}-${product.id}-` +
                    `${product.global_product_id}-${product.l4_category_id}`,
                )
                  ? css(styles.highlighted)
                  : null
              }
            >
              <td className="px-1 py-0">
                <Form.Check
                  type="checkbox"
                  checked={selectedIDs.includes(
                    `${product.acp_id}-${product.product_type}-${product.id}-` +
                      `${product.global_product_id}-${product.l4_category_id}`,
                  )}
                  onChange={event =>
                    setSelectedIDsWithShift(
                      event,
                      `${product.acp_id}-${product.product_type}-${product.id}-` +
                        `${product.global_product_id}-${product.l4_category_id}`,
                    )
                  }
                  className="py-2"
                />
              </td>
              {columns?.map(col => {
                if (col === 'name') {
                  return (
                    <td key={col} className="p-1">
                      <Link
                        to={
                          `/global-product-detail/${product.global_product_id}/` +
                          `${product.id}/${product.product_type}`
                        }
                        className={css(styles.productName)}
                      >
                        {product.name}
                      </Link>
                    </td>
                  );
                } else {
                  return (
                    <td className="p-2" key={col}>
                      <AISuggestedClassificationProductTableCell product={product} column={col} />
                    </td>
                  );
                }
              })}
            </tr>
          ))
        ) : (
          <>
            <tr>
              {columns?.map(col => (
                <td key={col} />
              ))}
              <td />
            </tr>
          </>
        )}
      </tbody>
    </ResizableTable>
  );
}

const styles = StyleSheet.create({
  productsTable: {
    fontSize: '0.65rem',
  },
  productName: {
    textDecoration: 'none',
    color: 'inherit',
    maxWidth: '200px',
  },
  thead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: white,
    minWidth: '15px',
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
  highlighted: {
    backgroundColor: backgroundLightBlue,
  },
});

AISuggestedClassificationProductTable.propTypes = {
  setSelectedIDsWithShift: PropTypes.func,
};

export default AISuggestedClassificationProductTable;
