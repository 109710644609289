import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Spinner, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Select from 'react-select/creatable';

import { GLOBAL_MANUFACTURERS_MERGE } from 'lib/networking/endpoints';
import { ManufacturerVerificationStatus } from 'lib/enums/ManufacturerVerificationStatus';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function MergeManufacturersModal({ mergeList, setMergeList, onHide }) {
  const [filteredManufacturers, setFilteredManufacturers] = useState(mergeList);
  const [parentManufacturer, setParentManufacturer] = useState(null);

  useEffect(() => {
    //If there is only one verified manufacturer in mergeList it will be
    //set as the parent manufacturer.
    const verifiedManufacturer = mergeList.find(
      manufacturer => manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED,
    );
    const unverifiedManufacturers = mergeList.filter(
      manufacturer => manufacturer.verification_status !== ManufacturerVerificationStatus.VERIFIED,
    );

    if (
      verifiedManufacturer &&
      mergeList.filter(
        manufacturer =>
          manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED,
      ).length === 1
    ) {
      setFilteredManufacturers(unverifiedManufacturers);
      setParentManufacturer(verifiedManufacturer);
    }
  }, [mergeList]);

  const { postData: mergeManufacturers, loading } = usePost(
    GLOBAL_MANUFACTURERS_MERGE,
    () => {
      SwalDialog('success', 'Started merging manufacturers', 'Success', 'center');
      onHide();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while merging manufacturers: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const options = mergeList.map((manufacturer, index) => ({
    label: manufacturer.name,
    value: index,
  }));

  const handleMergeManufacturer = () => {
    const hasVerifiedManufacturer = filteredManufacturers.some(
      manufacturer => manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED,
    );

    if (!parentManufacturer?.verification_status === ManufacturerVerificationStatus.VERIFIED) {
      Swal.fire({
        icon: 'error',
        title: 'Parent Manufacturer Not Verified',
        text: 'Parent manufacturer is not verified.',
        confirmButtonText: 'OK',
      });
    } else if (hasVerifiedManufacturer) {
      Swal.fire({
        icon: 'warning',
        title: 'Cannot Merge',
        text: 'At least one verified manufacturer in the secondary manufacturers.',
        confirmButtonText: 'OK',
      });
    } else {
      mergeManufacturers({
        parentId: parentManufacturer.id,
        mergeIds: filteredManufacturers.map(manufacturer => manufacturer.id),
      });
    }
  };

  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Merge Manufacturers</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h3 className="font-weight-bold">Parent Manufacturer</h3>
        <Select
          className="mb-3"
          options={options}
          value={
            parentManufacturer
              ? options.find(option => option.label === parentManufacturer.name)
              : null
          }
          onChange={option => {
            if (option) {
              setParentManufacturer(mergeList[option.value]);
              setFilteredManufacturers(
                mergeList.filter(
                  mergeManufacturer => mergeManufacturer.id !== mergeList[option.value].id,
                ),
              );
            } else {
              setParentManufacturer(null);
            }
          }}
          placeholder="Select the primary manufacturer"
        />
        <h3 className="font-weight-bold">Secondary Manufacturers</h3>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>FSA ID</th>
              <th>Cnd ID</th>
              <th>Verified</th>
              <th>Manufacturer gln</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {filteredManufacturers.map((manufacturer, index) => (
              <tr key={index}>
                <td>{manufacturer.name}</td>
                <td>{manufacturer.id}</td>
                <td>{manufacturer.cnd_id}</td>
                <td>
                  {manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED
                    ? 'Yes'
                    : 'No'}
                </td>
                <td>{manufacturer.manufacturer_gln}</td>
                <td>
                  <Button
                    variant="link"
                    onClick={() => {
                      setFilteredManufacturers(
                        filteredManufacturers.filter(
                          mergeManufacturer => mergeManufacturer.id !== manufacturer.id,
                        ),
                      );
                      setMergeList(
                        mergeList.filter(
                          mergeManufacturer => mergeManufacturer.id !== manufacturer.id,
                        ),
                      );
                    }}
                  >
                    X
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Row className="pt-3 mt-3 d-flex justify-content-between">
          <Col sm={3}>
            <Button
              disabled={filteredManufacturers?.length < 1 || loading || !parentManufacturer}
              block
              variant="primary"
              onClick={handleMergeManufacturer}
            >
              {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Merge'}
            </Button>
          </Col>
          <Col sm={2}>
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

MergeManufacturersModal.propTypes = {
  mergeList: PropTypes.array,
  setMergeList: PropTypes.func,
  onHide: PropTypes.func,
};

export default MergeManufacturersModal;
