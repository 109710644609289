import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { anchovy, lightGreyNew } from 'lib/css/colors';

const ProductImagePlaceholder = ({ imageWidth = 200, iconWidth = 70, textFontSize = 10 }) => {
  const defaultStyle = styles({});
  return (
    <div
      className={classNames(
        'd-flex align-items-center justify-content-center',
        css(styles({ imageWidth }).imageBackground),
      )}
    >
      <div className="d-flex flex-column">
        <div className={css(defaultStyle.svgStyle)}>
          <svg viewBox="0 0 256 256" width={iconWidth} height={iconWidth}>
            <path
              // eslint-disable-next-line max-len
              d="M179.68,53.68l-11.66-22.67H86.78L75.11,53.68h-19.1v-12.7h-34v12.7H3.8V219H251V53.68H179.68z M240.02,208.02H14.79V64.66 h67.03L93.49,42h67.83l11.66,22.67H240v143.35H240.02z M127.4,79.17c-30.66,0-55.6,24.94-55.6,55.6c0,30.65,24.94,55.59,55.6,55.59 c30.65,0,55.59-24.94,55.59-55.59C183,104.11,158.05,79.17,127.4,79.17z M127.4,179.38c-24.6,0-44.61-20.01-44.61-44.6 c0-24.6,20.01-44.61,44.61-44.61s44.6,20.01,44.6,44.61C172,159.37,152,179.38,127.4,179.38z M127.4,100.89 c-18.68,0-33.88,15.19-33.88,33.88c0,18.67,15.19,33.88,33.88,33.88c18.67,0,33.88-15.21,33.88-33.88 C161.28,116.09,146.08,100.89,127.4,100.89z M127.4,157.66c-12.62,0-22.89-10.26-22.89-22.89c0-12.62,10.28-22.89,22.89-22.89 c12.63,0,22.89,10.28,22.89,22.89C150.29,147.4,140.03,157.66,127.4,157.66z M190.98,79.08h34v16.56h-34V79.08z"
            />
          </svg>
        </div>
        <div className={classNames(css(styles({ textFontSize }).text))}>No Images Available</div>
      </div>
    </div>
  );
};

const styles = ({ imageWidth = 200, textFontSize = 10 }) =>
  StyleSheet.create({
    imageBackground: {
      backgroundColor: lightGreyNew,
      height: imageWidth,
      width: imageWidth,
    },
    text: {
      textAlign: 'center',
      fontSize: textFontSize,
      color: anchovy,
    },
    svgStyle: {
      textAlign: 'center',
    },
  });

ProductImagePlaceholder.propTypes = {
  imageWidth: PropTypes.number,
  iconWidth: PropTypes.number,
  textFontSize: PropTypes.number,
};

export default ProductImagePlaceholder;
