import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import CategoryFilter from 'components/l4_default_images/filters/CategoryFilter';

function CategoryFilters({
  l1Search,
  l2Search,
  l3Search,
  setL1Search,
  setL2Search,
  setL3Search,
  selectedL4CategoryName,
  setSelectedL4CategoryName,
  setSelectedL4CategoryId,
  l1Options,
  l2Options,
  l3Options,
  l4Options,
  loading,
}) {
  return (
    <div className={css(styles.container)}>
      <div className="my-3">
        <CategoryFilter
          categoryOptions={l1Options}
          selectedCategoryName={l1Search}
          onChange={(option, triggeredAction) => {
            if (triggeredAction.action === 'clear' || triggeredAction.action === 'select-option') {
              setL2Search(undefined);
              setL3Search(undefined);
              setSelectedL4CategoryName(undefined);
              setSelectedL4CategoryId(null);
            }
            setL1Search(option?.label);
          }}
          formTitle={'Select L1 Category'}
          placeholder={'Select a L1 Category'}
          loading={loading}
        />
      </div>
      <div className="my-3">
        <CategoryFilter
          categoryOptions={l2Options}
          selectedCategoryName={l2Search}
          onChange={(option, triggeredAction) => {
            if (triggeredAction.action === 'clear' || triggeredAction.action === 'select-option') {
              setL3Search(undefined);
              setSelectedL4CategoryName(undefined);
              setSelectedL4CategoryId(null);
            }
            setL2Search(option?.label);
          }}
          formTitle={'Select L2 Category'}
          placeholder={'Select a L2 Category'}
          loading={loading}
        />
      </div>
      <div className="my-3">
        <CategoryFilter
          categoryOptions={l3Options}
          selectedCategoryName={l3Search}
          onChange={(option, triggeredAction) => {
            if (triggeredAction.action === 'clear' || triggeredAction.action === 'select-option') {
              setSelectedL4CategoryName(undefined);
              setSelectedL4CategoryId(null);
            }
            setL3Search(option?.label);
          }}
          formTitle={'Select L3 Category'}
          placeholder={'Select a L3 Category'}
          loading={loading}
        />
      </div>
      <div className="my-3">
        <CategoryFilter
          categoryOptions={l4Options}
          selectedCategoryName={selectedL4CategoryName}
          onChange={(option, triggeredAction) => {
            setSelectedL4CategoryId(option?.id);
            setSelectedL4CategoryName(option?.label);
          }}
          formTitle={'Select L4 Category'}
          placeholder={'Select a L4 Category'}
          loading={loading}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '85vh',
  },
  countText: {
    fontSize: '0.9rem',
  },
});

CategoryFilters.propTypes = {
  l1Search: PropTypes.string,
  l2Search: PropTypes.string,
  l3Search: PropTypes.string,
  setL1Search: PropTypes.func,
  setL2Search: PropTypes.func,
  setL3Search: PropTypes.func,
  selectedL4CategoryName: PropTypes.string,
  setSelectedL4CategoryName: PropTypes.func,
  setSelectedL4CategoryId: PropTypes.func,
  l1Options: PropTypes.array,
  l2Options: PropTypes.array,
  l3Options: PropTypes.array,
  l4Options: PropTypes.array,
  loading: PropTypes.bool,
};

export default CategoryFilters;
