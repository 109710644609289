import React from 'react';

import { Button, Col, Row, Table } from 'react-bootstrap';

import Container from 'components/shared/Container';
import { ExportStatus } from 'lib/enums/ExportStatus';
import { EXPORT_TYPE_LABELS } from 'lib/enums/ExportType';
import { FILE_EXPORTS } from 'lib/networking/endpoints';
import { formatFileSize } from 'lib/utils/fileSizeUtils';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function FileExportsView() {
  const { data: { data: files = [] } = {}, refetch: refreshList, loading } = useGet(FILE_EXPORTS);
  return (
    <Container>
      <Row className="pb-3">
        <Col>
          <h3 className="font-weight-bold">File Exports</h3>
        </Col>
        <Col align="right">
          <Button onClick={() => refreshList()}>Refresh</Button>
        </Col>
      </Row>
      {loading ? (
        <LoadingSpinner short />
      ) : (
        <Table bordered striped hover>
          <thead>
            <tr>
              <th>File</th>
              <th>Type</th>
              <th>Size</th>
              <th>Last Modified</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {files.length ? (
              files.map(file => {
                return (
                  <tr key={file.file_name}>
                    <td className="py-3">{file.file_name}</td>
                    <td className="py-3">{EXPORT_TYPE_LABELS[file.export_type]}</td>
                    <td className="py-3">
                      {file.size !== null ? formatFileSize(file.size) : 'unavailable'}
                    </td>
                    <td className="py-3">{file.last_modified}</td>
                    <td className="py-3">{file.status}</td>
                    <td align="right">
                      {file.status === ExportStatus.PROCESSING ? null : (
                        <Button
                          variant="outline-primary"
                          onClick={() => (window.location.href = file.csv_url)}
                          disabled={!file.csv_url}
                        >
                          {file.csv_url ? 'Download' : 'Not Available'}
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan="100%">
                  No product exports found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Container>
  );
}

export default FileExportsView;
