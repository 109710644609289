import React, { useState } from 'react';

import { Button } from 'react-bootstrap';

import CDExportType from 'lib/enums/CDExportType';
import Container from 'components/shared/Container';
import {
  ADD_TAXONOMY_FRIENDLY_NAMES,
  CLEAN_INVALID_PRODUCTS,
  DELETE_ORPHANED_GLOBAL_PRODUCTS,
  EXPORT_ATTRIBUTES,
  EXPORT_DATA_CUT_DRY,
  EXPORT_HILLCREST_ERRORS,
  EXPORT_INCORRECTLY_LINKED_PRODUCTS_LIST,
  EXPORT_PRODUCTS_CD,
  FIX_INVALID_GLOBAL_PRODUCT_NAMES,
  FIX_MANUFACTURER_DATA,
  FIX_VENDOR_DATA,
  GLOBAL_PRODUCTS_MARK_SPECIALLY_UPLOADED,
  GTIN_VALIDATOR_CSV,
  HILLCREST_PRODUCT_RESTORE,
  IMPORT_ATTRIBUTES,
  IMPORT_ATTRIBUTE_VALUES,
  SEPARATE_HILLCREST_PRODUCTS,
  UPDATE_SYSCO_STATUSES,
} from 'lib/networking/endpoints';
import FileUploadModal from 'components/shared/FileUploadModal';
import FsaSkuMappingsImportModal from 'components/sync_data/modals/FsaSkuMappingsImportModal';
import Swal from 'sweetalert2';
import SwalDialog, { areYouSure } from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import { useSwalInput } from 'lib/hooks/useSwalInput';

// temporary page to manually sync data
function SyncDataView() {
  const [showFsaSkuMappingModal, setShowFsaSkuMappingModal] = useState(false);
  const [showAttributeUpsertModal, setShowAttributeUpsertModal] = useState(false);
  const [showAttributeValueUpsertModal, setShowAttributeValueUpsertModal] = useState(false);
  const [showGtinValidatorCsvUploadModal, setShowGtinValidatorCsvUploadModal] = useState(false);
  const swalInput = useSwalInput();
  const handleDeleteProducts = () => {
    swalInput('Delete Products From Global Product', 'Input Global Product Id');
  };

  const { postData: exportProducts, loading: exporting } = usePost(
    EXPORT_PRODUCTS_CD,
    () => {
      SwalDialog('success', 'Products exported successfully', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during product export', 'Error', 'center');
    },
  );

  const { postData: markSpeciallyUploaded, loading: markingSpeciallyUploaded } = usePost(
    GLOBAL_PRODUCTS_MARK_SPECIALLY_UPLOADED,
    () => {
      SwalDialog('success', '', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred', 'Error', 'center');
    },
  );

  const { postData: updateSyscoStatuses, loading: updatingStatuses } = usePost(
    UPDATE_SYSCO_STATUSES,
    () => {
      SwalDialog('success', 'Sysco product status update started', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred', 'Error', 'center');
    },
  );

  const { postData: exportTaxonomy, loading: taxonomyExporting } = usePost(
    EXPORT_DATA_CUT_DRY,
    () => {
      SwalDialog('success', 'Taxonomy export successfully started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred', 'Error', 'center');
    },
  );

  const { postData: exportCategorizedToCutDry, loading: categorizedExporting } = usePost(
    EXPORT_DATA_CUT_DRY,
    () => {
      SwalDialog('success', 'Categorized products export to Cut+Dry started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred', 'Error', 'center');
    },
  );

  const { postData: exportBrands, loading: exportBrandsLoading } = usePost(
    EXPORT_DATA_CUT_DRY,
    () => {
      SwalDialog('success', 'Brands exported successfully', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during brand export', 'Error', 'center');
    },
  );

  const { postData: exportBrandedProducts, loading: exportBrandedProductsLoading } = usePost(
    EXPORT_DATA_CUT_DRY,
    () => {
      SwalDialog('success', 'Products->Brands exported successfully', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during product->brand export', 'Error', 'center');
    },
  );

  const { postData: fixVendorData, loading: vendorDataFixing } = usePost(
    FIX_VENDOR_DATA,
    () => {
      SwalDialog('success', 'Vendor data fix started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during vendor data fix', 'Error', 'center');
    },
  );

  const { postData: fixManufacturerData, loading: manufacturerDataFixing } = usePost(
    FIX_MANUFACTURER_DATA,
    () => {
      SwalDialog('success', 'Manufacturer data fix started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during manufacturer data fix', 'Error', 'center');
    },
  );

  const { postData: cleanInvalidProducts, loading: cleaningInvalidProducts } = usePost(
    CLEAN_INVALID_PRODUCTS,
    () => {
      SwalDialog('success', 'Invalid product cleaning started.', 'Success', 'center');
    },
    () => {
      SwalDialog(
        'error',
        'An error occurred during invalid product code cleaning.',
        'Error',
        'center',
      );
    },
  );

  const { postData: exportHillcrestErrors, loading: hillcrestErrorsExporting } = usePost(
    EXPORT_HILLCREST_ERRORS,
    () => {
      SwalDialog('success', 'Hillcrest error export started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during Hillcrest error export.', 'Error', 'center');
    },
  );

  const { postData: separateHillcrestProducts, loading: separatingHillcrestProducts } = usePost(
    SEPARATE_HILLCREST_PRODUCTS,
    () => {
      SwalDialog(
        'success',
        'Hillcrest product separation from global product started.',
        'Success',
        'center',
      );
    },
    () => {
      SwalDialog(
        'error',
        'An error occurred during Hillcrest product separation.',
        'Error',
        'center',
      );
    },
  );

  const { postData: exportAttributes, loading: attributesExportLoading } = usePost(
    EXPORT_ATTRIBUTES,
    () => {
      SwalDialog('success', 'export started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during the export.', 'Error', 'center');
    },
  );

  const { postData: upsertAttributes, upsertingAttributes } = usePost(
    IMPORT_ATTRIBUTES,
    () => {
      SwalDialog('success', 'import started.', 'Success', 'center');
      setShowAttributeUpsertModal(false);
    },
    () => {
      SwalDialog('error', 'An error occurred during the import.', 'Error', 'center');
    },
  );

  const { postData: upsertAttributeValues, upsertingAttributeValues } = usePost(
    IMPORT_ATTRIBUTE_VALUES,
    () => {
      SwalDialog('success', 'import started.', 'Success', 'center');
      setShowAttributeValueUpsertModal(false);
    },
    () => {
      SwalDialog('error', 'An error occurred during the import.', 'Error', 'center');
    },
  );

  const { postData: deleteOrphanedGlobalProducts, loading: deletingGlobalProducts } = usePost(
    DELETE_ORPHANED_GLOBAL_PRODUCTS,
    () => {
      SwalDialog('success', 'Orphaned global product delete started.', 'Success', 'center');
    },
    () => {
      SwalDialog(
        'error',
        'An error occurred during orphaned global product delete.',
        'Error',
        'center',
      );
    },
  );

  const { postData: fixInvalidGlobalProdNames, loading: globalProdNamesFixing } = usePost(
    FIX_INVALID_GLOBAL_PRODUCT_NAMES,
    () => {
      SwalDialog('success', 'Global product name fix started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during global product name fix.', 'Error', 'center');
    },
  );

  const { postData: restoreHillcrestProducts, loading: restoringHillcrestProducts } = usePost(
    HILLCREST_PRODUCT_RESTORE,
    () => {
      SwalDialog('success', 'Hillcrest product restore started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during Hillcrest product restore.', 'Error', 'center');
    },
  );

  const { postData: addTaxonomyFriendlyNames, loading: addingTaxonomyFriendlyNames } = usePost(
    ADD_TAXONOMY_FRIENDLY_NAMES,
    () =>
      Swal.fire({
        icon: 'success',
        text: 'Taxonomy friendly name import started.',
        title: 'Success',
      }),
    () =>
      Swal.fire({
        icon: 'error',
        text: 'Error occurred during taxononmy friendly name import.',
        title: 'Error',
      }),
  );

  const { postData: uploadGTINCsv, loading: uploadingFile } = usePost(
    GTIN_VALIDATOR_CSV,
    () => {
      SwalDialog('success', 'Uploaded File Successfully', 'Success', 'center');
    },
    () => {
      SwalDialog(
        'error',
        'An error occurred during adding the upload of the csv file',
        'Error',
        'center',
      );
    },
  );

  const { postData: exportIncorrectlyLinkedProducts, loading: exportingIncorrectlyLinkedProducts } =
    usePost(
      EXPORT_INCORRECTLY_LINKED_PRODUCTS_LIST,
      () => {
        SwalDialog('success', 'File Export Started Successfully', 'Success', 'center');
      },
      () => {
        SwalDialog(
          'error',
          'An error occurred during adding the exporting the csv file',
          'Error',
          'center',
        );
      },
    );

  const loading =
    exporting ||
    markingSpeciallyUploaded ||
    updatingStatuses ||
    taxonomyExporting ||
    categorizedExporting ||
    exportBrandsLoading ||
    exportBrandedProductsLoading ||
    vendorDataFixing ||
    manufacturerDataFixing ||
    cleaningInvalidProducts ||
    hillcrestErrorsExporting ||
    separatingHillcrestProducts ||
    deletingGlobalProducts ||
    globalProdNamesFixing ||
    restoringHillcrestProducts ||
    addingTaxonomyFriendlyNames ||
    attributesExportLoading ||
    upsertingAttributes ||
    upsertingAttributeValues ||
    uploadingFile ||
    exportingIncorrectlyLinkedProducts;

  return (
    <Container>
      <div className="d-flex align-items-center mb-3">
        <h3 className="font-weight-bold">Sync Data</h3>
        <span className="small text-secondary ml-2">Temporary page to sync data</span>
      </div>
      <div className="my-3">
        <Button onClick={() => exportProducts()} disabled={loading}>
          Export Data to S3
        </Button>
      </div>
      <div className="my-3">
        <Button onClick={() => markSpeciallyUploaded()} disabled={loading}>
          Mark specially uploaded Global Products
        </Button>
        <Button className="ml-2" onClick={() => updateSyscoStatuses()} disabled={loading}>
          Update Sysco Product Statuses
        </Button>
      </div>
      <div className="my-3">
        <Button
          className="ml-0"
          onClick={() => exportTaxonomy({ export_type: CDExportType.TAXONOMY })}
          disabled={loading}
        >
          Export Taxonomy
        </Button>
        <Button
          className="ml-3"
          onClick={() =>
            exportCategorizedToCutDry({
              export_type: CDExportType.CATEGORIES,
              full_export: true,
            })
          }
          disabled={loading}
        >
          Export Categorized Products to Cut+Dry
        </Button>
      </div>
      <div className="my-3">
        <Button onClick={() => exportHillcrestErrors()} disabled={loading}>
          Export Hillcrest Errors
        </Button>
        <Button
          className="ml-3"
          onClick={() =>
            areYouSure(
              () => separateHillcrestProducts(),
              'All Hillcrest products will be separated from the connected Global Products! ' +
                'Are you sure you want to continue?',
            )
          }
          disabled={loading}
        >
          Separate Hillcrest Products
        </Button>
        <Button className="ml-3" onClick={() => addTaxonomyFriendlyNames()} disabled={loading}>
          Import Taxonomy Friendly Names
        </Button>
      </div>
      <div className="my-3">
        <h4>Data Fixers</h4>
        <Button onClick={() => fixVendorData()} disabled={loading}>
          Fix Vendor Data
        </Button>
        <Button className="ml-3" onClick={() => fixManufacturerData()} disabled={loading}>
          Fix Manufacturer Data
        </Button>
        <Button className="ml-3" onClick={() => cleanInvalidProducts()} disabled={loading}>
          Clean Invalid Products
        </Button>
        <Button
          className="ml-3"
          onClick={() =>
            areYouSure(
              () => deleteOrphanedGlobalProducts(),
              'All global products without any connected manufacturer or vendor ' +
                'products will be deleted! Are you sure you want to continue?',
            )
          }
          disabled={loading}
        >
          Delete Orphaned Global Products
        </Button>
        <Button className="ml-3" onClick={() => fixInvalidGlobalProdNames()} disabled={loading}>
          Fix Invalid Global Product Names
        </Button>
        <Button className="ml-3" onClick={() => restoreHillcrestProducts()} disabled={loading}>
          Restore Hillcrest Products
        </Button>
        <Button className="ml-3" onClick={() => handleDeleteProducts()} disabled={loading}>
          Delete All Products from Global Product
        </Button>
      </div>
      <div className="my-3">
        <h4>Brands</h4>
        <Button
          onClick={() => exportBrands({ export_type: CDExportType.BRANDS, full_export: true })}
          disabled={loading}
        >
          Export Brands
        </Button>
        <Button
          className="ml-3"
          onClick={() =>
            exportBrandedProducts({
              export_type: CDExportType.BRANDED_PRODUCTS,
              full_export: true,
            })
          }
          disabled={loading}
        >
          Export Branded Products
        </Button>
      </div>
      <div className="my-3">
        <h4>Attributes</h4>
        <Button onClick={() => exportAttributes()} disabled={loading}>
          Export All Attributes
        </Button>
        <Button
          className="ml-3"
          onClick={() => setShowAttributeUpsertModal(true)}
          disabled={loading}
        >
          Import Attrributes
        </Button>
        <Button
          className="ml-3"
          onClick={() => setShowAttributeValueUpsertModal(true)}
          disabled={loading}
        >
          Import Attrribute Values
        </Button>
      </div>
      <div className="my-3">
        <Button onClick={() => setShowGtinValidatorCsvUploadModal(true)} disabled={loading}>
          Validate GTIN
        </Button>
      </div>
      <div className="my-3">
        <h4>Products</h4>
        <Button onClick={() => exportIncorrectlyLinkedProducts()} disabled={loading}>
          Export Incorrectly Linked Product Details
        </Button>
      </div>
      {showFsaSkuMappingModal ? (
        <FsaSkuMappingsImportModal onHide={() => setShowFsaSkuMappingModal(false)} />
      ) : null}
      {showAttributeUpsertModal ? (
        <FileUploadModal
          onHide={() => setShowAttributeUpsertModal(false)}
          onSubmit={upsertAttributes}
          header="Import Attributes"
        />
      ) : null}
      {showAttributeValueUpsertModal ? (
        <FileUploadModal
          onHide={() => setShowAttributeValueUpsertModal(false)}
          onSubmit={upsertAttributeValues}
          header="Import Attribute Values"
        />
      ) : null}
      {showGtinValidatorCsvUploadModal ? (
        <FileUploadModal
          onHide={() => setShowGtinValidatorCsvUploadModal(false)}
          onSubmit={uploadGTINCsv}
          header="Upload file to validate gtin"
        />
      ) : null}
    </Container>
  );
}

export default SyncDataView;
