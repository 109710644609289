// update along with app/lib/enums/activity_log_type.py
const ActivityLogTypes = {
  DELETE_UNLINKED_BRANDS: 'DELETE_UNLINKED_BRANDS',
  DELETE_UNLINKED_MANUFACTURES: 'DELETE_UNLINKED_MANUFACTURES',
  MERGE_BRANDS: 'MERGE_BRANDS',
  MERGE_MANUFACTURERS: 'MERGE_MANUFACTURERS',
  MERGE_PRODUCTS: 'MERGE_PRODUCTS',
  TAG_IMAGES: 'TAG_IMAGES',
  UNVERIFY_BRANDS: 'UNVERIFY_BRANDS',
  UNVERIFY_MANUFACTURERS: 'UNVERIFY_MANUFACTURERS',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  VERIFY_BRANDS: 'VERIFY_BRANDS',
  VERIFY_MANUFACTURERS: 'VERIFY_MANUFACTURERS',
  PRODUCT_OUTLIER_CHANGES: 'PRODUCT_OUTLIER_CHANGES',
  SIMILAR_BRAND_NAMES_DELETE: 'SIMILAR_BRAND_NAMES_DELETE',
  SIMILAR_MANUFACTURER_NAMES_DELETE: 'SIMILAR_MANUFACTURER_NAMES_DELETE',
  MERGE_GLOBAL_PRODUCTS: 'MERGE_GLOBAL_PRODUCTS',
  UPDATE_BRAND: 'UPDATE_BRAND',
  UPDATE_MANUFACTURER: 'UPDATE_MANUFACTURER',
};

export default ActivityLogTypes;
