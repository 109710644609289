import React, { forwardRef, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import ToggleSwitch from 'react-switch';

import ActionButton from 'components/global_products/global_products_view/ActionButton';
import { GLOBAL_PRODUCT_REMOVE_CATEGORIES } from 'lib/networking/endpoints';
import { GlobalProductsContext } from 'components/global_products/GlobalProductsContainer';
import GlobalProductsHeader from 'components/global_products/global_products_view/GlobalProductsHeader';
import GlobalProductsTable from 'components/global_products/global_products_view/GlobalProductsTable';
import { grape } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import ProductStatus from 'lib/enums/ProductStatus';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

const GlobalProductsView = forwardRef(
  ({ setSelectedIDsWithShift, clearSelectedIDs, forAttribution = false }, globalProductsRef) => {
    const {
      products,
      productCount,
      globalProductsLoading,
      errorLoadingGlobalProducts,
      globalProductsCountLoading,
      errorLoadingGlobalProductsCount,
      refetch,
      refetchCount,
      handleSetSelectedStatus,
      currentPage,
      setCurrentPage,
      itemsPerPage,
      setItemsPerPage,
      selectedStatus,
      viewsLoading,
      handleExportGlobalProducts,
      productsExporting,
      selectedIDs,
      showOnboardedDPProductsOnly,
      setShowOnboardedDPProductsOnly,
    } = useContext(GlobalProductsContext);
    const hasUserPermissions = useUserPermissions();

    const { postData: removeCategories, loading: removing } = usePost(
      GLOBAL_PRODUCT_REMOVE_CATEGORIES,
      () => {
        onSuccess('Successfully unclassified the selected products');
        clearSelectedIDs();
      },
      () => onError('An error occurred while unclassifying selected products'),
    );

    const onSuccess = message => {
      SwalDialog('success', message, 'Success', 'center', () => {
        refetch();
        clearSelectedIDs();
      });
    };

    const onError = message => {
      SwalDialog('error', message, 'Error', 'center');
    };

    useImperativeHandle(globalProductsRef, () => ({
      refetchGlobalProducts: () => {
        refetch();
        refetchCount();
      },
    }));

    const actionButton =
      !forAttribution &&
      (selectedStatus?.find(status => status === ProductStatus.CATEGORIZED) ||
        !selectedStatus?.length) &&
      hasUserPermissions([UserPermission.UNCLASSIFY]) ? (
        <ActionButton
          selectedIDs={[...new Set(selectedIDs.map(unique_id => unique_id.split('-')[2]))]}
          disabled={globalProductsLoading || !selectedIDs.length || removing}
          action={removeCategories}
          message="Are you sure you want to unclassify the selected products"
          text="Unclassify"
        />
      ) : null;

    const updateItemsPerPage = count => {
      setItemsPerPage(count);
      clearSelectedIDs();
    };

    // when the page is changed; update the select all check box and
    // un-select all the selected products
    const updatePageNumber = pageNumber => {
      setCurrentPage(pageNumber);
      clearSelectedIDs();
    };

    if (globalProductsLoading || viewsLoading) {
      return <LoadingSpinner short />;
    }

    if (errorLoadingGlobalProducts || !products) {
      return null;
    }

    return (
      <Container fluid className={classNames(css(styles.container), 'py-1 pr-1')}>
        <Row>
          <h3 className="mx-3 mb-1 float-left font-weight-bold">Classify Products</h3>
          <Col className={css(styles.toggleSwitch)}>
            Show Live DP Products
            <ToggleSwitch
              className="ml-2"
              onColor={grape}
              checked={showOnboardedDPProductsOnly}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={setShowOnboardedDPProductsOnly}
            />
          </Col>
        </Row>
        <GlobalProductsHeader
          selectedStatus={selectedStatus}
          setSelectedStatus={handleSetSelectedStatus}
          setCurrentPage={updatePageNumber}
          clearSelectedIDs={clearSelectedIDs}
          disabled={globalProductsLoading}
          handleExportGlobalProducts={handleExportGlobalProducts}
          productsExporting={productsExporting}
          forAttribution={forAttribution}
        />
        <Row className="px-2">
          <Col className="p-2 d-flex align-items-center">
            {actionButton}
            {globalProductsCountLoading ? (
              <Spinner animation="border" role="status" size="sm" className="ml-4" />
            ) : (
              <span className={classNames(css(styles.selectedText), 'text-secondary ml-2')}>
                ({selectedIDs.length}/{productCount} selected)
              </span>
            )}
          </Col>
          <Col
            xs={8}
            className={classNames(
              css(styles.pagination),
              'w-100 d-flex align-items-center justify-content-end px-2 py-1',
            )}
          >
            {globalProductsCountLoading ? (
              <Spinner animation="border" role="status" size="sm" className="mr-4" />
            ) : (
              <PaginationComponent
                totalItems={productCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={updateItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={updatePageNumber}
                disabled={globalProductsLoading}
              />
            )}
          </Col>
        </Row>
        <div className={css(styles.tableContainer)}>
          <GlobalProductsTable setSelectedIDsWithShift={setSelectedIDsWithShift} />
        </div>
      </Container>
    );
  },
);

GlobalProductsView.displayName = 'GlobalProductsView';

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
    overflowX: 'hidden',
  },
  tableContainer: {
    height: '78vh',
    overflowY: 'scroll',
  },
  selectedText: {
    fontSize: '0.7rem',
  },
  pagination: {
    zIndex: 2,
  },
  toggleSwitch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    fontWeight: 'bold',
    marginTop: '3px',
    marginBottom: '1px',
  },
});

GlobalProductsView.propTypes = {
  setSelectedIDsWithShift: PropTypes.func,
  clearSelectedIDs: PropTypes.func,
  forAttribution: PropTypes.bool,
};

export default GlobalProductsView;
