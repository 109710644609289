import { StyleSheet } from 'aphrodite';

import { categoryOrange, green, orange, lightGreen2, salmon } from 'lib/css/colors';

const getLetterByScore = score => {
  if (score > 80) {
    return 'A';
  }
  if (score > 60) {
    return 'B';
  }
  if (score > 40) {
    return 'C';
  }
  if (score > 20) {
    return 'D';
  }
  return 'F';
};

function useProductScoreStyle(score) {
  const letter = getLetterByScore(score);

  return {
    letter,
    backgroundStyle: styles[letter],
  };
}

const styles = StyleSheet.create({
  A: {
    backgroundColor: lightGreen2,
  },
  B: {
    backgroundColor: green,
  },
  C: {
    backgroundColor: orange,
  },
  D: {
    backgroundColor: categoryOrange,
  },
  F: {
    backgroundColor: salmon,
  },
});

export default useProductScoreStyle;
