import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';

import { ATTRIBUTES_FOR_DATA_SOURCE_MAPPING, ATTRIBUTE_VALUES } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function AddNewAttributeModal({ onHide, setUpdatedFilterValues }) {
  const [selectedAttributeID, setSelectedAttributeID] = useState();
  const [selectedGlobalAttributeID, setSelectedGlobalAttributeID] = useState();
  const [selectedAttributeName, setSelectedAttributeValueName] = useState();
  const [selectedAttributeValueID, setSelectedAttributeValueID] = useState();
  const [displayName, setDisplayName] = useState('');
  const { data: { data: attributes } = {} } = useGet(ATTRIBUTES_FOR_DATA_SOURCE_MAPPING);
  const { data: { attribute_values } = {} } = useGet(
    ATTRIBUTE_VALUES,
    {
      ...(selectedAttributeID && {
        attribute_id: selectedAttributeID,
      }),
      ...(selectedGlobalAttributeID && {
        global_attribute_id: selectedGlobalAttributeID,
      }),
    },
    !selectedAttributeID && !selectedGlobalAttributeID,
  );

  const attributeOptions = attributes?.map(attr => ({
    label: attr.name,
    value: {
      attribute_id: attr.attribute_id,
      global_attribute_id: attr.global_attribute_id,
    },
  }));

  const attributeValuesForDropdown = attribute_values?.map(attribute_value => ({
    label: attribute_value.attribute_value,
    value: attribute_value.id,
  }));

  const handleAttributeChange = option => {
    setSelectedAttributeID(option.value.attribute_id);
    setSelectedGlobalAttributeID(option.value.global_attribute_id);
    setSelectedAttributeValueName(option.label);
  };

  const handleAttributeValueChange = option => {
    setSelectedAttributeValueID(option.value);
  };

  const handleDisplayNameChange = event => {
    setDisplayName(event.target.value);
  };

  const handleAddNewAttribute = () => {
    setUpdatedFilterValues(prevState => ({
      ...prevState,
      attribute_values: [
        ...prevState.attribute_values,
        {
          attribute_id: selectedAttributeID,
          global_attribute_id: selectedGlobalAttributeID,
          attribute_name: selectedAttributeName,
          catalog_filter_attribute_display_name: displayName,
          catalog_filter_attribute_value_id: null,
          catelog_filter_attribute_value_name: null,
          attribute_value_id: selectedAttributeValueID,
          is_new: true,
        },
      ],
    }));
    onHide();
  };
  return (
    <Modal centered show={true} onHide={onHide} size="xl">
      <Modal.Header className="py-2 my-2" closeButton>
        <h5 className="font-weight-bold">Add New Attribute</h5>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="d-flex justify-content-center">
            <Form.Label>Attribute</Form.Label>
          </Col>
          <Col className="d-flex justify-content-center">
            <Form.Label>Attribute Value</Form.Label>
          </Col>
          <Col className="d-flex justify-content-center">
            <Form.Label>Display Name</Form.Label>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Select options={attributeOptions} onChange={option => handleAttributeChange(option)} />
          </Col>
          <Col>
            <Select
              options={attributeValuesForDropdown}
              onChange={option => handleAttributeValueChange(option)}
            />
          </Col>
          <Col>
            <Form.Control
              type="text"
              placeholder="Enter display name"
              value={displayName}
              onChange={event => handleDisplayNameChange(event)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAddNewAttribute}>
          Add New Attribute
        </Button>
        <Button variant="primary" onClick={() => onHide()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddNewAttributeModal.propTypes = {
  onHide: PropTypes.func,
  catalogFilterAttributeID: PropTypes.number,
  setUpdatedFilterValues: PropTypes.func,
};

export default AddNewAttributeModal;
