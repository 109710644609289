import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import CHANGE_LOG_STATUS from 'lib/enums/ChangeLogStatus';
import { lightBrown, lightGreen, lightGreen2, lightYellow } from 'lib/css/colors';

function ProductUpdateStatusBadge({ status }) {
  return (
    <Badge
      className={
        status.toLowerCase() === CHANGE_LOG_STATUS.PENDING.toLowerCase()
          ? css(styles.openStatusBadge)
          : css(styles.resolvedStatusBadge)
      }
    >
      {status}
    </Badge>
  );
}

const styles = StyleSheet.create({
  resolvedStatusBadge: {
    color: lightGreen2,
    backgroundColor: lightGreen,
  },
  openStatusBadge: {
    color: lightBrown,
    backgroundColor: lightYellow,
  },
});

ProductUpdateStatusBadge.propTypes = {
  status: PropTypes.string,
};

export default ProductUpdateStatusBadge;
