import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ProductCatalogCard from 'components/product_lists/product_list/ProductCatalogCard';
import QAProductModal from 'components/product_lists/product_list/qa_product_list/QAProductModal';

function QAProductListCatalogView({ products, refetch }) {
  const [QAModalSettings, setQAModalSettings] = useState({ show: false });

  return (
    <div className="d-flex flex-wrap justify-content-center">
      {products.length ? (
        products.map(product => (
          <ProductCatalogCard
            key={`${product.product_type}-${product.product_id}`}
            product={product}
            setQAModalSettings={setQAModalSettings}
            isQAView={true}
          />
        ))
      ) : (
        <div className="p-5 my-3">No products found</div>
      )}
      {QAModalSettings.product ? (
        <QAProductModal
          show={QAModalSettings.show}
          product={QAModalSettings.product}
          onHide={() => setQAModalSettings({ show: false })}
          refetch={refetch}
        />
      ) : null}
    </div>
  );
}

QAProductListCatalogView.propTypes = {
  products: PropTypes.array,
  refetch: PropTypes.func,
};

export default QAProductListCatalogView;
