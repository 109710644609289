import { ProductVerificationStatus } from 'lib/enums/ProductVerificationStatus';

// update along with app/lib/enums/data_source_entity.py
const DATA_SOURCE_ENTITY = {
  MANUFACTURER: 'MANUFACTURER',
  VENDOR: 'VENDOR',
};

const DATA_SOURCE_ENTITY_OPTIONS = [
  { value: DATA_SOURCE_ENTITY.MANUFACTURER, label: 'Manufacturer' },
  { value: DATA_SOURCE_ENTITY.VENDOR, label: 'Distributor' },
];

const DATA_SOURCE_VERIFICATION_OPTIONS = [
  { value: ProductVerificationStatus.VERIFIED, label: 'Verified' },
  { value: ProductVerificationStatus.UNVERIFIED, label: 'Unverified' },
];

// update along with app/lib/enums/data_source/data_source_import_type.py
const DATA_SOURCE_IMPORT_TYPES = {
  ATTRIBUTE_IMPORT: 'ATTRIBUTE_IMPORT',
  SKU_MAPPING_IMPORT: 'SKU_MAPPING_IMPORT',
  IMAGE_IMPORT: 'IMAGE_IMPORT',
  FLAG_UPDATE: 'FLAG_UPDATE',
};

const DATA_SOURCE_IMPORT_TYPE_OPTIONS = [
  { value: DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT, label: 'Attribute Import' },
  { value: DATA_SOURCE_IMPORT_TYPES.SKU_MAPPING_IMPORT, label: 'SKU Mapping Import' },
  { value: DATA_SOURCE_IMPORT_TYPES.IMAGE_IMPORT, label: 'Image Import' },
  { value: DATA_SOURCE_IMPORT_TYPES.FLAG_UPDATE, label: 'Flag Update' },
];

export default DATA_SOURCE_ENTITY;
export {
  DATA_SOURCE_ENTITY_OPTIONS,
  DATA_SOURCE_VERIFICATION_OPTIONS,
  DATA_SOURCE_IMPORT_TYPES,
  DATA_SOURCE_IMPORT_TYPE_OPTIONS,
};
