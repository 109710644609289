import React, { createContext, useMemo, useState } from 'react';

import { Button, Col, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faFilter, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

import {
  ALL_PRODUCT_LISTS,
  BRANDS,
  BRANDS_DELETE_UNLINKED,
  BRANDS_DOWNLOAD_CSV,
  BRANDS_UNVERIFY,
} from 'lib/networking/endpoints';
import { areYouSure } from 'lib/utils/toast';
import { backgroundGrey3, white } from 'lib/css/colors';
import BrandEditModal from 'components/manage_brands/BrandEditModal';
import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageBrandsTable from 'components/manage_brands/ManageBrandsTable';
import MergeBrandsModal from 'components/manage_brands/MergeBrandsModal';
import BrandFilters from 'components/manage_brands/filters/BrandFilters';
import BrandImportModal from 'components/manage_brands/BrandImportModal';
import PaginationComponent from 'components/shared/PaginationComponent';
import SearchBar from 'components/shared/SearchBar';
import useGet from 'lib/hooks/useGet';
import useQueryString from 'lib/hooks/useQueryString';
import usePost from 'lib/hooks/usePost';

const ManageBrandsContext = createContext({});

function ManageBrandsView() {
  const history = useHistory();
  const verificationStatus = useQueryString().get('verificationStatus');
  const isDPOnly = useQueryString().get('isDPOnly');

  const [mergeOrDeleteList, setMergeOrDeleteList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery] = useDebounce(searchInput, 500);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [productList, setProductList] = useState();
  const [status, setStatus] = useState(verificationStatus || '');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [showBrandImportModal, setShowBrandImportModal] = useState(false);
  const [manufacturer, setManufacturer] = useState();
  const [brandEditModalSettings, setBrandEditModalSettings] = useState({ show: false });

  const {
    data: { brands, count } = {},
    loading,
    refetch,
  } = useGet(BRANDS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    query: searchQuery,
    product_list_id: productList?.id,
    status: status,
    manufacturer_id: manufacturer?.value,
    is_dp_only: isDPOnly || false,
  });

  const { postData: deleteBrands, loading: brandsDeleting } = usePost(
    BRANDS_DELETE_UNLINKED,
    () => {
      SwalDialog('success', 'Successfully deleted brands', 'Success', 'center');
      refetch();
      setMergeOrDeleteList([]);
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while deleting brands: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const { postData: unverifyBrands, loading: brandsUnverifying } = usePost(
    BRANDS_UNVERIFY,
    () => {
      SwalDialog('success', 'Successfully unverified brands', 'Success', 'center');
      refetch();
      setMergeOrDeleteList([]);
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while unverifying brands: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const { postData: exportBrandDetails, loading: brandsExporting } = usePost(
    BRANDS_DOWNLOAD_CSV,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  const { data: { product_lists = [] } = {} } = useGet(ALL_PRODUCT_LISTS);

  const productListOptions = useMemo(() => {
    return product_lists.map((list, index) => ({ label: list.name, value: index }));
  }, [product_lists]);

  return (
    <ManageBrandsContext.Provider
      value={{ mergeList: mergeOrDeleteList, setMergeList: setMergeOrDeleteList }}
    >
      <Container>
        <div className="mb-3 d-flex justify-content-between">
          <h3 className="font-weight-bold">Manage Brands</h3>
          <div>
            <Button
              className="float-left align-left"
              variant="link"
              onClick={() => history.push('/enrich-products/manage-brands/brand-QA')}
            >
              Brand QA
            </Button>
            <Button
              className="float-left align-left"
              variant="link"
              onClick={() => history.push('/enrich-products/manage-brands/manage-duplicate-brands')}
            >
              Manage Duplicate Brands
            </Button>
          </div>
        </div>
        <Row>
          <Col xs={3}>
            <SearchBar
              value={searchInput}
              setValue={setSearchInput}
              placeholder="Search by brand name, manufacturer"
            />
          </Col>
          <Col xs={2} className="d-flex mr-2 justify-content-start">
            <Button variant="link" onClick={() => setShowFilters(!showFilters)} className="p-2">
              <FontAwesomeIcon size="lg" icon={faFilter} className="mr-2" />
              {showFilters ? 'Hide' : 'Show'} Filters
            </Button>
          </Col>
          <Col className="d-flex mr-2 justify-content-end">
            <Button
              className="mr-3"
              variant="danger"
              onClick={() =>
                areYouSure(
                  () => deleteBrands({ deleteIds: mergeOrDeleteList.map(brand => brand.id) }),
                  'Are you sure you want to delete the selected brands?',
                )
              }
              disabled={mergeOrDeleteList.length < 1 || brandsDeleting}
            >
              {brandsDeleting ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                'Delete Unlinked Brands'
              )}
            </Button>
            <Button
              className="mr-3"
              variant="outline-danger"
              onClick={() =>
                areYouSure(
                  () => unverifyBrands({ brand_ids: mergeOrDeleteList.map(brand => brand.id) }),
                  'Are you sure you want to unverify the selected brands?',
                )
              }
              disabled={mergeOrDeleteList.length < 1 || brandsUnverifying}
            >
              {brandsUnverifying ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                'Unverify Selected Brands'
              )}
            </Button>
            <Button
              className="mr-3"
              variant="primary"
              onClick={() => setShowMergeModal(true)}
              disabled={mergeOrDeleteList.length < 2}
            >
              Merge Brands
            </Button>
            <Button
              className="mr-3"
              variant="primary"
              onClick={() => history.push('/enrich-products/brand-editor')}
            >
              + New Brand
            </Button>
            <Button
              className="mr-3"
              variant="primary"
              onClick={() => setShowBrandImportModal(true)}
            >
              Import New Brands
            </Button>
            <Button
              variant="outline-primary"
              onClick={() =>
                exportBrandDetails({
                  query: searchQuery,
                  product_list_id: productList?.id,
                  status: status,
                  manufacturer_id: manufacturer?.value,
                })
              }
              disabled={brandsExporting}
            >
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              Export
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex mt-3">
            <span className={classNames(css(styles.selectedText), 'text-secondary ml-2')}>
              {count} Brands found {productList === undefined ? null : `for ${productList.name}`}
            </span>
          </Col>
          <Col className={classNames(css(styles.pagination), 'd-flex justify-content-end')}>
            <PaginationComponent
              totalItems={count}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              defaultSize
            />
          </Col>
        </Row>
        <div className={css(styles.scrollableContainer)}>
          {loading ? (
            <LoadingSpinner short />
          ) : (
            <ManageBrandsTable
              brands={brands}
              setBrandEditModalSettings={setBrandEditModalSettings}
            />
          )}
        </div>
        {showFilters && (
          <div className={css(styles.brandFiltersOverlay)}>
            <BrandFilters
              productList={productList}
              setProductList={setProductList}
              status={status}
              setStatus={setStatus}
              setShowFilters={setShowFilters}
              loading={loading}
              manufacturer={manufacturer}
              setManufacturer={setManufacturer}
              product_lists={product_lists}
              productListOptions={productListOptions}
            />
          </div>
        )}
        {showMergeModal ? (
          <MergeBrandsModal
            mergeList={mergeOrDeleteList}
            setMergeList={setMergeOrDeleteList}
            onHide={() => {
              setShowMergeModal(false);
              refetch();
              setMergeOrDeleteList([]);
            }}
          />
        ) : showBrandImportModal ? (
          <BrandImportModal onHide={() => setShowBrandImportModal(false)} refetch={refetch} />
        ) : null}
        {brandEditModalSettings.show ? (
          <BrandEditModal
            show={brandEditModalSettings.show}
            brand={brandEditModalSettings.brand}
            onHide={() => {
              setBrandEditModalSettings({ show: false });
              setMergeOrDeleteList([]);
            }}
            refetch={refetch}
          />
        ) : null}
      </Container>
    </ManageBrandsContext.Provider>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '90vh',
  },
  searchBar: {
    width: '30%',
  },
  brandFiltersOverlay: {
    position: 'absolute',
    padding: '1.5rem',
    top: 40,
    right: 0,
    bottom: 0,
    width: '30vw',
    minHeight: '100vh',
    backgroundColor: white,
    borderLeft: '5px solid' + backgroundGrey3,
    transitionProperty: 'transform, visibility, width',
    transitionDuration: '0.4s',
    transitionTimingFunction: 'ease-in-out',
    zIndex: 999,
    visibility: 'visible',
  },
  pagination: {
    marginTop: '1rem',
    zIndex: 2,
  },
  selectedText: {
    fontSize: '1rem',
  },
});

export default ManageBrandsView;
export { ManageBrandsContext };
