// returns a new array with the first element matching the predicate (if any), with the replacement
function findAndReplace(arr, predicate, replacement) {
  const newArr = [...arr];
  const idx = arr.findIndex(predicate);
  if (idx !== -1) {
    newArr.splice(idx, 1, replacement);
  }
  return newArr;
}

function arraysAreEqual(arr1, arr2) {
  // Check if both are arrays
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]));
}

export { arraysAreEqual, findAndReplace };
