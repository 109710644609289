import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import App from 'containers/App';
import reportWebVitals from 'reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { isLocalhost, isSandboxServer, isStagingServer } from 'lib/networking/endpoints';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'lib/css/react_split_pane.css';
import 'styles/themes/modern-override-styles.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const environment = isLocalhost
  ? 'development'
  : isSandboxServer
  ? 'sandbox'
  : isStagingServer
  ? 'staging'
  : 'production';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: environment,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
