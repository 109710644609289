import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import { areYouSure } from 'lib/utils/toast';

const ActionButton = ({ disabled, selectedIDs, action, message, text }) => {
  return (
    <Button
      disabled={disabled}
      variant="success"
      onClick={() => areYouSure(() => action({ product_ids: selectedIDs }), message)}
    >
      {text}
    </Button>
  );
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  selectedIDs: PropTypes.array,
  action: PropTypes.func,
  message: PropTypes.string,
  text: PropTypes.string,
};

export default ActionButton;
