import React, { useEffect, useMemo, useState } from 'react';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { uniqBy } from 'lodash';
import { useParams } from 'react-router-dom/';

import ActionDropDown from 'components/shared/ActionDropDown';
import {
  ATTRIBUTE_SCHEMA_IMPORT,
  SCHEMA,
  SCHEMA_ATTRIBUTE,
  SCHEMA_ATTRIBUTES_DELETE,
  SCHEMA_ATTRIBUTES_EXPORT,
} from 'lib/networking/endpoints';
import ConfigureSchemaRow from 'components/manage_attribute/manage_schema/ConfigureSchemaRow';
import Container from 'components/shared/Container';
import FileUploadModal from 'components/shared/FileUploadModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { salmon } from 'lib/css/colors';
import { SCHEMA_ATTRIBUTE_SAMPLE_FILE } from 'lib/constants';
import SchemaAttributeDuplicateModal from 'components/manage_attribute/manage_schema/SchemaAttributeDuplicateModal';
import SwalDialog from 'lib/utils/toast';
import useDelete from 'lib/hooks/useDelete';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function ConfigureSchema() {
  const [searchedAttribute, setSearchedAttribute] = useState('');
  const [selectedSchemaAttributeIds, setSelectedSchemaAttributeIds] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showSchemaAttributeDuplicateModal, setShowSchemaAttributeDuplicateModal] = useState(false);

  const { schemaId } = useParams();
  const {
    data: { data: schemaAttributes = [] } = [],
    loading,
    refetch,
  } = useGet(SCHEMA_ATTRIBUTE, { schema_id: schemaId });
  const { data: { data: schemaDetails = [] } = [] } = useGet(
    SCHEMA,
    { schema_id: schemaId },
    !schemaId,
  );

  const { postData: uploadFile, loading: uploading } = usePost(
    ATTRIBUTE_SCHEMA_IMPORT,
    () => {
      SwalDialog('success', 'Processing the uploaded file.', 'Success', 'center');
      setShowFileUploadModal(false);
    },
    () => {
      SwalDialog('error', 'An error occurred during the file upload.', 'Error', 'center');
    },
  );

  const { postData: exportFile } = usePost(
    SCHEMA_ATTRIBUTES_EXPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () =>
      SwalDialog(
        'error',
        'An error occurred while trying to download the global products',
        'Error',
        'center',
      ),
  );

  const { deleteData: deleteSchemaAttributeRecords, loading: deleting } = useDelete(
    SCHEMA_ATTRIBUTES_DELETE,
    false,
    () => {
      SwalDialog('success', 'Deleting records.', 'Success', 'center');
      setSelectedSchemaAttributeIds([]);
      refetch();
    },
    () => {
      SwalDialog('error', 'An error occurred.', 'Error', 'center');
    },
  );

  useEffect(() => {
    if (schemaAttributes.length) {
      setFilteredAttributes(schemaAttributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemaAttributes]);

  useEffect(() => {
    let attributesToFilter = schemaAttributes;
    if (selectedCategory) {
      attributesToFilter = schemaAttributes.filter(
        schemaAttr => schemaAttr.category === selectedCategory.value,
      );
    }
    if (searchedAttribute) {
      attributesToFilter = filteredAttributes?.filter(
        attr =>
          attr.attributes?.attribute_name
            ?.toLowerCase()
            .includes(searchedAttribute.toLowerCase()) ||
          attr.global_attribute?.global_attribute_name
            ?.toLowerCase()
            .includes(searchedAttribute.toLowerCase()),
      );
    }
    setFilteredAttributes(attributesToFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, searchedAttribute]);

  const categoryListOptions = useMemo(() => {
    if (!schemaAttributes?.length) {
      return [];
    }
    return uniqBy(
      schemaAttributes
        .filter(attr => attr.category)
        .map(attr => ({ label: attr.category, value: attr.category })),
      'value',
    );
  }, [schemaAttributes]);

  if (loading || uploading || deleting) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <h3 className="font-weight-bold mb-5">{schemaDetails?.schema_name} Schema</h3>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Control
              onChange={event => setSearchedAttribute(event.target.value)}
              type="text"
              placeholder="Search Attributes"
              value={searchedAttribute}
            />
          </Form.Group>
        </Col>
        <Col sm="3">
          <Select
            isClearable
            isLoading={loading}
            options={categoryListOptions}
            value={selectedCategory}
            onChange={option => {
              setSearchedAttribute('');
              setSelectedCategory(option);
            }}
            placeholder="Select Category"
          />
        </Col>
        <Col sm="5">
          <Button
            disabled={selectedSchemaAttributeIds.length === 0}
            variant="link"
            className={classNames(css(styles.deleteButton), 'mr-1 float-right')}
            onClick={() => deleteSchemaAttributeRecords({ record_ids: selectedSchemaAttributeIds })}
          >
            Delete
          </Button>
        </Col>
        <Col sm="1">
          <Button className="float-right" onClick={() => setShowFileUploadModal(true)}>
            Add Attributes
          </Button>
        </Col>
        <Col sm="1">
          <ActionDropDown
            submenuItems={[
              {
                title: 'Export schema attributes',
                action: () => exportFile({ schema_id: schemaId }),
              },
              {
                title: 'Copy schema attributes to another schema',
                action: () => setShowSchemaAttributeDuplicateModal(true),
              },
            ]}
          />
        </Col>
      </Row>
      <Table className="mt-2" responsive>
        <thead>
          <tr className="d-flex">
            <th className="col-2 text-break pl-4">Attribute Name</th>
            <th className="col-1 text-break">Display Name</th>
            <th className="col-1 text-break">Attribute Help Text</th>
            <th className="col-1 text-break">Category</th>
            <th className="col-1 text-break">Sub-Category</th>
            <th className="col-1">Mandatory</th>
            <th className="col-1">Category Index</th>
            <th className="col-1">Sub-Category Index</th>
            <th className="col-1">Sort Index</th>
            <th className="col-1">Group Index</th>
            <th className="col-1"></th>
          </tr>
        </thead>
        <tbody>
          {filteredAttributes.map(schemaAttr => (
            <ConfigureSchemaRow
              key={schemaAttr.id}
              schemaAttribute={schemaAttr}
              setSelectedSchemaAttributeIds={setSelectedSchemaAttributeIds}
              selectedSchemaAttributeIds={selectedSchemaAttributeIds}
              refetch={refetch}
            />
          ))}
        </tbody>
      </Table>
      {showFileUploadModal ? (
        <FileUploadModal
          onHide={() => setShowFileUploadModal(false)}
          onSubmit={uploadFile}
          header="Upload attributes for the schema"
          sample_file_url={SCHEMA_ATTRIBUTE_SAMPLE_FILE}
          postBody={{ schema_id: schemaId }}
        />
      ) : null}
      {showSchemaAttributeDuplicateModal ? (
        <SchemaAttributeDuplicateModal onHide={() => setShowSchemaAttributeDuplicateModal(false)} />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  deleteButton: {
    color: salmon,
  },
});

export default ConfigureSchema;
