import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navbar } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import AppNavSideBar from 'components/layout/sidenav/AppSideNavBar';
import { NAVIGATION_SIDE_BAR_WIDTH, SIDE_BAR_DISABLED_PATHS } from 'lib/constants';
import { deeplavender } from 'lib/css/colors';

const AppNavBarContext = React.createContext(null);

function AppNavBar({ expanded, setExpanded, action = 'menu', title = '' }) {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (SIDE_BAR_DISABLED_PATHS.includes(location.pathname)) {
      setExpanded(false);
    }
  }, [location, setExpanded]);

  const brandClickHandler = useCallback(
    action => {
      if (action === 'back') {
        history.goBack();
      } else if (action === 'menu') {
        setExpanded(!expanded);
      }
    },
    [expanded, setExpanded, history],
  );

  return (
    <>
      <Navbar
        sticky="top"
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        className={classNames(css(expanded ? styles.navbarExpanded : styles.navbarCollapsed))}
      >
        <Navbar.Brand className="font-weight-bold">
          <div className="d-flex">
            <div
              onClick={() => brandClickHandler(action)}
              className={classNames(
                css(expanded && styles.active),
                'nav-menu-btn align-self-center',
              )}
            >
              <FontAwesomeIcon className="mr-2" icon={action === 'back' ? faChevronLeft : faBars} />
              Menu
            </div>
            {!expanded && <span className={classNames(css(styles.title), 'mx-3')}>{title}</span>}
          </div>
        </Navbar.Brand>
      </Navbar>
      {action === 'menu' && <AppNavSideBar expanded={expanded} />}
    </>
  );
}

const styles = StyleSheet.create({
  active: {
    color: deeplavender,
  },
  navbarCollapsed: {
    maxHeight: 40,
    paddingTop: 'max(0.5rem, env(safe-area-inset-top))',
    zIndex: 1040,
  },
  navbarExpanded: {
    marginLeft: NAVIGATION_SIDE_BAR_WIDTH,
    width: `calc(100% - ${NAVIGATION_SIDE_BAR_WIDTH})`,
    maxHeight: 40,
    paddingTop: 'max(0.5rem, env(safe-area-inset-top))',
    zIndex: 1040,
  },
  title: {
    fontSize: 25,
    fontWeight: 'bolder',
    lineHeight: 1,
  },
});

AppNavBar.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  action: PropTypes.oneOf(['back', 'menu']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default AppNavBar;
export { AppNavBarContext };
