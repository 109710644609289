import React from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Spinner, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Swal from 'sweetalert2';

import { areYouSure } from 'lib/utils/toast';
import { darkGrey2, lightPurple2, squidInk } from 'lib/css/colors';
import { GLOBAL_PRODUCT_REMOVE_CATEGORIES } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function GlobalProductDetailsSection({ globalProduct, refetch }) {
  const { postData: removeCategories, loading: removing } = usePost(
    GLOBAL_PRODUCT_REMOVE_CATEGORIES,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully Unclassified',
        title: 'Success',
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        title: 'Error',
      }),
  );
  return (
    <div className="mr-3 my-4">
      <Col className={classNames(css(styles.productOverviewBox))}>
        <Table className={css(styles.table)}>
          <tbody>
            <tr>
              <td className={css(styles.cell)}>
                <div className={css(styles.heading)}>Global Product Id</div>
                <div className={css(styles.squidInk)}>{globalProduct.id}</div>
              </td>
              <td className={css(styles.cell)}>
                <div className={css(styles.heading)}>Core 11</div>
                <div className={css(styles.squidInk)}>{globalProduct.gtin}</div>
              </td>
              <td className={css(styles.cell)}>
                <div className={css(styles.heading)}>GTIN / UPC</div>
                <div className={css(styles.squidInk)}>
                  {globalProduct.gtin_item || globalProduct.gtin_unknown || globalProduct.upc}
                </div>
              </td>
              <td className={css(styles.cell)}>
                <div className={css(styles.heading)}>
                  L4 Category
                  <Button
                    className={css(styles.button)}
                    variant="link"
                    onClick={() =>
                      areYouSure(
                        () => removeCategories({ product_ids: [globalProduct.id] }),
                        'Are you sure you want to unclassify this product?',
                      )
                    }
                    size="lg"
                    disabled={!globalProduct.l4_category?.category_name || removing}
                  >
                    {removing ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      '(Unclassify)'
                    )}
                  </Button>
                </div>
                <div className={css(styles.squidInk)}>
                  {globalProduct.l4_category?.category_name}
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </div>
  );
}

const styles = StyleSheet.create({
  productOverviewBox: {
    background: lightPurple2,
    borderRadius: 8,
    border: `1px solid ${darkGrey2}`,
  },
  table: {
    textAlign: 'center',
  },
  cell: {
    borderTop: 'none',
    paddingTop: 10,
    paddingBottom: 10,
  },
  heading: {
    fontWeight: 'bold',
  },
  squidInk: {
    color: squidInk,
  },
  editIcon: {
    marginRight: '0.75rem',
  },
  button: {
    fontSize: '1em',
    fontWeight: 'bold',
  },
});

GlobalProductDetailsSection.propTypes = {
  globalProduct: PropTypes.object,
  refetch: PropTypes.func,
};

export default GlobalProductDetailsSection;
