import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ColumnResizer from 'column-resizer';
import debounce from 'lodash.debounce';
import { Table } from 'react-bootstrap';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import { GLOBAL_PRODUCTS_TABLE_ID, AUTO_PRODUCT_CLASSIFICATION_TABLE_ID } from 'lib/constants';

const ResizableTable = ({ children, resizable, resizeOptions, className, tableID, columns }) => {
  const tableRef = useRef(null);
  const calculateWidths = useCallback(() => {
    if (columns && columns.length > 0) {
      const totalWidth = tableRef.current?.offsetWidth;
      const firstColumnWidth = columns[0].width || 30; // Default width for the first column
      const remainingWidth = (totalWidth - firstColumnWidth) / columns.length;

      const widths = columns.map((column, index) => {
        if (index === 0) {
          return firstColumnWidth;
        } else {
          return column.width || remainingWidth;
        }
      });

      return widths.join(';');
    } else {
      return '';
    }
  }, [columns]);

  const [resize, setResize] = useState();
  const [columnWidths, setColumnWidths] = useLocalStorage(tableID, calculateWidths());

  const onResize = useCallback(() => {
    debounce(() => {
      setColumnWidths(window.sessionStorage.getItem(tableID));
    }, 300);
  }, [tableID, setColumnWidths]);

  const enableResize = useCallback(() => {
    if (!resize) {
      const storedWidths =
        window.sessionStorage.getItem(tableID) || columnWidths || calculateWidths() || '';
      let widths = storedWidths.split(';').map(width => parseFloat(width));
      if ([GLOBAL_PRODUCTS_TABLE_ID, AUTO_PRODUCT_CLASSIFICATION_TABLE_ID].includes(tableID)) {
        widths[0] = widths[0] || 30;
      }
      const resizeFn = new ColumnResizer(tableRef.current, {
        ...resizeOptions,
        onResize,
        widths,
      });

      tableRef.current.className = tableRef.current?.className?.replace('grip-padding', '');

      setResize(resizeFn);
    }
  }, [resize, resizeOptions, onResize, columnWidths, tableID, calculateWidths]);

  const disableResize = () => {
    if (resize) {
      return resize.reset({ disable: true });
    }
  };

  useEffect(() => {
    if (tableRef.current && resizable) {
      enableResize();
    }
  }, [tableRef, resizable, enableResize]);

  useEffect(() => {
    return disableResize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Table bordered striped hover ref={tableRef} className={className} id={tableID}>
      {children}
    </Table>
  );
};

ResizableTable.propTypes = {
  children: PropTypes.any,
  resizable: PropTypes.bool,
  resizeOptions: PropTypes.object,
  className: PropTypes.string,
  tableID: PropTypes.string,
  columns: PropTypes.array,
};

export default ResizableTable;
