const BrandVerificationStatus = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  UNVERIFIABLE: 'UNVERIFIABLE',
};

// Purposely removed "PENDING_VERIFICATION" from here as that status can only be applied to
// brands created from C+D and should not be assignable from FSA+.
const BrandVerificationStatusOptions = [
  { value: BrandVerificationStatus.VERIFIED, label: 'Verified' },
  { value: BrandVerificationStatus.UNVERIFIED, label: 'Unverified' },
  { value: BrandVerificationStatus.UNVERIFIABLE, label: 'Unverifiable' },
];

export { BrandVerificationStatus, BrandVerificationStatusOptions };
