import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageDuplicateManufacturersTable from 'components/manage_manufacturers/duplicate_manufacturers/ManageDuplicateManufacturersTable';
import ManufacturerEditModal from 'components/manage_manufacturers/ManufacturerEditModal';
import MergeManufacturersModal from 'components/manage_manufacturers/MergeManufacturersModal';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductListFilter from 'components/shared/ProductListFilter';
import { SIMILAR_MANUFACTURERS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function ManageDuplicateManufacturersView() {
  const history = useHistory();

  const [mergeList, setMergeList] = useState([]);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [productList, setProductList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [manufacturerEditModalSettings, setManufacturerEditModalSettings] = useState({
    show: false,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchQuery] = useDebounce(searchTerm, 600);

  const itemsPerPage = 1;

  const {
    data: { manufacturers, count } = {},
    loading,
    refetch,
  } = useGet(SIMILAR_MANUFACTURERS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    product_list_id: productList?.id,
    search_term: searchQuery,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [productList, searchTerm]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => history.push('/enrich-products/manage-manufacturers')}
          />
          All manufacturers
        </h3>
      </div>
      <Row className="align-items-center">
        <Col md={4}>
          <ProductListFilter productList={productList} setProductList={setProductList} />
        </Col>
        <Col>
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search by manufacturer name"
          />
        </Col>
        <Col className="d-flex mr-2 justify-content-end">
          <Button
            className="mr-3"
            variant="primary"
            onClick={() => setShowMergeModal(true)}
            disabled={mergeList.length < 2}
          >
            Merge Manufacturers
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage - 1)}
            size="lg"
            disabled={loading || currentPage <= 1}
          >
            &lt; Previous
          </Button>
          <Button
            className={css(styles.button)}
            variant="link"
            onClick={() => setCurrentPage(currentPage + 1)}
            size="lg"
            disabled={loading || currentPage >= count}
          >
            Next &gt;
          </Button>
        </Col>
        <Col className="d-flex justify-content-end mr-3">
          {currentPage}/{count} Manufacturers found
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        <ManageDuplicateManufacturersTable
          manufacturers={manufacturers}
          mergeList={mergeList}
          setMergeList={setMergeList}
          setManufacturerEditModalSettings={setManufacturerEditModalSettings}
        />
      </div>
      {showMergeModal ? (
        <MergeManufacturersModal
          mergeList={mergeList}
          setMergeList={setMergeList}
          onHide={() => {
            setShowMergeModal(false);
            setMergeList([]);
            refetch();
          }}
        />
      ) : null}
      {manufacturerEditModalSettings.show ? (
        <ManufacturerEditModal
          show={manufacturerEditModalSettings.show}
          manufacturer={manufacturerEditModalSettings.manufacturer}
          onHide={() => {
            setManufacturerEditModalSettings({ show: false });
            setMergeList([]);
          }}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '90vh',
  },
  button: {
    fontSize: '1em',
    padding: '0.5em 1em',
  },
  hideSuggestionsButton: {
    fontSize: '1em',
    padding: '0.5em 1em',
    marginLeft: '2em',
  },
});

export default ManageDuplicateManufacturersView;
