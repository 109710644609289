// update along with app/lib/enums/attribute_priority_level.py
const AttributePriorityLevel = {
  PRIORITY_1: 'PRIORITY_1',
  PRIORITY_2: 'PRIORITY_2',
  PRIORITY_3: 'PRIORITY_3',
};

const AttributePriorityOptions = [
  { value: AttributePriorityLevel.PRIORITY_1, label: 'Priority 1' },
  { value: AttributePriorityLevel.PRIORITY_2, label: 'Priority 2' },
  { value: AttributePriorityLevel.PRIORITY_3, label: 'Priority 3' },
];

export { AttributePriorityLevel, AttributePriorityOptions };
