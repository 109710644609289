import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Modal } from 'react-bootstrap';

import BrandHarvestTableView from 'components/manage_brands/harvest_brands/BrandHarvestTableView';
import { BRANDS_BY_IDS } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function BrandHarvestGroupedBrandsViewModal({ onHide, brandIds }) {
  const { data: { data = [] } = {}, loading } = useGet(BRANDS_BY_IDS, {
    ids: brandIds,
    with_harvesting_details: 'true',
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Modal show={true} onHide={onHide} size="xl" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>Grouped Brands View</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BrandHarvestTableView
          brands={data}
          selectedIds={[]}
          setSelectedIds={() => {}}
          viewOnly={true}
        />
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
});

BrandHarvestGroupedBrandsViewModal.propTypes = {
  onHide: PropTypes.func,
  brandIds: PropTypes.array,
};

export default BrandHarvestGroupedBrandsViewModal;
