import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { useLocation } from 'react-router-dom';

import { NAVIGATION_SIDE_BAR_WIDTH } from 'lib/constants';

function FullScreenMainContainer({ expanded, children }) {
  const location = useLocation();

  if (location.pathname.indexOf('crunch') !== -1) {
    return children;
  }

  return (
    <div className={css(expanded ? styles.container : styles.containerCollapsed)}>{children}</div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginLeft: NAVIGATION_SIDE_BAR_WIDTH,
    width: `calc(100% - ${NAVIGATION_SIDE_BAR_WIDTH}px)`,
    overflowX: 'hidden !important',
  },
  containerCollapsed: {
    marginLeft: 0,
    width: '100%',
    overflowX: 'hidden !important',
  },
});

FullScreenMainContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  expanded: PropTypes.bool,
};

export default FullScreenMainContainer;
