import React from 'react';
import PropTypes from 'prop-types';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { amber, blue, iconGrey, green } from 'lib/css/colors';
import { ProductVerificationStatus } from 'lib/enums/ProductVerificationStatus';

const STATUS_COLORS = {
  [ProductVerificationStatus.VERIFIED]: green,
  [ProductVerificationStatus.PENDING_VERIFICATION]: amber,
  [ProductVerificationStatus.UNVERIFIED]: iconGrey,
  [ProductVerificationStatus.AI_VERIFIED]: blue,
};

function VerificationStatusIcon({ verificationStatus, aiSuggestedVerification }) {
  if (aiSuggestedVerification && verificationStatus === ProductVerificationStatus.VERIFIED) {
    verificationStatus = ProductVerificationStatus.AI_VERIFIED;
  }

  return (
    <OverlayTrigger overlay={<Tooltip>{verificationStatus}</Tooltip>}>
      <FontAwesomeIcon
        icon={faCheckCircle}
        color={STATUS_COLORS[verificationStatus] || iconGrey}
        size="lg"
      />
    </OverlayTrigger>
  );
}

VerificationStatusIcon.propTypes = {
  verificationStatus: PropTypes.string,
  aiSuggestedVerification: PropTypes.bool,
};

export default VerificationStatusIcon;
