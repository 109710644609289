import React from 'react';
import PropTypes from 'prop-types';

import { Col, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

const PurveyorManufacturerSection = ({ manufacturer = {} }) => {
  return (
    <Row>
      {manufacturer.logoUrl && (
        <Col md={12} lg={5} xl={3} className="d-flex align-items-center justify-content-center">
          <Image fluid src={manufacturer.logoUrl} className={css(styles.logo)} />
        </Col>
      )}
      <Col md={12} lg={7} xl={9}>
        {manufacturer.description}
      </Col>
    </Row>
  );
};

const styles = StyleSheet.create({
  logo: { maxHeight: 120, maxWidth: 140 },
});

PurveyorManufacturerSection.propTypes = {
  manufacturer: PropTypes.object,
};

export default PurveyorManufacturerSection;
