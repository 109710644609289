import { css, StyleSheet } from 'aphrodite';

const OFFSET_STEP = 10;

const fontStyles = (size, bold = false, matchLineHeight = false) =>
  css(
    StyleSheet.create({
      sizedFont: {
        fontSize: size,
        lineHeight: matchLineHeight ? size : undefined,
        fontWeight: bold ? 'bold' : undefined,
        fontFamily: bold ? 'Mont, sans-serif' : 'lato, sans-serif',
      },
    }).sizedFont,
  );

const subNutritionRowStyles = offset =>
  StyleSheet.create({
    titleContainer: {
      paddingLeft: OFFSET_STEP * offset,
    },
  });

export { fontStyles, OFFSET_STEP, subNutritionRowStyles };
