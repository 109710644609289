import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';
import { css } from 'aphrodite';

import { backgroundColorStyles } from 'lib/css/colors';
import ImageChangeRow from 'components/product_updates/product_update_details/ImageChangeRow';

function ImageChangesSection({ imageChanges }) {
  return (
    <div>
      <h4 className="font-weight-bold mb-3">Image Changes</h4>
      <Table hover>
        <thead className={css(backgroundColorStyles.backgroundGrey)}>
          <tr>
            <th>Operation</th>
            <th>Thumbnail</th>
            <th>Image Type</th>
            <th>Image State</th>
            <th>Image Facing</th>
            <th>Image Link</th>
          </tr>
        </thead>
        <tbody>
          {imageChanges.map(imageChange => (
            <ImageChangeRow key={imageChange.id} imageChange={imageChange} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

ImageChangesSection.propTypes = {
  imageChanges: PropTypes.array,
};

export default ImageChangesSection;
