import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Spinner, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { anchovy, black } from 'lib/css/colors';
import { getURL, TAXONOMY_CATEGORY_DETAILS } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
function ProductCategoryChangeConfirmModal({
  show,
  onHide,
  categoryId,
  selectedProductIDs,
  onConfirm,
  loading,
}) {
  const { data: { category: l4Category } = {}, loading: categoryLoading } = useGet(
    getURL(TAXONOMY_CATEGORY_DETAILS, {
      category_level: TaxonomyLevel.L4,
      category_id: categoryId,
    }),
    {},
    !categoryId,
  );

  const l3Category = l4Category?.l3_category;
  const l2Category = l3Category?.l2_category;
  const l1Category = l2Category?.l1_category;

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className={css(styles.title)}>Confirm Classification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {categoryLoading ? (
          <LoadingSpinner short />
        ) : (
          <Table className={css(styles.bottomBorder)} borderless>
            <thead className={css(styles.tableText)}>
              <th className="py-0">L1 Category</th>
              <th className="py-0">L2 Category</th>
              <th className="py-0">L3 Category</th>
              <th className="py-0">L4 Category</th>
            </thead>
            <tbody className={css(styles.tableBody)}>
              <tr>
                <td>{l1Category?.category_name}</td>
                <td>{l2Category?.category_name}</td>
                <td>{l3Category?.category_name}</td>
                <td>{l4Category?.category_name}</td>
              </tr>
            </tbody>
          </Table>
        )}
        <Table className={css(styles.countTable)} borderless>
          <thead className={css(styles.tableText)}>
            <th className="py-0">Selected Products</th>
          </thead>
          <tbody className={css(styles.tableBody)}>
            <tr>
              <td>{selectedProductIDs?.length}</td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex justify-content-left">
          <Button disabled={loading} className="mx-2" onClick={onConfirm}>
            {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Classify'}
          </Button>
          <Button className="mx-2" variant="outline-primary" onClick={onHide}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  bottomBorder: {
    borderBottom: '1pt solid ' + anchovy,
  },
  tableBody: {
    fontWeight: 'bold',
  },
  tableText: {
    fontSize: '1.1rem',
    color: anchovy,
  },
  productTableText: {
    fontSize: '0.75rem',
    color: black,
  },
  title: {
    fontWeight: 'bold',
  },
  countTable: {
    width: '60%',
    marginTop: '2rem',
  },
  scrollTable: {
    display: 'block',
    maxHeight: 200,
    overflowY: 'scroll',
  },
});

ProductCategoryChangeConfirmModal.propTypes = {
  categoryId: PropTypes.number,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  selectedProductIDs: PropTypes.array,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
};

export default ProductCategoryChangeConfirmModal;
