import React, { useContext, useRef } from 'react';

import { css, StyleSheet } from 'aphrodite';
import SplitPane from 'react-split-pane';

import { GlobalProductsContext } from 'components/global_products/GlobalProductsContainer';
import GlobalProductMultiAttributeSection from 'components/global_products/attribute/GlobalProductMultiAttributeSection';
import GlobalProductsView from 'components/global_products/global_products_view/GlobalProductsView';
import { useSelected } from 'lib/hooks/useSelected';
import { useShiftSelected } from 'lib/hooks/useShiftSelected';

function GlobalProductAttributeSplitView() {
  const globalProductsRef = useRef();
  const { products, selectedIDs, setSelectedIDs } = useContext(GlobalProductsContext);
  const { change: changeSelectedIDs, clear: clearSelectedIDs } = useSelected(
    selectedIDs,
    setSelectedIDs,
  );
  const setSelectedIDsWithShift = useShiftSelected(
    products?.map(product => product.id) || [],
    changeSelectedIDs,
  );

  return (
    <SplitPane
      primary="first"
      split="vertical"
      defaultSize="60%"
      maxSize={window.innerWidth - 750}
      className={css(styles.splitPane)}
    >
      <GlobalProductsView
        setSelectedIDsWithShift={setSelectedIDsWithShift}
        clearSelectedIDs={clearSelectedIDs}
        forAttribution={true}
        ref={globalProductsRef}
      />
      {selectedIDs.length ? <GlobalProductMultiAttributeSection /> : null}
      {/*empty div to avoid SplitPane children being null*/}
      <div />
    </SplitPane>
  );
}

const styles = StyleSheet.create({
  splitPane: {
    height: 'webkit-fill-available',
  },
});

export default GlobalProductAttributeSplitView;
