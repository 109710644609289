import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Table } from 'react-bootstrap';
import { convertToTitleCase } from 'lib/utils/stringUtils';
import { css, StyleSheet } from 'aphrodite';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import pointerOnHover from 'lib/css/pointerOnHover';

function ManageDuplicateManufacturersTable({
  manufacturers,
  mergeList,
  setMergeList,
  setManufacturerEditModalSettings,
}) {
  return (
    <Container>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>
              <Form.Check
                type="checkbox"
                checked={manufacturers?.length > 0 && mergeList?.length === manufacturers?.length}
                onChange={e => {
                  if (e.target.checked) {
                    setMergeList([...manufacturers]);
                  } else {
                    setMergeList([]);
                  }
                }}
              />
            </th>
            <th>Manufacturer Name</th>
            <th>GLN</th>
            <th>Brand Count</th>
            <th>Status</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {manufacturers?.length ? (
            manufacturers?.map((manufacturer, index) => (
              <tr key={index}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={mergeList?.some(
                      mergeManufacturer => mergeManufacturer?.id === manufacturer.id,
                    )}
                    onChange={e => {
                      if (e.target.checked) {
                        if (
                          !mergeList.some(
                            mergeManufacturer => mergeManufacturer.id === manufacturer.id,
                          )
                        ) {
                          setMergeList([...mergeList, manufacturer]);
                        }
                      } else {
                        setMergeList(
                          mergeList.filter(
                            mergeManufacturer => mergeManufacturer.id !== manufacturer.id,
                          ),
                        );
                      }
                    }}
                  />
                </td>
                <td>{manufacturer.name}</td>
                <td>{manufacturer.manufacturer_gln || '-'}</td>
                <td>{manufacturer.brand_count}</td>
                <td>{convertToTitleCase(manufacturer.verification_status)}</td>
                <td>
                  <Button
                    variant="link"
                    key={manufacturer?.id}
                    className={pointerOnHover}
                    onClick={e => {
                      e.stopPropagation();
                      setManufacturerEditModalSettings({ show: true, manufacturer: manufacturer });
                    }}
                  >
                    Edit
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    key={manufacturer?.id}
                    className={pointerOnHover}
                    onClick={() =>
                      window.open(
                        `/enrich-products/manufacturer-details/${manufacturer.id}`,
                        '_blank',
                      )
                    }
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No similar manufacturers found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

ManageDuplicateManufacturersTable.propTypes = {
  manufacturers: PropTypes.array,
  mergeList: PropTypes.array,
  setMergeList: PropTypes.func,
  setManufacturerEditModalSettings: PropTypes.func,
};

export default ManageDuplicateManufacturersTable;
