import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';

import { ATTRIBUTE_VALUE_MERGE } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function MergeAttributeValueModal({ selected_attribute_value_id, attribute_values, onHide }) {
  const [selectedValue, setSelectedValue] = useState();

  const dropDownOptions = useMemo(() => {
    return attribute_values
      .map(val =>
        val.id !== selected_attribute_value_id
          ? { label: val.attribute_value, value: val.id }
          : null,
      )
      .filter(option => option !== null);
  }, [selected_attribute_value_id, attribute_values]);

  const { postData: mergeAttributeValues } = usePost(
    ATTRIBUTE_VALUE_MERGE,
    () => {
      SwalDialog('success', 'Started the merging process', 'Success', 'center');
      onHide();
    },
    error => {
      SwalDialog(
        'error',
        error?.response?.data?.message,
        'An error occurred while merging attribute values',
        'center',
      );
    },
  );

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Merge Attribute Value Into (Parent)</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Select
          className="mt-3"
          value={selectedValue}
          options={dropDownOptions}
          onChange={e => setSelectedValue(e)}
          isSearchable
          isClearable
        />
        <div className="mt-3">
          <Button
            disabled={!selectedValue}
            variant="outline-primary"
            className="mr-2"
            onClick={() =>
              mergeAttributeValues({
                parent_attribute_value_id: selectedValue.value,
                attribute_value_id: selected_attribute_value_id,
              })
            }
          >
            Merge Values
          </Button>
          <Button variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

MergeAttributeValueModal.propTypes = {
  selected_attribute_value_id: PropTypes.number,
  attribute_values: PropTypes.array,
  onHide: PropTypes.func,
};

export default MergeAttributeValueModal;
