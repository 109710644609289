// update along with app/lib/enums/nutritional_panel_input_types.py
const NUTRITIONAL_PANEL_INPUT_TYPES = {
  CSV: 'CSV',
  IMAGES: 'IMAGES',
};

const NUTRITIONAL_PANEL_INPUT_TYPE_OPTIONS = [
  { value: NUTRITIONAL_PANEL_INPUT_TYPES.CSV, label: 'CSV' },
  { value: NUTRITIONAL_PANEL_INPUT_TYPES.IMAGES, label: 'IMAGES' },
];

export default NUTRITIONAL_PANEL_INPUT_TYPES;
export { NUTRITIONAL_PANEL_INPUT_TYPE_OPTIONS };
