import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { anchovy, colorStyles, squidInk } from 'lib/css/colors';

function BrandDetailsSection({ brand = {}, manufacturer = {}, onBrandClick, onManufacturerClick }) {
  const isBrandClickable = brand.id && brand.name && onBrandClick;
  const isManufacturerClickable = manufacturer.id && manufacturer.name && onManufacturerClick;
  const isSameName = brand.name === manufacturer.name;

  return (
    <div
      className={classNames(
        'd-flex align-items-center flex-wrap',
        css(colorStyles.auroMetalSaurus),
      )}
    >
      {(isBrandClickable || isManufacturerClickable) && (
        <div className="mr-1">{'See more from'}</div>
      )}
      {brand.name && (
        <>
          {!isBrandClickable ? (
            <div>{brand.name}</div>
          ) : (
            <Button
              variant="link"
              className={classNames(css(styles.link), 'px-0')}
              target="_blank"
              onClick={() => onManufacturerClick?.()}
            >
              {brand.name}
            </Button>
          )}
        </>
      )}
      {brand.name && manufacturer.name && !isSameName && <div className="mx-1">{' by '}</div>}
      {manufacturer.name && !isSameName && (
        <>
          {!isManufacturerClickable ? (
            <div>{manufacturer.name}</div>
          ) : (
            <Button
              variant="link"
              className={classNames(css(styles.link), 'px-0')}
              target="_blank"
              onClick={() => onManufacturerClick?.()}
            >
              <div>{manufacturer.name}</div>
            </Button>
          )}
        </>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  link: {
    textDecoration: 'underline',
    textDecorationColor: anchovy,
    color: anchovy,
    ':hover': {
      color: squidInk,
      textDecoration: 'none',
    },
  },
});

BrandDetailsSection.propTypes = {
  brand: PropTypes.object,
  manufacturer: PropTypes.object,
  onBrandClick: PropTypes.func,
  onManufacturerClick: PropTypes.func,
};

export default BrandDetailsSection;
