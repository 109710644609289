import axios from 'axios';

import SERVER from 'lib/networking/endpoints';

const client = axios.create({ withCredentials: true, baseURL: SERVER });

const httpGet = (URL, headers = {}) => {
  return client.get(URL, { headers });
};

const httpPost = (URL, data, headers = {}) => {
  return client.post(URL, data, { headers });
};

const httpPut = (URL, data, headers = {}) => {
  return client.put(URL, data, { headers });
};

const httpDelete = (URL, headers = {}) => {
  return client.delete(URL, { headers });
};

export { httpGet, httpPost, httpPut, httpDelete };
