import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import Select from 'react-select/creatable';
import ToggleSwitch from 'react-switch';

import { ATTRIBUTE_INPUT_TYPE_LIST } from 'lib/enums/AttributeInputType';
import { AttributePriorityLevel, AttributePriorityOptions } from 'lib/enums/AttributePriorityLevel';
import {
  ATTRIBUTES,
  ATTRIBUTES_SUB_SECTIONS_FOR_SECTION,
  ATTRIBUTES_SECTIONS,
  getURL,
  SCHEMAS,
} from 'lib/networking/endpoints';
import { AttributeType } from 'lib/enums/AttributeType';

import { blackberry, chartreuse } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';

// This modal is used to create new global/non-global attributes as well as to create
// attribute maps along with new/existing attributes
function NewAttributeModal({
  onHide,
  modalHeader,
  createAttribute,
  isGlobal = true,
  // we show only the Mandatory, Visibility, Key Attribute fields when creating a global attribute
  //or when creating an attribute map record
  isCreatingAttributeMap = true,
}) {
  const [newAttribute, setNewAttribute] = useState({
    isMandatory: false,
    isVisible: true,
    isAKeyAttribute: false,
    priorityLevel: AttributePriorityOptions.find(
      op => op.value === AttributePriorityLevel.PRIORITY_3,
    ),
    section: {},
    subSection: {},
    attributeNameDetails: {},
    inputType: {},
    USDACode: null,
  });

  const [disableEditing, setDisableEditing] = useState(false);

  const { data: { data: sections = [] } = [], sectionsLoading } = useGet(ATTRIBUTES_SECTIONS, {});
  const { data: { data: schemas = [] } = [] } = useGet(SCHEMAS);

  const { data, attributesLoading } = useGet(
    ATTRIBUTES,
    { attribute_type: AttributeType.NON_GLOBAL_ATTRIBUTES },
    isGlobal === true,
  );

  const { data: { data: subSections = [] } = [] } = useGet(
    getURL(ATTRIBUTES_SUB_SECTIONS_FOR_SECTION, {
      attribute_section_id: newAttribute?.section?.value,
    }),
    {},
    !newAttribute.section,
  );

  // we need to show the existing attributes only for non global attributes
  // since we assign those only to categories
  const attributeOptions = useMemo(() => {
    if (!isEmpty(data) && !isGlobal) {
      return data.attributes?.map(attr => ({ label: attr.attribute_name, value: attr.id }));
    }
  }, [data, isGlobal]);

  if (sectionsLoading || attributesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-3 font-weight-bold" centered>
      <Modal.Header className="my-0 pt-1 pb-0">
        <h5 className="px-1 my-2 font-weight-bold">{modalHeader}</h5>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form.Group>
          <Form.Label>Name</Form.Label>
          {isGlobal ? (
            <Form.Control
              type="text"
              placeholder="New Attribute Name"
              value={newAttribute.attributeNameDetails.label || ''}
              onChange={e =>
                setNewAttribute({
                  ...newAttribute,
                  attributeNameDetails: { label: e.target.value, value: e.target.value },
                })
              }
            />
          ) : (
            <Select
              value={newAttribute.attributeNameDetails || ''}
              options={attributeOptions}
              onChange={option => {
                // if the selected one is from the existing then get the relevant details of
                // the attribute
                if (option && !option?.__isNew__) {
                  const attr = data.attributes?.find(v => v.id === option.value);
                  setNewAttribute({
                    ...newAttribute,
                    attributeNameDetails: option,
                    section: {
                      label: attr.attribute_sub_section.attribute_section.attribute_section_name,
                      value: attr.attribute_sub_section.attribute_section.id,
                    },
                    subSection: {
                      label: attr.attribute_sub_section.attribute_sub_section_name,
                      value: attr.attribute_sub_section.id,
                    },
                  });
                  setDisableEditing(true);
                } else {
                  setNewAttribute({
                    ...newAttribute,
                    attributeNameDetails: option,
                    section: {},
                    subSection: {},
                  });
                  setDisableEditing(false);
                }
              }}
              isSearchable
              isClearable
            />
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Display Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="New Attribute Display Name"
            value={newAttribute.attributeDisplayName || ''}
            onChange={e =>
              setNewAttribute({ ...newAttribute, attributeDisplayName: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Universal Specific Field</Form.Label>
          <Form.Control
            type="text"
            placeholder="New Attribute Universal Specific Field"
            value={newAttribute.universalSpecificField || ''}
            onChange={e =>
              setNewAttribute({ ...newAttribute, universalSpecificField: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Attribute Id</Form.Label>
          <Form.Control
            type="text"
            placeholder="New Attribute Id"
            value={newAttribute.fsaAttributeId || ''}
            onChange={e => setNewAttribute({ ...newAttribute, fsaAttributeId: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>USDA Codee</Form.Label>
          <Form.Control
            type="text"
            placeholder="USDA Code"
            value={newAttribute.USDACode || ''}
            onChange={e => setNewAttribute({ ...newAttribute, USDACode: e.target.value })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Select
            value={newAttribute.inputType || {}}
            options={ATTRIBUTE_INPUT_TYPE_LIST}
            onChange={e => setNewAttribute({ ...newAttribute, inputType: e })}
            isSearchable
            isClearable
            isDisabled={disableEditing}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Select
            value={newAttribute.section || {}}
            options={sections.map(section => ({
              label: section.attribute_section_name,
              value: section.id,
            }))}
            onChange={e => setNewAttribute({ ...newAttribute, section: e })}
            isSearchable
            isClearable
            isDisabled={disableEditing}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sub Category</Form.Label>
          <Select
            value={newAttribute.subSection || {}}
            options={subSections.map(subSection => ({
              label: subSection.attribute_sub_section_name,
              value: subSection.id,
            }))}
            onChange={e => setNewAttribute({ ...newAttribute, subSection: e })}
            isSearchable
            isClearable
            isDisabled={disableEditing}
          />
        </Form.Group>
        {isCreatingAttributeMap === true ? (
          <>
            <Form.Group>
              <Form.Label>Priority Level</Form.Label>
              <Select
                value={newAttribute.priorityLevel}
                options={AttributePriorityOptions}
                onChange={e => setNewAttribute({ ...newAttribute, priorityLevel: e })}
                isSearchable={false}
                isClearable={false}
                isDisabled={disableEditing}
              />
            </Form.Group>
            <Row>
              <Col sm={4}>
                <Form.Label>Key Attribute</Form.Label>
              </Col>
              <Col align="left">
                <ToggleSwitch
                  onColor={chartreuse}
                  offColor={blackberry}
                  checked={newAttribute.isAKeyAttribute || false}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() =>
                    setNewAttribute({
                      ...newAttribute,
                      isAKeyAttribute: !newAttribute.isAKeyAttribute,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        ) : null}
        <Row>
          <Col sm={4}>
            <Form.Label>Mandatory</Form.Label>
          </Col>
          <Col align="left">
            <ToggleSwitch
              onColor={chartreuse}
              offColor={blackberry}
              checked={newAttribute.isMandatory || false}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() =>
                setNewAttribute({ ...newAttribute, isMandatory: !newAttribute.isMandatory })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form.Label>Visibility</Form.Label>
          </Col>
          <Col align="left">
            <ToggleSwitch
              onColor={chartreuse}
              offColor={blackberry}
              checked={newAttribute.isVisible}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() =>
                setNewAttribute({ ...newAttribute, isVisible: !newAttribute.isVisible })
              }
            />
          </Col>
        </Row>
        <hr />
        <Form.Group className="mt-2">
          <Form.Label>Select schemas to assign</Form.Label>
          <Select
            value={newAttribute.schemas}
            options={schemas.map(schema => ({
              label: schema.schema_name,
              value: schema.id,
            }))}
            onChange={e => setNewAttribute({ ...newAttribute, schemas: e })}
            isSearchable
            isClearable
            isMulti
          />
        </Form.Group>
        <Row className="pt-3 mt-3">
          <Col sm={3}>
            <Button
              block
              onClick={() => {
                if (!newAttribute.attributeNameDetails?.label) {
                  SwalDialog('error', 'Missing Attribute Name', 'Error', 'center');
                } else if (!newAttribute.subSection?.value) {
                  SwalDialog('error', 'Missing Sub Section', 'Error', 'center');
                } else {
                  createAttribute({
                    newAttribute: newAttribute.attributeNameDetails.label,
                    attributeId: newAttribute.attributeNameDetails.value,
                    attributeDisplayName: newAttribute.attributeDisplayName,
                    isMandatory: newAttribute.isMandatory,
                    priorityLevel: newAttribute.priorityLevel,
                    isVisible: newAttribute.isVisible,
                    keyAttribute: newAttribute.isAKeyAttribute,
                    inputType: newAttribute.inputType.label,
                    attributeSection: newAttribute.section,
                    attributeSubSection: newAttribute.subSection,
                    universalSpecificField: newAttribute.universalSpecificField,
                    fsaAttributeId: newAttribute.fsaAttributeId,
                    USDACode: newAttribute.USDACode,
                    schemas: newAttribute.schemas,
                  });
                }
              }}
            >
              Save
            </Button>
          </Col>
          <Col sm={2} className="pl-0">
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

NewAttributeModal.propTypes = {
  onHide: PropTypes.func,
  modalHeader: PropTypes.string,
  createAttribute: PropTypes.func,
  isGlobal: PropTypes.bool,
  isCreatingAttributeMap: PropTypes.bool,
};

export default NewAttributeModal;
