import React from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

const hours = Array.from({ length: 24 }, (_, i) => i);
const minutes = Array.from({ length: 60 }, (_, i) => i);

function TimeSelectorDropdown({ rate, setHour, setMinute }) {
  return (
    <Form.Group as={Row}>
      <Form.Label column>Recurring Rate Expression</Form.Label>
      <Col md={4}>
        <Select
          value={{ label: rate.hour, value: rate.hour } || ''}
          options={hours.map(h => ({ label: h, value: h }))}
          placeholder="select hour"
          isClearable
          onChange={op => setHour(op?.value)}
        />
      </Col>
      <Col md={3}>
        <Select
          value={{ label: rate.minute, value: rate.minute } || ''}
          options={minutes.map(m => ({ label: m, value: m }))}
          placeholder="select minute"
          isClearable
          onChange={op => setMinute(op?.value)}
        />
      </Col>
    </Form.Group>
  );
}

TimeSelectorDropdown.propTypes = {
  rate: PropTypes.shape({
    hour: PropTypes.string || null,
    minute: PropTypes.string || null,
  }).isRequired,
  setHour: PropTypes.func.isRequired,
  setMinute: PropTypes.func.isRequired,
};

export default TimeSelectorDropdown;
