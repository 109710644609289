import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import { OTHER_IMAGES } from 'lib/networking/endpoints';
import ImagesSection from 'components/product_lists/product_list/qa_product_list/ImagesSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function OtherImagesSection({
  productId,
  productType,
  selectedThumbnailUrl,
  setSelectedThumbnailId,
  setSelectedThumbnailUrl,
}) {
  const {
    data: { other_images = [] } = {},
    loading,
    error,
  } = useGet(OTHER_IMAGES, {
    product_id: productId,
    product_type: productType,
  });

  if (loading) {
    return <LoadingSpinner short />;
  }

  if (error) {
    return null;
  }

  const handleImage = image => {
    setSelectedThumbnailId(image?.id);
    setSelectedThumbnailUrl(image?.url);
  };

  return (
    <div>
      <div className={css(styles.recommendationsContainer)}>
        <ImagesSection
          images={other_images}
          onImageClick={image => handleImage(image)}
          isImageSelected={imageUrl => selectedThumbnailUrl === imageUrl}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  recommendationsContainer: {
    height: '350px',
    overflowY: 'auto',
  },
});

OtherImagesSection.propTypes = {
  productId: PropTypes.number,
  productType: PropTypes.string,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailId: PropTypes.func,
  setSelectedThumbnailUrl: PropTypes.func,
};

export default OtherImagesSection;
