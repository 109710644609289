import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Button } from 'react-bootstrap';

function VideoUrlEditor({
  label,
  brand,
  updatedBrand,
  setUpdatedBrand,
  updateBrand,
  setIsEditing,
}) {
  const [selectedUrls, setSelectedUrls] = useState([]);
  const [newVideoUrl, setNewVideoUrl] = useState('');
  useEffect(() => {
    if (updatedBrand.video_urls) {
      updateBrand(updatedBrand);
    }
    // eslint-disable-next-line
  }, [updatedBrand]);

  const handleVideoUrlUpdate = (property, value) => {
    const updatedVideoUrls = brand.video_urls ? [...brand.video_urls, value] : [value];
    setUpdatedBrand(prevBrand => ({
      ...prevBrand,
      [property]: updatedVideoUrls !== undefined ? updatedVideoUrls : null,
    }));
    setNewVideoUrl('');
  };
  const handleUrlSelect = url => {
    setSelectedUrls(prevSelected =>
      prevSelected.includes(url)
        ? prevSelected.filter(selectedUrl => selectedUrl !== url)
        : [...prevSelected, url],
    );
  };
  const handleDeleteSelected = () => {
    const updatedVideoUrls = brand.video_urls.filter(url => !selectedUrls.includes(url));
    setUpdatedBrand(prevBrand => ({
      ...prevBrand,
      [label]: updatedVideoUrls,
    }));
    setSelectedUrls([]);
  };
  return (
    <div>
      {brand.video_urls && brand.video_urls.length > 0 ? (
        <ul>
          {brand.video_urls.map((url, index) => (
            <li key={index}>
              <Form.Check
                type="checkbox"
                label={url}
                checked={selectedUrls.includes(url)}
                onChange={() => handleUrlSelect(url)}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p>No video URLs available.</p>
      )}

      <Form.Control
        as="input"
        className="mr-2 mb-2"
        rows={10}
        style={{ height: 'auto' }}
        autoFocus
        value={newVideoUrl}
        placeholder="Add new video URL"
        onChange={event => setNewVideoUrl(event.target.value)}
      />
      <Button
        className="mr-2"
        onClick={() => handleVideoUrlUpdate(label, newVideoUrl)}
        disabled={!newVideoUrl.trim()}
      >
        Add Video URL
      </Button>
      <Button className="mr-2" onClick={() => setIsEditing(false)}>
        Cancel
      </Button>

      {selectedUrls.length > 0 && (
        <Button variant="danger" onClick={handleDeleteSelected} className="ml-2">
          Delete Selected URLs
        </Button>
      )}
    </div>
  );
}

VideoUrlEditor.propTypes = {
  label: PropTypes.string.isRequired,
  brand: PropTypes.object.isRequired,
  setUpdatedBrand: PropTypes.func.isRequired,
  updatedBrand: PropTypes.object,
  updateBrand: PropTypes.func,
  setIsEditing: PropTypes.func,
};

export default VideoUrlEditor;
