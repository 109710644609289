import React from 'react';
import PropTypes from 'prop-types';

import { Col, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import DOMPurify from 'dompurify';

import ShowMore from 'components/shared/ShowMore';

function PurveyorBrandSection({ productBrand = {} }) {
  return (
    <div>
      {productBrand.description && (
        <Row>
          {productBrand.logoUrl ? (
            <>
              <Col
                md={12}
                lg={5}
                xl={3}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <Image src={productBrand.logoUrl} fluid className={css(styles.brandImg)} />
                </div>
              </Col>
              <Col md={12} lg={7} xl={9}>
                <ShowMore max={180}>
                  <div
                    className={css(styles.preserveFormat)}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(productBrand.description || ''),
                    }}
                  />
                </ShowMore>
              </Col>
            </>
          ) : (
            <Col>
              <ShowMore max={180}>
                <div
                  className={css(styles.preserveFormat)}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(productBrand.description || ''),
                  }}
                />
              </ShowMore>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  preserveFormat: {
    whiteSpace: 'pre-wrap',
    textAlign: 'justify',
  },
  brandImg: {
    objectFit: 'contain',
    maxHeight: 150,
    minHeight: 70,
  },
});

PurveyorBrandSection.propTypes = {
  productBrand: PropTypes.object,
};

export default PurveyorBrandSection;
