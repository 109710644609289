import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import DropZone from 'components/shared/Dropzone';

function UploadImagesSection({ imageUrls, setImageUrls, setSelectedThumbnailUrl }) {
  const handleUpload = newUrls => {
    setImageUrls(newUrls);
    setSelectedThumbnailUrl(newUrls?.[0]);
  };
  return (
    <div>
      <DropZone
        text="Drag and drop images here, or click to select images"
        onUploadSuccess={newUrls => handleUpload(newUrls)}
        fileUrls={imageUrls}
        isImage={true}
        isPublic={true}
      />
      <div className="mt-3 d-flex justify-content-center align-items-center">
        <Button variant="outline-primary" onClick={() => setImageUrls([])}>
          Clear Images
        </Button>
      </div>
      <p className="small text-muted">
        * Upload just one image to make it default L4 category image
      </p>
    </div>
  );
}

UploadImagesSection.propTypes = {
  imageUrls: PropTypes.array,
  setImageUrls: PropTypes.func,
  setSelectedThumbnailUrl: PropTypes.func,
};

export default UploadImagesSection;
