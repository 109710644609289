import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import { backgroundGrey4 } from 'lib/css/colors';
import ManufacturerRow from 'components/manage_manufacturers/manufacturer_details/ManufacturerRow';

const manufacturerRows = {
  name: 'Manufacturer Name',
  manufacturer_url: 'Manufacturer Website',
  manufacturer_gln: 'Manufacturer GLN',
  description: 'Description',
  is_partner: 'Is a manufacturer partner',
  upb_data_provider: 'Is a UPB data provider',
  requires_manufacturer_portal: 'Creation of manufacturer portal is required',
  similar_manufacturers: 'Similar Manufacturers',
};

function ManufacturerDetailView({ manufacturer, updateManufacturer, refetch }) {
  return (
    <div className="px-4 mt-3">
      {Object.keys(manufacturerRows).map(key => (
        <div key={key}>
          <ManufacturerRow
            label={key}
            title={manufacturerRows[key]}
            manufacturer={manufacturer}
            updateManufacturer={updateManufacturer}
            refetch={refetch}
          />
          <hr className={css(styles.separator)} />
        </div>
      ))}
    </div>
  );
}

const styles = StyleSheet.create({
  separator: {
    color: backgroundGrey4,
    backgroundColor: backgroundGrey4,
    margin: '6px 0',
  },
});

ManufacturerDetailView.propTypes = {
  manufacturer: PropTypes.object,
  updateManufacturer: PropTypes.func,
  refetch: PropTypes.func,
};

export default ManufacturerDetailView;
export { manufacturerRows };
