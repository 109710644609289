import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import { fontStyles } from 'components/shared/nutrition_facts/sharedStyles';
import { NUTRITION_ATTRIBUTES } from 'components/upb_viewer/pdp_components/ProductNutritionFactsView';
import { updateAttributeValueByName } from 'components/nutritional_panels/ProductNutritionFactsForEdit';

function ServingsSection({
  servingsPerPackage,
  description,
  quantity,
  uom,
  setNutritionAttributes,
}) {
  const handleChange = (attributeName, newValue) => {
    updateAttributeValueByName(setNutritionAttributes, attributeName, newValue);
  };

  return (
    <div className="py-1">
      {/* Servings per container */}
      <div className="d-flex align-items-center mb-2">
        <input
          id="servingsPerPackage"
          type="text"
          value={servingsPerPackage || ''}
          className={`form-control form-control-sm mr-1 ${css(styles.input)}`}
          onChange={e =>
            handleChange(NUTRITION_ATTRIBUTES.NUMBER_OF_SERVINGS_PER_PACKAGE, e.target.value)
          }
        />
        <span className={fontStyles('20px')}>servings per container</span>
      </div>

      {/* Serving size */}
      <div className="w-100 d-flex justify-content-between align-items-center">
        <span className={fontStyles('20px', true)}>Serving size</span>
        <div className="d-flex gap-2">
          <input
            type="text"
            value={description || ''}
            className={`form-control form-control-sm mr-1 ${css(styles.input)}`}
            placeholder="Description"
            onChange={e =>
              handleChange(NUTRITION_ATTRIBUTES.SERVING_SIZE_DESCRIPTION, e.target.value)
            }
          />
          <input
            type="text"
            value={quantity || ''}
            className={`form-control form-control-sm mr-1 ${css(styles.input)}`}
            placeholder="Quantity"
            onChange={e => handleChange(NUTRITION_ATTRIBUTES.SERVING_SIZE_QUANTITY, e.target.value)}
          />
          <input
            type="text"
            value={uom || ''}
            className={`form-control form-control-sm ${css(styles.input)}`}
            placeholder="Uom"
            onChange={e => handleChange(NUTRITION_ATTRIBUTES.SERVING_SIZE_UOM, e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  input: {
    width: '60px',
    textAlign: 'center',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
});

ServingsSection.propTypes = {
  servingsPerPackage: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.string,
  uom: PropTypes.string,
  setNutritionAttributes: PropTypes.func,
};

export default ServingsSection;
