import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStyles } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import { sectionHeaderClass } from 'components/upb_viewer/css/sharedStyles';

function AttributeSectionHeader({
  title,
  isExpanded,
  setIsExpanded,
  headerButtons,
  hideHeaderButtons = false,
}) {
  return (
    <div
      className={classNames(colorStyles.coolGrey, 'd-flex', pointerOnHover)}
      onClick={() => setIsExpanded(expanded => !expanded)}
    >
      <div className={sectionHeaderClass}>{title}</div>
      {headerButtons && !hideHeaderButtons && headerButtons}
      <div className="ml-auto align-self-center mr-2">
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
      </div>
    </div>
  );
}

AttributeSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  headerButtons: PropTypes.node,
  hideHeaderButtons: PropTypes.bool,
};

export default AttributeSectionHeader;
