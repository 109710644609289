import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';
import ToggleSwitch from 'react-switch';

import { blackberry, chartreuse, white } from 'lib/css/colors';
import { ROLE } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import UserPermission from 'lib/enums/UserPermission';

function EditRolesModal({ show, onHide, roles, rolesOptions, refetch }) {
  const [selectedRole, setSelectedRole] = useState();

  const { postData: createOrUpdateRole, loading: updating } = usePost(
    ROLE,
    () => {
      refetch();
      SwalDialog('success', 'Role created/updated successfully', 'Success', 'center');
      onHide();
    },
    () => {
      SwalDialog('error', 'An error occurred while creating/updating role', 'Error', 'center');
    },
  );

  const onToggle = (permission, checked) => {
    const rolePermissions = selectedRole.role_permissions;
    if (checked && !rolePermissions.includes(permission)) {
      rolePermissions.push(permission);
      setSelectedRole({
        ...selectedRole,
        role_permissions: rolePermissions,
      });
    } else if (!checked && rolePermissions.includes(permission)) {
      setSelectedRole({
        ...selectedRole,
        role_permissions: rolePermissions.filter(perm => perm !== permission),
      });
    }
  };

  const handleHide = () => {
    setSelectedRole(null);
    onHide();
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? blackberry : white,
      zIndex: 2,
      '&:hover': {
        backgroundColor: white,
      },
    }),
  };

  return (
    <Modal show={show} onHide={handleHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title className="font-weight-bold">Manage User Roles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label className="mt-2 font-weight-bold">
            Select role or create a new role
          </Form.Label>
          <Select
            styles={customStyles}
            classNamePrefix="themed_select"
            value={rolesOptions.find(option => option.value === selectedRole?.id)}
            options={rolesOptions}
            onChange={option => {
              let r;
              if (option.__isNew__) {
                r = { name: option.value, role_permissions: [] };
              } else {
                r = roles.find(r => r.name === option.value);
              }
              setSelectedRole(r);
            }}
          />
        </Form.Group>
        <hr />
        <div className={css(styles.permissions)}>
          {Object.values(UserPermission).map(permission => {
            return (
              <div
                key={permission}
                className="d-flex justify-content-between py-1 pr-2 pl-1 border-bottom"
              >
                <span>{permission}</span>
                <ToggleSwitch
                  onColor={chartreuse}
                  offColor={blackberry}
                  onChange={e => onToggle(permission, e)}
                  checked={selectedRole?.role_permissions.includes(permission)}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 2px rgba(0, 0, 0, 0.2)"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  height={20}
                  width={38}
                  handleDiameter={19}
                  disabled={!selectedRole || updating}
                />
              </div>
            );
          })}
        </div>
        <div className="d-flex mt-3 justify-content-left">
          <Button
            className="px-4"
            disabled={!selectedRole}
            onClick={() => {
              if (selectedRole) {
                createOrUpdateRole({
                  name: selectedRole.name,
                  permissions: selectedRole.role_permissions,
                });
              }
            }}
          >
            Save
          </Button>
          <Button variant="outline-primary" className="px-4 ml-2" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  permissions: {
    maxHeight: '50vh',
    overflowY: 'scroll',
    fontWeight: 'bold',
  },
});

EditRolesModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  roles: PropTypes.array,
  rolesOptions: PropTypes.array,
  refetch: PropTypes.func,
};

export default EditRolesModal;
