import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
  ATTRIBUTES_MAP,
  ATTRIBUTES_MAPS,
  ATTRIBUTES_MAP_DETAILS,
  ATTRIBUTES_MAPS_DETAILS,
} from 'lib/networking/endpoints';
import BroadlinerComplianceModal from 'components/manage_attribute/BroadlinerComplianceModal';
import DropDownMenu from 'components/manage_attribute/AttributeView/DropDownMenu';
import EditAttributeModal from 'components/manage_attribute/EditAttributeModal';
import { ManageAttributeContext } from 'components/manage_attribute/ManageAttributeContainer';
import SwalDialog from 'lib/utils/toast';
import useDelete from 'lib/hooks/useDelete';
import usePost from 'lib/hooks/usePost';

function AttributeRows({ attributes = [], refetch }) {
  const [showBroadlinerComplianceModal, setShowBroadlinerComplianceModal] = useState(false);
  const [showEditAttributeModal, setShowEditAttributeModal] = useState(false);
  const [attributeToEdit, setAttributeToEdit] = useState({});
  const { categoryLevel, selectedCategories } = useContext(ManageAttributeContext);

  // for single category
  const { postData: createOrUpdateAttributeMap } = usePost(
    ATTRIBUTES_MAP,
    () => {
      SwalDialog('success', 'Attribute created/updated successfully', 'Success', 'center');
      refetch();
      setShowEditAttributeModal(false);
    },
    () => {
      SwalDialog('error', 'An error occurred', 'Error', 'center');
    },
  );

  const { deleteData: deleteAttributeMap } = useDelete(
    ATTRIBUTES_MAP_DETAILS,
    false,
    () => {
      SwalDialog('success', 'Successfully removed mapping', 'Success', 'center', () => {});
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  // for multi category
  const { postData: createOrUpdateAttributeMaps } = usePost(
    ATTRIBUTES_MAPS,
    () => {
      SwalDialog('success', 'Attributes created/updated successfully', 'Success', 'center');
      refetch();
      setShowEditAttributeModal(false);
    },
    () => {
      SwalDialog('error', 'An error occurred', 'Error', 'center');
    },
  );

  const { deleteData: deleteAttributeMaps } = useDelete(
    ATTRIBUTES_MAPS_DETAILS,
    false,
    () => {
      SwalDialog('success', 'Successfully removed mapping', 'Success', 'center', () => {});
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  const deleteAttribute = data => {
    if (!data) {
      return;
    }
    if (data.attribute_map_ids) {
      deleteAttributeMaps({ attribute_map_ids: data.attribute_map_ids });
    } else {
      deleteAttributeMap({ attribute_map_id: data.id });
    }
    refetch();
  };

  const updateAttribute = data => {
    const reqBody = {
      attribute_display_name: data.attributeDisplayName,
      attribute_id: attributeToEdit.attributes.id,
      attribute_name: data.attributeName,
      attribute_sub_section_id: data.attributeSubSectionId.value,
      attribute_sub_section_name: data.attributeSubSectionId.label,
      category_level: categoryLevel.value,
      mandatory: data.isMandatory,
      visibility: data.isVisible,
      key_attribute: data.keyAttribute,
      input_type: data.inputType || null,
      priority_level: data.priorityLevel,
      attribute_section_id: data.attributeSectionId.value,
      attribute_section_name: data.attributeSectionId.label,
    };
    if (selectedCategories.length > 1) {
      createOrUpdateAttributeMaps({
        ...reqBody,
        category_ids: selectedCategories.map(cat => cat.id),
      });
    } else {
      createOrUpdateAttributeMap({ ...reqBody, category_id: selectedCategories[0].id });
    }
  };

  return (
    <>
      {attributes.map(attribute => (
        <tr key={attribute.id}>
          {categoryLevel.label.includes(attribute.category_level?.toString()) ? (
            <td>
              <DropDownMenu
                attributeName={attribute.attributes.attribute_name}
                editAttribute={() => {
                  setAttributeToEdit(attribute);
                  setShowEditAttributeModal(true);
                }}
                broadlinerCompliance={() => {
                  setAttributeToEdit(attribute);
                  setShowBroadlinerComplianceModal(true);
                }}
                deleteAttribute={() => deleteAttribute(attribute)}
                showBroadlinerCompliance={selectedCategories.length === 1}
              />
            </td>
          ) : (
            <td className="pl-4">{attribute.attributes.attribute_name}</td>
          )}
          <td>{attribute.attributes.input_type}</td>
          <td>
            {attribute.attributes.attribute_sub_section.attribute_section.attribute_section_name}
          </td>
          <td>{attribute.attributes.attribute_sub_section.attribute_sub_section_name}</td>
          <td>L{attribute.category_level}</td>
        </tr>
      ))}
      {showEditAttributeModal ? (
        <EditAttributeModal
          onHide={() => setShowEditAttributeModal(false)}
          modalHeader={
            attributeToEdit.attribute_map_ids
              ? 'Edit Attribute For Multi Categories'
              : 'Edit Attribute'
          }
          updateAttribute={updateAttribute}
          details={{
            attributeName: attributeToEdit.attributes.attribute_name,
            attributeDisplayName: attributeToEdit.attributes.attribute_display_name,
            isMandatory: attributeToEdit.mandatory,
            isVisible: attributeToEdit.attributes.visibility,
            isAKeyAttribute: attributeToEdit.key_attribute,
            inputType: attributeToEdit.attributes.input_type,
            priorityLevel: attributeToEdit.priority_level,
            sectionId: attributeToEdit.attributes.attribute_sub_section.attribute_section.id,
            sectionName:
              attributeToEdit.attributes.attribute_sub_section.attribute_section
                .attribute_section_name,
            subSectionId: attributeToEdit.attributes.attribute_sub_section.id,
            subSectionName:
              attributeToEdit.attributes.attribute_sub_section.attribute_sub_section_name,
          }}
        />
      ) : null}
      {showBroadlinerComplianceModal ? (
        <BroadlinerComplianceModal
          onHide={() => setShowBroadlinerComplianceModal(false)}
          attribute={attributeToEdit}
        />
      ) : null}
    </>
  );
}

AttributeRows.propTypes = {
  attributes: PropTypes.array,
  refetch: PropTypes.func,
};

export default AttributeRows;
