import React, { useState } from 'react';

import { Button, Col, Row, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ATTRIBUTE_VALUE_MAPS } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import ManageAttributeValueMapTableRow from 'components/manage_attribute_value_maps/ManageAttributeValueMapTableRow';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';

function ManageAttributeValueAlternatives() {
  const [newRow, setNewRow] = useState(false);

  const {
    data: { value_group_records: data = [] } = {},
    loading,
    refetch,
  } = useGet(ATTRIBUTE_VALUE_MAPS);

  return (
    <Container>
      <Row>
        <Col sm={11}>
          <h3 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
            />
            Assign Attribute Value Alternatives
          </h3>
        </Col>
        <Col sm={1}>
          <Button className="float-right" onClick={() => setNewRow(!newRow)} block>
            New Row
          </Button>
        </Col>
      </Row>
      <div className="my-3">
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <Table hover striped>
            <thead>
              <tr>
                <th></th>
                <th>Attribute Value</th>
                <th></th>
                <th>Alternative Values</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {newRow && (
                <ManageAttributeValueMapTableRow
                  valueMapRecord={{}}
                  isNewRow={true}
                  setNewRow={setNewRow}
                  refetch={refetch}
                />
              )}
              {data.map((record, index) => (
                <ManageAttributeValueMapTableRow
                  key={index}
                  valueMapRecord={record}
                  refetch={refetch}
                />
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </Container>
  );
}

export default ManageAttributeValueAlternatives;
