import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

function Container({ children, className }) {
  return <div className={classNames(className, 'p-3')}>{children}</div>;
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Container;
