// update along with app/lib/enums/change_log_type.py
const CHANGE_LOG_TYPE = {
  ATTRIBUTE_CHANGE: 'ATTRIBUTE_CHANGE',
  PRODUCT_UPDATE: 'PRODUCT_UPDATE', // CLASSIFICATION_CHANGE, PRODUCT_UNLINKED
  PRODUCT_LINK_UPDATED: 'PRODUCT_LINK_UPDATED', // CLASSIFICATION_CHANGE, PRODUCT_UNLINKED
  PRODUCT_IMAGE_DELETED: 'PRODUCT_IMAGE_DELETED',
};

const CHANGE_LOG_TYPE_MAP = [
  { value: CHANGE_LOG_TYPE.ATTRIBUTE_CHANGE, label: 'Attribute Change' },
  { value: CHANGE_LOG_TYPE.PRODUCT_UPDATE, label: 'Product updates' },
  { value: CHANGE_LOG_TYPE.PRODUCT_LINK_UPDATED, label: 'Product link update' },
  { value: CHANGE_LOG_TYPE.PRODUCT_IMAGE_DELETED, label: 'Product image delete' },
];

export { CHANGE_LOG_TYPE, CHANGE_LOG_TYPE_MAP };
