const ProductActiveStatus = {
  Inactive: 'Inactive',
  Active: 'Active',
  Archived: 'Archived',
};

const ProductActiveStatusOptions = [
  { value: ProductActiveStatus.Inactive, label: 'Inactive' },
  { value: ProductActiveStatus.Active, label: 'Active' },
  { value: ProductActiveStatus.Archived, label: 'Archived' },
];

export { ProductActiveStatus, ProductActiveStatusOptions };
