import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { blackberry, white } from 'lib/css/colors';
import { Button, Form, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select/creatable';

import SwalDialog from 'lib/utils/toast';
import { USER_CREATE } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function AddUserModal({ show, onHide, rolesOptions }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState();

  const { postData: createUser } = usePost(
    USER_CREATE,
    () => {
      SwalDialog('success', 'User created successfully', 'Success', 'center', () =>
        window.location.reload(),
      );
    },
    () => {
      SwalDialog('error', 'Could not create the user with the given details', 'Error', 'center');
    },
  );

  const handleUserCreate = () => {
    if (!name || !email || !role) {
      SwalDialog('error', 'Please fill all fields', 'Error', 'center');
      return;
    }

    createUser({
      name: name,
      email: email,
      role: role,
    });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? blackberry : white,
      '&:hover': {
        color: white,
      },
    }),
  };

  return (
    <Modal show={show} onHide={onHide} size="md" className="lato" centered>
      <Modal.Header closeButton>
        <Modal.Title className={classNames(css(styles.heading))}>Add New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control value={name} onChange={e => setName(e.target.value)} type="text" />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Select
            styles={customStyles}
            classNamePrefix="themed_select"
            value={rolesOptions.find(option => option.value === role)}
            onChange={role => setRole(role.value)}
            options={rolesOptions}
          />
        </Form.Group>
        <div className="d-flex mt-3 justify-content-left">
          <Button
            className={classNames(css(styles.createButton), 'px-4')}
            onClick={() => handleUserCreate()}
          >
            Create User
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    fontFamily: 'Mont, sans-serif',
    fontSize: '1.5rem',
  },
  createButton: {
    backgroundColor: blackberry,
    borderColor: blackberry,
    color: white,
    fontWeight: 'bold',
  },
});

AddUserModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  user: PropTypes.object,
  rolesOptions: PropTypes.array,
};

export default AddUserModal;
