import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getURL,
  GLOBAL_MANUFACTURERS_GET_BY_NAME,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function ManufacturerFilter({ manufacturerName, setAttributeValue, isGlobalProductAttribute }) {
  const [initOption, setInitOption] = useState({});
  // initial load
  useEffect(() => {
    if (manufacturerName) {
      const url = getURL(GLOBAL_MANUFACTURERS_GET_BY_NAME, { manufacturer_name: manufacturerName });
      httpGet(url).then(res => {
        if (res.status === 200) {
          const manufacturer = res.data?.manufacturer;
          if (manufacturer) {
            setInitOption({ label: manufacturer.name, value: manufacturer.id });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchableScrollableSelect
      disabled={isGlobalProductAttribute}
      initOption={initOption}
      onChange={option => setAttributeValue(option?.label)}
      fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
      optionMapper={manufacturer => ({ label: manufacturer.name, value: manufacturer.id })}
    />
  );
}

ManufacturerFilter.propTypes = {
  manufacturerName: PropTypes.string,
  setAttributeValue: PropTypes.func,
  isGlobalProductAttribute: PropTypes.bool,
};

export default ManufacturerFilter;
