import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

function L4CategoryFilter({
  selectedL4CategoryId,
  setSelectedL4CategoryId,
  setSelectedL4CategoryName,
  parentLoading,
  l4Options,
}) {
  return (
    <Form.Group>
      <Form.Label>Select L4 Category</Form.Label>
      <Select
        key={selectedL4CategoryId}
        isClearable
        isDisabled={parentLoading}
        options={l4Options}
        value={l4Options?.find(option => option?.id === selectedL4CategoryId)}
        onChange={option => {
          setSelectedL4CategoryId(option?.id);
          setSelectedL4CategoryName(option?.label);
        }}
        placeholder={parentLoading ? 'Loading...' : 'Select L4 Category'}
      />
    </Form.Group>
  );
}

L4CategoryFilter.propTypes = {
  selectedL4CategoryId: PropTypes.number,
  setSelectedL4CategoryId: PropTypes.func,
  setSelectedL4CategoryName: PropTypes.func,
  parentLoading: PropTypes.bool,
  l4Options: PropTypes.array,
};

export default L4CategoryFilter;
