import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs from 'dayjs';

import { CLASSIFICATION_STATS } from 'lib/networking/endpoints';
import { lightPurple } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function ClassificationGraphView({ lookBackDays = 7 }) {
  const { data: { data = [] } = [], loading } = useGet(CLASSIFICATION_STATS, {
    last_n_days: lookBackDays,
  });

  const datapoints = useMemo(() => {
    return data.map(datapoint => ({
      count: datapoint.count,
      day: dayjs(datapoint.stat_date).format('MM/DD/YYYY'),
    }));
  }, [data]);

  if (loading) {
    return <LoadingSpinner short={true} />;
  }

  return (
    <div>
      <LineChart width={300} height={300} data={datapoints}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="day" tick={{ fontSize: 10 }} angle={-45} textAnchor="end" />
        <YAxis tick={{ fontSize: 10 }} />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="count" stroke={lightPurple} />
      </LineChart>
    </div>
  );
}

ClassificationGraphView.propTypes = {
  lookBackDays: PropTypes.number,
};

export default ClassificationGraphView;
