import React from 'react';
import PropTypes from 'prop-types';

import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { truncate } from 'lodash';

import { darkGrey2, grape } from 'lib/css/colors';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';

function ProductCatalogCard({ product, setQAModalSettings, isQAView = false }) {
  const productLink = `/global-product-detail/${product.global_product_id}/\
${product.product_id}/${product.product_type}`;
  return (
    <Card
      className="border-0 m-0 p-0"
      onClick={isQAView ? () => setQAModalSettings({ show: true, product: product }) : null}
    >
      <div className={classNames(css(styles.card), 'p-2 d-flex flex-column')}>
        <div
          className={classNames(
            css(styles.productImageContainer),
            'align-items-center justify-content-center d-flex',
          )}
        >
          {isQAView ? (
            <Card.Img
              className={css(styles.productImage)}
              variant="top"
              src={product.propagated_thumbnail_image_url ?? placeholderProductImage}
            />
          ) : (
            <LinkContainer className={pointerOnHover} to={productLink}>
              <Card.Img
                className={css(styles.productImage)}
                variant="top"
                src={product.propagated_thumbnail_image_url ?? placeholderProductImage}
              />
            </LinkContainer>
          )}
        </div>
        <Card.Body className="px-2 pt-2 pb-1 d-flex flex-column">
          <div className="text-muted">#{product.sku}</div>
          {isQAView ? (
            <div className={css(styles.displayName)}>
              <OverlayTrigger overlay={<Tooltip>{product.product_name}</Tooltip>}>
                <div>{truncate(product.product_name, { length: 70 })}</div>
              </OverlayTrigger>
            </div>
          ) : (
            <Link to={productLink} target="_blank" className={css(styles.displayName)}>
              <OverlayTrigger overlay={<Tooltip>{product.product_name}</Tooltip>}>
                <div>{truncate(product.product_name, { length: 70 })}</div>
              </OverlayTrigger>
            </Link>
          )}
          {isQAView ? (
            <div className="text-muted">
              {product.propagated_pack_size && <div>{product.propagated_pack_size}</div>}
              {product.propagated_brand && <div>{product.propagated_brand}</div>}
            </div>
          ) : (
            <div className="text-muted">
              {product.pack_size && <div>{product.pack_size}</div>}
              {product.brand && <div>{product.brand}</div>}
            </div>
          )}
        </Card.Body>
      </div>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    minWidth: 260,
    minHeight: 330,
    width: 260,
    height: '100%',
    border: `1px solid ${darkGrey2}`,
    borderRadius: 5,
    padding: 20,
    margin: '0px 10px 20px',
    ':hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
      borderColor: grape,
    },
  },
  productImageContainer: {
    position: 'relative',
    width: '100%',
    height: 210,
    overflow: 'hidden',
  },
  productImage: {
    ':hover': {
      opacity: 0.6,
    },
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  displayName: {
    textDecoration: 'none',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
});

ProductCatalogCard.propTypes = {
  product: PropTypes.object,
  setQAModalSettings: PropTypes.func,
  isQAView: PropTypes.bool,
};

export default ProductCatalogCard;
