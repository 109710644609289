import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import ProductListFilter from 'lib/enums/ProductListFilter';
import { ProductVerificationStatusOptions } from 'lib/enums/ProductVerificationStatus';

function VerificationStatusFilter({ selectedValues, updateFilters }) {
  return (
    <Form.Group>
      <Form.Label>Verification status</Form.Label>
      <Select
        isMulti
        isClearable
        options={ProductVerificationStatusOptions}
        value={selectedValues.map(opt => ({ label: opt, value: opt }))}
        onChange={opt =>
          updateFilters(
            ProductListFilter.VERIFICATION_STATUS,
            opt.map(option => option.value),
          )
        }
      />
    </Form.Group>
  );
}

VerificationStatusFilter.propTypes = {
  selectedValues: PropTypes.array,
  updateFilters: PropTypes.func,
};

export default VerificationStatusFilter;
