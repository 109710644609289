import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import {
  faSquare as faSolidSquare,
  faCircle as faSolidCircle,
  faCheckCircle,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cursorStyles } from 'lib/css/pointerOnHover';

import { colorStyles } from 'lib/css/colors';

function RadioButton({
  box,
  value = null,
  checked,
  className,
  disabled = false,
  label,
  onClick = () => {},
  small = false,
}) {
  const icon = disabled
    ? checked
      ? box
        ? faCheckSquare
        : faCheckCircle
      : box
      ? faSolidSquare
      : faSolidCircle
    : checked
    ? box
      ? faCheckSquare
      : faCheckCircle
    : box
    ? faSquare
    : faCircle;

  const classes = classNames(
    className,
    disabled ? css(cursorStyles.stopOnHover) : css(cursorStyles.pointerOnHover),
    label ? 'd-flex align-items-start' : '',
  );

  return (
    <div className={classes} onClick={disabled ? undefined : () => onClick(value)}>
      <FontAwesomeIcon
        icon={icon}
        className={classNames(
          css(colorStyles.iconGrey, small ? styles.smallBox : styles.box),
          checked && !disabled ? 'text-primary' : '',
        )}
      />
      {label && <div className="ml-2">{label}</div>}
    </div>
  );
}

const styles = StyleSheet.create({
  box: {
    width: 18,
    height: 24,
    marginRight: 6,
    marginTop: '1.5px',
  },
  smallBox: {
    width: 15,
    height: 20,
    marginRight: 6,
    marginTop: '1.5px',
  },
});

RadioButton.propTypes = {
  box: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  value: PropTypes.any,
};

export default RadioButton;
