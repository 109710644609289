// update along with app/lib/enums/user_permission.py
const UserPermission = {
  ASSIGN_USER_ROLE: 'assign_user_role',
  BRAND_HARVESTING: 'brand_harvesting',
  CLASSIFY: 'classify',
  CONFLICT_RESOLVE: 'conflict_resolve',
  CONVERT_MANUFACTURER_PRODUCTS: 'convert_manufacturer_products',
  CREATE_EDIT_ROLE: 'create_edit_role',
  DATA_SYNC: 'data_sync',
  EDIT_ATTRIBUTES: 'edit_attributes',
  EDIT_PRODUCT: 'edit_product',
  ENRICH_PRODUCTS: 'enrich_products',
  MANAGE_ACTIVITY_LOGS: 'manage_activity_logs',
  MANAGE_ASSETS: 'manage_assets',
  MANAGE_DATA_SOURCES: 'manage_data_sources',
  MANAGE_DATA_STREAMS: 'manage_data_streams',
  MANAGE_PRODUCT_LISTS: 'manage_product_lists',
  MANAGE_TAXONOMY: 'manage_taxonomy',
  MANAGE_USERS: 'manage_users',
  TASKS_VIEW: 'view_tasks',
  UNCLASSIFY: 'unclassify',
  UPB_VIEW: 'upb_view',
  UPB_VIEW_ADMIN: 'upb_view_admin',
  VIEW_CATEGORY_CONFLICTS: 'view_category_conflicts',
  VIEW_CONFLICTS: 'view_conflicts',
  VIEW_DASHBOARD: 'view_dashboard',
  VIEW_FILE_EXPORTS: 'view_file_exports',
  VIEW_PRODUCT_LISTS: 'view_product_lists',
  VIEW_SKU_MAPPINGS: 'view_sku_mappings',
  VIEW_USER_MANAGEMENT: 'view_user_management',
};

export default UserPermission;
