import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form } from 'react-bootstrap';

import DropZone from 'components/shared/Dropzone';

function UploadImagesSection({
  imageUrls,
  setImageUrls,
  setSelectedThumbnailUrl,
  selectedThumbnailUrl,
}) {
  return (
    <div>
      <DropZone
        text="Drag and drop images here, or click to select images"
        onUploadSuccess={newUrls => setImageUrls(newUrls)}
        fileUrls={imageUrls}
        isImage={true}
        isPublic={true}
      />
      <div className="mt-3 d-flex justify-content-center align-items-center">
        <Form.Group controlId="thumb_check_box" className="mr-2">
          <Form.Check
            disabled={imageUrls?.length !== 1}
            onChange={e => setSelectedThumbnailUrl(e.target.checked ? imageUrls?.[0] : null)}
            type="checkbox"
            label="Make thumbnail*"
            checked={selectedThumbnailUrl && imageUrls?.[0] === selectedThumbnailUrl}
          />
        </Form.Group>
        <Button variant="outline-primary" onClick={() => setImageUrls([])}>
          Clear Images
        </Button>
      </div>
      <p className="small text-muted">* Upload just one image to make it as thumbnail</p>
    </div>
  );
}

UploadImagesSection.propTypes = {
  imageUrls: PropTypes.array,
  setImageUrls: PropTypes.func,
  setSelectedThumbnailUrl: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
};

export default UploadImagesSection;
