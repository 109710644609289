import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';

function WidgetSelectionModal({ show, onHide, widgetOptions, activeWidgets, setActiveWidgets }) {
  const [selectedWidgets, setSelectedWidgets] = useState(activeWidgets);

  const handleCheckboxChange = widgetType => {
    if (selectedWidgets.includes(widgetType)) {
      setSelectedWidgets(selectedWidgets.filter(w => w !== widgetType));
    } else {
      setSelectedWidgets([...selectedWidgets, widgetType]);
    }
  };

  const handleSave = () => {
    setActiveWidgets(selectedWidgets);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Widgets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {widgetOptions.map(group => (
            <div key={group.category} className="mt-2">
              <h4>{group.category}</h4>
              {group.options.map(option => (
                <Form.Check
                  key={option.value}
                  type="checkbox"
                  label={option.label}
                  checked={selectedWidgets.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                />
              ))}
            </div>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

WidgetSelectionModal.propTypes = {
  show: PropTypes.bool,
  widgetOptions: PropTypes.array,
  activeWidgets: PropTypes.array,
  onHide: PropTypes.func,
  setActiveWidgets: PropTypes.func,
};

export default WidgetSelectionModal;
