import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import ToggleSwitch from 'react-switch';

import { grape } from 'lib/css/colors';
import { HUNDRED_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import { SIMILAR_BRAND_INFO, SIMILAR_BRAND_UPDATE } from 'lib/networking/endpoints';
import SimilarBrandsQAStatusOptions from 'lib/enums/SimilarBrandsQAStatus';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function SimilarBrandsModal({ onHide, similarBrand, refetch }) {
  const [itemsPerPage, setItemsPerPage] = useState(HUNDRED_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: { products, count } = {}, loading } = useGet(SIMILAR_BRAND_INFO, {
    similar_brand_name: similarBrand.brand_name,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });
  const [verificationStaus, setVerificationStaus] = useState(similarBrand.qa_verification_status);
  const { postData: updateSimilarBrand } = usePost(
    SIMILAR_BRAND_UPDATE,
    () => {
      SwalDialog('success', 'Successfully Updated the Similar Brand', 'Success', 'center');
      onHide();
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while Updating the Brand: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  return (
    <Modal centered show={true} onHide={onHide} size="xl">
      <Modal.Header className="py-2 my-2" closeButton>
        <h5 className="font-weight-bold mr-2">{similarBrand.brand_name}</h5>
        <ToggleSwitch
          onColor={grape}
          checked={verificationStaus === SimilarBrandsQAStatusOptions.VERIFIED}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={() => {
            setVerificationStaus(
              verificationStaus === SimilarBrandsQAStatusOptions.VERIFIED
                ? SimilarBrandsQAStatusOptions.UNVERIFIED
                : SimilarBrandsQAStatusOptions.VERIFIED,
            );
          }}
        />
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <LoadingSpinner />
        ) : products?.length > 0 ? (
          <>
            <Row>
              <Col className={classNames(css(styles.pagination), 'd-flex justify-content-start')}>
                <PaginationComponent
                  totalItems={count}
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  defaultSize
                />
              </Col>
              <Col className="d-flex justify-content-end mt-3 ml-1">
                <span>{count} Brands Products</span>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Entity Type</th>
                  <th>Entity Name</th>
                  <th>SKU</th>
                  <th>Product Name</th>
                  <th>Pack Size</th>
                  <th>Distributor Manufacturer Name</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {products?.map(product => (
                  <tr key={product.id}>
                    <td>Distributor</td>
                    <td>{product.global_vendor.name || '-'}</td>
                    <td>{product.vendor_sku || '-'}</td>
                    <td>{product.name || '-'}</td>
                    <td>{product.pack_size || '-'}</td>
                    <td>{product.distributor_mfr_name || '-'}</td>
                    <td>
                      <Button
                        variant="link"
                        onClick={() => {
                          window.open(
                            `/global-product-detail/${product.global_product_id}/` +
                              `${product.id}/distributor`,
                            '_blank',
                          );
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          'No Products for this Similar Brand'
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-start">
        <Button variant="outline-primary" onClick={() => onHide()}>
          Cancel
        </Button>
        <Button
          variant="outline-primary"
          onClick={() => {
            updateSimilarBrand({
              similar_brand_id: similarBrand.id,
              verification_status: verificationStaus,
            });
          }}
        >
          Save and Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const styles = StyleSheet.create({
  pagination: {
    marginTop: '1rem',
    zIndex: 0,
  },
});

SimilarBrandsModal.propTypes = {
  onHide: PropTypes.func,
  similarBrand: PropTypes.object,
  refetch: PropTypes.func,
};

export default SimilarBrandsModal;
