import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select/creatable';

const EDITABLE_FIELD_INPUT_TYPES = {
  STRING: 'string',
  LIST: 'list',
};

function EditableInputField({
  value,
  setValue,
  edit,
  setCanEdit,
  dropdownOptions,
  type = EDITABLE_FIELD_INPUT_TYPES.STRING,
}) {
  return (
    <div>
      {edit ? (
        type === 'string' ? (
          <Form.Control
            type="text"
            autoFocus
            value={value || ''}
            onChange={e => setValue(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setCanEdit(false);
              }
            }}
          />
        ) : (
          <Select
            autoFocus
            value={dropdownOptions.find(option => option?.value === value)}
            options={dropdownOptions}
            onChange={e => {
              setValue(e.value);
            }}
            defaultValue={null}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setCanEdit(false);
              }
            }}
          />
        )
      ) : value === true ? (
        'true'
      ) : value === false ? (
        'false'
      ) : (
        value
      )}
    </div>
  );
}

EditableInputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  setValue: PropTypes.func,
  edit: PropTypes.bool,
  setCanEdit: PropTypes.func,
  dropdownOptions: PropTypes.array,
  type: PropTypes.string,
};

export default EditableInputField;
export { EDITABLE_FIELD_INPUT_TYPES };
