import React, { useEffect, useMemo, createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import mixpanel from 'mixpanel-browser';

import { AuthContext } from 'components/providers/AuthContextProvider';
import { ConfigContext } from 'components/providers/ConfigContextProvider';

const MixpanelContext = createContext();

const MixpanelProvider = ({ children }) => {
  const { authUser } = useContext(AuthContext);
  const { mixpanel_token } = useContext(ConfigContext);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const initializeMixpanel = () => {
      if (authUser && mixpanel_token && !initialized) {
        mixpanel.init(mixpanel_token);
        if (authUser.id) {
          mixpanel.identify(authUser.id);
        }
        mixpanel.people.set({
          id: authUser.id,
          $email: authUser.email,
          $name: authUser.name,
        });

        mixpanel.register({
          name: authUser.name,
          email: authUser.email,
        });

        setInitialized(true);
      }
    };

    initializeMixpanel();
  }, [authUser, mixpanel_token, initialized]);

  const mixpanelInstance = useMemo(() => {
    return initialized ? mixpanel : { track: () => {} };
  }, [initialized]);

  return <MixpanelContext.Provider value={mixpanelInstance}>{children}</MixpanelContext.Provider>;
};

MixpanelProvider.propTypes = {
  children: PropTypes.any,
};

const useMixpanel = () => {
  const context = useContext(MixpanelContext);
  if (!context) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }
  return context;
};

export { MixpanelProvider };
export default useMixpanel;
