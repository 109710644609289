import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import SwalDialog from 'lib/utils/toast';
import { useParams } from 'react-router-dom';

import { ATTRIBUTE_SCHEMA_IMPORT, SCHEMAS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function SchemaAttributeDuplicateModal({ onHide }) {
  const { schemaId } = useParams();

  const [selectedSchema, setSeletedSchema] = useState(null);
  const { data: { data = [] } = [], loading } = useGet(SCHEMAS);

  const { postData: copySchemaAttributes } = usePost(
    ATTRIBUTE_SCHEMA_IMPORT,
    () => {
      SwalDialog('success', 'Copying the attributes.', 'Success', 'center');
      onHide();
    },
    () => {
      SwalDialog('error', 'An error occurred during copying.', 'Error', 'center');
    },
  );

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Select Schema To Copy The Attributes</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Select
            isClearable
            isLoading={loading}
            options={data.map(schema => ({ label: schema.schema_name, value: schema.id }))}
            value={selectedSchema}
            onChange={option => setSeletedSchema(option)}
            placeholder="Select Schema"
          />
        </div>
        <Row className="pt-3 mt-3">
          <Col sm={3}>
            <Button
              block
              onClick={() =>
                copySchemaAttributes({
                  schema_id: selectedSchema.value,
                  get_from_another_schema_id: schemaId,
                })
              }
            >
              Copy
            </Button>
          </Col>
          <Col sm={2} className="pl-0">
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

SchemaAttributeDuplicateModal.propTypes = {
  brand: PropTypes.object,
  onHide: PropTypes.func,
  updateBrand: PropTypes.func,
  loading: PropTypes.bool,
};

export default SchemaAttributeDuplicateModal;
