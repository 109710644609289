import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import BrandsHarvestTableRow from 'components/manage_brands/harvest_brands/BrandsHarvestTableRow';

function BrandHarvestTableView({ brands, selectedIds, setSelectedIds, viewOnly = false }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Brand Name</th>
          <th>Manufacturer</th>
          <th># of DP Products</th>
          <th>Priority</th>
          <th>MPN</th>
          <th>GTIN</th>
          <th>Data Quality</th>
          <th>URL</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {brands.map(brand => (
          <BrandsHarvestTableRow
            key={brand.id}
            brand={brand}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            viewOnly={viewOnly}
          />
        ))}
      </tbody>
    </Table>
  );
}

BrandHarvestTableView.propTypes = {
  brands: PropTypes.array,
  selectedIds: PropTypes.array,
  setSelectedIds: PropTypes.func,
  viewOnly: PropTypes.bool,
};

export default BrandHarvestTableView;
