import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import BroadlinerComplianceModalRow from 'components/manage_attribute/BroadlinerComplianceModalRow';
import {
  getURL,
  GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS,
  GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS_FOR_ATTRIBUTE,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const GLOBAL_VENDOR_ID_MAP = [
  { id: 3, name: 'Sysco' },
  { id: 74, name: 'Performance Foodservice Group' },
  { id: 6, name: 'US Foods' },
  { id: 2, name: 'Chef"s Warehouse' },
];

function BroadlinerComplianceModal({ onHide, attribute }) {
  const [vendorRequirements, setVendorRequirements] = useState([]);
  const { data: { data } = [], loading } = useGet(
    getURL(GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS_FOR_ATTRIBUTE, {
      attribute_id: attribute?.attributes?.id,
    }),
    {},
    !attribute,
  );

  const { postData: createOrUpdateGlobalVendorAttributeRequirements, loading: updatingVendors } =
    usePost(
      GLOBAL_VENDOR_ATTRIBUTE_REQUIREMENTS,
      () => {
        SwalDialog('success', 'Attribute created/updated successfully', 'Success', 'center');
        onHide();
      },
      error => {
        SwalDialog('error', 'An error occurred', error.response?.data?.message, 'center');
      },
    );

  const updateVendorRequirements = (vendor, data) => {
    setVendorRequirements([
      ...vendorRequirements,
      {
        global_vendor_id: GLOBAL_VENDOR_ID_MAP.find(op => op.name === vendor).id,
        requirements: data.value,
        attributes_id: attribute.attributes.id,
      },
    ]);
  };

  const updateMappings = tst => {
    createOrUpdateGlobalVendorAttributeRequirements({
      vendor_requirements: [...vendorRequirements],
    });
  };

  if (loading || updatingVendors) {
    return <LoadingSpinner />;
  }

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered>
      <Modal.Header>
        <h5 className="px-2 font-weight-bold">Broadliner Complience</h5>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form.Label className="font-weight-bold mb-3">
          Attribute : {attribute.attributes.attribute_name}
        </Form.Label>
        {GLOBAL_VENDOR_ID_MAP.map((vendor, index) => (
          <BroadlinerComplianceModalRow
            key={index}
            header={vendor.name}
            value={data?.find(option => option.global_vendor_id === vendor.id)?.requirements}
            update={updateVendorRequirements}
          />
        ))}
        <Row className="pt-3 mt-3">
          <Col sm={3}>
            <Button block onClick={() => updateMappings()}>
              Save
            </Button>
          </Col>
          <Col sm={2} className="pl-0">
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

BroadlinerComplianceModal.propTypes = {
  onHide: PropTypes.func,
  attribute: PropTypes.object,
};

export default BroadlinerComplianceModal;
