import React, { useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Button, Col, Row } from 'react-bootstrap';
import SplitPane from 'react-split-pane';

import { ATTRIBUTE_CSV_IMPORT_FOR_ROSETTA_TAXONOMIES } from 'lib/networking/endpoints';
import { ATTRIBUTE_SAMPLE_FILE } from 'lib/constants';
import Container from 'components/shared/Container';
import AssignAttributeSplitViewForCategory from 'components/manage_attribute/CategoryView/AssignAttributeSplitViewForCategory';
import AssignAttributeTableSplitViewForAttributes from 'components/manage_attribute/AttributeView/AssignAttributeTableSplitViewForAttributes';
import FileUploadModal from 'components/shared/FileUploadModal';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function ManageAttributeView() {
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const { postData: uploadFile } = usePost(
    ATTRIBUTE_CSV_IMPORT_FOR_ROSETTA_TAXONOMIES,
    () => {
      SwalDialog('success', 'Processing the uploaded file.', 'Success', 'center');
      setShowFileUploadModal(false);
    },
    () => {
      SwalDialog('error', 'An error occurred during the file upload.', 'Error', 'center');
    },
  );

  return (
    <Container>
      <h3 className="font-weight-bold">Assign Attributes</h3>
      <Row>
        <Col sm={12}>
          <Button
            variant="link"
            className="float-right mr-4"
            onClick={() => setShowFileUploadModal(true)}
          >
            Upload csv
          </Button>
        </Col>
      </Row>
      <SplitPane
        primary="first"
        split="vertical"
        defaultSize="30%"
        className={css(styles.splitPane)}
      >
        <AssignAttributeSplitViewForCategory />
        <AssignAttributeTableSplitViewForAttributes />
      </SplitPane>
      {showFileUploadModal ? (
        <FileUploadModal
          onHide={() => setShowFileUploadModal(false)}
          onSubmit={uploadFile}
          header="Import Attributes File For Taxonomies"
          sample_file_url={ATTRIBUTE_SAMPLE_FILE}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  splitPane: {
    position: 'relative',
  },
});

export default ManageAttributeView;
