import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Container, Row } from 'react-bootstrap';

import AddNewFilterModal from 'components/manage_vendors/vendor_details/catalog_settings/AddNewFilterModal';
import AddNewFilterFromPresetsModal from 'components/manage_vendors/vendor_details/catalog_settings/AddNewFilterFromPresetsModal.jsx';
import CatalogAttributeFilterView from 'components/manage_vendors/vendor_details/catalog_settings/CatalogAttributeFilterView';
import { CATALOG_FILTER_ATTRIBUTES_VENDOR } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';

import useGet from 'lib/hooks/useGet';

function VendorCatalogSettingsView({ globalVendorId }) {
  const [showAddNewFilterModel, setShowAddNewFilterModal] = useState(false);
  const [showCreateNewFilterFromPresetModel, setShowCreateNewFilterFromPresetModel] =
    useState(false);

  const {
    data: { data: filter_values } = {},
    loading,
    refetch,
  } = useGet(
    CATALOG_FILTER_ATTRIBUTES_VENDOR,
    { global_vendor_id: globalVendorId },
    !globalVendorId,
  );
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <span className="mr-3 h6">Manage Catalog Filters</span>
          <Button onClick={() => setShowAddNewFilterModal(true)}>Create New Filter</Button>
          <Button className="ml-2" onClick={() => setShowCreateNewFilterFromPresetModel(true)}>
            Create New Filter from Preset
          </Button>
        </Col>
      </Row>
      {filter_values?.map(filter => (
        <CatalogAttributeFilterView
          key={filter.id}
          catalogFilterAttribute={filter}
          globalVendorId={globalVendorId}
          refetch={refetch}
        />
      ))}
      {showAddNewFilterModel && (
        <AddNewFilterModal
          onHide={() => setShowAddNewFilterModal(false)}
          globalVendorId={globalVendorId}
          refetch={refetch}
        />
      )}
      {showCreateNewFilterFromPresetModel && (
        <AddNewFilterFromPresetsModal
          onHide={() => setShowCreateNewFilterFromPresetModel(false)}
          globalVendorId={globalVendorId}
          refetch={refetch}
        />
      )}
    </Container>
  );
}

VendorCatalogSettingsView.propTypes = {
  globalVendorId: PropTypes.number,
};

export default VendorCatalogSettingsView;
