import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import CreatableSelect from 'react-select/creatable';
import { uniqBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import { getURLWithSearchParams } from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';

const RESULTS_PER_PAGE = 100;

function SearchableScrollableSelect({
  onChange,
  fetchUrl,
  optionMapper,
  initOption,
  productListId,
  disabled = false,
  autoFocus = true,
  placeholder = '',
  otherRequestParams = {},
}) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(initOption);
  const [loading, setLoading] = useState(false);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [searchQuery] = useDebounce(searchValue, 500);

  const loadMoreOptions = page => {
    if (!canFetchMore) {
      return;
    }
    setLoading(true);
    const requestParams = {
      limit: RESULTS_PER_PAGE,
      offset: (page - 1) * RESULTS_PER_PAGE,
      search: searchQuery,
      product_list_id: productListId,
    };
    const allRequestParams = { ...requestParams, ...otherRequestParams };
    const url = getURLWithSearchParams(fetchUrl, allRequestParams);
    httpGet(url)
      .then(res => {
        if (res.status === 200) {
          const items = res.data?.data || [];
          if (items?.length) {
            setCurrentPage(page + 1);
            setOptions(uniqBy([...options, ...items.map(optionMapper)], 'value'));
          } else {
            setCanFetchMore(false);
          }
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedOption(initOption);
  }, [initOption]);

  // reset params when a search query is inserted
  useEffect(() => {
    setCurrentPage(1);
    setOptions([]);
    loadMoreOptions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  // initial load
  useEffect(() => {
    loadMoreOptions(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CreatableSelect
      autoFocus={autoFocus}
      isClearable
      isSearchable
      isDisabled={disabled}
      isLoading={loading}
      options={options}
      value={selectedOption}
      onChange={option => {
        setSelectedOption(option);
        onChange(option);
      }}
      onMenuScrollToBottom={() => loadMoreOptions(currentPage)}
      inputValue={searchValue}
      onInputChange={input => setSearchValue(input)}
      className={css(styles.select)}
      placeholder={placeholder}
    />
  );
}

const styles = StyleSheet.create({
  select: {
    flexGrow: 0.96,
  },
});

SearchableScrollableSelect.propTypes = {
  onChange: PropTypes.func,
  fetchUrl: PropTypes.string,
  optionMapper: PropTypes.func,
  initOption: PropTypes.object,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  isCreatable: PropTypes.bool,
  productListId: PropTypes.number,
  otherRequestParams: PropTypes.object,
};

export default SearchableScrollableSelect;
