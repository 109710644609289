import { useCallback, useState } from 'react';

import { httpPost } from 'lib/networking/http';

function usePost(url, successCallback, errorCallback) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const postData = useCallback(
    postBody => {
      setLoading(true);
      return httpPost(url, postBody)
        .then(res => {
          successCallback && successCallback(res);
          return res;
        })
        .catch(error => {
          setError(error);
          errorCallback && errorCallback(error);
          return error;
        })
        .finally(() => setLoading(false));
    },
    [errorCallback, successCallback, url],
  );

  return { postData, loading, error };
}

export default usePost;
