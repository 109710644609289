import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css } from 'aphrodite';

import { fontStyles, subNutritionRowStyles } from 'components/shared/nutrition_facts/sharedStyles';
import Separator from 'components/shared/nutrition_facts/Separator';

function SubNutritionRow({
  title,
  quantity,
  uom,
  percentage,
  offset = 0,
  boldPercentage = false,
  skipSeparator = false,
  separatorOffset = 0,
}) {
  if (!quantity && !percentage) {
    return null;
  }

  const valueString = [quantity, uom].filter(x => x).join('');

  return (
    <>
      <div className="w-100 d-flex justify-content-between">
        <div
          className={classNames(
            css(subNutritionRowStyles(offset).titleContainer),
            fontStyles('16px'),
          )}
        >
          <span>{title}</span>
          <span className="ml-2">{valueString}</span>
        </div>
        {percentage && <div className={fontStyles('16px', boldPercentage)}>{`${percentage}%`}</div>}
      </div>
      {!skipSeparator && <Separator offset={separatorOffset} />}
    </>
  );
}

SubNutritionRow.propTypes = {
  title: PropTypes.node.isRequired,
  quantity: PropTypes.string,
  uom: PropTypes.string,
  percentage: PropTypes.string,
  offset: PropTypes.number,
  boldPercentage: PropTypes.bool,
  skipSeparator: PropTypes.bool,
  separatorOffset: PropTypes.number,
};

export default SubNutritionRow;
