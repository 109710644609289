import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import AttributesListView from 'components/upb_viewer/pdp_components/AttributesListView';
import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import AttributeSubsection from 'components/upb_viewer/pdp_components/AttributeSubsection';
import { convertToTitleCase } from 'lib/utils/stringUtils';
import { faDownload } from 'images/icons/fsa-monotone-svg-icons';
import LinkButton from 'components/shared/LinkButton';
import { preserveFormatClass, sectionTextClass } from 'components/upb_viewer/css/sharedStyles';
import ShowMore from 'components/shared/ShowMore';

function ProductDescriptionSection({ attributes, additionalDocuments }) {
  const romanceCopy =
    attributes?.find(attribute => attribute.attribute_name === 'romance_copy_long')
      ?.attribute_value || '';
  const featuresAndBenefits =
    attributes?.find(attribute => attribute.attribute_name === 'features_and_benefits_extended')
      ?.attribute_value || '';

  const showLearnMore = additionalDocuments && additionalDocuments?.length > 0;

  if (!romanceCopy && !featuresAndBenefits) {
    return null;
  }

  return (
    <AttributeSection title={'Description'}>
      <div className={css(styles.container)}>
        <ShowMore max={100} defaultState={false}>
          <div>
            {romanceCopy && (
              <AttributeSubsection>
                <div className={classNames(preserveFormatClass, sectionTextClass)}>
                  {romanceCopy}
                </div>
              </AttributeSubsection>
            )}
            {featuresAndBenefits && (
              <AttributeSubsection title={'Features and Benefits'}>
                <AttributesListView attributeValue={featuresAndBenefits} />
              </AttributeSubsection>
            )}
            {showLearnMore && (
              <AttributeSubsection title={'Learn More'}>
                <div>
                  {additionalDocuments &&
                    additionalDocuments?.length > 0 &&
                    additionalDocuments.map((document, index) => (
                      <div key={index}>
                        <LinkButton
                          icon={faDownload}
                          url={document.value}
                          text={`${convertToTitleCase(`download ${document.displayName}`)} (
                            ${document.value.split('.').pop()})`}
                        />
                      </div>
                    ))}
                </div>
              </AttributeSubsection>
            )}
          </div>
        </ShowMore>
      </div>
    </AttributeSection>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 0.1,
  },
});

ProductDescriptionSection.propTypes = {
  attributes: PropTypes.array,
  additionalDocuments: PropTypes.array,
};

export default ProductDescriptionSection;
