import React, { useState } from 'react';

import { Button, Col, Form, Row, Tab, Tabs, Table } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from 'use-debounce';

import Container from 'components/shared/Container';
import { DATA_SOURCE_ALL } from 'lib/networking/endpoints';
import DataSourceViewTableRow from 'components/data_sources/DataSourceViewTableRow';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import NewDataSourceModal from 'components/data_sources/NewDataSourceModal';
import useGet from 'lib/hooks/useGet';

const DATA_SOURCE_TABS = {
  RECURRING_DATA_SOURCES: 'Recurring data sources',
  ONE_TIME_DATA_SOURCES: 'One time data sources',
};

function DataSources() {
  const [activeTabKey, setActiveTabKey] = useState(DATA_SOURCE_TABS.ONE_TIME_DATA_SOURCES);
  const [showModal, setShowModal] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchQuery] = useDebounce(searchString, 400);

  const {
    data: { data: oneTimeDataSources = [] } = {},
    loading,
    error,
    refetch: refetchOnetimeDataSources,
  } = useGet(DATA_SOURCE_ALL, {
    search_term: searchQuery,
    limit: 50,
    exclude_recurring_data_sources: true,
  });

  const {
    data: { count: dataSourceCount = null, data: recurringDataSources = [] } = {},
    refetch: refetchRecurringDataSources,
  } = useGet(DATA_SOURCE_ALL, {
    search_term: searchQuery,
    include_recurring_data_sources_only: true,
    exclude_recurring_data_sources: false,
    with_count: true,
  });

  const refetchDataSources = () => {
    refetchOnetimeDataSources();
    refetchRecurringDataSources();
  };

  if (loading && !oneTimeDataSources.length) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  const tableHeaders = (
    <thead>
      <tr>
        <th>Source Name</th>
        <th>Entity Type</th>
        <th>Entity Name</th>
        <th>Type</th>
        <th>Status</th>
        <th>Last Processed</th>
        <th>Actions</th>
      </tr>
    </thead>
  );

  const recurringTableHeaders = (
    <thead>
      <tr>
        <th>Source Name</th>
        <th>Entity Type</th>
        <th>Entity Name</th>
        <th>Type</th>
        <th>Status</th>
        <th>Last Processed</th>
        <th># Of Errors</th>
        <th>Actions</th>
      </tr>
    </thead>
  );

  return (
    <Container>
      <Row>
        <Col>
          <h3 className="font-weight-bold">
            Data Sources
            {dataSourceCount && activeTabKey === DATA_SOURCE_TABS.RECURRING_DATA_SOURCES
              ? ` - ${dataSourceCount}`
              : null}
          </h3>
        </Col>
        <Col className="ml-auto col-auto">
          <Button onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faPlus} className="mx-1" />
            New Data Source
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Control
              onChange={e => setSearchString(e.target.value)}
              type="text"
              placeholder="Search Data Source"
              value={searchString}
            />
          </Form.Group>
        </Col>
      </Row>
      <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
        <Tab
          eventKey={DATA_SOURCE_TABS.ONE_TIME_DATA_SOURCES}
          title={DATA_SOURCE_TABS.ONE_TIME_DATA_SOURCES}
        >
          <Table hover className="mt-0">
            {tableHeaders}
            <tbody>
              {oneTimeDataSources?.map(record => (
                <DataSourceViewTableRow
                  dataSourceRecord={record}
                  key={record.id}
                  refetch={refetchDataSources}
                />
              ))}
            </tbody>
          </Table>
        </Tab>
        <Tab
          eventKey={DATA_SOURCE_TABS.RECURRING_DATA_SOURCES}
          title={DATA_SOURCE_TABS.RECURRING_DATA_SOURCES}
        >
          <Table hover className="mt-0">
            {recurringTableHeaders}
            <tbody>
              {recurringDataSources.map(record => (
                <DataSourceViewTableRow
                  dataSourceRecord={record}
                  key={record.id}
                  refetch={refetchDataSources}
                />
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
      {showModal ? (
        <NewDataSourceModal
          onHide={() => setShowModal(false)}
          refetch={() => refetchDataSources()}
        />
      ) : null}
    </Container>
  );
}

export default DataSources;
