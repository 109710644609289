// update along with app/lib/enums/vendor_requirement.py
const VendorRequirement = {
  MANDATORY: 'MANDATORY',
  REQUESTED: 'REQUESTED',
  NOT_REQUESTED: 'NOT_REQUESTED',
};

const VendorRequirementOptions = [
  { value: VendorRequirement.MANDATORY, label: 'Mandatory' },
  { value: VendorRequirement.REQUESTED, label: 'Requested' },
  { value: VendorRequirement.NOT_REQUESTED, label: 'Not Requested' },
];

export { VendorRequirement, VendorRequirementOptions };
