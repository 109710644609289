import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import { isEqual } from 'lodash';
import SplitPane from 'react-split-pane';

import { GLOBAL_PRODUCTS_PANE } from 'lib/constants';
import { GLOBAL_PRODUCTS_UPDATE_CATEGORIES } from 'lib/networking/endpoints';
import { GlobalProductsContext } from 'components/global_products/GlobalProductsContainer';
import { useSelected } from 'lib/hooks/useSelected';
import { useShiftSelected } from 'lib/hooks/useShiftSelected';
import GlobalProductsView from 'components/global_products/global_products_view/GlobalProductsView';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageTaxonomyView from 'components/manage_taxonomy/ManageTaxonomyView';
import ProductCategoryChangeConfirmModal from 'components/global_products/classify/ProductCategoryChangeConfirmModal';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function GlobalProductsTaxonomySplitView() {
  const [size, setSize] = useState('60%');
  const paneRef = useRef();

  const { products, searchFilters, selectedIDs, setSelectedIDs, globalProductsLoading } =
    useContext(GlobalProductsContext);

  const [searchedText, setSearchedText] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [showCategoryConfirmModal, setShowCategoryConfirmModal] = useState(false);

  const { change: changeSelectedIDs, clear: clearSelectedIDs } = useSelected(
    selectedIDs,
    setSelectedIDs,
  );
  const globalProductsRef = useRef();
  const hasUserPermissions = useUserPermissions();

  useEffect(() => {
    // clear the selected ids when a search/filter is done
    if (!isEqual(searchFilters, searchedText)) {
      setSearchedText(searchFilters);
      clearSelectedIDs();
    }
  }, [searchFilters, clearSelectedIDs, setSearchedText, searchedText]);

  useEffect(() => {
    const storedSize = window.localStorage.getItem(GLOBAL_PRODUCTS_PANE);
    if (storedSize) {
      setSize(storedSize);
    }
  }, []);

  const handleResize = () => {
    const paneWidth = paneRef.current.pane1.style.width;
    setSize(`${paneWidth}`);
    window.localStorage.setItem(GLOBAL_PRODUCTS_PANE, `${paneWidth}`);
  };

  const setSelectedIDsWithShift = useShiftSelected(
    products?.map(
      product => `${product.product_type}-${product.id}-${product.global_product_id}`,
    ) || [],
    changeSelectedIDs,
  );

  const { postData: classifyProducts, loading } = usePost(
    GLOBAL_PRODUCTS_UPDATE_CATEGORIES,
    () => {
      SwalDialog(
        'success',
        `${selectedIDs.length} product(s) successfully classified`,
        'Success',
        'center',
        () => {
          setShowCategoryConfirmModal(false);
          clearSelectedIDs();
          globalProductsRef.current?.refetchGlobalProducts();
        },
      );
    },
    () => {
      SwalDialog('error', 'Error occurred while Updating Products', 'Error', 'center');
    },
  );

  const selectedIDsRef = useRef();
  selectedIDsRef.current = selectedIDs;
  const onCategorySelect = useCallback(
    l4CategoryId => {
      const ids = selectedIDsRef?.current || [];
      if (!hasUserPermissions([UserPermission.CLASSIFY])) {
        SwalDialog(
          'error',
          "You don't have permission to classify products." +
            ' Please contact an admin to get permissions.',
          'Error',
          'center',
        );
        return;
      }
      if (!l4CategoryId || !ids.length) {
        return;
      }
      setSelectedCategoryId(l4CategoryId);
      setShowCategoryConfirmModal(true);
    },
    [hasUserPermissions],
  );

  const onClassifyConfirmation = () => {
    classifyProducts({
      product_ids: selectedIDs,
      category_id: selectedCategoryId,
    });
  };

  return (
    <div>
      <SplitPane
        primary="first"
        split="vertical"
        size={size}
        className={css(styles.splitPane)}
        onDragFinished={handleResize}
        ref={paneRef}
      >
        {globalProductsLoading ? (
          <LoadingSpinner short />
        ) : (
          <GlobalProductsView
            setSelectedIDsWithShift={setSelectedIDsWithShift}
            clearSelectedIDs={clearSelectedIDs}
            ref={globalProductsRef}
          />
        )}
        <ManageTaxonomyView onClassify={onCategorySelect} />
      </SplitPane>
      {showCategoryConfirmModal ? (
        <ProductCategoryChangeConfirmModal
          show={showCategoryConfirmModal}
          onHide={() => setShowCategoryConfirmModal(false)}
          categoryId={selectedCategoryId}
          selectedProductIDs={selectedIDs}
          onConfirm={onClassifyConfirmation}
          loading={loading}
        />
      ) : null}
    </div>
  );
}

const styles = StyleSheet.create({
  splitPane: {
    height: 'webkit-fill-available',
  },
});

export default GlobalProductsTaxonomySplitView;
