import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Pagination } from 'react-bootstrap';
import Select from 'react-select';

import { DEFAULT_PAGE_SIZE } from 'lib/constants';

const pageSizeOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const DISPLAY_LIMIT = 2;

function PaginationComponent({
  totalItems,
  itemsPerPage = DEFAULT_PAGE_SIZE,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  disabled = false,
  defaultSize = false,
}) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (totalPages > 0 && totalPages < currentPage) {
      setCurrentPage(1);
    }
  }, [currentPage, setCurrentPage, totalPages]);

  const items = useMemo(() => {
    const pageItems = [];
    let previousPageIsEllipsis = false;
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === currentPage ||
        i <= DISPLAY_LIMIT ||
        (i >= currentPage - DISPLAY_LIMIT && i <= currentPage + DISPLAY_LIMIT) ||
        i >= totalPages - (DISPLAY_LIMIT - 1)
      ) {
        pageItems.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => setCurrentPage(i)}
            disabled={disabled}
          >
            {i}
          </Pagination.Item>,
        );
        previousPageIsEllipsis = false;
      } else if (!previousPageIsEllipsis) {
        pageItems.push(<Pagination.Ellipsis key={i} />);
        previousPageIsEllipsis = true;
      }
    }
    return pageItems;
  }, [currentPage, disabled, setCurrentPage, totalPages]);

  const customStyles = defaultSize
    ? {}
    : {
        control: (provided, _state) => ({
          ...provided,
          height: '25px',
          minHeight: '20px',
        }),
        valueContainer: (provided, _state) => ({
          ...provided,
          padding: '0 0 0 2px',
        }),
        indicatorsContainer: (provided, _state) => ({
          ...provided,
          height: '25px',
        }),
        dropdownIndicator: (provided, _state) => ({
          ...provided,
          padding: '0',
          width: '15px',
        }),
        option: (provided, _state) => ({
          ...provided,
          fontWeight: 'normal',
          padding: '5px',
        }),
      };

  return (
    <div
      className={classNames(
        css(!defaultSize && styles.paginationText),
        'd-flex align-items-center',
      )}
    >
      <Pagination className="mb-0">
        <Pagination.First disabled={disabled} onClick={() => setCurrentPage(1)} />
        <Pagination.Prev
          disabled={disabled}
          onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
        />
        {items}
        <Pagination.Next
          disabled={disabled}
          onClick={() => setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage)}
        />
        <Pagination.Last disabled={disabled} onClick={() => setCurrentPage(totalPages)} />
      </Pagination>
      <Select
        className="ml-2"
        options={pageSizeOptions}
        value={pageSizeOptions.find(option => option.value === itemsPerPage)}
        onChange={option => setItemsPerPage(option.value)}
        isDisabled={disabled}
        styles={customStyles}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  paginationText: {
    fontSize: '0.7rem',
  },
});

PaginationComponent.propTypes = {
  currentPage: PropTypes.number,
  disabled: PropTypes.bool,
  itemsPerPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  setItemsPerPage: PropTypes.func,
  totalItems: PropTypes.number,
  defaultSize: PropTypes.bool,
};

export default PaginationComponent;
