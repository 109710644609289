import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, OverlayTrigger, Modal, Row, Tooltip } from 'react-bootstrap';
import Creatable from 'react-select/creatable';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';

import DATA_SOURCE_CATEGORY, { DATA_SOURCE_CATEGORY_OPTIONS } from 'lib/enums/DataSourceCategory';
import DATA_SOURCE_ENTITY, {
  DATA_SOURCE_ENTITY_OPTIONS,
  DATA_SOURCE_IMPORT_TYPES,
  DATA_SOURCE_IMPORT_TYPE_OPTIONS,
  DATA_SOURCE_VERIFICATION_OPTIONS,
} from 'lib/enums/DataSourceEntity';
import DropZone from 'components/shared/Dropzone';
import { grape } from 'lib/css/colors';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import {
  DATA_SOURCE_CREATE,
  DATA_SOURCE_ORIGIN_ALL,
  DATA_SOURCE_ORIGIN_CREATE,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_VENDORS_FOR_DROPDOWN,
} from 'lib/networking/endpoints';

function NewDataSourceModal({ onHide, refetch }) {
  const [dataSourceName, setDataSourceName] = useState('');
  const [S3Url, setS3Url] = useState('');
  const [dataSourceOrigin, setDataSourceOrigin] = useState({});
  const [sourceType, setSourceType] = useState({});
  const [entityType, setEntityType] = useState({});
  const [hasCopyright, setHasCopyright] = useState(false);
  const [forceOverride, setForceOverride] = useState(false);
  const [lockData, setLockData] = useState(false);
  const [flushExistingData, setFlushExistingData] = useState(false);
  const [flushExistingImages, setFlushExistingImages] = useState(false);
  const [shouldVerifyContent, setShouldVerifyContent] = useState(false);
  const [enrichManufacturerProduct, setEnrichManufacturerProduct] = useState(false);
  const [updateDistributorProductName, setUpdateDistributorProductName] = useState(false);
  const [ignoreEntityType, setIgnoreEntityType] = useState(false);
  const [assignUPBSKUs, setAssignUPBSKUs] = useState(false);
  const [importType, setImportType] = useState(
    DATA_SOURCE_IMPORT_TYPE_OPTIONS.find(
      op => op.value === DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT,
    ),
  );
  const [verificationStatus, setVerificationStatus] = useState({});
  const [entity, setEntity] = useState({});
  const [intervalType, setIntervalType] = useState({});
  const [file, setFile] = useState();
  const [lookbackDays, setLookbackDays] = useState(3);
  const [delimiter, setDelimiter] = useState(',');

  useEffect(() => {
    if (importType.value === DATA_SOURCE_IMPORT_TYPES.SKU_MAPPING_IMPORT) {
      setSourceType(DATA_SOURCE_CATEGORY_OPTIONS.find(op => op.value === DATA_SOURCE_CATEGORY.CSV));
    } else if (importType.value === DATA_SOURCE_IMPORT_TYPES.IMAGE_IMPORT) {
      setSourceType(
        DATA_SOURCE_CATEGORY_OPTIONS.find(op => op.value === DATA_SOURCE_CATEGORY.S3_FOLDER),
      );
    } else if (importType.value === DATA_SOURCE_IMPORT_TYPES.FLAG_UPDATE) {
      setSourceType(DATA_SOURCE_CATEGORY_OPTIONS.find(op => op.value === DATA_SOURCE_CATEGORY.CSV));
    }
  }, [importType]);

  const { postData: createDataSource, loading } = usePost(
    DATA_SOURCE_CREATE,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Data source created successfully',
      }).then(() => onHide());
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while creating the data source: ${error?.response?.data?.message}`,
      }),
  );

  const { data: { data: globalVendors } = {} } = useGet(GLOBAL_VENDORS_FOR_DROPDOWN);
  const { data: { data: dataSourceOrigins = [] } = {}, refetch: refetchSourceOriginData } =
    useGet(DATA_SOURCE_ORIGIN_ALL);
  const { postData: createDataSourceOrigin, loading: dataSourceOriginLoading } = usePost(
    DATA_SOURCE_ORIGIN_CREATE,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Data source origin created successfully',
      });
      refetchSourceOriginData();
    },
    error => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Error while creating the data source origin: ${error?.response?.data?.message}`,
      });
    },
  );

  const dataSourceOriginsOptions = useMemo(() => {
    if (dataSourceOrigins) {
      return dataSourceOrigins.map(origin => ({
        value: origin.id,
        label: origin.name,
      }));
    }
    return [];
  }, [dataSourceOrigins]);

  const entityNames = useMemo(() => {
    if (entityType.value === DATA_SOURCE_ENTITY.VENDOR && globalVendors) {
      return globalVendors.map(vendor => ({
        value: vendor.id,
        label: vendor.name,
      }));
    }
    return [];
  }, [entityType, globalVendors]);

  const intervalTypesMock = [
    { value: 'Daily', label: 'Daily' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
  ];

  const entityNameDisabled = useMemo(() => {
    if (!sourceType) {
      return false;
    }
    return (
      !sourceType.value ||
      sourceType.value === DATA_SOURCE_CATEGORY.GDSN ||
      sourceType.value === DATA_SOURCE_CATEGORY.GS1_CSV
    );
  }, [sourceType]);

  const lookBackDaysRequired = useMemo(() => {
    if (!sourceType) {
      return false;
    }
    return (
      sourceType.value === DATA_SOURCE_CATEGORY.GDSN ||
      sourceType.value === DATA_SOURCE_CATEGORY.GS1_S3_XML
    );
  }, [sourceType]);

  const fileUploadRequired = useMemo(() => {
    if (!sourceType) {
      return false;
    }
    return (
      sourceType.value === DATA_SOURCE_CATEGORY.CSV ||
      sourceType.value === DATA_SOURCE_CATEGORY.GS1_CSV
    );
  }, [sourceType]);

  const delimiterRequired = useMemo(() => {
    if (!sourceType) {
      return false;
    }
    return (
      sourceType.value === DATA_SOURCE_CATEGORY.CSV ||
      sourceType.value === DATA_SOURCE_CATEGORY.S3_CSV ||
      sourceType.value === DATA_SOURCE_CATEGORY.GS1_CSV
    );
  }, [sourceType]);

  const onSubmit = () => {
    const data = {
      source_name: dataSourceName,
      source_type: sourceType.value,
      import_type: importType.value,
      entity_type: entityType.value,
      global_manufacturer_id:
        entityType.value === DATA_SOURCE_ENTITY.MANUFACTURER ? entity.value : null,
      global_vendor_id: entityType.value === DATA_SOURCE_ENTITY.VENDOR ? entity.value : null,
      recurring_interval: intervalType.value,
      source_location_url: file?.s3_path || S3Url,
      data_source_origin_id: dataSourceOrigin?.value,
      lookback_days: lookbackDays,
      delimiter: delimiter,
      verification_status: verificationStatus?.value,
      has_copyright: hasCopyright,
      force_override: forceOverride,
      lock_data: lockData,
      flush_existing_data: flushExistingData,
      flush_existing_images: flushExistingImages,
      should_verify_content: shouldVerifyContent,
      enrich_manufacturer_product: enrichManufacturerProduct,
      update_distributor_product_name: updateDistributorProductName,
      ignore_entity_type: ignoreEntityType,
      assign_upb_skus: assignUPBSKUs,
    };
    if (!isValidDataSource(data)) {
      return;
    }
    createDataSource(data);
  };

  const isValidDataSource = data => {
    if (
      (data.source_type === DATA_SOURCE_CATEGORY.CSV ||
        data.source_type === DATA_SOURCE_CATEGORY.GS1_CSV) &&
      !file
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Missing Data',
        text: 'Data csv file is required',
      });
      return false;
    }
    if (data.source_type === DATA_SOURCE_CATEGORY.GS1_S3_XML && !S3Url) {
      Swal.fire({
        icon: 'error',
        title: 'Missing Data',
        text: 'S3 URL is required',
      });
      return false;
    }
    return true;
  };

  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">Add New Data Source</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={dataSourceName}
            onChange={e => setDataSourceName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Origin Source
            <OverlayTrigger
              overlay={<Tooltip>The original source of the data initially came from</Tooltip>}
              placement="top"
              delayShow={300}
              delayHide={150}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
            </OverlayTrigger>
          </Form.Label>
          <Creatable
            onCreateOption={name => createDataSourceOrigin({ name })}
            value={dataSourceOrigin}
            options={dataSourceOriginsOptions}
            onChange={e => setDataSourceOrigin(e)}
            isSearchable
            isClearable
          />
        </Form.Group>

        {![DATA_SOURCE_IMPORT_TYPES.FLAG_UPDATE].includes(importType.value) ? (
          <>
            <Row>
              <Form.Group className="d-flex align-items-center justify-content-between" as={Col}>
                <Form.Label>
                  Copyright Available
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        C+D has the copyright of this data? Data will not be overridden unless
                        &quot;force override&quot; is turned on.
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <ToggleSwitch
                  checked={hasCopyright}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setHasCopyright(!hasCopyright)}
                  className="ml-2"
                />
              </Form.Group>

              <Form.Group className="d-flex align-items-center justify-content-between" as={Col}>
                <Form.Label>
                  Force Override
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, the data will be overridden even if it has copyrights ON.
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <ToggleSwitch
                  checked={forceOverride}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setForceOverride(!forceOverride)}
                  className="ml-2"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="d-flex align-items-center justify-content-between" as={Col}>
                <Form.Label>
                  Lock Data
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, the data will not be overridden by any other data
                        source.(unless force override is enabled).
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <ToggleSwitch
                  checked={lockData}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setLockData(!lockData)}
                  className="ml-2"
                />
              </Form.Group>

              <Form.Group className="d-flex align-items-center  justify-content-between" as={Col}>
                <Form.Label>
                  Flush Existing Data
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, the existing data will be flushed before importing the new
                        data.
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <ToggleSwitch
                  checked={flushExistingData}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setFlushExistingData(!flushExistingData)}
                  className="ml-2"
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="d-flex align-items-center  justify-content-between" as={Col}>
                <Form.Label>
                  Flush Existing Images
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, the existing images(thumbnail and other_images) will be
                        deleted before the new data is inserted. If new data is not provided then
                        the existing images will not be deleted.
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <ToggleSwitch
                  checked={flushExistingImages}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setFlushExistingImages(!flushExistingImages)}
                  className="ml-2"
                />
              </Form.Group>

              <Form.Group className="d-flex align-items-center  justify-content-between" as={Col}>
                <Form.Label>
                  Mark for content verification
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, this data will go to the Scrapped content verification tool
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <ToggleSwitch
                  checked={shouldVerifyContent}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setShouldVerifyContent(!shouldVerifyContent)}
                  className="ml-2"
                />
              </Form.Group>
            </Row>
            <Row>
              {DATA_SOURCE_ENTITY.VENDOR === entityType.value ? (
                <Form.Group className="d-flex align-items-center  justify-content-between" as={Col}>
                  <Form.Label>
                    Update distributor Product Name if exists
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          If turned off, if a distributor product exists the name will not be
                          updated.
                        </Tooltip>
                      }
                      placement="top"
                      delayShow={300}
                      delayHide={150}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                    </OverlayTrigger>
                  </Form.Label>
                  <ToggleSwitch
                    checked={updateDistributorProductName}
                    onColor={grape}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setUpdateDistributorProductName(!updateDistributorProductName)}
                    className="ml-2"
                  />
                </Form.Group>
              ) : null}

              {[DATA_SOURCE_CATEGORY.GS1_S3_XML, DATA_SOURCE_CATEGORY.GS1_CSV].includes(
                sourceType?.value,
              ) &&
              DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT === importType.value &&
              DATA_SOURCE_ENTITY.VENDOR === entityType.value ? (
                <Form.Group className="d-flex align-items-center  justify-content-between" as={Col}>
                  <Form.Label>
                    Enrich Manufacturer Product
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          If turned on, instead of the distributor product the manufactrer product
                          will be enriched.
                        </Tooltip>
                      }
                      placement="top"
                      delayShow={300}
                      delayHide={150}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                    </OverlayTrigger>
                  </Form.Label>
                  <ToggleSwitch
                    checked={enrichManufacturerProduct}
                    onColor={grape}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setEnrichManufacturerProduct(!enrichManufacturerProduct)}
                    className="ml-2"
                  />
                </Form.Group>
              ) : null}
            </Row>
            <Row>
              <Form.Group className="d-flex align-items-center justify-content-between col-md-6">
                <Form.Label>
                  Ignore Product Entity Type
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        If turned on, can import both manufacturer and vendor data in a single
                        import.
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <ToggleSwitch
                  checked={ignoreEntityType}
                  onColor={grape}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => setIgnoreEntityType(!ignoreEntityType)}
                  className="ml-2"
                />
              </Form.Group>
              {entityType.value === DATA_SOURCE_ENTITY.MANUFACTURER && (
                <Form.Group className="d-flex align-items-center justify-content-between col-md-6">
                  <Form.Label>
                    Assign UPB SKUs
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          If turned on, will assign UPB SKUs to products on the file
                        </Tooltip>
                      }
                      placement="top"
                      delayShow={300}
                      delayHide={150}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                    </OverlayTrigger>
                  </Form.Label>
                  <ToggleSwitch
                    checked={assignUPBSKUs}
                    onColor={grape}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setAssignUPBSKUs(!assignUPBSKUs)}
                    className="ml-2"
                  />
                </Form.Group>
              )}
            </Row>
          </>
        ) : null}

        <Form.Group>
          <Form.Label>Import Type</Form.Label>
          <Creatable
            value={importType}
            options={DATA_SOURCE_IMPORT_TYPE_OPTIONS}
            onChange={e => setImportType(e)}
            isSearchable
            isClearable={false}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Source Type</Form.Label>
          <Select
            isDisabled={[
              DATA_SOURCE_IMPORT_TYPES.SKU_MAPPING_IMPORT,
              DATA_SOURCE_IMPORT_TYPES.IMAGE_IMPORT,
              DATA_SOURCE_IMPORT_TYPES.FLAG_UPDATE,
            ].includes(importType.value)}
            value={sourceType}
            options={DATA_SOURCE_CATEGORY_OPTIONS}
            onChange={e => setSourceType(e)}
            isSearchable
            isClearable
          />
        </Form.Group>
        {!ignoreEntityType && (
          <div>
            <Form.Group>
              <Form.Label>Entity Type</Form.Label>
              <Select
                value={entityType}
                options={DATA_SOURCE_ENTITY_OPTIONS}
                onChange={e => setEntityType(e || {})}
                isSearchable
                isClearable
              />
            </Form.Group>
            {entityNameDisabled ? null : (
              <Form.Group>
                <Form.Label>Entity Name</Form.Label>
                {entityType.value === DATA_SOURCE_ENTITY.MANUFACTURER ? (
                  <SearchableScrollableSelect
                    onChange={option => setEntity(option)}
                    fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
                    optionMapper={manufacturer => ({
                      label: manufacturer.name,
                      value: manufacturer.id,
                    })}
                    disabled={entityNameDisabled}
                    autoFocus={false}
                    placeholder="Select Manufacturer"
                  />
                ) : (
                  <Select
                    isDisabled={entityNameDisabled}
                    value={entity?.name}
                    options={entityNames}
                    onChange={e => setEntity(e)}
                    isSearchable
                    isClearable
                  />
                )}
              </Form.Group>
            )}
          </div>
        )}
        {[
          DATA_SOURCE_IMPORT_TYPES.ATTRIBUTE_IMPORT,
          DATA_SOURCE_IMPORT_TYPES.IMAGE_IMPORT,
        ].includes(importType.value) ? (
          <>
            {/* Verification status only applies for the manufacturer products as the moment. */}
            {entityType.value === DATA_SOURCE_ENTITY.MANUFACTURER ? (
              <Form.Group>
                <Form.Label>
                  Product Verification
                  <OverlayTrigger
                    overlay={<Tooltip>Whether to mark these products as verified or not?</Tooltip>}
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <Select
                  value={verificationStatus}
                  options={DATA_SOURCE_VERIFICATION_OPTIONS}
                  onChange={e => setVerificationStatus(e || {})}
                  isSearchable
                  isClearable
                />
              </Form.Group>
            ) : null}
            {[
              DATA_SOURCE_CATEGORY.GS1_S3_XML,
              DATA_SOURCE_CATEGORY.JSON_FEED,
              DATA_SOURCE_CATEGORY.S3_CSV,
              DATA_SOURCE_CATEGORY.S3_FOLDER,
            ].includes(sourceType?.value) ? (
              <Form.Group>
                <Form.Label>
                  S3 URI
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        eg: s3://cut-dry-vendor-integration/hillcrest/dev/inbound/trade_item_xml/
                      </Tooltip>
                    }
                    placement="top"
                    delayShow={300}
                    delayHide={150}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="ml-2" />
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control type="text" value={S3Url} onChange={e => setS3Url(e.target.value)} />
              </Form.Group>
            ) : null}
            {lookBackDaysRequired ? (
              <Form.Group>
                <Form.Label>Lookback Days</Form.Label>
                <Form.Control
                  type="text"
                  value={lookbackDays}
                  onChange={e => setLookbackDays(e.target.value)}
                />
              </Form.Group>
            ) : null}
            <Form.Group>
              <Form.Label>Recurring Interval</Form.Label>
              <Select
                value={intervalType}
                options={intervalTypesMock}
                onChange={e => setIntervalType(e)}
                isClearable
              />
            </Form.Group>
          </>
        ) : null}
        {delimiterRequired ? (
          <Form.Group>
            <Form.Label>Delimiter</Form.Label>
            <Form.Control
              type="text"
              value={delimiter}
              onChange={e => setDelimiter(e.target.value)}
            />
          </Form.Group>
        ) : null}
        {fileUploadRequired ? (
          <DropZone
            text="Drag and drop some files here, or click to select files"
            onUploadSuccess={newUrls => setFile(newUrls?.[0])}
            fileUrls={file ? [file.presigned_url] : []}
            isImage={false}
          />
        ) : null}
        <div className="my-3">
          <Button
            className="px-5 mr-2"
            disabled={loading || dataSourceOriginLoading}
            onClick={onSubmit}
          >
            Save
          </Button>
          <Button variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

NewDataSourceModal.propTypes = {
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default NewDataSourceModal;
