import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { areYouSure } from 'lib/utils/toast';
import { PRODUCT_LIST_ASSIGN_THUMBNAILS } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function AssignThumbnailsModal({ productListId, onHide }) {
  const OPTIONS = {
    FULL_RUN: 'To the whole product list',
    ONLY_UNASSIGNED: 'To only products without one',
  };

  const [selectedOption, setSelectedOption] = useState('ONLY_UNASSIGNED');

  const { postData: assignThumbnails } = usePost(
    PRODUCT_LIST_ASSIGN_THUMBNAILS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Product thumbnails assigning started successfully',
      }).then(() => {
        onHide();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while assigning thumbnails: ${error?.response?.data?.message}`,
      }),
  );

  const handleAssignThumbnails = () => {
    areYouSure(
      () =>
        assignThumbnails({
          product_list_id: productListId,
          full_run: selectedOption === 'FULL_RUN',
        }),
      `Are you sure you want to assign thumbnails ${OPTIONS[selectedOption]}?`,
    );
  };

  return (
    <Modal animation={false} centered show={true} onHide={onHide}>
      <div>
        <Modal.Header className="py-3 mb-0" closeButton>
          <h5>Assign Thumbnails</h5>
        </Modal.Header>
        <Modal.Body>
          {Object.entries(OPTIONS).map(([key, label]) => (
            <Row className="d-flex align-items-center my-3" key={key}>
              <Col className="d-flex align-items-center">
                <Form.Check
                  type="radio"
                  checked={selectedOption === key}
                  className="pr-0 mt-2 mr-2 float-right"
                  onChange={() => setSelectedOption(key)}
                />
                {label}
              </Col>
            </Row>
          ))}
          <hr />
          <div>
            <Button
              onClick={handleAssignThumbnails}
              size="lg"
              className="mr-2"
              disabled={selectedOption === null}
            >
              Assign Thumbnails
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

AssignThumbnailsModal.propTypes = {
  productListId: PropTypes.number,
  onHide: PropTypes.func,
};

export default AssignThumbnailsModal;
