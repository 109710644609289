import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

function UpdateReviewStatusModal({ show, onHide, showFeedback, categoryId, onSave }) {
  const [feedback, setFeedback] = useState('');
  const [notApplicable, setNotApplicable] = useState(false);

  useEffect(() => {
    if (categoryId) {
      setNotApplicable(false);
      setFeedback('');
    }
  }, [categoryId]);

  return (
    <Modal animation={false} centered size="md" show={show} onHide={onHide}>
      <Modal.Header className="border-0" closeButton>
        <h4 className="font-weight-bold">Update Review Status</h4>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <Form.Group controlId="notApplicableCheckbox">
              <Form.Check
                type="checkbox"
                label="L4 category is too broad to have a default image"
                onChange={() => setNotApplicable(!notApplicable)}
                checked={notApplicable}
              />
            </Form.Group>
          </Col>
        </Row>
        {showFeedback && !notApplicable && (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Feedback</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={feedback}
                  onChange={event => setFeedback(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <Button
              variant="primary"
              className="float-right"
              onClick={() => {
                onSave(categoryId, notApplicable, feedback);
                onHide();
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

UpdateReviewStatusModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  showFeedback: PropTypes.bool,
  categoryId: PropTypes.number,
  onSave: PropTypes.func,
};

export default UpdateReviewStatusModal;
