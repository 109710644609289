import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { css } from 'aphrodite';

import { TAXONOMY_CREATE_CATEGORY } from 'lib/networking/endpoints';
import { categoryStyles } from 'lib/css/categoryTexts';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';
import useTaxonomyOptions from 'lib/hooks/useTaxonomyOptions';

function CreateNewCategoryModal({ modalOptions, onHide, setCurrentPage, setCategoryModified }) {
  const [taxonomyLevel, setTaxonomyLevel] = useState(TaxonomyLevel.L0);
  const [selectedL0Category, setSelectedL0Category] = useState();
  const [selectedL1Category, setSelectedL1Category] = useState();
  const [selectedL2Category, setSelectedL2Category] = useState();
  const [selectedL3Category, setSelectedL3Category] = useState();
  const [selectedL4Category, setSelectedL4Category] = useState();

  useEffect(() => {
    if (modalOptions.l4Category) {
      setTaxonomyLevel(TaxonomyLevel.L4);
      setSelectedL3Category(modalOptions.l3Category);
      setSelectedL2Category(modalOptions.l2Category);
      setSelectedL1Category(modalOptions.l1Category);
      setSelectedL0Category(modalOptions.l0Category);
    } else if (modalOptions.l3Category) {
      setTaxonomyLevel(TaxonomyLevel.L3);
      setSelectedL2Category(modalOptions.l2Category);
      setSelectedL1Category(modalOptions.l1Category);
      setSelectedL0Category(modalOptions.l0Category);
    } else if (modalOptions.l2Category) {
      setTaxonomyLevel(TaxonomyLevel.L2);
      setSelectedL1Category(modalOptions.l1Category);
      setSelectedL0Category(modalOptions.l0Category);
    } else if (modalOptions.l1Category) {
      setTaxonomyLevel(TaxonomyLevel.L1);
      setSelectedL0Category(modalOptions.l0Category);
    }
  }, [modalOptions]);

  const { options: { l0Options, l1Options, l2Options, l3Options, l4Options } = {} } =
    useTaxonomyOptions(
      selectedL0Category?.label,
      selectedL1Category?.label,
      selectedL2Category?.label,
      selectedL3Category?.label,
      selectedL3Category?.label,
    );

  const onClose = () => {
    setSelectedL0Category(null);
    setSelectedL1Category(null);
    setSelectedL2Category(null);
    setSelectedL3Category(null);
    setSelectedL4Category(null);
    onHide();
  };

  const components = {
    DropdownIndicator: null,
  };

  const { postData: createCategory, loading: updating } = usePost(
    TAXONOMY_CREATE_CATEGORY,
    () => {
      SwalDialog('success', 'Successfully added the new category', 'Success', 'center', () => {
        onClose();
      });
      setCurrentPage(1);
      setCategoryModified(true);
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while creating the category: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const onSubmitNewCategory = () => {
    const existingCategoryData = {};
    const newCategoryData = {};
    if (!selectedL0Category) {
      return;
    }
    if (selectedL0Category.__isNew__) {
      newCategoryData.l0_category = selectedL0Category.value;
    } else {
      existingCategoryData.l0_category_id = selectedL0Category.value;
    }
    if (selectedL1Category) {
      if (selectedL1Category.__isNew__) {
        newCategoryData.l1_category = selectedL1Category.value;
      } else {
        existingCategoryData.l1_category_id = selectedL1Category.value;
      }
      if (selectedL2Category) {
        if (selectedL2Category.__isNew__) {
          newCategoryData.l2_category = selectedL2Category.value;
        } else {
          existingCategoryData.l2_category_id = selectedL2Category.value;
        }
        if (selectedL3Category) {
          if (selectedL3Category.__isNew__) {
            newCategoryData.l3_category = selectedL3Category.value;
          } else {
            existingCategoryData.l3_category_id = selectedL3Category.value;
          }
          if (selectedL4Category) {
            if (selectedL4Category.__isNew__) {
              newCategoryData.l4_category = selectedL4Category.value;
            } else {
              existingCategoryData.l4_category_id = selectedL4Category.value;
            }
          }
        }
      }
    }
    createCategory({
      new_category: newCategoryData,
      existing_category: existingCategoryData,
    });
  };

  return (
    <Modal centered show={modalOptions.show} onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="font-weight-bold">Add New L{taxonomyLevel} Category</Modal.Title>
      </Modal.Header>
      <Modal.Body className="font-weight-bold">
        {selectedL0Category === modalOptions.l0Category ? (
          <div>
            <p className={css(categoryStyles.l0_category)}>L0 - {selectedL0Category.label}</p>
            {selectedL1Category === modalOptions.l1Category ? (
              <div>
                <p className={css(categoryStyles.l1_category)}>L1 - {selectedL1Category.label}</p>
                {selectedL2Category === modalOptions.l2Category ? (
                  <div>
                    <p className={css(categoryStyles.l2_category)}>
                      L2 - {selectedL2Category.label}
                    </p>
                    {selectedL3Category === modalOptions.l3Category ? (
                      <div>
                        <p className={css(categoryStyles.l3_category)}>
                          L3 - {selectedL3Category.label}
                        </p>
                        <Form.Group>
                          <Form.Label>L4 Category</Form.Label>
                          <CreatableSelect
                            className="font-weight-normal"
                            components={components}
                            isClearable
                            isDisabled={!selectedL3Category}
                            placeholder="Enter the new L4 category name"
                            options={l4Options}
                            value={selectedL4Category}
                            onChange={option => setSelectedL4Category(option)}
                            formatCreateLabel={searchText => 'Create "' + searchText + '"'}
                          />
                        </Form.Group>
                      </div>
                    ) : (
                      <Form.Group>
                        <Form.Label>L3 Category</Form.Label>
                        <CreatableSelect
                          className="font-weight-normal"
                          isSearchable
                          isClearable
                          isDisabled={!selectedL2Category}
                          placeholder="Enter the new L3 category name"
                          options={l3Options}
                          value={selectedL3Category}
                          onChange={option => {
                            setSelectedL3Category(option);
                          }}
                          formatCreateLabel={searchText => 'Create "' + searchText + '"'}
                        />
                      </Form.Group>
                    )}
                  </div>
                ) : (
                  <Form.Group>
                    <Form.Label>L2 Category</Form.Label>
                    <CreatableSelect
                      className="font-weight-normal"
                      isSearchable
                      isClearable
                      isDisabled={!selectedL1Category}
                      placeholder="Enter the new L2 category name"
                      options={l2Options}
                      value={selectedL2Category}
                      onChange={option => {
                        setSelectedL2Category(option);
                      }}
                      formatCreateLabel={searchText => 'Create "' + searchText + '"'}
                    />
                  </Form.Group>
                )}
              </div>
            ) : (
              <Form.Group>
                <Form.Label>L1 Category</Form.Label>
                <CreatableSelect
                  className="font-weight-normal"
                  isSearchable
                  isClearable
                  isDisabled={!selectedL0Category}
                  placeholder="Enter the new L1 category name"
                  options={l1Options}
                  value={selectedL1Category}
                  onChange={option => {
                    setSelectedL1Category(option);
                  }}
                  formatCreateLabel={searchText => 'Create "' + searchText + '"'}
                />
              </Form.Group>
            )}
          </div>
        ) : (
          <Form.Group>
            <Form.Label>L0 Category</Form.Label>
            <CreatableSelect
              className="font-weight-normal"
              isSearchable
              isClearable
              placeholder="Enter the new L0 category name"
              options={l0Options}
              value={selectedL0Category}
              onChange={option => {
                setSelectedL0Category(option);
              }}
              formatCreateLabel={searchText => 'Create "' + searchText + '"'}
            />
          </Form.Group>
        )}
        <Button
          className="float-left mr-3"
          onClick={onSubmitNewCategory}
          disabled={
            updating ||
            (!selectedL0Category?.__isNew__ &&
              !selectedL1Category?.__isNew__ &&
              !selectedL2Category?.__isNew__ &&
              !selectedL3Category?.__isNew__ &&
              !selectedL4Category?.__isNew__)
          }
        >
          Add Category
        </Button>
        <Button variant="outline-primary" className="float-left" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
}

CreateNewCategoryModal.propTypes = {
  category: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  modalOptions: PropTypes.object,
  setCurrentPage: PropTypes.func,
  setCategoryModified: PropTypes.func,
};

export default CreateNewCategoryModal;
