import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Container from 'components/shared/Container';
import DataSourcesToValidateTable from 'components/product_content_validator/DataSourcesToValidateTable';

function ProductContentValidatorView() {
  return (
    <Container>
      <Row>
        <Col>
          <h3 className="font-weight-bold">Data Source : Product Content Validator</h3>
        </Col>
      </Row>
      <DataSourcesToValidateTable />
    </Container>
  );
}

export default ProductContentValidatorView;
