import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import ShowMore from 'components/shared/ShowMore';
import { preserveFormatClass, sectionTextClass } from 'components/upb_viewer/css/sharedStyles';

function AttributesParagraphView({ paragraph }) {
  if (!paragraph) {
    return null;
  }

  return (
    <div>
      <ShowMore max={100}>
        <div className={classNames(preserveFormatClass, sectionTextClass)}>{paragraph}</div>
      </ShowMore>
    </div>
  );
}

AttributesParagraphView.propTypes = {
  paragraph: PropTypes.string.isRequired,
};

export default AttributesParagraphView;
