import React from 'react';
import PropTypes from 'prop-types';

import { Image, Table } from 'react-bootstrap';

import placeholderProductImage from 'images/placeholder-img-product-v2.svg';

function NutritionalPanelImageTable({ nutritionalPanels }) {
  return (
    <div className="my-4">
      {nutritionalPanels?.length ? (
        <Table hover className="mt-3">
          <thead>
            <tr>
              <th>Nutritional Panel Image</th>
              <th>Date Uploaded</th>
              <th>Uploaded By</th>
            </tr>
          </thead>
          <tbody>
            {nutritionalPanels?.map(panel => (
              <tr key={panel.id}>
                <td width="230">
                  <div className="mx-4">
                    <Image
                      src={panel.panel_url ?? placeholderProductImage}
                      className="d-block"
                      alt="Product"
                      fluid
                    />
                  </div>
                </td>
                <td>{panel.date_created || '-'}</td>
                <td>{panel.uploaded_by || '-'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </div>
  );
}

NutritionalPanelImageTable.propTypes = {
  nutritionalPanels: PropTypes.array,
};

export default NutritionalPanelImageTable;
