import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import {
  DATA_STREAM_ENTITY,
  DATA_STREAM_ENTITY_OPTIONS,
  DATA_STREAM_EXPORT_TYPE_OPTIONS,
  DATA_STREAM_TYPE_OPTIONS,
} from 'lib/enums/DataStreamEnum';
import {
  DATA_STREAM,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_VENDORS_FOR_DROPDOWN,
} from 'lib/networking/endpoints';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';
import Select from 'react-select';
import usePost from 'lib/hooks/usePost';

function NewDataStreamModal({ onHide, refetch }) {
  const [newDataStream, setNewDataStream] = useState({
    streamName: '',
    streamType: { value: null, label: null },
    streamExportType: { value: null, label: null },
    streamEntityType: { value: null, label: null },
    streamEntity: { value: null, label: null },
  });

  const { postData: createNewDataStream, loading } = usePost(
    DATA_STREAM,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Successfully created',
        title: 'Success',
        position: 'center',
      }).then(() => {
        refetch();
        onHide();
      });
    },
    () => {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating',
        title: 'Error',
        position: 'center',
      });
    },
  );

  return (
    <>
      <Modal centered show={true} onHide={onHide} animation={false}>
        <Modal.Header>
          <h4>Add New Data Stream</h4>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Stream Name</Form.Label>
            <Form.Control
              onChange={e => setNewDataStream({ ...newDataStream, streamName: e.target.value })}
              value={newDataStream.streamName}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Stream Type</Form.Label>
            <Select
              value={newDataStream.streamType}
              options={DATA_STREAM_TYPE_OPTIONS}
              onChange={e => setNewDataStream({ ...newDataStream, streamType: e })}
              isSearchable
              isClearable
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Stream Export Type</Form.Label>
            <Select
              value={newDataStream.streamExportType}
              options={DATA_STREAM_EXPORT_TYPE_OPTIONS}
              onChange={e => setNewDataStream({ ...newDataStream, streamExportType: e })}
              isSearchable
              isClearable
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Entity Type</Form.Label>
            <Select
              value={newDataStream.streamEntityType}
              options={DATA_STREAM_ENTITY_OPTIONS}
              onChange={e => setNewDataStream({ ...newDataStream, streamEntityType: e })}
              isSearchable
              isClearable
            />
          </Form.Group>
          {newDataStream.streamEntityType.value && (
            <Form.Group>
              <Form.Label>Entity Name</Form.Label>
              {newDataStream.streamEntityType?.value === DATA_STREAM_ENTITY.MANUFACTURER && (
                <SearchableScrollableSelect
                  onChange={e => setNewDataStream({ ...newDataStream, streamEntity: e })}
                  fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
                  optionMapper={manufacturer => ({
                    label: manufacturer.name,
                    value: manufacturer.id,
                  })}
                  autoFocus={false}
                  placeholder="Select Manufacturer"
                />
              )}
              {newDataStream.streamEntityType?.value === DATA_STREAM_ENTITY.VENDOR && (
                <SearchableScrollableSelect
                  onChange={e => setNewDataStream({ ...newDataStream, streamEntity: e })}
                  fetchUrl={GLOBAL_VENDORS_FOR_DROPDOWN}
                  optionMapper={vendor => ({
                    label: vendor.name,
                    value: vendor.id,
                  })}
                  autoFocus={false}
                  placeholder="Select Distributor"
                />
              )}
            </Form.Group>
          )}
          <Button
            block
            disabled={loading}
            onClick={() => {
              createNewDataStream({
                stream_name: newDataStream.streamName,
                stream_type: newDataStream?.streamType?.value,
                stream_export_type: newDataStream.streamExportType?.value,
                entity_type: newDataStream.streamEntityType?.value,
                global_manufacturer_id:
                  newDataStream.streamEntityType.value === DATA_STREAM_ENTITY.MANUFACTURER
                    ? newDataStream.streamEntity.value
                    : null,
                global_vendor_id:
                  newDataStream.streamEntityType.value === DATA_STREAM_ENTITY.VENDOR
                    ? newDataStream.streamEntity.value
                    : null,
              });
            }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

NewDataStreamModal.propTypes = {
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default NewDataStreamModal;
