export const fsaManageTaxonomy = {
  prefix: 'fsa',
  iconName: 'fsaManageTaxonomy',
  icon: [
    576,
    512,
    [],
    null,
    'M320 80v64H256V80h64zM256 32c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h8v40H112c-30.9 0-56 25.1-56 56v32H48c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h64c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48h-8V288c0-4.4 3.6-8 8-8H264v40h-8c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h64c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48h-8V280H464c4.4 0 8 3.6 8 8v32h-8c-26.5 0-48 21.5-48 48v64c0 26.5 21.5 48 48 48h64c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48h-8V288c0-30.9-25.1-56-56-56H312V192h8c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H256zM48 368h64v64H48V368zm208 0h64v64H256V368zm208 0h64v64H464V368z',
  ],
};
