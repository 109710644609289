import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Table } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { auroMetalSaurus, backgroundGrey5, coolGrey, darkGrey2, squidInk } from 'lib/css/colors';

function ProductOverviewSection({ attributes = [] }) {
  const mappedAttributes = useMemo(() => {
    const result = [];
    for (let i = 0; i < attributes.length; i += 2) {
      result.push(attributes.slice(i, i + 2));
    }
    return result;
  }, [attributes]);

  if (attributes.length === 0) {
    return null;
  }

  return (
    <div className="my-4">
      <div className={css(styles.header)}>Product Overview</div>
      <div>
        <Col className={classNames(css(styles.productOverviewBox), 'p-3 my-2 d-flex flex-wrap')}>
          <Table className={css(styles.table)}>
            <tbody>
              {mappedAttributes.map((attribute, index) => (
                <tr key={index} className={index !== 0 ? css(styles.hLine) : ''}>
                  <td className={css(attributes.length === 1 ? styles.cell1Only : styles.cell1)}>
                    <div className={css(styles.auroMetalSaurus)}>
                      {attribute[0]?.display_name || ''}
                    </div>
                    <div className={css(styles.squidInk)}>
                      {attribute[0]?.attribute_value || ''}
                    </div>
                  </td>
                  <td className={css(styles.cell2)}>
                    <div className={css(styles.auroMetalSaurus)}>
                      {attribute[1]?.display_name || ''}
                    </div>
                    <div className={css(styles.squidInk)}>
                      {attribute[1]?.attribute_value || ''}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </div>
    </div>
  );
}

ProductOverviewSection.propTypes = {
  attributes: PropTypes.array,
};

const styles = StyleSheet.create({
  header: {
    fontSize: 21,
    color: coolGrey,
    fontWeight: 700,
  },
  productOverviewBox: {
    background: backgroundGrey5,
    borderRadius: 8,
    border: `1px solid ${darkGrey2}`,
  },
  table: {
    margin: 0,
  },
  cell1: {
    borderTop: 'none',
    paddingTop: 4,
    paddingBottom: 4,
  },
  cell1Only: {
    borderTop: 'none',
    paddingTop: 4,
    paddingBottom: 4,
  },
  cell2: {
    borderTop: 'none',
    paddingLeft: 28,
    paddingTop: 4,
    paddingBottom: 4,
  },
  auroMetalSaurus: {
    color: auroMetalSaurus,
  },
  squidInk: {
    color: squidInk,
  },
});

export default ProductOverviewSection;
