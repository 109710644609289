import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Spinner, Row } from 'react-bootstrap';

import {
  BRANDS_DELETE_SIMILAR_BRAND_NAMES,
  getURL,
  GLOBAL_MANUFACTURERS_DELETE_SIMILAR_MANUFACTURERS_NAMES,
} from 'lib/networking/endpoints';
import SwalDialog, { areYouSure } from 'lib/utils/toast';
import useDelete from 'lib/hooks/useDelete';

function SimilarNameEditor({ similarBrands, similarManufacturers, setIsEditing, refetch }) {
  const [selectedSimilarNamesList, setSelectedSimilarNamesList] = useState([]);

  const similarNames = useMemo(() => {
    if (similarBrands?.length) {
      return similarBrands.map(brand => ({
        label: brand.brand_name,
        value: brand.id,
      }));
    } else if (similarManufacturers?.length) {
      return similarManufacturers.map(manufacturer => ({
        label: manufacturer.manufacturer_name,
        value: manufacturer.id,
      }));
    }
  }, [similarBrands, similarManufacturers]);

  const { deleteData: deleteSimilarBrandNames, loading: deletingBrandNames } = useDelete(
    getURL(BRANDS_DELETE_SIMILAR_BRAND_NAMES, {
      similar_brand_name_ids: selectedSimilarNamesList.map(item => item.value).join(','),
    }),
    false,
    () => {
      refetch();
      setIsEditing(false);
      SwalDialog('success', 'Successfully deleted similar brand names', 'Success');
    },
    error => SwalDialog('error', error?.response?.data?.message, 'Error'),
  );

  const { deleteData: deleteSimilarManufacturerNames, loading: deletingManufacturerNames } =
    useDelete(
      getURL(GLOBAL_MANUFACTURERS_DELETE_SIMILAR_MANUFACTURERS_NAMES, {
        similar_manufacturer_name_ids: selectedSimilarNamesList.map(item => item.value).join(','),
      }),
      false,
      () => {
        refetch();
        setIsEditing(false);
        SwalDialog('success', 'Successfully deleted similar manufacturer names', 'Success');
      },
      error => SwalDialog('error', error?.response?.data?.message, 'Error'),
    );

  const handleDelete = () => {
    if (similarBrands?.length) {
      areYouSure(
        () => deleteSimilarBrandNames({}),
        `Are you sure you want to delete these similar brand names?`,
      );
    } else if (similarManufacturers?.length) {
      areYouSure(
        () => deleteSimilarManufacturerNames({}),
        `Are you sure you want to delete these similar manufacturer names?`,
      );
    }
  };

  const handleSelect = () => {
    if (similarNames?.length > 0 && selectedSimilarNamesList?.length === similarNames?.length) {
      setSelectedSimilarNamesList([]);
    } else {
      setSelectedSimilarNamesList(similarNames);
    }
  };

  return (
    <div className="container">
      <Row>
        <Col>
          {similarNames.map(similarName => (
            <div key={similarName.value} className="d-flex my-2">
              <Form.Check
                type="checkbox"
                checked={selectedSimilarNamesList.some(
                  selectedName => selectedName.value === similarName.value,
                )}
                onChange={e => {
                  if (e.target.checked) {
                    if (
                      !selectedSimilarNamesList.some(
                        selectedName => selectedName.value === similarName.value,
                      )
                    ) {
                      setSelectedSimilarNamesList([...selectedSimilarNamesList, similarName]);
                    }
                  } else {
                    setSelectedSimilarNamesList(
                      selectedSimilarNamesList.filter(
                        selectedName => selectedName.value !== similarName.value,
                      ),
                    );
                  }
                }}
              />
              {similarName.label}
            </div>
          ))}
        </Col>
        <Col className="d-flex justify-content-end">
          <div>
            <Button variant="outline-primary" onClick={handleSelect} className="mr-2">
              {similarNames?.length > 0 && selectedSimilarNamesList?.length === similarNames?.length
                ? 'Unselect All'
                : 'Select All'}
            </Button>
            <Button variant="primary" onClick={handleDelete} className="mr-2">
              {deletingBrandNames || deletingManufacturerNames ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                'Delete'
              )}
            </Button>
            <Button variant="outline-primary" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

SimilarNameEditor.propTypes = {
  similarBrands: PropTypes.array,
  similarManufacturers: PropTypes.array,
  setIsEditing: PropTypes.func,
  refetch: PropTypes.func,
};

export default SimilarNameEditor;
