import { useCallback, useState } from 'react';

import { httpPut } from 'lib/networking/http';

function usePut(url, successCallback, errorCallback) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const putData = useCallback(
    postBody => {
      setLoading(true);
      httpPut(url, postBody)
        .then(res => successCallback && successCallback(res))
        .catch(error => {
          setError(error);
          errorCallback && errorCallback(error);
        })
        .finally(() => setLoading(false));
    },
    [errorCallback, successCallback, url],
  );

  return { putData, loading, error };
}

export default usePut;
