import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { ALL_PRODUCT_LISTS } from 'lib/networking/endpoints';
import ImageClassificationState from 'lib/enums/ImageClassificationState';
import L1CategoryFilter from 'components/assets/image_recommendations/filters/L1CategoryFilter';
import useGet from 'lib/hooks/useGet';

function ImageRecommendationFilters({
  setFetchData,
  productList,
  setProductList,
  selectedL1CategoryId,
  setSelectedL1CategoryId,
  applyFilters,
  classificationState,
  setClassificationState,
  loading,
}) {
  const [selectedProductList, setSelectedProductList] = useState({ label: null });

  const { data: { product_lists = [] } = [] } = useGet(ALL_PRODUCT_LISTS);

  const productListOptions = useMemo(() => {
    return product_lists.map((list, index) => ({ label: list.name, value: index }));
  }, [product_lists]);

  const classificationStateOptions = Object.keys(ImageClassificationState).map(key => ({
    label: ImageClassificationState[key],
    value: ImageClassificationState[key],
  }));

  return (
    <div className={css(styles.container)}>
      <h4 className="font-weight-bold">Filters</h4>
      <hr className="my-0" />
      <div className="my-3">
        <Form.Group>
          <Form.Label>Select Product List</Form.Label>
          <Select
            isClearable
            options={productListOptions}
            value={selectedProductList}
            onChange={option => {
              setSelectedProductList(option);
              setProductList(product_lists[option?.value]);
            }}
          />
        </Form.Group>
      </div>
      <div className="my-3">
        <L1CategoryFilter
          selectedL1CategoryId={selectedL1CategoryId}
          setSelectedL1CategoryId={setSelectedL1CategoryId}
          parentLoading={loading}
        />
      </div>
      <div className="my-3">
        <Form.Group>
          <Form.Label>Classification State</Form.Label>
          <Select
            options={classificationStateOptions}
            value={classificationStateOptions.find(option => option.value === classificationState)}
            onChange={option => setClassificationState(option.value)}
          />
        </Form.Group>
      </div>
      <div className="my-3">
        <Button
          disabled={loading}
          onClick={() => {
            if (productList && Object.keys(productList).length) {
              applyFilters();
              setFetchData(true);
            }
          }}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '85vh',
  },
});

ImageRecommendationFilters.propTypes = {
  setFetchData: PropTypes.func,
  productList: PropTypes.object,
  setProductList: PropTypes.func,
  loading: PropTypes.bool,
  applyFilters: PropTypes.func,
  selectedL1CategoryId: PropTypes.number,
  setSelectedL1CategoryId: PropTypes.func,
  classificationState: PropTypes.string,
  setClassificationState: PropTypes.func,
};

export default ImageRecommendationFilters;
