import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row } from 'react-bootstrap';

import { NUTRITIONAL_PANELS_OF_PRODUCT } from 'lib/networking/endpoints';
import { GLOBAL_PRODUCT_TYPE } from 'lib/enums/ProductType';
import NutritionalPanelUploadModal from 'components/global_products/detail_view/assets_view/NutritionalPanelImageUploadModal';
import NutritionalPanelImageTable from 'components/global_products/detail_view/assets_view/NutritionalPanelImageTable';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ProductType } from 'lib/enums/ProductType';
import useGet from 'lib/hooks/useGet';

function GlobalProductNutritionalPanelView({ refetch, selectedProductOption }) {
  const [showNutritionalPanelUploadModal, setShowNutritionalPanelUploadModal] = useState(false);

  const selectedVendorProductId =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? selectedProductOption?.productId
      : null;
  const selectedManufacturerProductId =
    selectedProductOption?.productType === ProductType.MANUFACTURER
      ? selectedProductOption?.productId
      : null;

  const { data: { nutritional_panels, loading } = {} } = useGet(
    NUTRITIONAL_PANELS_OF_PRODUCT,
    {
      ...(selectedVendorProductId && {
        vendor_product_id: selectedVendorProductId,
      }),
      ...(selectedManufacturerProductId && {
        manufacturer_product_id: selectedManufacturerProductId,
      }),
    },
    selectedProductOption?.productType === GLOBAL_PRODUCT_TYPE,
  );

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <>
      <div className="my-4">
        <Row>
          <Col>
            <Button
              disabled={selectedProductOption === GLOBAL_PRODUCT_TYPE}
              className="float-right"
              onClick={() => setShowNutritionalPanelUploadModal(true)}
            >
              Upload Nutritional Panel Images
            </Button>
          </Col>
        </Row>
        {nutritional_panels?.length ? (
          <NutritionalPanelImageTable nutritionalPanels={nutritional_panels} />
        ) : (
          <div className="m-3">This product has no nutritional panels yet</div>
        )}
        {showNutritionalPanelUploadModal ? (
          <NutritionalPanelUploadModal
            onHide={() => setShowNutritionalPanelUploadModal(false)}
            refetch={refetch}
            selectedProductOption={selectedProductOption}
          />
        ) : null}
      </div>
    </>
  );
}

GlobalProductNutritionalPanelView.propTypes = {
  selectedProductOption: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

export default GlobalProductNutritionalPanelView;
