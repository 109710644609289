import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import dayjs from 'dayjs';

import { DATE_FORMAT_WITH_TIME } from 'lib/constants';

function ChangelogRow({ changeLog }) {
  return (
    <tr key={changeLog.id}>
      <td>{dayjs(changeLog.date_created).format(DATE_FORMAT_WITH_TIME)}</td>
      <td>{changeLog.change_log_type}</td>
      <td className={css(styles.scrollableCell)}>{changeLog.user_email}</td>
      <td className={css(styles.scrollableCell)}>{changeLog.old_value}</td>
      <td className={css(styles.scrollableCell)}>{changeLog.new_value}</td>
    </tr>
  );
}

const styles = StyleSheet.create({
  scrollableCell: {
    maxWidth: '100px',
    overflow: 'scroll',
    whiteSpace: 'nowrap',
  },
});

ChangelogRow.propTypes = {
  changeLog: PropTypes.object,
};

export default ChangelogRow;
