import React from 'react';
import PropTypes from 'prop-types';

import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { truncate } from 'lodash';

import { anchovy, bodyText, colorStyles, deeplavender } from 'lib/css/colors';

const DISPLAY_NAME_LENGTH = 65;

function NutritionalPanelCatalogCard({ nutritionalPanel, handleClick }) {
  const name = nutritionalPanel.manufacturer_product_name || nutritionalPanel.vendor_product_name;
  const sku = nutritionalPanel.manufacturer_sku || nutritionalPanel.vendor_sku;
  const entityName = nutritionalPanel.manufacturer_name || nutritionalPanel.vendor_name;
  const productId =
    nutritionalPanel.global_product_manufacturer_id || nutritionalPanel.global_product_vendor_id;

  return (
    <Card
      className={classNames('position-relative', css(styles.card))}
      onClick={() => handleClick(nutritionalPanel)}
    >
      <div className={classNames(css(styles.cardContent), 'd-flex flex-column h-100')}>
        <div
          className={classNames(
            css(styles.productImageContainer),
            'align-items-center justify-content-center d-flex',
          )}
        >
          <Card.Img
            className={css(styles.productImage)}
            variant="top"
            src={nutritionalPanel?.panel_url}
          />
        </div>
        <Card.Body className="px-0 pt-2 pb-1 d-flex flex-column">
          <div className="text-capitalize mt-2">
            {truncate(name, { length: DISPLAY_NAME_LENGTH, omission: '' })}
            {name.length > DISPLAY_NAME_LENGTH && (
              <OverlayTrigger overlay={<Tooltip>{nutritionalPanel.name}</Tooltip>}>
                <span>...</span>
              </OverlayTrigger>
            )}
          </div>
          <div className={classNames(css(colorStyles.anchovy, styles.subText), 'text-nowrap mt-1')}>
            <div
              className={css(styles.sku)}
              onClick={e => {
                e.stopPropagation();
                window.open(
                  `/global-product-detail/${nutritionalPanel.global_product_id}/` +
                    `${productId}/${nutritionalPanel.product_type}`,
                  '_blank',
                );
              }}
            >
              #{sku}
            </div>
            <div>{entityName}</div>
          </div>
        </Card.Body>
      </div>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    minWidth: 220,
    maxWidth: 220,
    minHeight: 330,
    borderRadius: 5,
    padding: 20,
    margin: '0px 10px 20px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    transition: 'all 0.3s ease 0s',
    ':hover': {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      border: '1px solid ' + anchovy,
    },
  },
  cardContent: {
    height: '100%',
  },
  productImageContainer: {
    position: 'relative',
    width: '100%',
    height: 210,
    overflow: 'hidden',
  },
  subText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    fontWeight: 600,
  },
  productImage: {
    ':hover': {
      opacity: 0.6,
    },
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  displayName: {
    textDecoration: 'none',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    color: bodyText,
  },
  brandName: {
    lineHeight: '20px',
    textDecoration: 'underline',
  },
  sku: {
    color: deeplavender,
    textDecoration: 'underline',
  },
});

NutritionalPanelCatalogCard.propTypes = {
  nutritionalPanel: PropTypes.object,
  handleClick: PropTypes.func,
};

export default NutritionalPanelCatalogCard;
