import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

function ConfirmationDeleteModal({ onHide, onSave }) {
  const [confirmationtext, setConfirmationtext] = useState('');

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Delete Objects ?</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            To confirm deletion, type <em>delete</em> in the text input field
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={'delete'}
            className={css(styles.formControl)}
            onChange={e => setConfirmationtext(e.target.value)}
          />
        </Form.Group>
        <Button
          disabled={confirmationtext !== 'delete'}
          variant="outline-primary"
          className="mr-2"
          onClick={() => onSave()}
        >
          Delete
        </Button>
        <Button variant="outline-primary" onClick={() => onHide()}>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  formControl: {
    '::placeholder': {
      fontStyle: 'italic',
    },
  },
});

ConfirmationDeleteModal.propTypes = {
  onHide: PropTypes.func,
  onSave: PropTypes.func,
};

export default ConfirmationDeleteModal;
