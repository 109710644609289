import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { truncate } from 'lodash';
import { useLocation } from 'react-router-dom';

import { anchovy, bodyText } from 'lib/css/colors';
import { constructUrlPathSegment } from 'components/upb_viewer/UPBViewer';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';
import UPBTaxonomyDetailsUpdateModal from 'components/upb_viewer/UPBTaxonomyDetailsUpdateModal';

const DISPLAY_NAME_LENGTH = 65;

function UPBCategoryCard({ category, refetch, showEditIcon }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const location = useLocation();
  const productLink = `${location.pathname}/${constructUrlPathSegment({
    value: category.id,
    label: category.category_name,
  })}`;
  const categoryName = category.taxonomy_display_name || category.category_name;
  return (
    <Card className={classNames('position-relative', css(styles.card))}>
      <div className={classNames(css(styles.cardContent), 'd-flex flex-column h-100')}>
        <div
          className={classNames(
            css(styles.productImageContainer),
            'align-items-center justify-content-center d-flex',
          )}
        >
          <LinkContainer className={pointerOnHover} to={productLink}>
            <Card.Img
              className={css(styles.productImage)}
              variant="top"
              src={category.icon_image_url || category.taxonomy_image || placeholderProductImage}
            />
          </LinkContainer>
        </div>
        <Card.Body className="px-0 pt-2 pb-1 d-flex flex-column">
          <Link to={productLink} className={css(styles.displayName)}>
            <div className="text-capitalize mt-2">
              {truncate(categoryName, { length: DISPLAY_NAME_LENGTH, omission: '' })}
              {categoryName?.length > DISPLAY_NAME_LENGTH && (
                <OverlayTrigger overlay={<Tooltip>{categoryName}</Tooltip>}>
                  <span>...</span>
                </OverlayTrigger>
              )}
              {showEditIcon && (
                <FontAwesomeIcon
                  icon={faEdit}
                  className="ml-2"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowEditModal(true);
                  }}
                />
              )}
            </div>
          </Link>
        </Card.Body>
      </div>
      {showEditModal && (
        <UPBTaxonomyDetailsUpdateModal
          category={category}
          onHide={() => setShowEditModal(false)}
          refetch={refetch}
        />
      )}
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    minWidth: 150,
    maxWidth: 150,
    minHeight: 150,
    maxHeight: 150,
    borderRadius: 5,
    padding: 15,
    margin: '0px 10px 20px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    transition: 'all 0.3s ease 0s',
    ':hover': {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      border: '1px solid ' + anchovy,
    },
  },
  cardContent: {
    height: '100%',
  },
  productImageContainer: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  productImage: {
    ':hover': {
      opacity: 0.6,
    },
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  displayName: {
    textDecoration: 'none',
    fontSize: 10,
    color: bodyText,
  },
});

UPBCategoryCard.propTypes = {
  category: PropTypes.object,
  refetch: PropTypes.func,
  showEditIcon: PropTypes.bool,
};

export default UPBCategoryCard;
