import React, { useState } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SplitPane from 'react-split-pane';
import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import { useHistory, useParams } from 'react-router-dom';

import ActionDropDown from 'components/shared/ActionDropDown';
import { areYouSure } from 'lib/utils/toast';
import Container from 'components/shared/Container';
import {
  getURL,
  GLOBAL_VENDORS_CREATE_DEMO_CATALOG,
  GLOBAL_VENDORS_GET_BY_ID,
  GLOBAL_VENDORS_BROKEN_IMAGE_DELETE,
  GLOBAL_VENDORS_UPDATE,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import LogoUpdateModal from 'components/shared/LogoUpdateModal';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';
import VendorDetailSplitView from 'components/manage_vendors/vendor_details/VendorDetailSplitView';
import VendorLogoSplitView from 'components/manage_vendors/vendor_details/VendorLogoSplitView';
import usePost from 'lib/hooks/usePost';

function VendorDetailsView() {
  const { vendorID } = useParams();
  const history = useHistory();
  const [showVendorLogoModal, setShowVendorLogoModal] = useState(false);

  const {
    data: { vendor } = {},
    loading,
    error,
    refetch,
  } = useGet(getURL(GLOBAL_VENDORS_GET_BY_ID, { vendor_id: vendorID }), {}, !vendorID);

  const { postData: deleteBrokenImages } = usePost(
    GLOBAL_VENDORS_BROKEN_IMAGE_DELETE,
    () =>
      Swal.fire({
        title: 'Success',
        text: `Started removing broken image links of ${vendor.name}.`,
        icon: 'success',
      }),
    error =>
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        icon: 'error',
      }),
  );

  const { postData: createDemoCatalog } = usePost(
    GLOBAL_VENDORS_CREATE_DEMO_CATALOG,
    () =>
      Swal.fire({
        title: 'Success',
        text: `Started creating demo catalog for ${vendor.name}.`,
        icon: 'success',
      }),
    error =>
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        icon: 'error',
      }),
  );

  const { postData: updateVendor, loading: updateLoading } = usePost(
    GLOBAL_VENDORS_UPDATE,
    () => {
      SwalDialog('success', 'Successfully updated vendor', 'Success', 'center', refetch());
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while updating the vendor: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !vendor) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col xs={4}>
          <h3 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
              onClick={() => history.push('/enrich-products/manage-vendors')}
            />
            All Vendors
          </h3>
        </Col>
        <Col className="d-flex mr-5 justify-content-end">
          <ActionDropDown
            submenuItems={[
              {
                title: 'Remove Broken images',
                action: () => deleteBrokenImages({ vendor_id: vendorID }),
              },
              {
                title: 'Create Demo Catalog',
                action: () =>
                  areYouSure(
                    () => createDemoCatalog({ vendor_id: vendorID }),
                    `Are you sure you want to create a demo catalog for ${vendor.name}?`,
                  ),
                disabled: !vendor.is_test_vendor,
              },
            ]}
          />
        </Col>
      </Row>
      <SplitPane
        primary="first"
        split="vertical"
        defaultSize="25%"
        className={css(styles.splitPane)}
        allowResize={false}
      >
        <VendorLogoSplitView
          vendor={vendor}
          refetch={refetch}
          setShowVendorLogoModal={setShowVendorLogoModal}
        />
        <VendorDetailSplitView vendor={vendor} refetch={refetch} />
      </SplitPane>
      {showVendorLogoModal ? (
        <LogoUpdateModal
          product={vendor}
          update={updateVendor}
          onHide={() => {
            setShowVendorLogoModal(false);
            refetch();
          }}
          loading={updateLoading}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  splitPane: {
    position: 'relative',
    minHeight: '80vh',
  },
});

export default VendorDetailsView;
