import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { uniqBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import ActionDropDown from 'components/shared/ActionDropDown';
import { ATTRIBUTES_SUB_SECTIONS_WITH_SECTIONS, EXPORT_ATTRIBUTES } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { ManageAttributeValueContext } from 'components/manage_attribute_value/ManageAttributeValueContainer';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function ManageAttributesFilterPanel({
  setShowNewGlobalAttributeModal,
  setShowNewAttributesModal,
  setShowAttributeUpdateModal,
  mergeSelectedAttributes,
}) {
  const [searchText, setSearchText] = useState('');
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubSection, setSelectedSubSection] = useState(null);
  const [searchAttribute] = useDebounce(searchText, 500);

  const { attributes, setFilteredAttributes } = useContext(ManageAttributeValueContext);

  const { data: { data: subSections = [] } = [] } = useGet(
    ATTRIBUTES_SUB_SECTIONS_WITH_SECTIONS,
    {},
  );

  const { postData: exportAttributes, loading: attributesExportLoading } = usePost(
    EXPORT_ATTRIBUTES,
    () => {
      SwalDialog('success', 'export started.', 'Success', 'center');
    },
    () => {
      SwalDialog('error', 'An error occurred during the export.', 'Error', 'center');
    },
  );

  useEffect(() => {
    let tempAttributes = attributes;
    if (selectedSection) {
      tempAttributes = attributes.filter(
        attr => attr.attribute_sub_section.attribute_section.id === selectedSection.value,
      );
    }
    if (selectedSubSection) {
      tempAttributes = attributes.filter(
        attr => attr.attribute_sub_section.id === selectedSubSection.value,
      );
    }
    if (searchAttribute) {
      tempAttributes = tempAttributes.filter(
        attr =>
          attr.attribute_name?.toLowerCase().includes(searchAttribute.toLowerCase()) ||
          attr.global_attribute_name?.toLowerCase().includes(searchAttribute.toLowerCase()),
      );
    }
    if (!searchAttribute && !selectedSection && !selectedSubSection) {
      setFilteredAttributes(attributes);
    } else {
      setFilteredAttributes(tempAttributes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes, searchAttribute, selectedSection, selectedSubSection]);

  const sectionOptions = useMemo(() => {
    if (selectedSubSection) {
      return uniqBy(
        subSections
          .filter(subSection => subSection.id === selectedSubSection.value)
          .map(subSection => ({
            label: subSection.attribute_section.attribute_section_name,
            value: subSection.attribute_section.id,
          })),
        'value',
      );
    } else {
      return uniqBy(
        subSections.map(subSection => ({
          label: subSection.attribute_section.attribute_section_name,
          value: subSection.attribute_section.id,
        })),
        'value',
      );
    }
  }, [subSections, selectedSubSection]);

  const subSectionOptions = useMemo(() => {
    if (selectedSection) {
      return subSections
        .filter(subSection => subSection.attribute_section.id === selectedSection.value)
        .map(subSection => ({
          label: subSection.attribute_sub_section_name,
          value: subSection.id,
        }));
    }
    return subSections.map(subSection => ({
      label: subSection.attribute_sub_section_name,
      value: subSection.id,
    }));
  }, [subSections, selectedSection]);

  if (attributesExportLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Row>
        <Col sm="2">
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              onChange={e => setSearchText(e.target.value)}
              type="text"
              placeholder="Search Attributes"
              value={searchText || ''}
            />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Select
              options={sectionOptions}
              onChange={option => setSelectedSection(option)}
              isMulti={false}
              isClearable
            />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label>Sub Category</Form.Label>
            <Select
              options={subSectionOptions}
              onChange={option => setSelectedSubSection(option)}
              isMulti={false}
              isClearable
            />
          </Form.Group>
        </Col>
        <Col sm="6" className="mt-1">
          <div className="float-right mt-3">
            <ActionDropDown
              className="mr-4"
              submenuItems={[
                {
                  title: 'Add New Attribute',
                  action: () => setShowNewAttributesModal(true),
                },
                {
                  title: 'Add New Global Attribute',
                  action: () => setShowNewGlobalAttributeModal(true),
                },
                {
                  title: 'Export All attributes with values',
                  action: () => exportAttributes({ with_attribute_values: true }),
                },
                {
                  title: 'Export All attributes',
                  action: () => exportAttributes({ with_attribute_values: false }),
                },
                {
                  title: 'Update attributes',
                  action: () => setShowAttributeUpdateModal(true),
                },
                {
                  title: 'Merge two attributes',
                  action: () => mergeSelectedAttributes(true),
                },
              ]}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

ManageAttributesFilterPanel.propTypes = {
  setShowNewGlobalAttributeModal: PropTypes.func,
  setShowNewAttributesModal: PropTypes.func,
  setShowAttributeUpdateModal: PropTypes.func,
  mergeSelectedAttributes: PropTypes.func,
};

export default ManageAttributesFilterPanel;
