import React, { createContext, useState } from 'react';

import { Route } from 'react-router-dom';

import GlobalProductsDetailView from 'components/global_products/detail_view/GlobalProductsDetailView';

const GlobalProductsDetailContext = createContext({});

function GlobalProductsDetailContainer() {
  const [selectedGlobalProduct, setSelectedGlobalProduct] = useState();
  const [thumbnailImage, setThumbnailImage] = useState({});
  const [otherImages, setOtherImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cndProductScore, setCndProductScore] = useState({});

  return (
    <GlobalProductsDetailContext.Provider
      value={{
        selectedGlobalProduct,
        setSelectedGlobalProduct,
        thumbnailImage,
        setThumbnailImage,
        otherImages,
        setOtherImages,
        loading,
        setLoading,
        cndProductScore,
        setCndProductScore,
      }}
    >
      <Route path="/global-product-detail/:globalProductId/:productID?/:productType?" exact>
        <GlobalProductsDetailView />
      </Route>
    </GlobalProductsDetailContext.Provider>
  );
}

export default GlobalProductsDetailContainer;
export { GlobalProductsDetailContext };
