import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Image, Media } from 'react-bootstrap';

import { anchovy } from 'lib/css/colors';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import pointerOnHover from 'lib/css/pointerOnHover';
import VendorRow from 'components/manage_vendors/vendor_details/VendorRow';

function VendorLogoSplitView({ vendor, refetch, setShowVendorLogoModal }) {
  return (
    <div className={classNames(css(styles.container), 'mt-2')}>
      <Media
        className={pointerOnHover}
        onClick={() => {
          setShowVendorLogoModal(true);
        }}
      >
        <Image
          className={css(styles.square)}
          src={vendor?.logo_url || placeholderProductImage}
          alt="Vendor Logo"
        />
      </Media>
      <p className="small text-muted">* Click on image to change logo</p>
      <h3 className="font-weight-bold py-4 ">{vendor.name}</h3>
      <div>
        <p className={css(styles.label)}>ID</p>
        <p className={css(styles.value)}>{vendor.id}</p>
      </div>
      <div>
        <VendorRow label={'cnd_id'} title={'Cut and Dry ID'} vendor={vendor} refetch={refetch} />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: '1.9rem',
  },
  square: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    border: '2px solid ' + anchovy,
    marginBottom: '1rem',
    padding: '0.5rem',
  },
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  value: {
    display: 'block',
  },
  toggleSwitchContainer: {
    marginBottom: '1rem',
  },
});

VendorLogoSplitView.propTypes = {
  vendor: PropTypes.object,
  refetch: PropTypes.func,
  setShowVendorLogoModal: PropTypes.func,
};

export default VendorLogoSplitView;
