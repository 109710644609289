import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { PRODUCT_LISTS } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function CreateProductListModal({ show, onHide, refetch }) {
  const [name, setName] = useState('');
  const history = useHistory();

  const onClose = () => {
    setName('');
    onHide();
  };

  const { postData: createList, loading } = usePost(
    PRODUCT_LISTS,
    response => {
      const prodList = response.data.product_list;
      refetch();
      Swal.fire({ title: 'Product list created successfully.', icon: 'success' }).then(() => {
        onClose();
        history.push('/product-lists/' + prodList.id);
      });
    },
    error =>
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        icon: 'error',
      }),
  );

  return (
    <Modal animation={false} centered show={show} onHide={onClose}>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title>Create List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>List Name</Form.Label>
            <Form.Control type="text" value={name || ''} onChange={e => setName(e.target.value)} />
          </Form.Group>
          <div className="w-100 d-flex justify-content-end my-3">
            <Button
              variant="outline-secondary"
              className="mr-2"
              disabled={loading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button disabled={!name || loading} onClick={() => createList({ name })}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

CreateProductListModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default CreateProductListModal;
