import React, { useMemo } from 'react';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import AttributeChangesSection from 'components/product_updates/product_update_details/AttributeChangesSection';
import { CHANGE_LOG_GROUP, CHANGE_LOGS_UPDATE_STATUS } from 'lib/networking/endpoints';
import ChangeLogsSection from 'components/product_updates/product_update_details/ChangeLogsSection';
import CHANGE_LOG_STATUS from 'lib/enums/ChangeLogStatus';
import Container from 'components/shared/Container';
import ImageChangesSection from 'components/product_updates/product_update_details/ImageChangesSection';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import useQueryString from 'lib/hooks/useQueryString';

function ProductUpdateDetails() {
  const history = useHistory();
  const queryString = useQueryString();
  const requestId = queryString.get('requestId');
  const productId = queryString.get('productId');
  const productType = queryString.get('productType');

  const { data, loading, refetch } = useGet(
    CHANGE_LOG_GROUP,
    {
      request_id: requestId,
      product_id: productId,
      product_type: productType,
    },
    !requestId || !productId || !productType,
  );

  const { postData: updateChaneLogsStatus, loading: statusUpdating } = usePost(
    CHANGE_LOGS_UPDATE_STATUS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Change logs successfully resolved',
        title: 'success',
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while resolving changes: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  const { product, changeLogs, attributeChanges, imageChanges } = useMemo(() => {
    if (!data) {
      return { product: null, changeLogs: [], attributeChanges: [], imageChanges: [] };
    }
    const product = data.product;
    const changeLogs = data.change_logs;
    const attributeChanges = [];
    const imageChanges = [];
    changeLogs.forEach(changeLog =>
      changeLog.is_image ? imageChanges.push(changeLog) : attributeChanges.push(changeLog),
    );
    return { product, changeLogs, attributeChanges, imageChanges };
  }, [data]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return null;
  }

  return (
    <Container>
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mr-2')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/product-updates')}
        />
        {product.name} - {product.sku}
      </h3>
      <div className="w-100 d-flex justify-content-end">
        <Button
          disabled={statusUpdating}
          onClick={() =>
            updateChaneLogsStatus({
              change_log_ids: changeLogs.map(changeLog => changeLog.id),
              status: CHANGE_LOG_STATUS.ATTENDED,
            })
          }
          className="px-5 py-2 font-weight-bold"
        >
          Resolve
        </Button>
      </div>
      {attributeChanges.length ? (
        <div className="mb-4">
          <AttributeChangesSection attributeChanges={attributeChanges} />
        </div>
      ) : null}
      {imageChanges.length ? (
        <div className="mb-4">
          <ImageChangesSection imageChanges={imageChanges} />
        </div>
      ) : null}
      {changeLogs.length ? (
        <div className="mb-4">
          <ChangeLogsSection changeLogs={changeLogs} />
        </div>
      ) : null}
    </Container>
  );
}

export default ProductUpdateDetails;
