import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { fontStyles } from 'components/shared/nutrition_facts/sharedStyles';
import Separator from 'components/shared/nutrition_facts/Separator';

function MainNutritionRow({ title, quantity, uom, percentage }) {
  if (!quantity && !percentage) {
    return null;
  }

  const valueString = [quantity, uom].filter(x => x).join('');
  return (
    <>
      <div className="w-100 d-flex justify-content-between">
        <div>
          <span className={fontStyles('16px', true)}>{title}</span>
          <span className={classNames(fontStyles('16px'), 'ml-2')}>{valueString}</span>
        </div>
        {percentage && <div className={fontStyles('16px', true)}>{`${percentage}%`}</div>}
      </div>
      <Separator />
    </>
  );
}

MainNutritionRow.propTypes = {
  title: PropTypes.string,
  quantity: PropTypes.string,
  uom: PropTypes.string,
  percentage: PropTypes.string,
};

export default MainNutritionRow;
