import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { ADD_IMAGES_TO_PRODUCT } from 'lib/networking/endpoints';
import RecommendedImagesTabView from 'components/assets/image_recommendations/recommendations/RecommendedImagesTabView';
import usePost from 'lib/hooks/usePost';

function RecommendedImagesModal({ product, show, onHide, refetch }) {
  const [selectedThumbnailId, setSelectedThumbnailId] = useState();
  const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState();
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [webImages, setWebImages] = useState([]);
  const [hiddenTabs, setHiddenTabs] = useState([]);

  const { postData: addImages, loading: updating } = usePost(
    ADD_IMAGES_TO_PRODUCT,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Images added to the product successfully',
        title: 'Success',
        timer: 500,
      }).then(() => {
        onHide();
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while assigning images: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  return (
    <Modal animation={false} centered size="xl" show={show} onHide={onHide}>
      <Modal.Header className="border-0" closeButton>
        <h4 className="font-weight-bold">Confirm Product Images</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <Image fluid src={selectedThumbnailUrl} className="w-100" />
          </Col>
          <Col className="font-weight-bold">
            <Row className="my-3">
              <Col>
                <div className="text-secondary">Name</div>
                <div>{product.product_name}</div>
              </Col>
            </Row>
            <Row className="my-3">
              <Col md={3}>
                <div className="text-secondary">SKU</div>
                <div>{product.sku}</div>
              </Col>
              <Col md={3}>
                <div className="text-secondary">Brand</div>
                <div>{product.brand}</div>
              </Col>
              <Col md={3}>
                <div className="text-secondary">
                  {product.manufacturer ? 'Manufacturer' : 'Distributor'}
                </div>
                <div>{product.manufacturer || product.vendor}</div>
              </Col>
              <Col>
                <div className="text-secondary">L4 Category</div>
                <div>{product.l4_category_name}</div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <RecommendedImagesTabView
          product={product}
          l4CategoryId={product.l4_category_id}
          selectedThumbnailId={selectedThumbnailId}
          setSelectedThumbnailId={setSelectedThumbnailId}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
          webImages={webImages}
          setWebImages={setWebImages}
          hiddenTabs={hiddenTabs}
          setHiddenTabs={setHiddenTabs}
          selectedThumbnailUrl={selectedThumbnailUrl}
          setSelectedThumbnailUrl={setSelectedThumbnailUrl}
        />
        <div className="my-3">
          <Button
            onClick={() =>
              addImages({
                product_id: product.product_id,
                product_type: product.product_type,
                thumbnail_id: selectedThumbnailId,
                thumbnail_url: selectedThumbnailUrl,
                image_ids: selectedImageIds,
                uploaded_images: uploadedImages,
                web_images: webImages,
              })
            }
            size="lg"
            className="mr-2"
            disabled={
              (!selectedImageIds.length && !uploadedImages.length && !webImages.length) || updating
            }
          >
            Link Images
          </Button>
          <Button size="lg" variant="outline-primary" onClick={onHide}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

RecommendedImagesModal.propTypes = {
  product: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default RecommendedImagesModal;
