import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

import ProductRow from 'components/product_lists/product_list/ProductRow';

function ProductListTableView({ products }) {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>SKU</th>
          <th>Thumbnail</th>
          <th>Name</th>
          <th>L4 Category</th>
          <th>Last Modified</th>
          <th>Created</th>
        </tr>
      </thead>
      <tbody>
        {products.length ? (
          products.map(product => (
            <ProductRow key={`${product.product_type}-${product.product_id}`} product={product} />
          ))
        ) : (
          <tr>
            <td className="text-center" colSpan="100%">
              No products found for the given filters
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

ProductListTableView.propTypes = {
  products: PropTypes.array,
};

export default ProductListTableView;
