import React from 'react';
import PropTypes from 'prop-types';

import AddedSugarsRow from 'components/shared/nutrition_facts/AddedSugarsRow';
import MainNutritionRow from 'components/shared/nutrition_facts/MainNutritionRow';
import { NUTRITION_ATTRIBUTES } from 'components/upb_viewer/pdp_components/ProductNutritionFactsView';
import SubNutritionRow from 'components/shared/nutrition_facts/SubNutritionRow';
import useFindAttributeValueByName from 'lib/hooks/product/useFindAttributeValueByName';

function CarbohydratesSection({ nutritionAttributes }) {
  const carbohydratesQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CARBOHYDRATES_QUANTITYCONTAINED,
  );
  const carbohydratesUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CARBOHYDRATES_UOM,
  );
  const carbohydratesDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CARBOHYDRATES_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const totalDietFiberQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_DIETARY_FIBER_QUANTITYCONTAINED,
  );
  const totalDietFiberUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_DIETARY_FIBER_UOM,
  );
  const totalDietFiberDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_DIETARY_FIBER_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const solubleFiberQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SOLUBLE_FIBER_QUANTITYCONTAINED,
  );
  const solubleFiberUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SOLUBLE_FIBER_UOM,
  );
  const insolubleFiberQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.INSOLUBLE_FIBER_QUANTITYCONTAINED,
  );
  const insolubleFiberUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.INSOLUBLE_FIBER_UOM,
  );
  const totalSugarQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_SUGAR_QUANTITYCONTAINED,
  );
  const totalSugarUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_SUGAR_UOM,
  );
  const addedSugarQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.ADDED_SUGAR_QUANTITYCONTAINED,
  );
  const addedSugarUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.ADDED_SUGAR_UOM,
  );
  const addedSugarDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.ADDED_SUGAR_RDI_DAILYVALUEINTAKEPERCENT,
  );
  if (!carbohydratesQuantity) {
    return null;
  }
  return (
    <>
      <MainNutritionRow
        title="Total Carbohydrates"
        quantity={carbohydratesQuantity}
        uom={carbohydratesUom}
        percentage={carbohydratesDailyValue}
      />
      <SubNutritionRow
        title="Dietary Fiber"
        quantity={totalDietFiberQuantity}
        uom={totalDietFiberUom}
        percentage={totalDietFiberDailyValue}
        offset={1}
        boldPercentage
      />
      <SubNutritionRow
        title="Soluble Fiber"
        quantity={solubleFiberQuantity}
        uom={solubleFiberUom}
        offset={2}
      />
      <SubNutritionRow
        title="Insoluble Fiber"
        quantity={insolubleFiberQuantity}
        uom={insolubleFiberUom}
        offset={2}
      />
      <SubNutritionRow
        title="Total Sugars"
        quantity={totalSugarQuantity}
        uom={totalSugarUom}
        offset={1}
        separatorOffset={addedSugarQuantity ? 2 : 0}
      />
      <AddedSugarsRow
        quantity={addedSugarQuantity}
        uom={addedSugarUom}
        percentage={addedSugarDailyValue}
      />
    </>
  );
}

CarbohydratesSection.propTypes = {
  nutritionAttributes: PropTypes.array,
};

export default CarbohydratesSection;
