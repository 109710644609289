import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Image, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { anchovy, lightPurple } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';

function ImagesSection({ images, onImageClick, isImageSelected }) {
  return (
    <Row noGutters>
      {images.map(image => (
        <Col md={2} key={image.image_id || image.id} className="px-1 my-1">
          <div
            className={classNames(
              'h-100 mt-1',
              pointerOnHover,
              css(
                styles.imageDiv,
                isImageSelected(image?.url) ? styles.selectedImageDiv : styles.unselectedImageDiv,
              ),
            )}
            onClick={() => onImageClick(image)}
          >
            {image.facing ? (
              <div className={css(styles.smallText)}>
                <span>Facing: </span>
                <span className="font-weight-bold">{image.facing}</span>
              </div>
            ) : null}
            {image.category ? (
              <div className={css(styles.smallText)}>
                <span>Category: </span>
                <span className="font-weight-bold">{image.category}</span>
              </div>
            ) : null}
            {image.manufacturer_name ? (
              <div className={css(styles.smallText)}>
                <span>Manufacturer: </span>
                <span className="font-weight-bold">{image.manufacturer_name}</span>
              </div>
            ) : null}
            {image.vendor_name ? (
              <div className={css(styles.smallText)}>
                <span>Distributor: </span>
                <span className="font-weight-bold">{image.vendor_name}</span>
              </div>
            ) : null}
            <Image fluid src={image.url} />
          </div>
        </Col>
      ))}
    </Row>
  );
}

const styles = StyleSheet.create({
  imageDiv: {
    ':hover': {
      opacity: 0.6,
    },
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
  },
  unselectedImageDiv: {
    border: `1px solid ${anchovy}`,
    ':hover': {
      border: `3px solid ${anchovy}`,
    },
  },
  selectedImageDiv: {
    border: `4px solid ${lightPurple}`,
  },
  smallText: {
    fontSize: 12,
  },
  alwaysBottom: {
    position: 'absolute',
    bottom: 0,
  },
});

ImagesSection.propTypes = {
  images: PropTypes.array,
  onImageClick: PropTypes.func,
  isImageSelected: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
};

export default ImagesSection;
