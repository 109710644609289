import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { isEqual } from 'lodash';

import ActionDropDown from 'components/shared/ActionDropDown';
import { areYouSure } from 'lib/utils/toast';
import {
  AUTO_CLASSIFICATION_PRODUCT,
  AUTO_CLASSIFICATION_PRODUCT_DOWNLOAD_CSV,
  AUTO_CLASSIFICATION_PRODUCT_REJECT_PREDICTIONS,
  AUTO_CLASSIFICATION_PRODUCT_UPDATE_CATEGORIES,
} from 'lib/networking/endpoints';
import { DEFAULT_CATEGORY_VIEW_PAGE_SIZE, AUTOMATIC_CLASSIFIER_COLUMNS } from 'lib/constants';
import AISuggestedClassificationProductTable from 'components/ai_suggested_classification_product/AISuggestedClassificationProductTable';
import PaginationComponent from 'components/shared/PaginationComponent';
import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import { useSelected } from 'lib/hooks/useSelected';
import { useShiftSelected } from 'lib/hooks/useShiftSelected';

const AISuggestedClassifierProductContext = createContext({});

const DefaultAISuggestedClassifierView = {
  name: 'Default Automatic Classifier View',
  columns: AUTOMATIC_CLASSIFIER_COLUMNS.map(column => ({ name: column, visible: true })),
};

function AISuggestedClassificationProductView() {
  const [view, setView] = useState(DefaultAISuggestedClassifierView);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_CATEGORY_VIEW_PAGE_SIZE);
  const [searchFilters, setSearchFilters] = useState({});
  const [searchFiltersId, setSearchFiltersId] = useState({});
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [sortConfig, setSortConfig] = useState({ field: '', direction: '' });
  const [searchedText, setSearchedText] = useState({});

  const {
    data: { data: { count: productCount } = {} } = {},
    loading: productsCountLoading,
    error: errorLoadingProductCount,
    refetch: refetchCount,
  } = useGet(AUTO_CLASSIFICATION_PRODUCT, {
    filters: JSON.stringify({
      ...searchFilters,
      ...searchFiltersId,
    }),
    count_only: true,
  });

  const {
    data: {
      data: {
        products,
        correct_prediction_count: correctPredictionCount,
        rejected_prediction_count: rejectedPredictionCount,
      } = {},
    } = {},
    loading: productsLoading,
    error: errorLoadingProducts,
    refetch,
  } = useGet(AUTO_CLASSIFICATION_PRODUCT, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    sort_by: sortConfig?.field,
    sort_direction: sortConfig?.direction,
    filters: JSON.stringify({
      ...searchFilters,
      ...searchFiltersId,
    }),
  });

  const { postData: classifyProducts, loading: classifyingProducts } = usePost(
    AUTO_CLASSIFICATION_PRODUCT_UPDATE_CATEGORIES,
    () => {
      SwalDialog(
        'success',
        `${selectedIDs.length} product(s) successfully classified`,
        'Success',
        'center',
        () => {
          clearSelectedIDs();
          refetch();
          refetchCount();
        },
      );
    },
    () => {
      SwalDialog('error', 'Error occurred while Updating Products', 'Error', 'center');
    },
  );

  const { postData: rejectPredictions, loading: rejectingPredictions } = usePost(
    AUTO_CLASSIFICATION_PRODUCT_REJECT_PREDICTIONS,
    () => {
      SwalDialog(
        'success',
        `${selectedIDs.length} predictions(s) successfully rejected`,
        'Success',
        'center',
        () => {
          clearSelectedIDs();
          refetch();
          refetchCount();
        },
      );
    },
    () => {
      SwalDialog('error', 'Error occurred while rejecting products', 'Error', 'center');
    },
  );

  const { postData: exportAutoClassificationProducts, loading: productsExporting } = usePost(
    AUTO_CLASSIFICATION_PRODUCT_DOWNLOAD_CSV,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () =>
      SwalDialog(
        'error',
        'An error occurred while trying to download the global products',
        'Error',
        'center',
      ),
  );

  const handleExportAutoClassificationProducts = rejectsOnly =>
    exportAutoClassificationProducts({
      sort_by: sortConfig?.field,
      sort_direction: sortConfig?.direction,
      filters: {
        ...searchFilters,
        ...searchFiltersId,
      },
      rejects_only: rejectsOnly,
    });

  const onColumnSearch = (column, searchText, searchId) => {
    setSearchFilters(prevState => ({ ...prevState, [column]: searchText || '' }));
    if (column.includes('category') && !column.includes('vendor')) {
      setSearchFiltersId(prevState => ({ ...prevState, [`${column}_id`]: searchId || '' }));
    }
  };

  const { change: changeSelectedIDs, clear: clearSelectedIDs } = useSelected(
    selectedIDs,
    setSelectedIDs,
  );

  useEffect(() => {
    // clear the selected ids when a search/filter is done
    if (!isEqual(searchFilters, searchedText)) {
      setSearchedText(searchFilters);
      clearSelectedIDs();
    }
  }, [searchFilters, clearSelectedIDs, setSearchedText, searchedText]);

  const setSelectedIDsWithShift = useShiftSelected(
    products?.map(
      product =>
        `${product.acp_id}-${product.product_type}-${product.id}-` +
        `${product.global_product_id}-${product.l4_category_id}`,
    ) || [],
    changeSelectedIDs,
  );

  const updatePageNumber = pageNumber => {
    setCurrentPage(pageNumber);
    clearSelectedIDs();
  };

  const totalPredictions = correctPredictionCount + rejectedPredictionCount;
  const approvedPredictionPercentage =
    totalPredictions > 0 ? (correctPredictionCount / totalPredictions) * 100 : 0;

  if (errorLoadingProducts) {
    return null;
  }

  return (
    <AISuggestedClassifierProductContext.Provider
      value={{
        view,
        setView,
        products,
        productCount,
        productsLoading,
        productsCountLoading,
        errorLoadingProducts,
        errorLoadingProductCount,
        classifyingProducts,
        rejectingPredictions,
        refetch,
        refetchCount,
        onColumnSearch,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        searchFilters,
        searchFiltersId,
        selectedIDs,
        setSelectedIDs,
        sortConfig,
        setSortConfig,
      }}
    >
      <div className={css(styles.container)}>
        <div className="d-flex align-items-start justify-content-between">
          <h3 className="mb-1 font-weight-bold">AI Suggested Classifications</h3>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column align-items-center">
              <span>
                <strong>
                  Approved Prediction Percentage: {approvedPredictionPercentage.toFixed(2)}%
                </strong>
              </span>
              <div className="align-self-end">
                <span className={classNames(css(styles.selectedText), 'text-secondary')}>
                  (Approved - {correctPredictionCount})
                </span>
                <span className={classNames(css(styles.selectedText), 'text-secondary ml-3')}>
                  (Rejected - {rejectedPredictionCount})
                </span>
              </div>
            </div>
          </div>
        </div>
        <Row className="align-items-center">
          <Col className="d-flex justify-content-end">
            <Button
              className="mr-3"
              variant="danger"
              onClick={() => {
                areYouSure(
                  () =>
                    rejectPredictions({
                      product_and_category_ids: selectedIDs,
                    }),
                  'Are you sure you want to reject the selected suggestions?',
                );
              }}
            >
              Reject the predictions
            </Button>
            <Button
              className="mr-3"
              variant="success"
              onClick={() => {
                areYouSure(
                  () =>
                    classifyProducts({
                      product_and_category_ids: selectedIDs,
                    }),
                  'Are you sure you want to classify the selected products?',
                );
              }}
            >
              Approve the predictions
            </Button>
            <ActionDropDown
              submenuItems={[
                {
                  title: 'Export product data',
                  action: () => handleExportAutoClassificationProducts(false),
                  disabled: productsExporting,
                },
                {
                  title: 'Export rejected product data',
                  action: () => handleExportAutoClassificationProducts(true),
                  disabled: productsExporting,
                },
              ]}
              label="Exports"
            />
          </Col>
        </Row>
        <Row className="py-2">
          <Col className="d-flex align-items-center">
            {productsCountLoading ? (
              <Spinner animation="border" role="status" size="sm" className="ml-4" />
            ) : (
              <span className={classNames(css(styles.selectedText), 'text-secondary')}>
                ({selectedIDs.length}/{productCount} selected)
              </span>
            )}
          </Col>
          <Col
            md={8}
            className={classNames(css(styles.pagination), 'w-100 d-flex justify-content-end py-1')}
          >
            {productsCountLoading ? (
              <Spinner animation="border" role="status" size="sm" className="ml-4" />
            ) : (
              <PaginationComponent
                totalItems={productCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={updatePageNumber}
                disabled={productsCountLoading}
              />
            )}
          </Col>
        </Row>
        <div>
          <AISuggestedClassificationProductTable
            setSelectedIDsWithShift={setSelectedIDsWithShift}
          />
        </div>
      </div>
    </AISuggestedClassifierProductContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: '1rem',
  },
  selectedText: {
    fontSize: '0.9rem',
  },
  pagination: {
    zIndex: 2,
  },
});

AISuggestedClassificationProductView.propTypes = {
  setSelectedIDsWithShift: PropTypes.func,
  clearSelectedIDs: PropTypes.func,
  forAttribution: PropTypes.bool,
};

export default AISuggestedClassificationProductView;
export { DefaultAISuggestedClassifierView, AISuggestedClassifierProductContext };
