// update along with app/lib/enums/brand_harvest_enum.py
const BrandHarvestingStatus = {
  PENDING: 'Pending',
  READY_FOR_SCRAPING: 'Ready For Scraping',
  NOT_SCRAPABLE: 'Not Scrapable',
  ACQUIRING: 'Acquiring',
  SCRAPING_COMPLETED: 'Scraping Completed',
  SCRAPPED_FILE_REJECTED: 'Scrapped File Rejected',
  DATA_MAPPING: 'Data mapping',
  UPLOADED_TO_UPB: 'Uploaded to UPB',
};

const BrandHarvestingPendingOption = 'PENDING';
const BrandHarvestingScrapingCompletedOption = 'SCRAPING_COMPLETED';
const BrandHarvestingScrapedFileRejectedOption = 'SCRAPPED_FILE_REJECTED';

const BrandHarvestingStatusOptions = Object.entries(BrandHarvestingStatus).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

const BrandHarvestingQuality = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
};

const BrandHarvestingQualityOptions = Object.entries(BrandHarvestingQuality).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

const BrandHarvestingPriority = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
};

const BrandHarvestingPriorityOptions = Object.entries(BrandHarvestingPriority).map(
  ([value, label]) => ({
    label,
    value,
  }),
);

export {
  BrandHarvestingStatus,
  BrandHarvestingPendingOption,
  BrandHarvestingScrapingCompletedOption,
  BrandHarvestingScrapedFileRejectedOption,
  BrandHarvestingStatusOptions,
  BrandHarvestingQuality,
  BrandHarvestingQualityOptions,
  BrandHarvestingPriority,
  BrandHarvestingPriorityOptions,
};
