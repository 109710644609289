import Swal from 'sweetalert2';

import { SKU_MAPPINGS_UNLINK_ALL_PRODUCTS } from 'lib/networking/endpoints';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

export const useSwalInput = () => {
  const { postData: unlinkAll } = usePost(
    SKU_MAPPINGS_UNLINK_ALL_PRODUCTS,
    () => SwalDialog('success', 'Started Unlinking.', 'Success', 'center'),
    () => SwalDialog('error', 'An error occurred during the unlinkg process.', 'Error', 'center'),
  );
  const swalInput = (title, text) => {
    Swal.fire({
      title: title,
      text: text,
      input: 'text',
      showCancelButton: true,
    }).then(result => {
      if (result.value) {
        unlinkAll({ global_product_id: result.value });
      }
    });
  };

  return swalInput;
};
