import React from 'react';
import PropTypes from 'prop-types';

import { fontStyles } from 'components/shared/nutrition_facts/sharedStyles';

function CaloriesSection({ calories }) {
  return (
    <div className="py-1">
      <div className={fontStyles('12px', true, true)}>Amount per serving</div>
      <div className="w-100 d-flex justify-content-between align-items-end">
        <div className={fontStyles('32px', true, true)}>Calories</div>
        <div className={fontStyles('44px', true, true)}>{calories || '0'}</div>
      </div>
    </div>
  );
}

CaloriesSection.propTypes = {
  calories: PropTypes.string,
};

export default CaloriesSection;
