import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { backgroundGrey2 } from 'lib/css/colors';

function AdvancedAtttributeMappingModalAttributeValueSection({
  attribute_values = [],
  subSection,
  section,
  attributeId,
}) {
  return (
    <div>
      <div className={classNames(css(styles.header), 'mt-4 py-1 my-1 font-weight-bold')}>
        <span>Values</span>
      </div>
      <div className={css(styles.scrollableDiv)}>
        {attribute_values.map(value => (
          <div key={value.id}>
            <Row>
              <Col sm={12}>
                {value.attribute_value} - {section?.section_id}.{subSection?.sub_section_id}.
                {attributeId}.{value.attribute_value_id} - {value.usda_code}
              </Col>
            </Row>
            <hr className="my-0 mr-1" />
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    backgroundColor: backgroundGrey2,
  },
  scrollableDiv: {
    overflow: 'scroll',
    height: '200px',
  },
});

AdvancedAtttributeMappingModalAttributeValueSection.propTypes = {
  attribute_values: PropTypes.array,
  subSection: PropTypes.object,
  section: PropTypes.object,
  attributeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AdvancedAtttributeMappingModalAttributeValueSection;
