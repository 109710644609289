import React, { useContext, useState } from 'react';

import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite';

import ActivityLogsView from 'components/settings/activity_logs/ActivityLogsView';
import AllProductsContainer from 'components/all_products/AllProductsContainer';
import AppNavBar, { AppNavBarContext } from 'components/layout/AppNavBar';
import AssetsView from 'components/assets/AssetsView';
import { AuthContext } from 'components/providers/AuthContextProvider';
import AISuggestedClassificationProductView from 'components/ai_suggested_classification_product/AISuggestedClassificationProductView';
import BrandDetailsView from 'components/manage_brands/brand_details/BrandDetailsView';
import BrandEditorView from 'components/manage_brands/BrandEditorView';
import BrandsHarvestView from 'components/manage_brands/harvest_brands/BrandsHarvestView';
import ConfigureSchema from 'components/manage_attribute/manage_schema/ConfigureSchema';
import CreateManufacturerView from 'components/manage_manufacturers/CreateManufacturerView';
import DashboardView from 'components/dashboard/DashboardView';
import DataSourcePendingAttributeView from 'components/pending_attribute_mapping/DataSourcePendingAttributeView';
import DataSourcesView from 'components/data_sources/DataSourcesView';
import DataSourcePendingAttributeMappingView from 'components/pending_attribute_mapping/DataSourcePendingAttributeMappingView';
import DataStreamsView from 'components/data_streams/DataStreamsView';
import FileExportsView from 'components/global_products_exports/FileExportsView';
import FullScreenMainContainer from 'components/layout/FullScreenMainContainer';
import GlobalProductsContainer from 'components/global_products/GlobalProductsContainer';
import GlobalProductsDetailContainer from 'components/global_products/detail_view/GlobalProductsDetailContainer';
import GS1LookUpView from 'components/gs1_lookup/GS1LookUpView';
import GS1XMLDecoder from 'components/tools/GS1XMLDecoder';
import ImageTaggerView from 'components/image_tagger/ImageTaggerView';
import L4DefaultImageView from 'components/l4_default_images/L4DefaultImageView';
import ManageAttributeContainer from 'components/manage_attribute/ManageAttributeContainer';
import ManageAttributeValueAlternatives from 'components/manage_attribute_value_maps/ManageAttributeValueAlternatives';
import ManageAttributeValueContainer from 'components/manage_attribute_value/ManageAttributeValueContainer';
import ManageBrandsView from 'components/manage_brands/ManageBrandsView';
import ManageDisplayValueView from 'components/manage_display_values/ManageDisplayValueView';
import BrandQAView from 'components/manage_brands/BrandQAView';
import BrandQAV2 from 'components/manage_brands/BrandQAV2';
import SimilarBrandsView from 'components/manage_brands/SimilarBrandsView';
import ManageDuplicateBrandsView from 'components/manage_brands/duplicate_brands/ManageDuplicateBrandsView';
import ManageDuplicateGlobalProductsView from 'components/duplicate_global_products/ManageDuplicateGlobalProductsView';
import ManageDuplicateManufacturersView from 'components/manage_manufacturers/duplicate_manufacturers/ManageDuplicateManufacturersView';
import ManageManufacturersView from 'components/manage_manufacturers/ManageManufacturersView';
import ManageProductOutliersView from 'components/product_anomalies/ManageProductOutliersView';
import ManageSchemaContainer from 'components/manage_attribute/manage_schema/ManageSchemaContainer';
import ManageVendorsView from 'components/manage_vendors/ManageVendorsView';
import ManageTaxonomyView from 'components/manage_taxonomy/ManageTaxonomyView';
import ManufacturerDetailsView from 'components/manage_manufacturers/manufacturer_details/ManufacturerDetailsView';
import NutritionalPanelView from 'components/nutritional_panels/NutritionalPanelView';
import ProductContentValidatorView from 'components/product_content_validator/ProductContentValidatorView';
import ProductsToValidateTable from 'components/product_content_validator/ProductsToValidateTable';
import ProductListExportContainer from 'components/product_lists/product_export/ProductListExportContainer';
import ProductListsView from 'components/product_lists/ProductListsView';
import ProductListView from 'components/product_lists/product_list/ProductListView';
import ProductOutliersQAView from 'components/product_anomalies/QA/ProductOutliersQAView';
import { ProductType } from 'lib/enums/ProductType';
import ProductUpdateDetails from 'components/product_updates/product_update_details/ProductUpdateDetails';
import ProductUpdatesView from 'components/product_updates/ProductUpdatesView';
import ProtectedRoute from 'components/layout/ProtectedRoute';
import QAProductListView from 'components/product_lists/product_list/qa_product_list/QAProductListView';
import ReviewView from 'components/l4_default_images/review_view/ReviewView';
import SingleDataSourceView from 'components/data_sources/single_data_source_view/SingleDataSourceView';
import SingleDataStreamViewContainer from 'components/data_streams/SingleDataStreamViewContainer';
import StatsView from 'components/stats/StatsView';
import SyncDataView from 'components/sync_data/SyncDataView';
import TasksView from 'components/settings/team/tasks/TasksView';
import TeamView from 'components/settings/team/TeamView';
import UPBProductDetailsPage from 'components/upb_viewer/UPBProductDetailsPage';
import UPBViewer from 'components/upb_viewer/UPBViewer';
import UploadedNutritionalPanelView from 'components/nutritional_panels/UploadedNutritionalPanelView';
import UserPermission from 'lib/enums/UserPermission';
import VendorDetailsView from 'components/manage_vendors/vendor_details/VendorDetailsView';

function AppContainer() {
  const { homeUrl } = useContext(AuthContext);
  const [appNavBarConfig, setAppNavBarConfig] = useState({});
  const [menuBarVisible, setMenuBarVisible] = useState(true);

  return (
    <AppNavBarContext.Provider value={setAppNavBarConfig}>
      <Router>
        <div className={css(styles.pageWrapper)}>
          <div className={css(styles.main)}>
            <AppNavBar
              expanded={menuBarVisible}
              setExpanded={setMenuBarVisible}
              {...appNavBarConfig}
            />
            <FullScreenMainContainer expanded={menuBarVisible}>
              <Switch>
                <ProtectedRoute
                  path="/sync-data"
                  exact={true}
                  component={<SyncDataView />}
                  permissions={[UserPermission.DATA_SYNC]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/task-view"
                  exact={true}
                  component={<TasksView />}
                  permissions={[UserPermission.TASKS_VIEW]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-updates/details"
                  component={<ProductUpdateDetails />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-updates"
                  exact={true}
                  component={<ProductUpdatesView />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/global-product-linkages/qa-product-changes"
                  exact={true}
                  component={<ProductOutliersQAView />}
                  permissions={[UserPermission.VIEW_SKU_MAPPINGS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/global-product-linkages"
                  exact={true}
                  component={<ManageProductOutliersView />}
                  permissions={[UserPermission.VIEW_SKU_MAPPINGS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/duplicate-global-products"
                  exact={true}
                  component={<ManageDuplicateGlobalProductsView />}
                  permissions={[UserPermission.VIEW_SKU_MAPPINGS]}
                  fallbackUrl={homeUrl}
                />
                <Route path="/file-exports">
                  <FileExportsView />
                </Route>
                <Route path="/global-products*">
                  <GlobalProductsContainer />
                </Route>
                <Route path="/data-sources" exact={true}>
                  <DataSourcesView />
                </Route>
                <Route path="/data-sources/:record_id">
                  <SingleDataSourceView />
                </Route>
                <Route path="/data-streams" exact={true}>
                  <DataStreamsView />
                </Route>
                <Route path="/data-streams/:record_id">
                  <SingleDataStreamViewContainer />
                </Route>
                <Route path="/all-products">
                  <AllProductsContainer />
                </Route>
                <ProtectedRoute
                  path="/assign-attributes"
                  exact={true}
                  component={<ManageAttributeContainer />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/manage-attributes"
                  exact={true}
                  component={<ManageAttributeValueContainer />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/manage-attribute-value-alternatives"
                  exact={true}
                  component={<ManageAttributeValueAlternatives />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/manage-schema/:schemaId"
                  component={<ConfigureSchema />}
                  permissions={[UserPermission.MANAGE_PRODUCT_LISTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/manage-schema"
                  exact={true}
                  component={<ManageSchemaContainer />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/manage-attributes/:attribute_name/:attribute_type/:attribute_id"
                  exact={true}
                  component={<ManageAttributeValueContainer />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/manage-taxonomy"
                  component={<ManageTaxonomyView manageTaxonomy={true} />}
                  permissions={[UserPermission.MANAGE_TAXONOMY]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/pending-attribute-mappings"
                  exact={true}
                  component={<DataSourcePendingAttributeView />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/pending-attribute-mappings/data-source/:data_source_id"
                  component={<DataSourcePendingAttributeMappingView />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-content-validator"
                  exact={true}
                  component={<ProductContentValidatorView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-content-validator/data-source/:data_source_id"
                  exact={true}
                  component={<ProductsToValidateTable />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/ai-suggested-product-classifier"
                  component={<AISuggestedClassificationProductView />}
                  permissions={[UserPermission.CLASSIFY]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manage-brands/manage-duplicate-brands"
                  component={<ManageDuplicateBrandsView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manage-brands/brand-QA"
                  component={<BrandQAView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/brand-qa"
                  component={<BrandQAV2 />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manage-brands/similar-brand/:similarBrandName?"
                  component={<SimilarBrandsView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/brand-harvest"
                  component={<BrandsHarvestView />}
                  permissions={[UserPermission.BRAND_HARVESTING]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manage-brands"
                  component={<ManageBrandsView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/brand-details/:brandID?"
                  component={<BrandDetailsView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/brand-editor/:brandID?"
                  component={<BrandEditorView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manage-manufacturers/manage-duplicate-manufacturers"
                  component={<ManageDuplicateManufacturersView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manage-manufacturers"
                  component={<ManageManufacturersView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/create-manufacturer"
                  component={<CreateManufacturerView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manufacturer-details/:manufacturerID?"
                  component={<ManufacturerDetailsView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/manage-vendors"
                  component={<ManageVendorsView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/vendor-details/:vendorID?"
                  component={<VendorDetailsView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/l4-default-images/review"
                  component={<ReviewView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/l4-default-images/"
                  component={<L4DefaultImageView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/enrich-products/gs1-lookup/"
                  component={<GS1LookUpView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/settings/team"
                  component={<TeamView />}
                  permissions={[UserPermission.VIEW_USER_MANAGEMENT]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/settings/activity-logs"
                  component={<ActivityLogsView />}
                  permissions={[UserPermission.MANAGE_ACTIVITY_LOGS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-lists/qa-product-list/:productListId"
                  component={<QAProductListView />}
                  permissions={[UserPermission.MANAGE_PRODUCT_LISTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-lists/:productListId/product-list-export"
                  exact={true}
                  component={<ProductListExportContainer />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-lists/:productListId"
                  component={<ProductListView />}
                  permissions={[UserPermission.MANAGE_PRODUCT_LISTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/product-lists"
                  component={<ProductListsView />}
                  permissions={[UserPermission.MANAGE_PRODUCT_LISTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/upb-viewer/:l1_category?/:l2_category?/:l3_category?/:l4_category?"
                  component={<UPBViewer />}
                  permissions={[UserPermission.UPB_VIEW]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/image-tagger/:productListId?"
                  component={<ImageTaggerView />}
                  permissions={[UserPermission.MANAGE_PRODUCT_LISTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/upb-pdp/:productId/:productType"
                  component={<UPBProductDetailsPage />}
                  permissions={[UserPermission.MANAGE_PRODUCT_LISTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/global-product-detail/:globalProductId/:productID?/:productType?"
                  component={<GlobalProductsDetailContainer />}
                  permissions={[UserPermission.EDIT_PRODUCT]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/assets"
                  component={<AssetsView />}
                  permissions={[UserPermission.MANAGE_ASSETS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/manage-display-values"
                  component={<ManageDisplayValueView />}
                  permissions={[UserPermission.EDIT_ATTRIBUTES]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/stats"
                  component={<StatsView />}
                  permissions={[UserPermission.VIEW_SKU_MAPPINGS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/dashboard"
                  component={<DashboardView />}
                  permissions={[UserPermission.VIEW_DASHBOARD]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/nutritional-panel-uploads/:nutritionalPanelUploadID?"
                  component={<UploadedNutritionalPanelView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/nutritional-panel-upload"
                  component={<NutritionalPanelView />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <ProtectedRoute
                  path="/tools/gs1-decoder"
                  exact={true}
                  component={<GS1XMLDecoder />}
                  permissions={[UserPermission.ENRICH_PRODUCTS]}
                  fallbackUrl={homeUrl}
                />
                <Route>
                  <Redirect to={homeUrl} />
                </Route>
              </Switch>
            </FullScreenMainContainer>
          </div>
        </div>
      </Router>
    </AppNavBarContext.Provider>
  );
}

const styles = StyleSheet.create({
  main: {
    transition: 'margin .5s',
    width: '100%',
  },
  pageWrapper: {
    height: '100%',
    overflowX: 'none',
    width: '100%',
    minHeight: '90vh',
  },
});

export default AppContainer;
