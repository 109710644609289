import { useContext } from 'react';

import { AuthContext } from 'components/providers/AuthContextProvider';

function useUserPermissions() {
  const {
    authUser: { role },
  } = useContext(AuthContext);
  return (permissions, hasAll = true) => {
    if (!role?.role_permissions) {
      return false;
    }
    if (hasAll) {
      return permissions.every(val => role.role_permissions.includes(val));
    }
    return permissions.some(val => role.role_permissions.includes(val));
  };
}

export default useUserPermissions;
