import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { GLOBAL_MANUFACTURERS_FOR_DROPDOWN } from 'lib/networking/endpoints';
import { BrandVerificationStatusOptions } from 'lib/enums/BrandVerificationStatus';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function BrandFilters({
  status,
  setStatus,
  loading,
  productList,
  setProductList,
  setShowFilters,
  manufacturer,
  setManufacturer,
  product_lists,
  productListOptions,
}) {
  const [selectedStatus, setSelectedStatus] = useState(status || '');
  const [selectedManufacturer, setSelectedManufacturer] = useState(manufacturer);
  const [selectedProductList, setSelectedProductList] = useState(
    productListOptions.find(option => option.label === productList?.name),
  );

  return (
    <div>
      <h4 className="font-weight-bold">Filters</h4>
      <hr className="my-0" />
      <div className="my-3">
        <Form.Group>
          <Form.Label>Product List</Form.Label>
          <Select
            isClearable
            options={productListOptions}
            value={selectedProductList}
            placeholder="Select a Product List"
            onChange={setSelectedProductList}
          />
          <Form.Label className="mt-3">Status</Form.Label>
          <Select
            isClearable
            options={BrandVerificationStatusOptions}
            value={BrandVerificationStatusOptions.find(statu => statu?.value === selectedStatus)}
            placeholder="Select Brand Verification Status"
            onChange={option => setSelectedStatus(option?.value)}
          />
          <Form.Label className="mt-3">Manufacturer</Form.Label>
          <SearchableScrollableSelect
            initOption={manufacturer}
            onChange={option => setSelectedManufacturer(option)}
            fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
            optionMapper={manufacturer => ({ label: manufacturer.name, value: manufacturer.id })}
            placeholder="Select Manufacturer"
            autoFocus={false}
          />
        </Form.Group>
      </div>
      <div className="my-4">
        <Button
          disabled={loading}
          onClick={() => {
            setProductList(product_lists[selectedProductList?.value]);
            setStatus(selectedStatus);
            setManufacturer(selectedManufacturer);
            setShowFilters(false);
          }}
        >
          Apply Filters
        </Button>
      </div>
    </div>
  );
}

BrandFilters.propTypes = {
  productList: PropTypes.object,
  setProductList: PropTypes.func,
  loading: PropTypes.bool,
  status: PropTypes.string,
  setStatus: PropTypes.func,
  setShowFilters: PropTypes.func,
  manufacturer: PropTypes.object,
  setManufacturer: PropTypes.func,
  product_lists: PropTypes.array,
  productListOptions: PropTypes.array,
};

export default BrandFilters;
