import React, { useContext, useEffect } from 'react';

import { Card, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { GoogleLogin } from '@react-oauth/google';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'components/providers/AuthContextProvider';
import { backgroundColorStyles, colorStyles } from 'lib/css/colors';
import useQueryString from 'lib/hooks/useQueryString';

function Login() {
  const { authUser, login } = useContext(AuthContext);
  const history = useHistory();
  const authCode = useQueryString().get('code');
  const redirectLink = useQueryString().get('redirect_on_login');

  useEffect(() => {
    if (authUser) {
      history.push(redirectLink || '/');
    } else if (authCode) {
      login(authCode);
    }
  }, [authCode, authUser, history, login, redirectLink]);

  return (
    <div
      className={classNames(
        css(styles.login, backgroundColorStyles.lighterGrey),
        'd-flex justify-content-center',
      )}
    >
      <div className="my-auto mx-auto">
        <Card className={classNames(css(styles.card), 'shadow')}>
          <Card.Header className={css(backgroundColorStyles.salmon)} />
          <Card.Body className="mx-auto">
            <Row>
              <h2 className="font-weight-bolder">FSA+</h2>
            </Row>
            <Row className="justify-content-center my-4">
              <GoogleLogin
                onSuccess={credentialResponse => login(credentialResponse)}
                onError={() =>
                  Swal.fire({
                    icon: 'error',
                    title: 'Login failed',
                    text: 'Something went wrong, please try again.',
                  })
                }
                text="continue_with"
              />
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  card: {
    width: 350,
  },
  googleButton: {
    width: '70%',
  },
  login: {
    minHeight: '100vh',
    fontFamily: 'Chivo',
  },
  signInBtn: {
    fontSize: 18,
  },
  underLine: {
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
      textShadow: colorStyles.blue,
    },
  },
});

export default Login;
