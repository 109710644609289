import React, { createContext, useState } from 'react';

import { Route } from 'react-router-dom';

import { CATEGORY_OPTIONS } from 'lib/enums/TaxonomyLevel';
import ManageAttributeView from 'components/manage_attribute/ManageAttributeView';

const ManageAttributeContext = createContext({});

function ManageAttributeContainer() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryLevel, setCategoryLevel] = useState(CATEGORY_OPTIONS[0]);
  const [searchText, setSearchText] = useState('');

  return (
    <ManageAttributeContext.Provider
      value={{
        categoryLevel,
        setCategoryLevel,
        searchText,
        setSearchText,
        selectedCategories,
        setSelectedCategories,
      }}
    >
      <Route path="/assign-attributes" exact>
        <ManageAttributeView />
      </Route>
    </ManageAttributeContext.Provider>
  );
}

export default ManageAttributeContainer;
export { ManageAttributeContext };
