import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import InfiniteScroll from 'react-infinite-scroll-component';

import CascadeCategoryDropdown from 'components/shared/CascadeCategoryDropdown';
import ResizableTable from 'components/shared/ResizableTable';
import TaxonomyTableRow from 'components/manage_taxonomy/TaxonomyTableRow';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';
import useTaxonomyOptions from 'lib/hooks/useTaxonomyOptions';
import { GLOBAL_PRODUCTS_TAXONOMY_TABLE_ID, TAXONOMY_TABLE_ID } from 'lib/constants';

function getCategoryKey(l0Category) {
  let key = l0Category?.id;
  if (l0Category?.l1_categories && Object.keys(l0Category.l1_categories)?.length > 0) {
    const l1Category = Object.values(l0Category?.l1_categories)[0];
    key += l1Category?.id;
    if (l1Category?.l2_categories && Object.keys(l1Category.l2_categories)?.length > 0) {
      const l2Category = Object.values(l1Category?.l2_categories)[0];
      key += l2Category?.id;
      if (l2Category?.l3_categories && Object.keys(l2Category.l3_categories)?.length > 0) {
        const l3Category = Object.values(l2Category?.l3_categories)[0];
        key += l3Category?.id;
        if (l3Category?.l4_categories && Object.keys(l3Category.l4_categories)?.length > 0) {
          const l4Category = Object.values(l3Category?.l4_categories)[0];
          key += l4Category?.id;
        }
      }
    }
  }
  return key;
}

function TaxonomyTable({
  l0Categories,
  loading,
  l0Search,
  l1Search,
  l2Search,
  l3Search,
  l4Search,
  setL0Search,
  setL1Search,
  setL2Search,
  setL3Search,
  setL4Search,
  onClassify,
  manageTaxonomy = false,
  setCurrentPage,
  currentPage,
  setCreateModalOptions,
  rowsToRender,
  setRowsToRender,
  sortConfig,
  setSortConfig,
  count,
  setCategoryModified,
}) {
  // to hold the previous response and to compare with the new, needed since the page renders
  // before api response and contains previous categories, therefore causes duplications
  const [previousResults, setPreviousResults] = useState(
    Object.values(l0Categories).length ? getCategoryKey(Object.values(l0Categories)[0]) : null,
  );
  const { options: { l0Options, l1Options, l2Options, l3Options, l4Options } = {} } =
    useTaxonomyOptions(l0Search, l1Search, l2Search, l3Search, l4Search, true);
  const hasUserPermissions = useUserPermissions();

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
  };

  const tableRows = useMemo(() => {
    if (!Object.values(l0Categories)?.length) {
      return [];
    }
    let rows = [];
    Object.values(l0Categories).forEach(l0Category => {
      if (manageTaxonomy) {
        rows.push(
          <TaxonomyTableRow
            key={`${currentPage}-l0-${l0Category.id}`}
            l0Category={l0Category}
            onClassify={onClassify}
            hasTaxonomyPermission={hasUserPermissions([UserPermission.MANAGE_TAXONOMY])}
            setCreateModalOptions={setCreateModalOptions}
            manageTaxonomy={manageTaxonomy}
            setCurrentPage={setCurrentPage}
            setCategoryModified={setCategoryModified}
          />,
        );
      }
      const l1Categories = l0Category.l1_categories || {};
      Object.values(l1Categories).forEach(l1Category => {
        if (manageTaxonomy) {
          rows.push(
            <TaxonomyTableRow
              key={`${currentPage}-l1-${l1Category.id}`}
              l0Category={l0Category}
              l1Category={l1Category}
              onClassify={onClassify}
              hasTaxonomyPermission={hasUserPermissions([UserPermission.MANAGE_TAXONOMY])}
              setCreateModalOptions={setCreateModalOptions}
              manageTaxonomy={manageTaxonomy}
              setCurrentPage={setCurrentPage}
              setCategoryModified={setCategoryModified}
            />,
          );
        }
        const l2Categories = l1Category.l2_categories || {};
        Object.values(l2Categories).forEach(l2Category => {
          if (manageTaxonomy) {
            rows.push(
              <TaxonomyTableRow
                key={`${currentPage}-l2-${l2Category.id}`}
                l0Category={l0Category}
                l1Category={l1Category}
                l2Category={l2Category}
                onClassify={onClassify}
                hasTaxonomyPermission={hasUserPermissions([UserPermission.MANAGE_TAXONOMY])}
                setCreateModalOptions={setCreateModalOptions}
                manageTaxonomy={manageTaxonomy}
                setCurrentPage={setCurrentPage}
                setCategoryModified={setCategoryModified}
              />,
            );
          }
          const l3Categories = l2Category.l3_categories || {};
          Object.values(l3Categories).forEach(l3Category => {
            if (manageTaxonomy) {
              rows.push(
                <TaxonomyTableRow
                  key={`${currentPage}-l3-${l3Category.id}`}
                  l0Category={l0Category}
                  l1Category={l1Category}
                  l2Category={l2Category}
                  l3Category={l3Category}
                  onClassify={onClassify}
                  hasTaxonomyPermission={hasUserPermissions([UserPermission.MANAGE_TAXONOMY])}
                  setCreateModalOptions={setCreateModalOptions}
                  manageTaxonomy={manageTaxonomy}
                  setCurrentPage={setCurrentPage}
                  setCategoryModified={setCategoryModified}
                />,
              );
            }
            const l4Categories = l3Category.l4_categories || {};
            if (Object.keys(l4Categories).length) {
              Object.values(l4Categories).forEach(l4Category => {
                rows.push(
                  <TaxonomyTableRow
                    key={`${currentPage}-l4-${l4Category.id}`}
                    l0Category={l0Category}
                    l1Category={l1Category}
                    l2Category={l2Category}
                    l3Category={l3Category}
                    l4Category={l4Category}
                    onClassify={onClassify}
                    hasTaxonomyPermission={hasUserPermissions([UserPermission.MANAGE_TAXONOMY])}
                    setCreateModalOptions={setCreateModalOptions}
                    manageTaxonomy={manageTaxonomy}
                    setCurrentPage={setCurrentPage}
                    setCategoryModified={setCategoryModified}
                  />,
                );
              });
            } else {
              if (manageTaxonomy) {
                rows.push(
                  <TaxonomyTableRow
                    key={`l4-${l0Category.id}-${l1Category.id}-${l2Category.id}-${l3Category.id}`}
                    l0Category={l0Category}
                    l1Category={l1Category}
                    l2Category={l2Category}
                    l3Category={l3Category}
                    l4Category={{}}
                    onClassify={onClassify}
                    hasTaxonomyPermission={hasUserPermissions([UserPermission.MANAGE_TAXONOMY])}
                    setCreateModalOptions={setCreateModalOptions}
                    manageTaxonomy={manageTaxonomy}
                    setCurrentPage={setCurrentPage}
                    setCategoryModified={setCategoryModified}
                  />,
                );
              }
            }
          });
        });
      });
    });
    return rows;
  }, [
    hasUserPermissions,
    l0Categories,
    manageTaxonomy,
    onClassify,
    setCreateModalOptions,
    setCurrentPage,
    currentPage,
    setCategoryModified,
  ]);

  useEffect(() => {
    // when a new search is done we need to reset the data
    if (currentPage === 1) {
      setRowsToRender(tableRows);
    } else if (previousResults !== getCategoryKey(Object.values(l0Categories)[0])) {
      setRowsToRender(rowsToRender.concat(tableRows));
    }
    setPreviousResults(
      Object.values(l0Categories).length ? getCategoryKey(Object.values(l0Categories)[0]) : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [l0Categories, currentPage]);

  return (
    <InfiniteScroll
      id="scrollableDiv"
      dataLength={rowsToRender.length}
      next={fetchMoreData}
      hasMore={rowsToRender.length < count}
      loader={<h5 style={{ textAlign: 'center' }}>Loading...</h5>}
      scrollableTarget="scrollableDiv"
      height={window.innerHeight - 100}
      scrollThreshold="70%"
    >
      <ResizableTable
        resizable={true}
        resizeOptions={{
          resizeMode: 'overflow',
          liveDrag: true,
          draggingClass: 'drag',
          minWidth: 20,
          maxWidth: 100,
        }}
        tableID={manageTaxonomy ? TAXONOMY_TABLE_ID : GLOBAL_PRODUCTS_TAXONOMY_TABLE_ID}
        className={css(styles.tableText)}
      >
        <thead className={css(styles.thead)}>
          <tr>
            {manageTaxonomy ? (
              <CascadeCategoryDropdown
                title="L0 Category"
                options={l0Options || []}
                searchTerm={l0Search}
                setSearchTerm={setL0Search}
                setCurrentPage={setCurrentPage}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            ) : null}
            <CascadeCategoryDropdown
              title="L1 Category"
              options={l1Options || []}
              searchTerm={l1Search}
              setSearchTerm={setL1Search}
              setCurrentPage={setCurrentPage}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
            <CascadeCategoryDropdown
              title="L2 Category"
              options={l2Options || []}
              searchTerm={l2Search}
              setSearchTerm={setL2Search}
              setCurrentPage={setCurrentPage}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
            <CascadeCategoryDropdown
              title="L3 Category"
              options={l3Options || []}
              searchTerm={l3Search}
              setSearchTerm={setL3Search}
              setCurrentPage={setCurrentPage}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
            <CascadeCategoryDropdown
              title="L4 Category"
              options={l4Options || []}
              searchTerm={l4Search}
              setSearchTerm={setL4Search}
              setCurrentPage={setCurrentPage}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {rowsToRender.length ? (
            rowsToRender
          ) : (
            <tr>
              <td />
              <td />
              <td />
              <td />
            </tr>
          )}
        </tbody>
      </ResizableTable>
    </InfiniteScroll>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.65rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: '#fff',
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
    height: 500,
  },
});

TaxonomyTable.propTypes = {
  l0Categories: PropTypes.array,
  loading: PropTypes.bool,
  l0Search: PropTypes.string,
  l1Search: PropTypes.string,
  l2Search: PropTypes.string,
  l3Search: PropTypes.string,
  l4Search: PropTypes.string,
  setL0Search: PropTypes.func,
  setL1Search: PropTypes.func,
  setL2Search: PropTypes.func,
  setL3Search: PropTypes.func,
  setL4Search: PropTypes.func,
  onClassify: PropTypes.func,
  setCurrentPage: PropTypes.func,
  currentPage: PropTypes.number,
  setCreateModalOptions: PropTypes.func,
  manageTaxonomy: PropTypes.bool,
  rowsToRender: PropTypes.array,
  setRowsToRender: PropTypes.func,
  sortConfig: PropTypes.object,
  setSortConfig: PropTypes.func,
  count: PropTypes.number,
  setCategoryModified: PropTypes.func,
};

export default TaxonomyTable;
