import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import Select from 'react-select';

import DATA_SOURCE_ENTITY, { DATA_SOURCE_ENTITY_OPTIONS } from 'lib/enums/DataSourceEntity';

function DataSourceSkuMappingsTabRow({
  column,
  getManufacturerOptions,
  getVendorOptions,
  unsavedColumnMap,
  setUnsavedColumnMap,
}) {
  // returns the Entitytype of the row (ex: `{value: 'MANUFACTURER', label: 'Manufacturer'}`)
  const memoizedRowEntityType = useCallback(
    columnName => {
      const match = Object.entries(unsavedColumnMap).find(([key, value]) => key === columnName);
      // match => ['SWT', {VENDOR: 38}]
      if (match) {
        const [, value] = match;
        if (value) {
          return DATA_SOURCE_ENTITY_OPTIONS.find(op => op.value === Object.keys(value)[0]);
        }
      }
      return '';
    },
    [unsavedColumnMap],
  );
  const entityType = memoizedRowEntityType(column);
  const dropDownOptions =
    entityType?.value === DATA_SOURCE_ENTITY.VENDOR ? getVendorOptions : getManufacturerOptions;

  // returns the global mfr/vendor along with its id, ex: `{value: 38, label: 'Southwest Traders'}}`
  const memoizedGetRowEntity = useCallback(
    columnName => {
      const match = Object.entries(unsavedColumnMap).find(([key, value]) => key === columnName);
      // match => ['SWT', {VENDOR: 38}]
      if (match) {
        const [, value] = match;
        if (value) {
          const id = Object.values(value)[0];
          return dropDownOptions.find(op => op.value === id);
        }
      }
      return '';
    },
    [dropDownOptions, unsavedColumnMap], // Rebuild only if these change
  );

  return (
    <tr>
      <th>{column}</th>
      <td>
        <FontAwesomeIcon icon={faArrowRight} />
      </td>
      <td>
        <Select
          value={entityType}
          options={DATA_SOURCE_ENTITY_OPTIONS}
          onChange={option => {
            setUnsavedColumnMap({
              ...unsavedColumnMap,
              [column]: { [option?.value]: null },
            });
          }}
          isSearchable
          isClearable
        />
      </td>
      <td>
        <Select
          disabled={isEmpty(entityType)}
          value={memoizedGetRowEntity(column)}
          options={dropDownOptions}
          onChange={option => {
            setUnsavedColumnMap({
              ...unsavedColumnMap,
              [column]: { [entityType?.value]: option?.value },
            });
          }}
          isSearchable
          isClearable
        />
      </td>
    </tr>
  );
}

DataSourceSkuMappingsTabRow.propTypes = {
  column: PropTypes.string,
  getManufacturerOptions: PropTypes.array,
  getVendorOptions: PropTypes.array,
  unsavedColumnMap: PropTypes.object,
  setUnsavedColumnMap: PropTypes.func,
};

export default DataSourceSkuMappingsTabRow;
