import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { uniq, without } from 'lodash';

import { BooleanValueOptions } from 'lib/enums/BooleanOption';
import EditableInputField from 'components/shared/EditableInputField';
import { EDITABLE_FIELD_INPUT_TYPES } from 'components/shared/EditableInputField';
import { purple, salmon } from 'lib/css/colors';
import RadioButton from 'components/shared/RadioButton';
import { SCHEMA_ATTRIBUTE } from 'lib/networking/endpoints';
import usePut from 'lib/hooks/usePut';
function ConfigureSchemaRow({
  schemaAttribute,
  selectedSchemaAttributeIds,
  setSelectedSchemaAttributeIds,
  refetch,
  newRecord = false,
}) {
  const [schemaDetails, setSchemaDetails] = useState({
    id: schemaAttribute.id,
    attribute_name:
      schemaAttribute.attributes?.attribute_name ||
      schemaAttribute.global_attribute?.global_attribute_name,
    display_name: schemaAttribute.display_name || '',
    attribute_help_text: schemaAttribute.attribute_help_text || '',
    category: schemaAttribute.category || '',
    sub_category: schemaAttribute.sub_category || '',
    mandatory: schemaAttribute.mandatory || false,
    category_index: schemaAttribute.category_index || null,
    sub_category_index: schemaAttribute.sub_category_index || null,
    sort_index: schemaAttribute.sort_index || null,
    group_index: schemaAttribute.group_index || null,
  });
  const [canEdit, setCanEdit] = useState(false);

  const isChecked = selectedSchemaAttributeIds.includes(schemaDetails.id);
  const toggleCheck = () => {
    if (isChecked) {
      setSelectedSchemaAttributeIds([...without(selectedSchemaAttributeIds, schemaDetails.id)]);
    } else {
      setSelectedSchemaAttributeIds([...uniq([...selectedSchemaAttributeIds, schemaDetails.id])]);
    }
  };

  const { putData: updateRecord } = usePut(SCHEMA_ATTRIBUTE, () => {
    setCanEdit(false);
    refetch();
  });

  const updateSchemaAttribute = () => {
    updateRecord({
      schema_attribute_id: schemaAttribute.id,
      display_name: schemaDetails.display_name || '',
      attribute_help_text: schemaDetails.attribute_help_text || '',
      category: schemaDetails.category || '',
      sub_category: schemaDetails.sub_category || '',
      mandatory: schemaDetails.mandatory || false,
      category_index: schemaDetails.category_index || '',
      sub_category_index: schemaDetails.sub_category_index || '',
      sort_index: schemaDetails.sort_index || '',
      group_index: schemaDetails.group_index || '',
    });
  };

  return (
    <tr key={schemaDetails.id} className="d-flex">
      <td className="col-2 text-break">
        <RadioButton
          checked={isChecked}
          className="float-left pr-2"
          box
          small={true}
          onClick={toggleCheck}
        />
        {schemaDetails.attribute_name}
      </td>
      <td className="col-1 text-break">
        <EditableInputField
          value={schemaDetails.display_name}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, display_name: value })}
        />
      </td>
      <td className="col-1 text-break">
        <EditableInputField
          value={schemaDetails.attribute_help_text}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, attribute_help_text: value })}
        />
      </td>
      <td className="col-1 text-break">
        <EditableInputField
          value={schemaDetails.category}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, category: value })}
        />
      </td>
      <td className="col-1 text-break">
        <EditableInputField
          value={schemaDetails.sub_category}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, sub_category: value })}
        />
      </td>
      <td className="col-1">
        {schemaDetails.mandatory}
        <EditableInputField
          value={schemaDetails.mandatory}
          edit={canEdit}
          dropdownOptions={BooleanValueOptions}
          type={EDITABLE_FIELD_INPUT_TYPES.LIST}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, mandatory: value })}
        />
      </td>
      <td className="col-1">
        <EditableInputField
          value={schemaDetails.category_index}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, category_index: value })}
        />
      </td>
      <td className="col-1">
        <EditableInputField
          value={schemaDetails.sub_category_index}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, sub_category_index: value })}
        />
      </td>
      <td className="col-1">
        <EditableInputField
          value={schemaDetails.sort_index}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, sort_index: value })}
        />
      </td>
      <td className="col-1">
        <EditableInputField
          value={schemaDetails.group_index}
          edit={canEdit}
          setCanEdit={setCanEdit}
          setValue={value => setSchemaDetails({ ...schemaDetails, group_index: value })}
        />
      </td>
      <td className="col-1">
        <Button
          variant="link"
          className={classNames(css(styles.editButton), 'mr-1 float-right')}
          onClick={() => {
            if (canEdit && !newRecord) {
              updateSchemaAttribute();
            }
            setCanEdit(!canEdit);
          }}
        >
          {canEdit ? 'Done' : 'Edit'}
        </Button>
      </td>
    </tr>
  );
}

const styles = StyleSheet.create({
  deleteButton: {
    color: salmon,
  },
  editButton: {
    color: purple,
  },
});

ConfigureSchemaRow.propTypes = {
  schemaAttribute: PropTypes.object,
  setSelectedSchemaAttributeIds: PropTypes.func,
  selectedSchemaAttributeIds: PropTypes.array,
  refetch: PropTypes.func,
  newRecord: PropTypes.bool,
};

export default ConfigureSchemaRow;
