import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Modal } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { API_INTEGRATION } from 'lib/networking/endpoints';
import { API_INTEGRATION_TYPES } from 'lib/enums/APIIntegrationEnum';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function SyndigoAPIIntegrationModal({ onHide, globalVendorId, apiIntegrationData }) {
  const [syndioAPIIntegrationData, setSyndioAPIIntegrationData] = useState({
    userName: apiIntegrationData.user_name,
    secret: apiIntegrationData.password,
    dataOwnerId: apiIntegrationData.meta_data?.data_owner_id,
    vocabularyId: apiIntegrationData.meta_data?.vocabulary_id,
  });
  const { postData: createOrUpdate } = usePost(
    API_INTEGRATION,
    () => {
      SwalDialog('success', 'Created/Updated successfully', 'Success', 'center');
      onHide();
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Syndigo Integration</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <Form.Control
            value={syndioAPIIntegrationData.userName}
            type="text"
            className={css(styles.formControl)}
            onChange={e =>
              setSyndioAPIIntegrationData({ ...syndioAPIIntegrationData, userName: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Data Owner ID</Form.Label>
          <Form.Control
            value={syndioAPIIntegrationData.dataOwnerId}
            type="text"
            className={css(styles.formControl)}
            onChange={e =>
              setSyndioAPIIntegrationData({
                ...syndioAPIIntegrationData,
                dataOwnerId: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Vocabulary ID</Form.Label>
          <Form.Control
            value={syndioAPIIntegrationData.vocabularyId}
            type="text"
            className={css(styles.formControl)}
            onChange={e =>
              setSyndioAPIIntegrationData({
                ...syndioAPIIntegrationData,
                vocabularyId: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Secret</Form.Label>
          <Form.Control
            value={syndioAPIIntegrationData.secret}
            type="text"
            className={css(styles.formControl)}
            onChange={e =>
              setSyndioAPIIntegrationData({ ...syndioAPIIntegrationData, secret: e.target.value })
            }
          />
        </Form.Group>
        <Button
          disabled={
            !syndioAPIIntegrationData.userName ||
            !syndioAPIIntegrationData.secret ||
            !syndioAPIIntegrationData.dataOwnerId
          }
          variant="outline-primary"
          className="mr-2"
          onClick={() => {
            createOrUpdate({
              global_vendor_id: globalVendorId,
              user_name: syndioAPIIntegrationData.userName,
              password: syndioAPIIntegrationData.secret,
              meta_data: {
                data_owner_id: syndioAPIIntegrationData.dataOwnerId,
                vocabulary_id: syndioAPIIntegrationData.vocabularyId,
              },
              integration_type: API_INTEGRATION_TYPES.SYNDIGO,
            });
          }}
        >
          Save
        </Button>
        <Button variant="outline-primary" onClick={() => onHide()}>
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  formControl: {
    '::placeholder': {
      fontStyle: 'italic',
    },
  },
});

SyndigoAPIIntegrationModal.propTypes = {
  onHide: PropTypes.func,
  globalVendorId: PropTypes.number,
  apiIntegrationData: PropTypes.object,
};

export default SyndigoAPIIntegrationModal;
