import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Spinner } from 'react-bootstrap';

import DropZone from 'components/shared/Dropzone';

const BRAND_LOGO_FOLDER = 'brand_logo';

function BrandLogoUploadModal({ brand, onHide, updateBrand, loading }) {
  const [uploadedBrandLogoUrl, setUploadedBrandLogoUrl] = useState([]);
  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Set Brand Logo</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <DropZone
            text="Drag and drop the logo image here, or click to select the image"
            onUploadSuccess={newUrls => setUploadedBrandLogoUrl(newUrls)}
            fileUrls={uploadedBrandLogoUrl}
            isImage={true}
            isPublic={true}
            filePath={BRAND_LOGO_FOLDER}
          />
          <div className="mt-3 d-flex justify-content-center align-items-center">
            <Button
              className="mr-2"
              disabled={uploadedBrandLogoUrl?.length !== 1 || loading}
              variant="primary"
              onClick={() =>
                updateBrand({
                  id: brand.id,
                  logo_url: uploadedBrandLogoUrl[0],
                }).then(() => {
                  setUploadedBrandLogoUrl([]);
                  onHide();
                })
              }
            >
              {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Link Logo'}
            </Button>
            <Button variant="outline-primary" onClick={() => setUploadedBrandLogoUrl([])}>
              Clear Images
            </Button>
          </div>
          <p className="small text-muted">* Upload just one image to make it the brand Logo</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

BrandLogoUploadModal.propTypes = {
  brand: PropTypes.object,
  onHide: PropTypes.func,
  updateBrand: PropTypes.func,
  loading: PropTypes.bool,
};

export default BrandLogoUploadModal;
