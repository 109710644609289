import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { some } from 'lodash';

import L4ImageStatus from 'lib/enums/L4ImageStatus';
import RadioButton from 'components/shared/RadioButton';

function ReviewCard({
  l4ImageStatus,
  categoryName,
  categoryId,
  imageUrl,
  checkedL4CategoryIds,
  handleToggleCheck,
  isNotApplicable,
  isRejected,
  isReviewed,
}) {
  return (
    <Card className="border-0 m-0 p-0">
      <div className={classNames(css(styles.card), 'd-flex flex-column')}>
        <div
          className={classNames(
            css(styles.productImageContainer),
            'align-items-center justify-content-center d-flex',
          )}
        >
          <Card.Img className={css(styles.productImage)} variant="top" src={imageUrl} />
          {isNotApplicable && (
            <div className={classNames(css(styles.alertBadge), 'position-absolute')}>
              <span className="badge badge-pill badge-danger">Too Broad</span>
            </div>
          )}
          {isRejected && (
            <div className={classNames(css(styles.alertBadge), 'position-absolute')}>
              <span className="badge badge-pill badge-danger">Rejected</span>
            </div>
          )}
          {isReviewed && (
            <div className={classNames(css(styles.alertBadge), 'position-absolute')}>
              <span className="badge badge-pill badge-success">Reviewed</span>
            </div>
          )}
        </div>
        <Card.Body className={classNames(css(styles.categoryName), 'py-0 px-0')}>
          <RadioButton
            checked={
              l4ImageStatus === L4ImageStatus.REVIEWED
                ? some(checkedL4CategoryIds, op => op.categoryId === categoryId)
                : some(checkedL4CategoryIds, op => op === categoryId)
            }
            className="float-left"
            box
            small={true}
            onClick={() => handleToggleCheck(categoryId)}
          />
          {categoryName}
        </Card.Body>
      </div>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    minWidth: 200,
    maxWidth: 200,
    maxHeight: 250,
    minHeight: 250,
    border: '1px solid rgb(208, 213, 221)',
    borderRadius: 5,
    padding: 5,
    margin: '0px 10px 20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    ':hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
      border: '1px solid rgb(153, 155, 167)',
    },
  },
  productImageContainer: {
    position: 'relative',
    width: '100%',
    height: 210,
    overflow: 'hidden',
  },
  productImage: {
    ':hover': {
      opacity: 0.6,
    },
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  categoryName: {
    fontSize: '0.8rem',
  },
  alertBadge: {
    top: 5,
  },
});

ReviewCard.propTypes = {
  l4ImageStatus: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  checkedL4CategoryIds: PropTypes.array.isRequired,
  handleToggleCheck: PropTypes.func.isRequired,
  isNotApplicable: PropTypes.bool,
  isRejected: PropTypes.bool,
  isReviewed: PropTypes.bool,
};

export default ReviewCard;
