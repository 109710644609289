import React, { useContext } from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';

import { AllProductsContext } from 'components/all_products/AllProductsContainer';
import AllProductsTableCell from 'components/all_products/AllProductsTableCell';
import CascadeCategoryDropdown from 'components/shared/CascadeCategoryDropdown';
import CategorizedDatePicker from 'components/shared/CategorizedDatePicker';
import {
  ALL_PRODUCTS_TABLE_ID,
  CATEGORY_LEVELS,
  SORTABLE_GLOBAL_PRODUCT_COLUMNS,
} from 'lib/constants';
import TableHeader from 'components/all_products/TableHeader';
import ResizableTable from 'components/shared/ResizableTable';
import useTaxonomyOptions from 'lib/hooks/useTaxonomyOptions';
import { white } from 'lib/css/colors';

function AllProductsTable() {
  const {
    view,
    products,
    onColumnSearch,
    searchFilters,
    sortConfig,
    setSortConfig,
    startDate,
    endDate,
    setDateRange,
  } = useContext(AllProductsContext);
  const columns = view?.columns?.filter(c => c.visible).map(c => c.name);

  const { options: { l0Options, l1Options, l2Options, l3Options, l4Options } = {} } =
    useTaxonomyOptions(
      searchFilters[CATEGORY_LEVELS.L0_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L1_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L2_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L3_CATEGORY],
      searchFilters[CATEGORY_LEVELS.L4_CATEGORY],
      true,
    );

  const optionMap = {
    [CATEGORY_LEVELS.L0_CATEGORY]: l0Options,
    [CATEGORY_LEVELS.L1_CATEGORY]: l1Options,
    [CATEGORY_LEVELS.L2_CATEGORY]: l2Options,
    [CATEGORY_LEVELS.L3_CATEGORY]: l3Options,
    [CATEGORY_LEVELS.L4_CATEGORY]: l4Options,
  };

  return (
    <ResizableTable
      resizable={true}
      resizeOptions={{
        resizeMode: 'overflow',
        liveDrag: true,
        draggingClass: 'drag',
        minWidth: 20,
        serialize: true,
        partialRefresh: true,
      }}
      className={css(styles.globalProductsTable)}
      tableID={ALL_PRODUCTS_TABLE_ID}
      columns={columns}
    >
      <thead className={css(styles.thead)}>
        <tr>
          {columns?.map(column =>
            column.includes('category') ? (
              <CascadeCategoryDropdown
                key={column}
                title={column}
                options={optionMap[column]}
                searchTerm={searchFilters[column]}
                setSearchTerm={(searchText, searchId) =>
                  onColumnSearch(column, searchText, searchId)
                }
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            ) : column === 'categorized_at' ? (
              <CategorizedDatePicker
                key={column}
                title={column}
                startDate={startDate}
                endDate={endDate}
                setDateRange={setDateRange}
              />
            ) : (
              <TableHeader
                title={column}
                key={column}
                onSearch={searchText => onColumnSearch(column, searchText)}
                value={searchFilters[column] || ''}
                sortable={SORTABLE_GLOBAL_PRODUCT_COLUMNS.includes(column)}
                sortConfig={sortConfig}
                setSortConfig={setSortConfig}
              />
            ),
          )}
        </tr>
      </thead>
      <tbody className={css(styles.tbody)}>
        {products.length ? (
          products.map(product => (
            <tr key={`${product.product_type}-${product.id}`}>
              {columns?.map(col => {
                if (col === 'name') {
                  return (
                    <td key={col} className="p-1">
                      <Link
                        to={
                          `/global-product-detail/${product.global_product_id}/` +
                          `${product.id}/${product.product_type}`
                        }
                        className={css(styles.productName)}
                      >
                        {product.name}
                      </Link>
                    </td>
                  );
                } else {
                  return (
                    <td className="p-1" key={col}>
                      <AllProductsTableCell product={product} column={col} />
                    </td>
                  );
                }
              })}
            </tr>
          ))
        ) : (
          <>
            <tr>
              {columns?.map(col => (
                <td key={col} />
              ))}
              <td />
            </tr>
          </>
        )}
      </tbody>
    </ResizableTable>
  );
}

const styles = StyleSheet.create({
  globalProductsTable: {
    fontSize: '0.65rem',
  },
  productName: {
    textDecoration: 'none',
    color: 'inherit',
    maxWidth: '200px',
  },
  thead: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: white,
    minWidth: '15px',
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

export default AllProductsTable;
