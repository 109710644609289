import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import CollapsibleSection from 'components/upb_viewer/CollapsibleSection';
import { darkGrey2 } from 'lib/css/colors';
import { getURL, TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS } from 'lib/networking/endpoints';
import NestedFiltersGroup from 'components/upb_viewer/NestedFiltersGroup';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function CatalogSidePanel({ selectedCategories, setSelectedCategories, showSubOptions = true }) {
  const { data: { data: categories } = {}, loading } = useGet(
    getURL(TAXONOMY_UPB_VIEW_CATEGORY_OPTIONS, {}),
    { with_sub_options: true },
  );

  const transformOptions = options => {
    // ex options: [{ "category_name": "Bev-Alc", "id": 2, sub_options: [] }]
    if (!options) return options;
    return options.map(option => {
      const transformed = {
        label: option.label || option.category_name,
        value: option.value || option.id,
      };

      if (option.sub_options) {
        transformed.subOptions = transformOptions(option.sub_options);
      }
      // { "label": "Bev-Alc", "value": 2, subOptions: []}
      return transformed;
    });
  };

  const categoryOptions = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    const catOptions = transformOptions(categories).sort((a, b) => a.label.localeCompare(b.label));
    return catOptions;
    // eslint-disable-next-line
  }, [categories]);

  return (
    <div className={css(styles.container)}>
      <CollapsibleSection title="Category" expandedByDefault={true}>
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <div className={css(styles.filterGroupContainer)}>
            <NestedFiltersGroup
              options={categoryOptions}
              selectedOptions={selectedCategories}
              setSelectedOptions={setSelectedCategories}
              showSubOptions={showSubOptions}
            />
          </div>
        )}
      </CollapsibleSection>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 35,
    overflowY: 'auto',
    borderRight: '1px solid ' + darkGrey2,
  },
  filterGroupContainer: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
});

CatalogSidePanel.propTypes = {
  selectedCategories: PropTypes.arrayOf(PropTypes.object),
  setSelectedCategories: PropTypes.func,
  showSubOptions: PropTypes.bool,
};

export default CatalogSidePanel;
