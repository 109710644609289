import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

function ActionDropDown({ submenuItems, label = 'Actions' }) {
  return (
    <Dropdown drop="down">
      <Dropdown.Toggle variant="outline-primary">{label}</Dropdown.Toggle>
      <Dropdown.Menu className={css(styles.dropdownMenu)}>
        {submenuItems.map((submenuItem, index) => (
          <Dropdown.Item
            onClick={submenuItem.action}
            key={index}
            className={css(styles.dropdownItem)}
            disabled={submenuItem.disabled || false}
          >
            {submenuItem.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const styles = StyleSheet.create({
  dropdownMenu: {
    padding: '0rem',
    minWidth: '6.4rem',
    fontSize: '0.8rem',
  },
  dropdownItem: {
    padding: '0.5rem 0.7rem',
    textDecoration: 'none',
  },
});

ActionDropDown.propTypes = {
  submenuItems: PropTypes.array,
  label: PropTypes.string,
};

export default ActionDropDown;
