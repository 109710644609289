import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

function CategoryFilter({
  categoryOptions,
  selectedCategoryName,
  onChange,
  formTitle,
  placeholder,
  loading,
}) {
  return (
    <Form.Group className={css(styles.fullWidth)}>
      <Form.Label>{formTitle}</Form.Label>
      <Select
        key={selectedCategoryName}
        isClearable
        isDisabled={loading}
        options={categoryOptions}
        value={categoryOptions.find(option => option?.label === selectedCategoryName)}
        onChange={(option, triggeredAction) => {
          onChange(option, triggeredAction);
        }}
        placeholder={loading ? 'Loading...' : placeholder}
      />
    </Form.Group>
  );
}

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
});

CategoryFilter.propTypes = {
  categoryOptions: PropTypes.array,
  selectedCategoryName: PropTypes.string,
  onChange: PropTypes.func,
  formTitle: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
};

export default CategoryFilter;
