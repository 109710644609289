import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Tab, Tabs } from 'react-bootstrap';

import OtherImagesSection from 'components/assets/image_recommendations/recommendations/OtherImagesSection';
import ThumbnailsSection from 'components/assets/image_recommendations/recommendations/ThumbnailsSection';
import UploadImagesSection from 'components/assets/image_recommendations/recommendations/UploadImagesSection';
import WebImagesSection from 'components/assets/image_recommendations/recommendations/WebImagesSection';

const IMAGE_RECOMMENDATIONS_TAB = {
  THUMBNAILS: 'Thumbnails',
  OTHER_IMAGES: 'Other Images',
  WEB_SEARCH_RESULTS: 'Web Search Results',
  UPLOAD_IMAGES: 'Upload Images',
};

function RecommendedImagesTabView({
  product,
  l4CategoryId,
  selectedThumbnailId,
  setSelectedThumbnailId,
  selectedThumbnailUrl,
  setSelectedThumbnailUrl,
  selectedImageIds,
  setSelectedImageIds,
  uploadedImages,
  setUploadedImages,
  webImages,
  setWebImages,
  hiddenTabs,
  setHiddenTabs,
}) {
  const [activeTabKey, setActiveTabKey] = useState(IMAGE_RECOMMENDATIONS_TAB.THUMBNAILS);

  useEffect(() => {
    if (hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.THUMBNAILS)) {
      if (hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES)) {
        if (hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS)) {
          setActiveTabKey(IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS);
        } else {
          setActiveTabKey(IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS);
        }
      } else {
        setActiveTabKey(IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES);
      }
    }
  }, [hiddenTabs]);

  return (
    <div>
      <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
        <Tab
          eventKey={IMAGE_RECOMMENDATIONS_TAB.THUMBNAILS}
          title={IMAGE_RECOMMENDATIONS_TAB.THUMBNAILS}
          tabClassName={classNames(
            hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.THUMBNAILS) && 'd-none',
          )}
        >
          <div
            className={classNames(
              'my-3',
              hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.THUMBNAILS) && 'd-none',
            )}
          >
            <ThumbnailsSection
              l4CategoryId={l4CategoryId}
              selectedThumbnailId={selectedThumbnailId}
              setSelectedThumbnailId={setSelectedThumbnailId}
              hideTab={() => setHiddenTabs([...hiddenTabs, IMAGE_RECOMMENDATIONS_TAB.THUMBNAILS])}
            />
          </div>
        </Tab>
        <Tab
          eventKey={IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES}
          title={IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES}
          tabClassName={classNames(
            hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES) && 'd-none',
          )}
        >
          <div
            className={classNames(
              'my-3',
              hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES) && 'd-none',
            )}
          >
            <OtherImagesSection
              l4CategoryId={l4CategoryId}
              selectedImageIds={selectedImageIds}
              setSelectedImageIds={setSelectedImageIds}
              selectedThumbnailUrl={selectedThumbnailUrl}
              setSelectedThumbnailUrl={setSelectedThumbnailUrl}
              hideTab={() => setHiddenTabs([...hiddenTabs, IMAGE_RECOMMENDATIONS_TAB.OTHER_IMAGES])}
            />
          </div>
        </Tab>
        <Tab
          eventKey={IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS}
          title={IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS}
          tabClassName={classNames(
            hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS) && 'd-none',
          )}
        >
          <div
            className={classNames(
              'my-3',
              hiddenTabs.includes(IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS) && 'd-none',
            )}
          >
            <WebImagesSection
              product={product}
              selectedUrls={webImages}
              setSelectedUrls={setWebImages}
              selectedThumbnailUrl={selectedThumbnailUrl}
              setSelectedThumbnailUrl={setSelectedThumbnailUrl}
              hideTab={() =>
                setHiddenTabs([...hiddenTabs, IMAGE_RECOMMENDATIONS_TAB.WEB_SEARCH_RESULTS])
              }
            />
          </div>
        </Tab>
        <Tab
          eventKey={IMAGE_RECOMMENDATIONS_TAB.UPLOAD_IMAGES}
          title={IMAGE_RECOMMENDATIONS_TAB.UPLOAD_IMAGES}
        >
          <div className="my-3">
            <UploadImagesSection
              product={product}
              imageUrls={uploadedImages}
              setImageUrls={setUploadedImages}
              selectedThumbnailUrl={selectedThumbnailUrl}
              setSelectedThumbnailUrl={setSelectedThumbnailUrl}
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

RecommendedImagesTabView.propTypes = {
  product: PropTypes.object,
  l4CategoryId: PropTypes.number,
  selectedThumbnailId: PropTypes.number,
  setSelectedThumbnailId: PropTypes.func,
  selectedImageIds: PropTypes.array,
  setSelectedImageIds: PropTypes.func,
  uploadedImages: PropTypes.array,
  setUploadedImages: PropTypes.func,
  webImages: PropTypes.array,
  setWebImages: PropTypes.func,
  hiddenTabs: PropTypes.array,
  setHiddenTabs: PropTypes.func,
  selectedThumbnailUrl: PropTypes.string,
  setSelectedThumbnailUrl: PropTypes.func,
};

export default RecommendedImagesTabView;
