import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Container from 'components/shared/Container';
import { FOR_LEADERBOARD } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import useGet from 'lib/hooks/useGet';

function LeaderBoardView() {
  const { data: { users } = {}, loading } = useGet(FOR_LEADERBOARD, {});
  return (
    <Container className="mt-4">
      <h2 className="ml-2 mb-4">Brand QA Leaderboard</h2>
      {loading ? (
        <LoadingSpinner />
      ) : users?.length > 0 ? (
        <>
          <Row className="mb-3" style={{ fontWeight: 'bold', borderBottom: '2px solid #ddd' }}>
            <Col xs={1} className="text-center"></Col>
            <Col xs={3} className="text-start">
              User
            </Col>
            <Col xs={2} className="text-center">
              Score
            </Col>
          </Row>
          {users.map((user, index) => (
            <Row
              key={user.id}
              className="align-items-center py-2"
              style={{
                borderBottom: '1px solid #eee',
                backgroundColor: index % 2 === 0 ? '#f8f9fa' : 'white',
              }}
            >
              <Col xs={1} className="text-center">
                {index + 1}
              </Col>
              <Col xs={3}>
                <span>{user.user.name}</span>
              </Col>
              <Col xs={2} className="text-center">
                {user.score}
              </Col>
            </Row>
          ))}
        </>
      ) : (
        <Row className="text-center mt-4">
          <Col>
            <strong>No scores to display yet</strong>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default LeaderBoardView;
