import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { Dropdown, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';

import { NAVIGATION_SIDE_BAR_WIDTH } from 'lib/constants';
import { backgroundGrey3, deeplavender, greyscale } from 'lib/css/colors';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function SideNavItemWithChildren({ title, icon, submenuItems }) {
  const hasUserPermissions = useUserPermissions();

  const loc = useLocation();
  const isActive = submenuItems.some(item => isEqual(loc.pathname, item.url));

  return (
    <Dropdown drop="right">
      <Dropdown.Toggle
        as={Nav.Link}
        className={classNames(css(styles.link, isActive && styles.currentIcon))}
      >
        <div className={css(styles.iconContainer)}>
          <FontAwesomeIcon size="lg" icon={icon} />
          <div className={css(styles.textWrapper)}>{title}</div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className={css(styles.dropdownMenu)}>
        {submenuItems.map((submenuItem, index) =>
          hasUserPermissions(submenuItem.permissions) ? (
            <LinkContainer className={css(styles.subLink)} key={index} to={submenuItem.url}>
              <Dropdown.Item className={css(styles.dropdownItem)}>
                {submenuItem.title}
              </Dropdown.Item>
            </LinkContainer>
          ) : null,
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const styles = StyleSheet.create({
  currentIcon: {
    color: deeplavender,
    backgroundColor: backgroundGrey3,
  },
  dropdownMenu: {
    padding: '0rem',
    minWidth: '6.4rem',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  dropdownItem: {
    padding: '0.5rem 0.7rem',
    textDecoration: 'none',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    '::after': {
      display: 'none',
    },
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem',
    color: greyscale,
    maxWidth: NAVIGATION_SIDE_BAR_WIDTH - 4,
  },
  subLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    fontSize: '0.8rem',
  },
  textWrapper: {
    fontSize: '10px',
  },
});

SideNavItemWithChildren.propTypes = {
  submenuItems: PropTypes.array,
  title: PropTypes.string,
  icon: PropTypes.object,
};

export default SideNavItemWithChildren;
