import { css, StyleSheet } from 'aphrodite';

function fontSize(size = '15px', weight = 'normal', lineHeight) {
  const sizedFont = {
    fontSize: size,
    fontWeight: weight,
  };
  if (lineHeight) {
    sizedFont.lineHeight = lineHeight;
  }
  const style = StyleSheet.create({ sizedFont });

  return css(style.sizedFont);
}

export { fontSize };
