import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Button, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import Swal from 'sweetalert2';

import ActionDropDown from 'components/shared/ActionDropDown';
import AdvancedAttributeMappingModal from 'components/data_sources/single_data_source_view/AdvancedAttributeMappingModal';
import {
  ATTRIBUTES_FOR_DATA_SOURCE_MAPPING,
  DATA_SOURCE_UPDATE,
  DATA_SOURCE_RESET_ATTRIBUTE_MAP,
  DATA_SOURCE_GET_BY_ID,
  getURL,
} from 'lib/networking/endpoints';
import { areYouSure } from 'lib/utils/toast';
import Container from 'components/shared/Container';
import { DATA_SOURCE_PREPROCESS_EXPORT } from 'lib/networking/endpoints';
import DataSourceAttributesTableRow from 'components/data_sources/single_data_source_view/DataSourceAttributesTableRow';
import isAdvancedMapping from 'lib/utils/isAdvancedMapping';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import UnmappedAttributeStatus from 'lib/enums/UnmappedAttributeStatus';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import usePut from 'lib/hooks/usePut';

function DataSourceAttributesTab({ dataSource, refetch }) {
  const scheduleRefetch = useRef(false);
  const [showAdvancedMappingModal, setShowAdvancedMappingModal] = useState(false);
  const [unmappedAttributes, setUnmappedAttributes] = useState([]);
  const { data: { data: attributes } = {}, loading } = useGet(ATTRIBUTES_FOR_DATA_SOURCE_MAPPING);
  const { putData: updateDataSource, loading: updating } = usePut(DATA_SOURCE_UPDATE, () => {
    refetch();
  });
  const attributeOptions = useMemo(() => {
    if (!attributes?.length) {
      return [];
    }
    return attributes.map(attribute => ({
      attributeID: attribute?.attribute_id,
      globalAttributeID: attribute?.global_attribute_id,
      value: attribute.name,
      label: attribute.name,
      inputType: attribute.input_type,
    }));
  }, [attributes]);

  const [unsavedAttributeMap, setUnsavedAttributeMap] = useState(dataSource.attribute_map || {});
  const [sortedUnsavedAttributeMap, setSortedUnsavedAttributeMap] = useState({});

  const { data: dataSourceForPolling, refetch: poll } = useGet(
    getURL(DATA_SOURCE_GET_BY_ID, { data_source_id: dataSource.id }),
  );

  useEffect(() => {
    //This is used to poll the database to check whether the value mapping is generated
    if (dataSourceForPolling?.file_reading_status) {
      const interval = setInterval(() => {
        poll();
      }, 3000);
      scheduleRefetch.current = true;
      return () => clearInterval(interval);
    } else if (!dataSourceForPolling?.file_reading_status && scheduleRefetch.current) {
      refetch();
    }
  }, [dataSourceForPolling, poll, refetch]);

  useEffect(() => {
    if (dataSource.unmapped_attributes) {
      setUnmappedAttributes(
        dataSource.unmapped_attributes
          .filter(attribute => attribute.status === UnmappedAttributeStatus.PENDING)
          .map(attribute => attribute.source_attribute_name),
      );
    }
  }, [dataSource]);

  useEffect(() => {
    // sort unsavedAttributeMap by whether it is an advanced mapping or not
    const advancedMappings = {};
    const sortedUnsavedAttributes = {};

    Object.keys(unsavedAttributeMap).forEach(sourceAttribute => {
      if (isAdvancedMapping(sourceAttribute)) {
        advancedMappings[sourceAttribute] = unsavedAttributeMap[sourceAttribute];
      } else {
        sortedUnsavedAttributes[sourceAttribute] = unsavedAttributeMap[sourceAttribute];
      }
    });

    setSortedUnsavedAttributeMap({ ...sortedUnsavedAttributes, ...advancedMappings });
  }, [dataSource, unsavedAttributeMap]);

  const { postData: exportPreprocessedFile } = usePost(
    DATA_SOURCE_PREPROCESS_EXPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  const { postData: resetAttributeMap, loading: resetting } = usePost(
    DATA_SOURCE_RESET_ATTRIBUTE_MAP,
    () => refetch(),
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  const attributeMap = dataSource.attribute_map || {};
  return (
    <Container className={css(styles.container)}>
      <div className="mb-3 w-100 d-flex justify-content-end">
        <Button
          className="px-5 mr-3"
          variant="outline-primary"
          disabled={
            (isEqual(attributeMap, unsavedAttributeMap) && unmappedAttributes.length === 0) ||
            updating ||
            dataSourceForPolling?.file_reading_status
          }
          onClick={() => {
            updateDataSource({
              data_source_id: dataSource.id,
              attribute_map: unsavedAttributeMap,
              unmapped_attributes: unmappedAttributes,
            });
          }}
        >
          {dataSourceForPolling?.file_reading_status ? 'Generating Column Value Map' : 'Save'}
        </Button>
        {resetting ? (
          <div className="d-flex mx-4">
            <LoadingSpinner short />
          </div>
        ) : (
          <Button
            className="px-5 mr-3"
            variant="outline-primary"
            disabled={resetting}
            onClick={() => {
              areYouSure(
                () =>
                  resetAttributeMap({
                    data_source_id: dataSource.id,
                  }),
                'Are you sure you want to reset the attribute map?' +
                  'This will reset the attribute map from the current uploaded source file.',
              );
            }}
          >
            Reset Map
          </Button>
        )}

        <ActionDropDown
          submenuItems={[
            {
              title: 'Export preprocessed file (csv)',
              action: () =>
                exportPreprocessedFile({ data_source_id: dataSource.id, file_format: 'csv' }),
            },
            {
              title: 'Export preprocessed file (xlsx)',
              action: () =>
                exportPreprocessedFile({ data_source_id: dataSource.id, file_format: 'xlsx' }),
            },
          ]}
        />
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>Source Attribute</th>
            <th />
            <th>FSA Attribute</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(sortedUnsavedAttributeMap).map((sourceAttribute, idx) => (
            <DataSourceAttributesTableRow
              key={idx}
              sourceAttribute={sourceAttribute}
              sortedUnsavedAttributeMap={sortedUnsavedAttributeMap}
              setUnsavedAttributeMap={setUnsavedAttributeMap}
              unmappedAttributes={unmappedAttributes}
              setUnmappedAttributes={setUnmappedAttributes}
              attributeOptions={attributeOptions}
              dataSource={dataSource}
              refetch={refetch}
            />
          ))}
        </tbody>
      </Table>
      <div
        className={classNames('d-flex align-items-center', pointerOnHover)}
        onClick={() => setShowAdvancedMappingModal(true)}
      >
        <FontAwesomeIcon icon={faPlusSquare} size="2x" />
        <span className="ml-2">Advanced Mapping</span>
      </div>
      {showAdvancedMappingModal && (
        <AdvancedAttributeMappingModal
          onHide={() => setShowAdvancedMappingModal(false)}
          attributes={attributes}
          attributeMap={attributeMap}
          unsavedAttributeMap={sortedUnsavedAttributeMap}
          setUnsavedAttributeMap={setUnsavedAttributeMap}
        />
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '70vh',
  },
});

DataSourceAttributesTab.propTypes = {
  dataSource: PropTypes.object,
  refetch: PropTypes.func,
};

export default DataSourceAttributesTab;
