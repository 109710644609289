import React from 'react';
import PropTypes from 'prop-types';

import { fontStyles } from 'components/shared/nutrition_facts/sharedStyles';

function ServingsSection({ servingsPerPackage, description, quantity, uom }) {
  const valueString = quantity && quantity !== '0' && uom ? `${quantity}${uom}` : null;

  const servingSize = [description, valueString ? `(${valueString})` : null]
    .filter(x => x)
    .join(' ');

  // If both servingsPerPackage and servingSize are missing, return null
  if (!servingsPerPackage && !servingSize) {
    return null;
  }

  return (
    <div className="py-1">
      {servingsPerPackage && (
        <div className={fontStyles('20px')}>{servingsPerPackage} servings per container</div>
      )}
      {/* Conditionally render serving size information */}
      {servingSize && (
        <div className="w-100 d-flex justify-content-between">
          <div className={fontStyles('20px', true)}>Serving size</div>
          <div className={fontStyles('20px', true)}>{servingSize}</div>
        </div>
      )}
    </div>
  );
}

ServingsSection.propTypes = {
  servingsPerPackage: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.string,
  uom: PropTypes.string,
};

// Default props for optional properties
ServingsSection.defaultProps = {
  servingsPerPackage: null,
  description: '',
  quantity: '',
  uom: '',
};

export default ServingsSection;
