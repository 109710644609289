import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import CaloriesSection from 'components/shared/nutrition_facts/CaloriesSection';
import CarbohydratesSection from 'components/shared/nutrition_facts/CarbohydratesSection';
import FatSection from 'components/shared/nutrition_facts/FatSection';
import { fontStyles } from 'components/shared/nutrition_facts/sharedStyles';
import MainNutritionRow from 'components/shared/nutrition_facts/MainNutritionRow';
import Separator, {
  MEDIUM_SEPARATOR_THICKNESS,
  LARGE_SEPARATOR_THICKNESS,
} from 'components/shared/nutrition_facts/Separator';
import ServingsSection from 'components/shared/nutrition_facts/ServingsSection';
import SubNutritionRow from 'components/shared/nutrition_facts/SubNutritionRow';
import useFindAttributeValueByName from 'lib/hooks/product/useFindAttributeValueByName';

const NUTRITION_ATTRIBUTES = {
  SERVING_SIZE_QUANTITY: 'serving_size_quantity',
  SERVING_SIZE_UOM: 'serving_size_uom',
  SERVING_SIZE_DESCRIPTION: 'serving_size_description',
  NUMBER_OF_SERVINGS_PER_PACKAGE: 'number_of_servings_per_package',
  CALORIES_QUANTITYCONTAINED: 'calories_quantitycontained',
  CHOLESTEROL_QUANTITYCONTAINED: 'cholesterol_quantitycontained',
  CHOLESTEROL_UOM: 'cholesterol_uom',
  CHOLESTEROL_RDI_DAILYVALUEINTAKEPERCENT: 'cholesterol_rdi_dailyvalueintakepercent',
  SODIUM_QUANTITYCONTAINED: 'sodium_quantitycontained',
  SODIUM_UOM: 'sodium_uom',
  SODIUM_RDI_DAILYVALUEINTAKEPERCENT: 'sodium_rdi_dailyvalueintakepercent',
  PROTEIN_QUANTITYCONTAINED: 'protein_quantitycontained',
  PROTEIN_UOM: 'protein_uom',
  PROTEIN_RDI_DAILYVALUEINTAKEPERCENT: 'protein_rdi_dailyvalueintakepercent',
  VITAMIN_D_QUANTITYCONTAINED: 'vitamin_d_quantitycontained',
  VITAMIN_D_UOM: 'vitamin_d_uom',
  VITAMIN_D_RDI_DAILYVALUEINTAKEPERCENT: 'vitamin_d_rdi_dailyvalueintakepercent',
  CALCIUM_QUANTITYCONTAINED: 'calcium_quantitycontained',
  CALCIUM_UOM: 'calcium_uom',
  CALCIUM_RDI_DAILYVALUEINTAKEPERCENT: 'calcium_rdi_dailyvalueintakepercent',
  IRON_QUANTITYCONTAINED: 'iron_quantitycontained',
  IRON_UOM: 'iron_uom',
  IRON_RDI_DAILYVALUEINTAKEPERCENT: 'iron_rdi_dailyvalueintakepercent',
  POTASSIUM_QUANTITYCONTAINED: 'potassium_quantitycontained',
  POTASSIUM_UOM: 'potassium_uom',
  POTASSIUM_RDI_DAILYVALUEINTAKEPERCENT: 'potassium_rdi_dailyvalueintakepercent',
  VITAMIN_A_QUANTITYCONTAINED: 'vitamin_a_quantitycontained',
  VITAMIN_A_UOM: 'vitamin_a_uom',
  VITAMIN_A_RDI_DAILYVALUEINTAKEPERCENT: 'vitamin_a_rdi_dailyvalueintakepercent',
  VITAMIN_C_QUANTITYCONTAINED: 'vitamin_c_quantitycontained',
  VITAMIN_C_UOM: 'vitamin_c_uom',
  VITAMIN_C_RDI_DAILYVALUEINTAKEPERCENT: 'vitamin_c_rdi_dailyvalueintakepercent',
  THIAMIN_QUANTITYCONTAINED: 'thiamin_quantitycontained',
  THIAMIN_UOM: 'thiamin_uom',
  THIAMIN_RDI_DAILYVALUEINTAKEPERCENT: 'thiamin_rdi_dailyvalueintakepercent',
  RIBOFLAVIN_B2_QUANTITYCONTAINED: 'riboflavin_b2_quantitycontained',
  RIBOFLAVIN_B2_UOM: 'riboflavin_b2_uom',
  RIBOFLAVIN_RDI_DAILYVALUEINTAKEPERCENT: 'riboflavin_rdi_dailyvalueintakepercent',
  NIACIN_QUANTITYCONTAINED: 'niacin_quantitycontained',
  NIACIN_UOM: 'niacin_uom',
  NIACIN_RDI_DAILYVALUEINTAKEPERCENT: 'niacin_rdi_dailyvalueintakepercent',
  VITAMIN_B6_QUANTITYCONTAINED: 'vitamin_b6_quantitycontained',
  VITAMIN_B6_UOM: 'vitamin_b6_uom',
  VITAMIN_B6_RDI_DAILYVALUEINTAKEPERCENT: 'vitamin_b6_rdi_dailyvalueintakepercent',
  FOLATE_QUANTITYCONTAINED: 'folate_quantitycontained',
  FOLATE_UOM: 'folate_uom',
  TOTAL_FOLATE_RDI_DAILYVALUEINTAKEPERCENT: 'total_folate_rdi_dailyvalueintakepercent',
  VITAMIN_B12_QUANTITYCONTAINED: 'vitamin_b12_quantitycontained',
  VITAMIN_B12_UOM: 'vitamin_b12_uom',
  VITAMIN_B12_RDI_DAILYVALUEINTAKEPERCENT: 'vitamin_b12_rdi_dailyvalueintakepercent',
  PHOSPHOROUS_QUANTITYCONTAINED: 'phosphorous_quantitycontained',
  PHOSPHOROUS_UOM: 'phosphorous_uom',
  PHOSPHOROUS_RDI_DAILYVALUEINTAKEPERCENT: 'phosphorous_rdi_dailyvalueintakepercent',
  MAGNESIUM_QUANTITYCONTAINED: 'magnesium_quantitycontained',
  MAGNESIUM_UOM: 'magnesium_uom',
  MAGNESIUM_RDI_DAILYVALUEINTAKEPERCENT: 'magnesium_rdi_dailyvalueintakepercent',
  ZINC_QUANTITYCONTAINED: 'zinc_quantitycontained',
  ZINC_UOM: 'zinc_uom',
  ZINC_RDI_DAILYVALUEINTAKEPERCENT: 'zinc_rdi_dailyvalueintakepercent',
  TOTAL_FAT_QUANTITYCONTAINED: 'total_fat_quantitycontained',
  TOTAL_FAT_UOM: 'total_fat_uom',
  TOTAL_FAT_RDI_DAILYVALUEINTAKEPERCENT: 'total_fat_rdi_dailyvalueintakepercent',
  SATURATED_FAT_QUANTITYCONTAINED: 'saturated_fat_quantitycontained',
  SATURATED_FAT_UOM: 'saturated_fat_uom',
  SATURATED_FAT_RDI_DAILYVALUEINTAKEPERCENT: 'saturated_fat_rdi_dailyvalueintakepercent',
  TRANSFATTY_ACIDS_QUANTITYCONTAINED: 'transfatty_acids_quantitycontained',
  TRANSFATTY_ACIDS_UOM: 'transfatty_acids_uom',
  POLYUNSATURATED_FAT_QUANTITYCONTAINED: 'polyunsaturated_fat_quantitycontained',
  POLYUNSATURATED_FAT_UOM: 'polyunsaturated_fat_uom',
  MONOUNSATURATED_FAT_QUANTITYCONTAINED: 'monounsaturated_fat_quantitycontained',
  MONOUNSATURATED_FAT_UOM: 'monounsaturated_fat_uom',
  CARBOHYDRATES_QUANTITYCONTAINED: 'carbohydrates_quantitycontained',
  CARBOHYDRATES_UOM: 'carbohydrates_uom',
  CARBOHYDRATES_RDI_DAILYVALUEINTAKEPERCENT: 'carbohydrates_rdi_dailyvalueintakepercent',
  TOTAL_DIETARY_FIBER_QUANTITYCONTAINED: 'total_dietary_fiber_quantitycontained',
  TOTAL_DIETARY_FIBER_UOM: 'total_dietary_fiber_uom',
  TOTAL_DIETARY_FIBER_RDI_DAILYVALUEINTAKEPERCENT:
    'total_dietary_fiber_rdi_dailyvalueintakepercent',
  SOLUBLE_FIBER_QUANTITYCONTAINED: 'soluble_fiber_quantitycontained',
  SOLUBLE_FIBER_UOM: 'soluble_fiber_uom',
  INSOLUBLE_FIBER_QUANTITYCONTAINED: 'insoluble_fiber_quantitycontained',
  INSOLUBLE_FIBER_UOM: 'insoluble_fiber_uom',
  TOTAL_SUGAR_QUANTITYCONTAINED: 'total_sugar_quantitycontained',
  TOTAL_SUGAR_UOM: 'total_sugar_uom',
  ADDED_SUGAR_QUANTITYCONTAINED: 'added_sugar_quantitycontained',
  ADDED_SUGAR_UOM: 'added_sugar_uom',
  ADDED_SUGAR_RDI_DAILYVALUEINTAKEPERCENT: 'added_sugar_rdi_dailyvalueintakepercent',
};

function ProductNutritionFactsView({ nutritionAttributes }) {
  const servingSizeQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SERVING_SIZE_QUANTITY,
  );
  const servingSizeUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SERVING_SIZE_UOM,
  );
  const servingSizeDescription = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SERVING_SIZE_DESCRIPTION,
  );
  const servingsPerPackage = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.NUMBER_OF_SERVINGS_PER_PACKAGE,
  );
  const calories = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CALORIES_QUANTITYCONTAINED,
  );
  const cholesterolQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CHOLESTEROL_QUANTITYCONTAINED,
  );
  const cholesterolUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CHOLESTEROL_UOM,
  );
  const cholesterolDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CHOLESTEROL_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const sodiumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SODIUM_QUANTITYCONTAINED,
  );
  const sodiumUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SODIUM_UOM,
  );
  const sodiumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.SODIUM_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const proteinQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.PROTEIN_QUANTITYCONTAINED,
  );
  const proteinUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.PROTEIN_UOM,
  );
  const proteinDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.PROTEIN_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const vitaminDQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_D_QUANTITYCONTAINED,
  );
  const vitaminDUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_D_UOM,
  );
  const vitaminDDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_D_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const calciumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CALCIUM_QUANTITYCONTAINED,
  );
  const calciumUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CALCIUM_UOM,
  );
  const calciumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.CALCIUM_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const ironQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.IRON_QUANTITYCONTAINED,
  );
  const ironUom = useFindAttributeValueByName(nutritionAttributes, NUTRITION_ATTRIBUTES.IRON_UOM);
  const ironDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.IRON_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const potassiumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.POTASSIUM_QUANTITYCONTAINED,
  );
  const potassiumUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.POTASSIUM_UOM,
  );
  const potassiumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.POTASSIUM_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const vitaminAQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_A_QUANTITYCONTAINED,
  );
  const vitaminAUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_A_UOM,
  );
  const vitaminADailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_A_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const vitaminCQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_C_QUANTITYCONTAINED,
  );
  const vitaminCUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_C_UOM,
  );
  const vitaminCDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_C_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const thiaminQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.THIAMIN_QUANTITYCONTAINED,
  );
  const thiaminUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.THIAMIN_UOM,
  );
  const thiaminDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.THIAMIN_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const riboflavinQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.RIBOFLAVIN_B2_QUANTITYCONTAINED,
  );
  const riboflavinUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.RIBOFLAVIN_B2_UOM,
  );
  const riboflavinDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.RIBOFLAVIN_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const niacinQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.NIACIN_QUANTITYCONTAINED,
  );
  const niacinUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.NIACIN_UOM,
  );
  const niacinDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.NIACIN_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const vitaminB6Quantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_B6_QUANTITYCONTAINED,
  );
  const vitaminB6Uom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_B6_UOM,
  );
  const vitaminB6DailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_B6_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const folateQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.FOLATE_QUANTITYCONTAINED,
  );
  const folateUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.FOLATE_UOM,
  );
  const folateDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.TOTAL_FOLATE_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const vitaminB12Quantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_B12_QUANTITYCONTAINED,
  );
  const vitaminB12Uom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_B12_UOM,
  );
  const vitaminB12DailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.VITAMIN_B12_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const phosphorousQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.PHOSPHOROUS_QUANTITYCONTAINED,
  );
  const phosphorousUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.PHOSPHOROUS_UOM,
  );
  const phosphorousDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.PHOSPHOROUS_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const magnesiumQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.MAGNESIUM_QUANTITYCONTAINED,
  );
  const magnesiumUom = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.MAGNESIUM_UOM,
  );
  const magnesiumDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.MAGNESIUM_RDI_DAILYVALUEINTAKEPERCENT,
  );
  const zincQuantity = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.ZINC_QUANTITYCONTAINED,
  );
  const zincUom = useFindAttributeValueByName(nutritionAttributes, NUTRITION_ATTRIBUTES.ZINC_UOM);
  const zincDailyValue = useFindAttributeValueByName(
    nutritionAttributes,
    NUTRITION_ATTRIBUTES.ZINC_RDI_DAILYVALUEINTAKEPERCENT,
  );

  if (!nutritionAttributes?.length) {
    return null;
  }

  return (
    <div className="border border-dark p-2">
      <div className={fontStyles('44px', true, true)}>Nutrition Facts</div>
      <Separator />

      <ServingsSection
        servingsPerPackage={servingsPerPackage}
        description={servingSizeDescription}
        quantity={servingSizeQuantity}
        uom={servingSizeUom}
      />
      <Separator thickness={LARGE_SEPARATOR_THICKNESS} />

      <CaloriesSection calories={calories} />
      <Separator thickness={MEDIUM_SEPARATOR_THICKNESS} />

      <div className={classNames('w-100 text-right', fontStyles('12px', true))}>% Daily Value*</div>
      <Separator />

      <FatSection nutritionAttributes={nutritionAttributes} />

      <MainNutritionRow
        title="Cholesterol"
        quantity={cholesterolQuantity}
        uom={cholesterolUom}
        percentage={cholesterolDailyValue}
      />

      <MainNutritionRow
        title="Sodium"
        quantity={sodiumQuantity}
        uom={sodiumUom}
        percentage={sodiumDailyValue}
      />

      <CarbohydratesSection nutritionAttributes={nutritionAttributes} />

      <MainNutritionRow
        title="Protein"
        quantity={proteinQuantity}
        uom={proteinUom}
        percentage={proteinDailyValue}
      />

      <Separator thickness={LARGE_SEPARATOR_THICKNESS} />

      <SubNutritionRow
        title="Vitamin D"
        quantity={vitaminDQuantity}
        uom={vitaminDUom}
        percentage={vitaminDDailyValue}
      />
      <SubNutritionRow
        title="Calcium"
        quantity={calciumQuantity}
        uom={calciumUom}
        percentage={calciumDailyValue}
      />
      <SubNutritionRow
        title="Iron"
        quantity={ironQuantity}
        uom={ironUom}
        percentage={ironDailyValue}
      />
      <SubNutritionRow
        title="Potassium"
        quantity={potassiumQuantity}
        uom={potassiumUom}
        percentage={potassiumDailyValue}
      />
      <SubNutritionRow
        title="Vitamin A"
        quantity={vitaminAQuantity}
        uom={vitaminAUom}
        percentage={vitaminADailyValue}
      />
      <SubNutritionRow
        title="Vitamin C"
        quantity={vitaminCQuantity}
        uom={vitaminCUom}
        percentage={vitaminCDailyValue}
      />
      <SubNutritionRow
        title="Thiamin"
        quantity={thiaminQuantity}
        uom={thiaminUom}
        percentage={thiaminDailyValue}
      />
      <SubNutritionRow
        title="Riboflavin"
        quantity={riboflavinQuantity}
        uom={riboflavinUom}
        percentage={riboflavinDailyValue}
      />
      <SubNutritionRow
        title="Niacin"
        quantity={niacinQuantity}
        uom={niacinUom}
        percentage={niacinDailyValue}
      />
      <SubNutritionRow
        title={
          <span>
            Vitamin B<sub>6</sub>
          </span>
        }
        quantity={vitaminB6Quantity}
        uom={vitaminB6Uom}
        percentage={vitaminB6DailyValue}
      />
      <SubNutritionRow
        title="Folate"
        quantity={folateQuantity}
        uom={folateUom}
        percentage={folateDailyValue}
      />
      <SubNutritionRow
        title={
          <span>
            Vitamin B<sub>12</sub>
          </span>
        }
        quantity={vitaminB12Quantity}
        uom={vitaminB12Uom}
        percentage={vitaminB12DailyValue}
      />
      <SubNutritionRow
        title="Phosphorous"
        quantity={phosphorousQuantity}
        uom={phosphorousUom}
        percentage={phosphorousDailyValue}
      />
      <SubNutritionRow
        title="Magnesium"
        quantity={magnesiumQuantity}
        uom={magnesiumUom}
        percentage={magnesiumDailyValue}
      />
      <SubNutritionRow
        title="Zinc"
        quantity={zincQuantity}
        uom={zincUom}
        percentage={zincDailyValue}
        skipSeparator
      />
      <Separator thickness={MEDIUM_SEPARATOR_THICKNESS} />

      <div className={classNames(fontStyles('12px'), 'd-flex justify-content-between')}>
        <div className="pr-1">*</div>
        <div>
          The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a
          daily diet. 2,000 calories a day is used for general nutrition advice.
        </div>
      </div>
    </div>
  );
}

ProductNutritionFactsView.propTypes = {
  nutritionAttributes: PropTypes.array,
};

export default ProductNutritionFactsView;
export { NUTRITION_ATTRIBUTES };
