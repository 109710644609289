import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getURL,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_MANUFACTURERS_GET_BY_ID,
} from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function ManufacturerFilter({ manufacturerID, label, handleUpdateBrand }) {
  const [initOption, setInitOption] = useState({});
  // initial load
  useEffect(() => {
    if (manufacturerID) {
      const url = getURL(GLOBAL_MANUFACTURERS_GET_BY_ID, { manufacturer_id: manufacturerID });
      httpGet(url).then(res => {
        if (res.status === 200) {
          const manufacturer = res.data?.manufacturer;
          if (manufacturer) {
            setInitOption({ label: manufacturer.name, value: manufacturer.id });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchableScrollableSelect
      initOption={initOption}
      onChange={option => handleUpdateBrand(label, option?.value)}
      fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
      optionMapper={manufacturer => ({ label: manufacturer.name, value: manufacturer.id })}
    />
  );
}

ManufacturerFilter.propTypes = {
  manufacturerID: PropTypes.number,
  label: PropTypes.string,
  handleUpdateBrand: PropTypes.func,
};

export default ManufacturerFilter;
