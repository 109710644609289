import React from 'react';
import PropTypes from 'prop-types';

import AttributeSection from 'components/upb_viewer/pdp_components/AttributeSection';
import AttributeSubsection from 'components/upb_viewer/pdp_components/AttributeSubsection';
import AttributesParagraphView from 'components/upb_viewer/pdp_components/AttributesParagraphView';
import AttributesTableView from 'components/upb_viewer/pdp_components/AttributesTableView';

function ProductIdentifiersSection({ productIdentifiers }) {
  if (!productIdentifiers.length) {
    return null;
  }

  return (
    <AttributeSection title={'Product Identifiers'}>
      <AttributeSubsection>
        <AttributesParagraphView paragraph={'These are only shown to you for your convenience.'} />
      </AttributeSubsection>
      <AttributesTableView attributes={productIdentifiers} linkToPDPPage={true} />
    </AttributeSection>
  );
}

ProductIdentifiersSection.propTypes = {
  productIdentifiers: PropTypes.array,
};

export default ProductIdentifiersSection;
