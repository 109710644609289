import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import DropZone from 'components/shared/Dropzone';
import SwalDialog from 'lib/utils/toast';
import { TAXONOMY } from 'lib/networking/endpoints';
import usePut from 'lib/hooks/usePut';

function UPBTaxonomyDetailsUpdateModal({ onHide, category, refetch }) {
  const [imageUrls, setImageUrls] = useState([]);
  const [displayName, setDisplayName] = useState(category.taxonomy_display_name || '');

  const { putData: editCategoryDetails } = usePut(
    TAXONOMY,
    () => {
      SwalDialog('success', 'Successfully updated category details', 'Success');
      onHide();
      refetch();
    },
    error => SwalDialog('error', error?.response?.data?.message, 'Error'),
  );

  return (
    <Modal show={true} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{`Level ${category.taxonomy_level} - ${category.category_name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row}>
          <Form.Label column sm="3" className="font-weight-bold">
            {'Icon'}
          </Form.Label>
          <Col sm="9">
            <DropZone
              text="Drag and drop image here, or click to select images"
              onUploadSuccess={newUrls => setImageUrls(newUrls)}
              fileUrls={imageUrls}
              isImage={true}
              isPublic={true}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3" className="font-weight-bold">
            {'Display Name'}
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              value={displayName}
              onChange={e => setDisplayName(e.target.value)}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            editCategoryDetails({
              category_level: category.taxonomy_level,
              category_id: category.id,
              taxonomy_display_name: displayName,
              icon_image_url: imageUrls ? imageUrls[0] : null,
            });
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UPBTaxonomyDetailsUpdateModal.propTypes = {
  show: PropTypes.bool,
  category: PropTypes.object,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default UPBTaxonomyDetailsUpdateModal;
