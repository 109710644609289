import { css, StyleSheet } from 'aphrodite';

const cursorStyles = StyleSheet.create({
  pointerOnHover: {
    ':hover': {
      cursor: 'pointer',
    },
  },
  stopOnHover: {
    ':hover': {
      cursor: 'not-allowed',
    },
  },
});

export default css(cursorStyles.pointerOnHover);
export { cursorStyles };
