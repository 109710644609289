import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { CND_SYNC_STATUS } from 'lib/constants';
import ProductListFilter from 'lib/enums/ProductListFilter';

function CutDrySyncStatusFilter({ selectedValue, updateFilters }) {
  const options = [
    {
      label: CND_SYNC_STATUS.YES,
      value: CND_SYNC_STATUS.YES,
    },
    {
      label: CND_SYNC_STATUS.NO,
      value: CND_SYNC_STATUS.NO,
    },
  ];

  return (
    <Form.Group>
      <Form.Label>Cut+Dry Sync Status</Form.Label>
      <Select
        isClearable
        options={options}
        value={options.find(val => val.value === selectedValue)}
        onChange={opt => updateFilters(ProductListFilter.CND_SYNC_STATUS, opt?.value)}
      />
    </Form.Group>
  );
}

CutDrySyncStatusFilter.propTypes = {
  selectedValue: PropTypes.string,
  updateFilters: PropTypes.func,
};

export default CutDrySyncStatusFilter;
