// update along with app/lib/enums/chnage_log_status.py
const CHANGE_LOG_STATUS = {
  ATTENDED: 'attended',
  PENDING: 'pending',
  CANCELED: 'canceled',
};

const CHANGE_LOG_STATUS_MAP = [
  { value: CHANGE_LOG_STATUS.ATTENDED, label: 'Resolved' },
  { value: CHANGE_LOG_STATUS.PENDING, label: 'Open' },
  { value: CHANGE_LOG_STATUS.CANCELED, label: 'Canceled' },
];

export default CHANGE_LOG_STATUS;
export { CHANGE_LOG_STATUS_MAP };
