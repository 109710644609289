import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { faBars, faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { remove, uniq } from 'lodash';
import Select from 'react-select/creatable';

import { GLOBAL_PRODUCT_COLUMNS } from 'lib/constants';
import pointerOnHover from 'lib/css/pointerOnHover';

const ALL_COLUMN_OPTIONS = GLOBAL_PRODUCT_COLUMNS.map(column => ({ label: column, value: column }));

function EditColumnsSection({ selectedView, updateViewColumns }) {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    result => {
      if (!selectedView || !result.destination) {
        return;
      }
      const sourceIdx = result.source.index;
      const destinationIdx = result.destination.index;
      if (sourceIdx === destinationIdx) {
        return;
      }
      const newColumns = Array.from(selectedView.columns);
      const updatedNewCloumns = reorder(newColumns, sourceIdx, destinationIdx);
      updateViewColumns(updatedNewCloumns);
    },
    [selectedView, updateViewColumns],
  );

  const customStyles = () => {
    return {
      control: (provided, _state) => ({
        ...provided,
        height: '30px',
        minHeight: '20px',
        fontWeight: 'normal',
      }),

      valueContainer: (provided, _state) => ({
        ...provided,
        padding: '0 5px',
      }),

      indicatorsContainer: (provided, _state) => ({
        ...provided,
        height: '25px',
      }),

      option: (provided, _state) => ({
        ...provided,
        fontWeight: 'normal',
        padding: '5px',
      }),
    };
  };

  if (!selectedView) {
    return null;
  }

  const columns = selectedView.columns;

  return (
    <>
      <h6>Columns</h6>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="columnsDroppable">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {columns?.map((column, idx) => (
                <Draggable key={column.name} draggableId={column.name} index={idx}>
                  {provided => (
                    <div
                      className="p-1 border-top d-flex justify-content-between"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <span>{column.name}</span>
                      <span className={pointerOnHover}>
                        <FontAwesomeIcon
                          icon={column.visible ? faEye : faEyeSlash}
                          className="mr-3"
                          onClick={() => {
                            const newColumns = [...columns];
                            newColumns[idx] = { ...column, visible: !column.visible };
                            updateViewColumns(newColumns);
                          }}
                        />
                        <FontAwesomeIcon icon={faBars} className="mr-3 text-secondary" />
                        <FontAwesomeIcon
                          className="text-danger"
                          icon={faTimes}
                          onClick={() =>
                            updateViewColumns(remove(columns, c => c.name !== column.name))
                          }
                        />
                      </span>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <hr />
      <div className="d-flex align-items-center">
        <span className="font-weight-bold mr-2">Add new column:</span>
        <Select
          className="flex-fill"
          menuPlacement="top"
          value={null}
          options={ALL_COLUMN_OPTIONS.filter(
            option => !columns.map(column => column.name).includes(option.value),
          )}
          onChange={option =>
            updateViewColumns(
              uniq([
                ...columns,
                {
                  name: option.value,
                  visible: true,
                },
              ]),
            )
          }
          styles={customStyles}
        />
      </div>
      <hr />
    </>
  );
}

EditColumnsSection.propTypes = {
  selectedView: PropTypes.object,
  updateViewColumns: PropTypes.func,
};

export default EditColumnsSection;
