import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { PRODUCT_LISTS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function ProductListSelector({ selectedProductListId, setSelectedProductListId }) {
  const { data: { product_lists: productLists } = {}, loading } = useGet(PRODUCT_LISTS);

  const productListsOptions = useMemo(() => {
    if (!productLists) return [];
    return productLists.map(productList => ({
      label: productList.name,
      value: productList.id,
    }));
  }, [productLists]);

  return (
    <>
      <Form.Group>
        <Form.Label>Product List</Form.Label>
        <Select
          isClearable
          value={productListsOptions.find(option => option.value === selectedProductListId)}
          options={productListsOptions}
          onChange={option => setSelectedProductListId(option?.value)}
          placeholder={loading ? 'Loading ...' : 'Select Product List...'}
        />
      </Form.Group>
    </>
  );
}

ProductListSelector.propTypes = {
  selectedProductListId: PropTypes.number,
  setSelectedProductListId: PropTypes.func,
};

export default ProductListSelector;
