import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Col, Container, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { backgroundGrey4 } from 'lib/css/colors';
import FiltersTabView from 'components/l4_default_images/FiltersTabView';
import L4DefaultImageSection from 'components/l4_default_images/L4DefaultImageSection';
import {
  getURL,
  TAXONOMY_CATEGORY_OPTIONS_FOR_PRODUCT_LIST,
  TAXONOMY_GET_REJECTED_L4_IMAGES_BY_USER_ID,
  TAXONOMY_L4_DEFAULT_IMAGE,
} from 'lib/networking/endpoints';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import useGet from 'lib/hooks/useGet';
import useTaxonomyOptions from 'lib/hooks/useTaxonomyOptions';

const FILTERS_TAB = {
  CATEGORIES: 'Categories',
  PRODUCT_LIST: 'Product List',
  REJECTED: 'Rejected',
};

function L4DefaultImageView() {
  const [selectedL4CategoryId, setSelectedL4CategoryId] = useState();
  const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState();
  const [l4Count, setL4Count] = useState();
  const [l4WithImagesCount, setL4WithImagesCount] = useState();
  const [l1Search, setL1Search] = useState('');
  const [l2Search, setL2Search] = useState('');
  const [l3Search, setL3Search] = useState('');
  const [selectedL4CategoryName, setSelectedL4CategoryName] = useState('');
  const [showUnassignedOnly, setShowUnassignedOnly] = useState(true);
  const [productList, setProductList] = useState();
  const [user, setUser] = useState();
  const [activeTabKey, setActiveTabKey] = useState(FILTERS_TAB.PRODUCT_LIST);

  const { data, loading } = useGet(TAXONOMY_L4_DEFAULT_IMAGE, {
    l4_category_id: selectedL4CategoryId,
  });

  const {
    data: {
      l4_count: l4CountForProductList,
      l4_with_images_count: l4WithImagesCountForProductList,
      filtered_l4_categories: l4OptionsWithProductList,
    } = {},
    loading: loadingl4OptionsWithProductList,
    refetch: refetchL4OptionsForProductList,
  } = useGet(
    getURL(TAXONOMY_CATEGORY_OPTIONS_FOR_PRODUCT_LIST, { product_list_id: productList?.id }),
    {
      category_level: TaxonomyLevel.L4,
      show_unassigned_only: showUnassignedOnly,
    },
    !productList,
  );

  const {
    data: { data: l4OCategoryptionsForUser } = {},
    loading: loadingl4OptionsForUser,
    refetch: refetchL4OptionsForUser,
  } = useGet(TAXONOMY_GET_REJECTED_L4_IMAGES_BY_USER_ID, { user_id: user?.id }, !user);

  const l4OptionsForProductList = l4OptionsWithProductList?.map(category => ({
    label: category.category_name,
    value: category.id,
    id: category.id,
  }));

  const l4OptionsForUser = l4OCategoryptionsForUser?.map(category => ({
    label: category.category_name,
    value: category.id,
    id: category.id,
  }));

  useEffect(() => {
    setSelectedThumbnailUrl(data?.default_image_url);
    setL4Count(data?.l4_category_count);
    setL4WithImagesCount(data?.l4_categories_with_images_count);
  }, [data]);

  useEffect(() => {
    setSelectedL4CategoryId(null);
    setSelectedL4CategoryName(undefined);
  }, [activeTabKey, productList, user]);

  const {
    options: { l1Options, l2Options, l3Options, l4Options } = {},
    loading: categoriesLoading,
    refetch: refetchCategories,
  } = useTaxonomyOptions(
    undefined,
    l1Search,
    l2Search,
    l3Search,
    undefined,
    true,
    false,
    showUnassignedOnly,
  );

  return (
    <Container fluid className={classNames(css(styles.container), 'py-3')}>
      <Row>
        <Col sm={3} className={css(styles.leftCol)}>
          <FiltersTabView
            l4Count={l4Count}
            l4WithImagesCount={l4WithImagesCount}
            l4CountForProductList={l4CountForProductList}
            l4WithImagesCountForProductList={l4WithImagesCountForProductList}
            l1Search={l1Search}
            l2Search={l2Search}
            l3Search={l3Search}
            setL1Search={setL1Search}
            setL2Search={setL2Search}
            setL3Search={setL3Search}
            selectedL4CategoryName={selectedL4CategoryName}
            setSelectedL4CategoryName={setSelectedL4CategoryName}
            productList={productList}
            setProductList={setProductList}
            user={user}
            setUser={setUser}
            selectedL4CategoryId={selectedL4CategoryId}
            setSelectedL4CategoryId={setSelectedL4CategoryId}
            l1Options={l1Options}
            l2Options={l2Options}
            l3Options={l3Options}
            l4Options={l4Options}
            categoriesLoading={categoriesLoading}
            showUnassignedOnly={showUnassignedOnly}
            setShowUnassignedOnly={setShowUnassignedOnly}
            l4OptionsForUser={l4OptionsForUser}
            loadingl4OptionsForUser={loadingl4OptionsForUser}
            l4OptionsForProductList={l4OptionsForProductList}
            loadingl4OptionsWithProductList={loadingl4OptionsWithProductList}
            activeTabKey={activeTabKey}
            setActiveTabKey={setActiveTabKey}
          />
        </Col>
        <Col sm={9}>
          <L4DefaultImageSection
            selectedL4CategoryId={selectedL4CategoryId}
            setSelectedL4CategoryId={setSelectedL4CategoryId}
            selectedThumbnailUrl={selectedThumbnailUrl}
            setSelectedThumbnailUrl={setSelectedThumbnailUrl}
            selectedL4CategoryName={selectedL4CategoryName}
            setSelectedL4CategoryName={setSelectedL4CategoryName}
            L4Category={data}
            l4CategoryOptions={
              activeTabKey === FILTERS_TAB.PRODUCT_LIST
                ? l4OptionsForProductList
                : activeTabKey === FILTERS_TAB.CATEGORIES
                ? l4Options
                : l4OptionsForUser
            }
            loading={loading}
            refetchCategories={
              activeTabKey === FILTERS_TAB.PRODUCT_LIST
                ? refetchL4OptionsForProductList
                : activeTabKey === FILTERS_TAB.CATEGORIES
                ? refetchCategories
                : refetchL4OptionsForUser
            }
          />
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
  },
  leftCol: {
    paddingLeft: '1.9rem',
    minHeight: '100vh',
    borderRight: '3px solid ' + backgroundGrey4,
  },
});

export default L4DefaultImageView;
export { FILTERS_TAB };
