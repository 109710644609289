import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Tab, Tabs } from 'react-bootstrap';

import ManageProductOutliersTable from 'components/product_anomalies/ManageProductOutliersTable';
import ProductChangesTableView from 'components/product_anomalies/ProductChangesTableView';

const PRODUCT_OUTLIERS_TAB = {
  GLOBAL_PRODUCTS_LINKS_TABLE: 'Global Product Links Table',
  PRODUCT_CHANGES: 'Product Changes',
};

function ManageProductOutliersTabView({
  products,
  selectedList,
  setSelectedList,
  globalProductId,
  setProductEditModalSettings,
}) {
  const [activeTabKey, setActiveTabKey] = useState(
    PRODUCT_OUTLIERS_TAB.GLOBAL_PRODUCTS_LINKS_TABLE,
  );
  return (
    <div>
      <Tabs activeKey={activeTabKey} onSelect={selectedKey => setActiveTabKey(selectedKey)}>
        <Tab
          eventKey={PRODUCT_OUTLIERS_TAB.GLOBAL_PRODUCTS_LINKS_TABLE}
          title={PRODUCT_OUTLIERS_TAB.GLOBAL_PRODUCTS_LINKS_TABLE}
        >
          <div className={css(styles.scrollableContainer)}>
            <ManageProductOutliersTable
              products={products}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              globalProductId={globalProductId}
              setProductEditModalSettings={setProductEditModalSettings}
            />
          </div>
        </Tab>
        <Tab
          eventKey={PRODUCT_OUTLIERS_TAB.PRODUCT_CHANGES}
          title={PRODUCT_OUTLIERS_TAB.PRODUCT_CHANGES}
        >
          <div className={css(styles.scrollableContainer)}>
            <ProductChangesTableView globalProductId={globalProductId} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '60vh',
    overflowY: 'scroll',
  },
  button: {
    fontSize: '1em',
    padding: '0.5em 1em',
    fontWeight: 'bold',
  },
  select: {
    zIndex: 2,
  },
});

ManageProductOutliersTabView.propTypes = {
  products: PropTypes.array,
  selectedList: PropTypes.array,
  setSelectedList: PropTypes.func,
  globalProductId: PropTypes.number,
  setProductEditModalSettings: PropTypes.func,
};

export default ManageProductOutliersTabView;
