import React, { useState } from 'react';

import { Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select/creatable';
import { useDebounce } from 'use-debounce';

import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { SORT_DIRECTIONS } from 'lib/constants';
import {
  SQS_ATTRIBUTE_MESSAGE_COUNT,
  TASKS,
  TASKS_COUNT_BY_STATUS,
} from 'lib/networking/endpoints';
import { TaskStatus, TaskStatusOptions, TaskTypeOptions } from 'lib/enums/TaskEnums';
import TaskTableRow from 'components/settings/team/tasks/TaskTableRow';
import useGet from 'lib/hooks/useGet';

function TasksView() {
  const [selectedTaskStatus, setSelectedTaskStatus] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState('');
  const [dataSourceId, setDataSourceId] = useState('');
  const [searchedDatasourceId] = useDebounce(dataSourceId, 600);

  const {
    data: { data: tasks = [] } = [],
    loading,
    error,
    refetch,
  } = useGet(TASKS, {
    order_by: SORT_DIRECTIONS.DESC,
    limit: 100,
    task_status: selectedTaskStatus,
    task_type: selectedTaskType,
    data_source_id: searchedDatasourceId,
  });

  const { data: { count: msgCount = 0 } = 0 } = useGet(SQS_ATTRIBUTE_MESSAGE_COUNT, {});

  const { data: { count: queuedTaskCount = 0 } = 0 } = useGet(TASKS_COUNT_BY_STATUS, {
    task_status: TaskStatus.QUEUED,
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return null;
  }

  return (
    <Container>
      <div className="d-flex justify-content-between">
        <h3 className="font-weight-bold">Tasks</h3>
      </div>
      <Row className="my-3">
        <Col sm="2">
          <Select
            placeholder="Select Status"
            isClearable
            options={TaskStatusOptions}
            onChange={option => setSelectedTaskStatus(option?.value)}
            value={TaskStatusOptions.find(option => option.value === selectedTaskStatus) || ''}
          />
        </Col>
        <Col sm="3">
          <Select
            placeholder="Select Task Type"
            isClearable
            options={TaskTypeOptions}
            onChange={option => setSelectedTaskType(option?.value)}
            value={TaskTypeOptions.find(option => option.value === selectedTaskType) || ''}
          />
        </Col>
        <Col sm="1">
          <Form.Control
            type="Insert Data Soucre Id"
            onChange={e => setDataSourceId(e.target.value)}
            defaultValue={dataSourceId}
            placeholder="Enter Data Source Id"
          />
        </Col>
        <Col sm="3">
          <Form.Label className="float-right pr-5 pt-2">
            {`SQS Message Count : ${msgCount}`}
          </Form.Label>
        </Col>
        <Col sm="3">
          <Form.Label className="float-right pr-5 pt-2">
            {`Queued Task Count : ${queuedTaskCount}`}
          </Form.Label>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Task Id</th>
            <th>Created time</th>
            <th>Task type</th>
            <th>Data Source Id</th>
            <th>Process Id</th>
            <th>Instance Id</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {tasks
            ? tasks.map(task => <TaskTableRow key={task.id} task={task} refetch={refetch} />)
            : null}
        </tbody>
      </Table>
    </Container>
  );
}

export default TasksView;
