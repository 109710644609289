import React, { useEffect, useState } from 'react';

import { css, StyleSheet } from 'aphrodite';
import classNames from 'classnames';
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import { arraysAreEqual } from 'lib/utils/arrayUtils';
import Container from 'components/shared/Container';
import { DEFAULT_UPB_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import {
  NUTRITIONAL_PANELS_APPROVE_SCRAPED_DATA,
  NUTRITIONAL_PANELS_FOR_VIEW,
  NUTRITIONAL_PANELS_SCRAPED_DETAILS_OF_PRODUCT,
} from 'lib/networking/endpoints';
import NutritionalPanelCatalogView from 'components/nutritional_panels/NutritionalPanelCatalogView';
import { NUTRITIONAL_PANEL_VIEW_TABS } from 'components/nutritional_panels/NutritionalPanelView';
import PaginationComponent from 'components/shared/PaginationComponent';
import RecreatedNutritionalPanelView from 'components/nutritional_panels/RecreatedNutritionalPanelView';
import pointerOnHover from 'lib/css/pointerOnHover';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

const ApprovalStatusOption = [
  { label: 'Approved', value: true },
  { label: 'Not Approved', value: false },
];

function UploadedNutritionalPanelView() {
  const { nutritionalPanelUploadID } = useParams();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_UPB_PAGE_SIZE);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [selectedPanel, setSelectedPanel] = useState();
  const [editPanel, setEditPanel] = useState(false);
  const [nutritionAttributes, setNutritionAttributes] = useState([]);
  const [attributesChanged, setAttributesChanged] = useState(false);
  const [approvalStatus, setaApprovalStatus] = useState(ApprovalStatusOption[1]);

  const { postData: approveScrapedData, loading: approvingScrapedData } = usePost(
    NUTRITIONAL_PANELS_APPROVE_SCRAPED_DATA,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Nutritional panel data approved successfully',
      }).then(() => {
        setEditPanel(false);
        setShowCompareModal(false);
      });
      refetch();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text:
          `An error occurred while approving the nutritional panel ` +
          `data: ${error?.response?.data?.message}`,
      }),
  );

  const { data: { nutritional_facts: nutritionFacts } = {}, loading: panelLoading } = useGet(
    NUTRITIONAL_PANELS_SCRAPED_DETAILS_OF_PRODUCT,
    {
      nutritional_panel_url_id: selectedPanel?.id,
    },
    !selectedPanel,
  );

  useEffect(() => {
    setNutritionAttributes(nutritionFacts);
  }, [nutritionFacts]);

  useEffect(() => {
    if (arraysAreEqual(nutritionAttributes, nutritionFacts)) {
      setAttributesChanged(false);
    } else {
      setAttributesChanged(true);
    }
  }, [nutritionAttributes, nutritionFacts]);

  const {
    data: { nutritional_panels, panel_count = 0 } = {},
    loading,
    refetch,
  } = useGet(NUTRITIONAL_PANELS_FOR_VIEW, {
    ...(nutritionalPanelUploadID && {
      nutritional_panel_upload_id: nutritionalPanelUploadID,
    }),
    skip_panels_without_ocr: true,
    approval_status: approvalStatus.value,
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
  });

  if (loading) {
    return <LoadingSpinner short />;
  }

  return (
    <>
      <Container className={css(styles.container)}>
        {nutritionalPanelUploadID && (
          <div className="mb-3 d-flex justify-content-between">
            <h3 className="font-weight-bold">
              <FontAwesomeIcon
                className={classNames(pointerOnHover, 'mr-2')}
                icon={faArrowCircleLeft}
                onClick={() =>
                  history.push(
                    `/nutritional-panel-upload` +
                      `?tab=${NUTRITIONAL_PANEL_VIEW_TABS.NUTRITIONAL_PANELS_UPLOAD}`,
                  )
                }
              />
              All Nutritional Panel Uploads
            </h3>
          </div>
        )}
        <Row>
          <Col className="w-100 d-flex justify-content-between px-3 py-2">
            <Form.Group className={css(styles.select)}>
              <Form.Label>Approval Status</Form.Label>
              <Select
                value={approvalStatus}
                options={ApprovalStatusOption}
                onChange={option => setaApprovalStatus(option)}
              />
            </Form.Group>
          </Col>
          <Col>
            <div className="float-right px-3 pb-3">
              <p>{panel_count} Uploaded nutritional panels</p>
              <PaginationComponent
                totalItems={panel_count}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                disabled={loading}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <NutritionalPanelCatalogView
            nutritionalPanels={nutritional_panels}
            panelCount={panel_count}
            handleClick={panel => {
              setSelectedPanel(panel);
              setShowCompareModal(true);
            }}
          />
        </Row>
      </Container>
      <Modal
        dialogClassName={css(styles.fullModal)}
        show={showCompareModal}
        onHide={() => {
          setEditPanel(false);
          setShowCompareModal(false);
          setSelectedPanel(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Nutritional Panel Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Col className="d-flex justify-content-end">
              {attributesChanged && !panelLoading && (
                <div>
                  <Button
                    variant="primary"
                    className="mr-2"
                    onClick={() => setNutritionAttributes(nutritionFacts)}
                    disabled={!attributesChanged}
                  >
                    Reset Changes
                  </Button>
                </div>
              )}
              {!selectedPanel?.panel_approval_status && (
                <>
                  <Button
                    variant="primary"
                    className="mr-2"
                    onClick={() => setEditPanel(!editPanel)}
                  >
                    {editPanel ? 'Finish Editing' : 'Edit'}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() =>
                      approveScrapedData({
                        nutritional_panel_url_id: selectedPanel.id,
                        vendor_product_id: selectedPanel.global_product_vendor_id,
                        manufacturer_product_id: selectedPanel.global_product_manufacturer_id,
                        nutritional_panel_attributes: nutritionAttributes,
                      })
                    }
                    disabled={approvingScrapedData}
                  >
                    Approve
                  </Button>
                </>
              )}
            </Col>
          </Row>
          {selectedPanel?.panel_approval_status && (
            <Col>
              <p>
                <strong>Approved by: {selectedPanel.approved_by}</strong>
              </p>
            </Col>
          )}
          <Row>
            <Col>
              <p>
                <strong>Uploaded Image</strong>
              </p>
              <Image
                src={selectedPanel?.panel_url}
                className="d-block"
                alt="Product_Uploaded_Panel"
                fluid
              />
            </Col>
            <Col>
              <RecreatedNutritionalPanelView
                nutritionAttributes={nutritionAttributes}
                setNutritionAttributes={setNutritionAttributes}
                attributesChanged={attributesChanged}
                editPanel={editPanel}
                panelLoading={panelLoading}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  fullModal: {
    width: '50%',
    maxWidth: '50%',
  },
  select: {
    width: '50%',
  },
});

export default UploadedNutritionalPanelView;
