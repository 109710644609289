import React, { useState } from 'react';

import { Button, Col, Row, Table } from 'react-bootstrap';

import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageSchemaRow from 'components/manage_attribute/manage_schema/ManageSchemaRow';
import { SCHEMAS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function ManageSchemaView() {
  const { data: { data = [] } = [], loading, refetch } = useGet(SCHEMAS);
  const [editableRow, setEditableRow] = useState(false);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <h3 className="font-weight-bold mb-5">Manage Schema</h3>
      <Row>
        <Col>
          <Button className="float-right" onClick={() => setEditableRow(true)}>
            Add Schema
          </Button>
        </Col>
      </Row>
      <Table className="mt-4">
        <thead>
          <tr>
            <th>Schema Name</th>
            <th># Attributes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map(schema => (
            <ManageSchemaRow key={schema.id} schema={schema} refetch={refetch} />
          ))}
        </tbody>
        {editableRow ? (
          <ManageSchemaRow
            schema={{}}
            refetch={refetch}
            newRecord={true}
            setEditableRow={setEditableRow}
          />
        ) : null}
      </Table>
    </Container>
  );
}

export default ManageSchemaView;
