// update along with app/lib/enums/product_outliers_change_type.py
const ProductOutlierChangeTypes = {
  VERIFY_PRODUCTS: 'VERIFY_PRODUCTS',
  UNVERIFY_PRODUCTS: 'UNVERIFY_PRODUCTS',
  UNLINK_PRODUCTS: 'UNLINK_PRODUCTS',
  CREATE_NEW_PRODUCT_GROUP: 'CREATE_NEW_PRODUCT_GROUP',
  CONVERT_TO_DISTRIBUTOR_PRODUCTS: 'CONVERT_TO_DISTRIBUTOR_PRODUCTS',
  MANUFACTURER_PRODUCT_MERGE: 'MANUFACTURER_PRODUCT_MERGE',
  DELETE_PRODUCTS: 'DELETE_PRODUCTS',
  PRODUCT_DETAILS_UPDATE: 'PRODUCT_DETAILS_UPDATE',
};

export default ProductOutlierChangeTypes;
