import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import ProductListFilter from 'lib/enums/ProductListFilter';
import { ProductActiveStatusOptions } from 'lib/enums/ProductActiveStatus';

function StatusFilter({ selectedValues, updateFilters }) {
  return (
    <Form.Group>
      <Form.Label>Select Status</Form.Label>
      <Select
        isMulti
        isClearable
        options={ProductActiveStatusOptions}
        value={ProductActiveStatusOptions.filter(obj => selectedValues.includes(obj.value))}
        onChange={opt =>
          updateFilters(
            ProductListFilter.STATUS,
            opt.map(option => option.value),
          )
        }
      />
    </Form.Group>
  );
}

StatusFilter.propTypes = {
  selectedValues: PropTypes.array,
  updateFilters: PropTypes.func,
};

export default StatusFilter;
