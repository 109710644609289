import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { faArrowRight, faTrashAlt, faClone, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormCheck, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';

import AttributeValueMappingModal from 'components/data_sources/single_data_source_view/AttributeValueMappingModal';
import { areYouSure } from 'lib/utils/toast';
import { blue, green, salmon } from 'lib/css/colors';
import isAdvancedMapping from 'lib/utils/isAdvancedMapping';
import pointerOnHover from 'lib/css/pointerOnHover';

function DataSourceAttributesTableRow({
  sourceAttribute,
  sortedUnsavedAttributeMap,
  setUnsavedAttributeMap,
  unmappedAttributes,
  setUnmappedAttributes,
  attributeOptions,
  dataSource,
  refetch,
}) {
  const [selectedOption, setSelectedOption] = useState(
    unmappedAttributes.includes(sourceAttribute)
      ? null
      : attributeOptions.find(
          option => option.value === sortedUnsavedAttributeMap[sourceAttribute],
        ),
  );
  const [showAttributeValueMappingModal, setShowAttributeValueMappingModal] = useState(false);
  const handleClone = sourceAttribute => {
    const newUnsavedAttributeMap = { ...sortedUnsavedAttributeMap };
    // get the number after the last copy number in the sourceAttribute
    const copyCount = parseInt(sourceAttribute.match(/_copy(\d+)$/)?.[1] || 0, 10);
    if (copyCount === 0) {
      newUnsavedAttributeMap[`${sourceAttribute}_copy1`] = newUnsavedAttributeMap[sourceAttribute];
      setUnsavedAttributeMap(newUnsavedAttributeMap);
      return;
    }
    const lastCopyNumber = copyCount;
    const newSourceAttribute = sourceAttribute.replace(
      `_copy${lastCopyNumber}`,
      `_copy${lastCopyNumber + 1}`,
    );
    newUnsavedAttributeMap[newSourceAttribute] = newUnsavedAttributeMap[sourceAttribute];
    setUnsavedAttributeMap(newUnsavedAttributeMap);
  };

  return (
    <tr>
      <td className={isAdvancedMapping(sourceAttribute) ? 'font-weight-bold' : ''}>
        {sourceAttribute}

        <span className="ml-3">
          <OverlayTrigger overlay={<Tooltip>Clone</Tooltip>}>
            <FontAwesomeIcon
              className={pointerOnHover}
              icon={faClone}
              onClick={() => handleClone(sourceAttribute)}
            />
          </OverlayTrigger>
        </span>
        <span className="ml-3">
          <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
            <FontAwesomeIcon
              className={pointerOnHover}
              icon={faTrashAlt}
              onClick={() => {
                const newUnsavedAttributeMap = { ...sortedUnsavedAttributeMap };
                delete newUnsavedAttributeMap[sourceAttribute];
                setUnsavedAttributeMap(newUnsavedAttributeMap);
              }}
            />
          </OverlayTrigger>
        </span>
        <span className="ml-3">
          <OverlayTrigger
            overlay={
              <Tooltip>
                {unmappedAttributes.includes(sourceAttribute)
                  ? 'Remove from Unknown Attributes'
                  : 'Mark as Unknown Attribute to Map'}
              </Tooltip>
            }
          >
            <FormCheck
              inline
              type="checkbox"
              checked={unmappedAttributes.includes(sourceAttribute)}
              onChange={() => {
                const newUnmappedAttributes = [...unmappedAttributes];
                if (unmappedAttributes.includes(sourceAttribute)) {
                  newUnmappedAttributes.splice(unmappedAttributes.indexOf(sourceAttribute), 1);
                } else {
                  newUnmappedAttributes.push(sourceAttribute);
                  // Clear the mapping for this attribute
                  const newUnsavedAttributeMap = { ...sortedUnsavedAttributeMap };
                  newUnsavedAttributeMap[sourceAttribute] = null;
                  setUnsavedAttributeMap(newUnsavedAttributeMap);
                }
                setUnmappedAttributes(newUnmappedAttributes);
              }}
            />
          </OverlayTrigger>
        </span>
        {dataSource?.column_value_map && sourceAttribute in dataSource?.column_value_map ? (
          <span>
            <OverlayTrigger overlay={<Tooltip>Map Source Attribute Values</Tooltip>}>
              <FontAwesomeIcon
                className={pointerOnHover}
                icon={faList}
                isDisabled
                onClick={() => setShowAttributeValueMappingModal(true)}
                style={{
                  color: Object.values(dataSource?.column_value_map[sourceAttribute]).every(
                    value => value === null,
                  )
                    ? salmon
                    : Object.values(dataSource?.column_value_map[sourceAttribute]).some(
                        value => value === null,
                      )
                    ? green
                    : blue,
                }}
              />
            </OverlayTrigger>
          </span>
        ) : null}
      </td>
      <td>
        <FontAwesomeIcon icon={faArrowRight} />
      </td>
      <td>
        <Select
          isClearable
          isDisabled={unmappedAttributes.includes(sourceAttribute)}
          options={attributeOptions}
          value={
            unmappedAttributes.includes(sourceAttribute)
              ? null
              : attributeOptions.find(
                  option => option.value === sortedUnsavedAttributeMap[sourceAttribute],
                )
          }
          onChange={option => {
            if (Object.values(sortedUnsavedAttributeMap).includes(option?.value)) {
              areYouSure(
                () =>
                  setUnsavedAttributeMap({
                    ...sortedUnsavedAttributeMap,
                    [sourceAttribute]: option?.value || null,
                  }),
                'This FSA attribute is already mapped',
              );
            } else {
              setUnsavedAttributeMap({
                ...sortedUnsavedAttributeMap,
                [sourceAttribute]: option?.value || null,
              });
              setSelectedOption(option);
            }
          }}
        />
      </td>
      {showAttributeValueMappingModal && (
        <AttributeValueMappingModal
          onHide={() => setShowAttributeValueMappingModal(false)}
          selectedOption={selectedOption}
          sourceAttribute={sourceAttribute}
          dataSource={dataSource}
          refetch={refetch}
        />
      )}
    </tr>
  );
}

DataSourceAttributesTableRow.propTypes = {
  sourceAttribute: PropTypes.string.isRequired,
  sortedUnsavedAttributeMap: PropTypes.object.isRequired,
  setUnsavedAttributeMap: PropTypes.func.isRequired,
  unmappedAttributes: PropTypes.array.isRequired,
  setUnmappedAttributes: PropTypes.func.isRequired,
  attributeOptions: PropTypes.array.isRequired,
  isAdvancedMapping: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  refetch: PropTypes.func,
};

export default DataSourceAttributesTableRow;
