import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select/creatable';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';
import { useHistory } from 'react-router-dom';

import { anchovy, grape } from 'lib/css/colors';
import { GLOBAL_VENDORS_CREATE } from 'lib/networking/endpoints';
import { P0SyncAttributeOptions } from 'lib/enums/P0Attributes';
import usePost from 'lib/hooks/usePost';

const vendorRows = {
  name: 'Vendor Name',
  cnd_id: 'Cut and Dry ID',
  excluded_p0_sync_attributes: 'Attrributes excluded from P0 sync',
  p0_sync_enabled: 'P0 sync is enabled',
  is_proprietary_images: 'Is proprietary images',
  cnd_image_sync_enabled: 'Cut and Dry image sync enabled',
  is_dp_partner: 'Is a distributor portal partner',
  is_test_vendor: 'Is Test Vendor',
};

function VendorCreateModal({ onHide }) {
  const [selectedOptions, setSelectedOptions] = useState();
  const [newVendor, setNewVendor] = useState({});
  const history = useHistory();

  const { postData: createVendor, loading } = usePost(
    GLOBAL_VENDORS_CREATE,
    response => {
      const vendor = response.data.vendor;
      Swal.fire({ title: 'Vendor created successfully.', icon: 'success' }).then(() => {
        onHide();
        history.push('/enrich-products/vendor-details/' + vendor.id);
      });
    },
    error =>
      Swal.fire({
        title: 'Error',
        text:
          error?.response?.data?.message ||
          'Error occured while creating vendor, please try again.',
        icon: 'error',
      }),
  );

  const handleNewVendor = (property, value) => {
    setNewVendor(prevVendor => ({
      ...prevVendor,
      [property]: value,
    }));
  };

  const handleSelectChange = (property, selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const selectedValuesString = selectedOptions.map(option => option.value).join(',');
    handleNewVendor(property, selectedValuesString);
  };

  return (
    <Modal show={true} onHide={onHide} size="lg" className="px-6" centered>
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="font-weight-bold">Create New Vendor</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="px-3 mt-3">
          {Object.keys(vendorRows).map(key => (
            <div key={key} className="pb-4">
              <p className={css(styles.label)}>{vendorRows[key]}</p>
              {['name', 'cnd_id', 'excluded_p0_sync_attributes'].includes(key) ? (
                key === 'excluded_p0_sync_attributes' ? (
                  <Select
                    className={css(styles.select)}
                    options={P0SyncAttributeOptions}
                    isMulti
                    value={selectedOptions}
                    onChange={event => handleSelectChange(key, event)}
                  />
                ) : (
                  <Form.Control
                    className={css(styles.form)}
                    defaultValue={null}
                    onChange={event => handleNewVendor(key, event.target.value)}
                  />
                )
              ) : (
                <div>
                  <ToggleSwitch
                    onColor={grape}
                    checked={key in newVendor ? newVendor[key] : false}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => handleNewVendor(key, !newVendor[key])}
                  />
                </div>
              )}
            </div>
          ))}
          <Row className="pt-3 d-flex justify-content-between">
            <Col sm={3}>
              <Button
                disabled={newVendor?.length < 1 || loading}
                block
                variant="primary"
                onClick={() => createVendor(newVendor)}
              >
                {loading ? <Spinner animation="border" role="status" size="sm" /> : 'Create Vendor'}
              </Button>
            </Col>
            <Col sm={2}>
              <Button variant="outline-primary" onClick={() => onHide()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const styles = StyleSheet.create({
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  select: {
    flexGrow: 0.96,
  },
  form: {
    height: 'auto',
  },
});

VendorCreateModal.propTypes = {
  onHide: PropTypes.func,
};

export default VendorCreateModal;
