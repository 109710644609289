import React from 'react';
import PropTypes from 'prop-types';

import { css } from 'aphrodite';
import { Table } from 'react-bootstrap';

import AttributeChangeRow from 'components/product_updates/product_update_details/AttributeChangeRow';
import { backgroundColorStyles } from 'lib/css/colors';

function AttributeChangesSection({ attributeChanges }) {
  return (
    <div>
      <h4 className="font-weight-bold mb-3">Attribute Changes</h4>
      <Table hover>
        <thead className={css(backgroundColorStyles.backgroundGrey)}>
          <tr>
            <th>Attribute</th>
            <th>Previous Value</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
          {attributeChanges.map(attributeChange => (
            <AttributeChangeRow key={attributeChange.id} attributeChange={attributeChange} />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

AttributeChangesSection.propTypes = {
  attributeChanges: PropTypes.array,
};

export default AttributeChangesSection;
