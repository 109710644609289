import { useCallback, useState } from 'react';

import { getURL } from 'lib/networking/endpoints';
import { httpDelete } from 'lib/networking/http';

function useDelete(url, skip = false, successCallback, errorCallback) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const deleteData = useCallback(
    (dict = null) => {
      if (skip || !dict) {
        return;
      }
      setLoading(true);
      httpDelete(getURL(url, dict))
        .then(res => {
          successCallback && successCallback(res);
        })
        .catch(error => {
          setError(error);
          errorCallback && errorCallback(error);
        })
        .finally(() => setLoading(false));
    },
    [skip, errorCallback, successCallback, url],
  );
  return { deleteData, loading, error };
}

export default useDelete;
