import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

function useQueryString() {
  const location = useLocation();
  return useMemo(() => new global.URLSearchParams(location.search), [location.search]);
}

export default useQueryString;
