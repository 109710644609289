import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { TAXONOMY_GET_L3_CATEGORIES_BY_L4_IMAGE_UPLOAD_STATUS } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function CategoryFilter({ l4ImageStatus, selectedL3CategoryId, setSelectedL3CategoryId }) {
  const {
    data: { data: categories } = {},
    loading,
    error,
  } = useGet(TAXONOMY_GET_L3_CATEGORIES_BY_L4_IMAGE_UPLOAD_STATUS, { status: l4ImageStatus });

  const categoryOptions = useMemo(() => {
    if (!categories?.length) {
      return [];
    }
    return categories.map(category => ({
      label: category.category_name,
      value: category.id,
    }));
  }, [categories]);

  useEffect(() => {
    if (!selectedL3CategoryId && categoryOptions.length > 0) {
      setSelectedL3CategoryId(categoryOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryOptions, selectedL3CategoryId]);

  if (error) {
    return null;
  }

  return (
    <Form.Group>
      <Form.Label>L3</Form.Label>
      <Select
        isClearable
        isDisabled={loading}
        options={categoryOptions}
        value={categoryOptions.find(op => op.value === selectedL3CategoryId)}
        onChange={option => setSelectedL3CategoryId(option?.value)}
        placeholder={loading ? 'Loading...' : ''}
      />
    </Form.Group>
  );
}

CategoryFilter.propTypes = {
  l4ImageStatus: PropTypes.string,
  selectedL3CategoryId: PropTypes.number,
  setSelectedL3CategoryId: PropTypes.func,
};

export default CategoryFilter;
