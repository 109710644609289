import React from 'react';
import PropTypes from 'prop-types';

import { css } from 'aphrodite';
import { Table } from 'react-bootstrap';

import { backgroundColorStyles } from 'lib/css/colors';
import ChangeLogRow from 'components/product_updates/product_update_details/ChangeLogRow';

function ChangeLogsSection({ changeLogs }) {
  const firstLog = changeLogs[0];
  return (
    <div>
      <h4 className="font-weight-bold mb-3">Log</h4>
      <Table hover>
        <thead className={css(backgroundColorStyles.backgroundGrey)}>
          <tr>
            <th>Timestamp</th>
            <th>Source</th>
            <th>Status</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          <ChangeLogRow changeLog={firstLog} />
        </tbody>
      </Table>
    </div>
  );
}

ChangeLogsSection.propTypes = {
  changeLogs: PropTypes.array,
};

export default ChangeLogsSection;
