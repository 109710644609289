import React, { useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BRANDS_FOR_HARVEST } from 'lib/networking/endpoints';
import BrandHarvestGroupedView from 'components/manage_brands/harvest_brands/BrandHarvestGroupedView';
import {
  BrandHarvestingPendingOption,
  BrandHarvestingStatusOptions,
} from 'lib/enums/BrandHarvestingEnums';
import BrandHarvestingEditModal from 'components/manage_brands/harvest_brands/BrandHarvestingEditModal';
import BrandHarvestingFilters from 'components/manage_brands/harvest_brands/BrandHarvestingFilters';
import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import BrandHarvestTableView from 'components/manage_brands/harvest_brands/BrandHarvestTableView';
import Container from 'components/shared/Container';
import Cookies from 'lib/enums/Cookies';
import { HUNDRED_PAGE_SIZE } from 'lib/constants';
import GenerateActivityReportModal from 'components/manage_brands/harvest_brands/GenerateActivityReportModal';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationComponent from 'components/shared/PaginationComponent';
import useLocalStorage from 'lib/hooks/useLocalStorage';
import useGet from 'lib/hooks/useGet';
import useQueryString from 'lib/hooks/useQueryString';

function BrandsHarvestView() {
  const [brandCount, setBrandCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(HUNDRED_PAGE_SIZE);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showBrandHarvestingEditModal, setShowBrandHarvestingEditModal] = useState(false);
  const [showGenerateBrandActivityReportModal, setShowGenerateBrandActivityReportModal] =
    useState(false);
  const harvestStatus = useQueryString().get('harvestStatus');

  const [cachedBrandHarvestFilters, setCachedBrandHarvestFilters] = useLocalStorage(
    Cookies.BRAND_HARVEST_FILTERS,
    {},
  );
  const [selectedOptions, setSelectedOptions] = useState({
    selectedStatus:
      BrandHarvestingStatusOptions.find(op => op.value === harvestStatus) ||
      cachedBrandHarvestFilters.selectedStatus ||
      null,
    mpn: cachedBrandHarvestFilters.mpn || null,
    gtin: cachedBrandHarvestFilters.gtin || null,
    dataQuality: cachedBrandHarvestFilters.dataQuality || null,
    selectedDistributor: cachedBrandHarvestFilters.selectedDistributor || {
      label: null,
      value: null,
    },
    searchQuery: null,
  });

  // this query will be used to render `BrandHarvestTableView`
  const { data: { brands = [], count = 0 } = {}, loading } = useGet(
    BRANDS_FOR_HARVEST,
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      is_dp_only: false,
      brand_harvest_status: selectedOptions.selectedStatus?.value || null,
      manufacturer_product_number_exists: selectedOptions.mpn?.value,
      gtin_exists: selectedOptions.gtin?.value,
      data_quality: selectedOptions.dataQuality?.value || null,
      distributor_id: selectedOptions.selectedDistributor?.value || null,
      searchQuery: selectedOptions.searchQuery || null,
      status: BrandVerificationStatus.VERIFIED,
      for_harvest: true,
    },
    ![BrandHarvestingPendingOption, null].includes(selectedOptions.selectedStatus?.value || null),
  );
  const _brandCount = [BrandHarvestingPendingOption, null].includes(
    selectedOptions.selectedStatus?.value || null,
  )
    ? count
    : brandCount;
  return (
    <Container className={css(styles.container)}>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Harvest Brand Data</h3>
        <Button
          variant="outline-primary"
          onClick={() => setShowGenerateBrandActivityReportModal(true)}
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" />
          Generate Activity Report
        </Button>
        {showGenerateBrandActivityReportModal && (
          <GenerateActivityReportModal
            onHide={() => setShowGenerateBrandActivityReportModal(false)}
          />
        )}
      </div>
      <BrandHarvestingFilters
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        setShowBrandHarvestingEditModal={setShowBrandHarvestingEditModal}
        selectedIds={selectedIds}
        setCachedBrandHarvestFilters={setCachedBrandHarvestFilters}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row>
            <Col className="d-flex justify-content-start mt-3 ml-1">
              <span>{`Brand Count - ${_brandCount}`}</span>
            </Col>
            <Col className={classNames(css(styles.pagination), 'd-flex justify-content-end')}>
              <PaginationComponent
                totalItems={_brandCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                defaultSize
              />
            </Col>
          </Row>
          {!selectedOptions.selectedStatus ||
          selectedOptions.selectedStatus?.value === BrandHarvestingPendingOption ? (
            <BrandHarvestTableView
              brands={brands}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          ) : (
            <BrandHarvestGroupedView
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              selectedOptions={selectedOptions}
              setBrandCount={setBrandCount}
            />
          )}
          {showBrandHarvestingEditModal && (
            <BrandHarvestingEditModal
              onHide={() => setShowBrandHarvestingEditModal(false)}
              isMultiBrandEdit={true}
              selectedIds={selectedIds}
            />
          )}
        </>
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  pagination: {
    marginTop: '1rem',
    zIndex: 0,
  },
  container: {
    minHeight: '100vh',
  },
});

export default BrandsHarvestView;
