import { useCallback, useEffect, useState } from 'react';

import { httpGet } from 'lib/networking/http';
import { getURLWithSearchParams } from 'lib/networking/endpoints';

function useGet(url, params = {}, skip = false, lazy = false) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  // not using params in axios to avoid render loop
  const urlWithParams = getURLWithSearchParams(url, params);

  const getData = useCallback(
    callback => {
      if (skip) {
        return;
      }
      setLoading(true);
      httpGet(urlWithParams)
        .then(res => {
          if (res.status === 200) {
            setData(res.data);
          }
          if (callback) {
            callback(res);
          }
        })
        .catch(error => setError(error))
        .finally(() => {
          setLoading(false);
          setIsLoaded(true);
        });
    },
    [skip, urlWithParams],
  );

  useEffect(() => {
    if (!lazy) {
      getData();
    }
  }, [getData, lazy]);

  return { data, loading, error, refetch: getData, isLoaded };
}

export default useGet;
