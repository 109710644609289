import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faPen, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { anchovy, green, iconGrey, blue, categoryGreen, salmon } from 'lib/css/colors';
import EditableProperty from 'components/manage_brands/brand_details/EditableProperty';
import { faSquareCheck } from 'images/icons/fsa-monotone-svg-icons';
import pointerOnHover from 'lib/css/pointerOnHover';
import { SimilarBrandNamesStatus } from 'lib/enums/SimilarBrandNamesStatusEnums';
function BrandRow({ label, title, brand, updateBrand, refetch }) {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedBrand, setUpdatedBrand] = useState({ id: brand.id });

  function getTooltipText(status) {
    switch (status) {
      case SimilarBrandNamesStatus.FIRST_YES:
        return 'Approved once';
      case SimilarBrandNamesStatus.SECOND_YES:
        return 'Fully approved';
      case SimilarBrandNamesStatus.FIRST_NO:
        return 'Rejected once';
      case SimilarBrandNamesStatus.TIED:
        return 'Awaiting third decision';
      default:
        return 'Not reviewed yet';
    }
  }

  return (
    <div>
      <p className={css(styles.label)}>{title}</p>
      {isEditing ? (
        <Form
          onBlur={() => {
            if (label !== 'similar_brands' && label !== 'video_urls') {
              setIsEditing(false);
            }
          }}
          className={label === 'similar_brands' ? css(styles.rowNotEditable) : css(styles.row)}
        >
          {label !== 'similar_brands' && label !== 'video_urls' ? (
            <FontAwesomeIcon
              className={classNames(pointerOnHover, css(styles.editIcon))}
              icon={faSquareCheck}
              onMouseDown={() => {
                updateBrand(updatedBrand);
                setIsEditing(false);
              }}
              size="lg"
            />
          ) : null}
          <EditableProperty
            label={label}
            brand={brand}
            setIsEditing={setIsEditing}
            updatedBrand={updatedBrand}
            setUpdatedBrand={setUpdatedBrand}
            updateBrand={updateBrand}
            refetch={refetch}
          />
        </Form>
      ) : (
        <div className={css(styles.row)}>
          <FontAwesomeIcon
            className={classNames(pointerOnHover, css(styles.editIcon))}
            icon={faPen}
            onClick={() => setIsEditing(true)}
          />
          {label === 'description' ? (
            <div>
              {brand[label] ? (
                brand[label].split('\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)
              ) : (
                <p>-</p>
              )}
            </div>
          ) : label === 'similar_brands' ? (
            <div>
              {brand[label].length ? (
                brand[label].map(similarBrand => (
                  <p key={similarBrand.id}>
                    &bull; {similarBrand.brand_name}
                    <OverlayTrigger
                      overlay={<Tooltip>{getTooltipText(similarBrand.status)}</Tooltip>}
                    >
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="ml-2"
                        style={{
                          color:
                            similarBrand.status === SimilarBrandNamesStatus.FIRST_YES
                              ? categoryGreen
                              : similarBrand.status === SimilarBrandNamesStatus.SECOND_YES
                              ? green
                              : similarBrand.status === SimilarBrandNamesStatus.FIRST_NO
                              ? salmon
                              : similarBrand.status === SimilarBrandNamesStatus.TIED
                              ? blue
                              : iconGrey,
                        }}
                      />
                    </OverlayTrigger>
                  </p>
                ))
              ) : (
                <p>-</p>
              )}
            </div>
          ) : label === 'video_urls' ? (
            <div>
              {brand[label]?.length ? (
                brand[label].map(video_url => <p key={video_url.id}>&bull; {video_url}</p>)
              ) : (
                <p>-</p>
              )}
            </div>
          ) : (
            <p>
              {label === 'manufacturer_id'
                ? brand['global_manufacturer']
                  ? brand['global_manufacturer']['name'] || '-'
                  : '-'
                : brand[label] || '-'}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  label: {
    display: 'inline',
    fontWeight: 'bold',
    color: anchovy,
  },
  row: {
    display: 'flex',
    marginLeft: '-1.65rem',
  },
  rowNotEditable: {
    display: 'flex',
  },
  editIcon: {
    marginRight: '0.75rem',
  },
});

BrandRow.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  brand: PropTypes.object,
  updateBrand: PropTypes.func,
  refetch: PropTypes.func,
};

export default BrandRow;
