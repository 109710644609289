import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from 'react-bootstrap';
import ToggleSwitch from 'react-switch';

import { grape } from 'lib/css/colors';
import ImageCategorySelector from 'components/image_tagger/ImageCategorySelector';
import ImageFacingSelector from 'components/image_tagger/ImageFacingSelector';
import ProductListSelector from 'components/shared/ProductListSelector';

function ImageFilterPanel({
  selectedImageCategory,
  setSelectedImageCategory,
  selectedImageFacing,
  setSelectedImageFacing,
  selectedProductListId,
  setSelectedProductListId,
  showOnlyUntagged,
  setShowOnlyUntagged,
  showAIMLSuggestions,
  setShowAIMLSuggestions,
}) {
  return (
    <div className="pr-1">
      <h5 className="font-weight-bold">Filter</h5>
      <Row>
        <Col>
          <ProductListSelector
            selectedProductListId={selectedProductListId}
            setSelectedProductListId={setSelectedProductListId}
          />
        </Col>
        <Col>
          <ImageCategorySelector
            selectedImageCategory={selectedImageCategory}
            setSelectedImageCategory={setSelectedImageCategory}
          />
        </Col>
        <Col>
          <ImageFacingSelector
            selectedImageFacing={selectedImageFacing}
            setSelectedImageFacing={setSelectedImageFacing}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div aria-label="untagged-toggle" className="d-flex align-items-center">
            <ToggleSwitch
              id="untagged-toggle"
              className="mr-2"
              onColor={grape}
              checked={showOnlyUntagged}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={e => setShowOnlyUntagged(e)}
              disabled={showAIMLSuggestions}
            />
            <span>Show Untagged Only</span>
          </div>
        </Col>
        <Col>
          <div aria-label="untagged-toggle" className="d-flex align-items-center">
            <ToggleSwitch
              id="untagged-toggle"
              className="mr-2"
              onColor={grape}
              checked={showAIMLSuggestions}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={e => setShowAIMLSuggestions(e)}
              disabled={!showOnlyUntagged}
            />
            <span>Show AI-ML Suggestions</span>
          </div>
        </Col>
      </Row>
    </div>
  );
}

ImageFilterPanel.propTypes = {
  selectedImageCategory: PropTypes.object,
  setSelectedImageCategory: PropTypes.func,
  selectedImageFacing: PropTypes.string,
  setSelectedImageFacing: PropTypes.func,
  selectedProductListId: PropTypes.number,
  setSelectedProductListId: PropTypes.func,
  showOnlyUntagged: PropTypes.bool,
  setShowOnlyUntagged: PropTypes.func,
  showAIMLSuggestions: PropTypes.bool,
  setShowAIMLSuggestions: PropTypes.func,
};

export default ImageFilterPanel;
