function getStartOfDayTimestamp(date) {
  if (!date || !(date instanceof Date)) {
    return null;
  }
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date.getTime() / 1000;
}

function getEndOfDayTimestamp(date) {
  if (!date || !(date instanceof Date)) {
    return null;
  }
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date.getTime() / 1000;
}

export { getStartOfDayTimestamp, getEndOfDayTimestamp };
