import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { css, StyleSheet } from 'aphrodite';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { backgroundGrey } from 'lib/css/colors';
import { BrandVerificationStatus } from 'lib/enums/BrandVerificationStatus';
import Container from 'components/shared/Container';
import PaginationComponent from 'components/shared/PaginationComponent';
import pointerOnHover from 'lib/css/pointerOnHover';
import { DATE_FORMAT_WITH_TIME } from 'lib/constants';

function ManufacturerBrandsTable({
  brands,
  brandCount,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
}) {
  const history = useHistory();

  return (
    <Container>
      <div className="my-3 d-flex justify-content-end">
        <PaginationComponent
          totalItems={brandCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          defaultSize
        />
      </div>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>Brand Name</th>
            <th>Status</th>
            <th>Last Modified</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {brands?.length ? (
            brands.map((brand, index) => (
              <tr
                key={index}
                className={pointerOnHover}
                onClick={e => {
                  history.push(`/enrich-products/brand-details/${brand.id}`);
                }}
              >
                <td>{brand.name}</td>
                <td>
                  {brand.verification_status === BrandVerificationStatus.VERIFIED
                    ? 'Verified'
                    : brand.archived
                    ? 'Archived'
                    : null}
                </td>
                <td>{dayjs(brand.date_modified).format(DATE_FORMAT_WITH_TIME)}</td>
                <td>{dayjs(brand.date_created).format(DATE_FORMAT_WITH_TIME)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No brands found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky-top',
    zIndex: 1,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

ManufacturerBrandsTable.propTypes = {
  brands: PropTypes.array,
  brandCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};

export default ManufacturerBrandsTable;
