import React from 'react';
import PropTypes from 'prop-types';

import { BrandHarvestingStatus } from 'lib/enums/BrandHarvestingEnums';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import DashboardWidget from 'components/dashboard/DashboardWidget';

function BrandHarvestingWidgetView({
  activeBrandHarvestingWidgets,
  harvestDashboardData,
  loading,
  dateDifference,
}) {
  function getLinkToOpen(status) {
    const url = `/enrich-products/brand-harvest?harvestStatus=${status}`;
    window.open(url, '_blank');
  }
  return (
    <div>
      <div>
        <h3 className={css(styles.mainHeader)}>Brand Harvesting</h3>
      </div>
      <div className={css(styles.gridContainer)}>
        {activeBrandHarvestingWidgets.map(widgetType => {
          const { current_total_count = 0, percentage_change = 0 } =
            harvestDashboardData[widgetType] || {};
          return (
            <div key={widgetType} className={css(styles.widgetContainer)}>
              <div className={classNames('mb-3', css(styles.widgetInnerContainer))}>
                <DashboardWidget
                  label={BrandHarvestingStatus[widgetType]}
                  currentTotalCount={current_total_count}
                  percentageChange={percentage_change}
                  loading={loading}
                  dateDifference={dateDifference}
                  onClick={() => getLinkToOpen(widgetType)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(340px, 1fr))',
    gap: '20px',
  },
  widgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  widgetInnerContainer: {
    minWidth: 340,
    minHeight: 30,
  },
  mainHeader: {
    margin: 30,
  },
});

BrandHarvestingWidgetView.propTypes = {
  activeBrandHarvestingWidgets: PropTypes.array,
  harvestDashboardData: PropTypes.object,
  loading: PropTypes.bool,
  dateDifference: PropTypes.number,
};

export default BrandHarvestingWidgetView;
