// update along with app/lib/enums/ocr_status.py
const OCRStatus = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  DATA_IMPORTED: 'DATA_IMPORTED',
};

const OCRStatusLabels = {
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  DATA_IMPORTED: 'Data Imported',
};

export { OCRStatus, OCRStatusLabels };
