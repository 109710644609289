import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { ATTRIBUTES } from 'lib/networking/endpoints';
import useGet from 'lib/hooks/useGet';

function ImageCategorySelector({ selectedImageCategory, setSelectedImageCategory, disabled }) {
  const { data: { attributes = [] } = {}, loading } = useGet(ATTRIBUTES, {
    attribute_section_id: 1,
  });

  const categoryOptions = useMemo(() => {
    return attributes.map(attribute => ({
      label: attribute.attribute_display_name || attribute.attribute_name,
      value: attribute.id,
    }));
  }, [attributes]);

  return (
    <Form.Group>
      <Form.Label>Image State</Form.Label>
      <Select
        isDisabled={loading || disabled}
        isClearable
        isLoading={loading}
        value={selectedImageCategory}
        options={categoryOptions}
        onChange={option => setSelectedImageCategory(option || null)}
        placeholder={loading ? 'Loading...' : 'Select State ...'}
      />
    </Form.Group>
  );
}

ImageCategorySelector.propTypes = {
  selectedImageCategory: PropTypes.object,
  setSelectedImageCategory: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ImageCategorySelector;
