import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, FormControl } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ATTRIBUTE_VALUE_MAP } from 'lib/networking/endpoints';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

function ManageAttributeValueMapTableRow({ valueMapRecord, isNewRow, setNewRow, refetch }) {
  const [edit, setEdit] = useState(isNewRow ? true : false);
  const [row, setRow] = useState({
    attributeValue: valueMapRecord.attribute_value,
    alternatives: valueMapRecord.alternative_values,
  });

  const { postData: createNew } = usePost(
    ATTRIBUTE_VALUE_MAP,
    () => {
      SwalDialog('success', 'Successfully created.', 'Success', 'center');
      refetch();
      if (isNewRow) {
        setNewRow(false);
      }
    },
    () => {
      SwalDialog('error', 'An error occurred.', 'Error', 'center');
    },
  );

  const handleAttributeValueChange = e => {
    const newValue = e.target.value;
    setRow({
      ...row,
      attributeValue: newValue,
    });
  };

  const handleAttributeValueAlternativeChange = e => {
    const newValue = e.target.value;
    setRow({
      ...row,
      alternatives: newValue,
    });
  };

  return (
    <tr className={pointerOnHover}>
      <td width="10">
        {!edit ? (
          <FontAwesomeIcon icon={faEdit} className="mt-2" onClick={() => setEdit(!edit)} />
        ) : null}
      </td>
      <td width="300">
        <FormControl
          disabled={!edit}
          autoFocus
          value={row.attributeValue}
          onChange={handleAttributeValueChange}
          className={css(styles.otherColumn)}
        />
      </td>
      <td width="100" className="pt-3 pl-4">
        {'=>'}
      </td>
      <td width="300">
        <FormControl
          disabled={!edit}
          autoFocus
          value={row.alternatives}
          onChange={handleAttributeValueAlternativeChange}
        />
      </td>
      <td width="50">
        {edit ? (
          <div className="align-items-left">
            <Button
              className="ml-1"
              onClick={() => {
                createNew({
                  attribute_value: row.attributeValue,
                  attribute_value_alternatives: row.alternatives,
                  attribute_value_map_id: valueMapRecord.id || null,
                });
                setEdit(false);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              className="ml-1"
              variant="outline-primary"
              onClick={() => {
                ({
                  attributeValue: valueMapRecord.attribute_value,
                  alternatives: valueMapRecord.alternative_values,
                });
                setEdit(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        ) : null}
      </td>
    </tr>
  );
}

ManageAttributeValueMapTableRow.propTypes = {
  valueMapRecord: PropTypes.object,
  isNewRow: PropTypes.bool,
  setNewRow: PropTypes.func,
  refetch: PropTypes.func,
};

const styles = StyleSheet.create({
  otherColumn: {
    maxWidth: 300,
  },
});

export default ManageAttributeValueMapTableRow;
