import React, { useEffect, useMemo, useState } from 'react';

import { Breadcrumb, Col, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { toSafeInteger } from 'lodash';
import { useDebounce } from 'use-debounce';
import { useHistory, useParams } from 'react-router-dom';

import { backgroundGrey5 } from 'lib/css/colors';
import CatalogSidePanel from 'components/upb_viewer/CatalogSidePanel';
import Container from 'components/shared/Container';
import { DEFAULT_UPB_PAGE_SIZE } from 'lib/constants';
import {
  ANNUALIZED_GMV,
  getURL,
  GLOBAL_PRODUCTS_BY_SEARCH,
  TOTAL_ANNUALIZED_GMV,
  TAXONOMY_CHILD_CATEGORY_DETAILS,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PaginationBar from 'components/shared/PaginationBar';
import SearchBar from 'components/shared/SearchBar';
import TaxonomyLevel from 'lib/enums/TaxonomyLevel';
import UPBCatalogView from 'components/upb_viewer/UPBCatalogView';
import UPBCategoryView from 'components/upb_viewer/UPBCategoryView';
import useGet from 'lib/hooks/useGet';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

const DEFAULT_ALL_CATEGORY_OPTION = { label: 'All', value: null };

function getCategoryIDFromUrl(category) {
  return decodeURIComponent(category)?.split('__')[1];
}

function getCategoryNameFromUrl(category) {
  return decodeURIComponent(category)?.split('__')[0];
}

function constructUrlPathSegment(category) {
  if (!category?.value || !category?.label) return null;
  return encodeURIComponent(`${category.label}__${category.value}`);
}

function getCategoryLevel(l1_category, l2_category, l3_category, l4_category) {
  if (l4_category) return TaxonomyLevel.L4;
  else if (l3_category) return TaxonomyLevel.L3;
  else if (l2_category) return TaxonomyLevel.L2;
  else if (l1_category) return TaxonomyLevel.L1;
}

function UPBViewer() {
  const history = useHistory();
  const hasUserPermissions = useUserPermissions();

  const { l1_category, l2_category, l3_category, l4_category } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedL1CategoryId, setSelectedL1CategoryId] = useState({
    l1CategoryId: getCategoryIDFromUrl(l1_category) || null,
  });
  const [debouncedQuery] = useDebounce(searchQuery, 600);
  const [productsToShow, setProductsToShow] = useState([]);
  const viewGMV = useMemo(
    () => hasUserPermissions([UserPermission.UPB_VIEW_ADMIN]),
    [hasUserPermissions],
  );

  const page = useMemo(() => Math.max(toSafeInteger(currentPage) - 1, 0), [currentPage]);
  const offset = useMemo(() => {
    return page * DEFAULT_UPB_PAGE_SIZE || 0;
  }, [page]);

  useEffect(() => {
    if (!selectedCategories?.length) return;
    // [0] => L1, [1] => L2, [2] => L3, [3] => L4
    const selectedL1CatValue = selectedCategories[0]?.value;
    setSelectedL1CategoryId({
      l1CategoryId: selectedL1CatValue,
    });

    const validCategories = selectedCategories
      .slice(0, 4) // Limit to first 4 categories (L1-L4)
      .map(constructUrlPathSegment)
      .filter(Boolean); // Remove null/undefined values

    if (validCategories.length > 0) {
      const newPath = `/upb-viewer/${validCategories.join('/')}`;
      history.push(newPath);
    }
    // eslint-disable-next-line
  }, [selectedCategories]);

  const onSearchQueryChange = query => {
    setSearchQuery(query);
    setProductsToShow([]);
  };

  const {
    data: { data: products = [], count = 0 } = {},
    loading,
    error,
  } = useGet(
    getURL(GLOBAL_PRODUCTS_BY_SEARCH, {}),
    {
      offset,
      limit: DEFAULT_UPB_PAGE_SIZE,
      search_query: debouncedQuery,
      l4_category_id: getCategoryIDFromUrl(l4_category),
      verified_manufacturer_products_only: false,
    },
    !l4_category,
  );

  const {
    data: { data: childCategoryOptions } = {},
    loading: categoryLoading,
    refetch,
  } = useGet(
    TAXONOMY_CHILD_CATEGORY_DETAILS,
    {
      l3_category_id: getCategoryIDFromUrl(l3_category) || null,
      l2_category_id: getCategoryIDFromUrl(l2_category) || null,
      l1_category_id: getCategoryIDFromUrl(l1_category) || null,
    },
    !l3_category && !l2_category && !l1_category,
  );

  const { data, loading: GMVLoading } = useGet(
    ANNUALIZED_GMV,
    {
      _id:
        getCategoryIDFromUrl(l4_category) ||
        getCategoryIDFromUrl(l3_category) ||
        getCategoryIDFromUrl(l2_category) ||
        getCategoryIDFromUrl(l1_category),
      category_level: getCategoryLevel(l1_category, l2_category, l3_category, l4_category),
    },
    !viewGMV || (!l3_category && !l2_category && !l1_category),
  );

  const { data: totalGMV, loading: TotalGMVLoading } = useGet(TOTAL_ANNUALIZED_GMV, {}, !viewGMV);

  useEffect(() => {
    if (products.length) {
      setProductsToShow(products);
      if (count < DEFAULT_UPB_PAGE_SIZE) {
        setCurrentPage(1);
      }
    }
  }, [count, debouncedQuery, products]);

  useEffect(() => {
    setProductsToShow([]);
  }, [l1_category, l2_category, l3_category, l4_category]);

  const totalPages = useMemo(() => Math.ceil(count / DEFAULT_UPB_PAGE_SIZE), [count]);

  if (error) {
    return null;
  }

  return (
    <Container>
      <Row className={classNames('mb-4 ml-4', css(styles.titleContainer), 'mb-4')}>
        <Col>
          <h3 className="font-weight-bold">UPB View</h3>
          {viewGMV && (
            <span>
              Total Annualized Contract GMV : $
              {TotalGMVLoading ? (
                <Spinner animation="border" role="status" size="sm" className="ml-4" />
              ) : totalGMV?.data ? (
                Number(totalGMV.data.toFixed(2)).toLocaleString('en-US')
              ) : (
                '-'
              )}
            </span>
          )}
        </Col>
        <Col>
          <div className="d-flex justify-content-end flex-row align-items-center">
            <div className="w-50 text-right mr-2 text-muted">
              {`${offset}-${offset + DEFAULT_UPB_PAGE_SIZE} of ${count}`} Items
            </div>
            <SearchBar
              value={searchQuery}
              setValue={onSearchQueryChange}
              placeholder="Search by name, gtin"
              disabled={false}
            />
          </div>
        </Col>
      </Row>
      <Row className={classNames(css(styles.catalogContainer))}>
        <Col md={3} className={classNames(css(styles.sidePanel))}>
          <CatalogSidePanel
            selectedCategories={selectedCategories}
            setSelectedCategories={e => {
              setSelectedCategories(e);
            }}
            selectedL1CategoryId={selectedL1CategoryId}
            showSubOptions={true}
          />
        </Col>
        <Col md={9}>
          <Row>
            <Col md={viewGMV ? 8 : 12} className="pl-0">
              <Breadcrumb>
                {l1_category && (
                  <Breadcrumb.Item href={`/upb-viewer/${l1_category}`}>
                    {getCategoryNameFromUrl(l1_category)}
                  </Breadcrumb.Item>
                )}
                {l2_category && (
                  <Breadcrumb.Item href={`/upb-viewer/${l1_category}/${l2_category}`}>
                    {getCategoryNameFromUrl(l2_category)}
                  </Breadcrumb.Item>
                )}
                {l3_category && (
                  <Breadcrumb.Item
                    href={`/upb-viewer/${l1_category}/${l2_category}/${l3_category}`}
                  >
                    {getCategoryNameFromUrl(l3_category)}
                  </Breadcrumb.Item>
                )}
                {l4_category && (
                  <Breadcrumb.Item href="#">{getCategoryNameFromUrl(l4_category)}</Breadcrumb.Item>
                )}
              </Breadcrumb>
            </Col>
            {viewGMV && (
              <Col md={4} className="mt-3">
                <span>
                  Annualized Contract GMV : $
                  {GMVLoading ? (
                    <Spinner animation="border" role="status" size="sm" className="ml-4" />
                  ) : data?.data ? (
                    Number(data.data.toFixed(2)).toLocaleString('en-US')
                  ) : (
                    '-'
                  )}
                </span>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12} className={css(styles.productSection)}>
              {l4_category ? (
                <>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <UPBCatalogView products={productsToShow} productCount={count} />
                      <div className="my-4">
                        <PaginationBar
                          totalPages={totalPages}
                          currentPageNumber={page}
                          onPageChange={newPage => setCurrentPage(newPage + 1)}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {categoryLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <UPBCategoryView
                        categories={childCategoryOptions}
                        categoryCount={childCategoryOptions?.length || 0}
                        refetch={refetch}
                      />
                      <div className="my-4">
                        <PaginationBar
                          totalPages={totalPages}
                          currentPageNumber={page}
                          onPageChange={newPage => setCurrentPage(newPage + 1)}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

const styles = StyleSheet.create({
  titleContainer: {
    // position: 'sticky',
    top: 50,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 40px 0px 20px',
  },
  catalogContainer: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 150px)',
  },
  sidePanel: {
    position: 'sticky',
    height: 'calc(100vh - 150px)',
    top: 0,
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 150px)',
  },
  productSection: {
    background: backgroundGrey5,
    position: 'sticky',
    top: 50,
    display: 'flex',
  },
});

export default UPBViewer;
export { constructUrlPathSegment, DEFAULT_ALL_CATEGORY_OPTION, getCategoryIDFromUrl };
