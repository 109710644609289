import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { colorStyles, greyStroke } from 'lib/css/colors';
import { displayJsonSafeText } from 'lib/utils/displayJsonSafeText';

function AttributesGroupedTagView({ attributes = [] }) {
  return (
    <div className="py-3">
      {attributes.map((attribute, idx) => (
        <Row key={attribute.displayName + idx} className={css(styles.rowStyle)}>
          <Col className={classNames('py-2 col-4', css(colorStyles.auroMetalSaurus))}>
            <div className="d-flex align-self-center">{attribute.displayName}</div>
          </Col>
          <Col className="d-flex flex-wrap">
            {attribute.values.map((tag, tagIdx) => (
              <div
                className={classNames(
                  css(colorStyles.squidInk, styles.tag),
                  'd-flex py-1 px-2 m-1 ',
                )}
                key={tagIdx}
              >
                {displayJsonSafeText(tag)}
              </div>
            ))}
          </Col>
        </Row>
      ))}
    </div>
  );
}

const styles = StyleSheet.create({
  rowStyle: {
    marginLeft: -6,
    marginRight: 0,
  },
  tag: {
    border: `1px solid ${greyStroke}`,
    borderRadius: 8,
  },
});

AttributesGroupedTagView.propTypes = {
  attributes: PropTypes.array,
};

export default AttributesGroupedTagView;
