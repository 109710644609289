import React, { useState } from 'react';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SplitPane from 'react-split-pane';
import { useHistory, useParams } from 'react-router-dom';

import { BRANDS_GET_BY_ID, BRANDS_UPDATE_FROM_FSA, getURL } from 'lib/networking/endpoints';
import BrandMenuSplitView from 'components/manage_brands/brand_details/BrandMenuSplitView';
import BrandLogoSplitView from 'components/manage_brands/brand_details/BrandLogoSplitView';
import BrandLogoUploadModal from 'components/manage_brands/brand_details/BrandLogoUploadModal';
import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function BrandDetailsView() {
  const { brandID } = useParams();
  const history = useHistory();

  const [showBrandLogoModal, setShowBrandLogoModal] = useState(false);

  const {
    data: { brand } = {},
    loading,
    error,
    refetch,
  } = useGet(getURL(BRANDS_GET_BY_ID, { brand_id: brandID }), {}, !brandID);

  const { postData: updateBrand, loading: updateLoading } = usePost(
    BRANDS_UPDATE_FROM_FSA,
    () => {
      SwalDialog('success', 'Successfully updated brand', 'Success', 'center', refetch());
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while updating the brand: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  if (loading || updateLoading) {
    return <LoadingSpinner />;
  }

  if (error || !brand) {
    return null;
  }

  return (
    <Container>
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mr-2')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/enrich-products/manage-brands')}
        />
        All Brands
      </h3>
      <SplitPane
        primary="first"
        split="vertical"
        defaultSize="25%"
        className={css(styles.splitPane)}
        allowResize={false}
      >
        <BrandLogoSplitView
          brand={brand}
          updateBrand={updateBrand}
          setShowBrandLogoModal={setShowBrandLogoModal}
        />
        <BrandMenuSplitView brand={brand} updateBrand={updateBrand} refetch={refetch} />
      </SplitPane>
      {showBrandLogoModal ? (
        <BrandLogoUploadModal
          brand={brand}
          updateBrand={updateBrand}
          onHide={() => {
            setShowBrandLogoModal(false);
            refetch();
          }}
          loading={updateLoading}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  splitPane: {
    position: 'relative',
    minHeight: '80vh',
  },
});

export default BrandDetailsView;
