import React, { useEffect, useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDebounce } from 'use-debounce';

import ImageRecommendationFilters from 'components/assets/image_recommendations/filters/ImageRecommendationFilters';
import ImageClassificationState from 'lib/enums/ImageClassificationState';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { PRODUCTS_WITHOUT_IMAGES } from 'lib/networking/endpoints';
import RecommendationProductsTable from 'components/assets/image_recommendations/RecommendationProductsTable';
import SearchBar from 'components/shared/SearchBar';
import useGet from 'lib/hooks/useGet';

function ImageRecommendationsView() {
  const [showFilters, setShowFilters] = useState(true);
  const [fetchData, setFetchData] = useState(false);
  const [productList, setProductList] = useState({});
  const [selectedL1CategoryId, setSelectedL1CategoryId] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [classificationState, setClassificationState] = useState(
    ImageClassificationState.CLASSIFIED,
  );
  const [searchQuery] = useDebounce(searchTerm, 400);

  const {
    data: { data: products, count: productCount } = {},
    loading,
    refetch: getProducts,
  } = useGet(
    PRODUCTS_WITHOUT_IMAGES,
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      product_list_id: productList?.id,
      l1_category_id: selectedL1CategoryId,
      search_term: searchQuery,
      classification_state: classificationState,
    },
    false,
    true,
  );

  // fetch when the page/page size change
  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, searchQuery]);

  useEffect(() => {
    if (productList === undefined) {
      setFetchData(false);
    }
  }, [productList]);

  return (
    <div>
      <Row className="my-3">
        <Col>
          <Row>
            <Col md={showFilters ? 9 : 6}>
              <SearchBar
                value={searchTerm}
                setValue={setSearchTerm}
                placeholder="Search by SKU, GTIN or name"
                disabled={loading}
              />
            </Col>
            <Col className="d-flex align-items-center">
              <Button variant="link" onClick={() => setShowFilters(!showFilters)} className="p-0">
                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                {showFilters ? 'Hide' : 'Show'} Product List
              </Button>
            </Col>
          </Row>
          <div className="my-3">
            {loading ? (
              <div className="my-5">
                <LoadingSpinner short />
              </div>
            ) : fetchData ? (
              <RecommendationProductsTable
                products={products}
                productCount={productCount}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                refetch={getProducts}
              />
            ) : (
              <div className="my-5 font-weight-bold text-center">
                Please select a product list for products to be displayed
              </div>
            )}
          </div>
        </Col>
        {showFilters ? (
          <Col md={4}>
            <ImageRecommendationFilters
              setFetchData={setFetchData}
              productList={productList}
              setProductList={setProductList}
              selectedL1CategoryId={selectedL1CategoryId}
              setSelectedL1CategoryId={setSelectedL1CategoryId}
              applyFilters={getProducts}
              classificationState={classificationState}
              setClassificationState={setClassificationState}
              loading={loading}
            />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default ImageRecommendationsView;
