import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import DistributorFilter from 'components/shared/DistributorFilter';
import { GLOBAL_PRODUCT_VENDOR_CLONE } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function CloneVendorProductModal({ showCloneModal, setShowCloneModal, vendorProductId, refetch }) {
  const [selectedVendorId, setSelectedVendorId] = useState(null);

  const { postData: cloneProduct, loading } = usePost(
    GLOBAL_PRODUCT_VENDOR_CLONE,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Product cloned successfully',
      }).then(() => {
        setShowCloneModal(false);
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while cloning the product: ${error?.response?.data?.message}`,
      }),
  );
  return (
    <Modal show={showCloneModal} onHide={() => setShowCloneModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Clone Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          All product attributes and images will be cloned to a new product. Propagated attributes
          will not be cloned.
        </p>
        <p>Select a vendor to clone the product to:</p>
        <DistributorFilter
          updateSelectedVendorId={option => {
            option?.value && setSelectedVendorId(option.value);
          }}
          testVendorsOnly
          loading={loading}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowCloneModal(false)} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            cloneProduct({
              vendor_product_id: vendorProductId,
              global_vendor_id: selectedVendorId,
            });
          }}
          disabled={!selectedVendorId}
        >
          {loading ? 'Cloning...' : 'Clone'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

CloneVendorProductModal.propTypes = {
  showCloneModal: PropTypes.bool,
  setShowCloneModal: PropTypes.func,
  vendorProductId: PropTypes.number,
  refetch: PropTypes.func,
};

export default CloneVendorProductModal;
