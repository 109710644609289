import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import SimilarNameEditor from 'components/manage_brands/brand_details/SimilarNameEditor';

function EditableProperty({ label, manufacturer, setIsEditing, setUpdatedManufacturer, refetch }) {
  const handleUpdateManufacturer = (property, value) => {
    setUpdatedManufacturer(prevManufacturer => ({
      ...prevManufacturer,
      [property]: value,
    }));
  };

  return label === 'similar_manufacturers' ? (
    <SimilarNameEditor
      similarManufacturers={manufacturer.similar_manufacturers}
      setIsEditing={setIsEditing}
      refetch={refetch}
    />
  ) : (
    <Form.Control
      as={label === 'description' ? 'textarea' : 'input'}
      rows={10}
      className={css(styles.form)}
      autoFocus
      defaultValue={manufacturer[label]}
      onChange={event => handleUpdateManufacturer(label, event.target.value)}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          setIsEditing(false);
        }
      }}
    />
  );
}

const styles = StyleSheet.create({
  select: {
    flexGrow: 0.96,
  },
  form: {
    height: 'auto',
    marginRight: '0.6rem',
  },
});

EditableProperty.propTypes = {
  label: PropTypes.string,
  manufacturer: PropTypes.object,
  setIsEditing: PropTypes.func,
  updatedManufacturer: PropTypes.object,
  setUpdatedManufacturer: PropTypes.func,
  refetch: PropTypes.func,
};

export default EditableProperty;
