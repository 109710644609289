import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

import {
  BRANDS_BY_IDS,
  BRANDS_FOR_DROPDOWN,
  getURLWithSearchParams,
} from 'lib/networking/endpoints';
import ProductListFilter from 'lib/enums/ProductListFilter';
import { removeEmpty } from 'lib/utils/objectUtils';
import { httpGet } from 'lib/networking/http';
import SearchableScrollableMultiSelect from 'components/shared/SearchableScrollableMultiSelect';

function BrandFilter({ selectedValues, updateFilters }) {
  const [initOptions, setInitOptions] = useState([]);

  // initial load
  useEffect(() => {
    const url = getURLWithSearchParams(
      BRANDS_BY_IDS,
      removeEmpty({
        ids: selectedValues?.length ? selectedValues.join(',') : null,
      }),
    );
    httpGet(url).then(res => {
      if (res.status === 200) {
        const brands = res.data?.data;
        if (brands?.length) {
          setInitOptions(brands.map(brand => ({ label: brand.name, value: brand.id })));
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Group>
      <Form.Label>Select Brands</Form.Label>
      <SearchableScrollableMultiSelect
        onChange={options =>
          updateFilters(
            ProductListFilter.BRAND_IDS,
            options?.map(option => option.value),
          )
        }
        fetchUrl={BRANDS_FOR_DROPDOWN}
        optionMapper={brand => ({ label: brand.name, value: brand.id })}
        initOptions={initOptions}
      />
    </Form.Group>
  );
}

BrandFilter.propTypes = {
  selectedValues: PropTypes.array,
  updateFilters: PropTypes.func,
};

export default BrandFilter;
