import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import pointerOnHover from 'lib/css/pointerOnHover';
import UnmappedAttributeStatus from 'lib/enums/UnmappedAttributeStatus';

function DataSourcePendingAttributeViewRow({ dataSourceRecord }) {
  const history = useHistory();

  const unmapped_attributes =
    dataSourceRecord.unmapped_attributes?.filter(
      attribute => attribute.status === UnmappedAttributeStatus.PENDING,
    ) || [];
  const mapped_attributes =
    dataSourceRecord.unmapped_attributes?.filter(
      attribute => attribute.status === UnmappedAttributeStatus.MAPPED,
    ) || [];

  return (
    <tr
      key={dataSourceRecord.id}
      className={pointerOnHover}
      onClick={() => history.push(`/pending-attribute-mappings/data-source/${dataSourceRecord.id}`)}
    >
      <td>{dataSourceRecord.source_name}</td>
      <td>{dataSourceRecord.source_type}</td>
      <td>{dataSourceRecord.entity_type}</td>
      <td>{unmapped_attributes.length}</td>
      <td>{mapped_attributes.length}</td>
      <td>View</td>
    </tr>
  );
}

DataSourcePendingAttributeViewRow.propTypes = {
  dataSourceRecord: PropTypes.object.isRequired,
};

export default DataSourcePendingAttributeViewRow;
