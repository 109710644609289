import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { IMAGE_FACING_OPTIONS } from 'components/image_tagger/image_type_constants';

function ImageFacingSelector({ selectedImageFacing, setSelectedImageFacing, disabled }) {
  const imageFacingOptions = [...IMAGE_FACING_OPTIONS];
  return (
    <Form.Group>
      <Form.Label>Image Facing</Form.Label>
      <Select
        isDisabled={disabled}
        isClearable
        value={imageFacingOptions.find(option => option.value === selectedImageFacing) || null}
        options={imageFacingOptions}
        onChange={option => setSelectedImageFacing(option?.value || null)}
        placeholder="Select Facing..."
      />
    </Form.Group>
  );
}

ImageFacingSelector.propTypes = {
  selectedImageFacing: PropTypes.string,
  setSelectedImageFacing: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ImageFacingSelector;
