import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';

import { areYouSure } from 'lib/utils/toast';
import { ProductTypeDisplay } from 'lib/enums/ProductType';

function UnlinkProductGroupModal({
  globalProductId,
  selectedList,
  unlinkProductGroup,
  unlinkingProductGroup,
  onHide,
}) {
  const [gtin, setGtin] = useState();

  return (
    <Modal animation={false} centered show={true} onHide={onHide}>
      <div>
        <Modal.Body>
          <Row className="my-3">
            <Col>
              <h6>
                <strong>Assign GTIN</strong>
              </h6>
              <Form.Control type="text" value={gtin} onChange={e => setGtin(e.target.value)} />
            </Col>
          </Row>
          <p className="small text-muted">* You can continue without assigning a GTIN.</p>
          <hr />
          <div>
            <Button
              className="mr-3 px-4"
              disabled={!selectedList.length || unlinkingProductGroup}
              variant="outline-danger"
              onClick={() =>
                areYouSure(
                  () =>
                    unlinkProductGroup({
                      global_product_id: globalProductId,
                      manufacturer_product_ids: selectedList
                        .filter(product => product.entity_type === ProductTypeDisplay.MANUFACTURER)
                        .map(product => product.id),
                      vendor_product_ids: selectedList
                        .filter(product => product.entity_type === ProductTypeDisplay.DISTRIBUTOR)
                        .map(product => product.id),
                      gtin: gtin,
                    }),
                  selectedList.length === 1
                    ? 'Are you sure you want to unlink this product?'
                    : 'Are you sure you want to unlink these product?',
                )
              }
            >
              {unlinkingProductGroup ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : selectedList.length === 1 ? (
                'Unlink Product'
              ) : (
                'Create New Group'
              )}
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

UnlinkProductGroupModal.propTypes = {
  globalProductId: PropTypes.number,
  selectedList: PropTypes.array,
  unlinkProductGroup: PropTypes.func,
  unlinkingProductGroup: PropTypes.bool,
  onHide: PropTypes.func,
};

export default UnlinkProductGroupModal;
