const VendorConfigurationStatus = {
  YES: 'YES',
  NO: 'NO',
};

const VendorConfigurationStatusOptions = [
  { value: VendorConfigurationStatus.YES, label: 'Yes' },
  { value: VendorConfigurationStatus.NO, label: 'No' },
];

export { VendorConfigurationStatus, VendorConfigurationStatusOptions };
