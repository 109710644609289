import React, { useMemo, useState } from 'react';

import classNames from 'classnames';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faFilter, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

import {
  ALL_PRODUCT_LISTS,
  GLOBAL_MANUFACTURERS,
  GLOBAL_MANUFACTURERS_DELETE_UNLINKED,
  GLOBAL_MANUFACTURERS_DOWNLOAD_CSV,
  GLOBAL_MANUFACTURERS_UNVERIFY,
} from 'lib/networking/endpoints';
import { areYouSure } from 'lib/utils/toast';
import { backgroundGrey3, white } from 'lib/css/colors';
import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageManufacturersTable from 'components/manage_manufacturers/ManageManufacturersTable';
import ManufacturerEditModal from 'components/manage_manufacturers/ManufacturerEditModal';
import ManufacturerFilters from 'components/manage_manufacturers/filters/ManufacturerFilters';
import MergeManufacturersModal from 'components/manage_manufacturers/MergeManufacturersModal';
import PaginationComponent from 'components/shared/PaginationComponent';
import ProductListFilter from 'lib/enums/ProductListFilter';
import SearchBar from 'components/shared/SearchBar';
import useGet from 'lib/hooks/useGet';
import useQueryString from 'lib/hooks/useQueryString';
import usePost from 'lib/hooks/usePost';

function ManageManufacturersView() {
  const history = useHistory();
  const verificationStatus = useQueryString().get('verificationStatus');

  const [searchInput, setSearchInput] = useState('');
  const [searchQuery] = useDebounce(searchInput, 200);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [mergeOrDeleteList, setMergeOrDeleteList] = useState([]);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [productList, setProductList] = useState();
  const [status, setStatus] = useState(verificationStatus || '');
  const [manufacturerEditModalSettings, setManufacturerEditModalSettings] = useState({
    show: false,
  });

  const {
    data: { manufacturers, count } = {},
    loading,
    refetch,
  } = useGet(GLOBAL_MANUFACTURERS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    query: searchQuery,
    product_list_id: productList?.id,
    status: status,
  });

  const { postData: deleteManufacturers, loading: manufacturersDeleting } = usePost(
    GLOBAL_MANUFACTURERS_DELETE_UNLINKED,
    () => {
      SwalDialog('success', 'Successfully deleted manufacturers', 'Success', 'center');
      refetch();
      setMergeOrDeleteList([]);
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while deleting manufacturers: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const { postData: unverifyManufacturers, loading: manufacturersUnverifying } = usePost(
    GLOBAL_MANUFACTURERS_UNVERIFY,
    () => {
      SwalDialog('success', 'Successfully unverified manufacturers', 'Success', 'center');
      refetch();
      setMergeOrDeleteList([]);
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while unverifying manufacturers: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const { postData: exportManufacturerDetails, loading: manufacturersExporting } = usePost(
    GLOBAL_MANUFACTURERS_DOWNLOAD_CSV,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  const { data: { product_lists = [] } = {} } = useGet(ALL_PRODUCT_LISTS);

  const productListOptions = useMemo(() => {
    return product_lists
      .filter(list => list?.filters && !(ProductListFilter.MANUFACTURER_IDS in list.filters))
      .map(list => ({ label: list.name, value: list.id }));
  }, [product_lists]);

  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Manage Manufacturers</h3>
        <Button
          variant="link"
          onClick={() =>
            history.push('/enrich-products/manage-manufacturers/manage-duplicate-manufacturers')
          }
        >
          Manage Duplicate Manufacturers
        </Button>
      </div>
      <Row>
        <Col xs={4}>
          <SearchBar
            value={searchInput}
            setValue={setSearchInput}
            placeholder="Search by manufacturer name"
          />
        </Col>
        <Col xs={2} className="d-flex mr-2 justify-content-start">
          <Button variant="link" onClick={() => setShowFilters(!showFilters)} className="p-2">
            <FontAwesomeIcon size="lg" icon={faFilter} className="mr-2" />
            {showFilters ? 'Hide' : 'Show'} Filters
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            className="mr-3"
            variant="danger"
            onClick={() =>
              areYouSure(
                () =>
                  deleteManufacturers({
                    deleteIds: mergeOrDeleteList.map(manufacturer => manufacturer.id),
                  }),
                'Are you sure you want to delete the selected manufacturers?',
              )
            }
            disabled={mergeOrDeleteList.length < 1 || manufacturersDeleting}
          >
            {manufacturersDeleting ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              'Delete Unlinked Manufacturers'
            )}
          </Button>
          <Button
            className="mr-3"
            variant="outline-danger"
            onClick={() =>
              areYouSure(
                () =>
                  unverifyManufacturers({
                    manufacturer_ids: mergeOrDeleteList.map(manufacturer => manufacturer.id),
                  }),
                'Are you sure you want to unverify the selected manufacturers?',
              )
            }
            disabled={mergeOrDeleteList.length < 1 || manufacturersUnverifying}
          >
            {manufacturersUnverifying ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              'Unverify Selected Manufacturers'
            )}
          </Button>
          <Button
            className="mr-3"
            variant="primary"
            onClick={() => setShowMergeModal(true)}
            disabled={mergeOrDeleteList.length < 2}
          >
            Merge Manufacturers
          </Button>
          <Button
            className="mr-3"
            variant="primary"
            onClick={() => history.push('/enrich-products/create-manufacturer')}
          >
            + New Manufacturer
          </Button>
          <Button
            variant="outline-primary"
            onClick={() =>
              exportManufacturerDetails({
                query: searchQuery,
                product_list_id: productList?.id,
                status: status,
              })
            }
            disabled={manufacturersExporting}
          >
            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
            Export
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex mt-3">
          <span className={classNames(css(styles.selectedText), 'text-secondary ml-2')}>
            {count} Manufacturers
          </span>
        </Col>
        <Col className={classNames(css(styles.pagination), 'd-flex justify-content-end')}>
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            defaultSize
          />
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        {loading ? (
          <LoadingSpinner short />
        ) : (
          <ManageManufacturersTable
            manufacturers={manufacturers}
            mergeList={mergeOrDeleteList}
            setMergeList={setMergeOrDeleteList}
            setManufacturerEditModalSettings={setManufacturerEditModalSettings}
          />
        )}
      </div>
      {showFilters && (
        <div className={css(styles.manufacturerFiltersOverlay)}>
          <ManufacturerFilters
            productList={productList}
            setProductList={setProductList}
            status={status}
            setStatus={setStatus}
            setShowFilters={setShowFilters}
            loading={loading}
            product_lists={product_lists}
            productListOptions={productListOptions}
          />
        </div>
      )}
      {showMergeModal ? (
        <MergeManufacturersModal
          mergeList={mergeOrDeleteList}
          setMergeList={setMergeOrDeleteList}
          onHide={() => {
            setShowMergeModal(false);
            refetch();
            setMergeOrDeleteList([]);
          }}
        />
      ) : null}
      {manufacturerEditModalSettings.show ? (
        <ManufacturerEditModal
          show={manufacturerEditModalSettings.show}
          manufacturer={manufacturerEditModalSettings.manufacturer}
          onHide={() => {
            setManufacturerEditModalSettings({ show: false });
            setMergeOrDeleteList([]);
          }}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '90vh',
  },
  manufacturerFiltersOverlay: {
    position: 'absolute',
    padding: '1.5rem',
    top: 40,
    right: 0,
    bottom: 0,
    width: '30vw',
    minHeight: '100vh',
    backgroundColor: white,
    borderLeft: '5px solid' + backgroundGrey3,
    transitionProperty: 'transform, visibility, width',
    transitionDuration: '0.4s',
    transitionTimingFunction: 'ease-in-out',
    zIndex: 999,
    visibility: 'visible',
  },
  pagination: {
    marginTop: '1rem',
    zIndex: 2,
  },
  selectedText: {
    fontSize: '1rem',
  },
});

export default ManageManufacturersView;
