import { useEffect, useState } from 'react';

function useImageDimensions(url) {
  const [size, setSize] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getImageSizeFromCache = () => {
      const img = new Image();
      img.onload = () => {
        const imageSize = {
          width: img.width,
          height: img.height,
        };
        setSize(imageSize);
      };
      img.onerror = () => {
        setError(new Error('Failed to load image'));
      };

      img.src = url;
    };

    if (url) {
      getImageSizeFromCache();
    }

    return () => {
      // Clean up any ongoing image loading if the component unmounts
      setSize(null);
      setError(null);
    };
  }, [url]);

  return { size, error };
}

export default useImageDimensions;
