import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Select from 'react-select';
import { useParams } from 'react-router-dom';

import AddCategoryToAttributeModal from 'components/manage_attribute_value/AddCategoryToAttributeModal';
import { ATTRIBUTES_ASSIGNED_CATEGORIES, ATTRIBUTES_MAPS_DETAILS } from 'lib/networking/endpoints';
import { backgroundGrey2 } from 'lib/css/colors';
import { CATEGORY_OPTIONS, TaxonomyGlobal } from 'lib/enums/TaxonomyLevel';
import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageAttributeCategoriesAssignedTableRow from 'components/manage_attribute_value/ManageAttributeCategoriesAssignedTableRow';
import SwalDialog from 'lib/utils/toast';
import { SwalToast } from 'lib/utils/toast';
import useDelete from 'lib/hooks/useDelete';
import useGet from 'lib/hooks/useGet';

function ManageAttributeCategoriesAssignedView() {
  const { attribute_id } = useParams();
  const [filters, setFilters] = useState({
    searchText: '',
    categoryLevel: CATEGORY_OPTIONS[0],
  });
  const [filteredMappings, setFilteredMappings] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]); // ['L0_13'] taxonomylevel_category_id
  const [showCategoryAssignModal, setShowCategoryAssignModal] = useState(false);

  const {
    data: { data: mappings = [] } = [],
    loading,
    refetch,
  } = useGet(ATTRIBUTES_ASSIGNED_CATEGORIES, {
    attribute_id: attribute_id,
  });

  const { deleteData: deleteAttributeMaps } = useDelete(
    ATTRIBUTES_MAPS_DETAILS,
    false,
    () => {
      SwalToast('success', `Removed`);
      setSelectedIds([]);
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        error.response?.data?.message,
        'An error occurred while removing mapping',
        'center',
      );
    },
  );

  useEffect(() => {
    if (mappings) {
      setFilteredMappings(mappings);
      setFilters({
        searchText: '',
        categoryLevel: CATEGORY_OPTIONS[0],
      });
    }
  }, [mappings]);

  useEffect(() => {
    let filtered = filteredMappings;

    if (filters.categoryLevel) {
      if (filters.categoryLevel.value === TaxonomyGlobal.GL) {
        filtered = mappings;
      } else {
        filtered = mappings.filter(item =>
          Object.entries(item).some(
            ([key, value]) => key === filters.categoryLevel.value && value.is_applied === true,
          ),
        );
      }
    }
    if (filters.searchText) {
      filtered = filtered.filter(item => {
        return Object.values(item).some(category =>
          category.category_name.toLowerCase().includes(filters.searchText.toLowerCase()),
        );
      });
    }
    setFilteredMappings(filtered);
    // eslint-disable-next-line
  }, [filters, mappings]);

  return (
    <Container className={css(styles.container)}>
      <Row>
        <Col sm={2}>
          <Form.Group>
            <Form.Label>Applied Level</Form.Label>
            <Select
              className={css(styles.dropDownSelector)}
              classNamePrefix="themed_select"
              options={CATEGORY_OPTIONS}
              isSearchable
              onChange={e => setFilters({ ...filters, categoryLevel: e })}
              value={filters.categoryLevel}
            />
          </Form.Group>
        </Col>
        <Col sm={3} className="mb-4 pl-3">
          <Form.Label></Form.Label>
          <Form.Group>
            <Form.Control
              className="mt-2"
              type="text"
              placeholder="Search Category"
              value={filters.searchText}
              // disabled={true}
              onChange={e => setFilters({ ...filters, searchText: e.target.value })}
            />
          </Form.Group>
        </Col>
        <Col sm={7} className="mt-1">
          <div className="float-right mt-4">
            <Button onClick={() => deleteAttributeMaps({ attribute_map_ids: selectedIds })}>
              Remove
            </Button>
          </div>
          <div className="float-right mt-4 mr-1">
            <Button onClick={() => setShowCategoryAssignModal(true)}>Add Category</Button>
          </div>
        </Col>
      </Row>
      <Table hover className="py-1">
        <thead className={classNames(css(styles.tableHeader), 'font-weight-bold')}>
          <tr>
            <td className="py-1 pl-4"></td>
            <td className="py-1">L0 Category</td>
            <td className="py-1">L1 Category</td>
            <td className="py-1">L2 Category</td>
            <td className="py-1">L3 Category</td>
            <td className="py-1">Applied Level</td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <LoadingSpinner />
          ) : (
            filteredMappings?.map((row, index) => (
              <ManageAttributeCategoriesAssignedTableRow
                key={index}
                tableRow={row}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            ))
          )}
        </tbody>
      </Table>
      {showCategoryAssignModal && (
        <AddCategoryToAttributeModal
          onHide={() => setShowCategoryAssignModal(false)}
          refetch={refetch}
        />
      )}
    </Container>
  );
}

const styles = StyleSheet.create({
  tableHeader: {
    backgroundColor: backgroundGrey2,
    borderRadius: 4,
    top: 0,
    position: 'sticky',
  },
  container: {
    minHeight: '400px',
  },
});

export default ManageAttributeCategoriesAssignedView;
