import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import dayjs from 'dayjs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { CATEGORY_COLUMNS, DATE_FORMAT_WITH_TIME } from 'lib/constants';
import { categoryStyles } from 'lib/css/categoryTexts';

function AISuggestedClassificationProductTableCell({ product, column }) {
  const text = product[column];
  const isCategoryColumn = CATEGORY_COLUMNS.includes(column);
  const isDate = column === 'categorized_at' && text;

  const renderTooltip = props => <Tooltip {...props}>{text}</Tooltip>;
  return text ? (
    <OverlayTrigger placement="top" overlay={renderTooltip} transition={false}>
      <div className={css(styles.textBox, isCategoryColumn ? categoryStyles[column] : null)}>
        {isDate ? dayjs(text).format(DATE_FORMAT_WITH_TIME) : text}
      </div>
    </OverlayTrigger>
  ) : null;
}

const styles = StyleSheet.create({
  textBox: {
    maxHeight: 18,
    wordBreak: 'break-word',
  },
});

AISuggestedClassificationProductTableCell.propTypes = {
  product: PropTypes.object,
  column: PropTypes.string,
};

export default AISuggestedClassificationProductTableCell;
