import React, { useContext } from 'react';

import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { ATTRIBUTION_CSV_EXPORT } from 'lib/networking/endpoints';
import { GlobalProductsContext } from 'components/global_products/GlobalProductsContainer';
import usePost from 'lib/hooks/usePost';

function GlobalProductMultiAttributeSection() {
  const { selectedIDs } = useContext(GlobalProductsContext);

  const { postData: exportAttributeCSV, loading } = usePost(
    ATTRIBUTION_CSV_EXPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the attributes',
      }),
  );

  return (
    <div className="w-100 text-center">
      <Button
        size="lg"
        className="text-center my-5 px-5"
        disabled={loading}
        onClick={() => exportAttributeCSV({ global_product_ids: selectedIDs })}
      >
        Download CSV for Attribution
      </Button>
    </div>
  );
}

export default GlobalProductMultiAttributeSection;
