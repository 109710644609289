import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';

import { ALL_PRODUCT_LISTS } from 'lib/networking/endpoints';
import L4CategoryFilter from 'components/l4_default_images/filters/L4CategoryFilter';
import useGet from 'lib/hooks/useGet';

function ProductListFilters({
  l4CountForProductList,
  l4WithImagesCountForProductList,
  productList,
  setProductList,
  selectedL4CategoryId,
  setSelectedL4CategoryId,
  setSelectedL4CategoryName,
  l4OptionsForProductList,
  loadingl4OptionsWithProductList,
}) {
  const { data: { product_lists = [] } = [], loading: productListsLoading } =
    useGet(ALL_PRODUCT_LISTS);

  const productListOptions = useMemo(() => {
    return product_lists.map((list, index) => ({ label: list.name, value: index }));
  }, [product_lists]);

  const label = productList
    ? `(${l4WithImagesCountForProductList}/${l4CountForProductList} Assigned)`
    : null;

  return (
    <div className={css(styles.container)}>
      <div className="my-3">
        <Form.Group>
          <div className="d-flex justify-content-between">
            <Form.Label>Select Product List</Form.Label>
            <Form.Label>
              {loadingl4OptionsWithProductList ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                label
              )}
            </Form.Label>
          </div>
          <Select
            isClearable
            options={productListOptions}
            value={productListOptions.find(option => option?.label === productList)}
            onChange={option => {
              setProductList(product_lists[option?.value]);
            }}
            isDisabled={productListsLoading}
            placeholder={productListsLoading ? 'Loading' : 'Select a product list'}
          />
        </Form.Group>
      </div>
      <div className="my-3">
        <L4CategoryFilter
          selectedL4CategoryId={selectedL4CategoryId}
          setSelectedL4CategoryId={setSelectedL4CategoryId}
          setSelectedL4CategoryName={setSelectedL4CategoryName}
          l4Options={l4OptionsForProductList}
          parentLoading={loadingl4OptionsWithProductList}
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '85vh',
  },
});

ProductListFilters.propTypes = {
  l4CountForProductList: PropTypes.number,
  l4WithImagesCountForProductList: PropTypes.number,
  productList: PropTypes.object,
  setProductList: PropTypes.func,
  selectedL4CategoryId: PropTypes.number,
  setSelectedL4CategoryId: PropTypes.func,
  setSelectedL4CategoryName: PropTypes.func,
  l4OptionsForProductList: PropTypes.array,
  loadingl4OptionsWithProductList: PropTypes.bool,
};

export default ProductListFilters;
