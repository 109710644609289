import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import ToggleSwitch from 'react-switch';

import { GLOBAL_MANUFACTURERS_UPDATE_FROM_FSA } from 'lib/networking/endpoints';
import { ManufacturerVerificationStatus } from 'lib/enums/ManufacturerVerificationStatus';
import { grape } from 'lib/css/colors';
import usePost from 'lib/hooks/usePost';

function ManufacturerEditModal({ manufacturer, show, onHide, refetch }) {
  const [name, setName] = useState(manufacturer.name);
  const [manufacturerGLN, setManufacturerGLN] = useState(manufacturer.manufacturer_gln);
  const [verificationStatus, setVerificationStatus] = useState(
    manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED,
  );
  const [requiresManufacturerPortal, setRequiresManufacturerPortal] = useState(
    manufacturer.requires_manufacturer_portal,
  );

  const verified = manufacturer.verification_status === ManufacturerVerificationStatus.VERIFIED;

  const { postData: updareManufacturerDetails, loading: updating } = usePost(
    GLOBAL_MANUFACTURERS_UPDATE_FROM_FSA,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Manufacturer details updated successfully',
        title: 'Success',
        timer: 500,
      }).then(() => {
        onHide();
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `Error occurred on updating manufacturer details: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  return (
    <Modal animation={false} centered show={show} onHide={onHide}>
      <div>
        <Modal.Body>
          <Row>
            <Col>
              <Row className="mt-3">
                <Col>
                  <h6>
                    <strong>Manufacturer Name</strong>
                  </h6>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    disabled={verified}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h6>
                    <strong>Verification Status</strong>
                  </h6>
                  <div>
                    <ToggleSwitch
                      onColor={grape}
                      checked={verificationStatus}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={() => setVerificationStatus(!verificationStatus)}
                      disabled={verified}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h6>
                    <strong>Manufacturer GLN</strong>
                  </h6>
                  <Form.Control
                    type="text"
                    value={manufacturerGLN}
                    onChange={e => setManufacturerGLN(e.target.value)}
                    disabled={verified && manufacturer.manufacturer_gln}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <h6>
                    <strong>Creation of manufacturer portal is required</strong>
                  </h6>
                  <div>
                    <ToggleSwitch
                      onColor={grape}
                      checked={requiresManufacturerPortal}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={() => setRequiresManufacturerPortal(!requiresManufacturerPortal)}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <div>
            <Button
              onClick={() =>
                updareManufacturerDetails({
                  id: manufacturer.id,
                  name: name,
                  verification_status: verificationStatus
                    ? ManufacturerVerificationStatus.VERIFIED
                    : ManufacturerVerificationStatus.UNVERIFIED,
                  manufacturer_gln: manufacturerGLN,
                  requires_manufacturer_portal: requiresManufacturerPortal,
                })
              }
              size="lg"
              className="mr-2"
              disabled={updating}
            >
              {updating ? <Spinner animation="border" role="status" size="sm" /> : 'Update'}
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

ManufacturerEditModal.propTypes = {
  manufacturer: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default ManufacturerEditModal;
