import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import DropZone from 'components/shared/Dropzone';
import { SKU_MAPPINGS_FSA_UPDATE } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function FsaSkuMappingsImportModal({ onHide }) {
  const [file, setFile] = useState();

  const { postData: updateMappings, loading } = usePost(
    SKU_MAPPINGS_FSA_UPDATE,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Data source created successfully',
      }).then(() => onHide());
      onHide();
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while creating the data source: ${error?.response?.data?.message}`,
      }),
  );

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">Update FSA SKU Mappings</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <DropZone
          text="Drag and drop some files here, or click to select files"
          onUploadSuccess={newUrls => setFile(newUrls?.[0])}
          fileUrls={file ? [file.presigned_url] : []}
          isImage={false}
        />
        <div className="my-3">
          <Button
            className="px-5 mr-2"
            disabled={loading}
            onClick={() =>
              updateMappings({
                source_location_url: file.s3_path,
              })
            }
          >
            Upload
          </Button>
          <Button variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

FsaSkuMappingsImportModal.propTypes = {
  onHide: PropTypes.func,
};

export default FsaSkuMappingsImportModal;
