import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { coolGrey, kiwiLinkHoverDark } from 'lib/css/colors';

function LinkButton({ text, icon, onClick, url }) {
  const urlProps = url ? { href: url, target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <Button
      variant="link"
      className={classNames('px-0', css(styles.link))}
      onClick={onClick}
      {...urlProps}
    >
      {icon && <FontAwesomeIcon icon={icon} className="mr-1" />}
      <span className={css(styles.text)}>{text}</span>
    </Button>
  );
}

const styles = StyleSheet.create({
  link: {
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 500,
    color: coolGrey,
    ':hover': {
      color: kiwiLinkHoverDark,
    },
  },
  text: {
    textDecoration: 'underline',
    ':hover': {
      textDecoration: 'none',
    },
  },
});

LinkButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  url: PropTypes.string,
  iconSize: PropTypes.string,
};

export default LinkButton;
