import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getURL, BRANDS_GET_BY_NAME, BRANDS_FOR_DROPDOWN } from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function BrandFilter({ brand, setBrand }) {
  // initial load
  useEffect(() => {
    if (brand?.length) {
      const url = getURL(BRANDS_GET_BY_NAME, { brand_name: brand?.label });
      httpGet(url).then(res => {
        if (res.status === 200) {
          const brandInitial = res.data?.brand;
          if (brandInitial) {
            setBrand({ label: brandInitial.name, value: brandInitial.id });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchableScrollableSelect
      autoFocus={false}
      initOption={brand}
      onChange={option => setBrand({ label: option.label, value: option.value })}
      fetchUrl={BRANDS_FOR_DROPDOWN}
      optionMapper={brand => ({ label: brand.name, value: brand.id })}
    />
  );
}

BrandFilter.propTypes = {
  brand: PropTypes.object,
  setBrand: PropTypes.func,
};

export default BrandFilter;
