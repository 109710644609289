import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Form, Image, OverlayTrigger, Popover } from 'react-bootstrap';

import { lightGreen } from 'lib/css/colors';
import pointerOnHover from 'lib/css/pointerOnHover';
import VerificationStatusIcon from 'components/global_products/detail_view/VerificationStatusIcon';

function DuplicateProductTableRow({
  product,
  selectedProducts,
  setSelectedProducts,
  colored = false,
}) {
  const product_uuid = `${product.product_type}:${product.id}`;

  const handleProductChange = () => {
    if (selectedProducts.includes(product_uuid)) {
      setSelectedProducts(selectedProducts.filter(id => id !== product_uuid));
    } else {
      setSelectedProducts([...selectedProducts, product_uuid]);
    }
  };

  if (!product || !product.global_product) {
    return null;
  }

  return (
    <tr
      onClick={handleProductChange}
      className={classNames(pointerOnHover, colored ? css(styles.colored) : '')}
    >
      <td>
        <Form.Check
          type="checkbox"
          checked={selectedProducts.includes(product_uuid)}
          onChange={handleProductChange}
        />
      </td>
      <td>
        {product.propagated_thumbnail_image_url ? (
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover id="popover-basic" style={{ maxWidth: 400 }}>
                <Popover.Content>
                  <Image width={400} fluid src={product.propagated_thumbnail_image_url} />
                </Popover.Content>
              </Popover>
            }
          >
            <Image width={50} fluid src={product.propagated_thumbnail_image_url} thumbnail />
          </OverlayTrigger>
        ) : (
          'no image'
        )}
      </td>
      <td>
        <a
          href={`/global-product-detail/${product.global_product.id}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {product.global_product.id}
        </a>
      </td>
      <td>
        <a
          href={`/global-product-detail/${product.global_product.id}/${product.id}/${product.product_type}`}
          target="_blank"
          rel="noreferrer"
          onClick={e => e.stopPropagation()}
        >
          {product.id}
        </a>
      </td>
      <td>{product.product_type}</td>
      <td>{product.global_manufacturer?.name || product.global_vendor?.name}</td>
      <td>{product.manufacturer_sku || product.vendor_sku}</td>
      <td>{product.name}</td>
      <td>{product.brand_name}</td>
      <td>{product.propagated_pack_size}</td>
      <td>{product.cd_product_score?.percentage || 0}%</td>
      <td className="text-center">
        <VerificationStatusIcon
          verificationStatus={product.verification_status}
          aiSuggestedVerification={product.ai_suggested_verification}
        />
      </td>
    </tr>
  );
}

const styles = StyleSheet.create({
  colored: {
    backgroundColor: lightGreen,
  },
});

DuplicateProductTableRow.propTypes = {
  product: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  colored: PropTypes.bool,
};

export default DuplicateProductTableRow;
