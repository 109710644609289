import React, { useState } from 'react';

import { Button, Container, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { GLOBAL_MANUFACTURERS_CREATE } from 'lib/networking/endpoints';
import DropZone from 'components/shared/Dropzone';
import { ManufacturerVerificationStatus } from 'lib/enums/ManufacturerVerificationStatus';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import usePost from 'lib/hooks/usePost';

const MANUFACTURER_LOGO_FOLDER = 'manufacturer_logo';

function CreateManufacturerView() {
  const history = useHistory();

  const { postData: createManufacturer } = usePost(
    GLOBAL_MANUFACTURERS_CREATE,
    res => {
      SwalDialog('success', 'Successfully created new manufacturer', 'Success', 'center');
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while creating the manufacturer: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const [name, setName] = useState();
  const [GLN, setGLN] = useState();
  const [description, setDescription] = useState();
  const [slogan, setSlogan] = useState();
  const [websiteUrl, setWebsiteUrl] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [verified, setVerified] = useState(false);
  const [isPartner, setIsPartner] = useState(false);

  return (
    <Container>
      <h3 className="font-weight-bold">
        <FontAwesomeIcon
          className={classNames(pointerOnHover, 'mr-2')}
          icon={faArrowCircleLeft}
          onClick={() => history.push('/enrich-products/manage-manufacturers')}
        />
        Create New Manufacturer
      </h3>

      <div className="border my-3 shadow-sm">
        <Form
          className="px-3 py-3"
          onSubmit={e => {
            e.preventDefault();
            if (name) {
              createManufacturer({
                name,
                description: description || null,
                slogan: slogan || null,
                manufacturer_url: websiteUrl || null,
                manufacturer_gln: GLN || null,
                is_partner: isPartner,
                logo_url: logoUrl,
                verified: verified
                  ? ManufacturerVerificationStatus.VERIFIED
                  : ManufacturerVerificationStatus.UNVERIFIED,
              });
            }
          }}
        >
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              Manufacturer Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                value={name || ''}
                onChange={e => setName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              GLN
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" value={GLN || ''} onChange={e => setGLN(e.target.value)} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              Description
            </Form.Label>
            <Col sm="10">
              <Form.Control
                as="textarea"
                rows="6"
                value={description || ''}
                onChange={e => setDescription(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              Slogan
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                value={slogan || ''}
                onChange={e => setSlogan(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              Manufacturer Website URL
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                value={websiteUrl || ''}
                onChange={e => setWebsiteUrl(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              Logo
            </Form.Label>
            <Col sm="10">
              <DropZone
                text="Drag and drop the logo image here, or click to select the image"
                onUploadSuccess={newUrls => setLogoUrl(newUrls)}
                fileUrls={logoUrl}
                isImage={true}
                isPublic={true}
                filePath={MANUFACTURER_LOGO_FOLDER}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              Manufacturer Partner
            </Form.Label>
            <Col sm="8">
              <Form.Check
                type="checkbox"
                checked={!!isPartner}
                onChange={e => setIsPartner(e.target.checked)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm="2" className="font-weight-bold">
              Verified
            </Form.Label>
            <Col sm="8">
              <Form.Check
                type="checkbox"
                checked={!!verified}
                onChange={e => setVerified(e.target.checked)}
              />
            </Col>
          </Form.Group>

          <Button type="submit">Create New Manufacturer</Button>
        </Form>
      </div>
    </Container>
  );
}

export default CreateManufacturerView;
