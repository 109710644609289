import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { preserveFormatClass, sectionTextClass } from 'components/upb_viewer/css/sharedStyles';

const BULLET_POINT = '\u2022';

function AttributesListView({ attributeValue }) {
  if (!attributeValue.trim()) {
    return null;
  }
  const splitText = attributeValue.trim().split('\n');

  return (
    <div className={classNames(preserveFormatClass, sectionTextClass)}>
      {splitText.map((element, idx) => (
        <div key={idx}>{`${BULLET_POINT} ${element}`}</div>
      ))}
    </div>
  );
}

AttributesListView.propTypes = {
  attributeValue: PropTypes.string,
};

export default AttributesListView;
export { BULLET_POINT };
