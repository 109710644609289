import React, { useEffect, useState } from 'react';

import { Button, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwalDialog from 'lib/utils/toast';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

import BrandEditModal from 'components/manage_brands/BrandEditModal';
import { BRANDS_UPDATE_MULTI, SIMILAR_BRANDS } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import ManageDuplicateBrandsTable from 'components/manage_brands/duplicate_brands/ManageDuplicateBrandsTable';
import MergeBrandsModal from 'components/manage_brands/MergeBrandsModal';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductListFilter from 'components/shared/ProductListFilter';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

function ManageDuplicateBrandsView() {
  const history = useHistory();

  const [mergeList, setMergeList] = useState([]);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [productList, setProductList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [brandEditModalSettings, setBrandEditModalSettings] = useState({ show: false });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchQuery] = useDebounce(searchTerm, 600);
  const [jumpTo, setJumpTo] = useState(null);

  const itemsPerPage = 1;

  const {
    data: { brands, count } = {},
    loading,
    refetch,
  } = useGet(SIMILAR_BRANDS, {
    limit: itemsPerPage,
    offset: itemsPerPage * (currentPage - 1),
    product_list_id: productList?.id,
    search_term: searchQuery,
  });

  const { postData: ignoreBrands } = usePost(
    BRANDS_UPDATE_MULTI,
    () => {
      SwalDialog('success', 'Successfully marked the brands as to be ignored', 'Success', 'center');
      refetch();
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while marking brands as 'to be ignored:
        ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [productList, searchTerm]);

  const handleClusterSearch = e => {
    if (e.key === 'Enter' && jumpTo.trim() !== '') {
      const pageNumber = parseInt(jumpTo.trim(), 10);
      if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= count) {
        setCurrentPage(pageNumber);
      }
    }
  };
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <Container>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">
          <FontAwesomeIcon
            className={classNames(pointerOnHover, 'mr-2')}
            icon={faArrowCircleLeft}
            onClick={() => history.push('/enrich-products/manage-brands')}
          />
          All Brands
        </h3>
      </div>
      <Row className="align-items-center">
        <Col md={4}>
          <ProductListFilter productList={productList} setProductList={setProductList} />
        </Col>
        <Col>
          <Form.Control
            type="text"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search by brand name"
          />
        </Col>
        <Col className="d-flex mr-2 justify-content-end">
          <Button
            className="mr-3"
            variant="primary"
            onClick={() =>
              ignoreBrands({
                brand_ids: mergeList.map(brand => brand.id),
                duplicate_suggestion_status: false,
              })
            }
            disabled={mergeList.length < 1}
          >
            Ignore Selected Brands
          </Button>
          <Button
            className="mr-3"
            variant="primary"
            onClick={() => setShowMergeModal(true)}
            disabled={mergeList.length < 2}
          >
            Merge Brands
          </Button>
        </Col>
      </Row>
      <Row className="mt-4 align-items-center">
        <Col>
          <div className="d-flex align-items-center">
            <Button
              className={css(styles.button)}
              variant="link"
              onClick={() => {
                setCurrentPage(currentPage - 1);
                setJumpTo(`${currentPage - 1}`);
              }}
              size="lg"
              disabled={loading || currentPage === 1}
            >
              &lt; Previous
            </Button>
            <Button
              className={css(styles.button)}
              variant="link"
              onClick={() => {
                setCurrentPage(currentPage + 1);
                setJumpTo(`${currentPage + 1}`);
              }}
              size="lg"
              disabled={loading || currentPage === count}
            >
              Next &gt;
            </Button>
            <Form.Control
              type="text"
              value={jumpTo}
              onChange={e => setJumpTo(e.target.value)}
              onKeyPress={handleClusterSearch}
              placeholder="Jump To"
              className={css(styles.jumpToInput)}
            />
          </div>
        </Col>
        <Col className="d-flex justify-content-end mr-3">
          {currentPage}/{count} Brands clusters found
        </Col>
      </Row>
      <div className={css(styles.scrollableContainer)}>
        <ManageDuplicateBrandsTable
          brands={brands}
          mergeList={mergeList}
          setMergeList={setMergeList}
          setBrandEditModalSettings={setBrandEditModalSettings}
        />
      </div>
      {showMergeModal ? (
        <MergeBrandsModal
          mergeList={mergeList}
          setMergeList={setMergeList}
          onHide={() => {
            setShowMergeModal(false);
            setMergeList([]);
            refetch();
          }}
        />
      ) : null}
      {brandEditModalSettings.show ? (
        <BrandEditModal
          show={brandEditModalSettings.show}
          brand={brandEditModalSettings.brand}
          onHide={() => {
            setBrandEditModalSettings({ show: false });
            setMergeList([]);
          }}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  scrollableContainer: {
    height: '90vh',
  },
  button: {
    fontSize: '1em',
    padding: '0.5em 1em',
  },
  hideSuggestionsButton: {
    fontSize: '1em',
    padding: '0.5em 1em',
    marginLeft: '2em',
  },
  jumpToInput: {
    width: '90px',
  },
});

export default ManageDuplicateBrandsView;
