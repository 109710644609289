import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  getURL,
  GLOBAL_MANUFACTURERS_FOR_DROPDOWN,
  GLOBAL_MANUFACTURERS_GET_BY_ID,
} from 'lib/networking/endpoints';
import { httpGet } from 'lib/networking/http';
import SearchableScrollableSelect from 'components/shared/SearchableScrollableSelect';

function ManufacturerFilter({ manufacturerID, manufacturer, setManufacturer, disabled }) {
  // initial load
  useEffect(() => {
    if (!manufacturerID) {
      return;
    }
    const url = getURL(GLOBAL_MANUFACTURERS_GET_BY_ID, { manufacturer_id: manufacturerID });
    httpGet(url).then(res => {
      if (res.status === 200) {
        const manufacturer = res.data?.manufacturer;
        if (manufacturer) {
          setManufacturer({ label: manufacturer.name, value: manufacturer.id });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchableScrollableSelect
      autoFocus={false}
      initOption={manufacturer}
      onChange={option => setManufacturer(option)}
      fetchUrl={GLOBAL_MANUFACTURERS_FOR_DROPDOWN}
      optionMapper={manufacturer => ({ label: manufacturer.name, value: manufacturer.id })}
      disabled={disabled}
    />
  );
}

ManufacturerFilter.propTypes = {
  manufacturerID: PropTypes.number,
  manufacturer: PropTypes.object,
  setManufacturer: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ManufacturerFilter;
