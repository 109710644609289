import React from 'react';
import PropTypes from 'prop-types';

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import {
  grey,
  modernSalmon,
  modernSalmonBackground,
  positiveGreen,
  positiveGreenBackground,
} from 'lib/css/colors';

const getPercentageChange = percentageChange => {
  let displayPercentageChange;
  if (percentageChange > 0 || percentageChange < 0) {
    displayPercentageChange = percentageChange.toFixed(2) + '%';
  }
  return displayPercentageChange;
};

const getPeriod = dateDifference => {
  let periodConsidered;
  if (dateDifference === 7) {
    periodConsidered = 'this week';
  } else if (dateDifference === 30) {
    periodConsidered = 'this month';
  } else {
    periodConsidered = `${dateDifference} days`;
  }
  return periodConsidered;
};

function PercentageChange({ percentageChange, dateDifference }) {
  const displayPercentageChange = getPercentageChange(percentageChange);
  const arrowIcon = percentageChange > 0 ? faArrowUp : percentageChange < 0 ? faArrowDown : null;
  const periodConsidered = getPeriod(dateDifference);
  return (
    <div
      className={classNames(
        'px-3',
        css(
          styles.percentageChange,
          percentageChange && percentageChange > 0 ? styles.positivePercentageChange : null,
          percentageChange && percentageChange < 0 ? styles.negativePercentageChange : null,
        ),
      )}
    >
      {arrowIcon && <FontAwesomeIcon icon={arrowIcon} className="mr-3" />}
      {displayPercentageChange && `${displayPercentageChange} (${periodConsidered})`}
    </div>
  );
}

const styles = StyleSheet.create({
  percentageChange: {
    display: 'inline-block',
    color: grey,
    fontSize: 14,
    borderRadius: 10,
  },
  positivePercentageChange: {
    color: positiveGreen,
    backgroundColor: positiveGreenBackground,
  },
  negativePercentageChange: {
    color: modernSalmon,
    backgroundColor: modernSalmonBackground,
  },
});

PercentageChange.propTypes = {
  percentageChange: PropTypes.number,
  dateDifference: PropTypes.number,
};

export default PercentageChange;
