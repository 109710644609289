import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap';
import DropZone from 'components/shared/Dropzone';

function ImageUploadModal({
  onHide,
  onSubmit,
  header,
  imageUrls = null,
  setImageUrls = null,
  postBody = null,
}) {
  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6" centered animation={false}>
      <Modal.Header>
        <div className="d-flex flex-column">
          <Modal.Title className="mont">
            <h2 className="bold">{header}</h2>
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <DropZone
          text="Drag and drop images here, or click to select images"
          onUploadSuccess={newUrls => setImageUrls(newUrls)}
          fileUrls={imageUrls}
          isImage={true}
          isPublic={true}
        />
        <div className="my-3">
          <Button
            disabled={!imageUrls.length}
            className="px-5 mr-2"
            onClick={() => {
              if (postBody) {
                onSubmit({ ...postBody, image_urls: imageUrls });
              } else {
                onSubmit({ image_urls: imageUrls });
              }
            }}
          >
            Upload
          </Button>
          <Button variant="outline-primary mx-2" onClick={() => setImageUrls([])}>
            Clear Images
          </Button>
          <Button variant="outline-primary" onClick={() => onHide()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ImageUploadModal.propTypes = {
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  header: PropTypes.string,
  imageUrls: PropTypes.array.isRequired,
  setImageUrls: PropTypes.func.isRequired,
  postBody: PropTypes.object,
};

export default ImageUploadModal;
