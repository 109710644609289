import React, { useContext } from 'react';

import classNames from 'classnames';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';

import { AllProductsContext } from 'components/all_products/AllProductsContainer';
import AllProductsHeader from 'components/all_products/AllProductsHeader';
import AllProductsTable from 'components/all_products/AllProductsTable';
import PaginationComponent from 'components/shared/PaginationComponent';

const AllProductsView = () => {
  const {
    products,
    productCount,
    allProductsLoading,
    errorLoadingAllProducts,
    allProductsCountLoading,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    handleExportAllProducts,
    productsExporting,
  } = useContext(AllProductsContext);

  const updateItemsPerPage = count => {
    setItemsPerPage(count);
  };

  const updatePageNumber = pageNumber => {
    setCurrentPage(pageNumber);
  };

  if (errorLoadingAllProducts || !products) {
    return null;
  }

  return (
    <Container fluid className={classNames(css(styles.container), 'py-1 pr-1')}>
      <Row>
        <h3 className="mx-3 float-left font-weight-bold">All Products</h3>
        <Col className="d-flex align-items-center justify-content-end mr-2">
          <AllProductsHeader
            setCurrentPage={updatePageNumber}
            disabled={allProductsLoading}
            handleExportAllProducts={handleExportAllProducts}
            productsExporting={productsExporting}
          />
        </Col>
      </Row>
      <Row className="px-2">
        <Col className="p-2 d-flex align-items-center">
          {allProductsCountLoading ? (
            <Spinner animation="border" role="status" size="sm" className="ml-4" />
          ) : (
            <span className={classNames(css(styles.selectedText), 'text-secondary ml-2')}>
              {productCount} Products
            </span>
          )}
        </Col>
        <Col
          xs={8}
          className={classNames(
            css(styles.pagination),
            'w-100 d-flex justify-content-end px-2 py-1',
          )}
        >
          {allProductsCountLoading ? (
            <Spinner animation="border" role="status" size="sm" className="mr-4" />
          ) : (
            <PaginationComponent
              totalItems={productCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={updateItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={updatePageNumber}
              disabled={allProductsLoading}
            />
          )}
        </Col>
      </Row>
      <div className={css(styles.tableContainer)}>
        <AllProductsTable />
      </div>
    </Container>
  );
};

AllProductsView.displayName = 'AllProductsView';

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
    overflowX: 'hidden',
  },
  tableContainer: {
    height: '78vh',
    overflowY: 'scroll',
  },
  selectedText: {
    fontSize: '1rem',
  },
  pagination: {
    zIndex: 2,
  },
});

export default AllProductsView;
