import React, { useState } from 'react';

import { Container, Tab, Tabs } from 'react-bootstrap';
import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import NutritionalPanelUploadView from 'components/nutritional_panels/NutritionalPanelUploadView';
import UploadedNutritionalPanelView from 'components/nutritional_panels/UploadedNutritionalPanelView';
import useQueryString from 'lib/hooks/useQueryString';

const NUTRITIONAL_PANEL_VIEW_TABS = {
  UPLOADED_NUTRITIONAL_PANELS: 'Uploaded Nutritional Panels',
  NUTRITIONAL_PANELS_UPLOAD: 'Nutritional Panel Upload',
};

function NutritionalPanelView() {
  const tab = useQueryString().get('tab');
  const [activeTabKey, setActiveTabKey] = useState(
    tab || NUTRITIONAL_PANEL_VIEW_TABS.UPLOADED_NUTRITIONAL_PANELS,
  );

  return (
    <Container fluid className={classNames(css(styles.container), 'py-3')}>
      <div className="mb-3 d-flex justify-content-between">
        <h3 className="font-weight-bold">Nutritional Panels</h3>
      </div>
      <Tabs
        className="border-top-0"
        activeKey={activeTabKey}
        onSelect={selectedKey => setActiveTabKey(selectedKey)}
        transition={false}
      >
        <Tab
          eventKey={NUTRITIONAL_PANEL_VIEW_TABS.UPLOADED_NUTRITIONAL_PANELS}
          title={NUTRITIONAL_PANEL_VIEW_TABS.UPLOADED_NUTRITIONAL_PANELS}
        >
          <UploadedNutritionalPanelView />
        </Tab>
        <Tab
          eventKey={NUTRITIONAL_PANEL_VIEW_TABS.NUTRITIONAL_PANELS_UPLOAD}
          title={NUTRITIONAL_PANEL_VIEW_TABS.NUTRITIONAL_PANELS_UPLOAD}
        >
          <NutritionalPanelUploadView />
        </Tab>
      </Tabs>
    </Container>
  );
}

const styles = StyleSheet.create({
  container: {
    minHeight: '50vh',
  },
});

export default NutritionalPanelView;
export { NUTRITIONAL_PANEL_VIEW_TABS };
