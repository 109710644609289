import React, { useState } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { css, StyleSheet } from 'aphrodite';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SplitPane from 'react-split-pane';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import ActionDropDown from 'components/shared/ActionDropDown';
import { areYouSure } from 'lib/utils/toast';
import LogoUpdateModal from 'components/shared/LogoUpdateModal';
import ManufacturerMenuSplitView from 'components/manage_manufacturers/manufacturer_details/ManufacturerMenuSplitView';
import ManufacturerLogoSplitView from 'components/manage_manufacturers/manufacturer_details/ManufacturerLogoSplitView';
import Container from 'components/shared/Container';
import {
  getURL,
  GLOBAL_MANUFACTURERS_BROKEN_IMAGE_DELETE,
  GLOBAL_MANUFACTURERS_CONVERT_TO_DISTRIBUTOR,
  GLOBAL_MANUFACTURERS_GET_BY_ID,
  GLOBAL_MANUFACTURERS_UPDATE_FROM_FSA,
} from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import UserPermission from 'lib/enums/UserPermission';
import useUserPermissions from 'lib/hooks/useUserPermissions';

function ManufacturerDetailsView() {
  const { manufacturerID } = useParams();
  const history = useHistory();
  const hasUserPermissions = useUserPermissions();

  const [showManufacturerLogoModal, setShowManufacturerLogoModal] = useState(false);

  const {
    data: { manufacturer } = {},
    loading,
    error,
    refetch,
  } = useGet(
    getURL(GLOBAL_MANUFACTURERS_GET_BY_ID, { manufacturer_id: manufacturerID }),
    {},
    !manufacturerID,
  );

  const { postData: updateManufacturer, loading: updateLoading } = usePost(
    GLOBAL_MANUFACTURERS_UPDATE_FROM_FSA,
    () => {
      SwalDialog('success', 'Successfully updated manufacturer', 'Success', 'center', refetch());
    },
    error => {
      SwalDialog(
        'error',
        `An error occurred while updating the manufacturer: ${error?.response?.data?.message}`,
        'Error',
        'center',
      );
    },
  );

  const { postData: deleteBrokenImages } = usePost(
    getURL(GLOBAL_MANUFACTURERS_BROKEN_IMAGE_DELETE, { manufacturer_id: manufacturerID }),
    () =>
      Swal.fire({
        title: 'Success',
        text: `Started removing broken image links of ${manufacturer.name}.`,
        icon: 'success',
      }),
    error =>
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        icon: 'error',
      }),
  );

  const { postData: convertToDistributor } = usePost(
    getURL(GLOBAL_MANUFACTURERS_CONVERT_TO_DISTRIBUTOR, { manufacturer_id: manufacturerID }),
    () =>
      Swal.fire({
        title: 'Success',
        text: `Started converting products of ${manufacturer.name} to distibutor products.`,
        icon: 'success',
      }),
    error =>
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.message || 'Something went wrong, please try again.',
        icon: 'error',
      }),
  );

  if (loading || updateLoading) {
    return <LoadingSpinner />;
  }

  if (error || !manufacturer) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Col xs={4}>
          <h3 className="font-weight-bold">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mr-2')}
              icon={faArrowCircleLeft}
              onClick={() => history.push('/enrich-products/manage-manufacturers')}
            />
            All Manufacturers
          </h3>
        </Col>
        <Col className="d-flex mr-5 justify-content-end">
          <ActionDropDown
            submenuItems={[
              {
                title: 'Remove Broken images',
                action: () => deleteBrokenImages(),
              },
              {
                title: 'Convert manufacturer products to distributor products',
                action: () =>
                  areYouSure(
                    () => convertToDistributor(),
                    'Are you sure you want to covert connected products to distributor products?',
                  ),
                disabled: !hasUserPermissions([UserPermission.CONVERT_MANUFACTURER_PRODUCTS]),
              },
            ]}
          />
        </Col>
      </Row>
      <SplitPane
        primary="first"
        split="vertical"
        defaultSize="25%"
        className={css(styles.splitPane)}
        allowResize={false}
      >
        <ManufacturerLogoSplitView
          manufacturer={manufacturer}
          updateManufacturer={updateManufacturer}
          setShowManufacturerLogoModal={setShowManufacturerLogoModal}
        />
        <ManufacturerMenuSplitView
          manufacturer={manufacturer}
          updateManufacturer={updateManufacturer}
          refetch={refetch}
        />
      </SplitPane>
      {showManufacturerLogoModal ? (
        <LogoUpdateModal
          product={manufacturer}
          update={updateManufacturer}
          onHide={() => {
            setShowManufacturerLogoModal(false);
            refetch();
          }}
          loading={updateLoading}
        />
      ) : null}
    </Container>
  );
}

const styles = StyleSheet.create({
  splitPane: {
    position: 'relative',
    minHeight: '80vh',
  },
});

export default ManufacturerDetailsView;
