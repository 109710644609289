import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { faArrowCircleLeft, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import Swal from 'sweetalert2';
import { useDebounce } from 'use-debounce';
import { useHistory, useParams } from 'react-router-dom';

import Container from 'components/shared/Container';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import EditableFieldV2 from 'components/shared/EditableFieldV2';
import { getURL, PRODUCT_LIST_ID, PRODUCT_LIST } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import ProductListFiltersSection from 'components/product_lists/product_list/filters/ProductListFiltersSection';
import ProductListTable from 'components/product_lists/product_list/ProductListProductsTable';
import ProductListScoreView from 'components/product_lists/product_list/ProductListScoreView';
import useGet from 'lib/hooks/useGet';
import usePut from 'lib/hooks/usePut';

function ProductListView() {
  const { productListId } = useParams();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [showFilters, setShowFilters] = useState(false);
  const [showCatalogView, setShowCatalogView] = useState(false);
  const [skuQuery, setSkuQuery] = useState('');
  const [skuDebouncedQuery] = useDebounce(skuQuery, 500);

  const {
    data: { data: productListCount } = {},
    loading: loadingCount,
    refetch: refetchCount,
  } = useGet(
    getURL(PRODUCT_LIST, {
      product_list_id: productListId,
    }),
    {
      count_only: true,
      sku_query_string: skuDebouncedQuery,
    },
    !productListId,
  );

  const {
    data: { data: productList } = {},
    loading,
    error,
    refetch,
  } = useGet(
    getURL(PRODUCT_LIST, {
      product_list_id: productListId,
    }),
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      sku_query_string: skuDebouncedQuery,
      with_propagated_thumbnail: true,
    },
    !productListId,
  );

  const { putData: updateProductList } = usePut(
    getURL(PRODUCT_LIST_ID, { product_list_id: productListId }),
    () => {
      Swal.fire({
        title: 'Success',
        text: 'Successfully updated.',
        icon: 'success',
      });
    },
    error =>
      Swal.fire({
        title: 'Error',
        text: 'Something went wrong, please try again.',
        icon: 'error',
      }),
  );

  useEffect(() => {
    if (productList?.filters !== undefined && isEmpty(productList?.filters)) {
      setShowFilters(true);
    }
  }, [productList]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !productList) {
    return null;
  }

  const products = productList?.products || [];
  const productCount = productListCount?.product_count || 0;

  return (
    <Container>
      <h3 className="font-weight-bold">
        <Row className="my-3">
          <Col md={6} className="ml-2">
            <FontAwesomeIcon
              className={classNames(pointerOnHover, 'mt-1 mr-2 float-left')}
              icon={faArrowCircleLeft}
              onClick={() => {
                history.push('/product-lists');
              }}
            />
            <EditableFieldV2
              className="float-right"
              value={productList?.name}
              setValue={e => updateProductList({ name: e })}
              view_as_plain_text={true}
            />
          </Col>
        </Row>
      </h3>
      <ProductListScoreView productListId={productListId} />
      <Row>
        <Col>
          {isEmpty(productList?.filters) ? (
            <div className="d-flex flex-column align-items-center justify-content-center my-5 py-5">
              <FontAwesomeIcon size="3x" icon={faQuestionCircle} />
              <h4 className="my-3">Start applying filters to populate the list.</h4>
            </div>
          ) : (
            <ProductListTable
              products={products}
              productCount={productCount}
              productList={productList}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              showCatalogView={showCatalogView}
              setShowCatalogView={setShowCatalogView}
              skuQuery={skuQuery}
              setSkuQuery={setSkuQuery}
              loading={loading}
              loadingCount={loadingCount}
            />
          )}
        </Col>
        {showFilters ? (
          <Col md={4} className="border-left">
            <ProductListFiltersSection
              productListId={productListId}
              filters={productList?.filters}
              refetch={() => {
                refetch();
                refetchCount();
              }}
            />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
}

export default ProductListView;
