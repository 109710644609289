import React, { createContext, useState } from 'react';

import { Route } from 'react-router-dom';

import SingleDataStreamView from 'components/data_streams/SingleDataStreamView';

const SingleDataStreamViewContext = createContext({});

function SingleDataStreamViewContainer() {
  const [attributes, setAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState(addUniqueKey([]));
  const [isAGlobalAttribute, setIsAGlobalAttribute] = useState();

  function addUniqueKey(objects) {
    return objects.map(obj => ({
      ...obj,
      uniqueKey: `${obj.id}_${obj.attributeName}_${obj.isGlobalAttribute}`,
    }));
  }

  return (
    <SingleDataStreamViewContext.Provider
      value={{
        attributes,
        setAttributes,
        selectedAttributes,
        setSelectedAttributes,
        isAGlobalAttribute,
        setIsAGlobalAttribute,
      }}
    >
      <Route path="/data-streams/:record_id" exact>
        <SingleDataStreamView />
      </Route>
    </SingleDataStreamViewContext.Provider>
  );
}

export default SingleDataStreamViewContainer;
export { SingleDataStreamViewContext };
