import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

import { fontStyles, subNutritionRowStyles } from 'components/shared/nutrition_facts/sharedStyles';
import Separator from 'components/shared/nutrition_facts/Separator';
import { updateAttributeValueByName } from 'components/nutritional_panels/ProductNutritionFactsForEdit';

function EditSubNutritionRow({
  title,
  quantity,
  uom,
  percentage,
  offset = 0,
  skipSeparator = false,
  separatorOffset = 0,
  setNutritionAttributes,
  quantityAttribute,
  uomAttribute,
  percentageAttribute,
}) {
  return (
    <>
      <div className="w-100 d-flex justify-content-between">
        <div
          className={classNames(
            css(subNutritionRowStyles(offset).titleContainer),
            fontStyles('16px'),
            'd-flex gap-2 my-1',
          )}
        >
          <span>{title}</span>
          <input
            type="text"
            value={quantity || ''}
            className={`form-control form-control-sm mx-1 ${css(styles.input)}`}
            placeholder="Quantity"
            onChange={e =>
              updateAttributeValueByName(setNutritionAttributes, quantityAttribute, e.target.value)
            }
          />
          <input
            type="text"
            value={uom || ''}
            className={`form-control form-control-sm mr-1 ${css(styles.input)}`}
            placeholder="Uom"
            onChange={e =>
              updateAttributeValueByName(setNutritionAttributes, uomAttribute, e.target.value)
            }
          />
        </div>
        {percentageAttribute && (
          <div className="d-flex my-1">
            <input
              type="text"
              value={percentage || ''}
              className={`form-control form-control-sm ${css(styles.input)}`}
              placeholder="Percentage"
              onChange={e =>
                updateAttributeValueByName(
                  setNutritionAttributes,
                  percentageAttribute,
                  e.target.value,
                )
              }
            />
            %
          </div>
        )}
      </div>
      {!skipSeparator && <Separator offset={separatorOffset} />}
    </>
  );
}

const styles = StyleSheet.create({
  input: {
    width: '50px',
    textAlign: 'center',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
});

EditSubNutritionRow.propTypes = {
  title: PropTypes.node.isRequired,
  quantity: PropTypes.string,
  uom: PropTypes.string,
  percentage: PropTypes.string,
  offset: PropTypes.number,
  skipSeparator: PropTypes.bool,
  separatorOffset: PropTypes.number,
  setNutritionAttributes: PropTypes.func,
  quantityAttribute: PropTypes.string,
  uomAttribute: PropTypes.string,
  percentageAttribute: PropTypes.string,
};

export default EditSubNutritionRow;
