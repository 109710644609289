import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import { Button, Col, Form, Image, Modal, Row, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { PRODUCT_LIST_UPDATE_PRODUCTS } from 'lib/networking/endpoints';
import BrandFilter from 'components/shared/BrandFilter';
import placeholderProductImage from 'images/placeholder-img-product-v2.svg';
import RecommendedImagesTabView from 'components/product_lists/product_list/qa_product_list/RecommendedImagesTabView';
import usePost from 'lib/hooks/usePost';

function QAProductModal({ product, show, onHide, refetch }) {
  const [selectedThumbnailId, setSelectedThumbnailId] = useState();
  const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState(
    product?.propagated_thumbnail_image_url,
  );
  const [uploadedImages, setUploadedImages] = useState([]);
  const [packSize, setPackSize] = useState(product?.propagated_pack_size);
  const [brand, setBrand] = useState({ label: product?.propagated_brand });

  const { postData: updateProductDetails, loading: updating } = usePost(
    PRODUCT_LIST_UPDATE_PRODUCTS,
    () => {
      Swal.fire({
        icon: 'success',
        text: 'Product details updated successfully',
        title: 'Success',
        timer: 500,
      }).then(() => {
        onHide();
        refetch();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        text: `An error occurred while updating product details: ${error?.response?.data?.message}`,
        title: 'Error',
      }),
  );

  return (
    <Modal animation={false} centered size="xl" show={show} onHide={onHide}>
      <div>
        <Modal.Header className={css(styles.modalHeader)} closeButton />
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Image
                className={classNames(css(styles.image), 'mx-auto d-block')}
                fluid
                src={selectedThumbnailUrl ?? placeholderProductImage}
              />
            </Col>
            <Col>
              <Row className="mb-4">
                <Col>
                  <h4>
                    <strong>{product.product_name}</strong>
                  </h4>
                  <div>SKU #{product.sku}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="my-3">
                    <Col>
                      <h6>
                        <strong>Manufacturer</strong>
                      </h6>
                      <p>{product.manufacturer || '-'}</p>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <h6>
                        <strong>GTIN</strong>
                      </h6>
                      <p>{product.gtin_item || product.gtin_unknown || '-'}</p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row className="my-3">
                    <Col>
                      <h6>
                        <strong>Pack Size</strong>
                      </h6>
                      <Form.Control
                        type="text"
                        value={packSize}
                        onChange={e => setPackSize(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col>
                      <h6>
                        <strong>Brand</strong>
                      </h6>
                      <BrandFilter brand={brand} setBrand={setBrand} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <RecommendedImagesTabView
            l4CategoryId={product.l4_category_id}
            selectedThumbnailId={selectedThumbnailId}
            setSelectedThumbnailId={setSelectedThumbnailId}
            uploadedImages={uploadedImages}
            setUploadedImages={setUploadedImages}
            selectedThumbnailUrl={selectedThumbnailUrl}
            setSelectedThumbnailUrl={setSelectedThumbnailUrl}
            brandId={brand.value}
            hasOtherImages={product.has_other_images}
            productId={product.product_id}
            productType={product.product_type}
          />
          <div className="my-3">
            <Button
              onClick={() =>
                updateProductDetails({
                  product_id: product.product_id,
                  product_type: product.product_type,
                  pack_size: packSize,
                  thumbnail_id: selectedThumbnailId,
                  thumbnail_url: selectedThumbnailUrl,
                  uploaded_images: uploadedImages,
                  brand_id: brand.value,
                })
              }
              size="lg"
              className="mr-2"
              disabled={updating}
            >
              {updating ? <Spinner animation="border" role="status" size="sm" /> : 'Update'}
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    borderBottom: 'none',
  },
  image: {
    maxHeight: '230px',
  },
  recommendationsContainer: {
    height: '420px',
    overflowY: 'auto',
  },
});

QAProductModal.propTypes = {
  product: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  refetch: PropTypes.func,
};

export default QAProductModal;
