import React, { useState } from 'react';

import { ceil } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';

import ActivityLogFilters from 'components/settings/activity_logs/ActivityLogFilters';
import ActivityLogSummary from 'components/settings/activity_logs/ActivityLogSummary';
import ActivityLogsTableView from 'components/settings/activity_logs/ActivityLogsTableView';
import { ACTIVITY_LOGS_FIND } from 'lib/networking/endpoints';
import { ALL_SELECT_OPTION } from 'lib/constants';
import Container from 'components/shared/Container';
import PaginationBar from 'components/shared/PaginationBar';
import useGet from 'lib/hooks/useGet';

const DEFAULT_PAGE_SIZE = 50;
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

function ActivityLogsView() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedActivityType, setSelectedActivityType] = useState(ALL_SELECT_OPTION);
  const [startDate, setStartDate] = useState(dayjs().startOf('month').toDate());
  const [endDate, setEndDate] = useState(dayjs().endOf('day').toDate());

  const { data: { data: activity_logs = [], total_count } = {}, loading } = useGet(
    ACTIVITY_LOGS_FIND,
    {
      limit: DEFAULT_PAGE_SIZE,
      offset: (currentPage - 1) * DEFAULT_PAGE_SIZE,
      user_id: selectedUser?.value,
      activity_log_type: selectedActivityType?.value,
      start_date: dayjs(startDate).format(DATE_TIME_FORMAT),
      end_date: dayjs(endDate).format(DATE_TIME_FORMAT),
    },
  );

  return (
    <Container>
      <div className="pb-3">
        <Row>
          <Col>
            <h3 className="font-weight-bold">Activity Logs</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <ActivityLogFilters
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              selectedActivityType={selectedActivityType}
              setSelectedActivityType={setSelectedActivityType}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ActivityLogSummary total_count={total_count} activity_logs={activity_logs} />
          </Col>
          <Col sm={4} className="d-flex justify-content-end">
            <PaginationBar
              totalPages={ceil(total_count / DEFAULT_PAGE_SIZE)}
              currentPageNumber={currentPage - 1}
              onPageChange={newPage => setCurrentPage(newPage + 1)}
            />
          </Col>
        </Row>
        <Row>
          <Col style={{ maxHeight: '70vh', overflowY: 'auto' }}>
            <div>
              <ActivityLogsTableView activityLogs={activity_logs} loading={loading} />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
}

export default ActivityLogsView;
