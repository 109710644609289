import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';
import { Form } from 'react-bootstrap';
import Select from 'react-select/creatable';

import { GLOBAL_VENDORS_FOR_DROPDOWN } from 'lib/networking/endpoints';
import { P0SyncAttributeOptions } from 'lib/enums/P0Attributes';
import SearchableScrollableMultiSelect from 'components/shared/SearchableScrollableMultiSelect';
import { VendorCategoryOptions } from 'lib/enums/VendorCategory';

const EXCLUDED_CND_FIELDS_OPTIONS = [{ label: 'status', value: 'status' }];

function EditableProperty({ label, vendor, setIsEditing, setUpdatedVendor }) {
  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (!vendor[label]) {
      return [];
    }

    let selectedValues = null;
    if (label !== 'chains') {
      selectedValues = vendor[label] instanceof Array ? vendor[label] : vendor[label].split(',');
    }

    const optionsForSelectedValues = () => {
      if (label === 'excluded_p0_sync_attributes') {
        return P0SyncAttributeOptions.filter(option => selectedValues.includes(option.value));
      } else if (label === 'rejected_brand_names') {
        return selectedValues.map(val => ({ label: val, value: val }));
      } else if (label === 'chains') {
        return vendor[label].map(val => ({ label: val.name, value: val.id }));
      } else if (label === 'excluded_cnd_sync_fields') {
        return EXCLUDED_CND_FIELDS_OPTIONS.filter(option => selectedValues.includes(option.value));
      } else if (label === 'vendor_category') {
        return VendorCategoryOptions.filter(option => selectedValues.includes(option.value));
      }
    };
    return optionsForSelectedValues();
  });

  const handleUpdateVendor = (property, value) => {
    setUpdatedVendor(prevVendor => ({
      ...prevVendor,
      [property]: value,
    }));
  };

  const handleSelectChange = selectedOptions => {
    setSelectedOptions(selectedOptions);
    if (label === 'chains') {
      const selectedValues = selectedOptions.map(option => ({
        name: option.label,
        id: option.value,
      }));
      handleUpdateVendor(label, selectedValues);
    } else if (label === 'vendor_category') {
      handleUpdateVendor(label, selectedOptions?.value || '');
    } else {
      const selectedValuesString = selectedOptions.map(option => option.value).join(',');
      handleUpdateVendor(label, selectedValuesString);
    }
  };

  const optionsMap = {
    excluded_p0_sync_attributes: P0SyncAttributeOptions,
    excluded_cnd_sync_fields: EXCLUDED_CND_FIELDS_OPTIONS,
    rejected_brand_names: [],
    vendor_category: VendorCategoryOptions,
  };

  return [
    'excluded_p0_sync_attributes',
    'excluded_cnd_sync_fields',
    'rejected_brand_names',
    'vendor_category',
  ].includes(label) ? (
    <Select
      className={css(styles.select)}
      autoFocus
      options={optionsMap[label]}
      isMulti={label !== 'vendor_category'}
      value={selectedOptions}
      onChange={handleSelectChange}
      isClearable={label === 'vendor_category'}
    />
  ) : 'chains' === label ? (
    <SearchableScrollableMultiSelect
      className={css(styles.select)}
      onChange={handleSelectChange}
      fetchUrl={GLOBAL_VENDORS_FOR_DROPDOWN}
      optionMapper={vendor => ({ label: vendor.name, value: vendor.id })}
      initOptions={selectedOptions}
    />
  ) : (
    <Form.Control
      className={css(styles.form)}
      autoFocus
      defaultValue={vendor[label]}
      onChange={event => handleUpdateVendor(label, event.target.value)}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          setIsEditing(false);
        }
      }}
    />
  );
}

const styles = StyleSheet.create({
  select: {
    flexGrow: 0.96,
  },
  form: {
    height: 'auto',
    marginRight: '.6rem',
  },
});

EditableProperty.propTypes = {
  label: PropTypes.string,
  vendor: PropTypes.object,
  setIsEditing: PropTypes.func,
  setUpdatedVendor: PropTypes.func,
};

export default EditableProperty;
