import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { DEFAULT_THRESHOLD_VALUE_FOR_AI_TASKS } from 'lib/constants';
import { PRODUCT_OUTLIERS } from 'lib/networking/endpoints';
import usePost from 'lib/hooks/usePost';

function ProductOutlierThresholdModal({ productListId, onHide }) {
  const [threshold, setThreshold] = useState(DEFAULT_THRESHOLD_VALUE_FOR_AI_TASKS);

  const { postData: findProductOutliers } = usePost(
    PRODUCT_OUTLIERS,
    () => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Started sending data to find product outliers.',
      }).then(() => {
        onHide();
      });
    },
    error =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `An error occurred while sending product data: ${error?.response?.data?.message}`,
      }),
  );

  return (
    <Modal animation={false} centered show={true} onHide={onHide}>
      <div>
        <Modal.Body>
          <Row className="mt-3">
            <Col>
              <h6>
                <strong>Threshold</strong>
              </h6>
              <Form.Control
                type="text"
                value={threshold}
                onChange={e => setThreshold(e.target.value)}
              />
            </Col>
          </Row>
          <hr />
          <div>
            <Button
              onClick={() =>
                findProductOutliers({
                  product_list_id: productListId,
                  threshold: threshold,
                })
              }
              size="lg"
              className="mr-2"
            >
              Send Data
            </Button>
            <Button size="lg" variant="outline-primary" onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

ProductOutlierThresholdModal.propTypes = {
  productListId: PropTypes.number,
  onHide: PropTypes.func,
};

export default ProductOutlierThresholdModal;
