import React from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from 'components/shared/LoadingSpinner';
import ProductNutritionFactsView from 'components/upb_viewer/pdp_components/ProductNutritionFactsView';
import ProductNutritionFactsForEdit from 'components/nutritional_panels/ProductNutritionFactsForEdit';

function RecreatedNutritionalPanelView({
  nutritionAttributes = [],
  setNutritionAttributes,
  editPanel = false,
  panelLoading,
}) {
  if (panelLoading) {
    return <LoadingSpinner short />;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <p>
          <strong>Recreated Nutritional Panel</strong>
        </p>
      </div>
      {nutritionAttributes?.length ? (
        editPanel ? (
          <ProductNutritionFactsForEdit
            nutritionAttributes={nutritionAttributes}
            setNutritionAttributes={setNutritionAttributes}
          />
        ) : (
          <ProductNutritionFactsView nutritionAttributes={nutritionAttributes} />
        )
      ) : (
        'OCR processed file not available for this product'
      )}
    </>
  );
}

RecreatedNutritionalPanelView.propTypes = {
  nutritionAttributes: PropTypes.array,
  setNutritionAttributes: PropTypes.func,
  editPanel: PropTypes.bool,
  panelLoading: PropTypes.bool,
};

export default RecreatedNutritionalPanelView;
