import React from 'react';
import PropTypes from 'prop-types';

import { css, StyleSheet } from 'aphrodite';

import { fontStyles } from 'components/shared/nutrition_facts/sharedStyles';
import { NUTRITION_ATTRIBUTES } from 'components/upb_viewer/pdp_components/ProductNutritionFactsView';
import { updateAttributeValueByName } from 'components/nutritional_panels/ProductNutritionFactsForEdit';

function CaloriesSection({ calories, setNutritionAttributes }) {
  return (
    <div className="py-1">
      <div className={fontStyles('12px', true, true)}>Amount per serving</div>
      <div className="w-100 d-flex justify-content-between align-items-end">
        <div className={fontStyles('32px', true, true)}>Calories</div>
        <input
          id="servingsPerPackage"
          type="text"
          value={calories}
          className={`form-control form-control-sm ${css(styles.input)}`}
          onChange={e =>
            updateAttributeValueByName(
              setNutritionAttributes,
              NUTRITION_ATTRIBUTES.CALORIES_QUANTITYCONTAINED,
              e.target.value,
            )
          }
        />
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  input: {
    width: '60px',
    textAlign: 'center',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
});

CaloriesSection.propTypes = {
  calories: PropTypes.string,
  setNutritionAttributes: PropTypes.func,
};

export default CaloriesSection;
