import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Table } from 'react-bootstrap';
import { convertToTitleCase } from 'lib/utils/stringUtils';
import { css, StyleSheet } from 'aphrodite';

import { backgroundGrey } from 'lib/css/colors';
import Container from 'components/shared/Container';
import pointerOnHover from 'lib/css/pointerOnHover';

function ManageDuplicateBrandsTable({
  brands,
  mergeList,
  setMergeList,
  setBrandEditModalSettings,
}) {
  return (
    <Container>
      <Table className={css(styles.tableText)}>
        <thead className={css(styles.thead)}>
          <tr>
            <th>
              <Form.Check
                type="checkbox"
                checked={brands?.length > 0 && mergeList?.length === brands?.length}
                onChange={e => {
                  if (e.target.checked) {
                    setMergeList([...brands]);
                  } else {
                    setMergeList([]);
                  }
                }}
              />
            </th>
            <th>Brand Name</th>
            <th>GLN</th>
            <th>Manufacturer</th>
            <th># of Products</th>
            <th>Status</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody className={css(styles.tbody)}>
          {brands?.length ? (
            brands?.map((brand, index) => (
              <tr key={index}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={mergeList?.some(mergeBrand => mergeBrand?.id === brand.id)}
                    onChange={e => {
                      if (e.target.checked) {
                        if (!mergeList.some(mergeBrand => mergeBrand.id === brand.id)) {
                          setMergeList([...mergeList, brand]);
                        }
                      } else {
                        setMergeList(mergeList.filter(mergeBrand => mergeBrand.id !== brand.id));
                      }
                    }}
                  />
                </td>
                <td>{brand.name}</td>
                <td>{brand.global_manufacturer?.manufacturer_gln || '-'}</td>
                <td>{brand.global_manufacturer?.name || '-'}</td>
                <td>{brand.product_count}</td>
                <td>{convertToTitleCase(brand.verification_status)}</td>
                <td>
                  <Button
                    variant="link"
                    key={brand?.id}
                    className={pointerOnHover}
                    onClick={e => {
                      e.stopPropagation();
                      setBrandEditModalSettings({ show: true, brand: brand });
                    }}
                  >
                    Edit
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    key={brand?.id}
                    className={pointerOnHover}
                    onClick={() =>
                      window.open(`/enrich-products/brand-details/${brand.id}`, '_blank')
                    }
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="100%" className="text-center">
                No similar brands found.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const styles = StyleSheet.create({
  tableText: {
    fontSize: '0.95rem',
  },
  thead: {
    position: 'sticky',
    top: 0,
    backgroundColor: backgroundGrey,
  },
  tbody: {
    zIndex: 0,
    position: 'relative',
  },
});

ManageDuplicateBrandsTable.propTypes = {
  brands: PropTypes.array,
  mergeList: PropTypes.array,
  setMergeList: PropTypes.func,
  setBrandEditModalSettings: PropTypes.func,
};

export default ManageDuplicateBrandsTable;
