import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Table } from 'react-bootstrap';
import classNames from 'classnames';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import Swal from 'sweetalert2';

import ActionDropDown from 'components/shared/ActionDropDown';
import AdvancedAttributeMappingModal from 'components/data_sources/single_data_source_view/AdvancedAttributeMappingModal';
import { ATTRIBUTES_FOR_DATA_SOURCE_MAPPING, DATA_SOURCE_UPDATE } from 'lib/networking/endpoints';
import Container from 'components/shared/Container';
import { DATA_SOURCE_PREPROCESS_EXPORT } from 'lib/networking/endpoints';
import DataSourcePendingAttributesTableRow from 'components/pending_attribute_mapping/DataSourcePendingAttributesTableRow';
import isAdvancedMapping from 'lib/utils/isAdvancedMapping';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import pointerOnHover from 'lib/css/pointerOnHover';
import URLField from 'components/data_sources/single_data_source_view/URLField';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';
import usePut from 'lib/hooks/usePut';

function DataSourcePendingAttributeTable({ dataSource, refetch }) {
  const [showAdvancedMappingModal, setShowAdvancedMappingModal] = useState(false);
  const [unmappedAttributes, setUnmappedAttributes] = useState([]);
  const { data: { data: attributes } = {}, loading } = useGet(ATTRIBUTES_FOR_DATA_SOURCE_MAPPING);
  const { putData: updateDataSource, loading: updating } = usePut(DATA_SOURCE_UPDATE, () => {
    refetch();
  });

  const attributeOptions = useMemo(() => {
    if (!attributes?.length) {
      return [];
    }
    return attributes.map(attribute => ({ value: attribute, label: attribute }));
  }, [attributes]);

  const [unsavedAttributeMap, setUnsavedAttributeMap] = useState(dataSource.attribute_map || {});
  const [sortedUnsavedAttributeMap, setSortedUnsavedAttributeMap] = useState({});

  useEffect(() => {
    // sort unsavedAttributeMap by whether it is an advanced mapping or not
    const advancedMappings = {};
    const sortedUnsavedAttributes = {};

    Object.keys(unsavedAttributeMap).forEach(sourceAttribute => {
      if (isAdvancedMapping(sourceAttribute)) {
        advancedMappings[sourceAttribute] = unsavedAttributeMap[sourceAttribute];
      } else {
        sortedUnsavedAttributes[sourceAttribute] = unsavedAttributeMap[sourceAttribute];
      }
    });

    if (dataSource.unmapped_attributes) {
      const unmappedUnsavedAdvancedAttributes = Object.keys(unsavedAttributeMap).filter(
        attribute => {
          return (
            isAdvancedMapping(attribute) &&
            dataSource.unmapped_attributes.some(
              unmapped_attr => attribute.indexOf(unmapped_attr.source_attribute_name) > -1,
            )
          );
        },
      );
      setUnmappedAttributes([
        ...dataSource.unmapped_attributes.map(attribute => attribute.source_attribute_name),
        ...unmappedUnsavedAdvancedAttributes,
      ]);
    }

    setSortedUnsavedAttributeMap({ ...sortedUnsavedAttributes, ...advancedMappings });
  }, [dataSource, unsavedAttributeMap]);

  const { postData: exportPreprocessedFile } = usePost(
    DATA_SOURCE_PREPROCESS_EXPORT,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () =>
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while trying to export the file',
      }),
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  const attributeMap = dataSource.attribute_map || {};

  return (
    <Container>
      <div className="mb-3 w-100 d-flex justify-content-end">
        <span className="mr-3">
          <URLField dataSourceId={dataSource.id} label="Download source file" />
        </span>
        <Button
          className="px-5 mr-3"
          variant="outline-primary"
          disabled={isEqual(attributeMap, unsavedAttributeMap) || updating}
          onClick={() => {
            updateDataSource({
              data_source_id: dataSource.id,
              attribute_map: unsavedAttributeMap,
              unmapped_attributes: unmappedAttributes,
            });
          }}
        >
          Save
        </Button>

        <ActionDropDown
          submenuItems={[
            {
              title: 'Export preprocessed file (csv)',
              action: () =>
                exportPreprocessedFile({ data_source_id: dataSource.id, file_format: 'csv' }),
            },
            {
              title: 'Export preprocessed file (xlsx)',
              action: () =>
                exportPreprocessedFile({ data_source_id: dataSource.id, file_format: 'xlsx' }),
            },
          ]}
        />
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>Unmapped Source Attribute</th>
            <th />
            <th>FSA Attribute</th>
          </tr>
        </thead>
        <tbody>
          {unmappedAttributes.map((sourceAttribute, idx) => (
            <DataSourcePendingAttributesTableRow
              key={idx}
              sourceAttribute={sourceAttribute}
              sortedUnsavedAttributeMap={sortedUnsavedAttributeMap}
              setUnsavedAttributeMap={setUnsavedAttributeMap}
              attributeOptions={attributeOptions}
            />
          ))}
        </tbody>
      </Table>
      <div
        className={classNames('d-flex align-items-center', pointerOnHover)}
        onClick={() => setShowAdvancedMappingModal(true)}
      >
        <FontAwesomeIcon icon={faPlusSquare} size="2x" />
        <span className="ml-2">Advanced Mapping</span>
      </div>
      {showAdvancedMappingModal && (
        <AdvancedAttributeMappingModal
          onHide={() => setShowAdvancedMappingModal(false)}
          attributeOptions={attributeOptions}
          attributeMap={attributeMap}
          unsavedAttributeMap={sortedUnsavedAttributeMap}
          setUnsavedAttributeMap={setUnsavedAttributeMap}
        />
      )}
    </Container>
  );
}

DataSourcePendingAttributeTable.propTypes = {
  dataSource: PropTypes.object,
  refetch: PropTypes.func,
};

export default DataSourcePendingAttributeTable;
