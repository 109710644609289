import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select/creatable';
import ToggleSwitch from 'react-switch';

import { ATTRIBUTE_INPUT_TYPE_LIST } from 'lib/enums/AttributeInputType';
import { AttributePriorityOptions } from 'lib/enums/AttributePriorityLevel';
import {
  ATTRIBUTES_SECTIONS,
  ATTRIBUTES_SUB_SECTIONS_FOR_SECTION,
  getURL,
} from 'lib/networking/endpoints';
import { blackberry, chartreuse } from 'lib/css/colors';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';

function EditAttributeModal({ onHide, modalHeader, updateAttribute, details }) {
  const [updatedAttribute, setUpdatedAttribute] = useState({
    attributeName: details.attributeName,
    attributeDisplayName: details.attributeDisplayName,
    isMandatory: details.isMandatory,
    isVisible: details.isVisible,
    isAKeyAttribute: details.isAKeyAttribute,
    inputType: { label: details.inputType, value: details.inputType },
    priorityLevel: AttributePriorityOptions.find(op => op.label === details.priorityLevel),
    subSection: { label: details.subSectionName, value: details.subSectionId },
    section: { label: details.sectionName, value: details.sectionId },
  });

  const { data: { data: sections = [] } = [], sectionsLoading } = useGet(ATTRIBUTES_SECTIONS, {});

  const { data: { data: subSections = [] } = [] } = useGet(
    getURL(ATTRIBUTES_SUB_SECTIONS_FOR_SECTION, {
      attribute_section_id: updatedAttribute.section?.value,
    }),
    {},
    !updatedAttribute.section,
  );

  if (sectionsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Modal show={true} onHide={onHide} size="md" className="px-6 font-weight-bold" centered>
      <Modal.Header>
        <h5 className="px-2 font-weight-bold">{modalHeader}</h5>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={updatedAttribute.attributeName || ''}
            onChange={e =>
              setUpdatedAttribute({ ...updatedAttribute, attributeName: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Display Name</Form.Label>
          <Form.Control
            type="text"
            value={updatedAttribute.attributeDisplayName || ''}
            onChange={e =>
              setUpdatedAttribute({ ...updatedAttribute, attributeDisplayName: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Select
            value={updatedAttribute.section}
            options={sections.map(section => ({
              label: section.attribute_section_name,
              value: section.id,
            }))}
            onChange={e => setUpdatedAttribute({ ...updatedAttribute, section: e })}
            isSearchable
            isClearable
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sub Category</Form.Label>
          <Select
            value={updatedAttribute.subSection}
            options={subSections.map(subSection => ({
              label: subSection.attribute_sub_section_name,
              value: subSection.id,
            }))}
            onChange={e => setUpdatedAttribute({ ...updatedAttribute, subSection: e })}
            isSearchable
            isClearable
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Input Type</Form.Label>
          <Select
            value={updatedAttribute.inputType}
            options={ATTRIBUTE_INPUT_TYPE_LIST}
            onChange={e => setUpdatedAttribute({ ...updatedAttribute, inputType: e })}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Priority Level</Form.Label>
          <Select
            value={updatedAttribute.priorityLevel}
            options={AttributePriorityOptions}
            onChange={e => setUpdatedAttribute({ ...updatedAttribute, priorityLevel: e })}
            isSearchable={false}
            isClearable={false}
          />
        </Form.Group>
        <Row>
          <Col sm={4}>
            <Form.Label>Mandatory</Form.Label>
          </Col>
          <Col align="left">
            <ToggleSwitch
              onColor={chartreuse}
              offColor={blackberry}
              checked={updatedAttribute.isMandatory}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() =>
                setUpdatedAttribute({
                  ...updatedAttribute,
                  isMandatory: !updatedAttribute.isMandatory,
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form.Label>Visibility</Form.Label>
          </Col>
          <Col align="left">
            <ToggleSwitch
              onColor={chartreuse}
              offColor={blackberry}
              checked={updatedAttribute.isVisible}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() =>
                setUpdatedAttribute({ ...updatedAttribute, isVisible: !updatedAttribute.isVisible })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form.Label>Key Attribute</Form.Label>
          </Col>
          <Col align="left">
            <ToggleSwitch
              onColor={chartreuse}
              offColor={blackberry}
              checked={updatedAttribute.isAKeyAttribute}
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() =>
                setUpdatedAttribute({
                  ...updatedAttribute,
                  isAKeyAttribute: !updatedAttribute.isAKeyAttribute,
                })
              }
            />
          </Col>
        </Row>
        <Row className="pt-3 mt-3">
          <Col sm={3}>
            <Button
              block
              onClick={() => {
                if (!updatedAttribute.attributeName) {
                  SwalDialog('error', 'Missing Attribute Name', 'Error', 'center');
                } else if (!updatedAttribute.subSection?.value) {
                  SwalDialog('error', 'Missing Sub Section', 'Error', 'center');
                } else {
                  updateAttribute({
                    attributeName: updatedAttribute.attributeName,
                    attributeDisplayName: updatedAttribute.attributeDisplayName,
                    isMandatory: updatedAttribute.isMandatory,
                    isVisible: updatedAttribute.isVisible,
                    keyAttribute: updatedAttribute.isAKeyAttribute,
                    inputType: updatedAttribute.inputType.value,
                    priorityLevel: updatedAttribute.priorityLevel.value,
                    attributeSectionId: updatedAttribute.section,
                    attributeSubSectionId: updatedAttribute.subSection,
                  });
                }
              }}
            >
              Save
            </Button>
          </Col>
          <Col sm={2} className="pl-0">
            <Button variant="outline-primary" onClick={() => onHide()}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

EditAttributeModal.propTypes = {
  onHide: PropTypes.func,
  modalHeader: PropTypes.string,
  updateAttribute: PropTypes.func,
  details: PropTypes.object,
};

export default EditAttributeModal;
