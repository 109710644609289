import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { css, StyleSheet } from 'aphrodite';

function ActivityLogSummary({ total_count, activity_logs }) {
  if (!activity_logs || activity_logs.length === 0) {
    return null;
  }

  const groupByType = activity_logs.reduce((acc, log) => {
    if (acc[log.activity_log_type]) {
      acc[log.activity_log_type] += 1;
    } else {
      acc[log.activity_log_type] = 1;
    }
    return acc;
  }, {});

  return (
    <div className="d-flex justify-content-left my-2 align-items-center">
      <div className="mr-3">
        TOTAL: <b>{total_count}</b>
      </div>
      {Object.entries(groupByType).map(([type, count]) => (
        <div key={type} className={classNames('mr-3 text-muted', css(styles.smallText))}>
          {type}: <b>{count}</b>
        </div>
      ))}
    </div>
  );
}

const styles = StyleSheet.create({
  smallText: {
    fontSize: 11,
  },
});

ActivityLogSummary.propTypes = {
  activity_logs: PropTypes.array,
  total_count: PropTypes.number,
};

export default ActivityLogSummary;
