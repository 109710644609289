import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CHANGE_LOGS_DELETED_IMAGE_DETAILS } from 'lib/networking/endpoints';
import { DEFAULT_PAGE_SIZE } from 'lib/constants';
import { GLOBAL_PRODUCT_TYPE } from 'lib/enums/ProductType';
import { ProductType } from 'lib/enums/ProductType';
import PaginationComponent from 'components/shared/PaginationComponent';
import ProductAssetsTable from 'components/global_products/detail_view/assets_view/ProductAssetsTable';
import useGet from 'lib/hooks/useGet';

function GlobalProductDeletedImagesView({ selectedProductOption }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_PAGE_SIZE);

  const selectedVendorProductId =
    selectedProductOption?.productType === ProductType.DISTRIBUTOR
      ? selectedProductOption?.productId
      : null;
  const selectedManufacturerProductId =
    selectedProductOption?.productType === ProductType.MANUFACTURER
      ? selectedProductOption?.productId
      : null;

  const { data: { image_change_logs: changeLogsDeletedImages, count } = {} } = useGet(
    CHANGE_LOGS_DELETED_IMAGE_DETAILS,
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      ...(selectedVendorProductId && {
        vendor_product_id: selectedVendorProductId,
      }),
      ...(selectedManufacturerProductId && {
        manufacturer_product_id: selectedManufacturerProductId,
      }),
    },
    selectedProductOption?.productType === GLOBAL_PRODUCT_TYPE,
  );

  return (
    <>
      <div className="my-4">
        <div className="my-2 d-flex justify-content-between">
          <p>{count} deleted images found</p>
          <PaginationComponent
            totalItems={count}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            defaultSize
          />
        </div>
        {changeLogsDeletedImages?.length ? (
          <ProductAssetsTable deletedImages={changeLogsDeletedImages} />
        ) : (
          <div className="m-3">This product has no deleted assets yet</div>
        )}
      </div>
    </>
  );
}

GlobalProductDeletedImagesView.propTypes = {
  selectedProductOption: PropTypes.object.isRequired,
};

export default GlobalProductDeletedImagesView;
