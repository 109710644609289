import React, { createContext, useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import SwalDialog from 'lib/utils/toast';
import useGet from 'lib/hooks/useGet';
import usePost from 'lib/hooks/usePost';

import AllProductsView from 'components/all_products/AllProductsView';
import { DEFAULT_CATEGORY_VIEW_PAGE_SIZE, DEFAULT_GLOBAL_PRODUCT_COLUMNS } from 'lib/constants';
import { getEndOfDayTimestamp, getStartOfDayTimestamp } from 'lib/utils/dateUtils';
import { GLOBAL_PRODUCTS, GLOBAL_PRODUCTS_DOWNLOAD_CSV, VIEWS } from 'lib/networking/endpoints';
import LoadingSpinner from 'components/shared/LoadingSpinner';

const AllProductsContext = createContext({});

const DefaultAllProductsView = {
  name: 'Default All Products View',
  columns: DEFAULT_GLOBAL_PRODUCT_COLUMNS.map(column => ({ name: column, visible: true })),
};

function AllProductsContainer() {
  const [allViews, setAllViews] = useState([DefaultAllProductsView]);
  const [view, setView] = useState(DefaultAllProductsView);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_CATEGORY_VIEW_PAGE_SIZE);
  const [searchFilters, setSearchFilters] = useState({});
  const [searchFiltersId, setSearchFiltersId] = useState({});
  const [sortConfig, setSortConfig] = useState({ field: '', direction: '' });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const { data: { data: views } = {}, loading, isLoaded: viewsLoaded } = useGet(VIEWS);
  const { postData: createView, loading: viewCreating } = usePost(
    VIEWS,
    () => {
      SwalDialog('success', 'View created successfully', 'Success', 'center');
    },
    error => {
      SwalDialog(
        'error',
        'An error occurred while creating the view: ' + error?.response?.data?.message,
        'Error',
        'center',
      );
    },
  );

  const noApiCallCondition =
    !viewsLoaded ||
    (Object.keys(searchFilters).length === 0
      ? startDate
        ? endDate === null
        : true
      : startDate
      ? endDate === null
      : false);

  const {
    data: { data: { count: productCount } = {} } = {},
    loading: allProductsCountLoading,
    error: errorLoadingallProductsCount,
  } = useGet(
    GLOBAL_PRODUCTS,
    {
      filters: JSON.stringify({
        start_date: getStartOfDayTimestamp(startDate),
        end_date: getEndOfDayTimestamp(endDate),
        ...searchFilters,
        ...searchFiltersId,
      }),
      count_only: true,
    },
    noApiCallCondition,
  );

  const {
    data: { data: { global_products: products } = {} } = {},
    loading: allProductsLoading,
    error: errorLoadingAllProducts,
    refetch,
  } = useGet(
    GLOBAL_PRODUCTS,
    {
      limit: itemsPerPage,
      offset: itemsPerPage * (currentPage - 1),
      columns: view?.columns?.map(c => c.name),
      sort_by: sortConfig?.field,
      sort_direction: sortConfig?.direction,
      filters: JSON.stringify({
        statuses: [],
        start_date: getStartOfDayTimestamp(startDate),
        end_date: getEndOfDayTimestamp(endDate),
        ...searchFilters,
        ...searchFiltersId,
      }),
    },
    noApiCallCondition,
  );

  const productsToUse = noApiCallCondition ? [] : products;

  const productCountToUse = noApiCallCondition ? 0 : productCount;

  const { postData: exportAllProducts, loading: productsExporting } = usePost(
    GLOBAL_PRODUCTS_DOWNLOAD_CSV,
    () =>
      Swal.fire({
        icon: 'success',
        html: `
        <div>
          The export csv file will be generated in a while. 
          You can download the generated file from\n
          <a
            href="/file-exports"
            rel="noopener noreferrer"
            target="_blank"
          >
            File Exports Page
          </a>
        </div>`,
        title: 'Success',
        position: 'center',
      }),
    () =>
      SwalDialog(
        'error',
        'An error occurred while trying to download the global products',
        'Error',
        'center',
      ),
  );

  const handleExportAllProducts = () =>
    exportAllProducts({
      columns: view?.columns?.map(c => c.name),
      sort_by: sortConfig?.field,
      sort_direction: sortConfig?.direction,
      filters: {
        statuses: [],
        start_date: getStartOfDayTimestamp(startDate),
        end_date: getEndOfDayTimestamp(endDate),
        ...searchFilters,
        ...searchFiltersId,
      },
    });

  const onColumnSearch = (column, searchText, searchId) => {
    if (!searchText) {
      setSearchFilters(prevState => {
        const { [column]: value, ...rest } = prevState;
        return rest;
      });
    } else {
      setSearchFilters(prevState => ({ ...prevState, [column]: searchText }));
    }

    setSearchFiltersId(prevState => ({ ...prevState, [`${column}_id`]: searchId || '' }));
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!views?.length) {
      return;
    }
    const vs = views.map(v => ({
      ...v,
      columns: v.columns.map(c => ({ name: c, visible: true })),
    }));
    setAllViews(vs);
    setView(vs.find(v => v.active));
  }, [views]);

  const viewsLoading = loading || viewCreating;
  return (
    <AllProductsContext.Provider
      value={{
        allViews,
        setAllViews,
        view,
        setView,
        createView,
        viewsLoading,
        products: productsToUse,
        productCount: productCountToUse,
        allProductsLoading,
        errorLoadingAllProducts,
        allProductsCountLoading,
        errorLoadingallProductsCount,
        refetch,
        onColumnSearch,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        searchFilters,
        searchFiltersId,
        sortConfig,
        setSortConfig,
        startDate,
        endDate,
        setDateRange,
        handleExportAllProducts,
        productsExporting,
      }}
    >
      {allProductsLoading || viewsLoading ? <LoadingSpinner short /> : <AllProductsView />}
    </AllProductsContext.Provider>
  );
}

export default AllProductsContainer;
export { AllProductsContext };
